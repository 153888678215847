<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div
            class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
              <h3>User List</h3>
            </div>
            <div class="col-sm-3">
                <div class="d-flex gap-2 justify-content-end align-items-center">
                    <div>
                        <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                          <button type="button" (click)="editOpenModal()" data-bs-toggle="offcanvas" data-bs-target="#edituser">
                              <span><i class="ri-pencil-line"></i></span>
                          </button>
                        </div>
                    </div>
                    <div>
                        <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                          <button type="button" data-bs-toggle="modal" data-bs-target="#cclrDeleteModal" (click)="deleteOpenModal()">
                            <span><i class="ri-delete-bin-5-line"></i></span>
                          </button>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="btn" (click)=moveToDeputy()><div>Onsite List</div></button>
                    </div>
                   
                    <div>
                        <button type="button" data-bs-toggle="modal" data-bs-target="#importuser" class="btn btn-icon"><i class="ri-file-download-line"></i></button> 
                    </div>
                    <div>
                        <button type="button" (click)="openCreate()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" class="btn btn-icon"><i class="ri-add-line"></i></button>
                    </div>
                </div>
              <div>
              </div>
            </div>
          </div>
          <ag-grid-angular
          style="height: calc(100vh - 120px) !important;" 
            class="ag-theme-material"
            (gridReady)="onGridReady($event)"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            pagination="true"
            [defaultColDef]="defaultColDef"
            [suppressRowClickSelection]="true"
            [rowSelection]="rowSelection"
            (selectionChanged)="onSelectionChanged($event)"
            paginationPageSize="10"
          >
          </ag-grid-angular>
        </div>
      </section>
    </section>
  </article>
</main>

<!-- Delete an item -->
<div class="modal fade" id="cclrDeleteModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form action="">
        <div class="modal-header">
            <h5 class="modal-title">Are you sure want to delete?</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>{{deleteConfirmationMsg}}?</p> 
          </div>
        </div>
        <div class="modal-footer">
          <button id="closeuserDeleteModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteUser()">Yes Delete</button>

        </div>
      </form>
    </div>
  </div>
</div>

<!-- Edit an item -->
<div
  class="offcanvas offcanvas-end canvas-overflow"
  tabindex="-1"
  id="edituser"
>
  <form [formGroup]="usersForm">
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">
        Edit User Profile
      </h5>
      <button
        type="button"
        id="closeUserEditModal"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="user-profile">
        <div class="user-avtar">
          <img src="{{ userImage }}" alt="profile_pic" />
          <div class="user-img-selection">
            <input type="file" (change)="onFileChanged($event)" />
            <i class="ri-pencil-line"></i>
          </div>
        </div>
        <div class="user-details">
          <p>Edit</p>
          <a href="">Reset Password</a>
        </div>
      </div>
      <div class="users-tabls">
        <nav>
          <div
            class="nav nav-tabs d-flex justify-content-between mb-2"
            id="nav-tab"
          >
            <a
              class="nav-item nav-link active p-2"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home"
              role="tab"
              >General</a
            >
            <a
              class="nav-item nav-link p-2"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile"
              role="tab"
              >Organization</a
            >
            <a
              class="nav-item nav-link p-2"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact"
              role="tab"
              >Storage</a
            >
          </div>
        </nav>
        <div class="tab-content pt-2" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-home" role="tabpanel">
            <div class="input">
              <div class="input-item">
                <input formControlName="firstName" type="text" required />
                <label for="firstName" class="floating-label"
                  >First Name*</label
                >
                <i class="ri-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.firstName.touched && f.firstName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.firstName.errors.required"
                  >First Name is required</mat-error
                >
                <mat-error *ngIf="f.firstName.errors.pattern"
                  >First Name can contain alphabets only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="middleName" type="text" required />
                <label for="middleName" class="floating-label"
                  >Middle Name*</label
                >
                <i class="ri-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.middleName.touched && f.middleName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.middleName.errors.required"
                  >Middle Name is required</mat-error
                >
                <mat-error *ngIf="f.middleName.errors.pattern"
                  >Middle Name can contain alphabets only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="lastName" type="text" required />
                <label for="lastName" class="floating-label">Last Name*</label>
                <i class="ri-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.lastName.touched && f.lastName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.lastName.errors.required"
                  >Last Name is required</mat-error
                >
                <mat-error *ngIf="f.lastName.errors.pattern"
                  >Last Name can contain alphabets only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="displayName" type="text" required />
                <label for="displayName" class="floating-label"
                  >Display Name*</label
                >
                <i class="ri-file-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.displayName.touched && f.displayName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.displayName.errors.required"
                  >Display Name is required</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <input
                  formControlName="localId"
                  placeholder="#0000"
                  type="text"
                />
                <label for="localId" class="floating-label">Emp Id*</label>
                <i class="ri-map-pin-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.localId.touched && f.localId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.localId.errors.required"
                  >Employee Id is required</mat-error
                >
                <mat-error *ngIf="f.localId.errors.pattern"
                  >Employee Id starts with # and followed by numbers
                  only</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="localId" type="text" />
                <label for="localId" class="floating-label">Emp Id</label>
                <i class="ri-map-pin-user-line"></i>
              </div>
              <mat-error
              *ngIf="f.localId.touched && f.localId.invalid"
              class="alert alert-danger"
            >
              <mat-error *ngIf="f.localId.errors.pattern"
                >Employee Id starts with # and followed by numbers
                only</mat-error
              >
            </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="userId" type="text" required />
                <label for="userId" class="floating-label">User Id*</label>
                <i class="ri-shield-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.userId.touched && f.userId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.userId.errors.required"
                  >User Id is required</mat-error
                >
                <mat-error *ngIf="f.userId.errors.pattern"
                  >User Id can contain alphabets & numbers only</mat-error
                >
              </mat-error>
            </div>
            <!-- <div class="input">
                            <div class="input-item">
                                <input formControlName="password" type="password" required>
                                <label for="password" class="floating-label">Password*</label>
                                <i class="ri-lock-line"></i>
                            </div>
                            <mat-error *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                                <mat-error *ngIf="f.password.errors.required">Password is required</mat-error>
                                <mat-error *ngIf="f.password.errors.pattern">Password must have at least 8 chracters
                                    which contain at least one lowercase character, at least one uppercase character, at
                                    least one number and special character </mat-error>
                            </mat-error>
                        </div> -->
            <div class="input">
              <div class="input-item">
                <label class="floating-label"></label>
                <input
                  list="taskOwnerDatalist"
                  formControlName="supervisonName"
                  placeholder="Supervison Name"
                />
                <datalist id="taskOwnerDatalist">
                  <option
                    [value]="item"
                    *ngFor="let item of userDropdownList"
                  ></option>
                </datalist>
                <i class="ri-user-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label"></label>
                <input
                  list="taskOwnerDatalist"
                  formControlName="hrName"
                  placeholder="Hr Name"
                />
                <datalist id="taskOwnerDatalist">
                  <option
                    [value]="item"
                    *ngFor="let item of userDropdownList"
                  ></option>
                </datalist>
                <i class="ri-user-line"></i>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-profile" role="tabpanel">
            <div class="input">
              <div class="input-item">
                <input formControlName="birthDate" type="date" required />
                <label for="birthDate" class="floating-label"
                  >Birth Date*</label
                >
                <i class="ri-file-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.birthDate.touched && f.birthDate.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.birthDate.errors.required"
                  >BirthDate is required</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <label for="department" class="floating-label floating-label-special"
                  >Department*</label
                >
                <select formControlName="department" placeholder="department">
                  <option value="" disabled selected>Select department</option>
                  <option value="accounts">Accounts</option>
                  <option value="Business Development">Business Development</option>
                  <option value="CAE">CAE</option>
                  <option value="Design">Design</option>
                  <option value="Design and Development">Design and Development</option>
                  <option value="HR">HR</option>
                  <option value="IT">IT</option>
                  <option value="Management">Management</option>
                  <option value="Admin">Admin</option>
                  <option value="QA">QA</option>
                </select>
                <i class="ri-building-line"></i>
              </div>
              <!-- <mat-error
                *ngIf="f.department.touched && f.department.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.department.errors.required"
                  >Department is required</mat-error
                >
              </mat-error> -->
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="department" type="text" required />
                <label for="department" class="floating-label"
                  >Department*</label
                >
                <i class="ri-building-line"></i>
              </div>
              <mat-error
                *ngIf="f.department.touched && f.department.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.department.errors.required"
                  >Department is required</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <label for="currentDesignation" class="floating-label floating-label-special"
                  >Current Designation*</label
                >
                <select formControlName="currentDesignation" placeholder="currentDesignation">
                  <option value="" disabled selected>Select current Designation</option>
                  <option value="CAD Engineer">CAD Engineer</option>
                  <option value="CAE Engineer">CAE Engineer</option>
                  <option value="HBD / CFO">HBD / CFO</option>
                  <option value="Chief Business Development office">Chief Business Development office</option>
                  <option value="Chief Technical Office">Chief Technical Officer</option>
                  <option value="Design Engineer">Design Engineer</option>
                  <option value="HR Manager">HR Manager</option>
                  <option value="Human Resource Business Partner">Human Resource Business Partner</option>
                  <option value="Lead Design Engineer">Lead Design Engineer</option>
                  <option value="Senior Lead Engineer">Senior Lead Engineer</option>
                  <option value="Senior CAE Engineer">Senior CAE Engineer</option>
                  <option value="Senior Design Engineer">Senior Design Engineer</option>
                  <option value="System Administrator">System Administrator</option>
                  <option value="Senior Manager">Senior Manager</option>
                  <option value="GET">GET</option>
                  <option value="Office Boy">Office Boy</option>
                  <option value="BD Intern">BD Intern</option>
                  <option value="Admin">Admin</option>
                  <option value="Account">Account</option>
                  <option value="Quality Engineer">Quality Engineer</option>
                  <option value="Quality Inspector Engineer">Quality Inspector Engineer</option>
                </select>
                <i class="ri-map-pin-user-line"></i>
              </div>
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="currentDesignation" type="text" />
                <label for="currentDesignation" class="floating-label"
                  >Current Designation</label
                >
                <i class="ri-map-pin-user-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="emailId" type="text" required />
                <label for="emailId" class="floating-label">Email Id*</label>
                <i class="ri-mail-unread-line"></i>
              </div>
              <mat-error
                *ngIf="f.emailId.touched && f.emailId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.emailId.errors.required"
                  >Email Id is required</mat-error
                >
                <mat-error *ngIf="f.emailId.errors.pattern"
                  >Email Id must contain @</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="alternateEmailId" type="text" />
                <label for="alternateEmailId" class="floating-label"
                  >Alternate Email Id</label
                >
                <i class="ri-mail-unread-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="phoneNo" type="text" required />
                <label for="phoneNo" class="floating-label">Phone No*</label>
                <i class="ri-phone-line"></i>
              </div>
              <mat-error
                *ngIf="f.phoneNo.touched && f.phoneNo.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.phoneNo.errors.required"
                  >Phone No. is required</mat-error
                >
                <mat-error *ngIf="f.phoneNo.errors.pattern"
                  >Phone No can contain numbers only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input">
                <div class="input-item" required>
                  <select formControlName="gender" required>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <label class="floating-label floating-label-special"
                    >Gender*</label
                  >
                  <i class="ri-calendar-check-line"></i>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input">
                <div class="input-item" required>
                  <select formControlName="bloodGroup">
                    <option value="" disabled selected>Select Blood Group</option>
                    <option value="A positive (A+)">A positive (A+)</option>
                    <option value="A negative (A-)">A negative (A-)</option>
                    <option value="B positive (B+)">B positive (B+)</option>
                    <option value="B negative (B-)">B negative (B-)</option>
                    <option value="AB positive (AB+)">AB positive (AB+)</option>
                    <option value="AB negative (AB-)">AB negative (AB-)</option>
                    <option value="O positive (O+)">O positive (O+)</option>
                    <option value="O negative (O-)">O negative (O-)</option>
                  </select>
                  <label class="floating-label floating-label-special"
                    >Blood Group*</label
                  >
                  <i class="ri-calendar-check-line"></i>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special">
                  Marriage Status
                </label>
                <select
                  formControlName="marriageStatus"
                  (change)="checkmarritalstatus($event.target.value)"
                >
                  <option value="" disabled selected>Marriage Status</option>
                  <option value="Married">Married</option>
                  <option value="UnMarried">UnMarried</option>
                </select>
                <i class="ri-heart-fill"></i>
              </div>
            </div>
            <div class="input" *ngIf="this.marriagedate === 'Married'">
              <div class="input-item">
                <input formControlName="marriageDate" type="date" />
                <label for="marriageDate" class="floating-label"
                  >Marriage Date</label
                >
                <i class="ri-heart-fill"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="dashboardIndex" type="text" />
                <label for="dashboardIndex" class="floating-label"
                  >Dashboard Index</label
                >
                <i class="ri-phone-line"></i>
              </div>
            </div>

            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >admin</label
                >
                <select formControlName="admin" placeholder="admin">
                  <option value="" disabled selected>Select admin</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <i class="ri-phone-line"></i>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-contact" role="tabpanel">
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <label for="location" class="floating-label floating-label-special">Location*</label>
                <select formControlName="location" placeholder="location">
                  <option value="" disabled selected>Select location</option>
                  <option value="Pune">Pune</option>
                  
                </select>
                <i class="ri-map-pin-line"></i>
              </div>
              <!-- <mat-error
                *ngIf="f.location.touched && f.location.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.location.errors.required"
                  >Location is required</mat-error
                >
              </mat-error> -->
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="location" type="text" required />
                <label for="location" class="floating-label">Location*</label>
                <i class="ri-map-pin-line"></i>
              </div>
              <mat-error
                *ngIf="f.location.touched && f.location.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.location.errors.required"
                  >Location is required</mat-error
                >
              </mat-error> 
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="country" type="text" />
                <label for="country" class="floating-label">Country</label>
                <i class="ri-earth-fill"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item no-overflow">
                <ng-select
                  formControlName="roles"
                  [items]="rolesArray"
                  [multiple]="true"
                  bindLabel="lable"
                  bindValue="value"
                  placeholder="Select roles"
                  style="width: -webkit-fill-available"
                >
                </ng-select>
                <i class="ri-group-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="costCenter" type="text" />
                <label
                  for="costCenter"
                  class="floating-label floating-label-special"
                  >CostCenter</label
                >
                <i class="ri-money-dollar-circle-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <select formControlName="deputedAt" >
                  <option value="" disabled selected>Select DeputedAt</option>
                  <option value="OnSite">OnSite</option>
                  <option value="Inhouse">Inhouse</option>
                </select>
                <label class="floating-label-special">Deputed At*</label>
                <i class="ri-map-pin-user-line"></i>
              </div>
            </div>

            <div class="input">
              <div class="input-item">
                <input formControlName="employeeCategory" type="text" />
                <label for="employeeCategory" class="floating-label"
                  >Employee Category</label
                >
                <i class="ri-stack-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="joinDate" type="date" />
                <label for="joinDate" class="floating-label">Join Date</label>
                <i class="ri-calendar-check-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="quitDate" type="date" />
                <label for="quitDate" class="floating-label">Quit Date</label>
                <i class="ri-calendar-check-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Resource Type</label
                >
                <select
                  formControlName="resourceType"
                  placeholder="Resource Type"
                >
                  <option value="" disabled selected>
                    Select Resource Type
                  </option>
                  <option value="Permanent">Permanent</option>
                  <option value="Contractual">Contractual</option>
                  <option value="Consultant">Consultant</option>
                </select>
                <i class="ri-file-settings-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Onboard Status</label
                >
                <select
                  formControlName="onboardStatus"
                  placeholder="Onboard Status"
                >
                  <option value="" disabled selected>
                    Select Onboard Status
                  </option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <i class="ri-line-chart-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="emergencyContactNo" type="text" />
                <label for="emergencyContactNo" class="floating-label"
                  >Emergency ContactNo</label
                >
                <i class="ri-inbox-archive-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >archived</label
                >
                <select formControlName="archived" placeholder="archived">
                  <option value="" disabled selected>Select archived</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <i class="ri-inbox-archive-line"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 text-end">
        <a
          (click)="updateUser(selectedRow?.id)"
          class="btn"
          [class.disabled]="!usersForm.valid ? true : null"
          >Update</a
        >
      </div>
    </div>
  </form>
</div>

<!-- Create new User -->
<div
  class="offcanvas offcanvas-end canvas-overflow"
  tabindex="-1"
  id="offcanvasExample"
  aria-labelledby="offcanvasExampleLabel"
>
  <form [formGroup]="usersForm">
    <div class="offcanvas-header">
      <h5 class="text-white">User Setting</h5>
      <button
        type="button"
        id="closeUserAddModal"
        class="btn-close bg-danger text-white p-3"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <div class="user-profile">
        <div class="user-avtar">
          <img [src]="retrievedImage" alt="" />
          <div class="user-img-selection">
            <input type="file" (change)="onFileChanged($event)" />
            <i class="ri-pencil-line"></i>
          </div>
        </div>
        <div class="user-details text-end text-right">
          <p>Create</p>
          <a href="">Reset Password</a>
        </div>
      </div>
      
      <div class="users-tabls">
        <nav>
          <div
            class="nav nav-tabs d-flex justify-content-between mb-2"
            id="nav-tab"
          >
            <a
              class="nav-item nav-link active p-2"
              id="nav-home-tab"
              data-toggle="tab"
              href="#nav-home1"
              role="tab"
              >General</a
            >
            <a
              class="nav-item nav-link p-2"
              id="nav-profile-tab"
              data-toggle="tab"
              href="#nav-profile1"
              role="tab"
              >Organization</a
            >
            <a
              class="nav-item nav-link p-2"
              id="nav-contact-tab"
              data-toggle="tab"
              href="#nav-contact1"
              role="tab"
              >Storage</a
            >
          </div>
        </nav>
      </div>
        <div class="tab-content pt-2" id="nav-tabContent">
          <div class="tab-pane fade show active" id="nav-home1" role="tabpanel">
            <div class="input">
              <div class="input-item">
                <input formControlName="firstName" type="text" required />
                <label for="firstName" class="floating-label"
                  >First Name*</label
                >
                <i class="ri-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.firstName.touched && f.firstName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.firstName.errors.required"
                  >First Name is required</mat-error
                >
                <mat-error *ngIf="f.firstName.errors.pattern"
                  >First Name can contain alphabets only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="middleName" type="text" required />
                <label for="middleName" class="floating-label"
                  >Middle Name*</label
                >
                <i class="ri-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.middleName.touched && f.middleName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.middleName.errors.required"
                  >Middle Name is required</mat-error
                >
                <mat-error *ngIf="f.middleName.errors.pattern"
                  >Middle Name can contain alphabets only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="lastName" type="text" required />
                <label for="lastName" class="floating-label">Last Name*</label>
                <i class="ri-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.lastName.touched && f.lastName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.lastName.errors.required"
                  >Last Name is required</mat-error
                >
                <mat-error *ngIf="f.lastName.errors.pattern"
                  >Last Name can contain alphabets only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="displayName" type="text" required />
                <label for="displayName" class="floating-label"
                  >Display Name*</label
                >
                <i class="ri-file-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.displayName.touched && f.displayName.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.displayName.errors.required"
                  >Display Name is required</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <input
                  formControlName="localId"
                  placeholder="#0000"
                  type="text"
                />
                <label for="localId" class="floating-label">Emp Id*</label>
                <i class="ri-map-pin-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.localId.touched && f.localId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.localId.errors.required"
                  >Employee Id is required</mat-error
                >
                <mat-error *ngIf="f.localId.errors.pattern"
                  >Employee Id starts with # and followed by numbers
                  only</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input
                  formControlName="localId"
                  placeholder="#0000"
                  type="text"
                />
                <label for="localId" class="floating-label">Emp Id</label>
                <i class="ri-map-pin-user-line"></i>
              </div>
              <mat-error
              *ngIf="f.localId.touched && f.localId.invalid"
              class="alert alert-danger"
            >
              <mat-error *ngIf="f.localId.errors.pattern"
                >Employee Id starts with # and followed by numbers
                only</mat-error
              >
            </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="userId" type="text" required />
                <label for="userId" class="floating-label">User Id*</label>
                <i class="ri-shield-user-line"></i>
              </div>
              <mat-error
                *ngIf="f.userId.touched && f.userId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.userId.errors.required"
                  >User Id is required</mat-error
                >
                <mat-error *ngIf="f.userId.errors.pattern"
                  >User Id can contain alphabets & numbers only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="password" type="password" />
                <label for="password" class="floating-label">Password*</label>
                <i class="ri-lock-line"></i>
              </div>
              <mat-error
                *ngIf="f.password.touched && f.password.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.password.errors.pattern"
                  >Password must have at least 8 chracters which contain at
                  least one lowercase character, at least one uppercase
                  character, at least one number and special character
                </mat-error>
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Supervison Name</label
                >
                <input
                  list="taskOwnerDatalist"
                  formControlName="supervisonName"
                  placeholder="Supervison Name"
                />
                <datalist id="taskOwnerDatalist">
                  <option
                    [value]="item"
                    *ngFor="let item of userDropdownList"
                  ></option>
                </datalist>
                <i class="ri-user-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Hr Name</label
                >
                <input
                  list="taskOwnerDatalist"
                  formControlName="hrName"
                  placeholder="Hr Name"
                />
                <datalist id="taskOwnerDatalist">
                  <option
                    [value]="item"
                    *ngFor="let item of userDropdownList"
                  ></option>
                </datalist>
                <i class="ri-user-line"></i>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-profile1" role="tabpanel">
            <div class="input">
              <div class="input-item">
                <input formControlName="birthDate" type="date" required />
                <label for="birthDate" class="floating-label"
                  >Birth Date*</label
                >
                <i class="ri-calendar-2-line"></i>
              </div>
              <mat-error
                *ngIf="f.birthDate.touched && f.birthDate.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.birthDate.errors.required"
                  >BirthDate is required</mat-error
                >
              </mat-error>
            </div>
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <label for="department" class="floating-label floating-label-special"
                  >Department*</label
                >
                <select formControlName="department" placeholder="department">
                  <option value="" disabled selected>Select department</option>
                  <option value="accounts">Accounts</option>
                  <option value="Business Development">Business Development</option>
                  <option value="CAE">CAE</option>
                  <option value="Design">Design</option>
                  <option value="Design and Development">Design and Development</option>
                  <option value="HR">HR</option>
                  <option value="IT">IT</option>
                  <option value="Management">Management</option>
                  <option value="Admin">Admin</option>
                  <option value="QA">QA</option>
                </select>
                <i class="ri-building-line"></i>
              </div>
              <!-- <mat-error
                *ngIf="f.department.touched && f.department.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.department.errors.required"
                  >Department is required</mat-error
                >
              </mat-error> -->
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="department" type="text" required />
                <label for="department" class="floating-label"
                  >Department*</label
                >
                <i class="ri-building-line"></i>
              </div>
              <mat-error
                *ngIf="f.department.touched && f.department.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.department.errors.required"
                  >Department is required</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="emailId" type="text" required />
                <label for="emailId" class="floating-label">Email Id*</label>
                <i class="ri-mail-unread-line"></i>
              </div>
              <mat-error
                *ngIf="f.emailId.touched && f.emailId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.emailId.errors.required"
                  >Email Id is required</mat-error
                >
                <mat-error *ngIf="f.emailId.errors.email"
                  >Email Id must be a valid email</mat-error
                >
                <mat-error *ngIf="f.emailId.errors.pattern"
                  >Enter valid email</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="alternateEmailId" type="text" />
                <label for="alternateEmailId" class="floating-label"
                  >Alternate Email Id</label
                >
                <i class="ri-mail-unread-line"></i>
              </div>
              <mat-error
                *ngIf="f.alternateEmailId.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.alternateEmailId.errors.email"
                  >Email Id must be a valid email</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="phoneNo" type="text" required />
                <label for="phoneNo" class="floating-label">Phone No*</label>
                <i class="ri-phone-line"></i>
              </div>
              <mat-error
                *ngIf="f.phoneNo.touched && f.phoneNo.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.phoneNo.errors.required"
                  >Phone No. is required</mat-error
                >
                <mat-error *ngIf="f.phoneNo.errors.pattern"
                  >Phone No can contain numbers only</mat-error
                >
              </mat-error>
            </div>
            <div class="input">
              <div class="input">
                <div class="input-item" required>
                  <select formControlName="gender" required>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <label class="floating-label floating-label-special"
                    >Gender*</label
                  >
                  <i class="ri-calendar-check-line"></i>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input">
                <div class="input-item" required>
                  <select formControlName="bloodGroup">
                    <option value="" disabled selected>Select Blood Group</option>
                    <option value="A positive (A+)">A positive (A+)</option>
                    <option value="A negative (A-)">A negative (A-)</option>
                    <option value="B positive (B+)">B positive (B+)</option>
                    <option value="B negative (B-)">B negative (B-)</option>
                    <option value="AB positive (AB+)">AB positive (AB+)</option>
                    <option value="AB negative (AB-)">AB negative (AB-)</option>
                    <option value="O positive (O+)">O positive (O+)</option>
                    <option value="O negative (O-)">O negative (O-)</option>
                  </select>
                  <label class="floating-label floating-label-special"
                    >Blood Group*</label
                  >
                  <i class="ri-calendar-check-line"></i>
                </div>
              </div>
            </div>

            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special">
                  Marriage Status
                </label>
                <select
                  formControlName="marriageStatus"
                  (change)="checkmarritalstatus($event.target.value)"
                >
                  <option value="" disabled selected>Marriage Status</option>
                  <option value="UnMarried">UnMarried</option>
                  <option value="Married">Married</option>
                </select>
                <i class="ri-heart-fill"></i>
              </div>
            </div>
            <div class="input" *ngIf="this.marriagedate === 'Married'">
              <div class="input-item">
                <input formControlName="marriageDate" type="date" />
                <label for="marriageDate" class="floating-label"
                  >Marriage Date</label
                >
                <i class="ri-calendar-2-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="dashboardIndex" type="text" />
                <label for="dashboardIndex" class="floating-label"
                  >Dashboard Index</label
                >
                <i class="ri-phone-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >admin</label
                >
                <select formControlName="admin" placeholder="admin">
                  <option value="" disabled selected>Select admin</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <i class="ri-phone-line"></i>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="nav-contact1" role="tabpanel">
            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <label for="location" class="floating-label floating-label-special">Location*</label>
                <select formControlName="location" placeholder="location">
                  <option value="" disabled selected>Select location</option>
                  <option value="pune">Pune</option>
                  
                </select>
                <i class="ri-map-pin-line"></i>
              </div>
              <!-- <mat-error
                *ngIf="f.location.touched && f.location.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.location.errors.required"
                  >Location is required</mat-error
                >
              </mat-error> -->
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="location" type="text" required />
                <label for="location" class="floating-label">Location*</label>
                <i class="ri-map-pin-line"></i>
              </div>
              <mat-error
                *ngIf="f.location.touched && f.location.invalid"
                class="alert alert-danger"
              >
                <mat-error *ngIf="f.location.errors.required"
                  >Location is required</mat-error
                >
              </mat-error> 
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="country" type="text" />
                <label for="country" class="floating-label">Country</label>
                <i class="ri-earth-fill"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-group">
                <div class="input-item no-overflow">
                  <ng-select
                    class="form-control"
                    formControlName="roles"
                    [items]="rolesArray"
                    [multiple]="true"
                    placeholder="Select roles"
                    bindLabel="lable"
                    bindValue="value"
                  >
                  </ng-select>
                  <i class="ri-group-line"></i>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Cost Center</label
                >
                <input list="costCenter" formControlName="costCenter" />
                <datalist id="costCenter">
                  <option
                    [value]="item"
                    *ngFor="let item of costDropdownList"
                  ></option>
                </datalist>

                <i class="ri-money-dollar-circle-line"></i>
              </div>
            </div>

            <div class="input" *ngIf="this.isTenantCustomised">
              <div class="input-item">
                <label for="currentDesignation" class="floating-label floating-label-special"
                  >Current Designation*</label
                >
                <select formControlName="currentDesignation" placeholder="currentDesignation">
                  <option value="" disabled selected>Select current Designation</option>
                  <option value="CAD Engineer">CAD Engineer</option>
                  <option value="CAE Engineer">CAE Engineer</option>
                  <option value="HBD / CFO">HBD / CFO</option>
                  <option value="Chief Business Development office">Chief Business Development office</option>
                  <option value="Chief Technical Office">Chief Technical Officer</option>
                  <option value="Design Engineer">Design Engineer</option>
                  <option value="HR Manager">HR Manager</option>
                  <option value="Human Resource Business Partner">Human Resource Business Partner</option>
                  <option value="Lead Design Engineer">Lead Design Engineer</option>
                  <option value="Senior Lead Engineer">Senior Lead Engineer</option>
                  <option value="Senior CAE Engineer">Senior CAE Engineer</option>
                  <option value="Senior Design Engineer">Senior Design Engineer</option>
                  <option value="System Administrator">System Administrator</option>
                  <option value="Senior Manager">Senior Manager</option>
                  <option value="GET">GET</option>
                  <option value="Office Boy">Office Boy</option>
                  <option value="BD Intern">BD Intern</option>
                  <option value="Admin">Admin</option>
                  <option value="Account">Account</option>
                  <option value="KBE Engineer">KBE Engineer</option>
                  <option value="Quality Engineer">Quality Engineer</option>
                  <option value="Quality Inspector Engineer">Quality Inspector Engineer</option>
                </select>
                <i class="ri-map-pin-user-line"></i>
              </div>
            </div>
            <div class="input" *ngIf="!this.isTenantCustomised">
              <div class="input-item">
                <input formControlName="currentDesignation" type="text" />
                <label for="currentDesignation" class="floating-label"
                  >Current Designation</label
                >
                <i class="ri-map-pin-user-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <select formControlName="deputedAt" >
                        <option value="" disabled selected>Select DeputedAt</option>
                        <option value="Onsite">OnSite</option>
                        <option value="Inhouse">Inhouse</option>
                      </select>
                  <label class="floating-label-special">Deputed At*</label>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="employeeCategory" type="text" />
                <label for="employeeCategory" class="floating-label"
                  >Employee Category</label
                >
                <i class="ri-stack-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="joinDate" type="date" />
                <label for="joinDate" class="floating-label">Join Date</label>
                <i class="ri-calendar-check-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input formControlName="quitDate" type="date" />
                <label for="quitDate" class="floating-label">Quit Date</label>
                <i class="ri-calendar-check-line"></i>
              </div>
              <div class="input text-center">
                <span id="display-error" class="d-none">{{
                  displayError
                }}</span>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Resource Type</label
                >
                <select
                  formControlName="resourceType"
                  placeholder="Resource Type"
                >
                  <option value="" disabled selected>
                    Select Resource Type
                  </option>
                  <option value="Permanent">Permanent</option>
                  <option value="Contractual">Contractual</option>
                  <option value="Consultant">Consultant</option>
                </select>
                <i class="ri-file-settings-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >Onboard Status</label
                >
                <select
                  formControlName="onboardStatus"
                  placeholder="Onboard Status"
                >
                  <option value="" disabled selected>
                    Select Onboard Status
                  </option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <i class="ri-line-chart-line"></i>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <label class="floating-label floating-label-special"
                  >archived</label
                >
                <select formControlName="archived" placeholder="archived">
                  <option value="" disabled selected>Select archived</option>
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
                <i class="ri-inbox-archive-line"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 text-end">
        <a
          (click)="createUsers()"
          class="btn"
          [class.disabled]="!usersForm.valid ? true : null"
          >Create User</a
        >
      </div>
 </form>
</div>

<!-- import user -->
<div class="modal fade" id="importuser" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="form">
        <div class="modal-header">
          <h5 class="modal-title">Import User</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col-md-4" style="padding-left: 25%">
            <input type="file" (change)="imprtUserOnFileChanged($event)" />
          </div>
        </div>
        <div class="modal-footer"></div>
      </form>
    </div>
  </div>
</div>
