import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ActionItemsGridRowRendererComponent } from '../../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leave-balance-report',
  templateUrl: './leave-balance-report.component.html',
  styleUrls: ['./leave-balance-report.component.scss']
})
export class LeaveBalanceReportComponent implements OnInit {
  
  tenantId: string;
  currentUser: any;
  gridLength = 0;
  leaveBalanceYearForm: FormGroup;
  sampleGridFrameworkComponents: any;
  // row data and column definitions
  rowData: any;
  // public columnDefs: ColDef[];
  public columnDefs: ColDef[] = []
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
  };
  
  constructor(
    private storageService: LocalStorageService,
    private HrService: HrService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { 
    { 
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.columnDefs = this.createColumnDefs();
  
      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent,
      };
    };

    this.leaveBalanceYearForm = this.formBuilder.group({
      year: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.getLeaveBalanceReport();
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.rowData = [];
  }

  // create column definitions
  private createColumnDefs() {
    return [
      {
        headerName: 'Employee Information',
        children: [
          {
            headerName: 'Employee Code',
            field: 'localId',
            minWidth: 100,
            filter: true,
            editable: false,
            sortable: true,
          },
          {
            headerName: 'Employee Name',
            field: 'userName',
            minWidth: 150,
            filter: true,
            editable: false,
            sortable: true,
          },
          {
            headerName: 'Joining Date',
            field: 'joiningDate',
            filter: true,
            editable: false,
            sortable: true,
          },
          {
            headerName: 'Leave Year',
            field: 'leaveYear',
            filter: true,
            editable: false,
            sortable: true,
          },
        ],
      },
      {
        headerName: 'Casual Leaves',
        children: [
          { columnGroupShow: 'closed', headerName:'Casual Leaves'},
          { columnGroupShow: 'open', headerName:'On 25th Dec', field: 'clAsOf25Dec', filter: true, sortable: true },
          { columnGroupShow: 'open', headerName:'Annual Allotment', field: 'annualClAllotment', filter: true, sortable: true },
          { columnGroupShow: 'open', headerName:'on 26th Dec', field: 'clAsOf26Dec', filter: true, sortable: true },
        ],
      },
      {
        headerName: 'Leaves Details(Taken So Far)',
        children: [
          { columnGroupShow: 'closed', headerName:'Leaves Details'},
          { columnGroupShow: 'open', headerName:'Casual Leave', valueGetter : function(params){return params.data.leaveDetails?.['Earned Leave']}, filter: true, sortable: true },
          { columnGroupShow: 'open', headerName:'Compensatory Leave', valueGetter : function(params){return params.data.leaveDetails?.['Compensatory leave']}, filter: true, sortable: true },
          { columnGroupShow: 'open', headerName:'Leave Without Pay', valueGetter : function(params){return params.data.leaveDetails?.['Leave Without Pay']}, filter: true, sortable: true },
        ],
      },
    ];
  }

  getLeaveBalanceReport(){
    let year = this.leaveBalanceYearForm.get('year').value;
    this.HrService.getLeaveBalanceReport(year).subscribe(((response: any) => {
      if(response) {
        this.rowData = response;
      }
    }));
  }

  backToLeaveBalance(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-profile']);
  }

}
