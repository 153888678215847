import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadDocumentService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(
    private handler: HttpBackend,
    private http: HttpClient,
    private storageService: LocalStorageService,
  ) {
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
    console.log("Error:", errorMessage);
    return throwError(errorMessage);
  }

  //Upload Document
  uploadUserDocument(payload: object, userId): Observable<Object> {
    return this.httpClient.post(`${environment.apiUrl}/userDocuments?userId=${userId}`,
      payload,
      {
        responseType: 'text', headers: new HttpHeaders({
          'Authorization': this.storageService.get('token'),
          'tenantId': this.storageService.get('tenantId'),
        })
      }
    ).pipe(catchError(this.handleError));
  }

  //Get All Documents
  getUserDocumentsByUserId(userId: number): Observable<Object>  {
    return this.httpClient.get(`${environment.apiUrl}/userDocuments?userId=${userId}`,
      {
        headers: new HttpHeaders({
          'Authorization': this.storageService.get('token'),
          'tenantId': this.storageService.get('tenantId'),
        })
      }
    ).pipe(catchError(this.handleError));
  }

  //Get OR Download Documents By Id
  downloadUserDocumentsId(documentId: string): Observable<Object>  {
    return this.httpClient.get<Blob>(`${environment.apiUrl}/userDocuments/${documentId}`,
      {
        headers: new HttpHeaders({
          'Authorization': this.storageService.get('token'),
          'tenantId': this.storageService.get('tenantId'),
          'Accept' : '*/*'
        })
        ,observe: 'response' ,responseType: 'blob' as 'json'
      });
  }

  //Delete Document
  deleteDocumentById(documentId: String): Observable<Object>  {
    return this.httpClient.delete(`${environment.apiUrl}/userDocuments/${documentId}`,
      {
        responseType: 'text', headers: new HttpHeaders({
          'Authorization': this.storageService.get('token'),
          'tenantId': this.storageService.get('tenantId'),
        })
      }
    ).pipe(catchError(this.handleError));
  }

}