// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class ItDashboardService {

//   constructor() { }
// }
import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItDashboardService extends Subject<DataStateChangeEventArgs> {

  private httpClient: HttpClient;
  constructor(private handler: HttpBackend,
    private http: HttpClient
  ) {
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // Get IT Dashboard Data
  getItDashboard(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/itDashboard`).pipe(catchError(this.handleError));
  }

  // Get HR Dashboard Data
  getHrDashboard(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/hrDashboard`).pipe(catchError(this.handleError));
  }

  // Get HR Trends
  getHrTrends(): Observable<object> {
    return this.http.get(`${environment.apiUrl}/hrTrendsDashboard`).pipe(catchError(this.handleError));
  }
}

