import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/_services/user/user.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';

@Component({
  selector: 'app-profile-dashboard-layout',
  templateUrl: './profile-dashboard-layout.component.html',
  styleUrls: ['./profile-dashboard-layout.component.scss']
})
export class ProfileDashboardLayoutComponent implements OnInit {
  currentUser: any;
  layoutFormGroup: FormGroup = new FormGroup({
    radioForm: new FormControl("0")
  })
  constructor(
    private userService: UserService,
    private storageService: LocalStorageService, 
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getProfileData()
  }



  details: any;
  getProfileData() {
    this.userService.getUser(this.currentUser.id).subscribe((response: any) => {
      this.layoutFormGroup.patchValue({
        radioForm: response.dashboardIndex.toString()
      })
      this.details = response;
    });
  }

  updateRadio() {
    this.details.dashboardIndex = +this.layoutFormGroup.get('radioForm').value;
    this.userService.updateUser(this.currentUser.id, this.details).subscribe((response: any) => {
      console.log("response: ", response);
    });
  }
}
