<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center"> 
                                <div class="edit">
                                    <button title="back page" (click)="backToExit()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Exit Finance</h3>
                            </div>
                        </div>
                    </div> 
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6 d-flex">
                         <h3><a (click)="openExitForm()" class="cursor-pointer">User</a></h3>
                         <h3><a class="active cursor-pointer">Finance</a></h3>
                         <h3><a (click)="openItHrmExit()" class="cursor-pointer">IT & HRM</a></h3>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="row">
                            <div class="col-sm-6">
                                <h5>Finance Clearance</h5>
                            </div>
                            <div class="col-sm-6 d-flex justify-content-end" *ngIf="isFinanceAdmin">
                                <button _ngcontent-mjv-c22="" type="button" (click)="visibleUserClearanceForm()" class="btn btn-icon">
                                    <i _ngcontent-mjv-c22="" class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-sm-10 inner-details" style="margin-left: 10px; margin-top: 10px;">
                            <form [formGroup]="userClearanceForm">
                                <table class="table">
                                    <thead class="h-90" style="border: 0; border-radius: 8px;">
                                        <tr>
                                          <th>Item Name</th>
                                          <th>Status</th>
                                          <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="isClearanceForm">
                                            <td>
                                                <div class="col-sm-12">
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="itemName">
                                                            <label class="floating-label-special">Item Name</label>
                                                            <i class="ri-user-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="col-sm-12">
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <select formControlName="status">
                                                                <option value="" disabled selected>Select leave type</option>
                                                                <option value="Returned">Returned</option>
                                                                <option value="Not Returned">Not Returned</option>
                                                                <option value="Not Applicable">Not Applicable</option>
                                                            </select>
                                                            <label class="floating-label-special">Status</label>
                                                            <i class="ri-user-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td style="max-width: 100px">
                                                <div class="row">
                                                  <div class="d-flex gap-2" style="width: 100px">
                                                    <button *ngIf="formMode === 1" [ngClass]="requiredSaveUserClearance() ? 'btn-invalid-action' : '' "
                                                      _ngcontent-mjv-c22="" type="button" (click)="saveFinanceUserClearance()" class="btn btn-icon">
                                                        <span><i class="ri-save-line"></i></span>
                                                    </button>
                                                    <button *ngIf="formMode === 2" [ngClass]="requiredSaveUserClearance() ? 'btn-invalid-action' : '' "
                                                      _ngcontent-mjv-c22="" type="button" (click)="updateFinanceUserClearance()" class="btn btn-icon">
                                                        <span><i class="ri-file-edit-line"></i></span>
                                                    </button>
                                                    <button type="button" (click)="cancelSave()" class="btn btn-icon">
                                                      <span><i class="ri-close-line"></i></span>
                                                    </button>
                                                  </div>
                                                </div>
                                            </td>
                                        </tr>
    
                                        <tr *ngFor="let item of userClearanceData">
                                            <td>
                                                <div class="col-sm-10">
                                                    {{item.itemName}}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="col-sm-10">
                                                    {{item.status}}
                                                </div>
                                            </td>
                                            <td style="max-width: 100px" *ngIf="isFinanceAdmin">
                                                <div class="d-flex gap-2" style="width: 100px">
                                                    <button [ngClass]="formMode !== 0 ? 'd-none' : ''" _ngcontent-mjv-c22="" type="button" (click)="editUserClearanceById(item.id)" class="btn btn-icon">
                                                      <span><i class="ri-pencil-line"></i></span>
                                                    </button>
                                                    <button [ngClass]="formMode !== 0 ? 'd-none' : ''" _ngcontent-mjv-c22="" type="button" (click)="deleteUserClearanceById(item.id)" class="btn btn-icon">
                                                      <span><i class="ri-delete-bin-6-line"></i></span>
                                                    </button>
                                                  </div>
                                            </td>
                                        </tr>
    
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
