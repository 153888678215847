import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service'
import { JobPostingService } from 'src/app/_services/job-posting/job-posting.service';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.scss']
})
export class JobPostComponent implements OnInit {
  form: FormGroup;
  jobPostForm;
  tenantId: string;
  sampleGridFrameworkComponents: any;
  jobTitle: any;
  expectedExperience: any;
  expectedSalary: any;
  expectedQualifications: any;
  location: any;
  description: any;
  jobPostId: any;
  selectedFile: File;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private jobPostingService: JobPostingService,
    ) {
        this.route.queryParams.subscribe(params => { this.jobPostId = params['jobPostId'];})
      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };

      this.jobPostForm = this.formBuilder.group({
        applicantName: new FormControl('', Validators.required),
        email: new FormControl('', Validators.required),
        phoneNo: new FormControl(''),
        relevantExperience: new FormControl(''),
        description: new FormControl(''),
      });
    }

      // getter for easy access to form fields
    get f() {
        return this.jobPostForm.controls;
    }


  ngOnInit(): void {
    this.tenantId = this.localStorageService.get('tenantId');
    this.getJobDescription();
  }

  getJobDescription(){
    this.jobPostingService.getMeJd(this.jobPostId).subscribe((response: any) => {
      this.jobTitle = response.title;
      this.expectedExperience = response.expectedExperience;
      this.expectedSalary = response.expectedSalary;
      this.location = response.location;
      this.expectedQualifications = response.expectedQualifications;
      this.description = response.description;
    });
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    console.log('in onFileChanged, selectedFile: ', this.selectedFile);
  }

  backToPost(){
    this.router.navigate([this.tenantId + '/dashboard/hr/job-application']);
  }

  createJobApplication(){
    let data = {
      jobDescriptionId: this.jobPostId,
      applicantName: this.jobPostForm.get('applicantName').value,
      email: this.jobPostForm.get('email').value,
      phoneNo: this.jobPostForm.get('phoneNo').value,
      relevantExperience: this.jobPostForm.get('relevantExperience').value,
      description: this.jobPostForm.get('description').value,
    }
    this.jobPostingService.applyForJob(data).subscribe( (response: any) => {
      if(this.selectedFile){
        this.uploadApplicantsAttachment(response.id);
        console.log("attachemnt id: ", response.id);
      }
      this.backToPost();
      $('#closeModal').trigger('click');
      }
    );
  }

  uploadApplicantsAttachment(id){
    const attachment = new FormData();
      attachment.append('file', this.selectedFile, this.selectedFile.name)
      this.jobPostingService.applyForJobAttachments(id, attachment).subscribe((response: any) => {
        $('#closeCreateModal').hide();
       });
    // applyForJobAttachments
  }

}