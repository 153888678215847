import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { event } from 'jquery';


@Injectable({
  providedIn: 'root'
})
export class CompanyStructureService extends Subject<DataStateChangeEventArgs> {

  private httpClient: HttpClient;
  constructor(
    private handler: HttpBackend,
    private http: HttpClient,
    private storageService: LocalStorageService,
  ) {
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
    console.log("Error:", errorMessage);
    return throwError(errorMessage);
  }



  //Get All approvals by Id Get
  getCompanyStructure(userId): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userHierarchy?id=${userId}`).pipe(catchError(this.handleError));
  }

  getAllUserHierarchy(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/allUserHierarchy`).pipe(catchError(this.handleError));
  }
}
