import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { LocalStorageService } from "../../../_services/localStorage/local-storage.service";
import { AvatarComponent } from '../avatar/avatar.component';
import { UserService } from "../../../_services/user/user.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AvatarService } from "../../../_services/avatar/avatar.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  

  constructor() {}
    
  

  ngOnInit(): void {
    
  }

}
