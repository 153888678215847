import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { env } from 'process';


@Injectable({
  providedIn: 'root'
})
export class AttendanceService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);

  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
    console.log("Error:", errorMessage);
    return throwError(errorMessage);
  }

  // Create Attendance
  createAttendance(data: Object): Observable<Object> {
    console.log("In createemployeeAttendance method, employeeAttendance: ", data);
    return this.http.post(`${environment.apiUrl}/employeeAttendance`, data).pipe(catchError(this.handleError));
  }

  // Create Attendance to bypassNotification
  createAttendanceNotification(data: Object): Observable<Object> {
    console.log("In createemployeeAttendance method, employeeAttendance: ", data);
    return this.http.post(`${environment.apiUrl}/employeeAttendance?bypassNotification=true`, data).pipe(catchError(this.handleError));
  }

  // Get Attendance by Id Get
  getAttendance(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/employeeAttendance/${id}`).pipe(catchError(this.handleError));
  }

  //Delete Attendance by Id Delete
  deleteAttendance(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/employeeAttendance/${id}`).pipe(catchError(this.handleError));
  }

  //Update Attendance by Id Put
  updateAttendance(user: Object, id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/employeeAttendance/${id}`, user).pipe(catchError(this.handleError));
  }

  //Get All Attendance by Id Get
  getAllAttendance(employeeId): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/employeeAttendance?employeeId=${employeeId}`).pipe(catchError(this.handleError));
  }

  //Get All Attendance Summary by Id Get
  getAttendanceSummary(employeeId, startDate, endDate): Observable<Object> {
    if (employeeId) {
      return this.http.get(`${environment.apiUrl}/employeeAttendanceSummary?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
    }
    else {
      return this.http.get(`${environment.apiUrl}/employeeAttendanceSummary?startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
    }
  }

  //Get Attendance Report
  getAttendanceReport(month, year): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/employeeAttendanceReport?month=${month}&year=${year}` ,{ responseType: 'blob'}).pipe(catchError(this.handleError));
  }

  //Get salary metadata
  getSalarymetadata(employeeId): Observable<Object> {
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    return this.http.get(`${environment.apiUrl}/salaryMetadata?userId=${employeeId}&month=${currentMonth}&year=${currentYear}`).pipe(catchError(this.handleError));
  }

  getSalarymetadataForcalender(employeeId, month, year): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/salaryMetadata?userId=${employeeId}&month=${month}&year=${year}`).pipe(catchError(this.handleError));
  }

  getAttendanceById(employeeId): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/employeeAttendanceSummary?employeeId=${employeeId}`).pipe(catchError(this.handleError));
  }

  getAttendanceForCalender(employeeId, startDate, endDate ): Observable<Object> {
    //  Create a new date object
    return this.http.get(`${environment.apiUrl}/employeeAttendanceSummary?employeeId=${employeeId}&startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
  }

  // create menual leaves
  createManualLeaves(payload): Observable<any> {
    return this.http.post(`${environment.apiUrl}/manualLeaves`, payload);
  }

  //Shift Scheduler

  //Create shift schedule
  createUserShift(user: Object): Observable<Object> {
    console.log("In createSoftwares method, UserShifts: ", user);
    return this.http.post(`${environment.apiUrl}/userShifts`, user).pipe(catchError(this.handleError));
  }

   // Get  shift schedule by Id Get
   getUserShifts(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userShifts/${id}`).pipe(catchError(this.handleError));
  }

  //Delete shift schedule by Id Delete
  deleteUserShift(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/userShifts/${id}`).pipe(catchError(this.handleError));
  }

   //Update shift schedule by Id Put
   updateUserShift(user: Object, id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/userShifts/${id}`, user).pipe(catchError(this.handleError));
  }

   //Get All shift schedule by Id Get
   getAllUserShifts(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userShifts`).pipe(catchError(this.handleError));
  }

  //get all shift
  getShiftDetails(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/shifts`).pipe(catchError(this.handleError));
  }

  //get all shift
  getShiftDetailsByName(shiftName: String): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/shiftByName?shiftName=${shiftName}`).pipe(catchError(this.handleError));
  }

  //create shift
  createShiftDetail(shiftData: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/shifts`, shiftData).pipe(catchError(this.handleError));
  }

  //update shift
  updateShiftDetail(shiftData: Object, id: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/shifts/${id}`, shiftData).pipe(catchError(this.handleError));
  }

   //Get All hrlookup Summary by Id Get
   gethrlookup(userId, startDate, endDate): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/hrlookup?&startDate=${startDate}&endDate=${endDate}&userId=${userId}`).pipe(catchError(this.handleError)); 
  }

  //create shift
  createShortLeave(leaveData: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/shortLeaves`, leaveData).pipe(catchError(this.handleError));
  }
}
