import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
 import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { UserService } from 'src/app/_services/user/user.service';
import { ExitProcessService } from 'src/app/_services/exit-process/exit-prosess.service';

@Component({
  selector: 'app-exit-process',
  templateUrl: './exit-process.component.html',
  styleUrls: ['./exit-process.component.scss']
})
export class ExitProcessComponent implements OnInit {
  tenantId: string;
  currentUser: any;
  userCreationForm
  defaultColDef:any;
  rowSelection:any
  dataSource: any;
  public columnDefs: ColDef[];
  sampleGridFrameworkComponents: any;
  private api: GridApi;
  private columnApi: ColumnApi;
  selectedRow: any;
  rowData: any;
  isHRAdmin: boolean;
  isManager: boolean;
  resignationStatus: any;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private snackBar: MatSnackBar,
    private exitProcessService: ExitProcessService,
    private userService: UserService,
  ) { 
    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
      this.columnDefs = this.createColumnDefs();  

      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };
    }
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isHRAdmin = this.currentUser.admin || this.currentUser.roles.includes('RoleHrmAdministrator') ;
    this.getUserSeparation();
  }

  getUserSeparation(){
    if(this.isHRAdmin){
      this.exitProcessService.getAllUserSeparations().subscribe({
        next : (value) => {
          this.rowData = value;
        },
        error(err) {
          this.openSnackBar('ERROR: getting resignation' + err)
        },
        complete() {
        }
      });
    }
    else{
      this.exitProcessService.getUserSeparationsByUserId(this.currentUser.id).subscribe({
        next : (value) => {
          this.rowData = value;
        },
        error(err) {
          this.openSnackBar('ERROR: getting resignation' + err)
        },
        complete() {
        }
      });
    }
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
  onGridReady(params): void {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: 'Employee',
        valueGetter: function(params){
          let user = params.data.userId + " . " + params.data.userName
            return user;
        },
        filter: true,
        editable: false,
        sortable: true,
        cellRenderer: this.createHyperLink.bind(this),
      },
      {
        headerName: 'Employee Id',
        field: 'localId',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Designation',
        field: 'currentDesignation',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Requested Date',
        field: 'resignationDate',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Last Working Date',
        field: 'lastWorkingDate',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Satus',
        field: 'status',
        filter: true,
        editable: false,
        sortable: true,
      },
    ]
  }

  createHyperLink(params): any {
    if (!params.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a href="${params.value}" > ${params.value} </a> `;
    this.userService.getUser(params.value.userId).subscribe({
      next: (response) => {
        if(this.currentUser.id === response['supervisorId']){
          this.isManager = true;
        }
      }
    });
    if(params.data.status === 'Accepted'){
      spanElement.addEventListener('click', ($event) => {
        $event.preventDefault();
        this.router.navigate([this.tenantId + '/dashboard/hr/exit-formalities/exit-form'], { queryParams: { resignationId: params.data.id } });
      });
      return spanElement;
    }
    else{
      spanElement.addEventListener('click', ($event) => {
        $event.preventDefault();
        this.openSnackBar('This resignation is not accepted, please contact your HR Admin OR Manager!!!');
      });
      return spanElement;
    }
  }

  // Pop-up message
  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }

  ExitResignationForm(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-resignation-form' ]);
  }

}
