import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormControl,FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { AvatarService } from 'src/app/_services/avatar/avatar.service';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { UserService } from 'src/app/_services/user/user.service';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  usersForm;
  // row data and column definitions
  rowData: any;
  public columnDefs: ColDef[];
  modeAction: number = 1; //1 - create // 2 - update
  // gridApi and columnApi
  private api: GridApi;
  private columnApi: ColumnApi;
  defaultColDef: any;
  rowSelection: any;
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  // modalService: any;
  currentUser: any;
  jsonData: any;
  emailPattern = '^[a-z0-9._%+-]+\\.[a-z]{2,4}$';
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  currentTab: number = 1;
  marriagedate: any;
  userDropdownList = [];
  getUserName: any;
  deleteConfirmationMsg = 'Are you sure you want to delete';
  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private storageService: LocalStorageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private avatar: AvatarService,
    private allocationService: AllocationService,
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent,
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required],
    });
    this.usersForm = new FormGroup({
      id: new FormControl(''),
      version: new FormControl(''),
      userId: new FormControl(''),
      password: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      displayName: new FormControl(''),
      department: new FormControl(''),
      emailId: new FormControl(''),
      phoneNo: new FormControl(''),
      location: new FormControl(''),
      roles: new FormControl(''),
      image: new FormControl(''),
      branch: new FormControl(''),
      groups: new FormControl(''),
      costCenter: new FormControl(''),
      localId: new FormControl(''),
      employeeCategory: new FormControl(''),
      level: new FormControl(''),
      joinDate: new FormControl(''),
      quitDate: new FormControl(''),
      permanentOrContractor: new FormControl(''),
      localOrExpat: new FormControl(''),
      level2: new FormControl(''),
      resourceType: new FormControl(''),
      onboardStatus: new FormControl(''),
      supervisorId: new FormControl(''),
      dashboardIndex: new FormControl(''),
      birthDate: new FormControl(''),
      hrId: new FormControl(''),
      hrName: new FormControl(''),
      supervisonName: new FormControl(''),
      adhaarNo: new FormControl(''),
      bankName: new FormControl(''),
      bankAccountNo: new FormControl(''),
      panNo: new FormControl(''),
      uinNo: new FormControl(''),
      archived: new FormControl(''),
      admin: new FormControl(''),
      joinWeek: new FormControl(''),
      quitWeek: new FormControl(''),
      marriageDate: new FormControl(''),
      marriageStatus: new FormControl(''),
      userType: new FormControl(''),
      alternateEmailId: new FormControl(''),
      country: new FormControl(''),
      bankIfscCode: new FormControl(''),
      address: new FormControl(''),
      gender: new FormControl(''),
      bloodGroup: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.getUsers();
    this.getUserList();
  }

  getUsers() {
    this.userService.getUserDetailsList().subscribe((response: any) => {
      this.rowData = response;
      console.log('response: ', response);
    });
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
  onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api);
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  // create column definitions
  private createColumnDefs() {
    return [
    {
      headerName: 'Version',
      field: 'version',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
      
    },
    {
      headerName: 'User Id',
      field: 'userId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Password',
      field: 'password',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      // valueGetter: (params) => {
      //   params.data.firstName . params.data.lastName
      // },
      filter: true,
      editable: false,
      sortable: true,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
      
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      filter: true,
      editable: false,
      sortable: true,
    },
    {
      headerName: 'Display Name',
      field: 'displayName',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Department',
      field: 'department',
      filter: true,
      editable: false,
      sortable: true,
    },
    {
      headerName: 'Email Id',
      field: 'emailId',
      filter: true,
      editable: false,
      sortable: true,
    },
    {
      headerName: 'Phone No',
      field: 'phoneNo',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Location',
      field: 'location',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Roles',
      field: 'roles',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Image',
      field: 'image',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Branch',
      field: 'branch',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Groups',
      field: 'groups',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Cost Center',
      field: 'costCenter',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Local Id',
      field: 'localId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Employee Category',
      field: 'employeeCategory',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Level',
      field: 'level',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Join Date',
      field: 'joinDate',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Quit Date',
      field: 'quitDate',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Permanent Or Contractor',
      field: 'permanentOrContractor',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Local Or Expat',
      field: 'localOrExpat',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'level2',
      field: 'level2',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Resource Type',
      field: 'resourceType',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Onboard Status',
      field: 'onboardStatus',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Supervisor Id',
      field: 'supervisorId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Dashboard Index',
      field: 'dashboardIndex',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Birth Date',
      field: 'birthDate',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Hr Id',
      field: 'hrId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Hr Name',
      field: 'hrName',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Supervison Name',
      field: 'supervisonName',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Adhaar No',
      field: 'adhaarNo',
      filter: true,
      editable: false,
      sortable: true,
      
    },
    {
      headerName: 'Bank Name',
      field: 'bankName',
      filter: true,
      editable: false,
      sortable: true,
      
    },
    {
      headerName: 'Bank Account No',
      field: 'bankAccountNo',
      filter: true,
      editable: false,
      sortable: true,
      
    },
    {
      headerName: 'Pan No',
      field: 'panNo',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Uin No',
      field: 'uinNo',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Archived',
      field: 'archived',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Career Start Date',
      field: 'careerStartDate',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Total Experience',
      field: 'totalExperience',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Current Experience',
      field: 'currentExperience',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Admin',
      field: 'admin',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    }
  ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  editOpenModal() {
    this.modeAction = 2;
    this.usersForm.patchValue(this.selectedCheckboxData[0]);
  }

  updateUser() {
    console.log("update user",this.usersForm.value)
    //  console.log("in update, id param: ", id);
    // let details = JSON.stringify(this.usersForm.value)
    this.userService.updateUser(this.selectedCheckboxData[0].id,this.usersForm.value).subscribe(response => {
       console.log("after update: ", response);
      this.getUsers();
      $('#closeUserEditModal').trigger("click");
    });
  }

  toggle(){
    $('.offcanvas').toggleClass('show').css('visibility','visible');
    $('body').css("overflow", "hidden");
    $('body').css("padding-right", "17px");
  }

  showInput(num:number) {
    this.currentTab = num;
  }

  checkmarritalstatus(marritalstatvalue){
    this.marriagedate = marritalstatvalue;
    console.log("checkmarritalstatus", marritalstatvalue);
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          ` ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

}
