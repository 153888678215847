import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CapacityPlanningService } from 'src/app/_services/capacity-planning/capacity-planning.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-capacity-planning',
  templateUrl: './capacity-planning.component.html',
  styleUrls: ['./capacity-planning.component.scss']
})
export class CapacityPlanningComponent implements OnInit {
  capacityPlanningForm
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
  defaultColDef:any;
  rowSelection:any
  tenantId: string;
  jsonData: any;
  gridApi: any;
  gridColumnApi: any;
  selectedRow: any;
  form: FormGroup;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'

  constructor(
    private capacityPlanningService: CapacityPlanningService, 
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService, 
    private router: Router, private modalService: NgbModal
    ) {
        this.defaultColDef = {
          flex: 1,
          minWidth: 100,
          resizable: true,
        };
        this.rowSelection = 'multiple';
        this.columnDefs = this.createColumnDefs();  

        this.sampleGridFrameworkComponents = {
          actionRowRendererComponent: ActionItemsGridRowRendererComponent
        };

        this.form = this.formBuilder.group({
          file: ['', Validators.required]
        });
    
        this.capacityPlanningForm = this.formBuilder.group({
          id: new FormControl(''),
          capacityPlanningName: new FormControl('', [Validators.required]),
          startDate: new FormControl(null, [Validators.required]),
          endDate: new FormControl(null, [Validators.required]),
        })
  }

    ngOnInit(): void {
      this.tenantId = this.storageService.get('tenantId');      
      this.getCapacityPlanning();
    }


      //Get The Capacity Planning List
    getCapacityPlanning() {
      this.capacityPlanningService.getCapacityPlanningData().subscribe((response: any) => {
        console.log("Get Capacity Planning Response: ", response);
        this.rowData = response;
      });
    }
  
  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }  

   // create column definitions  
   private createColumnDefs() {
    return [
   
    {
      headerName: "Capacity Planning Name",
      field: "capacityPlanningName",
      filter: true,
      editable: false,
      sortable: true,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
    },
    {
      headerName: "Start Date",
      field: "startDate",
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: "End Date",
      field: "endDate",
      filter: true,
      editable: false,
      sortable: true
    }]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }


  onCellClicked(e: any) {
    console.log('I AM IN THE ONCELLCLICK EVENT')
    console.log('click', e.data.id)
    //{ queryParams: { id: idValue } }
    // this.router.navigate([this.tenantId + '/dashboard/project/capacity-planning-detail', { id: e.data.id}]);
    this.router.navigate([this.tenantId + '/dashboard/project/capacity-planning-detail'], { queryParams: { id: e.data.id } });
  }

  

  createCapacityPlanning() {
    let details = JSON.stringify(this.capacityPlanningForm.value);
    console.log("Capacity Planning Response Saved: ", details);
    this.capacityPlanningService.createCapacityPlanningData(details).subscribe((response: any) => {
      console.log("Capacity Planning Response: ", response);

       // update grid after creating new lead & close the modal
       this.api.applyTransaction({ add: [response] });
       $('#closeCreateModal').trigger("click");
     });
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }     
  }

  private deleteGridRow(params: any) {
    this.capacityPlanningService.deleteCPbyID(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }
  editOpenModal() {
    this.modeAction = 2;
    this.capacityPlanningForm.patchValue(this.selectedCheckboxData[0])
  }
 
  createOpenModal() {
    this.modeAction = 1;
    this.capacityPlanningForm.reset();
  }

  updateCapacityPlanning() {
    this.capacityPlanningService.updateCapacityPlanning(this.capacityPlanningForm.value, this.selectedCheckboxData[0].id).subscribe(res => {
      this.getCapacityPlanning();
      this.selectedCheckboxData = []
      $('#closeCreateModal').trigger("click");
    })
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].capacityPlanningName}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].capacityPlanningName}`;
      else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].capacityPlanningName}`;
    }
  }

  deleteCPbyID() {
    this.capacityPlanningService.deleteCPbyID(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getCapacityPlanning();
      this.selectedCheckboxData = []
      $('#closeModalDeleteLead').trigger("click");
    });
  }

     // getter for easy access to form fields
     get f() {
      return this.capacityPlanningForm.controls;
    }
  

  // to open edit modal
  openEdit(editCapacity, item) {
    this.selectedRow = item;
    this.capacityPlanningForm.patchValue(item);
    this.modalService.open(editCapacity);
  }

}
