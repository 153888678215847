<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Quote Standard Default Values </h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <button type="button"  data-bs-toggle="modal" data-bs-target="#opportunity" class="btn btn-icon"><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="row">
                            <div class="col-sm-8">
                                <form [formGroup]="quoteParameterForm">
                                    <div class="row justify-content-between">
                                        <div class="col-sm-6 p-3">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="annualInflationRate" type="text" required>
                                                    <label for="annualInflationRate" class="floating-label">Annual Inflation Rate :</label>
                                                    <i class="ri-money-dollar-circle-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardEbit" type="text" required>
                                                    <label for="standardEbit" class="floating-label">Standard Ebit :</label>
                                                    <i class="ri-exchange-funds-fill"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardRiskMargin" type="text" required>
                                                    <label for="standardRiskMargin" class="floating-label">Risk Margin :</label>
                                                    <i class="ri-exchange-box-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input  formControlName="standardHandlingSurcharge" type="text" required>
                                                    <label for="standardHandlingSurcharge" class="floating-label">Handling Surcharge :</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardMonthlyWorkingHours" type="text" required>
                                                    <label for="standardMonthlyWorkingHours" class="floating-label">Monthly Working Hours :</label>
                                                    <i class="ri-history-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardSubContractorWorkHourPerMonth"  type="text"  required>
                                                    <label for="standardSubContractorWorkHourPerMonth" class="floating-label">Subcontract Work Hr/Month :</label>
                                                    <i class="ri-calendar-check-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardvat" type="text" required>
                                                    <label for="standardvat" class="floating-label">VAT % :</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 p-3">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input  formControlName="standardThirdPartyWorkHourPerMonth" type="text"required>
                                                    <label for="standardThirdPartyWorkHourPerMonth" class="floating-label">3rd Party work Hr/month :</label>
                                                    <i class="ri-calendar-event-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardSgst" type="text" required>
                                                    <label for="standardSgst" class="floating-label">SGST% :</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardCustomerTarget" type="text" required>
                                                    <label for="standardCustomerTarget" class="floating-label">Customer Target:</label>
                                                    <i class="ri-focus-3-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardThirdPartyDiscount" type="text" required>
                                                    <label for="standardThirdPartyDiscount" class="floating-label">3rd Party Discount :</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input  formControlName="standardCgst" type="text" required>
                                                    <label for="standardCgst" class="floating-label">CGST :</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardThirdPartySurcharge" type="text" required>
                                                    <label for="standardThirdPartySurcharge" class="floating-label">3rd Party Surcharge :</label>
                                                    <i class="ri-money-dollar-circle-line"></i>
                                                </div>
                                            </div>
                                            <div class="input">
                                                <div class="input-item">
                                                    <input formControlName="standardSubcontractDiscount" type="text" required>
                                                    <label for="standardSubcontractDiscount" class="floating-label">Subcontract Discount :</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 text-end p-3">
                                            <button class="btn" (click)=saveQuoteParameter() >Save</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
  
<!-- Create new Opportunity -->
<div class="modal fade" id="opportunity" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Custom standard value</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input type="text" required>
                            <label class="floating-label">Name</label>
                            <i class="ri-medal-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input type="text" required>
                            <label class="floating-label">Value</label>
                            <i class="ri-file-user-line"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary">Create</button>
                </div>
            </form>
        </div>
    </div>
</div>
