import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { ItDashboardService } from 'src/app/_services/it-dashboard/it-dashboard.service';
import { Browser } from '@syncfusion/ej2-base';


@Component({
  selector: 'app-hr-dashboard',
  templateUrl: './hr-dashboard.component.html',
  styleUrls: ['./hr-dashboard.component.scss']
})
export class HrDashboardComponent implements OnInit, AfterViewInit {
  public data: object[];
  public title: string;
  public ageData: object[];
  public departmentData: object[];
  public designationData: object[];
  public width: Object = Browser.isDevice ? '100%' : '90%';
  public locationData: object[];
  public datalabel?: Object;
  public data1?: Object[];
  public data2?: Object[];
  public data3?: Object[];
  public primaryXAxis: Object
  public legendSettings: Object = { visible: true };
  public columnMarker: Object = { dataLabel: { visible: true, position: 'Top' } };
  public lineMarker: Object = { visible: true, dataLabel: { visible: true } };

  public tooltip: Object = {
    mode: 'label',
    enable: true
  }
  public primaryYAxis: Object;
  constructor(
    private _itDashboardService: ItDashboardService
  ) {
    this.ageData = [];
    this.data = [];
    this.data1 = [];
    this.data2 = [];

  }

  ngOnInit(): void {
    this.datalabel = { visible: true, name: 'text', position: 'Outside' };
    this.getHrDashboard();
    this.getHrTrends();
    this.primaryXAxis = {
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      minorTickLines: { width: 0 },
      interval: 1,
      lineStyle: { width: 0 },
      valueType: "Category"
  };
  this.primaryYAxis = {
      title: "HR Trends",
  };
}
  

  ngAfterViewInit(): void {
    // this.getHrDashboard();
  }
  /**
 * get it dashboard data
 */
  getHrDashboard() {
    this._itDashboardService.getHrDashboard().subscribe({
      next: (value: any) => {
        let age = value['ages'];
        let departments = value['departments'];
        let designations = value['designations'];
        let locations = value['locations'];
        let agesData = [];
        let departmenData = [];
        let designationData = [];
        let locationData = [];
        for (let key of Object.keys(age)) {agesData.push({ name: key, value: age[key] })}
        for (let key of Object.keys(departments)) {departmenData.push({ name: key, value: departments[key] })}
        for (let key of Object.keys(designations)) {designationData.push({ name: key, value: designations[key] })}
        for (let key of Object.keys(locations)) {locationData.push({ name: key, value: locations[key] })}
        this.ageData = agesData;
        this.departmentData = departmenData;
        this.designationData = designationData;
        this.locationData = locationData;
      },
      error: (err) => {

      },
      complete: () => {

      },
    })
  }

    /**
 * get HR trends
 */
    getHrTrends() {
      this._itDashboardService.getHrTrends().subscribe({
        next: (value: any) => {
          let data = value['employeeAdditionTrend'];
          let data1 = value['employeeAttritionTrend'];
          let data2 = value['employeeHeadcount'];
          let dataValues = [];
          let data1Values = [];
          let data2Values = [];
          for (let key of Object.keys(data)) {dataValues.push({ x: key, y: data[key] })};
          for (let key of Object.keys(data1)) {data1Values.push({ x: key, y: data1[key] })};
          for (let key of Object.keys(data2)) {data2Values.push({ x: key, y: data2[key] })};
          this.data = dataValues;
          this.data1 = data1Values;
          this.data2 = data2Values;
        },
        error: (err) => {
  
        },
        complete: () => {
  
        },
      })
    }
}
