import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ExitProcessService } from 'src/app/_services/exit-process/exit-prosess.service';
import { DatePipe } from '@angular/common';
import { error } from 'console';

@Component({
  selector: 'app-exit-resignation-form',
  templateUrl: './exit-resignation-form.component.html',
  styleUrls: ['./exit-resignation-form.component.scss']
})
export class ExitResignationFormComponent implements OnInit {
  
  exitProcessForm
  form: FormGroup;
  tenantId: string;
  userDropdownList = [];
  currentUser: any;
  date: Date;
  TodayDate: string;
  resignationId: any;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private exitProcessService: ExitProcessService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
  ) {
    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });
    
    this.exitProcessForm = this.formBuilder.group({
      userId: new FormControl({value: '', disabled: true}),
      userName: new FormControl({value: '', disabled: true}),
      employeeId: new FormControl({value: '', disabled: true}),
      resignationDate: new FormControl({value: '', disabled: true}),
      lastWorkingDate: new FormControl({value: '', disabled: true}),
      alternateEmailId: new FormControl('', Validators.required),
      alternateContactNo: new FormControl('', Validators.required),
      reason: new FormControl('', Validators.required),
      comment: new FormControl(''),
      designation: new FormControl({value: '', disabled: true})
    });
   }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;

    this.exitProcessForm.value.userId = this.currentUser.id + " . " + this.currentUser.firstName + " " + this.currentUser.lastName;
    this.exitProcessForm.value.employeeId = this.currentUser.localId;
    this.exitProcessForm.value.designation = this.currentUser.currentDesignation;
    this.exitProcessForm.value.resignationDate = this.TodayDate;
    this.exitProcessForm.patchValue(this.exitProcessForm.value);

    this.resignationId = this.route.snapshot.paramMap.get('id');
    if (this.resignationId) {
      this.getUserSeparationsById(this.resignationId);
    }
  }

  backToExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-process' ]);
  }

  getUserSeparationsById(id){
    this.exitProcessService.getUserSeparationsById(id).subscribe({
      next : (value) => {
        this.exitProcessForm.patchValue({
          resignationDate: value['resignationDate'],
          lastWorkingDate: value['lastWorkingDate'],
          alternateEmailId: value['alternateEmailId'],
          alternateContactNo: value['alternateContactNo'],
          reason: value['reason'],
          comment: value['comment'],
        });
      },
      error(err) {
        this.openSnackBar('ERROR: getting resignation' + err)
      },
      complete() {
      }
    })
  }

  createResignationForm(){
    let details = {
      userId: this.exitProcessForm.get('userId').value.split(' . ')[0],
      userName: this.exitProcessForm.get('userId').value.split(' . ')[1],
      resignationDate: this.TodayDate,
      lastWorkingDate: this.exitProcessForm.get('lastWorkingDate').value,
      alternateEmailId: this.exitProcessForm.get('alternateEmailId').value,
      alternateContactNo: this.exitProcessForm.get('alternateContactNo').value,
      reason: this.exitProcessForm.get('reason').value,
      comment: this.exitProcessForm.get('comment').value,
      status: 'Submitted'
    }
    this.exitProcessService.createUserSeparations(details).subscribe({
      next : (value) => {
        this.backToExit();
        $('#closeModal').trigger('click');
      },
      error(err) {
        this.openSnackBar('ERROR: cannot create resignation: ' + err);
        $('#closeModal').trigger('click');
      },
      complete() {
        this.openSnackBar('Resignation Applied successfully!!!');
        $('#closeModal').trigger('click');
      }
    });
  }

}