import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CostService } from 'src/app/_services/cost/cost.service';

@Component({
  selector: 'app-software-rates',
  templateUrl: './software-rates.component.html',
  styleUrls: ['./software-rates.component.scss']
})
export class SoftwareRatesComponent implements OnInit {
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));

  jsonData: any[];
  softwareRatesFormGroup: FormGroup = new FormGroup({
    software: new FormControl(null),
    hourlyCostRate: new FormControl(null)
  })
  isShowInputSoftwareRates: boolean = false;

  constructor(
    private contactService: CostService
  ) { }

  ngOnInit(): void {
    this.getSoftwareRates();
  }
  
  showInputSoftwareRates() {
    this.isShowInputSoftwareRates = !this.isShowInputSoftwareRates;
  }
  getSoftwareRates() {
    this.contactService.getSoftwareRatesOpportuniry(this.quoteObject.id).subscribe((response:any) => {
      this.jsonData = response;
    }, error => {
      console.log('ERROR - cannot get software rates: ' + error);
    });
  }

  deleteSoftwareRates(id) {
    this.contactService.deleteSoftwareRatesOpportunity(id, this.opportunityObject.id, this.quoteObject.id).subscribe(response => {
      this.getSoftwareRates();
    }, error => {
      console.log('ERROR - cannot delete software rates: ' + error);
    })
  }

  addSoftwareRates() {
    let addsrdata = {
      software: this.softwareRatesFormGroup.get('software').value,
      hourlyCostRate: this.softwareRatesFormGroup.get('hourlyCostRate').value
    }
    
    this.contactService.addSoftwareRatesOpportunity(addsrdata, this.opportunityObject.id, this.quoteObject.id).subscribe((response:any) => {
      this.getSoftwareRates();
      this.softwareRatesFormGroup.patchValue({
        software: '',
        hourlyCostRate: '',
      });
      this.isShowInputSoftwareRates= false;
    }, error => {
      console.log('ERROR - cannot add software rates: ' + error);
    })
  }
}
