
<main class="main-dashboard">
    <article class="main-canvas"> 
      <section class="canvas-container">
        <section class="dashboard-items">
            <form [formGroup]="taxmetadataform">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button (click)="backToMetaData()">
                                        <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Tax MetaData Application</h3>
                            </div>   
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="canvas-data">
                                <div class="row">
                                    <!-- <div class="col-sm-6 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="id"
                                                required
                                              />
                                              <label class="floating-label"
                                                 >Id</label
                                               >
                                               <i class="ri-profile-line"></i>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                               
                                              <select formControlName="financialYear" >
                                                <!-- <option value="" disabled selected>Select Financial Year </option>                                    -->
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                </select>
                                                <label for="financialYear" class="floating-label floating-label-special">Financial Year</label>
                                               <i class="ri-bank-fill"></i>
                                            </div>
                                        </div>
                                        <mat-error *ngIf="f.financialYear.touched && f.financialYear.invalid" class="alert alert-danger">
                                            <mat-error *ngIf="f.financialYear.errors.required">Financial Year is required</mat-error>
                                        </mat-error>
                                    </div>
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="country"
                                                required/>
                                              <label class="floating-label">Country</label>
                                               <i class="ri-global-fill"></i>
                                            </div>
                                            <mat-error *ngIf="f.country.touched && f.country.invalid" class="alert alert-danger">
                                                <mat-error *ngIf="f.country.errors.required">Country is required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="regimeNo">
                                                  <!-- <option value="" disabled selected>Select Tax Regime </option> -->
                                                  <option value="Old">Old</option>
                                                  <option value="New">New</option>
                                                </select>
                                                <label class="loating-label floating-label-special">Tax Regime</label>
                                               <i class="ri-numbers-line"></i>
                                            </div>
                                            <!-- <mat-error *ngIf="f.regimeNo.touched && f.regimeNo.invalid" class="alert alert-danger">
                                                <mat-error *ngIf="f.regimeNo.errors.required">Regime No. is required</mat-error>
                                            </mat-error> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="componentName"
                                                required/>
                                              <label class="floating-label">Component Name</label>
                                               <i class="ri-calendar-todo-line"></i>
                                            </div>
                                            <mat-error *ngIf="f.componentName.touched && f.componentName.invalid" class="alert alert-danger">
                                                <mat-error *ngIf="f.componentName.errors.required">Component Name is required</mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="componentUpperLimitValue"/>
                                              <label class="floating-label">Component UpperLimit Value</label>
                                               <i class="ri-calendar-todo-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="componentValue"
                                                required/>
                                              <label class="floating-label">Component Value</label>
                                               <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="percent">
                                                    <!-- <option value="" disabled selected>
                                                        Select percent 
                                                      </option> -->
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </select>
                                                <label class="floating-label floating-label-special">Percent</label>
                                               <i class="ri-percent-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-3">
                                        <div class="row justify-content-end " *ngIf="this.taxId">
                                            <div class="col-sm-6 px-3 py-1" >
                                                <button type="button" class="btn" (click)="updateTaxMetadata()" [disabled]="!taxmetadataform.valid">
                                                    <div>Update</div>
                                                </button>
                                            </div>
                                            <div class="col-sm-4 px-3 py-1">
                                                <button type="button" id="" class="btn" data-bs-dismiss="modal" (click)="backToMetaData()">
                                                    <div>Cancel</div>
                                              </button>
                                            </div>
                                        </div>
                                        <div class="row justify-content-end " *ngIf="!this.taxId">
                                            <div class="col-sm-6 px-3 py-1" >
                                                <button type="button" id="" class="btn" (click)="createTaxMetadata()" [disabled]="!taxmetadataform.valid">
                                                    <div>Create</div>
                                                </button>
                                            </div>
                                            <div class="col-sm-4 px-3 py-1">
                                                <button type="button" id="" class="btn" data-bs-dismiss="modal" (click)="backToMetaData()">
                                                    <div>Cancel</div>
                                              </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>   
      </section>
    </article>
</main>
