import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CostService } from 'src/app/_services/cost/cost.service';

@Component({
  selector: 'app-costcenter-level-rates',
  templateUrl: './costcenter-level-rates.component.html',
  styleUrls: ['./costcenter-level-rates.component.scss']
})
export class CostcenterLevelRatesComponent implements OnInit {
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  jsonData: any;
  CostCenter: FormGroup;
  costCenterLevelRatesForm: FormGroup = new FormGroup({
    location: new FormControl(''),
    costcenter: new FormControl(''),
    level: new FormControl(''),
    hourlyCostRate: new FormControl(null)
  });
  isShowInputCclr: boolean = false;

  constructor(
    private contactService: CostService
  ) { }

  ngOnInit(): void {
    this.getCostCenterLevelRates()
  }

  showInputCclr() {
    this.isShowInputCclr = !this.isShowInputCclr;
  }

  getCostCenterLevelRates() {
    this.contactService.getCostCenterLevelRatesOpportunity(this.quoteObject.id).subscribe((response:any) => {
      this.jsonData = response;
      console.log('qq', this.jsonData)
    }, error => {
      console.log('ERROR - cannot get cost center level rates: ' + error)
    });
  }

  addCostCenterLevelRates() {
    let addcclrdata = {
      location: this.costCenterLevelRatesForm.get('location').value,
      costcenter: this.costCenterLevelRatesForm.get('costcenter').value,
      level: this.costCenterLevelRatesForm.get('level').value,
      hourlyCostRate: this.costCenterLevelRatesForm.get('hourlyCostRate').value
    }
    this.contactService.addCostCenterLevelRatesOpportunity(addcclrdata, this.opportunityObject.id, this.quoteObject.id).subscribe((response:any) => {
      this.getCostCenterLevelRates();
      this.costCenterLevelRatesForm.patchValue({
        location: '',
        costcenter: '',
        level: '',
        hourlyCostRate: ''
      });
      this.isShowInputCclr = false
    }, error => {
      console.log('ERROR - cannot add cost center level rates: ' + error)
    });
  }

  deleteCostCenterLevelRates(id) {
    this.contactService.deleteCostCenterLevelRatesOpportunityu(id, this.opportunityObject.id, this.quoteObject.id).subscribe((response:any) => {
      this.getCostCenterLevelRates();    
    }, error => {
      console.log('ERROR - cannot delete cost center level rates: ' + error)
    });
  }
}
