<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="row">
          <div class="col-sm-9">
            <div class="canvas-item top-sticky">
              <div class="canvas-data">
                <div class="capacity-collapse-graphs">
                  <div class="canvas-header row justify-content-between align-items-center">
                    <div class="title col-sm-6">
                      <div class="d-flex align-items-center">
                        <div class="edit">
                          <button (click)="backToCapacityTable()">
                            <span><i class="ri-arrow-left-line"></i></span>
                          </button>
                        </div>
                        <h3 class="px-3">Capacity planning details</h3>
                      </div>
                    </div>
                  </div>
                  <ng-template [ngIf]="CPJsonData">
                    <div class="row pt-3 pb-3">
                      <div class="col-sm-4">
                        <div class="input">
                          <div class="input-item">
                            <input type="text" value="{{CPJsonData.capacityPlanningName}}" required>
                            <label class="floating-label">Capacity planning name</label>
                            <i class="ri-shield-user-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="input">
                          <div class="input-item">
                            <input type="text" value="{{CPJsonData.startDate}}">
                            <label class="floating-label">Start date</label>
                            <i class="ri-calendar-check-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="input">
                          <div class="input-item">
                            <input type="text" value="{{CPJsonData.endDate}}" >
                            <label class="floating-label">End date</label>
                            <i class="ri-calendar-check-line"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <br />
                  <div>
                    <br />
                    <ng-template [ngIf]="GraphCount > 0" [ngIfElse]="displayerror">
                      <div class="graph-container">
                        <!--<div>
                          <button ejs-button cssClass="e-info" (click)='export()' style="text-transform:none !important" id="togglebtn print">EXPORT</button>
                        </div>-->

                        <ejs-chart #chart [width]='width' align='center' id='chartcontainer' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)' height='100%' style="height: inherit !important">
                          <e-series-collection>
                            <e-series [dataSource]='lineGraph' type='Line' xName='x' yName='y' [marker]="lineMarker"> </e-series>
                            <ng-container *ngFor="let i of [].constructor(GraphCount); let j = index">
                              <e-series [dataSource]="GraphData[j]" type="StackingColumn" [name]="GraphDataName[j]" xName='x' yName='y'>
                              </e-series>
                            </ng-container>
                          </e-series-collection>
                        </ejs-chart>
                      </div>
                    </ng-template>

                    <ng-template #displayerror>
                      <div><h5>Graph Data not available</h5></div>
                    </ng-template>

                  </div>
                </div>

              </div>


            </div>


          </div>
          <div class="col-sm-3">
            <form novalidate [formGroup]="formGroup">
              <div class="canvas-item">
                <div class="canvas-data">

                  <div class="capacity-collapese">
                    <div class="edit" id="capacity-opener">
                      <button type="button" data-toggle="collapse" data-target="#general-details">
                        <span>
                          <i class="ri-arrow-right-s-line"></i>
                        </span>
                      </button>
                    </div>
                    <div class="capacity-collaper">
                      <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-10">
                          <h3>Project List / Quotation</h3>
                        </div>
                        <div class="col-sm-2 d-flex justify-content-end">
                          <button type="button" data-bs-toggle="modal" data-bs-target="#opportunity" class="btn btn-icon createButton" (click)="addNewRow()"><i class="ri-add-line"></i></button>
                        </div>
                      </div>
                      <div formGroupName="values">
                        <ng-container *ngIf='formGroup.controls.values!=null'>
                          <div class="row mt-3 align-items-center justify-content-between" *ngFor="let itemrow of formGroup.controls.values.controls; let i=index" [formGroupName]="i">
                            <div class="col-sm-11 p-0">
                              <div class="row">
                                <div class="col-sm-12 p-0">
                                  <div class="input my-2">
                                    <div class="input-item">
                                      <select formControlName="quoteId" id="quoteId">
                                        <option *ngFor="let quote of quoteDropdown" [value]="quote['id']">{{quote['quoteName']}}</option>
                                      </select>
                                      <i class="ri-file-list-line"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 p-0">
                                  <div class="input">
                                    <div class="input-item">
                                      <input type="text" required id="probability" formControlName="probability">
                                      <label class="floating-label">Probability</label>
                                      <i class="ri-calendar-todo-line"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-1">
                              <div class="delete">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#delete-items" (click)="deleteRow(i)">
                                  <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                              </div>
                            </div>
                            
                          </div>
                        </ng-container>
                      </div>
                      <div class="col-sm-12 mx-2">
                        <button type="button" class="btn" (click)="savedCapacityPlanningDetails(id,formGroup.value)">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <br />
          <br />

        </div>




      </section>
    </section>
  </article>
</main>
<script>
  $(function () {
    $(".inner-sidebar-closer").click(function () {
      $('.capacity-collapese').toggleClass("active");
    });
  });
</script>
