<div class="heading">
  <h2>Dashboard</h2>
</div>

<div class="data">
  <!--<div id="chartContainer" style="height: 300px; width: 100%;"></div>-->



  <div style="height:65px;">
    <div class="form-group" style="float:right; margin-top:2%;margin-right:2%;">
      <label>Select Graph:  </label>
      <!--<mat-form-field appearance="fill">
        <mat-label>Choose an option</mat-label>-->
      <select (change)="changeGraph($event)">
        <option selected="selected" value="1">IT License Graph</option>
        <option value="2">Head Count By Location</option>
        <option value="3">Direct Cost Type</option>
        <option value="4">Head Count By Task</option>
        <option value="5">Head Count By Engineering Type</option>
      </select>
      <!--</mat-form-field>-->
    </div>
  </div>
  <!--<canvas id="myChart" width="400" height="400"></canvas>-->
  <div id="GraphContainer">


    <div id="Graph1" *ngIf="GraphDisplay == 1" style="width:100%;height:100%;">
      <ng-template [ngIf]="ItLicenseGraphDataSource?.length > 0" [ngIfElse]="displayerror1">
        <ejs-chart #chart [chartArea]='chartArea' [width]='width' align='center' [primaryXAxis]="primaryXAxis" [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)' height='100%'>
          <e-series-collection>
            <e-series *ngFor="let chart of ItLicenseGraphDataSource; let i = index"
                      [dataSource]="chart.values"
                      type='StackingColumn'
                      xName="x"
                      yName="y"
                      name="{{ItLicenseGraphArr[i]
  }}">
            </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
      <ng-template #displayerror1>
        <div><h5>Graph Data not available</h5></div>
      </ng-template>
    </div>





    <div id="Graph2" *ngIf="GraphDisplay == 2">
      <ng-template [ngIf]="HeadCountByLocationGraph?.length > 0" [ngIfElse]="displayerror2">
        <ejs-chart #chart [chartArea]='chartArea' [width]='width' align='center' [primaryXAxis]="primaryXAxis" [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)' height='100%'>
          <e-series-collection>

            <e-series *ngFor="let chart of HeadCountByLocationGraph; let i = index"
                      [dataSource]="chart.values"
                      type='StackingColumn'
                      xName="x"
                      yName="y"
                      name="{{HeadCountByLocationGraphArr[i]}}">
            </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
      <ng-template #displayerror2>
        <div><h5>Graph Data not available</h5></div>
      </ng-template>
    </div>




    <!--<div *ngIf="DirectCostTypeGraph?.length > 0">-->
    <div id="Graph3" *ngIf="GraphDisplay == 3">
      <ng-template [ngIf]="DirectCostTypeGraph?.length > 0" [ngIfElse]="displayerror3">
        <ejs-chart #chart [chartArea]='chartArea' [width]='width' align='center' [primaryXAxis]="primaryXAxis" [primaryYAxis]='primaryYAxisCost' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)' height='100%'>
          <e-series-collection>

            <e-series *ngFor="let chart of DirectCostTypeGraph; let i = index"
                      [dataSource]="chart.values"
                      type='StackingColumn'
                      xName="x"
                      yName="y"
                      name="{{DirectCostTypeGraphArr[i]}}">
            </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
      <ng-template #displayerror3>
        <div><h5>Graph Data not available</h5></div>
      </ng-template>
    </div>





    <!--<div *ngIf="HeadCountByTaskGraph?.length > 0">-->
    <div id="Graph4" *ngIf="GraphDisplay == 4">
      <ng-template [ngIf]="HeadCountByTaskGraph?.length > 0" [ngIfElse]="displayerror4">
        <ejs-chart #chart [chartArea]='chartArea' [width]='width' align='center' [primaryXAxis]="primaryXAxis" [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)' height='100%'>
          <e-series-collection>

            <e-series *ngFor="let chart of HeadCountByTaskGraph; let i = index"
                      [dataSource]="chart.values"
                      type='StackingColumn'
                      xName="x"
                      yName="y"
                      name="{{HeadCountByTaskGraphArr[i]}}">
            </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
      <ng-template #displayerror4>
        <div><h5>Graph Data not available</h5></div>
      </ng-template>
    </div>




    <!--<div *ngIf="HeadCountByEngineeringTypeGraph?.length > 0">-->
    <div id="Graph5" *ngIf="GraphDisplay == 5">
      <ng-template [ngIf]="HeadCountByEngineeringTypeGraph?.length > 0" [ngIfElse]="displayerror5">
        <ejs-chart #chart [chartArea]='chartArea' [width]='width' align='center' [primaryXAxis]="primaryXAxis" [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)' height='100%'>
          <e-series-collection>

            <e-series *ngFor="let chart of HeadCountByEngineeringTypeGraph; let i = index"
                      [dataSource]="chart.values"
                      type='StackingColumn'
                      xName="x"
                      yName="y"
                      name="{{HeadCountByEngineeringTypeGraphArr[i]}}">
            </e-series>
          </e-series-collection>
        </ejs-chart>
      </ng-template>
      <ng-template #displayerror5>
        <div><h5>Graph Data not available</h5></div>
      </ng-template>
    </div>


  </div>


</div>
