import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { LocalStorageService } from '../_services/localStorage/local-storage.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivate {
  constructor(
    private storageService: LocalStorageService,
    private snackbar: MatSnackBar
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = JSON.parse(this.storageService.get('currentUser'));
    //console.log("in role guard, route.data.roles: ", route.data.roles);
    // console.log("in role guard, currentUser.roles: ", currentUser.roles);

    // check if route has roles mentioned
    if (route.data.roles) {
      // allow access for tenant admin
      if (currentUser.admin == true) {
        return true;
      }

      // check current user's roles
      if (route.data.roles.some((ai) => currentUser.roles.includes(ai))) {
        return true;
      } else {
        this.snackbar.open('You are not authorized to perform this action', 'Close', {
          duration: 5000,
          verticalPosition: 'top',
        });
        return false;
      }
    }

    // allow access to tenant admin users
    // even when no roles are specified for route
    if (currentUser.admin == true) {
      return true;
    }
  }
}
