import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../../_services/user/user.service";
import { PaymentsService } from "../../../_services/payments/payments.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  hide = true;
  submitted = false;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  tenantIdPattern = "^([a-z0-9]+)$";

  form = new FormGroup({
    userId: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    displayName: new FormControl(''),
    department: new FormControl('', Validators.required),
    emailId: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9_@. ]*$'), Validators.email ]),
    phoneNo: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    location: new FormControl(''),
    tenantId: new FormControl('', [Validators.required, Validators.pattern(this.tenantIdPattern)])
  });

  constructor(
    private router: Router, 
    private userService: UserService, 
    private snackBar: MatSnackBar,
    private payments: PaymentsService
    ) { }

  ngOnInit(): void {
  }

  // getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    console.log("In onSubmit method, form value: ", JSON.stringify(this.form.value));
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let tenantId = this.form.value.tenantId;
    console.log("tenantId: ", tenantId);
    delete this.form.value.tenantId;
    let resource = JSON.stringify(this.form.value);
    console.log("resource: ", resource);
    this.userService.registerUser(resource, tenantId).subscribe((response: any) => {
      console.log("response: ", response);
      if (response != null) {
        this.snackBar.open('Registration successful!', 'Close', { duration: 3000 });
        this.router.navigate(['/login']);
      }
    },
    (error) => {
      this.snackBar.open('Registration failed!', 'Close', {duration: 5000});
    }
    );
  }

  cancel() {
    this.submitted = false;
    this.form.reset();
    this.router.navigate(['/login']);
  }

   //Payment Rayzor pay code funtionality
   options = {
    "key": "rzp_live_eK4sFPkTfTAbTW", // Enter the Key ID generated from the Dashboard
    "amount": "100", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Pragamana Private Limited",
    "description": "Register Transaction",
    "image": "../../assets/Pragamana_icon (3).jpg",
    "order_id": "", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "callback_url": "",
    //"prefill": {
    //  "name": "Bhavik",
    //  "email": "bhavik@example.com",
    //  "contact": "9999999999"
    //},
    //"notes": {
    //  "address": "Corporate Office"
    //},
    "theme": {
      "color": "#3399cc"
    }
    };


    postCreateSendOrder() {
      //let options1 = {
      //  "amount": "100", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      //  "currency": "INR",
      //}
      let options1 = "amount=100&currency=INR";
     // let options2 = JSON.stringify(options1);
      this.payments.createPaymentOrder(options1).subscribe((response: any) => {
        console.log("Payment Create Order: ", response);
      //  { 
      //  amount: 202
      //  amount_due: 202
      //  amount_paid: 0
      //  attempts: 0
      //  created_at: 1635567698
      //  currency: "INR"
      //  entity: "order"
      //  id: "order_IFPjktVl3rNMz6"
      //  notes: []
      //  offer_id: null
      //  receipt: "txn_1635567698385"
      //  status: "created"
      //}
  
        this.options.amount = response.amount;
        this.options.order_id = response.id;
      
      });
    }

    rzpbutton1() {
      //Create a Post the order
      this.postCreateSendOrder();
      let rzp1 = new this.payments.nativeWindow.Razorpay(this.options);
      rzp1.open();
    }

}
