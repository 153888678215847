<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between">
                        <div class="col-sm-1 p-1">
                            <div class="edit mx-2">
                                <button title="back page" (click)="backtoAttendance()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 p-1">
                                <div class="p-1">
                                    <div class="profile-items">
                                        <div class="d-flex">
                                            <div class="avtar">
                                                <img width="100px" height="100px" class="user-a" [src]="AttendanceImage" alt="profile_pic">
                                            </div>
                                            <div class="displayName">
                                                <label class="floating-label">{{ this.displayName }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-10 p-1">
                                    <div class="row">
                                      
                                      <label class="floating-label">contactName To View </label>
                                      <label class="floating-label">contactemail To Check</label>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-sm-6">
                                <!-- <div class="row">
                                    <div class="col-sm-6">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="date" formControlName="startDate" required />
                                                <label class="floating-label-special">From</label>
                                                <i class="ri-calendar-line"></i>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="date" formControlName="endDate" required />
                                                <label class="floating-label-special">To</label>
                                                <i class="ri-calendar-line"></i>
                                            </div>

                                        </div>
                                    </div>
                                </div> -->

                                <div class="row grey-boxes">
                                    <div class="col-sm-3">
                                        <div class="box">
                                            <p>Total Days</p>
                                            <span class="badge badge-secondary">{{salaryMetadata.totalDays}}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="box">
                                            <p>Present Days</p>
                                            <span class="badge badge-secondary">{{salaryMetadata.presentDays}}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="box">
                                            <p>Absent Days</p>
                                            <span class="badge badge-secondary">{{salaryMetadata.absentDays}}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="box">
                                            <p>Payable Days</p>
                                            <span class="badge badge-secondary">{{salaryMetadata.payableDays}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="div">
                                <div class="title col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <h3 class="pl-3">Holiday Calender</h3>
                                    </div>
                                </div>
                            </div>
                            <ejs-schedule #schedule height="650px" [(selectedDate)]="selectedDate"
                                [eventSettings]="eventSettings" currentView="Month" (actionComplete)="onChangeMonth($event)">
                                <ng-template #eventSettingsTemplate let-data>

                                    <div>
                                        <div class="time" *ngIf="data.name == ''">
                                            <!-- {{handleIsHoliday(data.attendanceDate)}} -->
                                            {{data.inTime}} - {{data.outTime}}
                                          <!-- {{data.name && data.name != "" ? data.name : (data.inTime - date.outTime)}} -->
                                            <!-- <br>
                                            <button (click)="handleIsHoliday()">Test</button> -->
                                        </div>
                                        <div class="time" *ngIf="data.name != ''">
                                             {{data.name}}
                                        </div>
                                        <!-- <button>click me</button> -->
                                        <!-- <div class="subject"> Working hours: {{data.Subject}}</div> -->
                                        
                                    </div>
                                </ng-template>
                            </ejs-schedule>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>