<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
              <div class="d-flex align-items-center">
                <div class="edit">
                  <button  title="back page" (click)="backToTimesheetReport()">
                    <span><i class="ri-arrow-left-line"></i></span>
                  </button>
                </div>
                <h3 class="px-3">TimeSheet No Punch</h3>
              </div>
            </div>
            <div class="row p-0">
                <div class="col-sm-12 p-0">
                  <div class="canvas-data px-0">
                    <form [formGroup]="timesheetPunchForm">
                    <div class="row m-0">
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="startDate"/>
                            <label class="floating-label-special">From</label>
                            <i class="ri-calendar-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="endDate"/>
                                <label class="floating-label-special">To</label>
                                <i class="ri-calendar-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                        <button class="btn"(click)="getNoTimesheetPunchReport()">Submit</button>
                      </div>
                    </div>
                  </form>
                  </div>
                </div>
            </div> 
          </div>
          <ag-grid-angular 
              style="height: 620px;width: 100;" 
              class="ag-theme-material"
              (gridReady)="onGridReady($event)"
              [rowData]="rowData"
              [columnDefs]="columnDefs"
              [alwaysShowHorizontalScroll]="true"
              [animateRows]="true"
              [defaultColDef]="defaultColDef"
              [suppressRowClickSelection]="true"
              [rowSelection]="rowSelection"
              [rowDragManaged]="true"
              (selectionChanged)="onSelectionChanged($event)"
              paginationPageSize=10
              [suppressDragLeaveHidesColumns]="true">
          </ag-grid-angular>
        </div>
      </section>
    </section>
  </article>
</main>
