import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileSettingComponent } from './profile-setting/profile-setting.component';
import { ProfilePageComponent } from './profile-page/profile-page.component';
import { ProfileDashboardLayoutComponent } from './profile-dashboard-layout/profile-dashboard-layout.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';

@NgModule({
    declarations: [
        ProfileSettingComponent,
        ProfilePageComponent,
        ProfileDashboardLayoutComponent,
        UploadDocumentComponent
],
imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule
]
})
export class ProfileModule { }

