<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                  <div class="canvas-header row justify-content-between align-items-center">
                    <div class="title col-sm-6">
                      <div class="d-flex align-items-center">
                        <div class="edit">
                          <button title="back page" (click)="backToJobApplication()">
                            <span><i class="ri-arrow-left-line"></i></span>
                          </button>
                        </div>
                        <h3> Job Applications for Interview </h3>
                      </div>
                    </div>
                    <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;"> 
                      <div class="button" *ngIf="jobDescriptionId">
                          <button type="button" (click)="openJobPost()">Job Post Link</button>
                      </div>
                    </div>
                    <section class="job-application-timeline">
                      <div class="row align-items-center">
                        <ul>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openApplicantList()">
                                <span>1</span>
                              </div>
                              <p class="step-count">Applicant</p>
                            </div>
                          </li>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openShortlistedApplicants()">
                                <span>2</span>
                              </div>
                              <p class="step-count">Shortlisted</p>
                            </div>
                          </li>
                          <li>
                            <div class="step active">
                              <div class="stepn-numb" (click)="openInterviewApplicants()">
                                <span>3</span>
                              </div>
                              <p class="step-count">Interview</p>
                            </div>
                          </li>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openOfferedApplicants()">
                                <span>4</span>
                              </div>
                              <p class="step-count">Offered</p>
                            </div>
                          </li>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openApprovedApplicants()">
                                <i class="ri-medal-line"></i>
                              </div>
                              <p class="step-count">Approved</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                  
                  <hr><br>
                  <ag-grid-angular 
                      style="height: 620px;" 
                      class="ag-theme-material"
                      (gridReady)="onGridReady($event)"
                      [rowData]="rowData"
                      [columnDefs]="columnDefs"
                      [animateRows]="true"
                      [defaultColDef]="defaultColDef"
                      [suppressRowClickSelection]="true"
                      [rowSelection]="rowSelection"
                      [rowDragManaged]="true"
                      paginationPageSize=10
                      [suppressDragLeaveHidesColumns]="true">
                  </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>