import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ChangerequestService } from 'src/app/_services/changerequest/changerequest.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-request',
  templateUrl: './change-request.component.html',
  styleUrls: ['./change-request.component.scss'],
})
export class ChangeRequestComponent implements OnInit {
  changeRequestForm
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
  tenantId: string;
  currentUser: any;
  selectedFile: File;
  form: FormGroup;
  sampleGridFrameworkComponents: any;
  gridLength = 0;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  id: any;

  constructor(
    private changereqyesrService: ChangerequestService,
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private allocationService: AllocationService,
    ) {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });

    this.changeRequestForm = new FormGroup({
      id : new FormControl(''), 
      title :   new FormControl(''), 
      status :   new FormControl(''), 
      changeRequestNumber :  new FormControl(''), 
      causedBy :   new FormControl(''), 
      partClassification :   new FormControl(''), 
      crResponsibility :   new FormControl(''), 
      schedule :   new FormControl(''), 
      problem :   new FormControl(''), 
      solution :   new FormControl(''), 
      impactDirectMaterialCost :   new FormControl(''), 
      impactRndOneOffCost :   new FormControl(''), 
      impactDirectLaborCost :   new FormControl(''), 
      impactPlantInvestment :   new FormControl(''), 
      impactOthers :   new FormControl(''), 
      impactTransferPrice :   new FormControl(''), 
      impactSumTranferPrice :   new FormControl(''), 
      impactCostBaringUseCase :   new FormControl(''), 
      impactWeight :   new FormControl(''), 
      impactOtherTarget :   new FormControl(''), 
      requiredChangesForCn :   new FormControl(''), 
      requiredChangesForEceRhd :   new FormControl(''), 
      requiredChangesForEceLhd :   new FormControl(''), 
      requiredChangesForFeatureList :   new FormControl(''), 
      requiredChangesForCbom :   new FormControl(''), 
      requiredChangesForEarlyBom :   new FormControl(''), 
      requiredChangesForHomologationRelevant :   new FormControl(''), 
      requiredChangesForAffectedFeature :  new FormControl(''),
    })
    }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getCRData();
  }

  
  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  } 

 // create column definitions 
 private createColumnDefs() {
  return [
    {
      headerName: 'Title',
      field: 'title',
      filter: true,
      editable: false,
      sortable: true,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
    },
    {
      headerName: 'Change Request Number',
      field: 'changeRequestNumber',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'CR Responsibility',
      field: 'crResponsibility',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Caused By',
      field: 'causedBy',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Schedule',
      field: 'schedule',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Part Classification',
      field: 'partClassification',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Problem',
      field: 'problem',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Solution',
      field: 'solution',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Direct Material Cost',
      field: 'impactDirectMaterialCost',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'impact RndOne OffCost',
      field: 'impactRndOneOffCost',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Direct LaborCost',
      field: 'impactDirectLaborCost',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Plant Investment',
      field: 'impactPlantInvestment',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Others',
      field: 'impactOthers',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Transfer Price',
      field: 'impactTransferPrice',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Sum Tranfer Price',
      field: 'impactSumTranferPrice',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact CostBaring UseCase',
      field: 'impactCostBaringUseCase',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Weight',
      field: 'impactWeight',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Impact Other Target',
      field: 'impactOtherTarget',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },

  ]
 }

    onSelectionChanged(event) {
      this.selectedCheckboxData = []
      let select = event.api.getSelectedNodes();
      for (let item of select) {
        this.selectedCheckboxData.push(item.data)
      }
    }

    isFirstColumn(params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    }

    createCR() {
      let details = JSON.stringify(this.changeRequestForm.value);
      this.changereqyesrService.createChangeRequests(details).subscribe(response => {
        console.log("response: ", response);
        
        // update grid after creating new lead & close the modal
        this.api.applyTransaction({ add: [response] });
        $('#closeCreateModal').trigger("click");
      });
    }

    performGridActionItem(params) {
      if (params.event.currentTarget.value == 'delete') {
        this.deleteGridRow(params);
      }     
    }

    private deleteGridRow(params: any) {
      this.changereqyesrService.deleteChangeRequests(params.params.data.id).subscribe(() => {
        var selectedData = params.params.data;
        this.api.applyTransaction({ remove: [selectedData] });
      });
    }

    editOpenModal() {
      this.modeAction = 2;
      this.changeRequestForm.patchValue(this.selectedCheckboxData[0])
    }

    createOpenModal() {
      this.modeAction = 1;
    }

    deleteOpenModal() {
      this.deleteConfirmationMsg = 'Are you sure you want to delete'
      for (let i in this.selectedCheckboxData) {
        if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].title} ${this.selectedCheckboxData[i].changeRequestNumber}`;
        else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].title} ${this.selectedCheckboxData[i].changeRequestNumber}`;
        else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].title} ${this.selectedCheckboxData[i].changeRequestNumber}`;
      }
    }
  
    deleteCR() {
      this.changereqyesrService.deleteChangeRequests(this.selectedCheckboxData[0].id).subscribe(() => {
        this.getCRData();
        this.selectedCheckboxData = []
        $('#closeModalCR').click()
      });
    }


  getCRData() {
    this.changereqyesrService.getAllchangeRequests().subscribe((response: any) => {
        console.log('get change request-list', response);
        this.rowData = response.result;
      });
  }

  openAddChangeReq(){
    this.router.navigate([this.tenantId + '/dashboard/project/add-change-request']);
  }

  openEditChangeReq(){
    this.router.navigate([this.tenantId + '/dashboard/project/add-change-request'], { queryParams: {id: this.selectedCheckboxData[0].id} },);
  }

  backToChangeRequest(){
    this.router.navigate([this.tenantId + '/dashboard/project/changerequest']);
  }

}
