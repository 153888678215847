<main class="main-dashboard">
    <article class="main-canvas"> 
        <section class="canvas-container">
            <section class="dashboard-items">
                <form [formGroup]="salaryDistributionForm" >
                    <div class="canvas-item">
                        <div class="canvas-header row justify-content-between align-items-center">
                            <div class="title col-sm-6">
                                <div class="d-flex align-items-center">
                                    <div class="edit">
                                        <button (click)="backToMySalaryDistribution()">
                                            <span><i class="ri-arrow-left-line"></i></span>
                                        </button>
                                    </div>
                                    <h3 class="px-3">Salary Distribution Form</h3>
                                </div>   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="canvas-data">
                                    <div class="row">
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item" required>
                                                    <select formControlName="date" required>
                                                    <option value="2022-2023">2022-2023</option>
                                                    <option value="2023-2024">2023-2024</option>
                                                    <option value="2024-2025">2024-2025</option>
                                                    <option value="2025-2026">2025-2026</option>
                                                    </select>
                                                    <label class="floating-label floating-label-special">Financial Year</label>
                                                    <i class="ri-calendar-check-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item" required>
                                                    <select formControlName="month" required>
                                                        <option value="" disabled selected>Select Month</option>
                                                        <option value="All">All</option>
                                                        <option value="January">January</option>
                                                        <option value="February">February</option>
                                                        <option value="March">March</option>
                                                        <option value="April">April</option>
                                                        <option value="May">May</option>
                                                        <option value="June">June</option>
                                                        <option value="July">July</option>
                                                        <option value="August">August</option>
                                                        <option value="September">September</option>
                                                        <option value="October">October</option>
                                                        <option value="November">November</option>
                                                        <option value="December">December</option>
                                                    </select>
                                                    <label class="floating-label floating-label-special">Month*</label>
                                                    <i class="ri-calendar-line"></i>
                                                </div>
                                                <mat-error *ngIf="f.month.touched && f.month.invalid" class="alert alert-danger">
                                                    <mat-error *ngIf="f.month.errors.required">Month is required</mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="string" formControlName="monthlyUpperLimit">
                                                    <label class="floating-label floating-label-special">Monthly Upper Limit</label>
                                                    <i class="ri-hashtag"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input list="taskOwnerDatalist" formControlName="employeeId" placeholder=" Select Employee" value="" />
                                                    <datalist id="taskOwnerDatalist">
                                                        <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                                    </datalist>
                                                    <label class="floating-label floating-label-special">Employee</label>
                                                    <i class="ri-file-copy-2-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="string" formControlName="componentname" required>
                                                    <label class="floating-label floating-label-special">Component Name*</label>
                                                    <i class="ri-hashtag"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="text" formControlName="componentvalue" >
                                                        <label class="floating-label floating-label-special">Component value*</label>
                                                        <i class="ri-hashtag"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item" >
                                                    <select formControlName="gender" >
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    </select>
                                                    <label class="floating-label floating-label-special">Gender</label>
                                                    <i class="ri-calendar-check-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <select formControlName="percent">
                                                        <option value="true">True</option>
                                                        <option value="false">False</option>
                                                    </select>
                                                    <label class="floating-label floating-label-special">Percent*</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <select formControlName="deduction">
                                                        <option value="true">True</option>
                                                        <option value="false">False</option>
                                                    </select>
                                                    <label class="floating-label floating-label-special">Deduction*</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input list="componentList" formControlName="percentComparedToComponent" value=""  />
                                                    <datalist id="componentList">
                                                        <option [value]="item" *ngFor="let item of componentsList"></option>
                                                    </datalist>
                                                    <label class="floating-label floating-label-special">Percent Compared To Component</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="text" formControlName="displayOrder" >
                                                    <label class="floating-label floating-label-special">Display Order</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                                <mat-error *ngIf="f.displayOrder.touched && f.displayOrder.invalid" class="alert alert-danger">
                                                    <mat-error *ngIf="f.displayOrder.errors.pattern">Display Order must contain number only</mat-error>
                                                </mat-error> 
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="text" formControlName="absoluteLimitWithinDistribution" >
                                                    <label class="floating-label floating-label-special">Absolute Limit Within Distribution</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <input type="text" formControlName="comparedToComponentRange" >
                                                    <label class="floating-label floating-label-special">compared To Component Range</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 px-3 py-1">
                                            <div class="input">
                                                <div class="input-item">
                                                    <select formControlName="regime">
                                                        <option value="" selected disabled>Select Regime</option>
                                                        <option value="Both">Both</option>
                                                        <option value="New">New</option>
                                                        <option value="Old">Old</option>
                                                    </select>
                                                    <label class="floating-label floating-label-special">Regime</label>
                                                    <i class="ri-percent-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-3 mt-2"> 
                                            <div class="row justify-content-end ">
                                                <div class="col-sm-6">
                                                    <div  class="text-end" *ngIf="this.salaryId">
                                                        <button
                                                        type="button"
                                                        id="closeCreateModal"
                                                        class="btn"
                                                        (click)="updateSalaryDetailsbyId()"
                                                        [disabled]="!salaryDistributionForm.valid"
                                                        >
                                                        <div *ngIf="this.salaryId">Save</div>
                                                        </button>
                                                    </div>
        
                                                    <div class="text-end" *ngIf="!this.salaryId">
                                                        <button
                                                        type="button"
                                                        id="closeCreateModal"
                                                        class="btn"
                                                        (click)="salaryform(salaryDistributionForm)"
                                                        >
                                                        <div *ngIf="!this.salaryId">Create</div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </section>    
    </article>
</main>
