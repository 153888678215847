<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Software Rates </h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <button type="button"  data-bs-toggle="modal" data-bs-target="#srAddModal" class="btn btn-icon" (click)="addOpenModal()"><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="canvas-table">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Software</th>
                                                <th>Hourly Rate</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of jsonData">
                                                <td>{{item.software}}</td>
                                                <td>{{item.hourlyCostRate}}</td>
                                                <td>
                                                    <div class="table-action">
                                                        <button class="action-btn"><i class="ri-more-2-fill"></i></button>
                                                        <div class="action-items d-flex">
                                                            <div class="edit">
                                                                <button type="button" (click)="editOpenModal(item)" data-bs-toggle="modal" data-bs-target="#srAddModal">
                                                                    <span><i class="ri-pencil-line"></i></span>
                                                                    
                                                                </button>
                                                            </div>
                                                            <div class="delete">
                                                                <button type="button" data-bs-toggle="modal" data-bs-target="#srDeleteModal" (click)="openDelete(item)">
                                                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                                                    
                                                                </button>
                                                            </div>
                                                            <div class="edit">
                                                                <button>
                                                                    <i class="ri-folder-transfer-fill"></i>
                                                                    
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>                                  
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
  
<!-- Add new Software Rate -->
<div class="modal fade" id="srAddModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="softwareRatesForm">
                <div class="modal-header">
                    <h5 class="modal-title">Add Software Rate</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="software" type="text" required>
                            <label class="floating-label">Software </label>
                            <i class="ri-computer-line"></i>
                        </div>
                        <mat-error *ngIf="f.software.touched && f.software.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.software.errors.required">Software Name is required</mat-error>
                          </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="hourlyCostRate" type="text" required>
                            <label class="floating-label">Hourly Rate</label>
                            <i class="ri-money-dollar-circle-line"></i>
                        </div>
                        <mat-error *ngIf="f.hourlyCostRate.touched && f.hourlyCostRate.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.hourlyCostRate.errors.required">Hourly Rate is required</mat-error>
                          </mat-error>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeSrAddModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="addSoftwareRates()" [disabled]="!softwareRatesForm.valid" >Add</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="editSoftwareRates()" [disabled]="!softwareRatesForm.valid">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="srDeleteModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="softwareRatesForm">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Software Rate</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>Are you sure you want to delete <b>{{selectedRow?.software}}</b>?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeSrDeleteModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <a class="btn btn-primary" (click)="deletedefsr(selectedRow?.id)">Yes Delete</a>
                </div>
            </form>
        </div>
    </div>
</div>