<section class="lead-user-timeline">
    <div class="card">
        <div class="card-header p-3 d-flex justify-content-between">
            <div class="title">
                <h3>Notification Settings <small></small> </h3>
            </div>
            <div class="d-flex gap-2 align-items-center">
              <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteCampaign"
                    (click)="deleteOpenModal()"
                  >
                    <span><i class="ri-delete-bin-5-line"></i></span>
                  </button>
              </div>
              <div class=" add-btn">
                 <button (click)="addEvent()" data-bs-toggle="modal" data-bs-target="#notification_modal" class="btn btn-icon">
                  <i class="ri-add-line"></i>
                 </button>
              </div>
            </div>
        </div>
        <div class="card-body">
            <form [formGroup]="form">
                <div class="event-items" formArrayName="notificationArray"
                    *ngFor="let item of form.get('notificationArray')?.controls; let i = index;">
                    <div class="row" [formGroupName]="i">
                        <div class="col-sm-3">
                            <div class="checkbox">
                                <input type="checkbox" (change)="onSelectionChanged(i)">
                                <label *ngIf="!isTitle[i]" for="" (click)="setTitleEdit(i)">{{title[i]}}</label>
                                <input *ngIf="isTitle[i]" class="form-control" formControlName="notificationName"
                                    (keyup.enter)="setTitleEdit(i)" [(ngModel)]="title[i]">
                            </div>
                            <a href="" class="template-btn">Set template</a>
                        </div>
                        <div class="col-sm-4">
                            <div class="selection">
                                <span>Notification before Days/Months</span>
                                <ng-select formControlName="notificationValue" [items]="notificationDuration"
                                    [multiple]="true" bindLabel="lable" bindValue="value" placeholder="Select duration">
                                </ng-select>
                                <!-- <a href="" class="link">Robert, Ritesh, and 3 others this month</a> -->
                            </div>
                        </div>
                        <div class="col-sm-2 mt-4">
                            <button class="btn btn-sm" (click)="updateSettings(i)">Update Settings</button>

                        </div>
                    </div>
                </div>
            </form>
            <!-- Create new column -->
            <div class="modal fade" id="notification_modal">
                <div class="modal-dialog">
                    <div class="modal-content pl-5 pr-5">
                        <form [formGroup]="addForm">
                            <div class="modal-header">
                                <h5 class="modal-title">Add Notification</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="input">
                                    <div class="input-item">
                                        <input formControlName="notificationName" type="text" />
                                        <label for="" class="floating-label">Notification Name</label>
                                    </div>
                                </div>
                                        <div class="selection">
                                            <ng-select formControlName="notificationValue"
                                                [items]="notificationDuration" [multiple]="true" bindLabel="lable"
                                                bindValue="value" placeholder="Select duration">
                                            </ng-select>
                                </div>
                                <div class="input">
                                    <div class="input-item">
                                        <input formControlName="receiver" type="text" />
                                        <label for="" class="floating-label">Receiver</label>
                                    </div>
                                </div>
                                <div class="row pt-3 pb-3">
                                    <div class="col-sm-6">
                                      <mat-slide-toggle formControlName="isActive"
                                        value="true">This notification is active or not ?</mat-slide-toggle>
                                    </div>
                                  </div>
                                  <div class="row pt-3 pb-3">
                                    <div class="col-sm-6">
                                      <mat-slide-toggle formControlName="viewFlag" 
                                        value="true">View Flag</mat-slide-toggle>
                                    </div>
                                  </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" id="closeModalNotification" class="btn btn-secondary"
                                    data-bs-dismiss="modal">
                                    Cancel
                                </button>
                                <button type="button" class="btn btn-primary" (click)="createSettings()">Add Column</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Delete an item -->
<div class="modal fade" id="deleteCampaign" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content pl-5 pr-5">
        <form action="">
          <div class="modal-header">
            <h5 class="modal-title">Are you sure want to delete?</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <p>{{ deleteConfirmationMsg }}?</p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              id="closeModalDelete"
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary"
              *ngIf="selectedCheckboxData.length > 0"
              (click)="deleteCampaign()"
            >
              Yes Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>