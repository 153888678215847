
<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Exit Process</h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end" style="display: flex; justify-content: flex-end; align-items: center;">
                            <div>
                                <button type="button" title="Create" class="btn btn-sm" (click)="ExitResignationForm()">
                                    <div>Resignation Apply</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        style="height: 620px;"
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)"
                        [rowData]="rowData"
                        [columnDefs]="columnDefs"
                        pagination="true"
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        paginationPageSize="10"
                    >
                  </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

