import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { UserService } from '../../../_services/user/user.service';
import { AvatarService } from "../../../_services/avatar/avatar.service";
import { LocalStorageService } from "../../../_services/localStorage/local-storage.service";

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  form: FormGroup;
  selectedFile: File;
  retrievedImage: any;
  base64Data: any;
  retrieveResponse: any;
  message: string;
  imageName: any;
  currentUser: any;

  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AvatarComponent>,
    private user: UserService, private avatar: AvatarService, private storageService: LocalStorageService) {
    this.form = this.formBuilder.group({
      imageName: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getImageOnInit();
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
  }

  //Gets called when the user clicks on submit to upload the image
  onUpload() {
    console.log("in onUpload, selectedFile: ", this.selectedFile);

    // get logged in user's details
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    console.log("current user's id: ", this.currentUser.id);

    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();

    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    console.log("uploadImageData: ", uploadImageData);

    //Make a call to the Spring Boot Application to save the image
    this.avatar.uploadImage(this.currentUser.id, uploadImageData).subscribe((response: any) => {
      if (response.status === 200) {
        this.message = 'Image uploaded successfully';
      } else {
        this.message = 'Image not uploaded successfully';
      }
    });

    setTimeout(() => {
      this.getImageAfterUpdate();
    }, 400);

  }

  getImageOnInit() {
    if (JSON.parse(this.storageService.get('userImage')) !== null) {
      this.retrievedImage = 'data:image/jpeg;base64,' + JSON.parse(this.storageService.get('userImage'));
    } else {
      this.retrievedImage = './assets/user-icon-jpg-29.jpg';
    }
  }

  //Gets called when the user clicks on retieve image button to get the image from back end
  getImageAfterUpdate() {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.user.getUser(this.currentUser.id).subscribe((response: any) => {
      this.retrievedImage = 'data:image/jpeg;base64,' + response.image;
      this.storageService.set('userImage', JSON.stringify(response.image));
    });
  }

}
