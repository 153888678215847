import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxctcService } from '../../../../_services/taxctc/taxctc.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { TaxmetadataService } from 'src/app/_services/taxmetadata/taxmetadata.service';

@Component({
  selector: 'app-tax-ctc-form',
  templateUrl: './tax-ctc-form.component.html',
  styleUrls: ['./tax-ctc-form.component.scss']
})
export class TaxCtcFormComponent implements OnInit {
  jsonData: any;
  // row data and column definitions 
  taxCtcform 
  rowData: any;
  form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  tenantId: string;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  taxctcId: any;
  getUserName: any;
  userDropdownList = [];
  taxregimDropdownList = [];
  tdsIgnoreMonthsArray : any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private taxmetadataService :TaxmetadataService,
    private allocationService: AllocationService,
    private router: Router,
    private route: ActivatedRoute,
    private taxctcService :TaxctcService
  ) { 
    this.taxCtcform = this.formBuilder.group({
    employeeId: new FormControl(''),
    financialYear: new FormControl(''),
    ctc: new FormControl(''),
    startMonth: new FormControl(''),
    endMonth: new FormControl(''),
    componentName: new FormControl(''),
    componentValue: new FormControl(''),
    miscellaneous: new FormControl(''),
    deduction: new FormControl(''),
    taxRegimeNo: new FormControl(''),
    employeeName: new FormControl(''),
    tdsIgnoreMonths: new FormControl(''),
    tdsIgnoreMonthsValues: new FormControl(''),
    absolute: new FormControl(''),

  })
}

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.taxctcId = this.route.snapshot.paramMap.get('id'); 
    if (this.taxctcId) this.getuserCtcsById(this.taxctcId)
    this.getUserList();
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  // getter for easy access to form fields
  get f() {
    return this.taxCtcform.controls;
  }

  getuserCtcsById(id){
    this.taxctcService.getuserCtcsById(id).subscribe((response) => {
      if(response) {
        this.taxCtcform.patchValue(response);
        const array = response.tdsIgnoreMonths?.split(',');
        this.taxCtcform.value.tdsIgnoreMonths = array;
        this.taxCtcform.value.employeeId = this.taxCtcform.value.employeeId + " . " + this.taxCtcform.value.employeeName;
        this.taxCtcform.patchValue(this.taxCtcform.value);
      }
    }, (error) =>{
      
    });
  }

  getUserCtcs(){
    this.taxctcService.getAlluserCtcs().subscribe((response: any) => {
      this.rowData = response.result; 
    });
    
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  createuserCtcs() {
    let tdsIgnoredMonth = this.taxCtcform.get('tdsIgnoreMonths').value?.toString();
    let details = {
      employeeId: this.taxCtcform.get('employeeId') ? this.taxCtcform.get('employeeId').value.split(' . ')[0] : null,
      employeeName: this.taxCtcform.get('employeeId') ? this.taxCtcform.get('employeeId').value.split(' . ')[1] : null,
      financialYear: this.taxCtcform.get('financialYear').value,
      ctc: this.taxCtcform.get('ctc').value,
      startMonth: this.taxCtcform.get('startMonth').value,
      endMonth: this.taxCtcform.get('endMonth').value,
      componentName: this.taxCtcform.get('componentName').value,
      componentValue: this.taxCtcform.get('componentValue').value,
      miscellaneous: this.taxCtcform.get('miscellaneous').value,
      deduction: this.taxCtcform.get('deduction').value,
      taxRegimeNo: this.taxCtcform.get('taxRegimeNo').value,
      tdsIgnoreMonths: tdsIgnoredMonth,
      tdsIgnoreMonthsValues: this.taxCtcform.get('tdsIgnoreMonthsValues').value,
      absolute: this.taxCtcform.get('absolute').value,
    }
    this.taxctcService.createuserCtcs(details).subscribe((response: any) => {
      // update grid after creating new contact & close the modal
      this.backToCtcTable();
    });
  }

  backToCtcTable() {
    this.router.navigate([this.tenantId + '/dashboard/finance/tax-ctc']);
  }

  updateuserCtcs() {
    let tdsIgnoredMonth = this.taxCtcform.get('tdsIgnoreMonths').value?.toString();
    let details = {
      //id: this.taxmetadataform.get('id').value,
      employeeId: this.taxCtcform.get('employeeId') ? this.taxCtcform.get('employeeId').value.split(' . ')[0] : null,
      employeeName:this.taxCtcform.get('employeeId') ? this.taxCtcform.get('employeeId').value.split(' . ')[1] : null,
      financialYear: this.taxCtcform.get('financialYear').value,
      ctc: this.taxCtcform.get('ctc').value,
      startMonth: this.taxCtcform.get('startMonth').value,
      endMonth: this.taxCtcform.get('endMonth').value,
      componentName: this.taxCtcform.get('componentName').value,
      componentValue: this.taxCtcform.get('componentValue').value,
      miscellaneous: this.taxCtcform.get('miscellaneous').value,
      deduction: this.taxCtcform.get('deduction').value,
      taxRegimeNo: this.taxCtcform.get('taxRegimeNo').value,
      tdsIgnoreMonths: tdsIgnoredMonth,
      tdsIgnoreMonthsValues: this.taxCtcform.get('tdsIgnoreMonthsValues').value,
      absolute: this.taxCtcform.get('absolute').value,
    }
    // console.log("details", details)
    this.taxctcService.updateuserCtcs(details, this.taxctcId).subscribe(res => {
      this.getUserCtcs();
      this.selectedCheckboxData = [];
      this.backToCtcTable();
    });
  }

}
