<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
               <div class="d-flex align-items-center">
                  <div class="edit">
                  <button title="back page" (click)="backToTimesheet()">
                    <span><i class="ri-arrow-left-line"></i></span>
                  </button>
                </div>
                <h3 class="px-3">TimeSheet Report</h3>
               </div>
            </div>
            <div class="col-sm-6 d-flex justify-content-end">
              <div class="col-sm-3 text-end">
                <button type="button" (click)="timesheetNoPunch()" class="btn btn-sm btn-secondary">
                  <div>No Punch Report</div>
                </button>
              </div>
               <div class="mx-2">
                <button type="button" (click)="timesheetGraph()" class="btn btn-icon">
                  <i class="ri-bar-chart-grouped-line"></i>
                </button>
              </div>
            </div>
            <hr class="mt-3">
            <div class="row p-0 mt-3">
              <div class="col-sm-12 p-0">
                <div class="canvas-data px-0">
                  <form [formGroup]="form">
                  <div class="row">
                    <div class="col-sm-3 p-0">
                      <div class="input">
                        <div class="input-item no-overflow">
                          <ng-select [items]="typeList" [(ngModel)]="selectedType" bindLabel="lable" bindValue="value" class="w-100" placeholder="Select Type" formControlName="type" (change)="onchangeType()"></ng-select>
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                      <div class="input my-2" *ngIf="selectedType && selectedType != ''">
                        <div class="input-item no-overflow">
                          <ng-select [items]="projectList" class="w-100" [multiple]="true" bindLabel="projectName" bindValue="id" placeholder="Select Projects" *ngIf="selectedType == 'project'" formControlName="ids">
                            <ng-template ng-header-tmp>

                              <div class="d-flex gap-2">
                                <button class="btn btn-sm"
                                        (click)="onSelectAll('project')">Select All</button>
                                <button class="btn btn-sm"
                                        (click)="onClearAll()">Clear All</button>
                              </div>
                          
                            </ng-template>
                          </ng-select>
                          <ng-select [items]="employeeList" class="w-100" [multiple]="true" bindValue="id" bindLabel="firstName" placeholder="Select Employee" *ngIf="selectedType == 'user'" formControlName="ids">
                            <ng-template ng-header-tmp>

                              <div class="d-flex gap-2">
                                <button class="btn btn-sm"
                                        (click)="onSelectAll('user')">Select All</button>
                                <button class="btn btn-sm"
                                        (click)="onClearAll()">Clear All</button>
                              </div>
                            </ng-template>
                          </ng-select>

                          <ng-select [items]="systemList" class="w-100" [multiple]="true" bindLabel="system" bindValue="id" placeholder="Select System" *ngIf="selectedType == 'system'" formControlName="ids">
                            <ng-template ng-header-tmp>

                              <div class="d-flex gap-2">
                                <button class="btn btn-sm"
                                        (click)="onSelectAll('system')">Select All</button>
                                <button class="btn btn-sm"
                                        (click)="onClearAll()">Clear All</button>
                              </div>
                          
                            </ng-template>
                          </ng-select>

                          <ng-select [items]="subSystemList" class="w-100" [multiple]="true" bindLabel="subSystem" bindValue="id" placeholder="Select SubSystem" *ngIf="selectedType == 'subSystem'" formControlName="ids">
                            <ng-template ng-header-tmp>

                              <div class="d-flex gap-2">
                                <button class="btn btn-sm"
                                        (click)="onSelectAll('subSystem')">Select All</button>
                                <button class="btn btn-sm"
                                        (click)="onClearAll()">Clear All</button>
                              </div>
                          
                            </ng-template>
                          </ng-select>

                          <ng-select [items]="subSubSystemList" class="w-100" [multiple]="true" bindLabel="subSubSystem" bindValue="id" placeholder="Select Sub-SubSystem" *ngIf="selectedType == 'subSubSystem'" formControlName="ids">
                            <ng-template ng-header-tmp>

                              <div class="d-flex gap-2">
                                <button class="btn btn-sm"
                                        (click)="onSelectAll('subSubSystem')">Select All</button>
                                <button class="btn btn-sm"
                                        (click)="onClearAll()">Clear All</button>
                              </div>

                            </ng-template>

                          </ng-select>
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="input">
                        <div class="input-item">
                          <input type="date" formControlName="startDate" />
                          <label class="floating-label-special">From</label>
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="input">
                        <div class="input-item">
                          <input type="date" formControlName="endDate" />
                          <label class="floating-label-special">To</label>
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 text-end text-right">
                      <button type="button" class="btn" (click)="generateReport()" [disabled]="this.selectedType && this.selectedType == ''">
                        <div>Submit</div>
                      </button>
                      <button type="button" data-bs-toggle="modal" data-bs-target="#createVisitor" id="confimationBtn"
                        style="display: none;">

                      </button>

                    </div>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
          <ag-grid-angular
             
              class="ag-theme-material"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [groupDisplayType]="groupDisplayType"
              [animateRows]="true"
              [rowData]="rowData"
              (gridReady)="onGridReady($event)"
          ></ag-grid-angular>
          
          <!-- <ag-grid-angular style="height: 620px;" class="ag-theme-material" (gridReady)="onGridReady($event)"
            [rowData]="rowData" [columnDefs]="columnDefs" pagination="true" [defaultColDef]="defaultColDef"
            [suppressRowClickSelection]="true" [rowSelection]="rowSelection"
            (selectionChanged)="onSelectionChanged($event)" paginationPageSize="10">
          </ag-grid-angular> -->
        </div>
      </section>
    </section>
  </article>
</main>