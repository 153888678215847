import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ColDef } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ActionItemsGridRowRendererComponent } from '../../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-leave-book-detail',
  templateUrl: './leave-book-detail.component.html',
  styleUrls: ['./leave-book-detail.component.scss']
})
export class LeaveBookDetailComponent implements OnInit {
  LeaveBookForm: any;
  rowSelection: any;
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  modalService: any;
  currentUser: any;
  jsonData: any;
  emailPattern = '^[a-z0-9._%+-]+\\.[a-z]{2,4}$';
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';
  userDropdownList = [];
  projectDropdownList = [];
  projectCode: number;
  projname: any;
  proj = [];
  getProjectDetailsList: any;
  getUserName: any;
  projectDetailList: any;
  // row data and column definitions
  rowData: any;
  month: any;
  year: any;
  
  modeAction: number = 1; //1 - create // 2 - update
  // public columnDefs: ColDef[];
  public columnDefs: ColDef[] = []
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
  };
  
  constructor(
    private storageService: LocalStorageService,
    private HrService: HrService,
    private router: Router,
  ) { 
     this.LeaveBookForm = new FormGroup({
      userName: new FormControl(''),
      userId: new FormControl(''),
      leaveType: new FormControl(''),
      balance: new FormControl(''),
      year: new FormControl(''),
      month: new FormControl(''),
      
    });
    { 
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
  
      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent,
      };
    };
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.getLeaveBookDetail();
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.rowData = [];
  }

  getLeaveBookDetail(){
    this.columnDefs= [];
    this.month = this.LeaveBookForm.get('month').value;
    this.year = this.LeaveBookForm.get('year').value;
    this.HrService.getLeaveBookDetail(this.month,this.year).subscribe(((response: any) => {
      if(response) {
        let leaveTypeArray: Array<any> = [];
        this.columnDefs = [
          { field: 'userName', rowGroup: true, hide: true },
          { field: 'localId' },
          { field: 'leaveType' },
          { field: 'balance' },
          { field: 'considerForbalance' }
        ];

        for (let i = 0; i < response.length; i++) {
          if(response[i]) {
            let leaveBooks = response[i]['leaveBooks'];
            for (let index = 0; index < leaveBooks.length; index++) {
              leaveTypeArray.push({
                userName: response[i].userName,
                localId:  response[i].localId,
                leaveType: leaveBooks[index].leaveType,
                balance: leaveBooks[index].balance,
                considerForbalance: leaveBooks[index].considerForbalance,
              });
            }
          }
        }
        this.rowData = leaveTypeArray;
      }
    }));
  }

 

  backToLeaveProfile(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-profile']);
  }

  leaveAdjustments(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-adjustments']);
  }

}
