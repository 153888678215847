import { FormControl, FormGroup, Validators } from '@angular/forms';

export class TableRows {
  id: string;
  name: string;
  projectId: string;
  role:string;
  location:string;
  employeeId:string;

  static asFormGroup(tableRows: TableRows): FormGroup {
    const allocationData = new FormGroup({
      id: new FormControl(tableRows.id, Validators.required),
      name: new FormControl(tableRows.name, Validators.required),
      projectId: new FormControl(tableRows.projectId, Validators.required),
      role: new FormControl(tableRows.role, Validators.required),
      location: new FormControl(tableRows.location, Validators.required),
      employeeId: new FormControl(tableRows.employeeId, Validators.required),
    });
    return allocationData;
  }

  
}

