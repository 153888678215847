<div class="heading">
    <h2>Other Direct Cost Distribution</h2>
</div>
<div class="data">
    <div class="canvas-table">
        <table class="table">
            <thead>
                <tr>
                    <th>ODC Categories</th>
                    <th>Cost</th>
                    <th>Percent</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataSource_odcd">
                    <td>{{item.odcName}}</td>
                    <td>{{item.cost | number}}</td>
                    <td>{{item.percent | number}}</td>
                </tr>
                <tr>
                    <td>Grand Total</td>
                    <td>{{this.totalCost_odcdist}}</td> 
                    <td>{{this.totalPercent_odcdist}}</td> 
                </tr>
            </tbody>
        </table>
    </div>
</div>