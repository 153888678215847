import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardLayoutComponent, PanelModel } from '@syncfusion/ej2-angular-layouts';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { NotificationService } from 'src/app/_services/notification/notification.service';
import { UserService } from 'src/app/_services/user/user.service';
import * as moment from 'moment';
import { HolidaycalenderService } from 'src/app/_services/holidaycalender/holidaycalender.service';
import {
  View, EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, ResizeService,
  DragAndDropService, NavigatingEventArgs, ActionEventArgs, ScheduleComponent, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService
} from '@syncfusion/ej2-angular-schedule';
import { AttendanceService } from 'src/app/_services/attendance/attendance.service';
import { extend } from '@syncfusion/ej2-base';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
  providers: [DatePipe, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService]
})
export class IndexComponent implements OnInit {

  @ViewChild('predefine_dashboard')
  public dashboard: DashboardLayoutComponent;
  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent;

  public data: object[] = [];
  public eventSettings: EventSettingsModel = {
    //dataSource: this.data,
    dataSource: null,
    allowAdding: false,
    allowEditing: false,
    allowDeleting: false,
  }
  public currentView: View = 'Month';
  public showWeekend: boolean = true;
  public jsonData;
  public showWeekNumber: boolean = true;
  public moment = moment;
  public isNoData: boolean = false;
  public panels: any = [
    {
      'panel1': { 'sizeX': 4, 'sizeY': 3, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 2, 'sizeY': 3, 'row': 0, 'col': 4 },
      'panel3': { 'sizeX': 6, 'sizeY': 2, 'row': 3, 'col': 0 }
    },
    {
      'panel1': { 'sizeX': 6, 'sizeY': 1, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 2, 'sizeY': 3, 'row': 1, 'col': 0 },
      'panel3': { 'sizeX': 4, 'sizeY': 3, 'row': 1, 'col': 2 },
      'panel4': { 'sizeX': 6, 'sizeY': 1, 'row': 4, 'col': 0 }
    },
    {
      'panel1': { 'sizeX': 6, 'sizeY': 1, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 3, 'sizeY': 3, 'row': 1, 'col': 0 },
      'panel3': { 'sizeX': 3, 'sizeY': 3, 'row': 1, 'col': 3 },
      'panel4': { 'sizeX': 6, 'sizeY': 1, 'row': 4, 'col': 0 }
    },
    {
      'panel1': { 'sizeX': 6, 'sizeY': 1, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 2, 'sizeY': 3, 'row': 1, 'col': 0 },
      'panel3': { 'sizeX': 2, 'sizeY': 3, 'row': 1, 'col': 2 },
      'panel4': { 'sizeX': 2, 'sizeY': 3, 'row': 1, 'col': 4 },
      'panel5': { 'sizeX': 6, 'sizeY': 1, 'row': 4, 'col': 0 }
    },
    {
      'panel1': { 'sizeX': 6, 'sizeY': 1, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 4, 'sizeY': 3, 'row': 1, 'col': 0 },
      'panel3': { 'sizeX': 2, 'sizeY': 3, 'row': 1, 'col': 4 },
      'panel4': { 'sizeX': 6, 'sizeY': 1, 'row': 4, 'col': 0 }
    },
    {
      'panel1': { 'sizeX': 2, 'sizeY': 3, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 2, 'sizeY': 3, 'row': 0, 'col': 2 },
      'panel3': { 'sizeX': 2, 'sizeY': 3, 'row': 0, 'col': 4 },
      'panel4': { 'sizeX': 6, 'sizeY': 2, 'row': 3, 'col': 0 }
    },
    {
      'panel1': { 'sizeX': 4, 'sizeY': 3, 'row': 0, 'col': 0 },
      'panel2': { 'sizeX': 2, 'sizeY': 3, 'row': 0, 'col': 4 },
      'panel3': { 'sizeX': 6, 'sizeY': 2, 'row': 3, 'col': 0 }
    }
  ];
  public panelsData: PanelModel[] = []
  public cellSpacing: number[] = [10, 10];
  public headerCount: number = 1;
  public notifications:any;
  dashboardIndexNum: number;
  currentUser: any;
  public salaryMetadata: any;
  earnleave: number;
  compensatoryleave: number;
  today= new Date();
  currentDate: any;
  leaveData= [];

  constructor(
    private userService: UserService,
    private storageService: LocalStorageService,
    private notificationService: NotificationService,
    private holidaycalenderService: HolidaycalenderService,
    private attendanceService: AttendanceService,
    private HrService: HrService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getNotification();
    this.getUserLeveBalance(currentUser.id);
    this.getUserLeaveDetails(currentUser.id);
    this.userService.getUser(currentUser.id).subscribe((response: any) => {
      this.dashboardIndexNum = response.dashboardIndex
      this.updatePanel(response.dashboardIndex)
    });
   this.getAllHolidays();

  }

  getAllHolidays() {
    this.holidaycalenderService.getAllHolidays().subscribe((response:any) => {
      this.jsonData = response.result;
      for (let index = 0; index < response.result.length; index++) {
        let element = response.result[index];
        let date = response.result[index].holidayDate.split('-');
        this.data.push({
          Id: response.result[index].id,
          Subject: response.result[index].name,
          StartTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
          EndTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
          IsAllDay: true
        });
      }
      this.eventSettings = {
        dataSource: this.data
      };
    });
  }

  /**
   * get day from dat
   * @param index 
   */
   getDays(index) {
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
   return weekday[index];
  }

  public count: number = 8;

  updatePanel(idx: number) {
    this.panelsData = []
    for (let i in this.panels[idx]) {
      this.panelsData.push({
        'sizeX': this.panels[idx][i]['sizeX'],
        'sizeY': this.panels[idx][i]['sizeY'],
        'row': this.panels[idx][i]['row'],
        'col': this.panels[idx][i]['col'],
        header: '<div class="e-header-text">Header Area</div><div class="header-border"></div>',
        content: '<div class="panel-content">Content Area</div>'
      })
    }
  }

  onAdd(): void {
    this.count = this.count + 1;
    let panel: PanelModel[] = [{
      'id': this.count.toString() + '_layout', 'sizeX': 2, 'sizeY': 2, 'row': 0, 'col': 0,
      content: '<div></div>',
      header: '<div>Panel ' + this.count.toString() + '</div>'
    }];
    this.dashboard.addPanel(panel[0]);
  }


  /**
   * get dashboard notification
   */
  getNotification() {
    this.notificationService.getDashboardNotifications().subscribe((response) => {
    if(response && Object.keys(response).length > 0) {
      this.notifications = response;
      this.isNoData = false;
    } else {
      this.isNoData = true;
    }
    })
  }

 

  // Leave detail info 
  getUserLeveBalance(id){
    this.HrService.getUserLeveBalance(id).subscribe(
      (response) => {
        for (let item of response.result) {
          this.earnleave = response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0].balance : 0;
          this.compensatoryleave = response.result.filter((leave) => leave.leaveType === 'Compensatory leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Compensatory leave')[0].balance : 0;
        }
      }
    );
  }

  getUserLeaveDetails(id){
    let today = this.today;
    this.currentDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);
    let endDate = this.datePipe.transform(lastDayOfMonth, 'yyyy-MM-dd');

    this.HrService.getUserLeaveApplicationDetails(id).subscribe((response: any) => {
      for(let i = 0; i < response.count; i++){
        if(response.result[i].startDate > this.currentDate && response.result[i].endDate <= endDate){
          this.leaveData.push(response.result[i]);
        }
      }

    });
  }

}
