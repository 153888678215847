<div class="heading">
    <h2>Manpower Distribution(Engineering Level)</h2>
</div>
<div class="data">
    <div class="canvas-table">
        <table class="table">
            <thead>
                <tr>
                    <th>Engineering Level</th>
                    <th>Hours</th>
                    <th>Hours(%)</th>
                    <th>Average FTE </th>
                    <th>Peak FTE</th>
                    <th>Hourly Rate</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataSource_elmd">
                    <td>{{item.engineeringLevel}}</td>
                    <td>{{item.hours}}</td>
                    <td>{{item.hourPercent}}</td>
                    <td>{{item.averageFte}}</td>
                    <td>{{item.peakFte}}</td>
                    <td>{{item.hourlyRate}}</td>
                </tr>
                <tr>
                    <td>Grand Total</td>
                    <td>{{this.totalHours_manpowerdistel | number}}</td>
                    <td>{{this.totalHourPercent_manpowerdistel | number}}</td>
                    <td>{{this.totalAvgFte_manpowerdistel | number}}</td>
                    <td>{{this.totalPeakFte_manpowerdistel | number}}</td>
                    <td>==</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>