import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from "../../../environments/environment";
import { LocalStorageService } from "../localStorage/local-storage.service";
import { NgxPermissionsService } from "ngx-permissions";

import { catchError, map, retry } from 'rxjs/operators';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(private http: HttpClient, public router: Router, private storageService: LocalStorageService,
    private permissionsService: NgxPermissionsService) { }

 
  get nativeWindow(): any {
    return _window();
  }

  createPaymentOrder(data: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/orders?${data}`,'').pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Authentication error!';
    if (error.error instanceof ErrorEvent) {
      // Client side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
