import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { QuotesComponent } from "./quotes/quotes.component";
import { OpportunitiesRoutingModule } from './opportunities-routing.module';
import { QuoteDetailsComponent } from './quote-details/quote-details.component';
import { OpportunityComponent } from './opportunity/opportunity.component';
import { ManpowerPlanningComponent } from './manpower-planning/manpower-planning.component';
import { OdcComponent } from './odc/odc.component';
import { TaskCostSummaryComponent } from './task-cost-summary/task-cost-summary.component';
import { BusinessUnitSummaryComponent } from './business-unit-summary/business-unit-summary.component';
import { OdcDistributionComponent } from './odc-distribution/odc-distribution.component';
import { ManpowerDistributionComponent } from './manpower-distribution/manpower-distribution.component';
import { CostcenterLevelRatesComponent } from './costcenter-level-rates/costcenter-level-rates.component';
import { SoftwareRatesComponent } from './software-rates/software-rates.component';
import { QuotesDashboardComponent } from './quotes-dashboard/quotes-dashboard.component';
// import { DataTablesModule } from "angular-datatables";
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import { ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule, CategoryService, ColumnSeriesService, ExportService, LegendService, DataLabelService } from '@syncfusion/ej2-angular-charts';

@NgModule({
  declarations: [
    QuotesComponent,
    QuoteDetailsComponent,
    OpportunityComponent,
    ManpowerPlanningComponent,
    OdcComponent,
    TaskCostSummaryComponent,
    BusinessUnitSummaryComponent,
    OdcDistributionComponent,
    ManpowerDistributionComponent,
    CostcenterLevelRatesComponent,
    SoftwareRatesComponent,
    QuotesDashboardComponent
  ],
  imports: [
    CommonModule,
    OpportunitiesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    ChartAllModule, AccumulationChartAllModule, RangeNavigatorAllModule,

    // DataTablesModule
  ],

  providers: [CategoryService, ColumnSeriesService, ExportService, LegendService, DataLabelService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class OpportunitiesModule { }
