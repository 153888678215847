import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { AdminstrationRoutingModule } from './adminstration-routing.module';
import { CostrateComponent } from "./costrate/costrate.component";
import { CostcenterComponent } from "./costcenter/costcenter.component";
import { SoftwareratesComponent } from "./softwarerates/softwarerates.component";
import { UnitComponent } from "./unit/unit.component";
import { UsersComponent } from './users/users.component';
import { CustomTagsComponent } from './custom-tags/custom-tags.component';
import { ResourceAllocationProjectComponent } from './resource-allocation-project/resource-allocation-project.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationCreateComponent } from './notification-create/notification-create.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ProductStructureComponent } from './product-structure/product-structure.component';
import { PerformanceTemplatesComponent } from './performance-templates/performance-templates.component';
import { GlobalSettingComponent } from './global-setting/global-setting.component';
import { OnsiteEmployeeComponent } from './onsite-employee/onsite-employee.component';

@NgModule({
  declarations: [
    CostrateComponent,
    CostcenterComponent,
    SoftwareratesComponent,
    UnitComponent,
    UsersComponent,
    CustomTagsComponent,
    ResourceAllocationProjectComponent,
    NotificationComponent,
    NotificationCreateComponent,
    ProductStructureComponent,
    PerformanceTemplatesComponent,
    GlobalSettingComponent,
    OnsiteEmployeeComponent,
    // CalenderHolidayComponent
  ],
  imports: [
    CommonModule,
    MatSlideToggleModule,
    AdminstrationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AgGridModule.withComponents([]),
  ]
})
export class AdminstrationModule { }
