
<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Employee List </h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" title="Edit" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#timesheet">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div> 
                            <!--<div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#deleteTimesheetModal" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div>
                                <button type="button" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#timesheet" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div> -->
                        </div>
                    </div>
                    <ag-grid-angular
                        style="height: calc(100vh - 120px) !important;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                   </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Timesheet -->
<div class="modal fade bd-example-modal-lg" id="timesheet" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="usersForm">
                <div class="modal-header">
                    <h5 class="modal-title">Update Employee </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <nav>
                    <div class="nav nav-tabs d-flex justify-content-between mb-2" id="nav-tab">
                      <a class="nav-item nav-link active p-2" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" >General</a>
                      <a class="nav-item nav-link p-2" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" >Organization</a>
                      <a class="nav-item nav-link p-2" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" >Storage</a>
                    </div>
                </nav>
                <div class="modal-body"  id="nav-tabContent">
                    <div class="tab-content pt-2" id="nav-tabContent">
                  <div class="tab-pane fade show active"   id="nav-home" role="tabpanel">
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="userType" type="text" required>
                            <label for="userType" class="floating-label">User Type</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="firstName" type="text" required>
                            <label for="firstName" class="floating-label">First Name*</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="lastName" type="text" required>
                            <label for="lastName" class="floating-label">Last Name</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="displayName" type="text" required>
                            <label for="displayName" class="floating-label">Display Name*</label>
                            <i class="ri-file-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                          <input formControlName="localId" type="text" />
                          <label for="localId" class="floating-label-special"
                            >Employee Id*</label
                          >
                          <i class="ri-user-line"></i>
                        </div>
                      </div>
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="userId" type="text" required>
                            <label for="userId" class="floating-label">User Id</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div> -->
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="password" type="password" required>
                            <label for="password" class="floating-label">Password</label>
                            <i class="ri-lock-line"></i>
                        </div>
                    </div> -->
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="supervisorId" type="text" required>
                            <label for="supervisorId" class="floating-label">Supervisor Id</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <!-- <input formControlName="supervisonName" type="text" required>
                            <label for="supervisonName" class="floating-label">Supervison Name</label> -->  
                            <input
                                list="taskOwnerDatalist"
                                formControlName="supervisonName"
                                placeholder="Supervison Name"
                            />
                            <datalist id="taskOwnerDatalist">
                                <option
                                [value]="item"
                                *ngFor="let item of userDropdownList"
                                ></option>
                            </datalist>
                            <label class="floating-label">Supervison Name</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="panNo" type="text" required>
                            <label for="panNo" class="floating-label">Pan No</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div>
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="hrId" type="text" required>
                            <label for="hrId" class="floating-label">Hr Id</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="hrName" type="text" required>
                            <label for="hrName" class="floating-label">Hr Name</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div>
                  </div> 
                  <div class="tab-pane fade" id="nav-profile" role="tabpanel">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="birthDate" type="date" required>
                            <label for="birthDate" class="floating-label">Birth Date</label>
                            <i class="ri-file-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="department" type="text" required>
                            <label for="department" class="floating-label">Department*</label>
                            <i class="ri-building-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input  formControlName="emailId" type="text" required>
                            <label for="emailId" class="floating-label">Email Id*</label>
                            <i class="ri-mail-unread-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input  formControlName="alternateEmailId" type="text" required>
                            <label for="alternateEmailId" class="floating-label">Alternate Email Id*</label>
                            <i class="ri-mail-unread-line"></i>
                        </div>
                    </div> 
                    
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="phoneNo" type="text" required>
                            <label for="phoneNo" class="floating-label">Phone No*</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special"> Marriage Status
                                </label>
                             <select formControlName="marriageStatus" (change)="checkmarritalstatus($event.target.value)">
                               <option value="" disabled selected>
                                 Marriage Status
                               </option>
                               <option value="Married">Married</option>
                               <option value="UnMarried">UnMarried</option>
                             </select>
                             <i class="ri-heart-fill"></i>
                        </div>
                    </div>
                    <div class="input" *ngIf="this.marriagedate === 'Married'">
                        <div class="input-item">
                            <input formControlName="marriageDate" type="date" required>
                            <label for="marriageDate" class="floating-label">Marriage Date</label>
                            <i class="ri-heart-fill"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="adhaarNo" type="password" required>
                            <label for="adhaarNo" class="floating-label">Adhaar No</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="bankAccountNo" type="password" required>
                            <label for="bankAccountNo" class="floating-label">Bank Account No</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="bankName" type="text" required>
                            <label for="bankName" class="floating-label">Bank Name</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="bankIfscCode" type="text" required>
                            <label for="bankIfscCode" class="floating-label">IFSC Code</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="admin" type="text" required>
                            <label for="admin" class="floating-label">Admin</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-contact" role="tabpanel">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="location" type="text" required>
                            <label for="location" class="floating-label">Location*</label>
                            <i class="ri-map-pin-line"></i>
                        </div>
                    </div>
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="branch" type="text" required>
                            <label for="branch" class="floating-label">Branch</label>
                            <i class="ri-building-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="groups" type="text" required>
                            <label for="groups" class="floating-label">Groups</label>
                            <i class="ri-group-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="costCenter" type="text" required>
                            <label for="costCenter" class="floating-label">Cost Center</label>
                            <i class="ri-money-dollar-circle-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="localId" type="text" required>
                            <label for="localId" class="floating-label">Local Id</label>
                            <i class="ri-map-pin-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="employeeCategory" type="text" required>
                            <label for="employeeCategory" class="floating-label">Employee Category</label>
                            <i class="ri-stack-line"></i>
                        </div>
                    </div>
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="level" type="text" required>
                            <label for="level" class="floating-label">Level</label>
                            <i class="ri-bar-chart-box-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="joinDate" type="date" required>
                            <label for="joinDate" class="floating-label">Join Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="quitDate" type="date" required>
                            <label for="quitDate" class="floating-label">Quit Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                     <div class="input">
                        <div class="input-item">
                            <input formControlName="permanentOrContractor" type="text" required>
                            <label for="permanentOrContractor" class="floating-label">Permanent</label>
                            <i class="ri-24-hours-line"></i>
                        </div>
                    </div>
                   <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="localOrExpat" type="text" required>
                            <label for="localOrExpat" class="floating-label">Local</label>
                            <i class="ri-map-pin-time-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="level2" type="text" required>
                            <label for="level2" class="floating-label">Level2</label>
                            <i class="ri-bar-chart-box-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="resourceType" type="text" required>
                            <label for="resourceType" class="floating-label">Resource Type</label>
                            <i class="ri-file-settings-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                          <label
                            class="floating-label floating-label-special"
                          >Onboard Status</label>
                          <select formControlName="onboardStatus" >
                            <option value="" disabled selected>Select Onboard Status</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <i class="ri-line-chart-line"></i>
                        </div>
                      </div>
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="joinWeek" type="text" required>
                            <label for="joinWeek" class="floating-label">Join Week</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="quitWeek" type="text" required>
                            <label for="quitWeek" class="floating-label">Quit Week</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div> -->
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="archived" type="text" required>
                            <label for="archived" class="floating-label">Archived</label>
                            <i class="ri-inbox-archive-line"></i>
                        </div>
                    </div> -->
                   </div> 
                    </div>
                </div>          
                <div class="modal-footer">
                    <button type="button" id="closeUserEditModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateUser()" >Update</button> 
                </div>
            </form>
        </div>
    </div>
</div>
