import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/_services/data-share/data.service';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { LocalStorageService } from "../../../../_services/localStorage/local-storage.service";
import { QuoteService } from "../../../../_services/quote/quote.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-quote-details',
  templateUrl: './quote-details.component.html',
  styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit {
  tenantId: string;
  quoteForm: FormGroup;
  quoteparametersapi: any;
  quoteParametersDetailsForm: FormGroup;
  defaultParametersDetailsForm: FormGroup;
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  manpowerPlanningData: any[] = [];

  constructor(private dataService: DataService, private formBuilder: FormBuilder, private storageService: LocalStorageService, private quoteService: QuoteService, public datepipe: DatePipe) {
    this.quoteForm = this.formBuilder.group({
      id: new FormControl(''),
      account: new FormControl('',Validators.required),
      project: new FormControl('',Validators.required),
      projectNumber: new FormControl('',Validators.required),
      projectManager: new FormControl('',Validators.required),
      responsibleUnit: new FormControl('',Validators.required),
      supportUnit: new FormControl('',Validators.required),
      startDate: new FormControl('',Validators.required),
      endDate: new FormControl('',Validators.required),
      keyAccountManager: new FormControl('',Validators.required),
      quoteName: new FormControl('')
    });

    this.quoteParametersDetailsForm = this.formBuilder.group({
      id: new FormControl(''),
      inflationRate: new FormControl('', Validators.required),
      expectedEbit: new FormControl('', Validators.required),
      riskMargin: new FormControl('', Validators.required),
      workHourPerMonth: new FormControl('', Validators.required),
      standardMonthlyWorkingHours: new FormControl(''),
      subContractorWorkHourPerMonth: new FormControl('', Validators.required),
      thirdPartyWorkHourPerMonth: new FormControl('', Validators.required),
      customerTarget: new FormControl('', Validators.required),
      thirdPartySurcharge: new FormControl('', Validators.required),
      thirdPartyDiscount: new FormControl('', Validators.required),
      vat: new FormControl('', Validators.required),
      sgst: new FormControl('', Validators.required),
      cgst: new FormControl('', Validators.required),
      subcontractDiscount: new FormControl('', Validators.required),
      subcontractSurcharge: new FormControl('', Validators.required)
    });

    this.defaultParametersDetailsForm = this.formBuilder.group({
      id: new FormControl(''),
      annualInflationRate: new FormControl(''),
      standardEbit: new FormControl(''),
      standardRiskMargin: new FormControl(''),
      standardHandlingSurcharge: new FormControl(''),
      standardMonthlyWorkingHours: new FormControl(''),
      standardSubContractorWorkHourPerMonth: new FormControl(''),
      standardThirdPartyWorkHourPerMonth: new FormControl(''),
      standardCustomerTarget: new FormControl(''),
      standardThirdPartySurcharge: new FormControl(''),
      standardThirdPartyDiscount: new FormControl(''),
      standardvat: new FormControl(''),
      standardSgst: new FormControl(''),
      standardCgst: new FormControl(''),
      standardSubcontractDiscount: new FormControl(''),
      standardSubcontractSurcharge: new FormControl('')
    });
   }

   
  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');

    this.getQuoteDetails();
    this.getQuoteParametersDetails();
    this.getDefaultParametersDetails();
  }
  // getter for easy access to form fields
  get f() {
    return this.quoteForm.controls;
  }

  getQuoteDetails() {
    this.dataService.quoteUpdate.subscribe((details: any) => {
      this.quoteForm.patchValue(this.quoteObject);
    });
  }

  getQuoteParametersDetails() {
    this.quoteService.getQuoteParametersDetails(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      this.quoteparametersapi = JSON.stringify(response);
      this.quoteParametersDetailsForm.patchValue(response);
    });
  }

  getDefaultParametersDetails() {
    this.quoteService.getDefaultParametersDetails(this.opportunityObject.id, this.quoteObject.id).subscribe((response:any) => {
      this.defaultParametersDetailsForm.patchValue(response);
    });
  }

  updateQuoteGeneralDetails() {
    var updateqgeneraldata = {
      id: this.quoteForm.get('id').value,
      account: this.quoteForm.get('account').value,
      project: this.quoteForm.get('project').value,
      projectNumber: this.quoteForm.get('projectNumber').value,
      projectManager: this.quoteForm.get('projectManager').value,
      responsibleUnit: this.quoteForm.get('responsibleUnit').value,
      supportUnit: this.quoteForm.get('supportUnit').value,
      startDate: this.quoteForm.get('startDate').value,
      endDate: this.quoteForm.get('endDate').value,
      keyAccountManager: this.quoteForm.get('keyAccountManager').value,
      quoteName: this.quoteForm.get('quoteName').value,
    }
    this.quoteService.updateGeneralDetails(updateqgeneraldata, this.opportunityObject.id, this.quoteObject.id, updateqgeneraldata['id']).subscribe((response:any) => {
      this.quoteForm.patchValue(response);
    });
  }
  
  updateQuoteParametersDetails() {
    var pushquotetoquote = {
      inflationRate: this.quoteParametersDetailsForm.get('inflationRate').value,
      expectedEbit: this.quoteParametersDetailsForm.get('expectedEbit').value,
      riskMargin: this.quoteParametersDetailsForm.get('riskMargin').value,
      workHourPerMonth: this.quoteParametersDetailsForm.get('workHourPerMonth').value,
      subContractorWorkHourPerMonth: this.quoteParametersDetailsForm.get('subContractorWorkHourPerMonth').value,
      thirdPartyWorkHourPerMonth: this.quoteParametersDetailsForm.get('thirdPartyWorkHourPerMonth').value,
      customerTarget: this.quoteParametersDetailsForm.get('customerTarget').value,
      thirdPartySurcharge: this.quoteParametersDetailsForm.get('thirdPartySurcharge').value,
      thirdPartyDiscount: this.quoteParametersDetailsForm.get('thirdPartyDiscount').value,
      vat: this.quoteParametersDetailsForm.get('vat').value,
      sgst: this.quoteParametersDetailsForm.get('sgst').value,
      cgst: this.quoteParametersDetailsForm.get('cgst').value,
      subcontractDiscount: this.quoteParametersDetailsForm.get('subcontractDiscount').value,
      subcontractSurcharge: this.quoteParametersDetailsForm.get('subcontractSurcharge').value,
    }
    var pushdeftoquote = {
      inflationRate: this.defaultParametersDetailsForm.get('annualInflationRate').value,
      expectedEbit: this.defaultParametersDetailsForm.get('standardEbit').value,
      riskMargin: this.defaultParametersDetailsForm.get('standardRiskMargin').value,
      workHourPerMonth: this.defaultParametersDetailsForm.get('standardMonthlyWorkingHours').value,
      subContractorWorkHourPerMonth: this.defaultParametersDetailsForm.get('standardSubContractorWorkHourPerMonth').value,
      thirdPartyWorkHourPerMonth: this.defaultParametersDetailsForm.get('standardThirdPartyWorkHourPerMonth').value,
      customerTarget: this.defaultParametersDetailsForm.get('standardCustomerTarget').value,
      thirdPartySurcharge: this.defaultParametersDetailsForm.get('standardThirdPartySurcharge').value,
      thirdPartyDiscount: this.defaultParametersDetailsForm.get('standardThirdPartyDiscount').value,
      vat: this.defaultParametersDetailsForm.get('standardvat').value,
      sgst: this.defaultParametersDetailsForm.get('standardSgst').value,
      cgst: this.defaultParametersDetailsForm.get('standardCgst').value,
      subcontractDiscount: this.defaultParametersDetailsForm.get('standardSubcontractDiscount').value,
      subcontractSurcharge: this.defaultParametersDetailsForm.get('standardSubcontractSurcharge').value,
    }

    if (this.quoteparametersapi == 'null') {
      var updateqpdata = pushdeftoquote;
    } else {
      var updateqpdata = pushquotetoquote;
    }

    this.quoteService.updateQuoteParameters(updateqpdata, this.opportunityObject.id, this.quoteObject.id).subscribe((response:any) => {
      this.quoteParametersDetailsForm.patchValue(response);
      if (this.quoteparametersapi == 'null') {
        window.alert("Default parameters loaded to quote");
      } else {
        window.alert("Update Quote parameters Successful");
      }
    });
  }

  sidebarOpen = false;
  openCloseInnerSidebar() {
    this.sidebarOpen = !this.sidebarOpen
  }

  updateManpowerPlanningEvent(e) {
    this.manpowerPlanningData = e;
  }
}
