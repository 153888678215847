<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center"> 
                                <div class="edit">
                                    <button title="back page" (click)="backToExit()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Exit Process</h3>
                            </div>
                        </div>
                    </div>
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6 d-flex">
                            <h2>Apply for Resignation</h2>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <form [formGroup]="exitProcessForm">
                            <div class="row">
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="userId">
                                            <label class="floating-label-special">Name of Employee</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="designation">
                                            <label class="floating-label-special">Designation</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="employeeId">
                                            <label class="floating-label-special">Employee ID</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="resignationDate">
                                            <label class="floating-label-special">Date of Submission</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="lastWorkingDate">
                                            <label class="floating-label-special">Required Last Working Day</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="alternateEmailId">
                                            <label class="floating-label-special">Alternate Email Id</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="alternateContactNo">
                                            <label class="floating-label-special">Alternate Contact No.</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <select formControlName="reason">
                                                <option value="" disabled selected>Select Reason</option>
                                                <option value="New Opportunity">New Opportunity</option>
                                                <option value="Medical Reason">Medical Reason</option>
                                                <option value="Family Issue">Family Issue</option>
                                                <option value="Accident">Accident</option>
                                                <option value="Personal Reason">Personal Reason</option>
                                                <option value="Compensation">Compensation</option>
                                                <option value="Job Role">Job Role</option>
                                                <option value="Growth Opportunity">Growth Opportunity</option>
                                                <option value="Company benefits">Company benefits</option>
                                                <option value="Relocation to other city/country">Relocation to other city/country</option>
                                                <option value="Other">Other</option>
                                            </select>
                                            <label class="floating-label-special">Reason</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 px-3 py-1" style="padding-bottom: 10px;">
                                    <div class="input">
                                        <div class="input-item">
                                            <textarea name="" id="" cols="20" rows="3" formControlName="comment"></textarea>
                                            <label class="floating-label-special">Comment</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <button class="btn-secondary btn" (click)="backToExit()">Cancel</button>
                                    <button class="btn" type="button" title="Confirm" data-bs-toggle="modal" data-bs-target="#confirmResignation">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Confirm Resignation Modal -->
<div class="modal fade" id="confirmResignation" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to submit the resignation?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="createResignationForm()">Yes</button>
                    <button id="closeModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
                </div>
            </form>
        </div>
    </div>
</div>
