<main *ngIf="jsonData; else noData" class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Opportunity</h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <button type="button" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#opportunity" class="btn btn-icon"><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="canvas-table">
                            <!-- <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table"> -->
                            <table class="table" datatable  id="datatable">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Account</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Deadline</th>
                                        <th>Gross Price</th>
                                        <th>Probability</th>
                                        <th>Wightedsalesvalue</th>
                                        <th>Status</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of jsonData">
                                        <td><a (click)="rowData(item)">{{item.opportunityName}}</a></td>
                                        <td>{{item.account}}</td>
                                        <td>{{item.startDate}}</td>
                                        <td>{{item.endDate}}</td>
                                        <td>{{item.submissionDeadline}}</td>
                                        <td>{{item.grossPrice}}</td>
                                        <td>{{item.probability}}</td>
                                        <td>{{item.salesWeightedValue}}</td>
                                        <td>{{item.status}}</td>
                                        <td>
                                            <div class="table-action">
                                                <button class="action-btn"><i class="ri-more-2-fill"></i></button>
                                                <div class="action-items d-flex">
                                                    <div class="edit">
                                                        <button data-bs-toggle="modal" data-bs-target="#editOpportunityModal" type="button" (click)="openEdit(item)">
                                                            <span><i class="ri-pencil-line"></i></span>
                                                            
                                                        </button>
                                                    </div>
                                                    <div class="delete">
                                                        <button data-bs-toggle="modal" data-bs-target="#deleteOpportunityModal" type="button" (click)="open(item)">
                                                            <span><i class="ri-delete-bin-5-line"></i></span>
                                                            
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>

<ng-template #noData>
    <main class="main-dashboard">
        <article class="main-canvas">
            <section class="canvas-container">
                <section class="dashboard-items">
                    <div class="canvas-item">
                        <div class="canvas-header">
                            <div class="title">
                                <h3>Welcome Back {{currentUser.displayName}}!</h3>
                            </div>
                        </div>
                        <div class="canvas-data">
                            <div class="canvas-illustartor">
                                <img src="assets/svg/add-opportunity.svg" alt="">
                                <p>Nothing found</p>
                                <a href="#opportunity" type="button" data-bs-toggle="modal"
                                    data-bs-target="#opportunity" class="btn btn-big">Add Opportunity</a>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </article>
    </main>
</ng-template>
  
<!-- Create new Opportunity -->
<div class="modal fade" id="opportunity" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="opportunityForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create new Opportunity</h5>
                    <button type="button" class="btn-close bg-danger text-white p-3" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="opportunityName" type="text" required >
                            <label for="opportunityName" class="floating-label">Opportunity Name</label>
                            <i class="ri-hand-coin-line"></i>
                            <mat-error *ngIf="f.opportunityName.invalid && (f.opportunityName.touched || f.opportunityName.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.opportunityName.errors.required">Opportinity Name is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            
                            <input list="accountNameDatalist" formControlName="account" placeholder="Select account name" (change)="getSelectedAccountId($event)">
                            <label class="floating-label">Account Name</label>
                                <datalist id="accountNameDatalist">
                                    <option [value]="item.accountName" *ngFor="let item of accountDropdownList">{{item.accountName}}</option>
                                </datalist>                           
                            <i class="ri-account-box-line"></i>
                            <mat-error *ngIf="f.account.invalid  && (f.account.touched || f.account.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.account.errors.required">Account is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="startDate" type="date" required >
                            <label for="startDate" class="floating-label">Start Date</label>
                            <i class="ri-calendar-check-line"></i>
                            <mat-error *ngIf="f.startDate.invalid && (f.startDate.touched || f.startDate.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="endDate" type="date" required >
                            <label for="endDate" class="floating-label">End Date</label>
                            <i class="ri-calendar-check-line"></i>
                            <mat-error *ngIf="f.endDate.invalid && ( f.endDate.touched || f.endDate.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.endDate.errors.required">End Date is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="submissionDeadline" type="date" required >
                            <label for="submissionDeadline" class="floating-label">Submission Deadline</label>
                            <i class="ri-calendar-check-line"></i>
                            <mat-error *ngIf="f.submissionDeadline.invalid && (f.submissionDeadline.touched || f.submissionDeadline.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.submissionDeadline.errors.required">Submission Deadline is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="grossPrice" type="number" min="0">
                            <label for="grossPrice" class="floating-label">Gross Price</label>
                            <i class="ri-money-dollar-circle-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label for="probability" class="floating-label floating-label-special">Probabilty</label>
                            <select formControlName="probability">
                                <option value="" disabled selected>Select Probability</option>
                                <option value="1">1%</option>
                                <option value="5">5%</option>
                                <option value="50">50%</option>
                                <option value="75">75%</option>
                                <option value="90">90%</option>
                            </select>
                            <i class="ri-line-chart-line"></i>
                            <mat-error *ngIf="f.probability.invalid && (f.probability.touched || f.probability.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.probability.errors.required">Probability is required</mat-error>
                            </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="salesWeightedValue" type="number" min="0">
                            <label for="salesWeightedValue" class="floating-label">Weighted Sales Value</label>
                            <i class="ri-coupon-5-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label for="status" class="floating-label floating-label-special">Status</label>
                            <select formControlName="status">
                                <option value="qualify">Qualify</option>
                                <option value="need analysis value proposition">Need Analysis Value Proposition</option>
                                <option value="approval price quote">Approval Price Quote</option>
                                <option value="negotiation review">Negotiation Review</option>
                                <option value="won">Won</option>
                                <option value="lost">Lost</option>
                            </select>
                            <i class="ri-24-hours-line"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalOpportunity" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="createOpportunity()" [disabled]="!opportunityForm.valid" >Create Opportunity</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteOpportunityModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Opportunity</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>Are you sure you want to delete {{selectedRow?.opportunityName}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteOpportunity" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="deleteOpportunity(selectedRow?.id)">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Edit an item -->
<div class="modal fade" id="editOpportunityModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="opportunityForm">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Opportunity</h5>
                    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal"></button> -->
                    <button type="button" class="btn-close p-2 bg-danger" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="opportunityName" type="text" required>
                            <label for="opportunityName" class="floating-label">Opportunity Name</label>
                            <i class="ri-hand-coin-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="account" type="text" required>
                            <label for="account" class="floating-label">Account Name</label>
                            <i class="ri-account-box-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="startDate" type="date" required>
                            <label for="startDate" class="floating-label">Start Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="endDate" type="date" required>
                            <label for="endDate" class="floating-label">End Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="submissionDeadline" type="text" required>
                            <label for="submissionDeadline" class="floating-label">Submission Deadline</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="grossPrice" type="number" min="0">
                            <label for="grossPrice" class="floating-label">Gross Price</label>
                            <i class="ri-money-dollar-circle-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label for="probability" class="floating-label">Probabilty</label>
                            <select formControlName="probability">
                                <option value="" disabled selected>Select Probability</option>
                                <option value="1">1%</option>
                                <option value="5">5%</option>
                                <option value="50">50%</option>
                                <option value="75">75%</option>
                                <option value="90">90%</option>
                            </select>
                            <i class="ri-line-chart-line"></i>
                            <mat-error *ngIf="f.probability.invalid && (f.probability.touched || f.probability.dirty )" class="alert alert-danger">
                                <mat-error *ngIf="f.probability.errors.required">Probability is required</mat-error>
                            </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="wightedsalesvalue" type="number" min="0">
                            <label for="wightedsalesvalue" class="floating-label">Wightedsalesvalue</label>
                            <i class="ri-coupon-5-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="status" type="text">
                            <label for="status" class="floating-label">Status</label>
                            <i class="ri-24-hours-line"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalEditOpportunity" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"  (click)="updateOpportunity(selectedRow?.id)">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>