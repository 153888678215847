import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class HelpdeskService extends Subject<DataStateChangeEventArgs>  {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) { 
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
  return throwError(errorMessage);
  }

   // Create Incidents
   createIncidents(user: Object): Observable<Object> {
    console.log("In createIncidents method, incidents: ", user);
    return this.http.post(`${environment.apiUrl}/incidents`, user).pipe(catchError(this.handleError));
  }

  // Get Incidents by Id Get
  getIncidents(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/incidents/${id}`).pipe(catchError(this.handleError));
  }

  //Delete incidents by Id Delete  
  deleteIncidents(id: number): Observable<Object> {
  return this.http.delete(`${environment.apiUrl}/incidents/${id}`).pipe(catchError(this.handleError));
  }


   //Update incidents by Id Put
   updateIncidents(user: Object,id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/incidents/${id}`, user).pipe(catchError(this.handleError));
    }

    //Get All Incidents 
    getAllIncidents(): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/incidents`).pipe(catchError(this.handleError));
      }

      
  uploadImage(id: number, data: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.httpClient.post(`${environment.apiUrl}/incidents/` + id + `/attachments`, data, header).pipe(catchError(this.handleError));
  }

  gethdApplicationsattachments(Id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/incidents/${Id}/attachments`).pipe(catchError(this.handleError));
  }

  deletehdApplicationsattachments(hdId: any, imageid: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/incidents/${hdId}/attachments/${imageid}`).pipe(catchError(this.handleError));
  }

  downloadhdApplicationsattachments(hdId: any, imageid: number): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.http.get(`${environment.apiUrl}/incidents/${hdId}/attachments/${imageid}`).pipe(catchError(this.handleError));
  }

  public gethelpdeskApplicationsattachments(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/incidents/${Id}/attachments`).pipe(catchError(this.handleError)
      );
  }

  public deletehelpdeskApplicationsattachments(hdId: any, imageid: number) {
    console.log('in service, deletehelpdeskApplicationsattachments data: ', imageid);
    return this.http
      .delete<any>(`${environment.apiUrl}/incidents/${hdId}/attachments/${imageid}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  public downloadhelpdeskApplicationsattachments(hdId: any, imageid: number) {
    console.log('in service, downloadhelpdeskApplicationsattachments imageid: ', imageid);
    
    return this.http
      .get<Blob>(`${environment.apiUrl}/incidents/${hdId}/attachments/${imageid}`,{observe: 'response' ,responseType: 'blob' as 'json'})
        
  }

  public getUserHelpdeskApplicationDetailsById(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/incidents/${Id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError)
      );
  }

  updatehelpdeskappDetailsbyId(helpdeskappupdatedetailsdata: Object, id: number) {
    console.log("helpdeskappdetailsdata Details, Service response: ", helpdeskappupdatedetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/incidents/${id}`, helpdeskappupdatedetailsdata,
      )
      .pipe(
        map(result => {
          console.log("helpdeskappdetailsdata Details, rEsult Service response: ", result);
          return result;

        }),
        catchError(this.handleError),
      );
  }

  escalatehelpdeskappDetailsbyId(helpdeskappescalatedetailsdata: Object, id: number) {
    console.log("helpdeskappescalatedetailsdata Details, Service response: ", helpdeskappescalatedetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/incidents/${id}`, helpdeskappescalatedetailsdata,
      )
      .pipe(
        map(result => {
          console.log(" escalate helpdeskappdetailsdata Details, rEsult Service response: ", result);
          return result;

        }),
        catchError(this.handleError),
      );
  }

  public addhelpdeskAppRates(addhddata: Object, userId: string) {
    console.log('addhelpdeskAppRates: ', addhddata);
    return this.http
      .post<any>(`${environment.apiUrl}/incidents`, addhddata)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError)
      );
  }

  public addhelpdeskAppComments(addhdcommentsdata: Object, id: string) {
    console.log('addhelpdeskAppComments: ', addhdcommentsdata);
    return this.http
      .post<any>(`${environment.apiUrl}/incidents/${id}/comments`, addhdcommentsdata)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError)
      );
  }

  category(){
    return[
      {
        id:1,
        name:"Hardware"
      },
      {
        id:2,
        name:"Software"
      },
      {
        id:3,
        name:"Network"
      },
      {
        id:4,
        name:"Server"
      },
      {
        id:5,
        name:"Email"
      }
    ]
 }
  subCategory(){
   return [
    {
      id:1,
      parent_id:1,
      name:"WorkStation"
    },
    {
      id:2,
      parent_id:1,
      name:"Desktop"
    },
    {
      id:3,
      parent_id:1,
      name:"Laptop"
    },
    {
      id:4,
      parent_id:1,
      name:"Headphone"
    },
    {
      id:5,
      parent_id:1,
      name:"Keyboard"
    },
    {
      id:6,
      parent_id:1,
      name:"Mouse"
    }, 
    {
      id:7,
      parent_id:1,
      name:"Other"
    },
    {
      id:8,
      parent_id:2,
      name:"Catia"
    },
    {
      id:9,
      parent_id:2,
      name:"UGNX"
    },
    {
      id:10,
      parent_id:2,
      name:"HM"
    },
    {
      id:11,
      parent_id:2,
      name:"Other"
    },
    {
      id:12,
      parent_id:3,
      name:"Internet"
    },
    {
      id:13,
      parent_id:3,
      name:"LAN"
    },
    {
      id:14,
      parent_id:3,
      name:"VPN"
    },
    {
      id:15,
      parent_id:3,
      name:"Other"
    },
    {
      id:16,
      parent_id:4,
      name:"Folder Access"
    },
    {
      id:17,
      parent_id:4,
      name:"Login"
    },
    {
      id:18,
      parent_id:4,
      name:"User Lockout"
    },
    {
      id:19,
      parent_id:4,
      name:"Password Expire"
    },
    {
      id:20,
      parent_id:4,
      name:"Forget Password"
    },
    {
      id:21,
      parent_id:5,
      name:"Email Not Working"
    },
    {
      id:22,
      parent_id:5,
      name:"Forget Password"
    }, {
      id:23,
      parent_id:5,
      name:"Email Not Working on Mobile"
    },
    {
      id:24,
      parent_id:5,
      name:"Outlook Not Working"
    },
    {
      id:25,
      parent_id:5,
      name:"Other"
    },
   ]
  }
  

}
