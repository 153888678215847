<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                      <div class="title col-sm-6">
                        <div class="d-flex align-items-center">
                          <div class="edit">
                              <button title="back page" (click)="backToHiringProcess()">
                                <span><i class="ri-arrow-left-line"></i></span>
                              </button>
                          </div>
                          <h3 class="px-3">Job Description</h3>
                      </div>
                      </div>
                      <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;"> 
                        <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                            <button type="button" title="Edit" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#jobDescription">
                              <span><i class="ri-pencil-line"></i></span>
                            </button>
                        </div>
                        <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                            <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteJobModal" (click)="deleteOpenModal()" >
                              <span><i class="ri-delete-bin-5-line"></i></span>
                            </button>
                        </div>
                        <div class="d-flex gap-2">
                            <button type="button" title="Create" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#jobDescription" class="btn btn-icon">
                                <i class="ri-add-line"></i>
                            </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                      <div class="row grey-boxes">
                        <div class="col-sm-12 col-md-4 col-lg-4">
                          <div class="box">
                            <span class="line"><p class="table-box-heading">Posted Job:&nbsp;</p><p>{{postedJobCount}}</p></span>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4">
                          <div class="box">
                            <span class="line"><p class="table-box-heading">Open Job:&nbsp;</p><p>{{openJobCount}}</p></span>
                            <!-- <p class="table-box-heading">Open Job: {{openJobCount}}</p> -->
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-4 col-lg-4">
                          <div class="box">
                            <span class="line"><p class="table-box-heading">Closed Job:&nbsp;</p><p>{{closedJobCount}}</p></span>
                          </div>
                      </div>
                      </div>
                    </div>
                    <hr><br>
                    <ag-grid-angular 
                        style="height: 620px;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)"
                        [rowData]="rowData"
                        [columnDefs]="columnDefs"
                        [animateRows]="true"
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        [rowDragManaged]="true"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10
                        [suppressDragLeaveHidesColumns]="true">
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Overtime -->
<div class="modal fade bd-example-modal-lg" id="jobDescription">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="jobDescriptionForm">
        <div class="modal-header">
          <h5 class="modal-title">Create Job Post</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="input">
            <div class="input-item">
              <input formControlName="title" type="text" required />
              <label class="floating-label-special">Job Title</label>
              <i class="ri-file-list-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label class="floating-label floating-label-special">Select Work Mode </label>
              <select formControlName="workMode">
                <option value="" disabled selected></option>
                <option value="Office">Office</option>
                <option value="Remote">Remote</option>
                <option value="Both">Both</option>
                <option value="Hybrid">Hybrid</option>
              </select>
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input formControlName="location" type="text" required />
              <label class="floating-label-special">Location</label>
              <i class="ri-file-list-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label class="floating-label floating-label-special">Select Job Type</label>
              <select formControlName="jobType">
                <option value="" disabled selected></option>
                <option value="Part Time">Part Time</option>
                <option value="Full Time">Full Time</option>
              </select>
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input formControlName="expectedExperience" type="text" required />
              <label class="floating-label-special">Minimum Experience</label>
              <i class="ri-file-list-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input formControlName="expectedQualifications" type="text" required />
              <label class="floating-label-special">Minimum Qualification</label>
              <i class="ri-file-list-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input formControlName="skills" type="text" required />
              <label class="floating-label-special">Skills</label>
              <i class="ri-file-list-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label class="floating-label floating-label-special">Select Job Status </label>
              <select formControlName="active">
                <option value="" disabled selected></option>
                <option value="true">Active</option>
                <option value="false">In-Active</option>
              </select>
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label for="emailForApplicants" class="floating-label-special">Email For Applicants</label>
              <input formControlName="emailForApplicants" type="text" />
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label for="jobLink" class="floating-label-special">Job Link</label>
              <input formControlName="jobLink" type="text" required/>
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label for="creationDate" class="floating-label-special">Creation Date</label>
              <input formControlName="creationDate" type="date" required/>
              <i class="ri-calendar-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <textarea formControlName="description" type="text" rows="3" cols="100" ></textarea>
              <label for="jobDescription" class="floating-label">Job Description</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="closeModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createJobDescription()">Create</button>
          <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="editJobDescription()">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteJobModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form action="">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure want to delete?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" ></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>{{ deleteConfirmationMsg }}?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button id="closeModalDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteJobDescription()">Yes Delete</button>
        </div>
      </form>
    </div>
  </div>
</div>