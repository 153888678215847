<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                 <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Performance</h3>
                        </div>
                        <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#createForm">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#deletePerformance" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div>
                                <button type="button"  data-bs-toggle="modal" data-bs-target="#createForm" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                            <div>
                                <button type="button" data-bs-toggle="modal" data-bs-target="#importTemplates" class="btn btn-primary btn-sm">
                                    <span><i class="ri-upload-2-line"></i>Import</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="row p-0">
                            <div class="col-sm-12 p-0">
                                <div class="canvas-data px-0">
                                    <form [formGroup]="filterForm">
                                        <div class="row m-0">
                                            <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                                <div class="input">
                                                    <div class="input-item"> 
                                                        <select formControlName="department" placeholder="Select Designation">
                                                            <option value="" disabled selected>Select Designation</option>
                                                            <option value="Development Program">Development Program</option>
                                                            <option value="Lead Engineer">Lead Engineer</option>
                                                            <option value="Design Engineer">Design Engineer</option>
                                                            <option value="Dev">Dev</option>
                                                            <option value="Senior Design Engineer">Senior Design Engineer</option>
                                                        </select>
                                                        <label class="floating-label-special">Designation</label>
                                                        <i class="ri-user-line"></i>
                                                    </div>
                                                  </div>
                                            </div> 
                                            <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                                <div class="input">
                                                    <div class="input-item">
                                                        <select formControlName="year" placeholder="Select Year">
                                                            <option value="" disabled selected>Select Year</option>
                                                            <option value="2021">2021</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                        </select>
                                                        <label class="floating-label-special">Select Year</label>
                                                        <i class="ri-calendar-line"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <button type="button" class="btn" (click)="getPerformanceReport()">
                                                    <div>Submit</div>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                 </div>
            </section>
        </section>
    </article>
</main>


<!-- Create new Goal -->
<div class="modal fade bd-example-modal-lg" id="createForm" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="performancetemplateForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create Performance Template</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">               
                        <div class="input">
                            <div class="input-item">
                                <input formControlName="designamtion" type="text">
                                <label for="title" class="floating-label">Designamtion</label>
                                <i class="ri-file-list-line"></i>
                            </div>
                            <!-- <mat-error *ngIf="f.title.touched && f.title.invalid" class="alert alert-danger">
                                <mat-error *ngIf="f.title.errors.required"><Title></Title> is required</mat-error>
                            </mat-error> -->
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="department" type="text">
                                    <label class="floating-label-special">Department</label>
                                    <i class="ri-chat-voice-line"></i>
                                </div>
                                <!-- <mat-error *ngIf="f.weightage.touched && f.weightage.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.weightage.errors.required">Weightage is required</mat-error>
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <!-- <input formControlName="year" type="text" > -->
                                    <select formControlName="year" placeholder="Select Year">
                                        <option value="" disabled selected>Select Year</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                    </select>
                                    <label class="floating-label-special">Year</label>
                                    <i class="ri-calendar-check-line"></i>
                                </div>
                                <!-- <mat-error *ngIf="f.startDate.touched && f.startDate.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="kra" type="text" >
                                    <label class="floating-label-special">KRA</label>
                                    <i class="ri-calendar-check-line"></i>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="kpi" type="text" >
                                    <label class="floating-label">KPI</label>
                                    <i class="ri-calendar-check-line"></i>
                                </div>
                                <!-- <mat-error *ngIf="f.deadline.touched && f.deadline.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.deadline.errors.required">Deadline is required</mat-error>
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="weightage" type="text">
                                    <label class="floating-label">Weightage</label>
                                    <i class="ri-file-list-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="base" type="text">
                                    <label class="floating-label">Base</label>
                                    <i class="ri-message-2-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="target" type="text">
                                    <label class="floating-label">Target</label>
                                    <i class="ri-message-2-line"></i>
                                </div>
                            </div>
                        </div>
                    </div> 
                    
                </div>
                <div class="modal-footer">
                    <button type="button" id="closeCreateModal"  class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createPerformanceTemp()" >Create Performance</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updatePerformanceTemp()" >Update</button>
                </div>
            </form>
        </div>
    </div>
</div> 

<!-- Import Performance Templates -->
<div class="modal fade" id="importTemplates" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="form">
                <div class="modal-header">
                    <h5 class="modal-title">Import Performance Templates</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="col-md-4" style="padding-left: 25%;">
                        <input type="file" (change)="onFileChanged($event)">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" id="closeImportModal" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" (click)="importPerformanceTemplates()" data-bs-dismiss="modal">Import</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deletePerformance" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteVisitor" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteperformanceTemp()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div> 