import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { JobPostingService } from 'src/app/_services/job-posting/job-posting.service';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.scss']
})
export class JobApplicationComponent implements OnInit {
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[];
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  selectedRow: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  gridApi: any;
  gridColumnApi: any;
  jobDescriptionId: any;
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  tenantId: string;
  currentUser: any;
  deleteConfirmationMsg = 'Are you sure you want to delete'

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private jobPostingService: JobPostingService,
    ) { 
      {
        this.defaultColDef = {
          flex: 1,
          minWidth: 200,
          resizable: true,
        };
        this.rowSelection = 'multiple';
        this.columnDefs = this.createColumnDefs();  
  
        this.route.queryParams.subscribe(params => { this.jobDescriptionId = params['jobPostId'];})

        this.sampleGridFrameworkComponents = {
          actionRowRendererComponent: ActionItemsGridRowRendererComponent
        };
      
       
      }
    }

  ngOnInit(): void {
    this.tenantId = this.localStorageService.get('tenantId');
    this.currentUser = JSON.parse(this.localStorageService.get('currentUser'));
    this.getJobApplicationData();
  }

  getJobApplicationData(){
    if(this.jobDescriptionId){
      this.jobPostingService.getAllJobApplicationsByJobPostId(this.jobDescriptionId).subscribe((response: any) => {
        this.rowData = response;
      });
    } else {
      this.jobPostingService.getAllJobApplications().subscribe((response: any) => {
        this.rowData = response;
      });
    }
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Name",
        field: "applicantName" ,'width': 150, 'suppressSizeToFit': true,
        mimWidth: 300 ,
        filter: true,
        editable: false,
        sortable: true,
        cellRenderer: this.createHyperLink.bind(this),
      },
      {
        headerName: 'Job Title',
        field: 'jobTitle',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Phone No.',
        field: 'phoneNo',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Email Id',
        field: 'email',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Relavent Experience',
        field: 'relevantExperience',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Attachment',
        field: 'attachment',
        filter: true,
        editable: false,
        sortable: true
      },
    ]
  }

  createHyperLink(params): any {
    if (!params.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a href="${params.value}" > ${params.value} </a> `;
    spanElement.addEventListener('click', ($event) => {
      $event.preventDefault();
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-applicant-details'], { queryParams: { jobApplicationId: params.data.id } });
    });
    return spanElement;
  }

  openJobPost() {
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-post'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openApplicantList(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openShortlistedApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-shortlisted'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openInterviewApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-interview'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openOfferedApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-offered'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openApprovedApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-approved'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  backToJobApplication(){
    if(this.jobDescriptionId){
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-description'], { queryParams: { jobPostId: this.jobDescriptionId } });
    }
    else{
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/hiring-process']);
    }
  }
}
