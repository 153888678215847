<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
   <mat-card class="box">
     <mat-card-header>
        <mat-card-title>Reset Password</mat-card-title>
      </mat-card-header>

      <form [formGroup]="resetForm" class="example-form">
         <mat-card-content>
             <label for="oldPassword">Old Password</label>
             <mat-form-field class="example-full-width" appearance="outline">                    
                    <input matInput placeholder="Enter Password" formControlName="oldPassword" id="oldPassword"
                        type="password" class="form-control">
             </mat-form-field>

             <label for="newPassword">New Password</label>
             <mat-form-field class="example-full-width" appearance="outline">                 
                    <input matInput placeholder="Enter Password" formControlName="newPassword" id="newPassword"
                        type="password" class="form-control">
             </mat-form-field>

             <label for="confirmPassword"> Confirm Password</label>
             <mat-form-field class="example-full-width" appearance="outline">
                    <input matInput placeholder="Enter Password" formControlName="confirmPassword" id="confirmPassword"
                        type="password" class="form-control">
             </mat-form-field>

             <button type="button" mat-stroked-button color="accent" class="btn-block" (click)=onSubmit()>Save</button>

             <button type="button" mat-stroked-button color="warn" class="btn-block"(click)=cancel()>Cancel</button>

         </mat-card-content>
      </form>
   </mat-card>
</div>   