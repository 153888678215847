<div class="row">
    <div class="col-sm-4">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">IT Asset</h3>
            </div>
            <div class="card-body">
                <div class="p-3">
                    <ejs-accumulationchart>
                        <e-accumulation-series-collection>
                            <e-accumulation-series type = 'Pie' [dataSource]='data' xName='name' yName='value' [dataLabel]='datalabel'></e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
   
    <div class="col-sm-4">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">Software Notification</h3>
            </div>
            <div class="card-body">
                <div *ngIf="softwareNotification && softwareNotification?.length > 0 ">
                    <span *ngFor="let item of softwareNotification"
                      style="margin-left: 10px;font-weight: bold;margin-top: 10px;">
    
                      <i class="ri-file-hwp-fill"></i> {{item.softwareName}} Expired on {{item.expiringInDays}} Days.
                      <br>
                    </span>
                  </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">OnBording Notification</h3>
            </div>
            <div class="card-body">
                <div *ngIf="employeeOnboarding && employeeOnboarding?.length > 0 ">
                    <span *ngFor="let item of employeeOnboarding"
                      style="margin-left: 10px;font-weight: bold;margin-top: 10px;">
    
                      <i class="ri-user-2-line"></i>Name: {{item.employeeNames?.join(' ')}} Status: {{item.status}}
                      <br>
                    </span>
                  </div>
            </div>
        </div>
    </div>
    <div class="col-sm-12 mt-3">
        <div class="card">
            <div class="card-header">
                <h3 class="h5">Helpdesk </h3>
            </div>
            <div class="card-body">
                <!-- If no content -->
                <div class="row grey-boxes mt-4">
                    <div class="col-sm-4" *ngFor="let item of helpDesk;">
                        <div class="box">
                            <p>{{item.ticketStatus}}</p>
                            <span class="badge badge-secondary">{{item.number}}</span>
                        </div>
                    </div>
                    </div>
                <!-- <div class="text-center">
                    <p>No data available</p>
                    <img src="assets/svg/birthday.svg" alt="">
                </div> -->
            </div>
        </div>
    </div>
</div>
