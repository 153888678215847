import { Component, Input, OnInit } from '@angular/core';
import { QuoteService } from "src/app/_services/quote/quote.service";
import { Chart, registerables } from 'node_modules/chart.js';
import { DataService } from 'src/app/_services/data-share/data.service';
import { AccumulationChart, AccumulationChartComponent, ChartTheme, IAccResizeEventArgs, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-quotes-dashboard',
  templateUrl: './quotes-dashboard.component.html',
  styleUrls: ['./quotes-dashboard.component.scss']
})
export class QuotesDashboardComponent implements OnInit {
  @Input('opportunityId') opportunityId: number;
  @Input('quoteDetailId') quoteDetailId: number;
  public tooltip: Object;
  public GraphDisplay: number;
  public ItLicenseGraph;
  public ItLicenseGraphDataSource: any[];
  public ItLicenseGraphArr;
  public HeadCountByLocationGraph: any[];
  public HeadCountByLocationGraphArr;
  public DirectCostTypeGraph: any[];
  public DirectCostTypeGraphArr;
  public HeadCountByTaskGraph: any[];
  public HeadCountByTaskGraphArr;
  public HeadCountByEngineeringTypeGraph: any[];
  public HeadCountByEngineeringTypeGraphArr;
  public title: string;
  public opportunityIdStore: number;
  public quoteDetailIdStore: number;
  public primaryXAxis: Object;
  public primaryYAxis: Object;
  public primaryYAxisCost: Object;
  public chartData: Object[];
  //Initializing Primary X Axis
  //public primaryXAxis: Object = {
  //  majorGridLines: { width: 0 },
  //  minorGridLines: { width: 0 },
  //  majorTickLines: { width: 0 },
  //  minorTickLines: { width: 0 },
  //  interval: 1,
  //  lineStyle: { width: 0 },
  //  labelIntersectAction: 'Rotate90',
  //  valueType: 'Category',
  //  labelFormat: 'MMM-y',
  //};
  //Initializing Primary Y Axis
  //public primaryYAxis: Object = {
  //  //  title: 'Total Gross Price by Year / Status',
  //  lineStyle: { width: 0 },
  //  majorTickLines: { width: 0 },
  //  majorGridLines: { width: 1 },
  //  minorGridLines: { width: 1 },
  //  minorTickLines: { width: 0 },
  //  labelFormat: '{value}',
  //};
  //public tooltip: Object = {
  //  enable: true
  //};
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };
  // custom code end
  public legendSettingsStackedChart: Object = { visible: true, toggleVisibility: true, position: 'Top' };
  public title: string = 'IT Licenses Graph';
  public chartArea: Object = {
    border: {
      width: 0
    }
  };
  public width: string = Browser.isDevice ? '100%' : '90%';

  //Customize title font style
  public titleFont = {
    opacity: 1, fontFamily: "Arial", fontStyle: 'italic', fontWeight: 'regular', color: "#E27F2D", size: '23px'
  }

  constructor(private quoteService: QuoteService, private dataService: DataService) { }

  ngOnInit(): void {

    this.opportunityIdStore = this.opportunityId;
    this.quoteDetailIdStore = this.quoteDetailId;
    this.GraphDisplay = 1;
    this.getItLicenseGraph();
    this.chartData = [
      { month: 'Jan', sales: 35 }, { month: 'Feb', sales: 28 },
      { month: 'Mar', sales: 34 }, { month: 'Apr', sales: 32 },
      { month: 'May', sales: 40 }, { month: 'Jun', sales: 32 },
      { month: 'Jul', sales: 35 }, { month: 'Aug', sales: 55 },
      { month: 'Sep', sales: 38 }, { month: 'Oct', sales: 30 },
      { month: 'Nov', sales: 25 }, { month: 'Dec', sales: 32 }
    ];
    this.primaryXAxis = {
      valueType: 'Category',
      labelIntersectAction: 'Rotate45'
    };
    this.tooltip = {
      enable: true
    }
    this.primaryYAxis = {
      //labelFormat: '{value}'
      labelFormat: 'n2'
    };
    this.primaryYAxisCost = {
      //labelFormat: '{value}'
      labelFormat: '${value}K'
    };

    //Chart.register(...registerables);
    //const myChart = new Chart("myChart", {
    //  type: 'bar',
    //  data: {
    //    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    //    datasets: [{
    //      label: '# of Votes',
    //      data: [12, 19, 3, 5, 2, 3],
    //      backgroundColor: [
    //        'rgba(255, 99, 132, 0.2)',
    //        'rgba(54, 162, 235, 0.2)',
    //        'rgba(255, 206, 86, 0.2)',
    //        'rgba(75, 192, 192, 0.2)',
    //        'rgba(153, 102, 255, 0.2)',
    //        'rgba(255, 159, 64, 0.2)'
    //      ],
    //      borderColor: [
    //        'rgba(255, 99, 132, 1)',
    //        'rgba(54, 162, 235, 1)',
    //        'rgba(255, 206, 86, 1)',
    //        'rgba(75, 192, 192, 1)',
    //        'rgba(153, 102, 255, 1)',
    //        'rgba(255, 159, 64, 1)'
    //      ],
    //      borderWidth: 1
    //    }]
    //  },
    //  options: {
    //    scales: {
    //      y: {
    //        beginAtZero: true
    //      }
    //    }
    //  }
    //});
  }



  //Funtion to generate the Head Count by Engineering Type
  getHeadCountByEngineeringTypeGraph() {
    console.log("Graph 5");
    var arrayChildAttr = [];
    var TEMP_ARR = [];
    var finalDataSource = [];
    this.quoteService.getHeadCountByEngineeringTypeGraphData(this.opportunityId, this.quoteDetailId).subscribe((response: any) => {
      //  console.log("getHeadCountByEngineeringTypeGraph Response", response);
      if (!this.isEmpty(response)) {
        response = this.sortResponse(response);
        var parentAttr = [...new Set(Object.getOwnPropertyNames(response))];
        // console.log("Parent Attribute Name", parentAttr);
        for (var i = 0; i < parentAttr.length; i++) {
          var childAttr = Object.getOwnPropertyNames(response[parentAttr[i]]);
          for (var j = 0; j < childAttr.length; j++) {
            arrayChildAttr.push(childAttr[j]);
          }
        }
        var uniqueChildArr = [...new Set(arrayChildAttr)];
        this.HeadCountByEngineeringTypeGraphArr = uniqueChildArr;
        // console.log("getHeadCountByEngineeringTypeGraph Graph Title Array", uniqueChildArr);
        for (var x = 0; x < uniqueChildArr.length; x++) {
          for (var y = 0; y < parentAttr.length; y++) {
            var result = response[parentAttr[y]].hasOwnProperty(uniqueChildArr[x]);
            if (result) {
              var jsonObj = { x: parentAttr[y], y: response[parentAttr[y]][uniqueChildArr[x]] };
              TEMP_ARR.push(jsonObj);
            }
          }
          var tempObj = {};
          tempObj['values'] = TEMP_ARR;
          finalDataSource.push(tempObj);
          TEMP_ARR = [];
          //    console.log("getHeadCountByEngineeringTypeGraph Final Data Source", finalDataSource);
        }

        this.HeadCountByEngineeringTypeGraph = finalDataSource;
      }
    });
    finalDataSource = [];
  }


  //Funtion to generate the Head Count bye Task
  getHeadCountByTaskGraph() {
    console.log("Graph 4");
    var arrayChildAttr = [];
    var TEMP_ARR = [];
    var finalDataSource = [];
    this.quoteService.getHeadCountByTaskGraphData(this.opportunityId, this.quoteDetailId).subscribe((response: any) => {
      //  console.log("getHeadCountByTaskGraph Response", response);
      if (!this.isEmpty(response)) {
        response = this.sortResponse(response);
        var parentAttr = [...new Set(Object.getOwnPropertyNames(response))];
        // console.log("Parent Attribute Name", parentAttr);
        for (var i = 0; i < parentAttr.length; i++) {
          var childAttr = Object.getOwnPropertyNames(response[parentAttr[i]]);
          for (var j = 0; j < childAttr.length; j++) {
            arrayChildAttr.push(childAttr[j]);
          }
        }
        var uniqueChildArr = [...new Set(arrayChildAttr)];
        this.HeadCountByTaskGraphArr = uniqueChildArr;
        // console.log("getHeadCountByTaskGraph Graph Title Array", uniqueChildArr);
        for (var x = 0; x < uniqueChildArr.length; x++) {
          for (var y = 0; y < parentAttr.length; y++) {
            var result = response[parentAttr[y]].hasOwnProperty(uniqueChildArr[x]);
            if (result) {
              var jsonObj = { x: parentAttr[y], y: response[parentAttr[y]][uniqueChildArr[x]] };
              TEMP_ARR.push(jsonObj);
            }
          }
          var tempObj = {};
          tempObj['values'] = TEMP_ARR;
          finalDataSource.push(tempObj);
          TEMP_ARR = [];
          //  console.log("getHeadCountByTaskGraph Final Data Source", finalDataSource);
        }

        this.HeadCountByTaskGraph = finalDataSource;
      }
    });
    finalDataSource = [];
  }


  //Funtion to generate the Direct Cost Type Graph
  getDirectCostTypeGraph() {
    //console.log("Graph 3");
    var arrayChildAttr = [];
    var TEMP_ARR = [];
    var finalDataSource = [];
    this.quoteService.getDirectCostTypeGraphData(this.opportunityId, this.quoteDetailId).subscribe((response: any) => {
      //  console.log("getDirectCostTypeGraph Response", response);
      if (!this.isEmpty(response)) {
        response = this.sortResponse(response);
        var parentAttr = [...new Set(Object.getOwnPropertyNames(response))];
        // console.log("Parent Attribute Name", parentAttr);
        for (var i = 0; i < parentAttr.length; i++) {
          var childAttr = Object.getOwnPropertyNames(response[parentAttr[i]]);
          for (var j = 0; j < childAttr.length; j++) {
            arrayChildAttr.push(childAttr[j]);
          }
        }
        var uniqueChildArr = [...new Set(arrayChildAttr)];
        this.DirectCostTypeGraphArr = uniqueChildArr;
        //console.log("getDirectCostTypeGraph Graph Title Array", uniqueChildArr);
        for (var x = 0; x < uniqueChildArr.length; x++) {
          for (var y = 0; y < parentAttr.length; y++) {
            var result = response[parentAttr[y]].hasOwnProperty(uniqueChildArr[x]);
            if (result) {
              var jsonObj = { x: parentAttr[y], y: response[parentAttr[y]][uniqueChildArr[x]] };
              TEMP_ARR.push(jsonObj);
            }
          }
          var tempObj = {};
          tempObj['values'] = TEMP_ARR;
          finalDataSource.push(tempObj);
          TEMP_ARR = [];
          // console.log("getDirectCostTypeGraph Final Data Source", finalDataSource);
        }

        this.DirectCostTypeGraph = finalDataSource;
      }
    });
    finalDataSource = [];
  }

  //Funtion to generate the Heade Count by Location Graph
  getHeadCountByLocationGraph() {
    //console.log("Graph 2");
    var arrayChildAttr = [];
    var TEMP_ARR = [];
    var finalDataSource = [];
    this.quoteService.getHeadCountByLocationGraphData(this.opportunityId, this.quoteDetailId).subscribe((response: any) => {
      // console.log("getHeadCountByLocationGraph Response", response);
      if (!this.isEmpty(response)) {
        response = this.sortResponse(response);
        var parentAttr = [...new Set(Object.getOwnPropertyNames(response))];
        // console.log("Parent Attribute Name", parentAttr);
        for (var i = 0; i < parentAttr.length; i++) {
          var childAttr = Object.getOwnPropertyNames(response[parentAttr[i]]);
          for (var j = 0; j < childAttr.length; j++) {
            arrayChildAttr.push(childAttr[j]);
          }
        }
        var uniqueChildArr = [...new Set(arrayChildAttr)];
        this.HeadCountByLocationGraphArr = uniqueChildArr;
        //    console.log("Child Attribute Array", uniqueChildArr);
        for (var x = 0; x < uniqueChildArr.length; x++) {
          for (var y = 0; y < parentAttr.length; y++) {
            var result = response[parentAttr[y]].hasOwnProperty(uniqueChildArr[x]);
            if (result) {
              var jsonObj = { x: parentAttr[y], y: response[parentAttr[y]][uniqueChildArr[x]] };
              TEMP_ARR.push(jsonObj);
            }
          }
          var tempObj = {};
          tempObj['values'] = TEMP_ARR;
          finalDataSource.push(tempObj);
          TEMP_ARR = [];
          console.log("getHeadCountByLocationGraph Final Data Source", finalDataSource);
        }

        this.HeadCountByLocationGraph = finalDataSource;
      }
    });
    finalDataSource = [];
  }

  //Funtion to generate a IT License Graph
  getItLicenseGraph() {
    var arrayChildAttr = [];
    var TEMP_ARR = [];
    var finalDataSource = [];
    this.quoteService.getItLicenseGraphData(this.opportunityId, this.quoteDetailId).subscribe((response: any) => {
      if (!this.isEmpty(response)) {
        response = this.sortResponse(response);
        var parentAttr = [...new Set(Object.getOwnPropertyNames(response))];
        for (var i = 0; i < parentAttr.length; i++) {
          var childAttr = Object.getOwnPropertyNames(response[parentAttr[i]]);
          for (var j = 0; j < childAttr.length; j++) {
            arrayChildAttr.push(childAttr[j]);
          }
        }
        var uniqueChildArr = [...new Set(arrayChildAttr)];
        this.ItLicenseGraphArr = uniqueChildArr;
        console.log("Child Attribute Array", uniqueChildArr);
        for (var x = 0; x < uniqueChildArr.length; x++) {
          for (var y = 0; y < parentAttr.length; y++) {
            var result = response[parentAttr[y]].hasOwnProperty(uniqueChildArr[x]);
            if (result) {
              var jsonObj = { x: parentAttr[y], y: response[parentAttr[y]][uniqueChildArr[x]] };
              TEMP_ARR.push(jsonObj);
            }
          }
          var tempObj = {};
          tempObj['values'] = TEMP_ARR;
          finalDataSource.push(tempObj);
          TEMP_ARR = [];
        }
        console.log("ItLicenseGraphDataSource", finalDataSource);
        this.ItLicenseGraphDataSource = finalDataSource;

      }
    });
    finalDataSource = [];
  }

  //Funtion to sort the api respnse JSON object as per Month/Year
  sortMonthYear(res) {
    let temp;
    let tempArr = new Array(res.length);
    //make sort friendly
    for (i = 0; i < res.length; i++) {
      temp = res[i].split('-');
      if (temp[0] < 10) {
        temp[0] = "0" + temp[0];
      }
      tempArr[i] = temp[1] + '-' + temp[0];
    }
    //use sort
    tempArr.sort();
    //back to previous format
    for (var i = 0; i < tempArr.length; i++) {
      temp = tempArr[i].split('-');
      if (temp[1].charAt(0) == 0) {
        temp[1] = temp[1].substring(1);
      }
      tempArr[i] = temp[1] + '-' + temp[0];
    }
    return tempArr;
  }

  sortResponse(jsObj) {
    var TempSortedObj = {};
    var allKey = Object.keys(jsObj);
    var result = [];
    allKey.forEach(function (myString) {
      var info = myString.split("-");
      var month = info[0];
      var year = info[1];
      var mresult;
      switch (month) {
        case "Jan": { mresult = 1; } break;
        case "Feb": { mresult = 2; } break;
        case "Mar": { mresult = 3; } break;
        case "Apr": { mresult = 4; } break;
        case "May": { mresult = 5; } break;
        case "Jun": { mresult = 6; } break;
        case "Jul": { mresult = 7; } break;
        case "Aug": { mresult = 8; } break;
        case "Sep": { mresult = 9; } break;
        case "Oct": { mresult = 10; } break;
        case "Nov": { mresult = 11; } break;
        case "Dec": { mresult = 12; } break;
      }
      result.push(mresult + "-" + year[0] + year[1] + year[2] + year[3]);
    });
    // console.log("result sorted", result)
    var testRes = this.sortMonthYear(result);
    //console.log("SORTED ARRAY", testRes)
    var TempResult = [];
    testRes.forEach(function (myString) {
      var info = myString.split("-");
      var month = info[0];
      var year = info[1];
      var mresult;
      switch (parseInt(month)) {
        case 1: { mresult = "Jan"; } break;
        case 2: { mresult = "Feb"; } break;
        case 3: { mresult = "Mar"; } break;
        case 4: { mresult = "Apr"; } break;
        case 5: { mresult = "May"; } break;
        case 6: { mresult = "Jun"; } break;
        case 7: { mresult = "Jul"; } break;
        case 8: { mresult = "Aug"; } break;
        case 9: { mresult = "Sep"; } break;
        case 10: { mresult = "Oct"; } break;
        case 11: { mresult = "Nov"; } break;
        case 12: { mresult = "Dec"; } break;
      }
      TempResult.push(mresult + "-" + year[0] + year[1] + year[2] + year[3]);
    });
    // console.log("TEmp Sorted Arry", TempResult);
    for (var i = 0; i < TempResult.length; i++) {
      TempSortedObj[TempResult[i]] = jsObj[TempResult[i]];
    }
    // Run native sort function and returns sorted array.
    return TempSortedObj;
  }


  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  //Funtion for Dropdown to select the Graph
  changeGraph(e) {
    console.log("Graph Change", e.target.value);
    let graph: number = Number(e.target.value);

    switch (+graph) {
      case 1:
        //Graph IT License and this is the default graph to load
        this.getItLicenseGraph();
        this.GraphDisplay = 1;
        this.title = 'IT License Graph';
        // console.log("Graph 1", e.target.value);
        // document.getElementById("Graph1").style.display = "block";
        break;
      case 2:
        this.getHeadCountByLocationGraph();
        this.GraphDisplay = 2;
        this.title = 'Head Count By Location';
        // console.log("Graph 2", e.target.value);
        // document.getElementById("Graph2").style.display = "block";
        break;
      case 3:
        this.getDirectCostTypeGraph();
        this.GraphDisplay = 3;
        this.title = 'Direct Cost Type';
        // document.getElementById("Graph3").style.display = "block";
        break;
      case 4:
        this.getHeadCountByTaskGraph();
        this.GraphDisplay = 4;
        this.title = 'Head Count By Task';
        //  document.getElementById("Graph4").style.display = "block";
        break;
      case 5:
        this.getHeadCountByEngineeringTypeGraph();
        this.GraphDisplay = 5;
        this.title = 'Head Count By Engineering Type';
        // document.getElementById("Graph5").style.display = "block";
        break;
      default:
        this.getItLicenseGraph();
        this.GraphDisplay = 1;
        this.title = 'IT License Graph';
        // console.log("Graph 1", e.target.value);
        // document.getElementById("Graph1").style.display = "block";
        break;
    }
  }
}
