import { Component, OnInit } from '@angular/core';
import { ItDashboardService } from '../../../../_services/it-dashboard/it-dashboard.service';
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-it-dashboard',
  templateUrl: './it-dashboard.component.html',
  styleUrls: ['./it-dashboard.component.scss']
})
export class ItDashboardComponent implements OnInit {
  public data: object[];

  constructor(private _itDashboardService: ItDashboardService) { 
    this.data =  [];
  }
  public datalabel?: Object;
  public helpDesk;
  public softwareNotification;
  public employeeOnboarding;

  ngOnInit(): void {
    this.datalabel = { visible: true, name: 'text', position: 'Outside' };
    // this.helpDesk
    this.getDashboard();
    
  }


/**
 * get it dashboard data
 */
getDashboard() {
  this._itDashboardService.getItDashboard().subscribe({
  next : (value) => {
    let assets = value['asset'];
    let dataValue = [];
    for (let index = 0; index < assets.length; index++) {
      const element = assets[index];
      dataValue.push({
        name: element.assetType,
        value: element.number
      });
      
    }
 this.data = dataValue;  
this.helpDesk = value['helpdesk'];
this.softwareNotification = value['softwateNotification']; 
this.employeeOnboarding = value['employeeOnboarding'];
  },
    error(err) {
      
    },
    complete() {
      
    },
  })
}

}
