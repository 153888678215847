<h1>The div element</h1>

<div class="myDiv" style="border: 5px outset red;
background-color: lightblue;    
text-align: center;">
  <h2>This is a heading in a div element</h2>
  <p>This is some text in a div element.</p>
</div>

<p>This is some text outside the div element.</p>

<mat-tab-group>
  <mat-tab label="Second">
    
  </mat-tab>
    <mat-tab label="First">

      <h4>NgFor (grouped)</h4>
      <ul *ngFor="let group of peopleByCountry">
        <li>{{ group.country }}</li>
        <ul>
          <li *ngFor="let person of group.people">
            {{ person.name }}
          </li>
        </ul>
      </ul>

    </mat-tab>
 
  <mat-tab label="Third"> 
    <mat-grid-list cols="4" rowHeight="4:1">
      <mat-grid-tile [colspan]="3" [rowspan]="1" style="float: inline-start; text-indent: left; align-items: flex-start;justify-items: flex-start;align-self: flex-start;width: 100%;">
      <div>
               one vnjdsnvkl
              </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1" [rowspan]="1" style="background-color: pink;">
        two
      </mat-grid-tile>
    
    </mat-grid-list>
  
  </mat-tab>
  </mat-tab-group>