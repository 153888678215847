import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminstrationComponent } from './adminstration.component'
import { CostcenterComponent } from './costcenter/costcenter.component'
import { SoftwareratesComponent } from'./softwarerates/softwarerates.component'
import { UnitComponent } from './unit/unit.component';
import { CostrateComponent } from './costrate/costrate.component';
import { UsersComponent } from "./users/users.component";
import { CustomTagsComponent } from './custom-tags/custom-tags.component';
import { ResourceAllocationProjectComponent } from './resource-allocation-project/resource-allocation-project.component';
import { AuthGuardService } from 'src/app/_helpers/auth-guard.service';
import { NotificationComponent } from './notification/notification.component';
import { NotificationCreateComponent } from './notification-create/notification-create.component';
import { ProductStructureComponent } from './product-structure/product-structure.component';
import { PerformanceTemplatesComponent } from './performance-templates/performance-templates.component';
import { GlobalSettingComponent } from './global-setting/global-setting.component';
import { OnsiteEmployeeComponent } from './onsite-employee/onsite-employee.component';


const routes: Routes = [{
  path: '',
  component: AdminstrationComponent,
  children: [
    {
      path: 'users',
      component: UsersComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'costcenter',
      component: CostcenterComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'softwarerates',
      component: SoftwareratesComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'unit',
      component: UnitComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'costrate',
      component: CostrateComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'custom-tags',
      component: CustomTagsComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'notification',
      component: NotificationComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'notification-create',
      component: NotificationCreateComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'resource-allocation-project',
      component: ResourceAllocationProjectComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'product-structure',
      component: ProductStructureComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'performance-template',
      component: PerformanceTemplatesComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'global-setting',
      component: GlobalSettingComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'onsite-employee',
      component: OnsiteEmployeeComponent, canActivate: [AuthGuardService]
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminstrationRoutingModule { }
