<button *ngIf="deleteRequired" type="button" (click)="performAction($event)" value="delete" class="btn btn-secondary">
    <i class="ri-delete-bin-5-line" aria-hidden="true">
    </i>
</button>
<!-- <div class="table-action">
    <button class="action-btn"><i class="ri-more-2-fill"></i></button>
    <div class="action-items">
        <div class="edit">
            <button  type="button" >
                <span><i class="ri-pencil-line"></i></span>
                <span>Edit</span>
            </button>
        </div>
        <div class="delete">
            <button  type="button" >
                <span><i class="ri-delete-bin-5-line"></i></span>
                <span>Delete</span>
            </button>
        </div>
    </div>
</div> -->
