
<header>
    <div class="container">
        <div class="row justify-content-center ">
            <div class="col-sm-2 text-center">
                <div class="logo">
                    <img src="./assets/logo.png" alt="">
                </div>
            </div>
        </div>
    </div>
</header>


<div class="price-sections">
    <div class="container pb-5">
        <div class="text-center">
            <h3 class="h3">Select Pricing plan</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur quos aliquid voluptatum aspernatur ut, earum est sapiente molestiae in, nobis nulla aliquam unde repudiandae dolores illum asperiores? Dolore, minus est!</p>
        </div>
    </div>
    <div class="container pt-4">
        <div class="row">
            <div class="col-sm-4">
                <div class="price-box">
                    <div class="price-header">
                        <h3>Free plan</h3>
                    </div>
                    <div class="price-item">
                        <p>$0</p>
                    </div>
                    <div class="price-body">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non, consequuntur?</p>
                        <ul>
                            <li>ALorem ipsum dolorccout</li>
                            <li>consectetur adipisicing</li>
                            <li>elit. Non</li>
                            <li>amet consectetur adipisicing</li>
                            <li>dolor sit, amet</li>
                        </ul>
                    </div>
                    <div class="price-footer">
                        <button class="btn" routerLink="register" (click)=register() >Select plan</button>
                        <!-- <a href="" class="btn">Select plan</a> -->
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="price-box">
                    <div class="price-header">
                        <h3>Business Plan</h3>
                    </div>
                    <div class="price-item">
                        <p>$299<span>/Per Month</span></p>
                    </div>
                    <div class="price-body">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non, consequuntur?</p>
                        <ul>
                            <li>ALorem ipsum dolorccout</li>
                            <li>consectetur adipisicing</li>
                            <li>elit. Non</li>
                            <li>amet consectetur adipisicing</li>
                            <li>dolor sit, amet</li>
                        </ul>
                    </div>
                    <div class="price-footer">
                        <button class="btn" routerLink="register" (click)=register() >Select plan</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="price-box">
                    <div class="price-header">
                        <h3>Advance Plan</h3>
                    </div>
                    <div class="price-item">
                        <p>$999<span>/Per Month</span> </p>
                    </div>
                    <div class="price-body">
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Non, consequuntur?</p>
                        <ul>
                            <li>ALorem ipsum dolorccout</li>
                            <li>consectetur adipisicing</li>
                            <li>elit. Non</li>
                            <li>amet consectetur adipisicing</li>
                            <li>dolor sit, amet</li>
                        </ul>
                    </div>
                    <div class="price-footer">
                        <button class="btn" routerLink="register" (click)=register() >Select plan</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
