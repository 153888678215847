<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button title="back page" (click)="backToLeaveProfile()">
                                      <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Leave Balance Logs</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row my-1 align-items-center">
                        <form [formGroup] = "leaveBalanceLogsForm">
                            <div class="col-sm-12 col-md-12 col-lg-8">
                                <div class="row my-2 justify-content-center" >
                                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                        <div class="input">
                                            <div class="input-item">
                                                <input list="taskOwnerDatalist" formControlName="userId" placeholder="Select Name" value="" required/>
                                                <datalist id="taskOwnerDatalist">
                                                    <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                                </datalist>
                                                <label class="floating-label-special">User Name*</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                        <div class="input">
                                          <div class="input-item">
                                            <input type="date" formControlName="startDate"/>
                                            <label class="floating-label-special">Start Date</label>
                                            <i class="ri-calendar-line"></i>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                        <div class="input">
                                          <div class="input-item">
                                            <input type="date" formControlName="endDate"/>
                                            <label class="floating-label-special">End Date</label>
                                            <i class="ri-calendar-line"></i>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2 text-center">
                                        <button type="button" class="btn" (click)=getLeaveBalanceLogsByUserId()>
                                            <div>Submit</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <ag-grid-angular
                        style="width: auto; height: 100%;"
                        class="ag-theme-material"
                        [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"
                        [rowData]="rowData"
                        (gridReady)="onGridReady($event)"
                    ></ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>