import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class JobPostingService extends Subject<DataStateChangeEventArgs>  {
    private httpClient: HttpClient;
    constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
        super();
        this.httpClient = new HttpClient(handler);
    }

    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = `Error: ${error.error.message}`;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //  window.alert(errorMessage);
        console.log("Error:", errorMessage);
        return throwError(errorMessage);
    }

    // Create Job Post
    createJobDescription(data: Object): Observable<Object> {
        return this.http.post(`${environment.apiUrl}/jobDescriptions`, data).pipe(catchError(this.handleError));
    }

    // Get Job Post by Id
    getJobDescription(id: any): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/jobDescriptions/${id}`).pipe(catchError(this.handleError));
    }

    //Delete Job Post by Id  
    deleteJobDescription(id: any): Observable<Object> {
        return this.http.delete(`${environment.apiUrl}/jobDescriptions/${id}`).pipe(catchError(this.handleError));
    }

    //Update Job Post by Id
    updateJobDescription(data: Object, id: any): Observable<Object> {
        return this.http.put(`${environment.apiUrl}/jobDescriptions/${id}`, data).pipe(catchError(this.handleError));
    }

    //Get All Job Posts
    getAllJobDescriptions(): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/jobDescriptions`).pipe(catchError(this.handleError));
    }

    //Get Job Applications
    getAllJobApplications(): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/jobApplications`).pipe(catchError(this.handleError));
    }

    //Get job applications by job postId
    getAllJobApplicationsByJobPostId(jobDescriptionId: any): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/jobApplications?jobDescriptionId=${jobDescriptionId}`).pipe(catchError(this.handleError));
    }

    //Create Job Applications
    createJobApplication(data: Object): Observable<Object> {
        return this.http.post(`${environment.apiUrl}/jobApplications`, data).pipe(catchError(this.handleError));
    }

    // Get Job Application by Id
    getJobApplication(id: any): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/jobApplications/${id}`).pipe(catchError(this.handleError));
    }

    //Delete Job Application by Id  
    deleteJobApplication(id: any): Observable<Object> {
        return this.http.delete(`${environment.apiUrl}/jobApplications/${id}`).pipe(catchError(this.handleError));
    }

    //Update Job Application by Id
    updateJobApplication(data: Object, id: any): Observable<Object> {
        return this.http.put(`${environment.apiUrl}/jobApplications/${id}`, data).pipe(catchError(this.handleError));
    }

    //Apply for job - OUTSIDE
    applyForJob(data: Object): Observable<Object>{
        return this.http.post(`${environment.apiUrl}/applyForJob`, data).pipe(catchError(this.handleError));
    }

    //Apply for job - OUTSIDE
    applyForJobAttachments(jobApplicationId: any, file: Object): Observable<object> {
        const header = {
            headers: new HttpHeaders({
              'tenantId': this.storageService.get('tenantId'),
              'attachmentType': 'Resume',
              'jobApplicationId': jobApplicationId,
            }),
          };
        return this.http.post(`${environment.apiUrl}/applyForJobAttachments`, file, header).pipe(catchError(this.handleError));
    }

    //Get job description - OUTSIDE
    getMeJd(id: any): Observable<Object> {
        const header = {
            headers: new HttpHeaders({
              'tenantId': this.storageService.get('tenantId'),
              'jobDescriptionId': id,
            }),
          };
        return this.http.get(`${environment.apiUrl}/getMeJd`, header).pipe(catchError(this.handleError));
    }

    public downloadJobApplicationAttachments(jobApplicationId: any, imageid: number) {
        return this.http.get<Blob>(`${environment.apiUrl}/leaveApplications/${jobApplicationId}/attachments/${imageid}`,{observe: 'response' ,responseType: 'blob' as 'json'})    
    }

    getjobApplicationAttachments(jobApplicationId: any){
        return this.http.get<any>(`${environment.apiUrl}/leaveApplications/${jobApplicationId}/attachments`).pipe(map((result) => { return result;}), catchError(this.handleError));
      }
}