import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ResoureProjectService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);

   }

   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }


  // Create ResourceAllocationProject
  createResourceAllocationProject(user: Object): Observable<Object> {
    console.log("In createResourceAllocationProject method, ResourceAllocationProject: ", user);
    return this.http.post(`${environment.apiUrl}/resourceAllocationProjects`, user).pipe(catchError(this.handleError));
  }

  // Get ResourceAllocationProject by Id Get
  getResourceAllocationProject(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/resourceAllocationProjects/${id}`).pipe(catchError(this.handleError));
  }

  
  //Delete ResourceAllocationProject by Id Delete
  deleteResourceAllocationProject(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/resourceAllocationProjects/${id}`).pipe(catchError(this.handleError));
  }

   //Update ResourceAllocationProjects by Id Put
   updateResourceAllocationProject(user: Object,id: number): Observable<Object> {
    console.log("In resourceAllocationProjects method, user: ", user);
    return this.http.put(`${environment.apiUrl}/resourceAllocationProjects/${id}`, user).pipe(catchError(this.handleError));
  }
  

  //Get All ResourceAllocationProject by Id Get
  getAllResourceAllocationProject(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/resourceAllocationProjects`).pipe(catchError(this.handleError));
  }
}
