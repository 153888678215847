import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { OpportunityService } from 'src/app/_services/opportunity/opportunity.service';
import { QuoteService } from 'src/app/_services/quote/quote.service';

@Component({
  selector: 'app-business-unit-summary',
  templateUrl: './business-unit-summary.component.html',
  styleUrls: ['./business-unit-summary.component.scss']
})
export class BusinessUnitSummaryComponent implements OnInit {
  @Input() manpowerPlanningData;
  tenantId: string;
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  summaryDetails: [];
  businessUnitSummary: any = {}

  constructor(
    private quoteService: QuoteService ,
    private storageService: LocalStorageService, 
  
  ) { }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getBuSummaryDetails();
    
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getBuSummaryDetails()
  }

  getBuSummaryDetails() {
    
    this.quoteService.getBusinessUnitSummary(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      if (response) {
        this.businessUnitSummary = response;
        if (response.summaryDetails) {
          this.summaryDetails = response.summaryDetails;
        }
      }
    }, error => {
      console.log('ERROR - cannot get business unit summary: ' + error);
    });
  }
  isManpowerBtnOpen: boolean = false;
  manpowerSpan: number = 2;
  manpowerBtn() {
    this.isManpowerBtnOpen = !this.isManpowerBtnOpen;
    if (this.isManpowerBtnOpen) this.manpowerSpan = 4;
    else this.manpowerSpan = 2;
  }


  isOdcCostBtnOpen: boolean = false;
  odcCostSpan: number = 2;
  odcCostBtn() {
    this.isOdcCostBtnOpen = !this.isOdcCostBtnOpen;
    if (this.isOdcCostBtnOpen) this.odcCostSpan = 6;
    else this.odcCostSpan = 2;
  }

  isTotalManpowerBtnOpen: boolean = false;
  totalManpowerSpan: number = 2;
  totalManpowerBtn() {
    this.isTotalManpowerBtnOpen = !this.isTotalManpowerBtnOpen;
    if (this.isTotalManpowerBtnOpen) this.totalManpowerSpan = 4;
    else this.totalManpowerSpan = 2;
  }

  isOdcPriceBtnOpen: boolean = false;
  odcPriceSpan: number = 2;
  odcPriceBtn() {
    this.isOdcPriceBtnOpen = !this.isOdcPriceBtnOpen;
    if (this.isOdcPriceBtnOpen) this.odcPriceSpan = 6;
    else this.odcPriceSpan = 2;
  }

  costSpan(): number {
    return 2 + this.manpowerSpan + this.odcCostSpan;
  }

  priceSpan(): number {
    return 2 + this.totalManpowerSpan + this.odcPriceSpan;
  }

  }

 
