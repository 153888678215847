import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/_services/data-share/data.service';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { LocalStorageService } from "../../../../_services/localStorage/local-storage.service";
import { QuoteService } from "../../../../_services/quote/quote.service";
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-odc',
  templateUrl: './odc.component.html',
  styleUrls: ['./odc.component.scss']
})
export class OdcComponent implements OnInit {
  startDate = new Date();
  endDate = new Date();
  tenantId: string;
  quoteForm: FormGroup;
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  // odc start
  odcTable: FormGroup;
  odcFormGroup: FormGroup = new FormGroup({
    taskName: new FormControl(''),
    activity: new FormControl(''),
    costcenter: new FormControl('', Validators.required),
    otherCost: new FormControl('', Validators.required)
  })
  touchedRowsODC: any;
  startDateODC = new Date();
  endDateODC = new Date();
  rangeODC = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  daysODC = [];
  allocationDataODC: object[];
  controlODC: FormArray;
  deleteArrayODC = [];
  // odc end

  costCenterDropDownReslvl: any[] = [];
  costCenterDropDownloc: any[] = [];
  costCenterDropDown: any[] = [];
  costCenterLevelRatesapi: string;

  allOdcData: any[] = [];
  odcDataTop: any[] = [];
  odcDataBottom: any[] = [];
  // defined the array of Software Dropdown
  public dataSource_srdropdown: {[key: string]: Object}[];
  // defined the array of COSTcenter Dropdown
  public dataSource_cclrdropdown: {[key: string]: Object}[];

  formMode: number = 0; // 0 - none // 1 - new form // 2 - edit form
  isOdcFormVisible: boolean = false;
  otherCostDropdownList: string[] = ['travel', 'service', 'testFacilities', 'other']
  
  constructor(private dataService: DataService, private formBuilder: FormBuilder, private storageService: LocalStorageService, private quoteService: QuoteService, public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getOtherDirectCost();
    this.getCostCenterLevelRates();
    this.startDate = new Date(this.quoteObject.startDate);
    this.endDate = new Date(this.quoteObject.endDate);
    this.getAllDates(this.startDate, this.endDate);

    for (let item of this.daysODC) {
      this.odcFormGroup.addControl(item, new FormControl(0))
    }
  }

  getCostCenterLevelRates() {
    this.quoteService.getCostCenterLevelRates(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      if (response.length > 0) {
        for (let item of response) {
          if (!this.costCenterDropDown.includes(item.costcenter)) this.costCenterDropDown.push(item.costcenter)
          if (!this.costCenterDropDownloc.includes(item.location)) this.costCenterDropDownloc.push(item.location)
          if (!this.costCenterDropDownReslvl.includes(item.level)) this.costCenterDropDownReslvl.push(item.level)
        }
      }
    });
  }

  getAllDates(startDate, endDate) {
    let dates = [];
    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setMonth(theDate.getMonth() + 1);
    }
    dates = [...dates];
    this.daysODC = [];
    for (let item of dates) {
      this.daysODC.push(this.datepipe.transform(item, 'MMM-y'));
    }
  }

  visibleForm() {
    if (this.formMode === 0) {
      this.isOdcFormVisible = true;
      this.formMode = 1;
      this.odcFormGroup.patchValue({
        taskName: '',
        activity: '',
        costcenter: '',
        otherCost: '',
      })
      for (let item of this.daysODC) {
        this.odcFormGroup.get(item).setValue(null)
      }
    } else if (this.formMode === 1) {
      this.isOdcFormVisible = false;
      this.formMode = 0;
    }
  }

  requiredSave() {
    if (this.odcFormGroup.status === 'INVALID') return true;
    else return false;
  }

  getOtherDirectCost() {
    this.quoteService.getOdcDetails(this.opportunityObject.id, this.quoteObject.id).subscribe(response => {
          this.allOdcData = [];
          this.odcDataTop = [];
          this.odcDataBottom = [];
          this.allOdcData = response;
          this.odcDataTop = response;
        }, error => {
          console.log('ERROR - cannot get odc data: ' + error)
        }
      );
  }

  saveOtherDirectCost() {
    let valuesDate = [];
    for (let item of this.daysODC) {
      valuesDate.push({
        date: item,
        value: this.odcFormGroup.get(item).value
      })
    }
    let data = {
      taskName: this.odcFormGroup.get('taskName').value,
      activity: this.odcFormGroup.get('activity').value,
      costcenter: this.odcFormGroup.get('costcenter').value,
      otherCost: this.odcFormGroup.get('otherCost').value,
      values: valuesDate
    }
    this.quoteService.createOdcDetails(data, this.opportunityObject.id, this.quoteObject.id).subscribe(response => {
        this.getOtherDirectCost();
      }, error => {
        console.log('ERROR - cannot post odc: ' + error)
      }
    );
  }

  updateOtherDirectCost() {
    let valuesDate = [];
    for (let item of this.daysODC) {
      valuesDate.push({
        date: item,
        value: this.odcFormGroup.get(item).value
      })
    }
    let data = {
      taskName: this.odcFormGroup.get('taskName').value,
      activity: this.odcFormGroup.get('activity').value,
      costcenter: this.odcFormGroup.get('costcenter').value,
      otherCost: this.odcFormGroup.get('otherCost').value,
      values: valuesDate
    }
    this.quoteService.updateOdcDetails(data, this.opportunityObject.id, this.quoteObject.id, this.editOdcId).subscribe(response => {
      this.getOtherDirectCost();
      this.formMode = 0;
      this.isOdcFormVisible = false;
    }, error => {
      console.log('ERROR - cannot post odc: ' + error)
    })
  }

  editOdcId: number;
  editOdcById(id: number) {
    this.formMode = 2;
    this.isOdcFormVisible = true;
    const idx = this.allOdcData.findIndex(i => i.id === id);
    this.editOdcId = id;
    this.odcDataTop = this.allOdcData.slice(0, idx);
    this.odcDataBottom = this.allOdcData.slice(idx + 1, this.allOdcData.length)
    this.odcFormGroup.patchValue({
      taskName: this.allOdcData[idx].taskName,
      activity: this.allOdcData[idx].activity,
      costcenter: this.allOdcData[idx].costcenter,
      otherCost: this.allOdcData[idx].otherCost,
    })
    for (let item of this.allOdcData[idx].values) {
      this.odcFormGroup.get(item.date).setValue(item.value)
    }
  }

  deleteOtherDirectCost(id: number){
    this.quoteService.deleteOdcDetails(this.opportunityObject.id, this.quoteObject.id, id).subscribe(response => {
      this.getOtherDirectCost();
    }, error => {
      console.log('ERROR - cannot delete odc: ' + error)
    });
  }
}
