import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  constructor(private http: HttpClient) { }

  deleteSoftwareRates(oppId: number, quoteId: number, id: number) {
    console.log("in service, SR Delete data: ", id);
    return this.http
      .delete<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/softwareRates/${id}`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  deleteCostCenterLevelRates(oppId: number, quoteId: number, id: number) {
    console.log("in service, CCLR Delete data: ", id);
    return this.http
      .delete<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/costcenterLevelRates/${id}`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  
  addSoftwareRates(addsrdata: Object, oppId: number, quoteId: number) {
    console.log("Update Data, SR Data: ", addsrdata);
    return this.http
      .post<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/appendSoftwareRates`, addsrdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }


  getSoftwareRates(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/softwareRates`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  addCostCenterLevelRates(addcclrdata: Object, oppId: number, quoteId: number) {
    console.log("Update Data, CCLR Data: ", addcclrdata);
    return this.http
      .post<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/appendCostcenterLevelRates`, addcclrdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getCostCenterLevelRates(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/costcenterLevelRates`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateGeneralDetails(generaldetailsdata: Object, oppId: number, quoteId: number, id: number) {
    console.log("General Details, Service response: ", generaldetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${id}`, generaldetailsdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateQuoteParameters(updateqpdata: Object, oppId: number, quoteId: number) {
    console.log("Update Data, QUOTE PARAMETERS: ", updateqpdata);
    return this.http
      .post<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/costSummaryInput`, updateqpdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateDefaultParameters(updatedpdata: Object, oppId: number, quoteId: number) {
    console.log("Update Data, DEFAULT PARAMETERS: ", updatedpdata);
    return this.http
      .post<any>(`${environment.apiUrl}/quoteParameters`, updatedpdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getOdcDetails(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/otherDirectCosts`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  createOdcDetails(data: Object, oppId: number, quoteId: number) {
    console.log("in service, odc: ", data);
    return this.http
      .post<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/otherDirectCosts`, data,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateOdcDetails(data: Object, oppId: number, quoteId: number, id: number) {
    console.log("in service, odc: ", data);
    return this.http
      .put<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/otherDirectCosts/${id}`, data,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  deleteOdcDetails(oppId: number, quoteId: number, id: number) {
    return this.http
      .delete<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/otherDirectCosts/${id}`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getQuoteParametersDetails(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/costSummaryInput`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getDefaultParametersDetails(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/quoteParameters`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getDefaultCostCenterDetails(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/costcenterLevelRates/-1`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getDefaultSoftwareDetails(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/softwareRates/-1`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getManpowerDistELSummary(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/manpowerDistributionByEngineeringLevel`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getOdcDistributionSummary(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/otherDirectCostDistribution`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getQuoteParameters(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/quoteParameters`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getTaskCostSummary(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/costSummary?filteringElement=taskname`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getBusinessUnitSummary(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/costSummary?filteringElement=costcenter`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getManpowerDistributions(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/manpowerDistributions`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  createManpowerDistributions(data: Object, oppId: number, quoteId: number) {
    console.log("in service, manpower data: ", data);
    return this.http
      .post<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/manpowerDistributions`, data,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateManpowerDistributions(data: Object, oppId: number, quoteId: number, id: number) {
    console.log("in service, manpower data: ", data);
    return this.http
      .put<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/manpowerDistributions/${id}`, data,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  deleteManpowerDistributions(oppId: number, quoteId: number, id: number) {
    return this.http
      .delete<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/manpowerDistributions/${id}`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getQuoteDetails(oppId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  getQuoteById(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  createQuote(quote: Object, oppId: number) {
    return this.http
      .post<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes`, quote,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateQuote(quote: Object, oppId: number, quoteId: number) {
    return this.http
      .put<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}`, quote,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  deleteQuote(oppId: number, quoteId: number) {
    return this.http
      .delete<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}`,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  //Get the Graph details for IT License Graph
  getItLicenseGraphData(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/itLicenseGraph`,
    )
      .pipe(
        map(result => {
          console.log("IT License ", result);
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }


  //Funtion to get the Graph details for Head Count by Location
  getHeadCountByLocationGraphData(oppId: number, quoteId: number){
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/headCountByLocationGraph`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  //Funtion to get the Graph details for Direct Cost Type
  getDirectCostTypeGraphData(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/directCostTypeGraph`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  //Funtion to get the Graph details for Head Count by Engineering type
  getHeadCountByEngineeringTypeGraphData(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/headCountByEngineeringTypeGraph`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }


  //Funtion to get the Graph details for Head Count by Task
  getHeadCountByTaskGraphData(oppId: number, quoteId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/opportunities/${oppId}/quotes/${quoteId}/headCountByTaskGraph`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  private handleError<T>() {
    return (error: any): Observable<boolean> => {
      return of(false);
    };
  }
}

