import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { LicenseManager } from "ag-grid-enterprise";
import { registerLicense } from '@syncfusion/ej2-base';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Registering Syncfusion License Key
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjWH9WcH1VRGBeUEE=');

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-20_February_2022_[v2]_MTY0NTMxNTIwMDAwMA==eccdefb21866b9a08dd2eacf3c096a53");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
