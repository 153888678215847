import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { HrService } from "src/app/_services/hr-management/hr.service";

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html',
  styleUrls: ['./hr.component.scss']
})
export class HrComponent implements OnInit {
  

  constructor() { 
  
  }

  ngOnInit(): void {
    
  }


}
