export enum Roles {
    // User = "User", //This is for Pragamana internal use
    // Administrator = "Admin", //This is for Pragamana internal use
    // RoleTenantAdministrator = "Tenant Administrator", //This is for Pragamana internal use
    // RoleKnowledgeAdministrator = "Knowledge Administrator",
    // RoleSalesUser = "Sales User", //This is for Pragamana internal use
    // RoleAllReader = "All Reader", //This is for Pragamana internal use
    RoleApprover = "Approver", //To access the Approval page
    RoleAdministrator = "Administrator", //All Management Person
    RoleAdministrationUser = "Administration User", //Role for Admin Module
    RoleReportsExtractor = "Report Extractor", //To Export Reports
    RoleHrmAdministrator = "HRM Administrator", //Role for HRM admin
    RoleHrmUser = "HRM User", //Role for HRM user
    RoleFinanceAdministrator = "Finance Adminstration", //Role for finance admin
    RoleFinanceUser = "Finance User", //Role for finance user
    RoleNDATeam = "NDA Admin", //For NDA Admin
    RoleITAdministrator = "IT Administrator", //Role for IT admin
    RoleITUser = "IT User", //Role for IT user
    RoleHelpdeskUser = "Helpdesk User", //Role for Helpdesk User(Only Helpdesk from ITM)
    RoleTimesheetUser = "Timesheet User", //Role for user to access Timesheet
    RoleResourceAllocationUser = "Resource Allocation User", //Role for person who can allocate resource any task/project
    RoleProjectUser = "Project User", //Role for PMO user
    RoleCapacityPlanningUser = "Capacity Planning User", //Role for person to plan the employee capacity
    RolePmoAdministrator = "PMO Administrator", //Role for PMO admin
    RolePmoUser = "PMO User", //Role for PMO user
    RoleCrmAdministrator = "CRM Administrator", //Role for CRM admin
    RoleCrmUser = "CRM User", //Role for CRM user
    RoleOpportunityUser = "Opportunity User", //Role for person to access the opportunity
    RolePmoManager = "PMO Manager" //Role for Project manager or lead to access timesheet report and allocate shifts
}