import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';

@Component({
  selector: 'app-resource-allocation',
  templateUrl: './resource-allocation.component.html',
  styleUrls: ['./resource-allocation.component.scss']
})
export class ResourceAllocationComponent implements OnInit {

  resourceAllocationData: any[] = [];
  selectedRow:any;
  tenantId:any;
  modeAction:number = 1;

  resourceAllocationFormGroup: FormGroup = new FormGroup({
    name: new FormControl('',[Validators.required]),
    startDate: new FormControl('',[Validators.required]),
    endDate: new FormControl('',[Validators.required]),
    resourceType: new FormControl('daily')
  })
 
  constructor(
    private router: Router,
    private storageService: LocalStorageService,
    private allocationService: AllocationService
  ) { }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getResourceAllocation()
  }

  get f(){
    return this.resourceAllocationFormGroup.controls;
  }

  getResourceAllocation() {
    this.allocationService.getResourceAllocationProjectDetails().subscribe(response => {
      this.resourceAllocationData = response
    })
  }

  createOpenModal() {
    this.modeAction = 1;
    this.resourceAllocationFormGroup.reset();
  }

  createResourceAllocation() {
    this.allocationService.createResourceAllocationProjectDetails(this.resourceAllocationFormGroup.value).subscribe(response => {
      if (response) {
        this.getResourceAllocation()
        $('#closeModalResourceAllocation').trigger("click");
      }
    })
  }

  openEdit(item) {
    this.resourceAllocationFormGroup.patchValue(item);
    this.selectedRow = item;
  }

  updateResourceAllocation(id) {
    this.allocationService.updateResourceAllocationProjectDetails(this.resourceAllocationFormGroup.value, id).subscribe(response => {
      this.getResourceAllocation()
      $('#closeModalEditResourceAllocation').trigger("click");
    })
  }

  openDelete(item) {
    this.selectedRow = item;
  }

  deleteResourceAllocation(id) {
    this.allocationService.deleteResourceAllocationProjectDetails(id).subscribe(response => {
      this.getResourceAllocation()
      $('#closeModalDeleteResourceAllocation').trigger("click");
    })
  }

  rowData(item) {
    localStorage.setItem("resourceAllocationObject", JSON.stringify(item))
    this.router.navigate([this.tenantId + '/dashboard/resource-utilization/add-allocation']);
  }
}
