import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { ResponseType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class PerformanceTempService extends Subject<DataStateChangeEventArgs>{

  constructor(private http: HttpClient,
    private storageService: LocalStorageService
  ) {
    super();
   }

   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

   //Get All default Performance Templates 
  getAllDefaultPerformanceTemplates(): Observable<Object> {
    let params = new HttpParams();
    //params = params.append('filterBy', 'projectId');
    // params = params.append('projectId', projectId);
    return this.http.get(`${environment.apiUrl}/defaultPerformanceTemplates`, { params: params }).pipe(catchError(this.handleError));
  }

  // Get defaultPerformanceTemplates by Id Get
  getPerformanceTemplatesById(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/defaultPerformanceTemplates/${id}`).pipe(catchError(this.handleError));
  }

  // Create PerformanceTemplates
  createPerformanceTemplates(user: Object): Observable<Object> {
    console.log("In createContact method, ProjectTasks: ", user);
    return this.http.post(`${environment.apiUrl}/defaultPerformanceTemplates`, user).pipe(catchError(this.handleError));
  }

  //Delete PerformanceTemplates by Id Delete
  deletePerformanceTemplates(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/defaultPerformanceTemplates/${id}`).pipe(catchError(this.handleError));
  }

  //Update PerformanceTemplates by Id Put
  updatePerformanceTemplates(user: Object,id: number): Observable<Object> {
    console.log("In createPerformanceTemplates method, user: ", user);
    return this.http.put(`${environment.apiUrl}/defaultPerformanceTemplates/${id}`, user).pipe(catchError(this.handleError));
  }

  importPerformanceTemplates(payload: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'ResponseType': 'text/plain',
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.http.post(`${environment.apiUrl}/bulkDefaultPerformanceTemplates`, payload, header).pipe(catchError(this.handleError));
  }

  getPerformanceTemplatesByDept(dept: String, year: number): Observable<Object>{
    return this.http.get(`${environment.apiUrl}/defaultPerformanceTemplates?designation=${dept}&year=${year}`).pipe(catchError(this.handleError));
  }

}