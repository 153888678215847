<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
              <h2>Welcome {{currentUser.displayName}}!</h2>
            </div>
          </div>
          <br />
          <div class="control-section p-6">
            <ejs-dashboardlayout id='defaultLayout' #defaultLayout [columns]="5" [cellSpacing]='cellSpacing'>
              <div id="one" class="e-panel" data-row="0" data-col="0" data-sizeX="1" data-sizeY="1">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">0</div>
                </div>
              </div>
              <div id="two" class="e-panel" data-row="0" data-col="1" data-sizeX="2" data-sizeY="2">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">
                    <div id="column">
                      <ejs-chart id="columnChart" [chartArea]='chartArea' [width]='width' align='center' [primaryXAxis]='primaryXAxis'
                                 [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]='tooltip' [legendSettings]="legendSettingsStackedChart" (load)='load($event)'>
                        <e-series-collection>
                          <e-series [dataSource]='QualifyData' type='StackingColumn' xName='x' yName='y' name='Qualify' width='1'> </e-series>
                          <e-series [dataSource]='NeedAnalysisData' type='StackingColumn' xName='x' yName='y' name='Need Analysis' width='1'> </e-series>
                          <e-series [dataSource]='ValuePropositionData' type='StackingColumn' xName='x' yName='y' name='Value Proposition' width='1'> </e-series>
                          <e-series [dataSource]='ApprovalsData' type='StackingColumn' xName='x' yName='y' name='Approvals' width='1'> </e-series>
                          <e-series [dataSource]='PriceData' type='StackingColumn' xName='x' yName='y' name='Price Quote' width='1'> </e-series>
                          <e-series [dataSource]='NegotiationData' type='StackingColumn' xName='x' yName='y' name='Negotiation Review' width='1'> </e-series>
                          <e-series [dataSource]='WinData' type='StackingColumn' xName='x' yName='y' name='Win' width='1'> </e-series>
                          <e-series [dataSource]='LostData' type='StackingColumn' xName='x' yName='y' name='Lost' width='1'> </e-series>
                        </e-series-collection>
                      </ejs-chart>
                    </div>
                  </div>
                </div>
              </div>
              <div id="three" class="e-panel" data-row="0" data-col="3" data-sizeX="2" data-sizeY="2">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">
                    <ejs-accumulationchart id="chart-container" #funnel style='display:block;' [legendSettings]="legendSettings" [tooltip]="tooltip" [title]="titleFunnel" [enableAnimation]='enableAnimation'
                                           (load)='load($event)' (resized)='onChartResized($event)' background="#fff" width='600' height='100%' (pointRender)="onPointRender($event)">
                      <e-accumulation-series-collection>
                        <e-accumulation-series [dataSource]='dataFunnel' xName='x' yName='y' [neckWidth]="neckWidth" [neckHeight]="neckHeight" [gapRatio]="gapRatio"
                                               [emptyPointSettings]="emptyPointSettings" [explode]="explode" [dataLabel]="dataLabel" type='Funnel' [palettes]="palette"> </e-accumulation-series>
                      </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                  </div>
                </div>
              </div>
              <div id="four" class="e-panel" data-row="1" data-col="0" data-sizeX="1" data-sizeY="1">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">3</div>
                </div>
              </div>
              <div id="five" class="e-panel" data-row="2" data-col="4" data-sizeX="2" data-sizeY="1">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">4</div>
                </div>
              </div>
              <!--<div id="six" class="e-panel" data-row="0" data-col="3" data-sizeX="1" data-sizeY="1">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">5</div>
                </div>
              </div>-->
              <div id="seven" class="e-panel" data-row="3" data-col="2" data-sizeX="1" data-sizeY="1">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">6</div>
                </div>
              </div>
              <!--<div id="eight" class="e-panel" data-row="2" data-col="0" data-sizeX="1" data-sizeY="3">
                <span id="close" class="e-template-icon e-clear-icon"></span>
                <div class="e-panel-container">
                  <div class="text-align">7</div>
                </div>
              </div>-->
            </ejs-dashboardlayout>
          </div>
        </div>

      </section>

    </section>


  </article>
</main>
