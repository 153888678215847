<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <form [formGroup]="taxdeductionform">
          <div class="canvas-item">
            <div class="canvas-header row justify-content-between align-items-center">
              <div class="title col-sm-6">
                <div class="d-flex align-items-center">
                  <div class="edit">
                    <button (click)="backToTaxDeduction()">
                        <span><i class="ri-arrow-left-line"></i></span>
                    </button>
                </div>
                  <h3 class="px-3">Tax Exemption</h3>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="canvas-data">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="input">
                        <div class="input-item">
                          <!-- <input type="type" formControlName="employeeName" />
                          <label class="floating-label">Employee Name</label> -->
                          <input
                            list="taskOwnerDatalist"
                            formControlName="employeeName"
                            placeholder="Select Employee Name"
                            value=""
                          />
                          <datalist id="taskOwnerDatalist">
                            <option
                              [value]="item"
                              *ngFor="let item of userDropdownList"
                            ></option>
                          </datalist>
                          <label class="floating-label-special">Employee Name</label>
                          <i class="ri-user-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="financialYear">
                            <!-- <option value="" disabled selected>Select Financial Year</option> -->
                            <option value="2022-2023">2022-2023</option>
                            <option value="2023-2024">2023-2024</option>
                            <option value="2024-2025">2024-2025</option>
                            <option value="2025-2026">2025-2026</option>
                          </select>
                         <label for="" class="floating-label-special">Financial Year</label> 
                          <i class="ri-bank-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <mat-slide-toggle formControlName="metro" >If you in metro please make this one on</mat-slide-toggle>
                    </div>
                    <div class="col-sm-3 text-end text-right">
                      <button *ngIf="!this.currenttaxdeductionId" type="button" (click)="createTaxDeduction()" class="btn">
                        <div>Create</div>
                      </button>
                      <button *ngIf="this.currenttaxdeductionId" type="button" (click)="updateDeduction()" class="btn">
                        <div>Update</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="this.taxdeductionform.controls.financialYear.value">
              <div class="disable-state">
                <div class="canvas-data">
                  <div class="heading row align-items-center m-0">
                    <h5>HRA Exemptions U/s 10</h5>
                  </div>
      
                  <div class="row mb-3">
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="basicSalaryDA" />
                          <label class="floating-label">Basic Salary+DA</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="" />
                          <label class="floating-label">HRA As per Salary Slip</label>
                          <i class="ri-user-2-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="hra" />
                          <label class="floating-label"> House Rent Allowance</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="lta" />
                          <label class="floating-label">Leave Travel Allowance</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="gratuityPayment" />
                          <label class="floating-label">Gratuity Payment</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="leaveEncashmentPayment" />
                          <label class="floating-label">Leave Encashment Payment</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="" />
                          <label class="floating-label">Uniform/Professional Devop.Allowance</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
      
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="childrenEducationAllowance" />
                          <label class="floating-label">Children Education Allowance</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="otherTaxFreeAllowanceOrReimbursements" />
                          <label class="floating-label">Other Tax Free Allowances/reimbursements</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
      
                      </div>
      
                    </div>
      
                  </div>
      
                  <div class="heading m-0 row align-items-center">
                    <h5>Deduction U/s 16 </h5>
                  </div>
      
                  <div class="row mb-3">
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="sharedDeduction" />
                          <label class="floating-label">Standard Deduction</label>
                          <i class="ri-file-list-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="professionalTax" />
                          <label class="floating-label">Professional Tax</label>
                          <i class="ri-file-list-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
      
                  <div class="heading mb-0 row align-items-center">
                    <h5>Deductions under chapter VI-A</h5>
                  </div>
      
                  <div class="row mb-3">
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80C" />
                          <label class="floating-label">Investments Section 80C</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80DMediclaim" />
                          <label class="floating-label">Medical Insurance Preminum Sec 80D</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80E" />
                          <label class="floating-label">Interest on Higher Education Loan(Sec 80E)</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80TTA" />
                          <label class="floating-label">Section 80TTA</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="incomeFromHousePropertySection3" />
                          <label class="floating-label">Income from House property-(Section 3)</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="incomeLossOnHouseProperty" />
                          <label class="floating-label"> Income (loss) on House Property(Let Out + Self Occupied)</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80CCD1" />
                          <label class="floating-label">Contribution to NPS Sec 80CCD1</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80CCD2" />
                          <label class="floating-label">Employer Contribution to NPS Sec 80CCD2</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80DD" />
                          <label class="floating-label">Medical treatment for handicapped dependents 80DD</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80DDB" />
                          <label class="floating-label">Medical treatment for specified disease 80DDB</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80EEA" />
                          <label class="floating-label">Interest on Housing loan 80EEA</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80EEB" />
                          <label class="floating-label">nterest on Electric Vehicle Loan 80EEB</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80GGGAGGC" />
                          <label class="floating-label">Donations Eligible for tax deduction 80GGGAGGC</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80GG" />
                          <label class="floating-label">eduction in respect of rents paids 80GG</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 mb-2">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="section80GGGAGGC" />
                          <label class="floating-label">Deduction for permanents disability 80U</label>
                          <i class="ri-hashtag"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </section>
  </article>
</main>

<!-- Delete an item -->
<div class="modal fade" id="delete-items" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form action="">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure want to delete?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>Lorem ipsum dolor sit amet.</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary">Yes Delete</a>
        </div>
      </form>
    </div>
  </div>
</div>