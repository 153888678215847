import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class TimesheetService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  public reportData: BehaviorSubject<any> = new BehaviorSubject({});
  public reportData$ =  this.reportData.asObservable();
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);

   }
  
   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }


  // Create Timesheet
  createTimesheet(user: Object): Observable<Object> {
    console.log("In createTimesheet method, Timesheet: ", user);
    return this.http.post(`${environment.apiUrl}/timesheets`, user).pipe(catchError(this.handleError));
  }

   // Get Contact by Id Get
   getTimesheet(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/timesheets/${id}`).pipe(catchError(this.handleError));
  }


  //Delete Contact by Id Delete
  deleteTimesheet(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/timesheets/${id}`).pipe(catchError(this.handleError));
  }

  //Update Contact by Id Put
  updateTimesheet(data: Object,id: number): Observable<Object> {
    console.log("In updateTimesheet method, user: ", data);
    return this.http.put(`${environment.apiUrl}/timesheets/${id}`, data).pipe(catchError(this.handleError));
  }

   //Get All Contact by Id Get
   getAllTimesheet(userId): Observable<Object> {
    let uri = `${environment.apiUrl}/timesheets`;
    if(userId){
      uri = uri + `?assignedTo=${userId}`;
    }
    return this.http.get(uri).pipe(catchError(this.handleError));
  }


     // Get time sheet by date range
     getTimesheetDateRange( startDate, endDate): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/timesheets?startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
    }


  // Get timesheetgraph
  getTimesheetReports(type,startDate,endDate): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/timesheetReports?type=${type}&startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
  }
  
  //Get Timesheet report
  getTimesheetReport(params): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/timesheetReports?${params}`).pipe(catchError(this.handleError));
  }

  // Get No timesheet repory
  getNoTimesheetPunchReport(startDate,endDate): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/noTimesheetPunchReport?startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
  }
}
