import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/_services/user/user.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service'

@Component({
  selector: 'app-onboarding-nda',
  templateUrl: './onboarding-nda.component.html',
  styleUrls: ['./onboarding-nda.component.scss']
})
export class OnboardingNdaComponent implements OnInit {
  tenantId: string;
  usersForm;
  userCreationForm;
  userDropdownList = [];
  getUserName: any;
  isNDAAdmin: boolean;
  isHRAdmin: boolean;
  userDetails: any;
  requester: any;
  userCreationId: any;
  userFullName: any;
  usersData: any = [];
  usersCreationData: any = [];

  constructor(
    private router: Router,
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
  ) {
    this.usersForm = this.formBuilder.group({
      id: new FormControl(''),
      userId: new FormControl(''),
      password: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      displayName: new FormControl(''),
      department: new FormControl(''),
      phoneNo: new FormControl(''),
      location: new FormControl(''),
      joinDate: new FormControl(''),
      permanentOrContractor: new FormControl(''),
      alternateEmailId: new FormControl(''),
      supervisorId: new FormControl(''),
      supervisonName: new FormControl(''),
      currentDesignation: new FormControl(''),
      careerStartDate: new FormControl(''),
      middleName: new FormControl(''),
      managerId: new FormControl(''),
      managerName: new FormControl(''),
      addedToBioMetrics: new FormControl(''),
      totalExperience: new FormControl(''),
      currentExperience: new FormControl(''),
      localId: new FormControl(''),
      status: new FormControl(''),
      birthDate: new FormControl(''),
    });
    this.userCreationForm = this.formBuilder.group({
      id: new FormControl(''),
      requesterId: new FormControl({value:'' , disabled: true}),
      requesterName: new FormControl({value:'' , disabled: true}),
      requestedDate: new FormControl({value:'' , disabled: true}),
      status: new FormControl({value:''}),
      userId: new FormControl({value:'' , disabled: true}),
      firstName: new FormControl({value:'' , disabled: true}),
      middleName: new FormControl({value:'' , disabled: true}),
      lastName: new FormControl({value:'' , disabled: true}),
      emailId: new FormControl({value:'' , disabled: true}),
      remarks: new FormControl({value:'' , disabled: true}),
      addedToBioMetrics: new FormControl({value:'' , disabled: true}),
      licenseType: new FormControl({value:'' , disabled: true}),
      licenseRequired: new FormControl({value:'' , disabled: true}),
      licenseAllocation: new FormControl({value:'' , disabled: true}),
      systemRequiredBoolean: new FormControl({value:'' , disabled: true}),
      systemRequired: new FormControl({value:'' , disabled: true}),
      ndaSignedDate: new FormControl(''),
      biometric: new FormControl(''),
      ismsTraining: new FormControl({value:'' , disabled: true}),
      ismsAgreement: new FormControl({value:'' , disabled: true}),
      joinDate: new FormControl(''),
      emailIdRequired: new FormControl({value:''}),
      ndaSignedBoolean: new FormControl({value:''}),
      domainUserId: new FormControl({value:''})
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));

    this.isHRAdmin = this.userDetails.admin || this.userDetails.roles.includes('RoleHrmAdministrator');
    this.isNDAAdmin = this.userDetails.admin || this.userDetails.roles.includes('RoleNDATeam');
    
    this.userCreationForm.patchValue(this.userCreationForm.value);
    this.userCreationId = this.route.snapshot.paramMap.get('id');
    if (this.userCreationId) {
      this.getUserCreationById(this.userCreationId);
    }
  }

  getUserCreationById(id){
    this.userService.getUserCreationById(id).subscribe((response) => {
      console.log("response",response);
      this.usersCreationData = response;
      if (response) {
        this.userCreationForm.patchValue({
          requesterId: response.requesterId + " . " + response.requesterName,
          requestedDate: response.requestedDate,
          userId: response.userId + " . " + response.firstName + " " + response.lastName,
          status: response.status,
          remarks: response.remarks,
          addedToBioMetrics: response.addedToBioMetrics,
          ndaSignedBoolean: response.ndaSignedBoolean,
          ndaSignedDate: response.ndaSignedDate,
          systemRequiredBoolean: response.systemRequiredBoolean,
          systemRequired: response.systemRequired,
          emailIdRequired: response.emailIdRequired,
          domainUserId: response.domainUserId,
          biometric: response.biometric,
          ismsTraining: response.ismsTraining,
          licenseRequired: response.licenseRequired,
          licenseAllocation: response.licenseAllocation,
          licenseType: response.licenseType,
          passwordBoolean: response.passwordBoolean,
        })
      }

      let userId = response.userId;
      let status = response.status;
      this.userService.getUser(userId).subscribe((response) => {
        this.usersData = response;
        this.userFullName = response.firstName + " "+ response.middleName + " " + response.lastName;
        this.usersForm.patchValue({
          userId: response.userId,
          localId: response.localId,
          password: response.password,
          firstName: response.firstName,
          middleName: response.middleName,
          lastName: response.lastName,
          displayName: response.displayName,
          department: response.department,
          alternateEmailId: response.alternateEmailId,
          phoneNo: response.phoneNo,
          location: response.location,
          joinDate: response.joinDate,
          permanentOrContractor: response.permanentOrContractor,
          supervisonName: response.supervisorId + ' . ' + response.supervisonName,
          currentDesignation: response.currentDesignation,
          archived: true,
          managerName: response.managerName,
          birthDate: response.birthDate,
        });
        this.userCreationForm.value.status = status;
        this.userCreationForm.value.joinDate = response.joinDate;
        this.userCreationForm.value.emailId = response.emailId;
        this.userCreationForm.patchValue(this.userCreationForm.value);
      })
    })
  }

  updateUserCreation(){
    let userId = this.userCreationForm.get('userId').value.split(' . ')[0];
    let user = {
      id: parseInt(userId),
      userId: this.usersData.userId,
      localId: this.usersData.localId,
      firstName: this.usersData.firstName,
      middleName: this.usersData.middleName,
      lastName: this.usersData.lastName,
      displayName: this.usersData.displayName,
      department: this.usersData.department,
      emailId: this.usersData.emailId,
      alternateEmailId: this.usersData.alternateEmailId,
      phoneNo: this.usersData.phoneNo,
      location: this.usersData.location,
      joinDate: this.usersData.joinDate,
      permanentOrContractor: this.usersData.permanentOrContractor,
      supervisorId: this.usersData.supervisorId,
      supervisonName: this.usersData.supervisonName,
      currentDesignation: this.usersData.currentDesignation,
      archived: true,
      managerName: this.usersData.managerName,
      // roles: this.usersData.roles,
      hrId: this.usersData.hrId,
      hrName: this.usersData.hrName,
      birthDate: this.usersData.birthDate,
    }
    this.userService.updateUser(userId, user).subscribe((response)=>{
      let userCreationData = {
        id: parseInt(this.userCreationId),
        requesterId: this.usersCreationData.requesterId,
        requesterName: this.usersCreationData.requesterName,
        requestedDate: this.usersCreationData.requestedDate,
        userId: this.usersCreationData.userId,
        firstName: this.usersCreationData.firstName,
        lastName: this.usersCreationData.lastName,
        emailId: this.usersCreationData.emailId,
        status: 'withItTeam',
        remarks: this.usersCreationData.remarks,
        ndaSignedDate: this.userCreationForm.get('ndaSignedDate').value,
        ndaSignedBoolean: this.userCreationForm.get('ndaSignedBoolean').value,
        licenseType: this.usersCreationData.licenseType,
        licenseRequired: this.usersCreationData.licenseRequired,
        licenseAllocation: this.usersCreationData.licenseAllocation,
        systemRequiredBoolean: this.usersCreationData.systemRequiredBoolean,
        systemRequired: this.usersCreationData.systemRequired,
        biometric: this.usersCreationData.biometric,
        ismsTraining: this.usersCreationData.ismsTraining,
        ismsAgreement: this.usersCreationData.ismsAgreement,
        passwordBoolean: this.usersCreationData.passwordBoolean,
        domainUserId: this.usersCreationData.domainUserId,
        emailIdRequired: this.usersCreationData.emailIdRequired,
      }
      if (response) {
        this.userService.updateUserCreation(this.userCreationId, userCreationData).subscribe((response) => {
          this.backToOnboarding();
        })
      }
    })
  }

  get f() {
    return this.usersForm.controls;
  }

  openItonboarding() {
    this.router.navigate([this.tenantId + '/dashboard/hr/onboarding-component/onboarding-it', {id: this.userCreationId}]);
  }

  openHrmonboarding() {
    this.router.navigate([this.tenantId + '/dashboard/hr/onboarding-component/onboarding-form', {id: this.userCreationId}]);
  }

  openndaonboarding() {
    this.router.navigate([this.tenantId + '/dashboard/hr/onboarding-component/onboarding-nda', {id: this.userCreationId}]);
  }

  backToOnboarding(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/onboarding-component/onboarding']);
  }
}
