import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from "src/app/_helpers/auth-guard.service";
import { ProfileDashboardLayoutComponent } from "./profile-dashboard-layout/profile-dashboard-layout.component";
import { ProfilePageComponent } from "./profile-page/profile-page.component";
import { ProfileSettingComponent } from "./profile-setting/profile-setting.component";
import { ProfileComponent } from './profile.component';
import { UploadDocumentComponent } from "./upload-document/upload-document.component";

const routes: Routes = [{
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'profile-setting',
        component: ProfileSettingComponent, canActivate: [AuthGuardService]
      },
      {
        path: 'profile-page',
        component: ProfilePageComponent, canActivate: [AuthGuardService]
      },
      {
        path: 'profile-dashboard-layout',
        component: ProfileDashboardLayoutComponent, canActivate: [AuthGuardService]
      },
      {
        path: 'upload-document',
        component: UploadDocumentComponent, canActivate: [AuthGuardService]
      }
      
    ]
}];  

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class ProfileRoutingModule { }