<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Capacity planning</h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#createCapacityPlanning">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#deleteCapacityPlanning" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div>
                                <button type="button" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#createCapacityPlanning" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        style="height: calc(100vh - 120px) !important;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (cellDoubleClicked)="onCellClicked($event)"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>
  
<!-- Create new CapacityPlanning -->
<div class="modal fade" id="createCapacityPlanning" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="capacityPlanningForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create new Capacity</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="capacityPlanningName" type="text" required>
                            <label  for="capacityPlanningName" class="floating-label">Capacity Planning Name</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                        <mat-error *ngIf="f.capacityPlanningName.invalid && (f.capacityPlanningName.touched || f.capacityPlanningName.dirty)" class="alert alert-danger">
                            <mat-error *ngIf="f.capacityPlanningName.errors.required">
                              CapacityPlaningName is required
                            </mat-error>
                        </mat-error>  
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="startDate" type="date" id="startDate" >
                            <label for="startDate" class="floating-label">Start Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                        <mat-error *ngIf="f.startDate.invalid && (f.startDate.touched || f.startDate.dirty)" class="alert alert-danger">
                            <mat-error *ngIf="f.startDate.errors.required">
                              startDate is required
                            </mat-error>
                        </mat-error> 
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="endDate" type="date">
                            <label for="endDate" class="floating-label">End Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                        <mat-error *ngIf="f.endDate.invalid && (f.endDate.touched || f.endDate.dirty)" class="alert alert-danger">
                            <mat-error *ngIf="f.endDate.errors.required">
                              endDate is required
                            </mat-error>
                        </mat-error> 
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="closeCreateModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createCapacityPlanning()" [disabled]="!capacityPlanningForm.valid?true:null" >Create</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateCapacityPlanning()" >Update</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteCapacityPlanning" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteLead" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteCPbyID()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div> 
