import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/_services/project/project.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup} from '@angular/forms';

import { Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi, ICellRendererParams, RowGroupingDisplayType } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { TimesheetService } from 'src/app/_services/timesheet/timesheet.service';
import { ActionItemsGridRowRendererComponent } from '../../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { convertYYYYMMDDtoDDMMYYYY } from 'src/app/_helpers/utils';
import { HttpClient } from '@angular/common/http';
import { TaskService } from 'src/app/_services/task/task.service';

@Component({
  selector: 'app-timesheet-report',
  templateUrl: './timesheet-report.component.html',
  styleUrls: ['./timesheet-report.component.scss']
})
export class TimesheetReportComponent implements OnInit {
   // row data and column definitions
  rowData: any;
  // public columnDefs: ColDef[];
  public columnDefs: ColDef[] = []
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
    wrapText: true,
  };
  public groupDisplayType: RowGroupingDisplayType = 'groupRows';
  // public rowData!: any;

  modeAction: number = 1; //1 - create // 2 - update
  public employeeList:  Array<any> = [];
  public projectList:  Array<any> = [];
  public systemList: Array<any> = [];
  public subSystemList: Array<any> = [];
  public subSubSystemList: Array<any> = [];
  lafduration: number;
  latduration: number;
  laduration: number;
  th: number;
  date: Date;
  pjCode: number;
  public typeList: Array<any> = [
    {
      lable: 'Project',
      value: 'project'
    },
    {
      lable: 'User',
      value: 'user'
    },
    {
      lable: 'System',
      value: 'system'
    },
    {
      lable: 'Sub-System',
      value: 'subSystem'
    },
    {
      lable: 'Sub-SubSystem',
      value: 'subSubSystem'
    }
  ];
  public selectedType: any = null;
  // gridApi and columnApi
  private api: GridApi;
  private columnApi: ColumnApi;
  // defaultColDef: any;
  rowSelection: any;
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  modalService: any;
  currentUser: any;
  jsonData: any;
  emailPattern = '^[a-z0-9._%+-]+\\.[a-z]{2,4}$';
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';
  userDropdownList = [];
  projectDropdownList = [];
  projectCode: number;
  projname: any;
  proj = [];
  getProjectDetailsList: any;
  getUserName: any;
  projectDetailList: any;
  isPMOManager: boolean = false;

  constructor(
    private storageService: LocalStorageService,
    private timesheetService: TimesheetService,
    private formBuilder: FormBuilder,
    private allocationService: AllocationService,
    private projectService: ProjectService,
    private router: Router,
    private taskService: TaskService,
  ) { 
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    // this.columnDefs = this.createColumnDefs();

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent,
    };

  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isPMOManager = this.currentUser.roles.includes('RolePmoManager') || this.currentUser.roles.includes('RoleReportsExtractor');
    this.getUserList();
    this.getProjectList();
    this.getpmoSystemInformationDetails();
    this.form = this.formBuilder.group( {
      type: [null],
      ids: [null],
      startDate: [""],
      endDate: [""]
    });
  }

  onGridReady(params: any) {
    this.rowData = [];
    // this.generateReport();
    // this.rowData = data;
    // this.generateReport()
    // this.http
    //   .get<any[]>(
    //     'https://www.ag-grid.com/example-assets/olympic-winners.json'
    //   )
    //   .subscribe((data) => {
    //     
    //   });
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
  // onGridReady(params): void {
  //   this.api = params.api;
  //   this.columnApi = params.columnApi;
  //   this.api.sizeColumnsToFit();

    
  // }
  public onSelectAll(type) {
    this.form.get('ids').patchValue([]);
    switch (type) {
      case 'project':
        const selectedProject = this.projectList.map(item => item.id);
        this.form.get('ids').patchValue(selectedProject);
        break;
        case 'user':
          const selected = this.employeeList.map(item => item.id);
          this.form.get('ids').patchValue(selected);
        break;
        case 'system':
          const selectedSystem = this.systemList.map(item => item);
          this.form.get('ids').patchValue(selectedSystem);
        break;
        case 'subSystem':
          const selectedSubSystem = this.subSystemList.map(item => item);
          this.form.get('ids').patchValue(selectedSubSystem);
        break;
        case 'subSubSystem':
          const selectedSubSubSystem = this.subSubSystemList.map(item => item);
          this.form.get('ids').patchValue(selectedSubSubSystem);
        break;
      default:
        break;
    }
  }
  
  public onClearAll() {
    this.form.get('ids').patchValue([]);
  }


  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  getUserList() {
    this.userDropdownList = [];

    if(this.isPMOManager === true){
      this.allocationService.getShiftAllocationUsers().subscribe((response) => {
        for (let item of response) {
          this.employeeList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      });
    }
    else{
      this.allocationService.getUserDetails().subscribe((response) => {
        for (let item of response)  {
          this.employeeList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      })
    }
  }

  // getProjectList() {
  //   this.projectService.getProjectDetails().subscribe((response : any [])  => {
  //     this.projectList = response.filter(p => {
  //           return ((this.currentUser.id.toString() === p.projectManagerId.toString()) || 
  //           (p.projectController ? 
  //             (p.projectController.toString().split(' . ').length === 2 ? 
  //                 p.projectController.split(' . ')[0] === this.currentUser.id : 
  //             false) : 
  //           false))
  //     })
  //   })
  // }

  getProjectList() {
    if(this.isPMOManager === true){
      this.projectService.getProjectByUserId(this.currentUser.id, false).subscribe((response: any) => {
        this.projectList = response;
      });
    }
    else{
      this.projectService.getProjectDetails().subscribe(response => {
        this.projectList = response;
      })
    }
  }

  onchangeType() {
    this.form.patchValue({
      ids: []
    });
  }

  generateReport() {
    this.columnDefs= [];
    let type = this.form.value.type ? this.form.value.type : '';
    let startDate = this.form.value.startDate ? convertYYYYMMDDtoDDMMYYYY(this.form.value.startDate) : '';
    let endDate = this.form.value.endDate ? convertYYYYMMDDtoDDMMYYYY(this.form.value.endDate) : '';
    let ids: any;

    if(type === 'user'){
      let idss : string = '';
      this.form.value.ids.forEach(element => {
        idss = idss + element.split(' . ')[0] + ','
      });
      if(idss.endsWith(',')) {
        idss = idss.substring(0, idss.length-1);
      }
      ids = idss;
    }
    else{
      ids = this.form.value.ids && this.form.value.ids.length > 0 ? this.form.value.ids.join(',') : "";
    }
    let queryParams = `type=${type}&startDate=${startDate}&endDate=${endDate}&ids=${encodeURIComponent(ids)}`
    this.timesheetService.getTimesheetReport(queryParams).subscribe((res => {
      if(res) {
      
        let userArray: Array<any> = [];
        let projectArray: Array<any> = [];
        let systemArray: Array<any> = [];
        let subSystemArray: Array<any> = [];
        let subSubSystemArray: Array<any> = [];
        if(this.form.value.type == 'user') {
          this.columnDefs = [
            { field: 'employeeName', rowGroup: true, hide: true },
            {
              field: 'employeeName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'projectName', minWidth: 200 , rowGroup: true, hide: true },
            {
              field: 'projectName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'projectTotalHours' },
            { field: 'taskName',minWidth: 200,tooltipField: 'taskName',},
            { field: 'description',minWidth: 250,tooltipField: 'description',},
            { field: 'subSubSystem' },
            { field: 'startDate' },
            { field: 'endDate' },
            { field: 'taksAppliedHours'},
            { field: 'taskApprovedHours'},
            { field: 'efficiency' },
            { field: 'userTotalAppliedHours' },
            { field: 'userTotalApprovedHours' },
          ];
          
          if(res['employeesInfo'] && res['employeesInfo'].length > 0) {
            let employess = res['employeesInfo'];
            
            for (let i = 0; i < employess.length; i++) {
              if(res['employeesInfo'][i]['projects'] && res['employeesInfo'][i]['projects'].length > 0) {
                let projects = res['employeesInfo'][i]['projects'];
                for (let index = 0; index < projects.length; index++) {
                  if(res['employeesInfo'][i]['employee']['projectTasks'] && res['employeesInfo'][i]['employee']['projectTasks'].length > 0){
                    for(let j = 0 ; j< res['employeesInfo'][i]['employee']['projectTasks'].length; j++){
                      userArray.push({
                        projectName: projects[index].projectName,
                        projectId: projects[index].projectId,
                        projectTotalHours: projects[index].projectTotalHours,
                        taskName: res['employeesInfo'][i]['employee']['projectTasks'][j]['task'].name,
                        taksAppliedHours: res['employeesInfo'][i]['employee']['projectTasks'][j].userTotalAppliedHours,
                        taskApprovedHours: res['employeesInfo'][i]['employee']['projectTasks'][j].userTotalApprovedHours,
                        userTotalAppliedHours: res['employeesInfo'][i]['employee'].userTotalAppliedHours,
                        userTotalApprovedHours: res['employeesInfo'][i]['employee'].userTotalApprovedHours,
                        employeeName:  res['employeesInfo'][i]['employee'].name,
                        totalAppliedHours:  res['totalAppliedHours'],
                        totalApprovedHours:  res['totalApprovedHours'],
                        efficiency:  res['efficiency'],
                        description:  res['employeesInfo'][i]['employees'][index]['projectTasks'][j]['task'].description,          
                        id: res['employeesInfo'][i]['employee'].id,
                        subSubSystem:  res['employeesInfo'][i]['employees'][index]['projectTasks'][j]['task'].subSubSystem,
                        startDate:  res['employeesInfo'][i]['employees'][index]['projectTasks'][j]['task'].startDate,
                        endDate:  res['employeesInfo'][i]['employees'][index]['projectTasks'][j]['task'].endDate,
                      });
                    }
                  }
                }
              }
            }
          }
          this.rowData = userArray;
        } 
        if(this.form.value.type == 'project') {
          this.timesheetService.reportData.next(res);
          this.columnDefs = [
            { field: 'projectName', rowGroup: true, hide: true },
            {
              field: 'projectName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'employeeName', rowGroup: true, hide: true },
            {
              field: 'employeeName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'taskName',minWidth: 200,tooltipField: 'taskName',},
            { field: 'description',minWidth: 200,tooltipField: 'description',},
            { field: 'subSubSystem' },
            { field: 'startDate' },
            { field: 'endDate' },
            { field: 'taksAppliedHours' },
            { field: 'taskApprovedHours' },
            { field: 'userTotalAppliedHours' },
            { field: 'userTotalApprovedHours' },
            { field: 'efficiency' },
            
          ];
          if(res['projectsInfo'] && res['projectsInfo'].length > 0) {
            let projects = res['projectsInfo'];
            for (let i = 0; i < projects.length; i++) {
              if(res['projectsInfo'][i]['employees'] && res['projectsInfo'][i]['employees'].length > 0) {
                let employees = res['projectsInfo'][i]['employees'];
                for (let index = 0; index < employees.length; index++) {
                  if(res['projectsInfo'][i]['employees'][index]['projectTasks'] && res['projectsInfo'][i]['employees'][index]['projectTasks'].length > 0){
                    for(let j = 0 ; j< res['projectsInfo'][i]['employees'][index]['projectTasks'].length; j++){
                      projectArray.push({
                        projectName: res['projectsInfo'][i]['project'].projectName,
                        projectId:  res['projectsInfo'][i]['project'].projectId,
                        projectTotalHours: res['projectsInfo'][i]['project'].projectTotalHours,
                        employeeName:  employees[index].name,
                        taskName: res['projectsInfo'][i]['employees'][index]['projectTasks'][j]['task'].name,
                        taksAppliedHours: res['projectsInfo'][i]['employees'][index]['projectTasks'][j].userTotalAppliedHours,
                        taskApprovedHours: res['projectsInfo'][i]['employees'][index]['projectTasks'][j].userTotalApprovedHours,
                        userTotalAppliedHours: employees[index].userTotalAppliedHours,
                        userTotalApprovedHours: employees[index].userTotalApprovedHours,
                        totalAppliedHours:  res['totalAppliedHours'],
                        totalApprovedHours:  res['totalApprovedHours'],
                        description:  res['projectsInfo'][i]['employees'][index]['projectTasks'][j]['task'].description,
                        efficiency:  employees[index]['efficiency'],
                        id: res['projectsInfo'][i]['project'].id,
                        subSubSystem:  res['projectsInfo'][i]['employees'][index]['projectTasks'][j]['task'].subSubSystem,
                        startDate:  res['projectsInfo'][i]['employees'][index]['projectTasks'][j]['task'].startDate,
                        endDate:  res['projectsInfo'][i]['employees'][index]['projectTasks'][j]['task'].endDate,
                      });
                    }
                  }
                }
              } 
            }
          }
          this.rowData = projectArray
        }
        
        if(this.form.value.type == 'system') {
          this.timesheetService.reportData.next(res);
          this.columnDefs = [
            { field: 'systemName', rowGroup: true, hide: true },
            {
              field: 'systemName',
              minWidth: 250,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'employeeName', rowGroup: true, hide: true },
            {
              field: 'employeeName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'taskName',minWidth: 200,tooltipField: 'taskName', },
            { field: 'description',minWidth: 200,tooltipField: 'description',},
            { field: 'subSubSystem' },
            { field: 'startDate' },
            { field: 'endDate' },
            { field: 'taksAppliedHours' },
            { field: 'taskApprovedHours' },
            { field: 'userTotalAppliedHours' },
            { field: 'userTotalApprovedHours' },
            { field: 'efficiency' },
            
          ];
          if(res['systemInfo'] && res['systemInfo'].length > 0) {
            let projects = res['systemInfo'];
            for (let i = 0; i < projects.length; i++) {
              if(res['systemInfo'][i]['employees'] && res['systemInfo'][i]['employees'].length > 0) {
                let employees = res['systemInfo'][i]['employees'];
                for (let index = 0; index < employees.length; index++) {
                  if(res['systemInfo'][i]['employees'][index]['projectTasks'] && res['systemInfo'][i]['employees'][index]['projectTasks'].length > 0){
                    for(let j = 0 ; j< res['systemInfo'][i]['employees'][index]['projectTasks'].length; j++){
                      systemArray.push({
                        systemName: res['systemInfo'][i].name,
                        employeeName:  employees[index].name,
                        taskName: res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].name,
                        taksAppliedHours: res['systemInfo'][i]['employees'][index]['projectTasks'][j].userTotalAppliedHours,
                        taskApprovedHours: res['systemInfo'][i]['employees'][index]['projectTasks'][j].userTotalApprovedHours,
                        userTotalAppliedHours: employees[index].userTotalAppliedHours,
                        userTotalApprovedHours: employees[index].userTotalApprovedHours,
                        totalAppliedHours:  res['totalAppliedHours'],
                        totalApprovedHours:  res['totalApprovedHours'],
                        description:   res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].description,
                        subSubSystem:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].subSubSystem,
                        efficiency:  employees[index]['efficiency'],
                        startDate:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].startDate,
                        endDate:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].endDate,
                        
                      })
                    }
                  }
                }
              } 
            }
          }
          this.rowData = systemArray
        }

        if(this.form.value.type == 'subSystem') {
          this.timesheetService.reportData.next(res);
          this.columnDefs = [
            { field: 'subSystemName', rowGroup: true, hide: true },
            {
              field: 'subSystemName',
              minWidth: 250,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'employeeName', rowGroup: true, hide: true },
            {
              field: 'employeeName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'taskName', minWidth: 200,tooltipField: 'taskName', },
            { field: 'description', minWidth: 200,tooltipField: 'description',},
            { field: 'subSubSystem' },
            { field: 'startDate' },
            { field: 'endDate' },
            { field: 'taksAppliedHours' },
            { field: 'taskApprovedHours' },
            { field: 'userTotalAppliedHours' },
            { field: 'userTotalApprovedHours' },
            { field: 'efficiency' },
           
          ];
          if(res['systemInfo'] && res['systemInfo'].length > 0) {
            let projects = res['systemInfo'];
            for (let i = 0; i < projects.length; i++) {
              if(res['systemInfo'][i]['employees'] && res['systemInfo'][i]['employees'].length > 0) {
                let employees = res['systemInfo'][i]['employees'];
                for (let index = 0; index < employees.length; index++) {
                  if(res['systemInfo'][i]['employees'][index]['projectTasks'] && res['systemInfo'][i]['employees'][index]['projectTasks'].length > 0){
                    for(let j = 0 ; j< res['systemInfo'][i]['employees'][index]['projectTasks'].length; j++){
                      subSystemArray.push({
                        subSystemName: res['systemInfo'][i].name,
                        employeeName:  employees[index].name,
                        taskName: res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].name,
                        taksAppliedHours: res['systemInfo'][i]['employees'][index]['projectTasks'][j].userTotalAppliedHours,
                        taskApprovedHours: res['systemInfo'][i]['employees'][index]['projectTasks'][j].userTotalApprovedHours,
                        userTotalAppliedHours: employees[index].userTotalAppliedHours,
                        userTotalApprovedHours: employees[index].userTotalApprovedHours,
                        totalAppliedHours:  res['totalAppliedHours'],
                        totalApprovedHours:  res['totalApprovedHours'],
                        description:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].description,
                        subSubSystem:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].subSubSystem,
                        efficiency:  employees[index]['efficiency'],
                        startDate:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].startDate,
                        endDate:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].endDate,
                      })
                    }
                  }
                }
              } 
            }
          }
          this.rowData = subSystemArray
        }

        if(this.form.value.type == 'subSubSystem') {
          this.timesheetService.reportData.next(res);
          this.columnDefs = [
            { field: 'subSubSystemName', rowGroup: true, hide: true },
            {
              field: 'subSubSystemName',
              minWidth: 250,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'employeeName', rowGroup: true, hide: true },
            {
              field: 'employeeName',
              minWidth: 200,
              cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 60px">${params.value}</span>`;
              },
            },
            { field: 'taskName',minWidth: 200,tooltipField: 'taskName', },
            { field: 'description',minWidth:200,tooltipField: 'description',},
             { field: 'subSubSystem' },
             { field: 'startDate' },
            { field: 'endDate' },
            { field: 'taksAppliedHours' },
            { field: 'taskApprovedHours' },
            { field: 'userTotalAppliedHours' },
            { field: 'userTotalApprovedHours' },
            { field: 'efficiency' },
            
          ];
          if(res['systemInfo'] && res['systemInfo'].length > 0) {
            let projects = res['systemInfo'];
            for (let i = 0; i < projects.length; i++) {
              if(res['systemInfo'][i]['employees'] && res['systemInfo'][i]['employees'].length > 0) {
                let employees = res['systemInfo'][i]['employees'];
                for (let index = 0; index < employees.length; index++) {
                  if(res['systemInfo'][i]['employees'][index]['projectTasks'] && res['systemInfo'][i]['employees'][index]['projectTasks'].length > 0){
                    for(let j = 0 ; j< res['systemInfo'][i]['employees'][index]['projectTasks'].length; j++){
                      subSubSystemArray.push({
                        subSubSystemName: res['systemInfo'][i].name,
                        employeeName:  employees[index].name,
                        taskName: res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].name,
                        taksAppliedHours: res['systemInfo'][i]['employees'][index]['projectTasks'][j].userTotalAppliedHours,
                        taskApprovedHours: res['systemInfo'][i]['employees'][index]['projectTasks'][j].userTotalApprovedHours,
                        userTotalAppliedHours: employees[index].userTotalAppliedHours,
                        userTotalApprovedHours: employees[index].userTotalApprovedHours,
                        totalAppliedHours:  res['totalAppliedHours'],
                        totalApprovedHours:  res['totalApprovedHours'],
                        efficiency:  employees[index]['efficiency'],
                        description:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].description,
                        subSubSystem:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].subSubSystem,
                        startDate:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].startDate,
                        endDate:  res['systemInfo'][i]['employees'][index]['projectTasks'][j]['task'].endDate,
                      })
                    }
                  }
                }
              } 
            }
          }
          this.rowData = subSubSystemArray
        }
      }
      
    }))
  }

  backToTimesheet() {
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/time-sheet']);
  }

  timesheetGraph(){
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/timesheet-graph']);
  }

  timesheetNoPunch(){
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/timesheet-no-punch']);
  }

  getpmoSystemInformationDetails(){
    this.taskService.getpmoSystemInformationDetails().subscribe((response: any) => {
      this.systemList = response.system;
      this.subSystemList = response.subSystem;
      this.subSubSystemList = response.subSubSystem;
    });
  }
}
