import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service'
import { JobPostingService } from 'src/app/_services/job-posting/job-posting.service';
import { HttpResponse } from '@angular/common/http';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-job-applicant-details',
  templateUrl: './job-applicant-details.component.html',
  styleUrls: ['./job-applicant-details.component.scss']
})
export class JobApplicantDetailsComponent implements OnInit {
  form: FormGroup;
  jobApplicantDetailsForm;
  tenantId: string;
  sampleGridFrameworkComponents: any;
  jobApplicationId: any;
  jobPostTitle: any;
  jobPostId: any;
  jobApplicantName: any;
  imageid: any;
  imagename: any;
  attachments: any;
  jobDescriptionId: any;
  status: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private jobPostingService: JobPostingService,
    ) {
        this.route.queryParams.subscribe(params => { this.jobApplicationId = params['jobApplicationId'];
        this.jobDescriptionId = params['jobPostId']})
      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };

      this.jobApplicantDetailsForm = this.formBuilder.group({
        applicantName: new FormControl('', Validators.required),
        email: new FormControl('', Validators.required),
        phoneNo: new FormControl(''),
        relevantExperience: new FormControl(''),
        description: new FormControl(''),
        status: new FormControl(''),
        rating: new FormControl(''),
        offeredPackage: new FormControl(''),
      });
    }

      // getter for easy access to form fields
    get f() {
        return this.jobApplicantDetailsForm.controls;
    }


  ngOnInit(): void {
    this.tenantId = this.localStorageService.get('tenantId');
    this.getJobApplication();
    this.getJobApplicationAttachments(this.jobApplicationId);
  }

  getJobApplication(){
    this.jobPostingService.getJobApplication(this.jobApplicationId).subscribe((response: any) => {
        this.jobApplicantName = response.applicantName;
        this.jobPostId = response.jobDescriptionId;
        this.status = response.status;
        this.getJobDescription(response.jobDescriptionId);
        this.jobApplicantDetailsForm.patchValue(response);
    });
    
  }

  getJobDescription(jobPostId){
    this.jobPostingService.getJobDescription(jobPostId).subscribe((response: any) => {
        this.jobPostTitle = response.title;
    });
  }

  backToApplication(){
    this.router.navigate([this.tenantId + '/dashboard/hr/job-application']);
  }

  updateJobApplication(){
    let data = {
        jobDescriptionId: this.jobPostId,
        applicantName: this.jobApplicantDetailsForm.get('applicantName').value,
        email: this.jobApplicantDetailsForm.get('email').value,
        phoneNo: this.jobApplicantDetailsForm.get('phoneNo').value,
        relevantExperience: this.jobApplicantDetailsForm.get('relevantExperience').value,
        description: this.jobApplicantDetailsForm.get('description').value,
        status: this.jobApplicantDetailsForm.get('status').value,
        rating: this.jobApplicantDetailsForm.get('rating').value,
        offeredPackage: this.jobApplicantDetailsForm.get('offeredPackage').value,
    }
    this.jobPostingService.updateJobApplication(data, this.jobApplicationId).subscribe( (response: any) => {
      this.backToApplication();
      $('#closeModal').trigger('click');
      }
    );
  }

  getJobApplicationAttachments(jobApplicationId){
    this.jobPostingService.getjobApplicationAttachments(jobApplicationId).subscribe((response) => {
        console.log('getleaveApplicationsattachments: ', response);
        this.attachments = response;
        console.log('getleaveApplicationsattachments: ', this.attachments);
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }

  downloadJobApplicationAttachments(value){
    this.imageid = value.split(': ')[0];
    this.imagename = value.split(': ')[1];
    this.jobPostingService.downloadJobApplicationAttachments(this.jobApplicationId, this.imageid).subscribe((response: HttpResponse<Blob>) => {
        let filename: string = this.imagename;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        console.log('downloadJobApplicationAttachments: ', response);
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  } 

  backToJobApplication(){
    if(this.jobDescriptionId){
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application'], { queryParams: { jobPostId: this.jobDescriptionId } });
    }
    else{
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application']);
    }
  }

}