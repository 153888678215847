import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { JobPostingService } from 'src/app/_services/job-posting/job-posting.service';
import { UserService } from 'src/app/_services/user/user.service';
import { DatePipe } from '@angular/common';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-job-application-approved',
  templateUrl: './job-application-approved.component.html',
  styleUrls: ['./job-application-approved.component.scss']
})
export class JobApplicationApprovedComponent implements OnInit {
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[];
  private api: GridApi;
  userCreationForm;
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  sampleGridFrameworkComponents: any;
  gridApi: any;
  jobDescriptionId: any;
  // gridApi and columnApi  
  tenantId: string;
  currentUser: any;
  user : any;
  requester: any;
  TodayDate: string;
  date: any;
  shortlistedApplicant: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private localStorageService: LocalStorageService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute,
    private jobPostingService: JobPostingService,
    ) { 
      {
        this.defaultColDef = {
          flex: 1,
          minWidth: 200,
          resizable: true,
        };
        this.rowSelection = 'multiple';
        this.columnDefs = this.createColumnDefs();  
  
        this.route.queryParams.subscribe(params => { this.jobDescriptionId = params['jobPostId'];})

        this.sampleGridFrameworkComponents = {
          actionRowRendererComponent: ActionItemsGridRowRendererComponent
        };
      }

      this.userCreationForm = this.formBuilder.group({
        userId: new FormControl('', Validators.required),
        department: new FormControl('', Validators.required),
        location: new FormControl('', Validators.required),
      });
    }

  ngOnInit(): void {
    this.tenantId = this.localStorageService.get('tenantId');
    this.currentUser = JSON.parse(this.localStorageService.get('currentUser'));
    this.getJobApplicationData();
    this.requester = this.currentUser.id + " . " + this.currentUser.firstName + " " + this.currentUser.lastName;
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;
  }

  // getter for easy access to form fields
  get f() {
    return this.userCreationForm.controls;
  }

  getJobApplicationData(){
    if(this.jobDescriptionId){
      this.jobPostingService.getAllJobApplicationsByJobPostId(this.jobDescriptionId).subscribe((response: any) => {
        for(let i=0; i< response.length; i++){
            if(response[i].status === 'Approved'){
                 this.shortlistedApplicant.push(response[i]);
            }
        }
        this.rowData = this.shortlistedApplicant;
      });
    } else {
      this.jobPostingService.getAllJobApplications().subscribe((response: any) => {
        for(let i=0; i< response.length; i++){
            if(response[i].status === 'Approved'){
                 this.shortlistedApplicant.push(response[i]);
            }
        }
        this.rowData = this.shortlistedApplicant;
      });
    }
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Name",
        field: "applicantName" ,'width': 150, 'suppressSizeToFit': true,
        mimWidth: 300 ,
        filter: true,
        editable: false,
        sortable: true,
        cellRenderer: this.createHyperLink.bind(this),
      },
      {
        headerName: 'Phone No.',
        field: 'phoneNo',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Email Id',
        field: 'email',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Relavent Experience',
        field: 'relevantExperience',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Attachment',
        field: 'attachment',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Rating',
        field: 'rating',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName:'Package',
        field: 'offerredPackage',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Action',
        cellRenderer : (params) => {
          if(params.data.status === "Approved") {
            const spanElement = document.createElement('div');
            spanElement.classList.add('d-flex');
            spanElement.innerHTML = `<button type="button" id="approvalBtn" class="btn btn-success" style="margin-right: 5px">On-board</button> <button type="button" id="cancelBtn"class="btn-danger btn">Cancel</button>`;
            spanElement.addEventListener('click', ($event) => {
              if($event.target && $event.target['id'] == "approvalBtn") {
                this.user = params.data;
                $event.preventDefault();
                let element: HTMLElement = document.getElementById('confimationBtn');
                element.click();
              } else {
                $event.preventDefault();
              }
              if($event.target && $event.target['id'] == "cancelBtn") {
                this.user = params.data;
                $event.preventDefault();
                let element: HTMLElement = document.getElementById('cancelConfirmationBtn');
                element.click();
              } else {
                $event.preventDefault();
              }
            });
            return spanElement; 
          }
        }
      },
    ]
  }

  /**
   * approval leave
   * @param user
   */
  onBoardCandidate() {
    console.log('user', this.user);
    let userDetail = {
      userId: this.userCreationForm.get('userId').value,
      department: this.userCreationForm.get('department').value,
      location: this.userCreationForm.get('location').value,
      firstName: this.user.applicantName.split(' ')[0],
      lastName: this.user.applicantName.split(' ')[1] ?  this.user.applicantName.split(' ')[0] : this.user.applicantName.split(' ')[0],
      displayName: this.user.applicantName,
      emailId: this.user.email,
      alternateEmailId: this.user.email,
      phoneNo: this.user.phoneNo,
      status: 'withHrTeam',
      currentExperience: this.user.currentExperience,
      joinDate: this.TodayDate,
      password: 'Abcd@123',
      //archived and HR fields are not shown in form
      archived: true,
      hrId: this.currentUser.id,
      hrName: this.currentUser.firstName + " " + this.currentUser.lastName,
    }
    this.userService.createUser(userDetail).subscribe((response: any) => {
      if(response != null){
        let userCreationDetails = {
          requesterId: this.currentUser.id,
          requesterName: this.currentUser.firstName + " " + this.currentUser.lastName,
          requestedDate: this.TodayDate,
          userId: response.id,
          firstName: response.firstName,
          middleName: response.middleName,
          lastName: response.lastName,
          managerName: response.managerName,
        }

        this.userService.userCreation(userCreationDetails).subscribe((response: any) => {
          let data = {
            jobDescriptionId: this.user.jobDescriptionId,
            applicantName: this.user.applicantName,
            email: this.user.email,
            phoneNo: this.user.phoneNo,
            relevantExperience: this.user.relevantExperience,
            description: this.user.description,
            status: 'Onboarded',
            rating: this.user.rating,
            offeredPackage: this.user.offeredPackage,
          }
          this.jobPostingService.updateJobApplication(data, this.user.id).subscribe( (response: any) => {
            this.getJobApplicationData();          
          });
        })
      }
    })
  }

  //cancelOnBoarding
  cancelOnBoarding() {
    // this.approvalService.regularizationsLeaveCancelById(userId, formatDate).subscribe({
    //   next: (value) => {
    //     this.getJobApplicationData();
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   },
    //   complete: () => {
        
    //   },
    // })
  }

  createHyperLink(params): any {
    if (!params.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a href="${params.value}" > ${params.value} </a> `;
    spanElement.addEventListener('click', ($event) => {
      $event.preventDefault();
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-applicant-details'], { queryParams: { jobApplicationId: params.data.id } });
    });
    return spanElement;
  }



  openJobPost() {
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-post'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openApplicantList(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openShortlistedApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-shortlisted'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openInterviewApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-interview'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openOfferedApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-offered'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  openApprovedApplicants(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application-approved'], { queryParams: { jobPostId: this.jobDescriptionId } });
  }

  backToJobApplication(){
    if(this.jobDescriptionId){
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application'], { queryParams: { jobPostId: this.jobDescriptionId } });
    }
    else{
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application']);
    }
  }
}
