import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from "src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component";
import { LocalStorageService } from "src/app/_services/localStorage/local-storage.service";
import { AllocationService } from "src/app/_services/resource-allocation/allocation.service";
import { HrService } from "src/app/_services/hr-management/hr.service";
import { AttendanceService } from "src/app/_services/attendance/attendance.service";
import 'rxjs/add/operator/filter';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'app-leave-revoke',
    templateUrl: './leave-revoke.component.html',
    styleUrls: ['./leave-revoke.component.scss']
})
export class LeaveRevokeComponent implements OnInit {
    leaveRevokeForm
    form: FormGroup;
    rowData: any;
    public columnDefs: ColDef[];
    modeAction: number = 1 //1 - create // 2 - update
    // gridApi and columnApi
    private api: GridApi;
    private columnApi: ColumnApi;
    defaultColDef: any;
    rowSelection: any;
    tenantId: string;
    currentUser: any;
    selectedFile: File;
    sampleGridFrameworkComponents: any;
    gridLength = 0;
    selectedCheckboxData = [];
    selectedRow: any;
    userDropdownList= [];

    constructor(
        private storageService: LocalStorageService,
        private formBuilder: FormBuilder,
        private router: Router,
        private attendanceService: AttendanceService,
        private allocationService: AllocationService,
        private hrService: HrService
    ) {
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            resizable: true,
        };
        this.rowSelection = 'multiple';
        this.columnDefs = this.createColumnDefs();

        this.sampleGridFrameworkComponents = {
            actionRowRendererComponent: ActionItemsGridRowRendererComponent,
        };

        this.form = this.formBuilder.group({
            file: ['', Validators.required]
        });

        this.leaveRevokeForm = new FormGroup({
            id: new FormControl(''),
            employeeId: new FormControl(''),
            employeeName: new FormControl(''),
            days: new FormControl(''),
            month: new FormControl(''),
            year: new FormControl(''),
            value: new FormControl('')
        });

    }

    ngOnInit(): void {
        this.tenantId = this.storageService.get('tenantId');
        this.currentUser = JSON.parse(this.storageService.get('currentUser'));
        this.getAllLeaveRevokalData();
        this.getUserList();
    }

    getUserList() {
        this.userDropdownList = [];
        this.allocationService.getUserDetails().subscribe((response) => {
          for (let item of response) {
            this.userDropdownList.push(
              `${item.id} . ${item.firstName} ${item.lastName}`
            );
          }
        });
      }

    getAllLeaveRevokalData() {
        this.hrService.getAllLOPRevoke().subscribe((response: any) => {
            this.rowData = response;
        });
    }

    onGridReady(params): void {
        this.api = params.api;
        console.log('qqqq', this.api);
        this.columnApi = params.columnApi;
        this.api.sizeColumnsToFit();
    }

    private createColumnDefs() {
        return [
            {
                headerName: 'Employee',
                valueGetter: function(params){
                    let employee = params.data.employeeId + ' . ' + params.data.employeeName;
                    return employee
                  },
                filter: true,
                editable: false,
                sortable: true,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
            },
            {
                headerName: 'Days to Revoke',
                field: 'days',
                filter: true,
                editable: false,
                sortable: true
            },
            {
                headerName: 'Month/Year',
                valueGetter: function(params){
                    return `${String(params.data.month).padStart(2, '0')}-${params.data.year}`;
                  },
                filter: true,
                editable: false,
                sortable: true
            },
            {
                headerName: 'Arrear Amount',
                field: 'value',
                filter: true,
                editable: false,
                sortable: true
            },
        ]
    }

    createOpenModal() {
        this.modeAction = 1;
        this.leaveRevokeForm.reset();
        this.leaveRevokeForm.patchValue({
            employeeId: '',
            days: '',
            month: '',
            year: ''
        });
    }

    openEditModal() {
        let data = this.selectedCheckboxData[0];
        let formattedDate = `${data.year}-${data.month}`;
        this.leaveRevokeForm.patchValue({
            employeeId: (data.employeeId + ' . ' + data.employeeName),
            days: data.days,
            month: formattedDate,
            year: data.year
        });
        this.modeAction = 2;
    }

    onSelectionChanged(event) {
        this.selectedCheckboxData = []
        let select = event.api.getSelectedNodes();
        for (let item of select) {
            this.selectedCheckboxData.push(item.data)
        }
    }

    isFirstColumn(params) {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        const thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    }

    backToLeaveProfile() {
        this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-profile']);
    }

    createLeaveRevoke() {
        let details = {
            employeeId: this.leaveRevokeForm.get('employeeId').value.split(' . ')[0],
            employeeName: this.leaveRevokeForm.get('employeeId').value.split(' . ')[1],
            days: this.leaveRevokeForm.get('days').value,
            month: this.leaveRevokeForm.get('month').value.split('-')[1],
            year: this.leaveRevokeForm.get('year').value,
            value: parseFloat(this.leaveRevokeForm.get('value').value)
        }
        this.hrService.createLOPRevoke(details).subscribe(response => {
            console.log("response: ", response);

            // update grid after creating new lead & close the modal
            this.api.applyTransaction({ add: [response] });
            $('#closeCreateModal').trigger("click");
        });
    }

    updateLeaveRevoke() {
        let details = {
            id: this.selectedCheckboxData[0].id,
            employeeId: this.leaveRevokeForm.get('employeeId').value.split(' . ')[0],
            employeeName: this.leaveRevokeForm.get('employeeId').value.split(' . ')[1],
            days: this.leaveRevokeForm.get('days').value,
            month: this.leaveRevokeForm.get('month').value.split('-')[1],
            year: this.leaveRevokeForm.get('year').value,
            value: parseFloat(this.leaveRevokeForm.get('value').value)
        }
        this.hrService.updateLOPRevoke(details, this.selectedCheckboxData[0].id).subscribe(response => {
            console.log("response: ", response);
            this.getAllLeaveRevokalData();
            $('#closeCreateModal').trigger("click");
        });
    }

}
