import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { environment } from "../../../environments/environment";
import { catchError, map, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProjectResourcesService extends Subject<DataStateChangeEventArgs> {

  constructor(
    private http: HttpClient
  ) { 
    super();
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

   // Create projectResources
   createprojectResources(resourceDetails): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/projectResources`, resourceDetails).pipe(catchError(this.handleError));
  }

   //Delete projectResources by Id Delete
   deleteProjectResources(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/projectResources/${id}`).pipe(catchError(this.handleError));
  }

   //Update projectResources by Id Put
   updateProjectResources(user: Object,id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/projectResources/${id}`, user).pipe(catchError(this.handleError));
  }

   //Get All projectResources
   getAllProjectResources(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projectResources`).pipe(catchError(this.handleError));
  }

  //Get All projectResources by Id Get
  getAllProjectResourcesByProjectId(projectId): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projectResources?projectId=${projectId}`).pipe(retry(2), catchError(this.handleError));
  }

}
