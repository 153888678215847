import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
//import { httpOptions } from "../auth/authentication.service";
import { environment } from "../../../environments/environment";
import { Opportunity } from '../../_models/opportunity.model';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService extends Subject<DataStateChangeEventArgs> {
  constructor(
    private http: HttpClient) {
    super();
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

//Synfusion  CRUD Operation
  public execute(state: any): void {
  //  console.log("Execute Opportunity funtion", state);
  //  console.log("Execute Return Staement", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
    this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs));
  //  console.log("Exeute Funtion", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
  }

  /** GET all data from the server */
  getAllData(state?: any): Observable<any[]> {
  //  console.log("GetAllData Opoprtunity",this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`).pipe(retry(2), catchError(this.handleError)));
    //return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`,httpOptions).pipe(retry(2), catchError(this.handleError));

    return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`).pipe(map((response: any) => (<any>{
      result: state.take > 0 ? response.slice(0, state.take) : response,
      count: response.length
    })));

  }



  /** POST: add a new record  to the server */
  addRecord(state: DataSourceChangedEventArgs): Observable<Opportunity> {
    // you can apply empty string instead of state.data to get failure(error)
  //  console.log("Add record opportunity Call");
    return this.http.post<Opportunity>(`${environment.apiUrl}/opportunities`, state.data);
  }

  /** DELETE: delete the record from the server */
  deleteRecord(state: any): Observable<Opportunity> {
    const id = state.data[0].id;
    const url = `${ environment.apiUrl}/opportunities/${ id }`;
    return this.http.delete<Opportunity>(url);
  }

  /** PUT: update the record on the server */
  updateRecord(state: any): Observable<Opportunity> {
    const id = state.data.id;
  //  console.log("UpdateRecord", this.http.put<Opportunity>(`${environment.apiUrl}/opportunities/${id }`, state.data));
    return this.http.put<Opportunity>(`${environment.apiUrl}/opportunities/${id }`, state.data);
  }

 
 
  // Add oppotunity
  createOpportunity(opportunity: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/opportunities`, opportunity).pipe(catchError(this.handleError));
  }
  public getData(): Observable<any> {
    return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`).pipe(retry(2), catchError(this.handleError));
  }
  public getSpecificData(id): Observable<any> {
    return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities/${id}`).pipe(retry(2), catchError(this.handleError));
  }
  public updateOpportunity(opportunity: Object, id: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/opportunities/${id}`, opportunity).pipe(catchError(this.handleError));
  }
  //Delete Opportunity by Id Delete
  deleteOpportunitys(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/opportunities/${id}`).pipe(catchError(this.handleError));
  }

  // Create crm association of contact with others(eg:account)
  createOpportunityAssociation(user: Object): Observable<Object> {
    console.log("In createOpportunityAssociation method, Opportunity Association: ", user);
    return this.http.post(`${environment.apiUrl}/crmAssociations`, user).pipe(catchError(this.handleError));
  }
}
