import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthenticationService } from "../_services/auth/authentication.service";
import { JwtTokenService } from "../_services/jwtToken/jwt-token.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private authService: AuthenticationService, private jwtService: JwtTokenService, private snackBar: MatSnackBar) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(this.jwtService.getUser()) {
      if(this.jwtService.isTokenExpired()) {
        this.snackBar.open("Session expired! Redirecting to login page...", "Close", { duration: 3000, verticalPosition: 'top' });
        this.router.navigate(['login']);
      } else {
        return true;
      }
    } else {
      this.snackBar.open("Access not allowed!", "Close", { duration: 3000, verticalPosition: 'top' });
      this.router.navigate(['login']);
      return false;
    }
      
  }
}
