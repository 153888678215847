<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button title="back page" (click)="backToLeaveProfile()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3> LeaveBook </h3>
                            </div>
                        </div>
                        <!-- <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#updateLeaveBook">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                        </div> -->
                    </div>

                    <div class="row">
                        <form [formGroup] = "employeeForm">
                        <div class="col-sm-8">
                            <div class="canvas-data">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <label class="floating-label">Employee Name</label>
                                                <input list="taskOwnerDatalist" formControlName="userId" placeholder="Employee Name" value="" />
                                                <datalist id="taskOwnerDatalist">
                                                    <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                                </datalist>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-2 text-end text-right">
                                        <button type="button" class="btn" (click)=getEmployee() >
                                            <div>Submit</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    </div> 

                    <ag-grid-angular
                        style="height: 620px;"
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)"
                        [rowData]="rowData"
                        [columnDefs]="columnDefs"
                        pagination="true"
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Update an item -->
<div class="modal fade" id="updateLeaveBook" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="leaveBookForm">
                <div class="modal-header">
                    <h5 class="modal-title">LeaveBook</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="leaveType" type="text" required disabled>
                            <label class="floating-label-special">Leave Type</label>
                            <i class="ri-building-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="balance" type="text">
                            <label class="floating-label-special">Balance Leave</label>
                            <i class="ri-phone-line"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="closeUpdateModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateLeaveBook()">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>