<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Salary Distribution </h3>
                        </div>
                        <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#updateModal" (click)="updateSalaryApp($event)">
                                  <span><i class="ri-pencil-line"></i></span>
                                </button>
                              </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#deleteLeaveModal" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div> 
                            <div>
                                <button type="button"  class="btn btn-icon">
                                    <i class="ri-add-line" (click)="salaryDistributionPage()" ></i>
                                    <!-- <a routerLink="/salary-distribustion-page"></a> -->
                                </button>
                            </div>
                        </div>
                    </div>
                   
                    <div>
                        <div class="row p-3 justify-content-between">
                            <div class="col-sm-6">
                                <div class="title">
                                    <h3>Financial Year</h3>
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="input">
                                    <div class="input-item">
                                        <select (change)="handleChange($event.target.selectedIndex)" name="cmbTipoTexto" class=""  id="cmbTipoTexto">
                                            <option *ngFor="let item of dateList" [value]="item.key">{{item.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                       
                        <ag-grid-angular 
                         style="height: 620px;width: 100;" 
                         class="ag-theme-alpine"
                         [rowData]="rowData" 
                         [columnDefs]="columnDefs" 
                         [animateRows]="true"
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        [rowDragManaged]="true"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10
                        [suppressDragLeaveHidesColumns]="true">
                        </ag-grid-angular>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
<!-- Delete an item -->
<div class="modal fade" id="deleteLeaveModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteLeave" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteleaveAppRates()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>


