
<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Resource Project </h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">                          
                            <button type="button"  data-bs-toggle="modal" data-bs-target="#opportunity" class="btn btn-icon"><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="canvas-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Project</th>
                                        <th>Details</th>
                                        <th>Status</th>
                                        <th>Probability</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of jsonData">
                                        <td>{{item.project}}</td>
                                        <td>{{item.details}}</td>
                                        <td>{{item.status}}</td>
                                        <td>{{item.probability}}</td>
                                        <td>
                                            <div class="table-action">
                                                <button class="action-btn"><i class="ri-more-2-fill"></i></button>
                                                <div class="action-items">
                                                    <div class="edit">
                                                        <button data-bs-toggle="modal" data-bs-target="#editResourceAllocationProject" type="button" (click)="openEdit(item)">
                                                            <span><i class="ri-pencil-line"></i></span>
                                                            <span>Edit</span>
                                                        </button>
                                                    </div>
                                                    <div class="delete">
                                                        <button data-bs-toggle="modal" data-bs-target="#deleteResourceAllocationProject" type="button" (click)="open(item)">
                                                            <span><i class="ri-delete-bin-5-line"></i></span>
                                                            <span>Delete</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Opportunity -->
<div class="modal fade" id="opportunity" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="resourceAddForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create new Project</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="project" type="text" required>
                            <label class="floating-label">Project</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input  formControlName="details" type="text" required>
                            <label class="floating-label">Details</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="status" type="text" required>
                            <label class="floating-label">Status</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="probability" type="text" required>
                            <label class="floating-label">Probability</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteResourceAllocationProject" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"(click)="createResourceAllocationProject()">Create Project</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteResourceAllocationProject" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>Are you sure you want to delete {{selectedRow?.ProjectName}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteResourceAllocationProject" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="deleteResourceAllocationProject(selectedRow?.id)">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div> 