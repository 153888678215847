
<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center" style="padding: 0 !important;">
                        <nav class="col-sm-8 p-0">
                          <div class="nav nav-tabs border-0" id="nav-tab">
                            <a class="nav-item nav-link title  p-2" (click)="timesheetPage()" role="tab"><h2> Timesheet</h2></a>
                            <a class="nav-item nav-link title active p-2"   ><h2>Project Task</h2></a>
                          </div>
                        </nav>
                        <div class="col-sm-4 d-flex justify-content-end">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" title="Edit" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#createTask" >
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteSaleTask" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div class=" add-btn">
                                <button type="button" title="Create" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#createTask" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    
                    <ag-grid-angular
                        style="height: 620px;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
               </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new createTask -->
<div class="modal fade bd-example-modal-lg" id="createTask">
    <div class="modal-dialog modal-lg">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="projectTaskGridForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create Task</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input type="text" list="projectList" formControlName="projectName" required>
                                    <datalist id="projectList">
                                        <option [value]="item" *ngFor="let item of projectDropdownList"></option>
                                    </datalist>
                                    <label class="floating-label-special">Project Name</label>
                                    <i class="ri-file-list-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input type="text" formControlName="name" required>
                                    <label class="floating-label-special">Task Name*</label>
                                    <i class="ri-task-line"></i>
                                </div>
                                <!-- <mat-error *ngIf="f.name.invalid && f.name.touched" class="alert alert-danger">
                                    <mat-error *ngIf="f.name.errors.required">Name is required</mat-error>
                                </mat-error> -->
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input type="text" formControlName="taskOwnerName" disabled required>
                                    <label class="floating-label-special">Task Owner*</label>
                                    <i class="ri-user-3-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <select formControlName="system" (change)="getSubSystemInformation($event)">
                                        <option value="" disabled>Select System</option>
                                        <option *ngFor="let system of systemArray" value="{{system}}">{{system}}</option>
                                     </select>
                                    <label class="floating-label-special">System Information</label>
                                    <i class="ri-menu-unfold-line"></i>
                                </div>
                            </div>
                        </div> 
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <select formControlName="subSystem" (change)="getSubSubSystemInformation($event)">
                                        <option value="" disabled>Select Sub-System</option>
                                        <option *ngFor="let subSystem of subSystemArray" value="{{subSystem}}">{{subSystem}}</option>
                                     </select>
                                    <label class="floating-label-special">Sub-System Information</label>
                                    <i class="ri-menu-unfold-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <select formControlName="subSubSystem">
                                        <option value="" disabled>Select Sub Sub-System</option>
                                        <option *ngFor="let subSubSystem of subSubSystemArray" value="{{subSubSystem}}">{{subSubSystem}}</option>
                                     </select>
                                    <label class="floating-label-special">Sub Sub-System Information</label>
                                    <i class="ri-menu-unfold-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input type="date" formControlName="startDate">
                                    <label class="floating-label-special">Start Date</label>
                                    <i class="ri-calendar-todo-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input type="date" formControlName="endDate">
                                    <label class="floating-label-special">End Date</label>
                                    <i class="ri-calendar-event-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                  <label class="floating-label floating-label-special"
                                    >Status</label
                                  >
                                  <select formControlName="status" placeholder="status">
                                    <option value="" disabled selected>Select Status</option>
                                    <option value="open">Open</option>
                                    <option value="close">Close</option>
                                  </select>
                                  <i class="ri-phone-line"></i>
                                </div>
                              </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="input">
                                <div class="input-item">
                                    <input type="text" formControlName="estimatedTimeInHours">
                                    <label class="floating-label-special">Estimate Time in Hours(ex. 10.5)</label>
                                    <i class="ri-timer-line"></i>
                                </div>
                            </div>
                        </div> 
                        <div class="col-sm-6" *ngIf="this.modeAction === 2">
                            <div class="input">
                                <div class="input-item">
                                    <input type="file" (change)="onFileChanged($event)" placeholder="Attachment" />
                                    <label class="floating-label-special">Attachment</label>
                                    <i class="ri-attachment-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" *ngIf="this.modeAction === 1">
                            <div class="input">
                                <div class="input-item">
                                    <input type="file" (change)="onFileChangedBeforeTaskCreation($event)" placeholder="Attachment" />
                                    <label class="floating-label-special">Attachment</label>
                                    <i class="ri-attachment-line"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="this.modeAction === 2">
                            <div *ngFor="let item of projectTaskAttachments">
                                <table style="border: 1px dotted blue; border-radius: 10px">
                                    <tbody>
                                        <tr>
                                            <td>
                                            <span>{{ item }}</span>
                                            </td>
                                            <td>
                                            <div class="delete">
                                                <button type="button" (click)="downloadProjectTaskAttachments(item)">
                                                <span><i class="ri-download-cloud-fill"></i></span>
                                                </button>
                                            </div>
                                            </td>
                                            <td>
                                            <div class="delete">
                                                <button type="button" (click)="deleteProjectTaskAttachments(item)">
                                                <span><i class="ri-delete-bin-5-line"></i></span>
                                                </button>
                                            </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="this.modeAction === 1">
                            <div *ngFor="let item of projectTaskAttachments">
                                <table style="border: 1px dotted blue; border-radius: 10px">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <span>{{ item }}</span>
                                            </td>
                                            <td>
                                                <div class="delete">
                                                    <button type="button" (click)="deleteProjectTaskAttachmentsBeforeTaskCreation(item)">
                                                        <span><i class="ri-delete-bin-5-line"></i></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                        <div class="col-sm-12">
                            <div class="input">
                                <div class="input-item">
                                    <textarea name="" id="" cols="12" rows="3" formControlName="description"></textarea>
                                    <label class="floating-label-special">Description</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="closeCreateModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createProjectTask()"  >Create</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateProjectTask()"  >Update</button> 
                </div>
            </form>
        </div>
    </div>
</div>