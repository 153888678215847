<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button title="back page" (click)="backToJobApplication()">
                                      <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3> {{jobPostTitle}} - {{jobApplicantName}} </h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <form [formGroup]="jobApplicantDetailsForm">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                <div class="canvas-data">
                                    <div class="row">
                                        <div class="row inner-details">
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Name</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="applicantName" disabled required />
                                                            <i class="ri-shield-user-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Email</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="email" required disabled />
                                                            <i class="ri-mail-unread-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row inner-details">
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Phone No.</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="phoneNo" disabled/>
                                                            <i class="ri-phone-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Relavant Experience</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="relevantExperience" disabled />
                                                            <i class="ri-calendar-todo-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Description</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <textarea name="" id="" cols="30" rows="4" disabled formControlName="description"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Status</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <select formControlName="status">
                                                                <option value="" disabled selected>--Select--</option>
                                                                <option value="Applicant">Applicant</option>
                                                                <option value="Shortlisted">Shortlisted</option>
                                                                <option value="Rejected">Rejected</option>
                                                                <option value="Interview">Interview</option>
                                                                <option value="Selected">Selected</option>
                                                                <option value="Approved">Approved</option>
                                                              </select>
                                                            <i class="ri-user-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.status==='Interview'" class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Rating</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="rating" />
                                                            <i class="ri-star-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.status==='Selected'" class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Rating</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="rating" disabled/>
                                                            <i class="ri-star-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="this.status==='Selected'" class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Offered Package</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="offeredPackage" />
                                                            <i class="ri-star-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-end align-items-center">
                                            <div class="col-sm-12 text-end px-3 py-1">
                                                <div class="d-flex justify-content-end gap-2">
                                                    <button class="btn-secondary btn" (click)="backToApplication()">Cancel</button>
                                                    <button type="button" class="btn" (click)="updateJobApplication()" [disabled]="!jobApplicantDetailsForm.valid">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let item of attachments">
                                            <table style="border: 1px dotted blue; border-radius: 10px">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span>{{ item }}</span>
                                                        </td>
                                                        <td>
                                                            <div class="delete">
                                                                <button type="button" (click)="downloadJobApplicationAttachments(item)">
                                                                    <span><i class="ri-download-cloud-fill"></i></span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>