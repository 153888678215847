import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class HolidaycalenderService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) { 
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }

   // Create holidays
   createHolidays(addcclrdata: Object): Observable<Object> {
    console.log("In createitAssets method, holidays: ", addcclrdata);
    return this.http.post(`${environment.apiUrl}/holidays`, addcclrdata).pipe(catchError(this.handleError));
  }

   // Get holidays by Id Get
   getHolidays(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/holidays/${id}`).pipe(catchError(this.handleError));
   }

    //Delete holidays by Id Delete
    deleteHolidays(id: number): Observable<Object> {
      return this.http.delete(`${environment.apiUrl}/holidays/${id}`).pipe(catchError(this.handleError));
     }

     //Update holidays by Id Put
    updateHolidays(user: Object,id: number): Observable<Object> {
      console.log("In createUser method, user: ", user);
      return this.http.put(`${environment.apiUrl}/holidays/${id}`, user).pipe(catchError(this.handleError));
    }

     //Get All holidays by Id Get
     getAllHolidays(): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/holidays`).pipe(catchError(this.handleError));
    }

    //Get All holidays by Id Get
    getAllHolidaysByYear(year: number): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/holidays?year=${year}`).pipe(catchError(this.handleError));
    }
   
}
