import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { DataService } from 'src/app/_services/data-share/data.service';
import { QuoteService } from 'src/app/_services/quote/quote.service';
import { LocalStorageService } from "../../../../_services/localStorage/local-storage.service";
import { Router } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CostService } from 'src/app/_services/cost/cost.service';
import { AccountsService } from 'src/app/_services/accounts/accounts.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ProjectService } from 'src/app/_services/project/project.service';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss']
})
export class QuotesComponent implements OnInit {
  jsonData: any;
  quotesForm: FormGroup;
  tenantId: string;
  selectedRow: any;
  public data: object[];
  accountDropdownList = [];
  userDropdownList = [];
  costDropdownList = [];
  projectDropdownList = [];
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  
  
  constructor(
    private contactService: CostService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private quoteService: QuoteService,
    private storageService: LocalStorageService, 
    private router: Router, 
    private modalService: NgbModal,
    private accountsService: AccountsService,
    private allocationService: AllocationService,
    private costService: CostService,
    private projectService: ProjectService,
    ) {

    this.quotesForm = this.formBuilder.group({
      id: new FormControl(''),
      account: new FormControl(''),
      project: new FormControl(''),
      projectNumber: new FormControl(''),
      projectManager: new FormControl(''),
      responsibleUnit: new FormControl(''),
      supportUnit: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      keyAccountManager: new FormControl(''),
      quoteName: new FormControl('')
    })
  } 

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getQuotes();
    this.getAccountist();
    this.getUserList();
    this.getCostCenterLevelRates();
    this.getprojectList();
    // this.loadData();
    // setTimeout(() => {
    //   this.loadDatatable();
    // }, 1000);
  }

  getQuotes() {
    this.quoteService.getQuoteDetails(this.opportunityObject.id).subscribe((response: any) => {
      this.jsonData = response;
      console.log("response: ", response);
    });
  }

  getAccountist() {
    this.accountDropdownList = []
    this.accountsService.getAllAccounts().subscribe((res:any) => {
      for (let item of res.result) {
        this.accountDropdownList.push(item.accountName)
      }
    })
  }

  getUserList() {
    this.userDropdownList = []
    this.allocationService.getUserDetails().subscribe(response => {
      for (let item of response) {
        this.userDropdownList.push(`${item.firstName} ${item.lastName}`)
      }
    })
  }

  getCostCenterLevelRates() {
    this.costDropdownList = []
    this.costService.getDefaultCostCenterLevelRates().subscribe((response:any) => {
      for (let item of response) {
        this.costDropdownList.push(item.costcenter)
      }
    });
  }
  // loadDatatable() {
  //   $('#datatable').DataTable({
  //     processing: true
  //   });
  // }

  createQuote() {
    let details = JSON.stringify(this.quotesForm.value);
    this.quoteService.createQuote(details, this.opportunityObject.id).subscribe((response: any) => {
      console.log("Quote created!, response: ", response);
      if (response) {
        this.getQuotes()
        $('#closeModalQuote').trigger("click");
      }

      this.contactService.getDefaultSoftwareRates().subscribe(defaultSr => {
        if (defaultSr.length > 0) {
          for (let item of defaultSr) {
            this.contactService.addSoftwareRatesOpportunity(item, this.opportunityObject.id, response.id).subscribe(responseDefaultSr => {})
          }
        }
      })

      this.contactService.getDefaultCostCenterLevelRates().subscribe(defaultCclr => {
        if (defaultCclr.length > 0) {
          for (let item of defaultCclr) {
            this.contactService.addCostCenterLevelRatesOpportunity(item, this.opportunityObject.id, response.id).subscribe(responseDefaultCclr => {})
          }
        }
      })
      
    });

    // datatable reloading
    // setTimeout(() => {
    //   this.getQuotes();
    //   var datatable = $('#datatable').DataTable();
    //   datatable.destroy();
    //   setTimeout(() => {
    //     this.loadDatatable();
    //   }, 1000);
    // }, 1000);
  }

  rowData(item) {
    this.dataService.updatedQuote(item);
    localStorage.setItem("quoteObject", JSON.stringify(item))
    this.router.navigate([this.tenantId + '/dashboard/opportunities/quote-details']);
    // this.router.navigate(['quote-details'], { relativeTo: this.route });
  }

  deleteQuote(id) {
    this.quoteService.deleteQuote(this.opportunityObject.id, id).subscribe(response => {
        this.getQuotes()
        $('#closeModalDeleteQuote').trigger("click");
    });

    // datatable reloading
    // setTimeout(() => {
    //   this.getQuotes();
    //   var datatable = $('#datatable').DataTable();
    //   datatable.destroy();
    //   setTimeout(() => {
    //     this.loadDatatable();
    //   }, 1000);
    // }, 1000);
  }

  loadData() {
    this.quoteService.getQuoteDetails(this.opportunityObject.id).subscribe((response: any) => {
      this.data = response;
    });
  }
  createOpenModal() {
     this.quotesForm.reset();
  }


  open(item) {
    this.selectedRow = item;
  }

  getprojectList() {
    this.projectDropdownList = []
    this.projectService.getProjects().subscribe((res:any) => {
      for (let item of res) {
        this.projectDropdownList.push(`${item.id}. ${item.projectName}`)
      }
    })
  }
}
