import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/_services/user/user.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ExitProcessService } from 'src/app/_services/exit-process/exit-prosess.service';


@Component({
  selector: 'app-exit-hrit',
  templateUrl: './exit-hrit.component.html',
  styleUrls: ['./exit-hrit.component.scss']
})
export class ExitHritComponent implements OnInit {
  @Output() clearanceEvent = new EventEmitter<any>();
  tenantId: string;
  userDropdownList = [];
  resignationId: any;
  isHRAdmin: boolean;
  isFinanceAdmin: boolean;
  isITAdmin: boolean;
  currentUser: any;
  userClearanceForm;
  form: FormGroup;
  clearanceUserId: any;
  editUserClearanceId: any;
  userClearanceData: any[] = [];
  isClearanceForm: boolean = false;
  modeAction: number = 1; //1 - create // 2 - update
  formMode: number = 0; // 0 - none // 1 - new form // 2 - edit form
  defaultColDef:any;
  rowSelection:any
  dataSource: any;
  public columnDefs: ColDef[];
  sampleGridFrameworkComponents: any;
  private api: GridApi;
  private columnApi: ColumnApi;
  selectedRow: any;
  rowData: any;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private exitProcessService: ExitProcessService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.resignationId = params['resignationId'];
    });

    this.userClearanceForm = this.formBuilder.group({
      itemName: new FormControl(''),
      status: new FormControl(''),
      clearanceType: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isHRAdmin = this.currentUser.roles.includes('RoleHrmAdministrator');
    this.isFinanceAdmin = this.currentUser.roles.includes('RoleFinanceAdministrator');
    this.isITAdmin = this.currentUser.roles.includes('RoleITMAdministrator');
    this.getUserSeparation(this.resignationId);
  }

  getUserSeparation(resignationId){
    this.exitProcessService.getUserSeparationsById(resignationId).subscribe({
      next : (value) => {
        this.clearanceUserId = value['userId'];
        this.getClearanceInterviewForm();
      },
      error(err) {
        this.openSnackBar('ERROR: Getting Resignation Details' + err);
      },
      complete() {
        // this.getUserClearance();
      }
    });
  }

  getClearanceInterviewForm(){
    this.exitProcessService.getClearanceInterviewForm('ITAndHR', this.clearanceUserId).subscribe({
      next : (value: any) => {
        this.userClearanceData = [];
        this.userClearanceData = value;
        this.clearanceEvent.emit(value);
      },
      error(err) {
        this.openSnackBar('ERROR: Getting Clearance Details' + err);
      },
      complete() {
        // this.getUserClearance();
      }
    });
  }

  cancelSave(){
    this.formMode = 0;
    this.isClearanceForm = false;
  }

  requiredSaveUserClearance() {
    if (this.userClearanceForm.status === 'INVALID') return true;
    else return false;
  }

  updateFinanceUserClearance(){
    let data = {
      userId: this.clearanceUserId,
      itemName: this.userClearanceForm.get('itemName').value,
      status: this.userClearanceForm.get('status').value,
      clearanceType: 'ITAndHR',
    }
    if (!this.requiredSaveUserClearance()) {
      this.exitProcessService.editClearanceInterviewForm(data, this.editUserClearanceId).subscribe({
        next: (value) =>{
          this.getClearanceInterviewForm();
        },
        error(error){
          this.openSnackBar('ERROR: ' + error);
        },
        complete(){
          this.openSnackBar('Update Successfull!!!');
        }
      });
    }
  }

  editUserClearanceById(id) {
    this.formMode = 2;
    this.isClearanceForm = true;
    const idx = this.userClearanceData.findIndex(i => i.id === id);
    this.editUserClearanceId = id;
    this.userClearanceForm.patchValue({
      itemName: this.userClearanceData[idx].itemName,
      status: this.userClearanceData[idx].status,
    });
  }

  backToExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-process']);
  }

  openExitForm(){
    this.router.navigate([this.tenantId + '/dashboard/hr/exit-formalities/exit-form'], { queryParams: { resignationId: this.resignationId } });
  }

  openFinanceExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-finance' ], { queryParams: { resignationId: this.resignationId } });
  }

}
