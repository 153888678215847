import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { UserService } from 'src/app/_services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AvatarService } from 'src/app/_services/avatar/avatar.service';
import { AvatarComponent } from '../../avatar/avatar.component';
import { AttendanceService } from 'src/app/_services/attendance/attendance.service';
import { convertYYYYMMDDtoDDMMYYYY } from 'src/app/_helpers/utils';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import * as moment from 'moment';
import { Roles } from '../../../../_models/roles';
import { CompanyStructureService } from 'src/app/_services/company-structure/company-structure.service';
import { flatMap } from 'lodash';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {
  @Output() workExperienceEvent = new EventEmitter<any>();
  @Output() skillEvent = new EventEmitter<any>();
  retrievedImage: any;
  image: any;
  currentUser: any;
  tenantId: string;
  profileForm: FormGroup;
  workExperienceForm: FormGroup;
  skillsForm: FormGroup;
  userDependentsForm: FormGroup;
  employeeForm: FormGroup;
  emergencyContactsForm: FormGroup;
  selectedFile: File;
  profileData: any;
  timeSheetData: any;
  userDropdownList = [];
  getUserName: any;
  marriagedate: any;
  public superVisor: any;
  public reportees: Array<any> = [];
  rolesArray: Array<any> = [];
  keys = Object.keys;
  rolesEnum = Roles;
  workExperienceData: any[] = [];
  isWorkExperienceForm: boolean = false;
  disableEndDate:boolean = false;
  isSkillsForm: boolean = false;
  isEmergencyContactsForm: boolean = false;
  isUserDependentsForm: boolean = false;
  editSkillId: string;
  editWorkExperienceId: string;
  editUserDependentsId: string;
  editEmergencyContactsId: string;
  skillsData: any[] = [];
  emergencyContacts= [];
  emergencyContactsData: any[] = [];
  userDependentsData: any[] = [];
  userDependents= [];
  hrName: any;
  hrImage: any;
  hrDesignation: any;
  modeAction: number = 1; //1 - create // 2 - update
  formMode: number = 0; // 0 - none // 1 - new form // 2 - edit form
  isManager: boolean;
  isAdmin : boolean;
  rowData: any;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private storageService: LocalStorageService,
    private attendanceService: AttendanceService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private avatar: AvatarService,
    private allocationService: AllocationService,
    private companyStructureService: CompanyStructureService,
  ) {
    this.profileForm = this.formBuilder.group({
      id: new FormControl(''),
      version: new FormControl(''),
      userId: new FormControl(''),
      password: new FormControl(''),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      displayName: new FormControl(''),
      department: new FormControl(''),
      emailId: new FormControl('', Validators.required),
      phoneNo: new FormControl('', Validators.required),
      location: new FormControl('', Validators.required),
      roles: new FormControl(''),
      image: new FormControl(''),
      branch: new FormControl(''),
      groups: new FormControl(''),
      costCenter: new FormControl(''),
      localId: new FormControl(''),
      employeeCategory: new FormControl('', Validators.required),
      level: new FormControl(''),
      joinDate: new FormControl(''),
      quitDate: new FormControl(''),
      permanentOrContractor: new FormControl(''),
      localOrExpat: new FormControl(''),
      level2: new FormControl(''),
      resourceType: new FormControl(''),
      onboardStatus: new FormControl(''),
      supervisorId: new FormControl(''),
      dashboardIndex: new FormControl(''),
      birthDate: new FormControl('', Validators.required),
      hrId: new FormControl(''),
      hrName: new FormControl(''),
      supervisonName: new FormControl(''),
      adhaarNo: new FormControl('', Validators.required),
      bankName: new FormControl('', Validators.required),
      bankAccountNo: new FormControl('', Validators.required),
      panNo: new FormControl('', Validators.required),
      uinNo: new FormControl('', Validators.required),
      archived: new FormControl(''),
      admin: new FormControl(''),
      joinWeek: new FormControl(''),
      quitWeek: new FormControl(''),
      marriageDate: new FormControl(''),
      marriageStatus: new FormControl(''),
      userType: new FormControl(''),
      alternateEmailId: new FormControl(''),
      country: new FormControl('', Validators.required),
      currentDesignation: new FormControl(''),
      previousDesignation: new FormControl('', Validators.required),
      offeredDesignation: new FormControl(''),
      deputedAt: new FormControl(''),
      bankIfscCode: new FormControl('', Validators.required),
      address: new FormControl(''),
      gender: new FormControl('', Validators.required),
      careerStartDate: new FormControl(''),
      middleName: new FormControl(''),
      managerId: new FormControl(''),
      managerName: new FormControl(''),
      addedToBioMetrics: new FormControl(''),
      totalExperience: new FormControl(''),
      currentExperience: new FormControl(''),
      emergencyContactNo: new FormControl(''),
      bloodGroup: new FormControl('', Validators.required),
    });
    this.workExperienceForm = this.formBuilder.group({
      userId: new FormControl(''),
      companyName: new FormControl(''),
      jobTitle: new FormControl(''),
      stardDate: new FormControl(''),
      endDate: new FormControl(''),
      jobDescription: new FormControl(''),
      relevant: new FormControl(''),
      experience: new FormControl(''),
    });

    this.skillsForm = this.formBuilder.group({
      id: new FormControl(''),
      userId: new FormControl(''),
      skillName: new FormControl(''),
      skillExpertise: new FormControl(''),
      skillExperience: new FormControl(''),
    });

    this.userDependentsForm = this.formBuilder.group({
      id: new FormControl(''),
      userId: new FormControl(''),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      gender: new FormControl(''),
      nationalID: new FormControl(''),
      passport: new FormControl(''),
      residesOutofCountry: new FormControl(''),
      dob: new FormControl(''),
    });

    this.emergencyContactsForm = this.formBuilder.group({
      id: new FormControl(''),
      userId: new FormControl(''),
      personName: new FormControl(''),
      relationWithUser: new FormControl(''),
      phoneNumber: new FormControl(''),
      dependent: new FormControl(''),
      email: new FormControl(''),
    });

    this.employeeForm = this.formBuilder.group({
      id: new FormControl(''),
      employeeId: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.isAdmin = this.currentUser.admin ||this.currentUser.roles.includes('RoleHrmAdministrator');
    this.getProfileData(this.currentUser.id);
    //this.getImage();
    this.getHRData();
    this.getAttendanceSummary(this.currentUser.id);
    this.getCompanyStructure(this.currentUser.id);  
    this.getWorkExperience(this.currentUser.id);
    this.getUserSkill(this.currentUser.id);
    this.getEmergencyContacts(this.currentUser.id);
    this.getUserDependents(this.currentUser.id);
    this.getUserList()
  }

  // Pop-up message
  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }

  getProfileData(id: number) {
    this.userService.getUser(id).subscribe((response: any) => {
      this.profileData = response;
      this.profileForm.patchValue(response);
      this.getImage();
    });
  }

  get f() {
    return this.profileForm.controls;
  }

  getUserList() {
    this.userDropdownList = [];
    if(this.isAdmin){
      this.allocationService.getUserDetails().subscribe((response) => {
        this.getUserName = response;
        for(let item of response) {
          this.userDropdownList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      });
    }
    else {
      this.allocationService.getReportees().subscribe((response) => {
        this.getUserName = response;
        for(let item of response) {
          this.userDropdownList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
        if(this.userDropdownList.length > 0){
          this.isManager = true;
          console.log("Manager: ",this.isManager);
        }
      });
    }
  }

  getEmployeeProfile(){
    let employeeId = this.employeeForm.get('employeeId').value.split(' . ')[0];
    this.getProfileData(employeeId);
    this.getAttendanceSummary(employeeId);
    this.getCompanyStructure(employeeId);  
    this.getWorkExperience(employeeId);
    this.getUserSkill(employeeId);
    this.getEmergencyContacts(employeeId);
    this.getUserDependents(employeeId);
  }

  openEdit(item) {
    this.checkmarritalstatus(this.profileData.marriageStatus);
    this.profileForm.patchValue(item);
    this.getImage();
  }

  checkmarritalstatus(marritalstatvalue) {
    this.marriagedate = marritalstatvalue;
  }

  updateProfileData() {
    if(this.currentUser.roles.length > 0){
      let profileData = this.profileForm.value;
      this.userService
        .updateUser(this.currentUser.id, profileData)
        .subscribe((response: any) => {
          this.getProfileData(this.currentUser.id);
          $('#profileUpdate').trigger('click');
        });
    }
    else{
      let profileData = {
        id : this.profileData.id,
        version: this.profileData.version,
        userId: this.profileData.userId,
        password: this.profileData.password,
        displayName: this.profileData.displayName,
        department: this.profileData.department,
        image: this.profileData.image,
        branch: this.profileData.branch,
        groups: this.profileData.groups,
        costCenter: this.profileData.costCenter,
        localId: this.profileData.localId,
        level: this.profileData.level,
        joinDate: this.profileData.joinDate,
        quitDate: this.profileData.quitDate,
        permanentOrContractor: this.profileData.permanentOrContractor,
        localOrExpat: this.profileData.localOrExpat,
        level2: this.profileData.level2,
        resourceType: this.profileData.resourceType,
        onboardStatus: this.profileData.onboardStatus,
        supervisorId: this.profileData.supervisorId,
        dashboardIndex: this.profileData.dashboardIndex,
        hrId: this.profileData.hrId,
        hrName: this.profileData.hrName,
        supervisonName: this.profileData.supervisonName,
        archived: this.profileData.archived,
        admin: this.profileData.admin,
        joinWeek: this.profileData.joinWeek,
        quitWeek: this.profileData.quitWeek,
        marriageDate: this.profileData.marriageDate,
        marriageStatus: this.profileData.marriageStatus,
        userType: this.profileData.userType,
        alternateEmailId: this.profileData.alternateEmailId,
        offeredDesignation: this.profileData.offeredDesignation,
        deputedAt: this.profileData.deputedAt,
        address: this.profileData.address,
        careerStartDate: this.profileData.careerStartDate,
        middleName: this.profileData.middleName,
        managerId: this.profileData.managerId,
        managerName: this.profileData.managerName,
        addedToBioMetrics: this.profileData.addedToBioMetrics,
        totalExperience: this.profileData.totalExperience,
        currentExperience: this.profileData.currentExperience,
        emergencyContactNo: this.profileData.emergencyContactNo,

        firstName: this.profileForm.get('firstName').value,
        lastName: this.profileForm.get('lastName').value,
        emailId: this.profileForm.get('emailId').value,
        phoneNo: this.profileForm.get('phoneNo').value,
        location: this.profileForm.get('location').value,
        employeeCategory: this.profileForm.get('employeeCategory').value,
        birthDate: this.profileForm.get('birthDate').value,
        adhaarNo: this.profileForm.get('adhaarNo').value,
        bankName: this.profileForm.get('bankName').value,
        bankAccountNo: this.profileForm.get('bankAccountNo').value,
        panNo: this.profileForm.get('panNo').value,
        uinNo: this.profileForm.get('uinNo').value,
        country: this.profileForm.get('country').value,
        previousDesignation: this.profileForm.get('previousDesignation').value,
        bankIfscCode: this.profileForm.get('bankIfscCode').value,
        gender: this.profileForm.get('gender').value,
        bloodGroup: this.profileForm.get('bloodGroup').value,
        roles: ["RoleITUser", "RoleHrmUser", "RoleFinanceUser"]
      }
      
      console.log("Add Roles", profileData);
      this.userService
        .updateUser(this.currentUser.id, profileData)
        .subscribe((response: any) => {
          this.getProfileData(this.currentUser.id);
          $('#profileUpdate').trigger('click');
          window.alert("Please re-login to access the application.");
        });
    }

  }

  editOpenModal() {
    this.modeAction = 2;
    this.getUserList();
    let keys = Object.keys(this.rolesEnum);
    let roleArray = Object.values(this.rolesEnum);
    for (let index = 0; index < roleArray.length; index++) {
      this.rolesArray.push({
        lable: roleArray[index],
        value: keys[index],
      });
    }
    this.profileForm.patchValue(this.profileData);
  }

  getAttendanceSummary(id: number) {
    // let employeeId = this.currentUser.id;
    let startDate = moment().format('DD-MM-yyyy');
    let endDate = moment().format('DD-MM-yyyy');
    this.attendanceService
      .getAttendanceSummary(id, startDate, endDate)
      .subscribe((response: any) => {
        this.timeSheetData = response.result[0];
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(AvatarComponent);
    dialogRef.afterClosed().subscribe((result) => {
      this.getImage();
    });
  }

  getImage() {
    //this.image = JSON.parse(this.storageService.get('userImage'));
    //this.image = JSON.parse(this.profileData.image);
    this.image = this.profileData.image;
    console.log("image", this.profileData);
    if (this.image !== null) {
      this.retrievedImage = 'data:image/jpeg;base64,' + this.image;
    } else {
      this.retrievedImage = './assets/user-icon-jpg-29.jpg';
    }
  }

  passwordReset() {
    this.router.navigate([this.tenantId + '/dashboard/reset-password']);
  }

  updateProfile() {
    let details = JSON.stringify(this.profileForm.value);
    this.userService
      .updateUser(this.currentUser.id, details)
      .subscribe((response: any) => {
        this.getProfileData(this.currentUser.id);
        this.getImageAfterUpdate();
        this.openSnackBar('Profile Updated!');
      });
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    if (event.target.files[0].size < 2000000){
      this.onUpload();
    }
    else{
      window.alert('The image size should be max upto 2MB');
    }
  }

  //Gets called when the user clicks on submit to upload the image
  onUpload() {
    // get logged in user's details
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));

    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();

    uploadImageData.append(
      'imageFile',
      this.selectedFile,
      this.selectedFile.name
    );

    //Make a call to the Spring Boot Application to save the image
    this.avatar
      .uploadImage(this.currentUser.id, uploadImageData)
      .subscribe((response: any) => {
        if (response) {
          // this.message = 'Image uploaded successfully';
          this.openSnackBar('Image uploaded successfully');
        } else {
          // this.message = 'Image not uploaded successfully';
          this.openSnackBar('Image not uploaded');
        }
      });

    setTimeout(() => {
      this.getImageAfterUpdate();
    }, 400);
  }

  //Gets called when the user clicks on retieve image button to get the image from back end
  getImageAfterUpdate() {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));

    this.userService.getUser(this.currentUser.id).subscribe((response: any) => {
      this.retrievedImage = 'data:image/jpeg;base64,' + response.image;
      this.storageService.set('userImage', JSON.stringify(response.image));
    });
  }

  backtoAttendance() {
    this.router.navigate([this.tenantId + '/dashboard/hr/attendance']);
  }

  visibleForm() {
    this.disableEndDate = false;
    if (this.formMode === 0) {
      this.isWorkExperienceForm = true;
      this.formMode = 1;
      this.workExperienceForm.patchValue({
          userId: '',
          companyName: '',
          jobTitle: '',
          stardDate: '',
          endDate: '',
          jobDescription: '',
          relevant: '',
          experience: '',
          emergencyContactNo: '',
      })
    } else if (this.formMode === 1) {
      this.isWorkExperienceForm = false;
      this.formMode = 0;
    }
  }

  requiredSave() {
    if(this.disableEndDate === true){
      return false
    }
    else{
      if (this.workExperienceForm.status === 'INVALID') {
        //console.log("this.workExperienceForm.status", this.workExperienceForm.status);
        //console.log("endDate", this.workExperienceForm.get('endDate').errors);
        //console.log("endDate", this.workExperienceForm.get('endDate').valid);
        return true;
      }
      else return false;
    }
  }

  saveExperience() {
    let data: object;
    if(this.disableEndDate === true){
      data = {
        userId: this.currentUser.id,
        companyName: this.workExperienceForm.get('companyName').value,
        jobTitle: this.workExperienceForm.get('jobTitle').value,
        stardDate: this.workExperienceForm.get('stardDate').value,
        endDate: null,
        jobDescription: this.workExperienceForm.get('jobDescription').value,
        relevant: this.workExperienceForm.get('relevant').value,
        experience: this.workExperienceForm.get('experience').value
      }
    } else {
      data = {
        userId: this.currentUser.id,
        companyName: this.workExperienceForm.get('companyName').value,
        jobTitle: this.workExperienceForm.get('jobTitle').value,
        stardDate: this.workExperienceForm.get('stardDate').value,
        endDate: this.workExperienceForm.get('endDate').value,
        jobDescription: this.workExperienceForm.get('jobDescription').value,
        relevant: this.workExperienceForm.get('relevant').value,
        experience: this.workExperienceForm.get('experience').value
      }
    }
    
    if (!this.requiredSave()) {
      this.userService.addWorkExperience(data).subscribe(response => {
        this.getWorkExperience(this.currentUser.id);
        this.formMode = 0;
        this.isWorkExperienceForm = false;
      },
      error => {
        //console.log('ERROR - cannot post add work Experience: ' + error)
      });
    }
  }

  getWorkExperience(id: number){
    this.userService.getWorkExperience(id).subscribe((response : any) => {
      this.workExperienceData = [];
      this.workExperienceData = response;
      this.workExperienceEvent.emit(response)
    },
    error => {
      //console.log('ERROR - cannot add work Experience: ' + error)
    });
  }

  cancelSave(){
    this.formMode = 0;
    this.isWorkExperienceForm = false;
    this.isSkillsForm = false;
    this.isEmergencyContactsForm = false;
    this.isUserDependentsForm = false;
  }

  updateWorkExperience(){
    let data: object;
    if(this.disableEndDate === true){
      data = {
        userId: this.currentUser.id,
        companyName: this.workExperienceForm.get('companyName').value,
        jobTitle: this.workExperienceForm.get('jobTitle').value,
        stardDate: this.workExperienceForm.get('stardDate').value,
        endDate: null,
        jobDescription: this.workExperienceForm.get('jobDescription').value,
        relevant: this.workExperienceForm.get('relevant').value,
        experience: this.workExperienceForm.get('experience').value
      }
    } else {
      data = {
        userId: this.currentUser.id,
        companyName: this.workExperienceForm.get('companyName').value,
        jobTitle: this.workExperienceForm.get('jobTitle').value,
        stardDate: this.workExperienceForm.get('stardDate').value,
        endDate: this.workExperienceForm.get('endDate').value,
        jobDescription: this.workExperienceForm.get('jobDescription').value,
        relevant: this.workExperienceForm.get('relevant').value,
        experience: this.workExperienceForm.get('experience').value
      }
    }

    if (!this.requiredSave()) {
      this.userService.updateWorkExperience(data, this.editWorkExperienceId).subscribe(response => {
        this.getWorkExperience(this.currentUser.id);
        this.formMode = 0;
        this.isWorkExperienceForm = false;
      },
      error => {
        //console.log('ERROR - cannot edit work Experience: ' + error)
      });
    }
  }

  editWorkExperienceById(id: string) {
    this.formMode = 2;
    this.isWorkExperienceForm = true;
    const idx = this.workExperienceData.findIndex(i => i.id === id);
    if(this.workExperienceData[idx].endDate){
      this.disableEndDate = false;
    } else {
      this.disableEndDate = true;
    }
    this.editWorkExperienceId = id;
    this.workExperienceForm.patchValue({
      companyName: this.workExperienceData[idx].companyName,
      jobTitle: this.workExperienceData[idx].jobTitle,
      stardDate: this.workExperienceData[idx].stardDate,
      endDate: this.workExperienceData[idx].endDate,
      jobDescription: this.workExperienceData[idx].jobDescription,
      relevant: this.workExperienceData[idx].relevant,
      experience:this.workExperienceData[idx].experience,
    });
  }

  deleteWorkExperienceById(id) {
    this.userService.deleteWorkExperience(id).subscribe(response => {
      this.getWorkExperience(this.currentUser.id);
    },
    error => {
      //console.log('ERROR - cannot delete work Experience: ' + error)
    });
  }

  currentJob(event){
    if(this.disableEndDate === true){
      this.disableEndDate = false;
    } else{
      this.disableEndDate = true;
    }
  }



  //Skills
  visibleSkillsForm() {
    if (this.formMode === 0) {
      this.isSkillsForm = true;
      this.formMode = 1;
      this.skillsForm.patchValue({
          userId: '',
          skillName: '',
          skillExpertise: '',
          skillExperience: ''
      })
    } else if (this.formMode === 1) {
      this.isSkillsForm = false;
      this.formMode = 0;
    }
  }

  saveSkill(){
    let data = { 
      userId: this.currentUser.id,
      skillName: this.skillsForm.get('skillName').value,
      skillExpertise: this.skillsForm.get('skillExpertise').value,
      skillExperience: this.skillsForm.get('skillExperience').value,
    }
    if (!this.requiredSaveSkill()) {
      this.userService.addUserSkill(data).subscribe(response => {
        this.getUserSkill(this.currentUser.id);
        this.formMode = 0;
        this.isSkillsForm = false;
      },
      error => {
        //console.log('ERROR - cannot add user Skill: ' + error)
      });
    }
  }

  updateSkill(){
    let data = { 
      userId: this.currentUser.id,
      skillName: this.skillsForm.get('skillName').value,
      skillExpertise: this.skillsForm.get('skillExpertise').value,
      skillExperience: this.skillsForm.get('skillExperience').value,
    }
    if (!this.requiredSaveSkill()) {
      this.userService.updateUserSkill(data, this.editSkillId).subscribe(response => {
        this.getUserSkill(this.currentUser.id);
        this.formMode = 0;
        this.isSkillsForm = false;
      },
      error => {
        //console.log('ERROR - cannot edit user Skill: ' + error)
      });
    }
  }

  requiredSaveSkill() {
    if (this.skillsForm.status === 'INVALID') return true;
    else return false;
  }

  editSkillById(id) {
    this.formMode = 2;
    this.isSkillsForm = true;
    const idx = this.skillsData.findIndex(i => i.id === id);
    this.editSkillId = id;
    this.skillsForm.patchValue({
      skillName: this.skillsData[idx].skillName,
      skillExpertise: this.skillsData[idx].skillExpertise,
      skillExperience: this.skillsData[idx].skillExperience,
    });
  }

  deleteSkillById(id) {
    this.userService.deleteUserSkill(id).subscribe(response => {
      this.getUserSkill(this.currentUser.id);
    },
    error => {
      //console.log('ERROR - cannot delete User Skill: ' + error)
    });
  }

  getUserSkill(id: number){
    this.userService.getUserSkill(id).subscribe((response : any) => {
      this.skillsData = [];
      this.skillsData = response;
      this.skillEvent.emit(response);
    },
    error => {
      //console.log('ERROR - cannot get user skills: ' + error)
    });
  }


  //Emergency Contacts
  getEmergencyContacts(id: number){
    this.userService.getEmergencyContacts(id).subscribe((response : any) => {
      this.emergencyContactsData = response;
      this.emergencyContacts = response;
    },
    error => {
      //console.log('ERROR - cannot get user emergencyContacts: ' + error)
    });
  }

  visibleEmergencyContactsForm() {
    if (this.formMode === 0) {
      this.isEmergencyContactsForm = true;
      this.formMode = 1;
      this.emergencyContactsForm.patchValue({
          userId: '',
          personName: '',
          relationWithUser: '',
          phoneNumber: '',
          dependent: '',
          email: ''
      })
    } else if (this.formMode === 1) {
      this.isEmergencyContactsForm = false;
      this.formMode = 0;
    }
  }

  saveEmergencyContact(){
    let data = { 
      userId: this.currentUser.id,
      personName: this.emergencyContactsForm.get('personName').value,
      relationWithUser: this.emergencyContactsForm.get('relationWithUser').value,
      phoneNumber: this.emergencyContactsForm.get('phoneNumber').value,
      dependent: this.emergencyContactsForm.get('dependent').value,
      email: this.emergencyContactsForm.get('email').value,
    }
    
    if (!this.requiredSaveEmergencyContact()) {
      this.userService.addEmergencyContacts(data).subscribe((response : any) => {
        this.getEmergencyContacts(this.currentUser.id);
        this.formMode = 0;
        this.isEmergencyContactsForm = false;
      },
      error => {
        //console.log('ERROR - cannot get user emergencyContacts: ' + error)
      });
    }
  }

  requiredSaveEmergencyContact(){
    if (this.emergencyContactsForm.status === 'INVALID') return true;
    else return false;
  }

  updateEmergencyContact(){
    let data = {
      id: this.editEmergencyContactsId,
      userId: this.currentUser.id,
      personName: this.emergencyContactsForm.get('personName').value,
      relationWithUser: this.emergencyContactsForm.get('relationWithUser').value,
      phoneNumber: this.emergencyContactsForm.get('phoneNumber').value,
      dependent: this.emergencyContactsForm.get('dependent').value,
      email: this.emergencyContactsForm.get('email').value,
    }
    
    if (!this.requiredSaveEmergencyContact()) {
      this.userService.updateEmergencyContacts(this.editEmergencyContactsId, data).subscribe((response : any) => {
        this.getEmergencyContacts(this.currentUser.id);
        this.formMode = 0;
        this.isEmergencyContactsForm = false;
      },
      error => {
        //console.log('ERROR - cannot get user emergencyContacts: ' + error)
      });
    }
  }

  editEmergencyContactById(id) {
    this.formMode = 2;
    this.isEmergencyContactsForm = true;
    const idx = this.emergencyContactsData.findIndex(i => i.id === id);
    this.editEmergencyContactsId = id;
    this.emergencyContactsForm.patchValue({
      personName: this.emergencyContactsData[idx].personName,
      relationWithUser: this.emergencyContactsData[idx].relationWithUser,
      phoneNumber: this.emergencyContactsData[idx].phoneNumber,
      dependent: this.emergencyContactsData[idx].dependent,
      email: this.emergencyContactsData[idx].email,
    });
  }


  deleteEmergencyContactById(id) {
    this.userService.deleteEmergencyContacts(id).subscribe(response => {
      this.getEmergencyContacts(this.currentUser.id);
    },
    error => {
     // console.log('ERROR - cannot delete Emergency info: ' + error)
    });
  }

  
  //User Dependents
  getUserDependents(id: number){
    this.userService.getUserDependents(id).subscribe((response : any) => {
      this.userDependentsData = response;
      this.userDependents = response;
    },
    error => {
      //console.log('ERROR - cannot get user Dependents: ' + error)
    });
  }

  visibleUserDependentsForm() {
    if (this.formMode === 0) {
      this.isUserDependentsForm = true;
      this.formMode = 1;
      this.userDependentsForm.patchValue({
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        gender: '',
        nationalID: '',
        passport: '',
        residesOutofCountry: '',
        dob: '',
      })
    } else if (this.formMode === 1) {
      this.isUserDependentsForm = false;
      this.formMode = 0;
    }
  }

  saveUserDependent(){
    let data = { 
      userId: this.currentUser.id,
      firstName: this.userDependentsForm.get('firstName').value,
      lastName: this.userDependentsForm.get('lastName').value,
      gender: this.userDependentsForm.get('gender').value,
      nationalID: this.userDependentsForm.get('nationalID').value,
      passport: this.userDependentsForm.get('passport').value,
      residesOutofCountry: this.userDependentsForm.get('residesOutofCountry').value,
      dob: this.userDependentsForm.get('dob').value,
    }
    
    if (!this.requiredSaveUserDependent()) {
      this.userService.addUserDependents(data).subscribe((response : any) => {
        this.getUserDependents(this.currentUser.id);
        this.formMode = 0;
        this.isUserDependentsForm = false;
      },
      error => {
        console.log('ERROR - cannot get User Dependents: ' + error)
      });
    }
  }

  requiredSaveUserDependent(){
    if (this.userDependentsForm.status === 'INVALID') return true;
    else return false;
  }

  updateUserDependent(){
    let data = {
      id: this.editUserDependentsId,
      userId: this.currentUser.id,
      firstName: this.userDependentsForm.get('firstName').value,
      lastName: this.userDependentsForm.get('lastName').value,
      gender: this.userDependentsForm.get('gender').value,
      nationalID: this.userDependentsForm.get('nationalID').value,
      passport: this.userDependentsForm.get('passport').value,
      residesOutofCountry: this.userDependentsForm.get('residesOutofCountry').value,
      dob: this.userDependentsForm.get('dob').value,
    }
    
    if (!this.requiredSaveUserDependent()) {
      this.userService.updateUserDependents(this.editUserDependentsId, data).subscribe((response : any) => {
        this.getUserDependents(this.currentUser.id);
        this.formMode = 0;
        this.isUserDependentsForm = false;
      },
      error => {
        //console.log('ERROR - cannot get User Dependents: ' + error)
      });
    }
  }

  editUserDependentById(id) {
    this.formMode = 2;
    this.isUserDependentsForm = true;
    const idx = this.userDependentsData.findIndex(i => i.id === id);
    this.editUserDependentsId = id;
    this.userDependentsForm.patchValue({
      firstName: this.userDependentsData[idx].firstName,
      lastName: this.userDependentsData[idx].lastName,
      gender: this.userDependentsData[idx].gender,
      nationalID: this.userDependentsData[idx].nationalID,
      passport: this.userDependentsData[idx].passport,
      residesOutofCountry: this.userDependentsData[idx].residesOutofCountry,
      dob: this.userDependentsData[idx].dob,
    });
  }


  deleteUserDependentById(id) {
    this.userService.deleteUserDependents(id).subscribe(response => {
      this.getUserDependents(this.currentUser.id);
    },
    error => {
      //console.log('ERROR - cannot delete User Dependents: ' + error)
    });
  }

  /**
   * get company structure
   */
  getCompanyStructure(id: number) {
    this.companyStructureService.getCompanyStructure(id).subscribe({
      next: (response) => {
        if(response) {
          this.superVisor = response['supervisor'] ? response['supervisor'] : {};
          this.reportees = response['reportees'] ? response['reportees'] : [];
        }
        // this.userData = respose.
      },
      error: () => {

      },
      complete: () => {

      }
    }) 
  }

  getHRData(){
    this.hrName = this.currentUser.hrName;
    let id = this.currentUser.hrId;
    this.userService.getUser(id).subscribe((response: any) => {
      if (response.image != null) {
        this.hrImage = 'data:image/jpeg;base64,' + response.image;
      } else {
        this.hrImage = './assets/user-icon-jpg-29.jpg';
      }
      this.hrDesignation = response.currentDesignation;
    });
  }

}