import { Component, ViewEncapsulation, OnInit, ViewChild, OnDestroy, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  View, EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, ResizeService,
  DragAndDropService, NavigatingEventArgs, ActionEventArgs, ScheduleComponent, AgendaService
} from '@syncfusion/ej2-angular-schedule';

import { CalenderService } from 'src/app/_services/calender/calender.service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AttendanceService } from 'src/app/_services/attendance/attendance.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { extend } from '@syncfusion/ej2-base';
import { UserService } from 'src/app/_services/user/user.service';
import { HolidaycalenderService } from 'src/app/_services/holidaycalender/holidaycalender.service';


@Component({
  selector: 'app-attendance-detail',
  templateUrl: './attendance-detail.component.html',
  styleUrls: ['./attendance-detail.component.scss'],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService],
  encapsulation: ViewEncapsulation.None
})
export class AttendanceDetailComponent implements OnInit {
  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent;
  public selectedDate: Date = new Date();

  public jsonData;
  public eventSettings: EventSettingsModel = {
     dataSource: null ,
     allowAdding: false,
      allowEditing: false,
      allowDeleting: false,
    };
  public currentView: View = 'Month';
  public isHubConnected = false;
  public data: Record<string, any>[];
  public showWeekNumber: boolean = true;
  public isReadOnly: boolean = true;
  tenantId: any;
  AttendanceImage: string;
  image: any;
  attendanceform: FormGroup;
  form: FormGroup;
  Name: string;
  public userData: any;
  public connection: HubConnection;
  currentUser: any;
  rowData: any;
  public salaryMetadata: any;
  public displayName: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: LocalStorageService,
    private attendanceService: AttendanceService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private holidaycalenderService: HolidaycalenderService
  ) {
    this.attendanceform = this.formBuilder.group({
      id: new FormControl(''),
      name: new FormControl(''),
      address: new FormControl(''),
      longitude: new FormControl(''),
      latitude: new FormControl(''),
      inTime: new FormControl(''),
      outTime: new FormControl(''),
      punchRadius: new FormControl(''),
      employeeId: new FormControl(''),
      officeId: new FormControl(''),
      attendanceDate: new FormControl(''),
      status: new FormControl(''),
      markingTime: new FormControl(''),
      employeeName: new FormControl(''),
      workTimeInHours: new FormControl(''),
      shift: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.getAllHolidays();
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getUserById();
    this.getImage();
    this.getSalarymetadata();
    this.handleCalenderData();
  }

  getAllHolidays() {
    this.holidaycalenderService.getAllHolidays().subscribe((response:any) => {
      this.jsonData = response.result;
      console.log(this.jsonData, "jsonData")
    });
  }

  async handleCalenderData() {
    let data: Array<any> = [];
    let responseResults: Array<any> = [];
    let employeeId = this.currentUser.id;
    await this.attendanceService.getAttendanceById(employeeId).subscribe({
      next: (response: any) => {
        if (response.result && response.result.length > 0) {
           responseResults = response.result;
           this.setCalendarData(response.result)
        }
      },
      error: (err) => {

      },
      complete: () => {
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

const d = new Date();


        for (let index = 0; index < responseResults.length; index++) {
          let date = responseResults[index].attendanceDate.split('-');
          let inTime = responseResults[index].inTime && responseResults[index].inTime != "" ? responseResults[index].inTime.split(':') : "";
          let outTime = responseResults[index].outTime && responseResults[index].outTime != "" ? responseResults[index].outTime.split(':') : "";
          data.push({
            Id: index + 1,
            Subject: responseResults[index].workTimeInHours,
            StartTime: new Date(date[0], date[1] - 1, date[2], inTime[0], inTime[1]),
            EndTime: new Date(date[0], date[1] - 1, date[2], outTime[0], outTime[1]),
            inTime: responseResults[index].inTime,
            outTime: responseResults[index].outTime,
            day: this.getDays(responseResults[index].attendanceDate),
            attendanceDate: responseResults[index].attendanceDate,
            name: ""
          })

        }
        for (let index = 0; index < this.jsonData.length; index++) {
          let element = this.jsonData[index];
          let date = this.jsonData[index].holidayDate.split('-');
          data.push({
            Id: index + 1,
            Subject: "",
            StartTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
            EndTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
            inTime: "",
            outTime: "",
            day: this.getDays(element.holidayDate),
            attendanceDate: element.holidayDate,
            name: element.name
          })
        }
        console.log(responseResults,"responseResults")
        let dataNew: Object[] = <Object[]>extend([], data, null, true)
        this.eventSettings = {
          dataSource: dataNew
        };
      }
    })
  }

  setCalendarData(responseResults) {
        let data: Array<any> = [];
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];

const d = new Date();


        for (let index = 0; index < responseResults.length; index++) {
          let date = responseResults[index].attendanceDate.split('-');
          let inTime = responseResults[index].inTime && responseResults[index].inTime != "" ? responseResults[index].inTime.split(':') : "";
          let outTime = responseResults[index].outTime && responseResults[index].outTime != "" ? responseResults[index].outTime.split(':') : "";
          data.push({
            Id: index + 1,
            Subject: responseResults[index].workTimeInHours,
            StartTime: new Date(date[0], date[1] - 1, date[2], inTime[0], inTime[1]),
            EndTime: new Date(date[0], date[1] - 1, date[2], outTime[0], outTime[1]),
            inTime: responseResults[index].inTime,
            outTime: responseResults[index].outTime,
            day: this.getDays(responseResults[index].attendanceDate),
            attendanceDate: responseResults[index].attendanceDate,
            name: ""
          })

        }
        for (let index = 0; index < this.jsonData.length; index++) {
          let element = this.jsonData[index];
          let date = this.jsonData[index].holidayDate.split('-');
          data.push({
            Id: index + 1,
            Subject: "",
            StartTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
            EndTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
            inTime: "",
            outTime: "",
            day: this.getDays(element.holidayDate),
            attendanceDate: element.holidayDate,
            name: element.name
          })
        }
        console.log(responseResults,"responseResults")
        let dataNew: Object[] = <Object[]>extend([], data, null, true)
        this.eventSettings = {
          dataSource: dataNew
        };
      }
  

  handleIsHoliday(date: any) {
    if(this.jsonData.includes(date)) {
     return this.jsonData.findIndex(date);
    } else {
      return -1;
    }
  }

  onChangeMonth(event) {
    if(event.requestType === 'dateNavigate' && event.event) {
      this.handleCalenderDataforOtherMonth(event.event.currentTarget.innerText.split('\n')[0]);
      this.getSalarymetadataForOtherMonth(event.event.currentTarget.innerText.split('\n')[0]);
    }
  }
  
  async handleCalenderDataforOtherMonth(monthYear) {

    let start_date_Object: Date = new Date(monthYear);
    let end_date_Object = new Date(start_date_Object);
    end_date_Object.setMonth(end_date_Object.getMonth() + 1);
    
    let day = start_date_Object.getDate().toString().length === 1 ? ("0" + start_date_Object.getDate()) : start_date_Object.getDate() ;
    let month = (start_date_Object.getMonth() + 1).toString().length === 1 ? ("0" + (start_date_Object.getMonth() + 1)) : (start_date_Object.getMonth() + 1).toString();
    let yearMinus = start_date_Object.getFullYear(); 
    
    let start_date_String = day + "-" + month + "-" + yearMinus;

    day = end_date_Object.getDate().toString().length === 1 ? ("0" + end_date_Object.getDate()) : end_date_Object.getDate() ;
    month = (end_date_Object.getMonth() + 1).toString().length === 1 ? ("0" + (end_date_Object.getMonth() + 1)) : (end_date_Object.getMonth() + 1).toString();
    yearMinus = end_date_Object.getFullYear(); 

    let end_date_String = day + "-" + month + "-" + yearMinus;

    let data: Array<any> = [];
    let responseResults: Array<any> = [];
    let employeeId = this.currentUser.id;
    await this.attendanceService.getAttendanceForCalender(employeeId, start_date_String, end_date_String).subscribe({
      next: (response: any) => {
        if (response.result && response.result.length > 0) {
           responseResults = response.result;
           this.setCalendarData(response.result);
        }
      },
      error: (err) => {

      },
      complete: () => {
        
      }
    })
  }

  /**
   * get salary metadata
   */
  getSalarymetadataForOtherMonth(monthYear) {

    let employeeId = this.currentUser.id;

    let start_date_Object: Date = new Date(monthYear);
    let month = (start_date_Object.getMonth() + 1).toString().length === 1 ? ("0" + (start_date_Object.getMonth() + 1)) : (start_date_Object.getMonth() + 1).toString();
    let yearMinus = start_date_Object.getFullYear(); 
    
    this.attendanceService.getSalarymetadataForcalender(employeeId, month, yearMinus).subscribe({
      next: (response: any) => {
        if (response) {
          this.salaryMetadata = response;

        }
      },
      error: (err) => {

      },
      complete: () => {

      }

    })
  }

  /**
   * get salary metadata
   */
  getSalarymetadata() {
    let employeeId = this.currentUser.id;
    this.attendanceService.getSalarymetadata(employeeId).subscribe({
      next: (response: any) => {
        if (response) {
          this.salaryMetadata = response;

        }
      },
      error: (err) => {

      },
      complete: () => {

      }

    })
  }

  /**
   * get day from dat
   * @param index 
   */
  getDays(index) {
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
   return weekday[index];
  }

  /**
   * get user by userId
   * @param id 
   */
  getUserById() {
    this.userService.getUser(this.currentUser.id).subscribe({
      next: (response) => {
        this.displayName = response['displayName'];
        // this.userData = respose.
      },
      error: () => {

      },
      complete: () => {

      }
    })
  }


  getAttendance() {
    let employeeId = this.currentUser.id;
    console.log("current user id:", employeeId);
    this.attendanceService.getAttendanceById(employeeId).subscribe((response: any) => {
      this.rowData = response.result;
      console.log("getAttendance this.rowData:", this.rowData);
      if (response) {
        this.attendanceform.patchValue(response);
        if (response.image !== null) {
          this.AttendanceImage = 'data:image/jpeg;base64,' + response.image;
        } else {
          this.AttendanceImage = './assets/user-icon-jpg-29.jpg';
        }

      }
      this.Name = response.name;
    });
  }

  backtoAttendance() {
    this.router.navigate([this.tenantId + '/dashboard/hr/attendance']);
  }

  public onNavigating(args: NavigatingEventArgs): void {
    if (args.action === 'view' && this.isHubConnected) {
      // this.connection.invoke('SendData', args.action, args.currentView);
      console.log("onNavigation", args.action, args.currentView)
    }
  }

  public onActionComplete(args: ActionEventArgs): void {
    if (this.isHubConnected && (args.requestType === 'eventCreated' || args.requestType === 'eventChanged' || args.requestType === 'eventRemoved')) {

      console.log(args.requestType, this.scheduleObj.eventSettings.dataSource)
      // this.connection.invoke('SendData', args.requestType, this.scheduleObj.eventSettings.dataSource);
    }
  }

  capitalize(object) {
    var isArray = Array.isArray(object);
    for (let key in object) {
      let value = object[key];
      let newKey = key;
      if (!isArray) { // if it is an object 
        delete object[key]; // firstly remove the key 
        newKey = key.charAt(0).toUpperCase() + key.slice(1); // secondly generate new key (capitalized)
      }
      let newValue = value;
      if (typeof value != "object") { // if it is not an object (array or object in fact), stops here 
        if (typeof value == "string") {
          // newValue = value.toUpperCase(); // if it is a string, capitalize it 
          newValue = value;
        }
      } else { // if it is an object, recursively capitalize it 
        newValue = this.capitalize(value);
      }
      object[newKey] = newValue;
    }
    return object;
  }

  getImage() {
    this.image = JSON.parse(this.storageService.get('userImage'));
    if(this.image == null) {
      this.AttendanceImage = './assets/user-icon-jpg-29.jpg';
    } else {
      this.AttendanceImage = 'data:image/jpeg;base64,' + this.image;
    }
  }

 
}
