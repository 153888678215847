<!-- <div *ngIf="spinnerService.visibility.value" class="text-center">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
</div> -->
<div *ngIf="spinnerService.visibility.value" class="loader">
    <div class="loading-container">
        <div class="loading"></div>
        <div id="loading-text">loading</div>
    </div>
  </div>
<!-- <div *ngIf="spinnerService.visibility.value" class="loading-container">
    <div  class="inner-loading-container">
        <div class="spinner-grow text-primary" role="status">
            <span class="sr-only"></span>
        </div>
        <div class="spinner-grow text-primary" role="status">
            <span class="sr-only"></span>
        </div>
        <div class="spinner-grow text-primary" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</div> -->
<div class="wrapper">
    <div class="box header">
        <app-header></app-header>
    </div>
    <div class="box container-delete">
        <router-outlet ></router-outlet> 
    </div>
    <div class="box footer">
        <app-footer></app-footer>
    </div>
</div> 
