<main class="main-dashboard">
    <article class="main-canvas"> 
      <section class="canvas-container">
        <section class="dashboard-items">
            <form [formGroup]="taxCtcform">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button (click)="backToCtcTable()">
                                        <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Tax CTC Form</h3>
                            </div>   
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="canvas-data">
                                <div class="row justify-content-end">
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input">
                                                <div class="input-item">
                                                  <input
                                                    list="taskOwnerDatalist"
                                                    formControlName="employeeId"
                                                    placeholder="Employee Name"
                                                    value=""
                                                  />
                                                  <datalist id="taskOwnerDatalist">
                                                    <option
                                                      [value]="item"
                                                      *ngFor="let item of userDropdownList"
                                                    ></option>
                                                  </datalist>
                                                  <label class="floating-label floating-label-special">Employee Name</label>
                                                  <i class="ri-user-line"></i>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <label for="financialYear" class="floating-label floating-label-special">Financial Year</label>
                                              <select formControlName="financialYear" >
                                                <!-- <option value="" disabled selected>Select Financial Year </option>                                    -->
                                                <option value="2022-2023">2022-2023</option>
                                                <option value="2023-2024">2023-2024</option>
                                                <option value="2024-2025">2024-2025</option>
                                                <option value="2025-2026">2025-2026</option>
                                                </select>
                                                <label class="floating-label floating-label-special">Financial Year</label>
                                               <i class="ri-bank-fill"></i>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="ctc"
                                                required/>
                                              <label class="floating-label">CTC</label>
                                               <i class="ri-global-fill"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                              <select formControlName="startMonth">
                                                <!-- <option value="" disabled selected>Select Start Month</option> -->
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                              </select>
                                              <label class="floating-label floating-label-special">Start Month</label>
                                              <i class="ri-calendar-todo-line"></i>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                              <select formControlName="endMonth">
                                                <!-- <option value="" disabled selected>Select End Month</option> -->
                                                <option value="January">January</option>
                                                <option value="February">February</option>
                                                <option value="March">March</option>
                                                <option value="April">April</option>
                                                <option value="May">May</option>
                                                <option value="June">June</option>
                                                <option value="July">July</option>
                                                <option value="August">August</option>
                                                <option value="September">September</option>
                                                <option value="October">October</option>
                                                <option value="November">November</option>
                                                <option value="December">December</option>
                                              </select>
                                              <label class="floating-label floating-label-special">End Month</label>
                                              <i class="ri-calendar-todo-line"></i>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="componentName"
                                                required/>
                                              <label class="floating-label">Component Name</label>
                                              <i class="ri-user-line"></i>
                                            </div>
                                          
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <input
                                                type="text"
                                                formControlName="componentValue"/>
                                              <label class="floating-label">Component Value</label>
                                              <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                              <select formControlName="miscellaneous">
                                                <!-- <option value="" disabled selected>Select Miscellaneous</option> -->
                                                <option [ngValue]="true">True</option>
                                                <option [ngValue]="false">False</option>
                                              </select> 
                                              <label class="floating-label floating-label-special">Miscellaneous</label> 
                                              <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                              <select formControlName="deduction">
                                                <!-- <option value="" disabled selected>Select Deduction</option> -->
                                                <option [ngValue]="true">True</option>
                                                <option [ngValue]="false">False</option>
                                              </select>   
                                              <label class="floating-label floating-label-special">Deduction</label> 
                                              <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                              <select formControlName="taxRegimeNo">
                                                <!-- <option value="" disabled selected>Select Tax Regime No.</option> -->
                                                <option value="Old">Old</option>
                                                <option value="New">New</option>
                                              </select>
                                              <label class="floating-label floating-label-special">Tax RegimeNo</label> 
                                              <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                              <select formControlName="absolute">
                                                <!-- <option value="" disabled selected>Select Deduction</option> -->
                                                <option [ngValue]="true">True</option>
                                                <option [ngValue]="false">False</option>
                                              </select>   
                                              <label class="floating-label floating-label-special">Absolute</label> 
                                              <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1"></div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item no-overflow">
                                                <ng-select
                                                formControlName="tdsIgnoreMonths"
                                                [items]="tdsIgnoreMonthsArray"
                                                [multiple]="true"
                                                bindLabel="lable"
                                                bindValue="value"
                                                placeholder="Select Month"
                                                style="width: -webkit-fill-available"
                                              >
                                              </ng-select>
                                              <label class="floating-label floating-label-special">TDS Ignore Months</label> 
                                              <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item no-overflow">
                                                <input type="text" formControlName="tdsIgnoreMonthsValues" placeholder="Eg. 100,10,1000,..."/>
                                                <label class="floating-label floating-label-special">TDS Ignore Months Values</label> 
                                                <i class="ri-numbers-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 mb-2">
                                        <div class="row justify-content-end " *ngIf="this.taxctcId">
                                            <div class="col-sm-6 px-3 py-1" >
                                                <button type="button" class="btn" (click)="updateuserCtcs()" >
                                                    <div>Update</div>
                                                </button>
                                            </div>
                                            <div class="col-sm-4 px-3 py-1">
                                                <button type="button" id="" class="btn" data-bs-dismiss="modal" (click)="backToCtcTable()">
                                                    <div>Cancel</div>
                                              </button>
                                            </div>
                                        </div>
                                        <div class="row justify-content-end " *ngIf="!this.taxctcId">
                                            <div class="col-sm-6 px-3 py-1" >
                                                <button type="button" id="" class="btn" (click)="createuserCtcs()" >
                                                    <div>Create</div>
                                                </button>
                                            </div>
                                            <div class="col-sm-4 px-3 py-1">
                                                <button type="button" id="" class="btn" data-bs-dismiss="modal" (click)="backToCtcTable()">
                                                    <div>Cancel</div>
                                              </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>   
      </section>
    </article>
</main>
