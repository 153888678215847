import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from "../../../_services/localStorage/local-storage.service";
import { AccumulationChart, AccumulationChartComponent, ChartTheme, IAccPointRenderEventArgs, IAccResizeEventArgs, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { OpportunityService } from 'src/app/_services/opportunity/opportunity.service';
import { Observable } from 'rxjs/Observable';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  currentUser: any;
  public cellSpacing: number[] = [10, 10];
  public chartData: Observable<any>;
  public onPointRender: Function;
  public palette: string[];
  public funnelCount: number;
  public funnelLastName: string;
  //Variable for Stacked chart
  //Temp Varaiable
  public LostDataTemp = [];
  public WinDataTemp = [];
  public NegotiationDataTemp = [];
  public ApprovalsDataTemp = [];
  public PriceDataTemp = [];
  public ValuePropositionDataTemp = [];
  public NeedAnalysisDataTemp = [];
  public QualifyDataTemp = [];


  public LostData: Object[];
  public WinData: Object[];
  public NegotiationData: Object[];
  public ApprovalsData: Object[];
  public PriceData: Object[];
  public ValuePropositionData: Object[];
  public NeedAnalysisData: Object[];
  public QualifyData: Object[];

  public customAttributes: Object;
  public dataFunnelTemp = [];
  public dataFunnel: Object[];
  @ViewChild('funnel')
  public funnel: AccumulationChartComponent | AccumulationChart;
  log: any;
  public funnelNeckWidth(e: Event): void {
    let value: string = (document.getElementById('funnelNeckWidth') as HTMLSelectElement).value;
    this.funnel.series[0].neckWidth = value + '%';
    document.getElementById('funnelNeckWidth').innerHTML = value + '%';
    this.funnel.removeSvg();
    this.funnel.refreshSeries();
    this.funnel.refreshChart();
  };
  public funnelNeckHeight(e: Event): void {
    let value: string = (document.getElementById('funnelNeckHeight') as HTMLSelectElement).value;
    this.funnel.series[0].neckHeight = value + '%';
    document.getElementById('funnelNeckHeight').innerHTML = value + '%';
    this.funnel.removeSvg();
    this.funnel.refreshSeries();
    this.funnel.refreshChart();
  };
  //Initializing DataLabel
  public dataLabel: Object = {
    visible: true, position: 'Inside', connectorStyle: { length: '3%' }, format: '${point.x} : <b>${point.y}</b>'
  };
  // custom code end
  public onChartResized(args: IAccResizeEventArgs): void {
    let bounds: ClientRect = document.getElementById('container').getBoundingClientRect();
    if (bounds.width < bounds.height) {
      args.accumulation.series[0].width = '80%';
      args.accumulation.series[0].height = '70%';
    } else {
      args.accumulation.series[0].width = '60%';
      args.accumulation.series[0].height = '80%';
    }
  };
  public neckWidth: string = '25%';
  public neckHeight: string = '0%';
  public gapRatio: number = 0.03;
  public emptyPointSettings: Object = {
    fill: 'red', mode: 'Drop'
  };
  public explode: boolean = false;
  public enableAnimation: boolean = false;
  public legendSettings: Object = { visible: true, toggleVisibility: false, position: 'Bottom' };
  public tooltip: Object = { enable: true, format: '${point.x} : <b>${point.y}</b>' };
  public titleFunnel: string = 'Opportunity Status Funnel';
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    labelIntersectAction: 'Rotate45',
    valueType: 'Category'
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: 'Total Gross Price by Year / Status',
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}B',
  };
   // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
    // args.chart.themeStyle.minorGridLine = "#E94649";
    console.log(args.chart)
    console.log("Load Event for Funnel Chart", args)
  };
  // custom code end
  public legendSettingsStackedChart: Object = { visible: true, toggleVisibility: true, position: 'Bottom' };
  public title: string = 'Weighted Graph as per the Status and Year';
  public chartArea: Object = {
    border: {
      width: 0
    }
  };
  public width: string = Browser.isDevice ? '100%' : '60%';
  constructor(private opportunityService: OpportunityService, private storageService: LocalStorageService) {
    this.generateFunnelChart();
    this.generateStackedChart();
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    console.log("currentUser in home ngOnInit: ", this.currentUser);


    //Graph
    var stepArr = ["Lost", "Win", "Negotiation Review", "Price Quote", "Approvals", "Value Proposition", "Need Analysis", "Qualify"]
    this.palette = ["Red", "Green", "#8f8b66", "#3f82f6", "#954535", '#40e0d0', "#008080", "#e1ad01"]
    this.onPointRender = (args: IAccPointRenderEventArgs): void => {

      // console.log(args.series)
      var pointsLength = args.series.points.length;
      var pointsResultData = args.series.resultData;
      console.log("Fetched Resulted Data on Load", pointsResultData)
      var pointsName = args.series.resultData[pointsLength - 1]["text"];


      //jsonObjChart[pointsLength];
      //console.log(pointsLength, pointsName)
      //console.log(args.point.x,args.point)

      //if count of qualify is more than need analysis then its ok but if the qualify count is less then its red

      //similarly if negotiation count is less than win then its red else its default color

      this.funnelCount = args.point.index;
      this.funnelLastName = args.point.x.toString();
      if ((args.point.x as string).indexOf("Lost") > -1) {
        args.fill = 'Red';
      }
      if ((args.point.x as string).indexOf("Win") > -1) {
        args.fill = 'Green';
      }
      if ((args.point.x as string).indexOf("Negotiation Review") > -1) {
        args.fill = '#8f8b66';
      }
      if ((args.point.x as string).indexOf("Price Quote") > -1) {
        args.fill = '#3f82f6';
      }
      if ((args.point.x as string).indexOf("Approvals") > -1) {
        args.fill = '#954535';
      }
      if ((args.point.x as string).indexOf("Value Proposition") > -1) {
        args.fill = '#40e0d0';
      }
      if ((args.point.x as string).indexOf("Need Analysis") > -1) {
        args.fill = '#008080';
      }
      if ((args.point.x as string).indexOf("Qualify") > -1) {
        args.fill = '#e1ad01';
      }
      //if ((args.point.x as string).indexOf("Qualify") < (args.point.x as string).indexOf("Need Analysis")) {
      //  console.log(args);
      //  args.fill = 'Red';

      //} else {

      //}

      // if ((args.point.x as string).indexOf("Negotiation Review") < (args.point.x as string).indexOf("Win")) {
      //  args.fill = 'Red';
      //}


    }









    // get drop-down list values in datagrid

    //this.statusParams = {
    //  params: {
    //    dataSource: new DataManager(status),
    //    fields: { text: 'status', value: 'status' }
    //  }
    //};
    //this.statusParams = {
    //  params: {
    //    dataSource: new DataManager(status),
    //    fields: { text: 'status', value: 'status' }
    //  }
    //};




  }


  //Generate the Stacked Chart as per the Fetched api data
  generateStackedChart() {
    this.dataFunnelTemp = [];
    let StatusObj = {};
    let day;
    // console.log(this.data1);
    this.LostDataTemp = [];
    this.WinDataTemp = [];
    this.NegotiationDataTemp = [];
    this.ApprovalsDataTemp = [];
    this.PriceDataTemp = [];
    this.ValuePropositionDataTemp = [];
    this.NeedAnalysisDataTemp = [];
    this.QualifyDataTemp = [];
    this.opportunityService.getData().subscribe(data => {
      // this.chartData = data;
      for (const property in data) {
        switch (data[property].status) {
          case 'Lost':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //  console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.LostDataTemp.push(jsonCal);
            // console.log(this.LostDataTemp);
            break;
          case 'Win':

            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //  console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.WinDataTemp.push(jsonCal);
            // console.log(this.WinDataTemp);
            break;
          case 'Negotiation Review':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //  console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.NegotiationDataTemp.push(jsonCal);
            //  console.log(this.NegotiationDataTemp);
            break;
          case 'Approvals':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //   console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.ApprovalsDataTemp.push(jsonCal);
            // console.log(this.ApprovalsDataTemp);

            break;
          case 'Price Quote':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //   console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.PriceDataTemp.push(jsonCal);
            //  console.log(this.PriceDataTemp);
            break;
          case 'Value Proposition':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //  console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.ValuePropositionDataTemp.push(jsonCal);
            // console.log(this.ValuePropositionDataTemp);
            break;
          case 'Need Analysis':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //  console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.NeedAnalysisDataTemp.push(jsonCal);
            //  console.log(this.NeedAnalysisDataTemp);
            break;
          case 'Qualify':
            var dateData = new Date(data[property].startDate);
            var yearStart = dateData.getFullYear().toString();

            //Calculate the value
            var GrossPriceVal = data[property].grossPrice;
            var ProbabilityVal = data[property].probability;
            var WeightedNo = (ProbabilityVal / 100) * GrossPriceVal;
            //   console.log(GrossPriceVal, ProbabilityVal, WeightedNo);
            var jsonCal = { x: yearStart, y: WeightedNo };
            this.QualifyDataTemp.push(jsonCal);
          //  console.log(this.QualifyDataTemp);
        }
        this.LostData = this.calculateTotalWeighted(this.LostDataTemp);
        this.WinData = this.calculateTotalWeighted(this.WinDataTemp);
        this.NegotiationData = this.calculateTotalWeighted(this.NegotiationDataTemp);
        this.ApprovalsData = this.calculateTotalWeighted(this.ApprovalsDataTemp);
        this.PriceData = this.calculateTotalWeighted(this.PriceDataTemp);
        this.ValuePropositionData = this.calculateTotalWeighted(this.ValuePropositionDataTemp);
        this.NeedAnalysisData = this.calculateTotalWeighted(this.NeedAnalysisDataTemp);
        this.QualifyData = this.calculateTotalWeighted(this.QualifyDataTemp);
      }

    });
  }
  getUnique(array) {
    var uniqueArray = [];

    // Loop through array values
    for (var i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  calculateTotalWeighted(arg) {
    var resultedObj = [];
    var xValue = [];
    var sum = 0;
    // console.log("Arguement Object", arg);
    for (var j = 0; j < arg.length; j++) {
      xValue.push(arg[j]["x"]);
    }
    var uniqueVal = this.getUnique(xValue);
    for (var y = 0; y < uniqueVal.length; y++) {
      resultedObj.push({ x: uniqueVal[y], y: 0 });
    }
    for (var i = 0; i < resultedObj.length; i++) {
      for (var x = 0; x < arg.length; x++) {
        if (arg[x]["x"] == resultedObj[i]["x"]) {
          resultedObj[i]["y"] = arg[x]["y"] + resultedObj[i]["y"];
        }
      }
    }
    //  console.log("Final Resulted Value", resultedObj)
    return resultedObj;
  }

  //Generate the Funnel Chart as per the Fetched api data
  generateFunnelChart() {
    var stepArr = ["Lost", "Win", "Negotiation Review", "Price Quote", "Approvals", "Value Proposition", "Need Analysis", "Qualify"]
    var ResultedTempArr = [];
    this.dataFunnelTemp = [];
    let StatusObj = {};
    let day;
    this.opportunityService.getData().subscribe(data => {
      this.chartData = data;
      console.log("Status data", this.chartData);
      for (var i = 0; i < stepArr.length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (stepArr[i] == data[j].status) {
            ResultedTempArr.push(data[j]);
          }
        }
      }
      console.log("Arranged Arr", ResultedTempArr);
      for (const property in ResultedTempArr) {
        switch (ResultedTempArr[property].status) {
          case 'Lost':
            if (StatusObj['Lost'] != null) {
              StatusObj['Lost'] = StatusObj['Lost'] + 1;
            } else {
              StatusObj['Lost'] = 1;
            }
            day = "Lost";
            break;
          case 'Win':
            day = "Win";
            if (StatusObj['Win'] != null) {
              StatusObj['Win'] = StatusObj['Win'] + 1;
            } else {
              StatusObj['Win'] = 1;
            }
            break;
          case 'Negotiation Review':
            day = "Negotiation / Review";
            if (StatusObj['Negotiation Review'] != null) {
              StatusObj['Negotiation Review'] = StatusObj['Negotiation Review'] + 1;
            } else {
              StatusObj['Negotiation Review'] = 1;
            }
            break;
          case 'Approvals':
            day = "Approvals";
            if (StatusObj['Approvals'] != null) {
              StatusObj['Approvals'] = StatusObj['Approvals'] + 1;
            } else {
              StatusObj['Approvals'] = 1;
            }
            break;
          case 'Price Quote':
            day = "Price Quote";
            if (StatusObj['Price Quote'] != null) {
              StatusObj['Price Quote'] = StatusObj['Price Quote'] + 1;
            } else {
              StatusObj['Price Quote'] = 1;
            }
            break;
          case 'Value Proposition':
            day = 'Value Proposition';
            if (StatusObj['Value Proposition'] != null) {
              StatusObj['Value Proposition'] = StatusObj['Value Proposition'] + 1;
            } else {
              StatusObj['Value Proposition'] = 1;
            }
            break;
          case 'Need Analysis':
            day = "Need Analysis";
            if (StatusObj['Need Analysis'] != null) {
              StatusObj['Need Analysis'] = StatusObj['Need Analysis'] + 1;
            } else {
              StatusObj['Need Analysis'] = 1;
            }
            break;
          case 'Qualify':
            day = "Qualify";
            if (StatusObj['Qualify'] != null) {
              StatusObj['Qualify'] = StatusObj['Qualify'] + 1;
            } else {
              StatusObj['Qualify'] = 1;
            }
        }
      }

      for (const obj in StatusObj) {
        let funnelObj = { 'x': obj, y: StatusObj[obj], text: obj };
        this.dataFunnelTemp.push(funnelObj);
      }
      this.dataFunnel = this.dataFunnelTemp;
      console.log("this.dataFunnel", this.dataFunnel);
      //this.dataFunnel.sort(function (a, b) {
      //  return a["y"] - b["y"];
      //});
      console.log("this.dataFunnel After Sort", this.dataFunnel);
    });
    //this.OpportunityData = this.getOpportunityData();
    // console.log(this.OpportunityData);


    /////const data = this.dataOp;

  }
}
