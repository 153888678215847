<div class="heading d-flex justify-content-between align-items-center">
    <h2>Manpower Planning</h2>
    <button _ngcontent-mjv-c22="" type="button" (click)="visibleForm()"
        class="btn btn-icon"><i _ngcontent-mjv-c22="" class="ri-add-line"></i>
    </button>
</div>
<form [formGroup]="manpowerFormGroup">
    <div class="table-responsive">
        <table class="table">
            <thead class="h-90">
                <tr>
                    <th></th>
                    <th>Task name</th>
                    <th>Activity</th>
                    <th>Cost Center</th>
                    <th>Location</th>
                    <th>Resource Level</th>
                    <th>Software</th>
                    <th>SW Utilization</th>
                    <th *ngFor="let day of days">
                        <div class="trans-270">{{day | date:'MMM-y'}}</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let manpower of allocationDataTop">
                    <td>
                        
                                <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="editPlanningById(manpower.id)" class="btn btn-icon my-1">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                                <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="deletePlanningById(manpower.id)" class="btn btn-icon">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                        
                    </td>
                    <td>
                        {{manpower.taskName}}
                    </td>
                    <td>
                        {{manpower.activity}}
                    </td>
                    <td>
                        {{manpower.costcenter}}
                    </td>
                    <td>
                        {{manpower.location}}
                    </td>
                    <td>
                        {{manpower.resourceLevel}}
                    </td>
                    <td>
                        {{manpower.software}}
                    </td>
                    <td>
                        {{manpower.softwareUtilization}}
                    </td>
                    <td *ngFor="let dayManpower of manpower.values">
                        {{dayManpower.value}}
                    </td>
                </tr>
                <tr *ngIf="isManpowerFormVisible">
                    <td>
                        <button *ngIf="formMode === 1" [ngClass]="requiredSave() ? 'btn-invalid-action' : ''" _ngcontent-mjv-c22="" type="button" (click)="savePlanning()" class="btn btn-icon">
                            <span><i class="ri-save-line"></i>
                            </span>
                        </button>
                        <button *ngIf="formMode === 2" [ngClass]="requiredSave() ? 'btn-invalid-action' : ''" _ngcontent-mjv-c22="" type="button" (click)="updatePlanning()" class="btn btn-icon">
                            <span><i class="ri-file-edit-line"></i></span>
                        </button>
                    </td>
                    <td>
                        <div class="input">
                            <input type="text" formControlName="taskName">
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <input type="text" formControlName="activity">
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select name="" id="" formControlName="costcenter"
                                (selectionChange)="getCostCenterddLevelRates()">
                                <option *ngFor="let costCenter of costCenterDropDown; let ccddpos = index"
                                    [value]="costCenter">{{ costCenter }}</option>
                                <option value="--">--</option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select name="" id="" formControlName="location"
                                (selectionChange)="getCostCenterddLevelRates()">
                                <option
                                    *ngFor="let costCenterLoc of costCenterDropDownloc; let ccddlocpos = index"
                                    [value]="costCenterLoc">{{ costCenterLoc }}</option>
                                <option value="--">--</option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select name="" id="" formControlName="resourceLevel"
                                (selectionChange)="getCostCenterddLevelRates()">
                                <option
                                    *ngFor="let costCenterLvl of costCenterDropDownReslvl; let ccddlvlpos = index"
                                    [value]="costCenterLvl">{{ costCenterLvl }}</option>
                                <option value="--">--</option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select name="" id="" formControlName="software"
                                (selectionChange)="getSoftwareddRates()">
                                <option *ngFor="let Software of softwareDropDown; let sftddpos = index"
                                    [value]="Software.software">{{ Software.software }}</option>
                                <option value="--">--</option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select name="" id="" formControlName="softwareUtilization">
                                <option *ngFor="let val of softwareUtildropdown" [value]="val">{{val}}</option>
                            </select>
                        </div>
                    </td>
                    <td  *ngFor="let day of days">
                        <input class="input-date-manpower" type="text" [formControlName]="day">
                    </td>
                </tr>
                <tr *ngFor="let manpower of allocationDataBottom">
                    <td>
                        <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="editPlanningById(manpower.id)" class="btn btn-icon">
                            <span><i class="ri-pencil-line"></i></span>
                        </button>
                        <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="deletePlanningById(manpower.id)" class="btn btn-icon">
                            <span><i class="ri-pencil-line"></i></span>
                        </button>
                    </td>
                    <td>
                        {{manpower.taskName}}
                    </td>
                    <td>
                        {{manpower.activity}}
                    </td>
                    <td>
                        {{manpower.costcenter}}
                    </td>
                    <td>
                        {{manpower.location}}
                    </td>
                    <td>
                        {{manpower.resourceLevel}}
                    </td>
                    <td>
                        {{manpower.software}}
                    </td>
                    <td>
                        {{manpower.softwareUtilization}}
                    </td>
                    <td *ngFor="let dayManpower of manpower.values">
                        {{dayManpower.value}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</form>