<div class="admin-profile">
    <div class="canvas-item">
        <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
                <div class="d-flex align-items-center">
                    <div class="title col-sm-6">
                        <h5>User Documents</h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 d-flex justify-content-end">
                <div class=" add-btn">
                    <button  data-bs-toggle="modal" data-bs-target="#notification_modal" class="btn btn-icon" (click)="visibleForm()">
                        <i class="ri-add-line"></i>
                    </button>
                </div>
            </div>
        </div>
        
        <div class="row my-1 align-items-center">
            <form [formGroup] = "employeeForm">
                <div class="row p-0">
                    <div class="col-sm-12 p-0">
                        <div class="canvas-data px-0">
                            <div class="row m-0">
                                <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2" *ngIf="this.isManager || this.isHRAdmin">
                                    <div class="input">
                                        <div class="input-item"> 
                                          <input list="taskOwnerDatalist" formControlName="employeeId" placeholder="Select Employee Name" value="" onfocus="this.value=''" onchange="this.blur();" />
                                          <datalist id="taskOwnerDatalist">
                                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                          </datalist>
                                          <label class="floating-label">Employee</label>
                                          <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 text-end text-right" *ngIf="this.isManager || this.isHRAdmin">
                                    <button type="button" class="btn" (click)="getDocumentsByUserId()">
                                      <div>Submit</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="admin-profile border-bottom">
        <div class="row my-3 align-items-center">
            <div class="col-sm-12 inner-details">
                <form [formGroup]="documentUploadForm">
                    <table class="table">
                        <thead class="h-90" style="border: 0; border-radius: 8px">
                            <tr>
                                <th>Document Name</th>
                                <th>Document Type</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngIf="isForm">
                                <td>
                                    <div class="col-sm-3">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="documentName" placeholder="Document Name*" (change)="checkStatus($event.target.value)" required style="border: 0; background: #f1f3f6; border-radius: 4px">
                                                    <option value="" disabled>Select File</option>
                                                    <option value="Aadhar Card">Aadhar Card</option>
                                                    <option value="PAN Card">PAN Card</option>
                                                    <option value="Passbook">Passbook</option>
                                                    <option value="10th Diploma Certificate">10th Diploma Certificate</option>
                                                    <option value="Graduation Degree Certificate">Graduation Degree Certificate</option>
                                                    <option value="Electricity Bill">Electricity Bill</option>
                                                    <option value="Document1">Document1</option>
                                                    <option value="Document2">Document2</option>
                                                    <option [ngValue]="true">Other</option>
                                                </select>
                                                <i class="ri-file-line"></i>
                                                <label class="floating-label-special">Document Name*</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3" *ngIf="this.isOther">
                                        <div class="input">
                                            <div class="input-item">
                                                <input list="taskOwnerDatalist" formControlName="documentName1" required style="border: 0; background: #f1f3f6; border-radius: 4px"/>
                                                <i class="ri-file-line"></i>
                                                <label class="floating-label-special">Document Name*</label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-3">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="documentType" style="border: 0; background: #f1f3f6; border-radius: 4px">
                                                    <option value="" disabled>Select File Type</option>
                                                    <option value="pdf">PDF</option>
                                                    <option value="docs">Docs</option>
                                                    <option value="jpeg">JPEG</option>
                                                    <option value="png">PNG</option>
                                                </select>
                                                <i class="ri-draft-fill"></i>
                                                <label class="floating-label-special">Document Type*</label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-3">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="file" formControlName="file" (change)="onFileChanged($event)" required style="border: 0; background: #f1f3f6; border-radius: 4px"/>
                                                <i class="ri-attachment-2"></i>
                                                <label class="floating-label-special">Document/File*</label>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td style="max-width: 100px">
                                    <div class="row">
                                        <div class="d-flex gap-2" style="width: 100px">
                                            <button *ngIf="formMode === 1" [ngClass]="requiredSave() ? 'btn-invalid-action' : '' " _ngcontent-mjv-c22="" type="button" (click)="saveDocument()" class="btn btn-icon">
                                                <span><i class="ri-save-line"></i></span>
                                            </button>
                                            <button type="button" (click)="cancelSave()" class="btn btn-icon">
                                                <span><i class="ri-close-line"></i></span>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
            
                            <tr *ngFor="let item of documentsData">
                                <td> {{ item.documentName }} </td>
                                <td> {{ item.documentType }} </td>
                                <td style="max-width: 100px">
                                    <div class="d-flex gap-2" style="width: 100px">
                                        <button [ngClass]="formMode !== 0 ? 'd-none' : ''" _ngcontent-mjv-c22="" type="button" (click)="downloadDocumentById(item.id, item.fileName)" class="btn btn-icon">
                                            <span><i class="ri-download-line"></i></span>
                                        </button>
                                        <button *ngIf="this.isSameUserDocs" [ngClass]="formMode !== 0 ? 'd-none' : ''" _ngcontent-mjv-c22="" type="button" (click)="deleteDocumentById(item.id)" class="btn btn-icon">
                                            <span><i class="ri-delete-bin-6-line"></i></span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>