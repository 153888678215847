<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> {{jobTitle}} </h3>
                        </div>
                    </div>
                    <div class="row inner-details">
                        <div class="col-sm-1">
                            <p>
                                <span class="line"><i class="ri-calendar-2-line"></i>
                                    <p>&nbsp;{{expectedExperience}}</p>
                                </span>
                            </p>
                        </div>
                        <div class="col-sm-1">
                            <span class="line"><p style="color: gray;">|</p>&nbsp;<i class="ri-wallet-2-line"></i>
                                <p>&nbsp;{{expectedSalary}}</p>
                            </span>
                        </div>
                        <div class="col-sm-1">
                            <span class="line"><p style="color: gray;">|</p>&nbsp;<i class="ri-map-pin-line"></i>
                                <p>&nbsp;{{location}}</p>
                            </span>
                        </div>
                        <div class="col-sm-1">
                            <span class="line"><p style="color: gray;">|</p>&nbsp;<i class="ri-article-line"></i>
                                <p>&nbsp;{{expectedQualifications}}</p>
                            </span>
                        </div>
                    </div>
                    <div style="margin: 2%;">
                        <p>{{description}}</p>
                    </div>
                    <div style="margin: 1.5%;">
                        <h6>Apply for this job</h6>
                    </div>
                    <div class="row">
                        <form [formGroup]="jobPostForm">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                <div class="canvas-data">
                                    <div class="row">
                                        <div class="row inner-details">
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Name*</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="applicantName" required />
                                                            <i class="ri-shield-user-line"></i>
                                                        </div>
                                                        <div>
                                                            <mat-error *ngIf="f.applicantName.touched && f.applicantName.invalid" class="alert alert-danger">
                                                                <mat-error *ngIf="f.applicantName.errors.required">Name is required</mat-error>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Email*</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="email" required />
                                                            <i class="ri-mail-unread-line"></i>
                                                        </div>
                                                        <div>
                                                            <mat-error *ngIf="f.email.touched && f.email.invalid" class="alert alert-danger">
                                                                <mat-error *ngIf="f.email.errors.required">Email is required</mat-error>
                                                            </mat-error>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row inner-details">
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Phone No.</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="phoneNo" />
                                                            <i class="ri-phone-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6 px-3 py-1">
                                                <div class="box">
                                                    <span>Relavant Experience</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="text" formControlName="relevantExperience" />
                                                            <i class="ri-calendar-todo-line"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Description</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <textarea name="" id="" cols="30" rows="4" formControlName="description"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row inner-details">
                                            <div class="col-sm-12 px-3 py-1">
                                                <div class="box">
                                                    <span>Attachment</span>
                                                    <div class="input">
                                                        <div class="input-item">
                                                            <input type="file" (change)="onFileChanged($event)" placeholder="Attachment" />
                                                            <i class="ri-attachment-line"></i>
                                                        </div>
                                                        <p>Upload in either Doc, Docx, or PDF file format(File size not more than 3 MB)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-end align-items-center">
                                            <div class="col-sm-12 text-end px-3 py-1">
                                                <div class="d-flex justify-content-end gap-2">
                                                    <button class="btn-secondary btn" (click)="backToPost()">Cancel</button>
                                                    <button type="button" class="btn" (click)="createJobApplication()" [disabled]="!jobPostForm.valid">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>