<div class="heading">
    <h2>Task Cost Summary</h2>
</div>
<div class="table-responsive">
    <table class="table">
        <thead>
            <tr>
                <th colspan="5" style="border: 0.5px solid;"></th>
                <th [attr.colspan]="costSpan()" style="text-align: center; border: 0.5px solid;">COST</th>
                <th [attr.colspan]="priceSpan()" style="text-align: center; border: 0.5px solid;">PRICE</th>
            </tr>
            <tr>
                <th style="border-left: 0.5px solid;">Task</th>
                <th>Hours</th>
                <th>AVG FTE	</th>
                <th>Peak FTE</th>
                <th style="border-right: 0.5px solid;">Average Cost Rate</th>
                <th *ngIf="isManpowerBtnOpen">Manpower Cost</th>
                <th *ngIf="isManpowerBtnOpen">Offshore Cost</th>
                <th>Total Manpower</th>
                <th style="border-right: 0.5px solid;">
                    <button (click)="manpowerBtn()" class="btn" style="min-width: 25px !important;">
                        <span><i class="ri-arrow-left-line" [ngClass]="isManpowerBtnOpen ? 'ri-arrow-left-line' : 'ri-arrow-right-line'"></i></span>
                    </button>
                </th>
                <th *ngIf="isOdcCostBtnOpen">Travel Cost</th>
                <th *ngIf="isOdcCostBtnOpen">Services Cost</th>
                <th *ngIf="isOdcCostBtnOpen">Facilities Cost</th>
                <th *ngIf="isOdcCostBtnOpen">Other Cost</th>
                <th>Total ODC Cost</th>
                <th style="border-right: 0.5px solid;">
                    <button (click)="odcCostBtn()" class="btn" style="min-width: 25px !important;">
                        <span><i class="ri-arrow-left-line" [ngClass]="isOdcCostBtnOpen ? 'ri-arrow-left-line' : 'ri-arrow-right-line'"></i></span>
                    </button>
                </th>
                <th>Software Cost</th>
                <th style="border-right: 0.5px solid;">Grand Total Cost</th>
                <th *ngIf="isTotalManpowerBtnOpen">Manpower Price</th>
                <th *ngIf="isTotalManpowerBtnOpen">Offshore Price</th>
                <th>Total Manpower</th>
                <th style="border-right: 0.5px solid;">
                    <button (click)="totalManpowerBtn()" class="btn" style="min-width: 25px !important;">
                        <span><i class="ri-arrow-left-line" [ngClass]="isTotalManpowerBtnOpen ? 'ri-arrow-left-line' : 'ri-arrow-right-line'"></i></span>
                    </button>
                </th>
                <th *ngIf="isOdcPriceBtnOpen">Travel Price</th>
                <th *ngIf="isOdcPriceBtnOpen">Services Price</th>
                <th *ngIf="isOdcPriceBtnOpen">Facilities Price</th>
                <th *ngIf="isOdcPriceBtnOpen">Other Price</th>
                <th>Total ODC Price</th>
                <th style="border-right: 0.5px solid;">
                    <!-- <div class="edit">
                        <button type="button" (click)="odcPriceBtn()">
                            <span>
                                <i class="ri-arrow-left-line" [ngClass]="isManpowerBtnOpen ? 'ri-arrow-left-line' : 'ri-arrow-right-line'"></i>
                            </span>
                        </button>
                    </div> -->
                    <button (click)="odcPriceBtn()" class="btn" style="min-width: 25px !important;">
                        <span><i class="ri-arrow-left-line" [ngClass]="isManpowerBtnOpen ? 'ri-arrow-left-line' : 'ri-arrow-right-line'"></i></span>
                    </button>
                </th>
                <th>Software Price</th>
                <th style="border-right: 0.5px solid;">Net Price excl. Risk Manager</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of summaryDetails" >
                <td style="border-left: 0.5px solid;">{{item.taskname}}</td>
                <td>{{item.hours}}</td>
                <td>{{item.averageFTE}}</td>
                <td>{{item.peakFTE}}</td>
                <td style="border-right: 0.5px solid;">{{item.averageCost}}</td>
                <td *ngIf="isManpowerBtnOpen">{{item.costSummaryDetails?.manpower}}</td>
                <td *ngIf="isManpowerBtnOpen">{{item.costSummaryDetails?.thirdParty}}</td>
                <td colspan="2" style="text-align: center; border-right: 0.5px solid;">{{item.costSummaryDetails?.totalManpower}}</td>
                <td *ngIf="isOdcCostBtnOpen">{{item.costSummaryDetails?.travel}}</td>
                <td *ngIf="isOdcCostBtnOpen">{{item.costSummaryDetails?.services}}</td>
                <td *ngIf="isOdcCostBtnOpen">{{item.costSummaryDetails?.testFacilities}}</td>
                <td *ngIf="isOdcCostBtnOpen">{{item.costSummaryDetails?.other}}</td>
                <td colspan="2" style="text-align: center; border-right: 0.5px solid;">{{item.costSummaryDetails?.totalODC}}</td>
                <td>{{item.costSummaryDetails?.software}}</td>
                <td style="border-right: 0.5px solid;">{{item.costSummaryDetails?.totalCost}}</td>
                <td *ngIf="isTotalManpowerBtnOpen">{{item.priceSummaryDetails?.manpower}}</td>
                <td *ngIf="isTotalManpowerBtnOpen">{{item.priceSummaryDetails?.thirdParty}}</td>
                <td colspan="2" style="text-align: center; border-right: 0.5px solid;">{{item.priceSummaryDetails?.totalManpower}}</td>
                <td *ngIf="isOdcPriceBtnOpen">{{item.priceSummaryDetails?.travel}}</td>
                <td *ngIf="isOdcPriceBtnOpen">{{item.priceSummaryDetails?.services}}</td>
                <td *ngIf="isOdcPriceBtnOpen">{{item.priceSummaryDetails?.testFacilities}}</td>
                <td *ngIf="isOdcPriceBtnOpen">{{item.priceSummaryDetails?.other}}</td>
                <td colspan="2" style="text-align: center; border-right: 0.5px solid;">{{item.priceSummaryDetails?.totalODC}}</td>
                <td>{{item.priceSummaryDetails?.software}}</td>
                <td style="border-right: 0.5px solid;">{{item.priceSummaryDetails?.netPriceInDetails}}</td>                  
            </tr>  
            <tr>
                <td style="font-weight: 600; border-left: 0.5px solid; border-bottom: 0.5px solid;">Grand Total</td>
                <td style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalHours}}</td> 
                <td style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalAvgFTE}}</td>
                <td style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalAvgPTE}}</td> 
                <td style="border-right: 0.5px solid; border-bottom: 0.5px solid;"></td> 
                <td *ngIf="isManpowerBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandManpowerCost}}</td> 
                <td *ngIf="isManpowerBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandOffshoreCost}}</td> 
                <td colspan="2" style="text-align: center; border-right: 0.5px solid; border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalCostManpower}}</td> 
                <td *ngIf="isOdcCostBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTravelCost}}</td> 
                <td *ngIf="isOdcCostBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandServicesCost}}</td> 
                <td *ngIf="isOdcCostBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTestFacilitiesCost}}</td> 
                <td *ngIf="isOdcCostBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandOtherCost}}</td> 
                <td colspan="2" style="text-align: center; border-right: 0.5px solid; border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalCostODC}}</td>  
                <td style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalCostSoftware}}</td>
                <td style="border-bottom: 0.5px solid; border-right: 0.5px solid;">{{taskCostSummary?.grandTotalCostCost}}</td>
                <td *ngIf="isTotalManpowerBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandManpowerPrice}}</td>
                <td *ngIf="isTotalManpowerBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandOffshorePrice}}</td>
                <td colspan="2" style="text-align: center; border-right: 0.5px solid; border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalPriceManpower}}</td>
                <td  *ngIf="isOdcPriceBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTravelPrice}}</td>
                <td  *ngIf="isOdcPriceBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandServicesPrice}}</td>
                <td  *ngIf="isOdcPriceBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTestFacilitiesPrice}}</td>
                <td  *ngIf="isOdcPriceBtnOpen" style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandOtherPrice}}</td>
                <td colspan="2" style="text-align: center; border-bottom: 0.5px solid; border-right: 0.5px solid;">{{taskCostSummary?.grandTotalPriceODC}}</td>
                <td style="border-bottom: 0.5px solid;">{{taskCostSummary?.grandTotalPriceSoftware}}</td>
                <td style="border-bottom: 0.5px solid; border-right: 0.5px solid;">{{taskCostSummary?.netPrice}}</td>
            </tr>              
        </tbody>
    </table>
</div>

