<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button title="back page" (click)="backToLeaveProfile()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Leave Revoke</h3>
                            </div>
                        </div>
                        <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" title="Edit" (click)="openEditModal()" data-bs-toggle="modal" data-bs-target="#leaveRevoke">
                                  <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                            <div>
                                <button type="button" title="Create" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#leaveRevoke" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        style="height: 620px;"
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)"
                        [rowData]="rowData"
                        [columnDefs]="columnDefs"
                        pagination="true"
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Lead -->
<div class="modal fade" id="leaveRevoke" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="leaveRevokeForm">
                <div class="modal-header">
                    <h5 class="modal-title">Leave Revoke</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input list="userDropdownList" formControlName="employeeId" placeholder="Select Employee Name" value="" />
                                <datalist id="userDropdownList">
                                    <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                </datalist>
                            <label for="employeeId" class="floating-label-special">Employee</label>
                            <i class="ri-user-line"></i>
                        </div>
                        <div class="input-item">
                            <input formControlName="month" type="month">
                            <label for="month" class="floating-label-special">Month</label>
                            <i class="ri-user-line"></i>
                        </div>
                        <div class="input-item">
                            <select formControlName="year">
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                            </select>
                            <label for="year" class="floating-label-special">Year</label>
                            <i class="ri-user-line"></i>
                        </div>
                        <div class="input-item">
                            <input formControlName="days" type="text">
                            <label for="days" class="floating-label-special">No. of Days to Revoke</label>
                            <i class="ri-user-line"></i>
                        </div>
                        <div class="input-item">
                            <input formControlName="value" type="text">
                            <label for="days" class="floating-label-special">Arrear Amount</label>
                            <i class="ri-user-line"></i>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" id="closeCreateModal" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createLeaveRevoke()">Create</button>
                        <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateLeaveRevoke()">Update</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>