import { Component, OnInit } from '@angular/core';
import { PaymentsService } from '../../../_services/payments/payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {

  constructor(
    private payments: PaymentsService
  ) { }

  ngOnInit(): void {
  }
  options = {
    "key": "rzp_live_eK4sFPkTfTAbTW", // Enter the Key ID generated from the Dashboard
    "amount": "100", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    "currency": "INR",
    "name": "Acme Corp",
    "description": "Test Transaction",
    "image": "https://example.com/your_logo",
    "order_id": "order_9A33XWu170gUtm", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    "handler": function (response) {
      alert(response.razorpay_payment_id);
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature)
    },
    "prefill": {
      "name": "Bhavik",
      "email": "bhavik@example.com",
      "contact": "9999999999"
    },
    "notes": {
      "address": "Razorpay Corporate Office"
    },
    "theme": {
      "color": "#3399cc"
    }
     };

     rzpbutton1() {
      let rzp1 = new this.payments.nativeWindow.Razorpay(this.options);
      rzp1.open();
  }

}
