<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center"> 
                                <div class="edit">
                                    <button title="back page" (click)="backToExit()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Exit Process</h3>
                            </div>
                        </div>
                    </div>
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6 d-flex">
                            <h3><a (click)="openExitEmployeeForm()" class="cursor-pointer">Employee Exit Form</a></h3>
                            <h3><a class="active cursor-pointer">Employee Exit Interview Form</a></h3>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div>
                            <p>
                                Please complete the following picks :
                            </p>
                        </div>
                        <form [formGroup]="employeeExitInterviewForm">
                            <div class="row">
                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        1 . I had regular feedback on my performance
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="regularFeedback">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        6 . I had a good working relationship with co-workers
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="workingRelationshipWithCoworkers">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        2 . The policies and welfare benefits were fair
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="policiesAndWelfareAreFair">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        7 . Training & job development met expectations
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="trainingsMetExpectations">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        3 . I am paid fairly for the work I do
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="fairlyPaid">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        8 . Working conditions met expectations
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="workingCOnditionMetExpectation">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        4 . There is scope for personal development & growth
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="personalDevelopmentAndGrowthScope">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        9 . The work culture in the Organization was good
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="companyWorkCulture">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        5 . I had a good working relationship with my supervisor
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="workingRelationshipWithManager">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-6 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        10 . Overall I was satisfied with the job
                                    </p>
                                    <div class="col-sm-10 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="satisfactionWithJob">
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="Strongly Agree">Strongly Agree</option>
                                                    <option value="Agree">Agree</option>
                                                    <option value="Disagree">Disagree</option>
                                                    <option value="Strongly Disagree">Strongly Disagree</option>
                                                </select>
                                                <label class="floating-label-special">Feedback</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-8 px-3 py-1">
                                    <p style="padding-top: 10px;">
                                        What other comments/suggestions would you like to make ?
                                    </p>
                                    <div class="col-sm-12 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <textarea name="" id="" cols="20" rows="4" formControlName="commentsOrSuggestions"></textarea>
                                                <label class="floating-label-special">Comment</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer" style="padding-top: 10px;">
                                    <button class="btn-secondary btn" (click)="backToExit()">Cancel</button>
                                    <button type="button" class="btn" (click)="saveEmployeeExitInterviewForm()">Save</button>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>


<!-- You worked with us & have contributed your share towards this organization. We appreciate your  services & are interested in having your views towards the company & your colleagues, during  your tenure with us.  
We value your honest, objective & unbiased views on the following issues. We assure you that  sharing this information with others would be kept anonymous & used to increase our  commitment towards being a fair organization. -->