import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/_services/user/user.service';
import { ExitProcessService } from 'src/app/_services/exit-process/exit-prosess.service';

@Component({
  selector: 'app-exit-interview-form',
  templateUrl: './exit-interview-form.component.html',
  styleUrls: ['./exit-interview-form.component.scss']
})
export class ExitInterviewFormComponent implements OnInit {
  tenantId: string;
  userDropdownList = [];
  employeeExitInterviewForm
  form: FormGroup;
  currentUser: any;
  date: Date;
  TodayDate: string;
  resignationId: any;
  resignedUserId; any;
  isUpdateInfo: boolean = false;
  exitInterviewFormID: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private exitProcessService: ExitProcessService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
  ) {
    this.route.queryParams.subscribe(params => {
      this.resignedUserId = params['userId'];
    });

    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });

    this.employeeExitInterviewForm = this.formBuilder.group({
      userId: new FormControl(''),
      regularFeedback: new FormControl(''),
      policiesAndWelfareAreFair: new FormControl(''),
      fairlyPaid: new FormControl(''),
      personalDevelopmentAndGrowthScope: new FormControl(''),
      workingRelationshipWithManager: new FormControl(''),
      workingRelationshipWithCoworkers: new FormControl(''),
      trainingsMetExpectations: new FormControl(''),
      workingCOnditionMetExpectation: new FormControl(''),
      companyWorkCulture: new FormControl(''),
      satisfactionWithJob: new FormControl(''),
      commentsOrSuggestions: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;

    this.getEmployeeInterviewForm(this.resignedUserId);
  }

  getEmployeeInterviewForm(resignedUserId){
    this.exitProcessService.getEmployeeInterviewForm(resignedUserId).subscribe({
      next : (value: any[]) => {
        if(value.length > 0){
          this.isUpdateInfo = true;
          this.exitInterviewFormID = value[0]['id'];
          this.employeeExitInterviewForm.patchValue({
            regularFeedback: value[0]['regularFeedback'],
            policiesAndWelfareAreFair: value[0]['policiesAndWelfareAreFair'],
            fairlyPaid: value[0]['fairlyPaid'],
            personalDevelopmentAndGrowthScope: value[0]['personalDevelopmentAndGrowthScope'],
            workingRelationshipWithManager: value[0]['workingRelationshipWithManager'],
            workingRelationshipWithCoworkers: value[0]['workingRelationshipWithCoworkers'],
            trainingsMetExpectations: value[0]['trainingsMetExpectations'],
            workingCOnditionMetExpectation: value[0]['workingCOnditionMetExpectation'],
            companyWorkCulture: value[0]['companyWorkCulture'],
            satisfactionWithJob: value[0]['satisfactionWithJob'],
            commentsOrSuggestions: value[0]['commentsOrSuggestions'],
          });
        }
      },
      error(err) {
        this.openSnackBar('ERROR: Getting resignation' + err)
      },
      complete() {
      }
    });
  }

  saveEmployeeExitInterviewForm(){
    let details = {
      userId: this.resignedUserId,
      regularFeedback: this.employeeExitInterviewForm.get('regularFeedback').value,
      policiesAndWelfareAreFair: this.employeeExitInterviewForm.get('policiesAndWelfareAreFair').value,
      fairlyPaid: this.employeeExitInterviewForm.get('fairlyPaid').value,
      personalDevelopmentAndGrowthScope: this.employeeExitInterviewForm.get('personalDevelopmentAndGrowthScope').value,
      workingRelationshipWithManager: this.employeeExitInterviewForm.get('workingRelationshipWithManager').value,
      workingRelationshipWithCoworkers: this.employeeExitInterviewForm.get('workingRelationshipWithCoworkers').value,
      trainingsMetExpectations: this.employeeExitInterviewForm.get('trainingsMetExpectations').value,
      workingCOnditionMetExpectation: this.employeeExitInterviewForm.get('workingCOnditionMetExpectation').value,
      companyWorkCulture: this.employeeExitInterviewForm.get('companyWorkCulture').value,
      satisfactionWithJob: this.employeeExitInterviewForm.get('satisfactionWithJob').value,
      commentsOrSuggestions: this.employeeExitInterviewForm.get('commentsOrSuggestions').value,
    }
    if(this.isUpdateInfo === true){
      this.exitProcessService.editEmployeeInterviewForm(details, this.exitInterviewFormID).subscribe({
        next : (value) => {
          this.router.navigate([ this.tenantId + '/dashboard/hr/exit-process' ]);
        },
        error(err) {
          this.openSnackBar('ERROR: ' + err);
        },
        complete() {
          this.openSnackBar('Employee Interview Form Update Successfully Completed!!!');
        }
      });
    }
    else{
      this.exitProcessService.createEmployeeInterviewForm(details, this.resignedUserId).subscribe({
        next : (value) => {
          this.exitInterviewFormID = value[0]['id'];
          this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-process' ]);
        },
        error(err) {
          this.openSnackBar('ERROR: ' + err);
        },
        complete() {
          this.openSnackBar('Employee Interview Form Successfully Completed!!!');
        }
      });
    }
  }

  backToExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-process' ]);
  }

  openExitEmployeeForm(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-employee-form' ], {queryParams: {userId: this.currentUser.id}});
  }

}