import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { HrService } from 'src/app/_services/hr-management/hr.service';

@Component({
  selector: 'app-salary-sheet',
  templateUrl: './salary-sheet.component.html',
  styleUrls: ['./salary-sheet.component.scss']
})
export class SalarySheetComponent implements OnInit {
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
  tenantId: string;
  currentUser: any;
  selectedFile: File;
  deducationColumns: Array<any> = [];  
  form: FormGroup;
  sampleGridFrameworkComponents: any;
  gridLength = 0;
  payslipForm: any;
  payslipMonth: any;
  payslipYear: any;
  payslipFinancialYear: any;
  public deductions: Array<any> = [];
  educationcess: any;
  updatedDeductionss: any [];
  updatedDeductions: any [];

  constructor(
    private HrService: HrService,
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService,
    private allocationService: AllocationService,
  ) { 
    this.payslipForm = new FormGroup({
      employeeId: new FormControl(''),
      employeeName: new FormControl(''),
      payslipMonth: new FormControl(''),
      payslipYear: new FormControl(''),
      payslipNo: new FormControl(''),
      payslipFinancialYear: new FormControl(''),
      localId: new FormControl(''),
    });
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
  this.columnDefs = this.createColumnDefs();  

  this.sampleGridFrameworkComponents = {
    actionRowRendererComponent: ActionItemsGridRowRendererComponent
  };
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.getAllUserPaySlip();
  }

  getAllUserPaySlip() {
    this.payslipMonth = this.payslipForm.get('payslipMonth').value;
    this.payslipYear = this.payslipForm.get('payslipYear').value;
    this.payslipFinancialYear = this.payslipForm.get('payslipFinancialYear').value;
    this.HrService.getAllUserPaySlip(this.payslipMonth,this.payslipYear,this.payslipFinancialYear).subscribe((response: any) => {

      if(response.payslips && response.payslips.length > 0) {

        let array = response.payslips;
        let data: Array<any> =[];
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          let deduData = {};

          if(array[index].deductions && array[index].deductions.length > 0) {
            this.educationcess = 0;
            this.updatedDeductions = [];
    
            array[index].deductions.forEach(
              (d : any) => {
                if(d.componentName === 'Education Cess'){
                  this.educationcess = d.componentValue;
                }
              }
            );
    
            if(this.educationcess === 0) {
              for(let i=0; i<array[index].deductions.length; i++){
                this.updatedDeductions.push(array[index].deductions[i]); 
              }
            }
            else{
              for(let i=0; i<array[index].deductions.length; i++){
                if(array[index].deductions[i].componentName === 'TDS'){
                  array[index].deductions[i].componentValue= array[index].deductions[i].componentValue + this.educationcess;
                  this.updatedDeductions.push(array[index].deductions[i]);
                }
                else if(array[index].deductions[i].componentName === 'Education Cess'){
                  // ignore this
                }
                else{
                  this.updatedDeductions.push(array[index].deductions[i]);
                }
              }
            }
            this.updatedDeductions.forEach(element => {
              deduData[element.componentName] = element.componentValue
            });
          }

          this.deducationColumns.push(
           {headerName: 'TDS', field: 'TDS', columnGroupShow: 'open', filter: 'agNumberColumnFilter', }
          )
          let payableData = {};
          if(array[index].payables && array[index].payables.length > 0) {

            array[index].payables.forEach(element => {
              payableData[element.componentName] = element.componentValue
            });
          }
          let obj = {
            employeeId: array[index].employeeId,
            employeeName: array[index].employeeName,
            bankAccontNo: array[index].bankAccontNo,
            bankName: array[index].bankName,
            panNo: array[index].panNo,
            noOfDays: array[index].noOfDays,
            noOfPayableDays: array[index].noOfPayableDays,
            totalPayable:array[index].totalPayable,
            totalDeduction:array[index].totalDeduction,
            netPayable:array[index].netPayable,
            month:array[index].month,
            year:array[index].year,
            localId:array[index].localId,
            joiningDate: array[index].joiningDate,
            adhaarNo: array[index].adhaarNo,
            ifscCode: array[index].ifscCode,
            currentDesignation: array[index].currentDesignation,
          }
          let finalObject = Object.assign(obj, deduData, payableData);
          data.push(finalObject);
        }
        this.rowData = data;
      }
     
    });
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  
   onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  } 

    // create column definitions  
    private createColumnDefs() {
      return [
      {
        headerName: 'Employee Id',
        field: 'localId',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Employee Name',
        field: 'employeeName',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Designation',
        field: 'currentDesignation',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'joining Date',
        field: 'joiningDate',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Adhaar No',
        field: 'adhaarNo',
        filter: true,
        editable: false,
        sortable: true,
        
      },
      {
        headerName: 'Bank AccontNo',
        field: 'bankAccontNo',
        filter: true,
        editable: false,
        sortable: true,
        

      },
      {
        headerName: 'Bank Name',
        field: 'bankName',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'IFSC Code',
        field: 'ifscCode',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Pan No',
        field: 'panNo',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,

      },
      {
        headerName: 'Uan No',
        field: 'uanNo',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,

      },
      {
        headerName: 'No Of Days',
        field: 'noOfPayableDays',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Month',
        field: 'month',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
     
     
      {
        headerName: 'Payable',
        children: [
          { field: 'totalPayable' },
          {headerName: 'Basic + DA', field: 'Basic + DA', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
          {headerName: 'HRA', field: 'HRA', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
          {headerName: 'Other Allowance', field: 'Other Allowance', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
          {headerName: 'Performance Pay', field: 'Performance Pay', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
          {headerName: 'Variable Allowance', field: 'variableAllowance', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
          {headerName: 'Advance Salary', field: 'Advance Salary', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
          
      ]
        
      },
      {
        headerName: 'Deduction',
        
        children: [
          {field: 'totalDeduction'},
            {headerName: 'TDS', field: 'TDS', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
            // {headerName: 'Education Cess', field: 'Education Cess', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
            {headerName: 'PF', field: 'PF', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
            {headerName: 'PT', field: 'PT', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
            {headerName: 'ESIC', field: 'ESIC', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
            {headerName: 'LWF', field: 'Labour Welfare Fund', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
            {headerName: 'Other Deduction', field: 'Other Deduction', columnGroupShow: 'open', filter: 'agNumberColumnFilter', },
        ],
      },
      {
        headerName: 'Net Payable',
        field: 'netPayable',
        filter: true,
        editable: false,
        sortable: true,
        

      },
      {
        headerName: 'Month',
        field: 'month',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Year',
        field: 'year',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      }]
    }
  
}
