import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TaxmetadataService } from '../../../../_services/taxmetadata/taxmetadata.service';
import { error } from 'console';


@Component({
  selector: 'app-taxmeta-form',
  templateUrl: './taxmeta-form.component.html',
  styleUrls: ['./taxmeta-form.component.scss']
})
export class TaxmetaFormComponent implements OnInit {
  
  jsonData: any;
  // row data and column definitions 
  taxmetadataform 
  rowData: any;
  form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  tenantId: string;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  taxId: any;

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private taxmetadataService :TaxmetadataService
  ) {
    this.taxmetadataform = this.formBuilder.group({
      id: new FormControl(''),
      financialYear: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      regimeNo: new FormControl(''),
      componentName: new FormControl('', Validators.required),
      componentUpperLimitValue: new FormControl(''),
      componentValue: new FormControl(''),
      percent:  new FormControl(''),
    })
   }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.taxId = this.route.snapshot.paramMap.get('id');

    if (this.taxId) this.getTaxmetaDataById(this.taxId)
    
  }

  // getter for easy access to form fields
  get f() {
    return this.taxmetadataform.controls;
  }

  getTaxmetaDataById(id){
    this.taxmetadataService.getTaxmetaDataById(id).subscribe((response) => {
      if(response) {
        // this.taxmetadataform.patchValue({
        //   //id: this.taxmetadataform.get('id').value,
        //   financialYear: response.financialYear,
        //   country: response.country,
        //   regimeNo: response.regimeNo,
        //   componentName: response.componentName,
        //   componentUpperLimitValue: response.componentUpperLimitValue,
        //   componentValue: response.componentValue,
        //   percent: response.percent,
        // });
        this.taxmetadataform.patchValue(response);
      }
    }, (error) =>{
      console.log('ERROR:' + error);
    });
  }

  getTaxmetaData(){
    this.taxmetadataService.getAlltaxMetadata().subscribe((response: any) => {
      this.rowData = response.result; 
      console.log("this.rowData:", this.rowData);
    });
    
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  createTaxMetadata() {
    //let details = JSON.stringify(this.taxmetadataform.value);
    let details = {
      financialYear: this.taxmetadataform.get('financialYear').value,
      country: this.taxmetadataform.get('country').value,
      regimeNo: this.taxmetadataform.get('regimeNo').value,
      componentName: this.taxmetadataform.get('componentName').value,
      componentUpperLimitValue: this.taxmetadataform.get('componentUpperLimitValue').value,
      componentValue: this.taxmetadataform.get('componentValue').value,
      percent: this.taxmetadataform.get('percent').value,
    }
    this.taxmetadataService.createTaxMetadata(details).subscribe((response: any) => {
      console.log("response: ", response);
      // update grid after creating new contact & close the modal
      this.backToMetaData();
    });
  }


  backToMetaData() {
    this.router.navigate([this.tenantId + '/dashboard/finance/tax']);
  }

  updateTaxMetadata() {
    let details = {
      //id: this.taxmetadataform.get('id').value,
      financialYear: this.taxmetadataform.get('financialYear').value,
      country: this.taxmetadataform.get('country').value,
      regimeNo: this.taxmetadataform.get('regimeNo').value,
      componentName: this.taxmetadataform.get('componentName').value,
      componentUpperLimitValue: this.taxmetadataform.get('componentUpperLimitValue').value,
      componentValue: this.taxmetadataform.get('componentValue').value,
      percent: this.taxmetadataform.get('percent').value,
    }
    this.taxmetadataService.updatetaxMetadata(details, this.taxId).subscribe(res => {
      this.getTaxmetaData();
      this.selectedCheckboxData = [];
      this.backToMetaData();
    })
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
      else this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
    }
  }

  deleteTaxMetadata() {
    this.taxmetadataService.deletetaxMetadata(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getTaxmetaData();
      this.selectedCheckboxData = []
      $('#closeModalDeleteTaxMetaData').click()
    });
  }
}
