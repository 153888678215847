<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
              <div class="d-flex align-items-center">
                <div class="edit">
                  <button routerLink="/{{tenantId}}/dashboard/opportunities/quotes" [routerLinkActive]="['active']">
                    <span><i class="ri-arrow-left-line"></i></span>
                  </button>
                </div>
                <h3 class="px-3">{{quoteObject.quoteName}}</h3>
              </div>
            </div>
            <div class="col-sm-2 d-flex justify-content-end">
              <div class="input">
                <input type="text" placeholder="Search anything">
              </div>
            </div>
          </div>
          <div class="canvas-data">
            <div class="collapse-box">
              <div class="heading d-flex align-items-center">
                <h5>General Details</h5>
                <div class="edit mx-2">
                  <button type="button" data-toggle="collapse" data-target="#general-details">
                    <span>
                      <i class="ri-arrow-up-s-line"></i>
                    </span>
                  </button>
                </div>
              </div>
              <div class="collapse show" id="general-details">
                <div class="collapse-box m-0 p-0 border-0">
                  <form [formGroup]="quoteForm">
                    <div class="row">
                      <div class="col-sm-3">
                        <div class="box">
                          <div class="table-data">
                            <p>Account</p>
                            <input type="text" formControlName="account">
                          </div>
                          <mat-error *ngIf="f.account.invalid  && f.account.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.account.errors.required">Account is required</mat-error>
                          </mat-error>  
                        </div>
                        <div class="box">
                          <div class="table-data">
                            <p>Responsible Unit</p>
                            <input type="text" formControlName="responsibleUnit">
                          </div>
                          <mat-error *ngIf="f.responsibleUnit.invalid  && f.responsibleUnit.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.responsibleUnit.errors.required">Responsible Unit is required</mat-error>
                          </mat-error> 
                        </div>
                        <div class="box">
                          <div class="table-data active">
                            <p>End Date</p>
                            <input type="date" formControlName="endDate">
                          </div>
                          <mat-error *ngIf="f.endDate.invalid  && f.endDate.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.endDate.errors.required">End Date is required</mat-error>
                          </mat-error> 
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="box">
                          <div class="table-data">
                            <p>Project</p>
                            <input type="text" formControlName="project">
                          </div>
                          <mat-error *ngIf="f.project.invalid  && f.project.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.project.errors.required">Project is required</mat-error>
                          </mat-error> 
                        </div>
                        <div class="box">
                          <div class="table-data">
                            <p>Start Date</p>
                            <input type="date" formControlName="startDate">
                          </div>
                          <mat-error *ngIf="f.startDate.invalid  && f.startDate.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                          </mat-error> 
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="box">
                          <div class="table-data">
                            <p>Project Manager</p>
                            <input type="text" formControlName="projectManager">
                          </div>
                          <mat-error *ngIf="f.projectManager.invalid  && f.projectManager.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.projectManager.errors.required">Project Manager is required</mat-error>
                          </mat-error> 
                        </div>
                        <div class="box">
                          <div class="table-data">
                            <p>Support Unit</p>
                            <input type="text" formControlName="supportUnit">
                          </div>
                          <mat-error *ngIf="f.supportUnit.invalid  && f.supportUnit.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.supportUnit.errors.required">Support Unit is required</mat-error>
                          </mat-error> 
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <div class="box">
                          <div class="table-data">
                            <p>Key Account Manager</p>
                            <input type="text" formControlName="keyAccountManager">
                          </div>
                          <mat-error *ngIf="f.keyAccountManager.invalid  && f.keyAccountManager.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.keyAccountManager.errors.required">Key Account Manager is required</mat-error>
                          </mat-error> 
                        </div>
                        <div class="box">
                          <div class="table-data">
                            <p>Project Number</p>
                            <input type="text" formControlName="projectNumber">
                          </div>
                          <mat-error *ngIf="f.projectNumber.invalid  && f.projectNumber.touched " class="alert alert-danger">
                            <mat-error *ngIf="f.projectNumber.errors.required">Project Number is required</mat-error>
                          </mat-error> 
                        </div>
                        <div class="box">
                          <button class="btn btn-sm" (click)="updateQuoteGeneralDetails()" [disabled]="!quoteForm.valid">Update</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="inner-sidebar" [ngClass]="sidebarOpen ? 'active' : '' ">
              <div class="sidebar-item">
                <button (click)="openCloseInnerSidebar()" class="btn btn-icon inner-sidebar-closer"><i class="ri-menu-line"></i></button>
                <ul class="nav nav-tabs border-0">
                  <li><a data-toggle="tab" href="#quote-parameters" class="active">Quote Parameters</a></li>
                  <li><a data-toggle="tab" href="#manpower-planning">Manpower Planning</a></li>
                  <li><a data-toggle="tab" href="#other-direct-cost">Other Direct Cost</a></li>
                  <li><a data-toggle="tab" href="#task-sost-summary">Task Cost Summary</a></li>
                  <li><a data-toggle="tab" href="#business-unit-summary">Business Unit Summary</a></li>
                  <li><a data-toggle="tab" href="#cost-distribution">Other Direct Cost Distribution</a></li>
                  <li><a data-toggle="tab" href="#manpower-distribution">Manpower Distribution(Engineering Level)</a></li>
                  <li><a data-toggle="tab" href="#quote-dashboard">Dashboard</a></li>
                  <li><a data-toggle="tab" href="#costcenter-level-rates">CostCenter Level Rates</a></li>
                  <li><a data-toggle="tab" href="#software-rates">Software Rates</a></li>
                </ul>
              </div>
              <div class="sidebar-details tab-content">
                <div class="tab-pane fade show active" id="quote-parameters">
                  <div class="heading">
                    <h2>Quote Parameters</h2>
                  </div>
                  <div class="data" *ngIf="this.quoteparametersapi != 'null';">
                    <form [formGroup]="quoteParametersDetailsForm">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="box">
                            <p>Work Hr./Month</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="workHourPerMonth">
                            <!-- <mat-error *ngIf="f.workHourPerMonth.touched && f.workHourPerMonth.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.workHourPerMonth.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>Customer Target</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="customerTarget">
                            <!-- <mat-error *ngIf="f.customerTarget.touched && f.customerTarget.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.customerTarget.errors.required">Customer Target is required</mat-error>
                          </mat-error> -->
                          </div>
                          <div class="box">
                            <p>Handling Fees</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="subcontractSurcharge">
                            <!-- <mat-error *ngIf="f.subcontractSurcharge.touched && f.subcontractSurcharge.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.subcontractSurcharge.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>Subcontract Discount</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="subcontractDiscount">
                            <!-- <mat-error *ngIf="f.subcontractDiscount.touched && f.subcontractDiscount.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.subcontractDiscount.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>SGST %</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="sgst">
                            <!-- <mat-error *ngIf="f.sgst.touched && f.sgst.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.sgst.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="box">
                            <p>SubContract Work Hr. / Month</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="subContractorWorkHourPerMonth">
                            <!-- <mat-error *ngIf="f.subContractorWorkHourPerMonth.touched && f.subContractorWorkHourPerMonth.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.subContractorWorkHourPerMonth.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>3rd Party Surcharge</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="thirdPartySurcharge">
                            <!-- <mat-error *ngIf="f.thirdPartySurcharge.touched && f.thirdPartySurcharge.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.thirdPartySurcharge.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>Expected EBIT</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="expectedEbit">
                            <!-- <mat-error *ngIf="f.expectedEbit.touched && f.expectedEbit.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.expectedEbit.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>Risk Margin</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="riskMargin">
                            <!-- <mat-error *ngIf="f.riskMargin.touched && f.riskMargin.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.riskMargin.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>CGST</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="cgst">
                            <!-- <mat-error *ngIf="f.cgst.touched && f.cgst.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.cgst.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="box">
                            <p>3rd Party Work Hr./Month</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="thirdPartyWorkHourPerMonth">
                            <!-- <mat-error *ngIf="f.thirdPartyWorkHourPerMonth.touched && f.thirdPartyWorkHourPerMonth.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.thirdPartyWorkHourPerMonth.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>Inflation Rate</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="inflationRate">
                            <!-- <mat-error *ngIf="f.inflationRate.touched && f.inflationRate.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.inflationRate.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>3rd Party Discount</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="thirdPartyDiscount">
                            <!-- <mat-error *ngIf="f.thirdPartyDiscount.touched && f.thirdPartyDiscount.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.thirdPartyDiscount.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <p>VAT %</p>
                            <!-- <input type="text" value="160" placeholder="160" > -->
                            <input type="text" formControlName="vat">
                            <!-- <mat-error *ngIf="f.vat.touched && f.vat.invalid" class="alert alert-danger">
                              <mat-error *ngIf="f.vat.errors.required">This Field is required</mat-error>
                            </mat-error> -->
                          </div>
                          <div class="box">
                            <a class="btn" (click)="updateQuoteParametersDetails()" [disabled]="!quoteParametersDetailsForm.valid">Update</a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="data" *ngIf="this.quoteparametersapi == 'null';">
                    <form [formGroup]="defaultParametersDetailsForm">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="box">
                            <p>Work Hr./Month</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardMonthlyWorkingHours">
                          </div>
                          <div class="box">
                            <p>Customer Target</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardCustomerTarget">
                          </div>
                          <div class="box">
                            <p>Handling Fees</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardHandlingSurcharge">
                          </div>
                          <div class="box">
                            <p>Subcontract Discount</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="subcontractDiscount">
                          </div>
                          <div class="box">
                            <p>SGST %</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardSgst">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="box">
                            <p>SubContract Work Hr. / Month</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardSubContractorWorkHourPerMonth">
                          </div>
                          <div class="box">
                            <p>3rd Party Surcharge</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardThirdPartySurcharge">
                          </div>
                          <div class="box">
                            <p>Expected EBIT</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardEbit">
                          </div>
                          <div class="box">
                            <p>Risk Margin</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardRiskMargin">
                          </div>
                          <div class="box">
                            <p>CGST</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardCgst">
                          </div>
                        </div>
                        <div class="col-sm-4">
                          <div class="box">
                            <p>3rd Party Work Hr./Month</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardThirdPartyWorkHourPerMonth">
                          </div>
                          <div class="box">
                            <p>Inflation Rate</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="annualInflationRate">
                          </div>
                          <div class="box">
                            <p>3rd Party Discount</p>
                            <!-- <input type="text" value="160" placeholder="160"> -->
                            <input type="text" formControlName="standardThirdPartyDiscount">
                          </div>
                          <div class="box">
                            <p>VAT %</p>
                            <!-- <input type="text" value="160" placeholder="160" > -->
                            <input type="text" formControlName="standardvat">
                          </div>
                          <div class="box">
                            <a class="btn" (click)="updateQuoteParametersDetails()">Update</a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="tab-pane fade" id="manpower-planning">
                  <app-manpower-planning (manpowerPlanningEvent)="updateManpowerPlanningEvent($event)"></app-manpower-planning>
                </div>
                <div class="tab-pane fade" id="other-direct-cost">
                  <app-odc></app-odc>
                </div>
                <div class="tab-pane fade" id="task-sost-summary">
                  <app-task-cost-summary [manpowerPlanningData]="manpowerPlanningData"></app-task-cost-summary>
                </div>
                <div class="tab-pane fade" id="business-unit-summary">
                  <app-business-unit-summary [manpowerPlanningData]="manpowerPlanningData"></app-business-unit-summary>
                </div>
                <div class="tab-pane fade" id="cost-distribution">
                  <app-odc-distribution></app-odc-distribution>
                </div>
                <div class="tab-pane fade" id="manpower-distribution">
                  <app-manpower-distribution></app-manpower-distribution>
                </div>
                <div class="tab-pane fade" id="quote-dashboard">
                  <app-quotes-dashboard opportunityId={{opportunityObject.id}} quoteDetailId={{quoteObject.id}}></app-quotes-dashboard>
                </div>
                <div class="tab-pane fade" id="costcenter-level-rates">
                  <app-costcenter-level-rates></app-costcenter-level-rates>
                </div>
                <div class="tab-pane fade" id="software-rates">
                  <app-software-rates></app-software-rates>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </article>
</main>

<!-- Create new Opportunity -->
<div class="modal fade" id="opportunity" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form action="">
        <div class="modal-header">
          <h5 class="modal-title">Create new Opportunity</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Opportunity Name</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Account Name</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Start Date</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">End Date</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Submission Deadline</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Gross Price</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Probabilty</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input type="text" required>
              <label class="floating-label">Status</label>
              <i class="ri-shield-user-line"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary">Create Opportunity</button>
        </div>
      </form>
    </div>
  </div>
</div>

<script>
  $(function () {
    $(".inner-sidebar-closer").click(function () {
      $('.inner-sidebar').toggleClass("active");
    });
  });
</script>
