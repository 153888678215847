import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CapacityPlanningService {

  constructor(private http: HttpClient) { }

  public getQuoteProjectData() {
    return this.http.get<any>(`${environment.apiUrl}/quotes`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public createCapacityPlanningData(data: Object) {
    return this.http.post<any>(`${environment.apiUrl}/capacityPlanning`, data
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  //Get Capacity Planning data
  public getCapacityPlanningData() {
    return this.http.get<any>(`${environment.apiUrl}/capacityPlanning`
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  //Get Individual Capacity Planning details
  public getCapacityPlanningDatabyID(id:number) {
    return this.http.get<any>(`${environment.apiUrl}/capacityPlanning/${id}`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  //Get Capacity Planning Graph details
  public getCapacityPlanningGraphDatabyID(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/capacityPlanning/${id}/graph`,
    )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  
  //Update the Capacity Planning
  public updateCapacityPlanningData(data: Object, id: number) {
  
    return this.http.put<any>(`${environment.apiUrl}/capacityPlanning/${id}`, data)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getSpecificCPData(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/capacityPlanning/${id}`).pipe(retry(2), catchError(this.handleError<any>()));
  }

  public updateCapacityPlanning(cp: Object, id: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/capacityPlanning/${id}`, cp).pipe(catchError(this.handleError<any>()));
  }

  //Delete Opportunity by Id Delete
  deleteCPbyID(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/capacityPlanning/${id}`).pipe(catchError(this.handleError<any>()));
  }
  private handleError<T>() {
    return (error: any): Observable<boolean> => {
      return of(false);
    };
  }
}
