<div class="admin-profile py-3 border-bottom">
  <div class="canvas-header row justify-content-between align-items-center">
    <form [formGroup] = "employeeForm">
      <div class="row p-0">
        <div class="col-sm-12 p-0">
          <div class="canvas-data px-0">
            <div class="row m-0">
              <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2" *ngIf="this.isManager || this.isAdmin">
                <div class="input">
                  <div class="input-item"> 
                    <input
                      list="taskOwnerDatalist"
                      formControlName="employeeId"
                      placeholder="Select Employee Name"
                      value=""
                      onfocus="this.value=''"
                      onchange="this.blur();"
                    />
                    <datalist id="taskOwnerDatalist">
                      <option [value]="item" *ngFor="let item of userDropdownList"></option>
                    </datalist>
                    <label class="floating-label">Employee</label>
                    <i class="ri-user-line"></i>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 text-end text-right" *ngIf="this.isManager || this.isAdmin">
                <button type="button" class="btn" (click)="getEmployeeProfile()">
                  <div>Submit</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form> 
  </div>
  <div class="row align-items-end">
    <div class="profile-cover">
      <img
        src="./assets/svg/profile.jpg"
        alt=""
      />
    </div>
    <div class="col-sm-2 p-3">
      <div class="profile-items">
        <div class="avtar">
          <img class="w-100" [src]="retrievedImage" alt="profile_pic" />
        </div>
        <div class="avtar-update">
          <i class="ri-pencil-line"><input type="file" (change)="onFileChanged($event)"/></i>
        </div>
      </div>
    </div>
    <div class="col-sm-10">
      <div style="display: flex">
        <div class="profile-page-title">
          <h2>Personal Information</h2>
        </div>
        <div class="edit" style="margin-left: 15px; margin-top: 5px">
          <button
            type="button"
            (click)="editOpenModal()"
            data-bs-toggle="modal"
            data-bs-target="#profileUpdate"
            class="btn-edit-icon"
          >
            <i class="ri-pencil-line"></i>
          </button>
        </div>
      </div>
      <div class="row inner-details">
        <div class="col-sm-3">
          <div class="box">
            <span>Name</span>
            <p>{{ profileData.firstName }} {{ profileData.lastName }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Job role</span>
            <p>{{ profileData.employeeCategory }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Date of birth</span>
            <p>{{ profileData.birthDate }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Email Id</span>
            <p>{{ profileData.emailId }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Employment Information</h2>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row inner-details">
        <div class="col-sm-3">
          <div class="box">
            <span>Department</span>
            <p>{{ profileData.department }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Phone No</span>
            <p>{{ profileData.phoneNo }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>City</span>
            <p>{{ profileData.location }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Supervison Name</span>
            <p>{{ profileData.supervisonName }}</p>
          </div>
        </div>
      </div>
      <div class="row inner-details mt-3">
        <div class="col-sm-3">
          <div class="box">
            <span>JoinDate</span>
            <p>{{ profileData.joinDate }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Current Designation</span>
            <p>{{ profileData.currentDesignation }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Previous Designation</span>
            <p>{{ profileData.previousDesignation }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Deputed At</span>
            <p>{{ profileData.deputedAt }}</p>
          </div>
        </div>
      </div>
      <div class="row inner-details mt-3">
        <div class="col-sm-3">
          <div class="box">
            <span>Current Experience</span>
            <p>{{ profileData.currentExperience }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Total Experience</span>
            <p>{{ profileData.totalExperience }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Employee Id</span>
            <p>{{ profileData.localId }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Blood Group</span>
            <p>{{ profileData.bloodGroup }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Time Management</h2>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row inner-details">
        <div class="col-sm-3">
          <div class="box">
            <span>In Time</span>
            <p>{{ timeSheetData.inTime }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Out Time</span>
            <p>{{ timeSheetData.outTime }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Total Time</span>
            <p>{{ timeSheetData.workTimeInHours }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Action</span>
            <p>
              <a (click)="backtoAttendance()" class="link">Go to timesheet</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Organisation Information</h2>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row inner-details">
        <div class="col-sm-3">
          <div class="box">
            <span>Adhaar No</span>
            <p>{{ profileData.adhaarNo }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Bank Name</span>
            <p>{{ profileData.bankName }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Bank Account No</span>
            <p>{{ profileData.bankAccountNo }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Pan No</span>
            <p>{{ profileData.panNo }}</p>
          </div>
        </div>
      </div>
      <div class="row inner-details mt-3">
        <div class="col-sm-3">
          <div class="box">
            <span>UAN No</span>
            <p>{{ profileData.uinNo }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>IFSC Code</span>
            <p>{{ profileData.bankIfscCode }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Gender</span>
            <p>{{ profileData.gender }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Work Experience</h2>
      </div>
      <div class="text-center">
        <button
          _ngcontent-mjv-c22=""
          type="button"
          (click)="visibleForm()"
          class="btn btn-icon"
        >
          <i _ngcontent-mjv-c22="" class="ri-add-line"></i>
        </button>
      </div>
    </div>
    <div class="col-sm-10 inner-details">
      <form [formGroup]="workExperienceForm">
        <table class="table mobile-responsive-table" >
          <thead class="h-90" style="border: 0; border-radius: 8px">
            <tr>
              <th style="min-width: 160px">Company Name</th>
              <th style="min-width: 150px">Job Title</th>
              <th style="min-width: 140px">Start Date</th>
              <th style="min-width: 140px">End Date</th>
              <th style="min-width: 160px">Job Description</th>
              <th style="min-width: 150px">Relevant</th>
              <th style="min-width: 160px">Experience</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isWorkExperienceForm">
              <td>
                <div>
                  <input
                    type="text"
                    formControlName="companyName"
                    placeholder="Company Name*"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td>
                <div>
                  <input
                    type="text"
                    formControlName="jobTitle"
                    placeholder="Job Title*"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td>
                <div>
                  <input
                    type="date"
                    formControlName="stardDate"
                    placeholder="Start Date*"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td>
                <div>
                  <div *ngIf="disableEndDate === true">
                    <input
                      type="date"
                      formControlName="endDate"
                      placeholder="End Date"
                      
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </div>
                  <div *ngIf="disableEndDate === false">
                    <input
                      type="date"
                      formControlName="endDate"
                      placeholder="End Date*"
                      required
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      value="checked"
                      (click)="currentJob($event)"
                    />
                    Current Job?
                  </div>
                </div>
              </td>
              <td>
                <div>
                  <input
                    type="text"
                    formControlName="jobDescription"
                    placeholder="Job Description"
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td>
                <div>
                  <!-- <input type="text" formControlName="relevant" style="border: 0;background: #f1f3f6;border-radius: 4px;"> -->
                  <select
                    formControlName="relevant"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  >
                    <option value="" disabled selected>
                      Select Relevance*
                    </option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </div>
              </td>
              <td>
                <div>
                  <input
                    type="text"
                    formControlName="experience"
                    placeholder="Experience"
                    disabled
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td style="max-width: 100px">
                <div class="row">
                  <div class="d-flex gap-2" style="width: 100px">
                    <button
                      *ngIf="formMode === 1"
                      [ngClass]="requiredSave() ? 'btn-invalid-action' : ''"
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="saveExperience()"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-save-line"></i></span>
                    </button>
                    <button
                      *ngIf="formMode === 2"
                      [ngClass]="requiredSave() ? 'btn-invalid-action' : ''"
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="updateWorkExperience()"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-file-edit-line"></i></span>
                    </button>
                    <button
                      type="button"
                      (click)="cancelSave()"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-close-line"></i></span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>

            <tr *ngFor="let item of workExperienceData">
              <td>
                {{ item.companyName }}
              </td>
              <td>
                {{ item.jobTitle }}
              </td>
              <td>
                {{ item.stardDate }}
              </td>
              <td>
                {{ item.endDate }}
              </td>
              <td>
                {{ item.jobDescription }}
              </td>
              <td>
                {{ item.relevant }}
              </td>
              <td>
                {{ item.experience }}
              </td>
              <td style="max-width: 100px">
                <div class="d-flex gap-2" style="width: 100px">
                  <button
                    [ngClass]="formMode !== 0 ? 'd-none' : ''"
                    _ngcontent-mjv-c22=""
                    type="button"
                    (click)="editWorkExperienceById(item.id)"
                    class="btn btn-icon"
                  >
                    <span><i class="ri-pencil-line"></i></span>
                  </button>
                  <button
                    [ngClass]="formMode !== 0 ? 'd-none' : ''"
                    _ngcontent-mjv-c22=""
                    type="button"
                    (click)="deleteWorkExperienceById(item.id)"
                    class="btn btn-icon"
                  >
                    <span><i class="ri-delete-bin-6-line"></i></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Skills</h2>
      </div>
      <div class="text-center">
        <button
          _ngcontent-mjv-c22=""
          type="button"
          (click)="visibleSkillsForm()"
          class="btn btn-icon"
        >
          <i _ngcontent-mjv-c22="" class="ri-add-line"></i>
        </button>
      </div>
    </div>
    <div class="col-sm-10 inner-details">
      <form [formGroup]="skillsForm">
        <table class="table">
          <thead class="h-90" style="border: 0; border-radius: 8px">
            <tr>
              <th>Skill Name</th>
              <th>Skill Expertise</th>
              <th>Skill Experience</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isSkillsForm">
              <td>
                <div class="col-sm-3">
                  <input
                    type="text"
                    formControlName="skillName"
                    placeholder="Skill Name*"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td>
                <div class="col-sm-3">
                  <select
                    formControlName="skillExpertise"
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                    required
                  >
                    <option value="" disabled selected>
                      Select Expertise*
                    </option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediate">Intermediate</option>
                    <option value="Advanced">Advanced</option>
                    <option value="Expert">Expert</option>
                  </select>
                </div>
              </td>
              <td>
                <div class="col-sm-3">
                  <input
                    type="text"
                    formControlName="skillExperience"
                    placeholder="Experience*"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </div>
              </td>
              <td style="max-width: 100px">
                <div class="row">
                  <div class="d-flex gap-2" style="width: 100px">
                    <button
                      *ngIf="formMode === 1"
                      [ngClass]="
                        requiredSaveSkill() ? 'btn-invalid-action' : ''
                      "
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="saveSkill()"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-save-line"></i></span>
                    </button>
                    <button
                      *ngIf="formMode === 2"
                      [ngClass]="
                        requiredSaveSkill() ? 'btn-invalid-action' : ''
                      "
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="updateSkill()"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-file-edit-line"></i></span>
                    </button>
                    <button
                      type="button"
                      (click)="cancelSave()"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-close-line"></i></span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>

            <tr *ngFor="let item of skillsData">
              <td>
                {{ item.skillName }}
              </td>
              <td>
                {{ item.skillExpertise }}
              </td>
              <td>
                {{ item.skillExperience }}
              </td>
              <td style="max-width: 100px">
                <div class="d-flex gap-2" style="width: 100px">
                  <button
                    [ngClass]="formMode !== 0 ? 'd-none' : ''"
                    _ngcontent-mjv-c22=""
                    type="button"
                    (click)="editSkillById(item.id)"
                    class="btn btn-icon"
                  >
                    <span><i class="ri-pencil-line"></i></span>
                  </button>
                  <button
                    [ngClass]="formMode !== 0 ? 'd-none' : ''"
                    _ngcontent-mjv-c22=""
                    type="button"
                    (click)="deleteSkillById(item.id)"
                    class="btn btn-icon"
                  >
                    <span><i class="ri-delete-bin-6-line"></i></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Job Relationship</h2>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row inner-details">
        <div class="col-sm-3" *ngIf="superVisor">
          <div class="relationship-items">
            <div *ngIf="superVisor.image != null">
              <img
                class="table-profile"
                src="data:image/jpeg;base64,{{ superVisor.image }}"
                alt="profile_pic"
              />
            </div>
            <div *ngIf="superVisor.image === null">
              <img class="table-profile" src="./assets/user-icon-jpg-29.jpg" />
            </div>
            <div class="box">
              <p>{{ superVisor["fullName"] }}</p>
              <span>{{ superVisor["currentDesignation"] }}</span>
            </div>
          </div>
        </div>
        <div class="col-sm-3" *ngIf="hrName">
          <div class="relationship-items">
            <img class="table-profile" [src]="hrImage" alt="profile_pic" />
            <div class="box">
              <p>{{ hrName }}</p>
              <span>{{ hrDesignation }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Contact & Addresses</h2>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="row inner-details">
        <div class="col-sm-3">
          <div class="box">
            <span>Personal Email</span>
            <p>{{ profileData.alternateEmailId }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Business emaild</span>
            <p>{{ profileData.emailId }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>Emergency ContactNo</span>
            <p>{{ profileData.emergencyContactNo }}</p>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="box">
            <span>As of today - recorded</span>
            <p>{{ timeSheetData.workTimeInHours }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>Emergency Contacts</h2>
      </div>
      <div class="text-center">
        <button
          _ngcontent-mjv-c22=""
          type="button"
          (click)="visibleEmergencyContactsForm()"
          class="btn btn-icon"
        >
          <i _ngcontent-mjv-c22="" class="ri-add-line"></i>
        </button>
      </div>
    </div>
    <div class="col-sm-10">
      <div *ngIf="emergencyContacts.length > 0">
        <span *ngFor="let item of emergencyContacts">
          <div class="row inner-details">
            <div class="row inner-details mt-3">
              <div class="col-sm-2">
                <div class="box">
                  <span>Name</span>
                  <p>{{ item.personName }}</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box">
                  <span>Relationship</span>
                  <p>{{ item.relationWithUser }}</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box">
                  <span>Phone Number</span>
                  <p>{{ item.phoneNumber }}</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box">
                  <span>Dependent</span>
                  <p>{{ item.dependent }}</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box">
                  <span>Email</span>
                  <p>{{ item.email }}</p>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="box">
                  <div class="d-flex gap-2" style="width: 100px">
                    <button
                      [ngClass]="formMode !== 0 ? 'd-none' : ''"
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="editEmergencyContactById(item.id)"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-pencil-line"></i></span>
                    </button>
                    <button
                      [ngClass]="formMode !== 0 ? 'd-none' : ''"
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="deleteEmergencyContactById(item.id)"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-delete-bin-6-line"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </span>
      </div>
      <div *ngIf="isEmergencyContactsForm">
        <form [formGroup]="emergencyContactsForm">
          <div class="row inner-details">
            <div class="col-sm-2">
              <div class="box">
                <span>Name*</span>
                <p>
                  <input
                    type="text"
                    formControlName="personName"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </p>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="box">
                <span>Relationship</span>
                <p>
                  <input
                    type="text"
                    formControlName="relationWithUser"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </p>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="box">
                <span>Phone Number</span>
                <p>
                  <input
                    type="text"
                    formControlName="phoneNumber"
                    required
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </p>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="box">
                <span>Dependent</span>
                <p>
                  <select
                    formControlName="dependent"
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  >
                    <option value="" disabled selected>Select Dependent</option>
                    <option value="false">False</option>
                    <option value="true">True</option>
                  </select>
                </p>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="box">
                <span>Email</span>
                <p>
                  <input
                    type="text"
                    formControlName="email"
                    style="border: 0; background: #f1f3f6; border-radius: 4px"
                  />
                </p>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="d-flex gap-2" style="width: 100px">
                <button
                  *ngIf="formMode === 1"
                  [ngClass]="
                    requiredSaveEmergencyContact() ? 'btn-invalid-action' : ''
                  "
                  _ngcontent-mjv-c22=""
                  type="button"
                  (click)="saveEmergencyContact()"
                  class="btn btn-icon"
                >
                  <span><i class="ri-save-line"></i></span>
                </button>
                <button
                  *ngIf="formMode === 2"
                  [ngClass]="
                    requiredSaveEmergencyContact() ? 'btn-invalid-action' : ''
                  "
                  _ngcontent-mjv-c22=""
                  type="button"
                  (click)="updateEmergencyContact()"
                  class="btn btn-icon"
                >
                  <span><i class="ri-file-edit-line"></i></span>
                </button>
                <button
                  type="button"
                  (click)="cancelSave()"
                  class="btn btn-icon"
                >
                  <span><i class="ri-close-line"></i></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="admin-profile py-3 border-bottom">
  <div class="row my-3 align-items-center">
    <div class="col-sm-2">
      <div class="profile-page-title">
        <h2>User Dependent Contacts</h2>
      </div>
      <div class="text-center">
        <button
          _ngcontent-mjv-c22=""
          type="button"
          (click)="visibleUserDependentsForm()"
          class="btn btn-icon"
        >
          <i _ngcontent-mjv-c22="" class="ri-add-line"></i>
        </button>
      </div>
    </div>
    <div class="col-sm-10">
      <div *ngIf="userDependents.length > 0">
        <span *ngFor="let item of userDependents">
          <div class="row inner-details">
            <div class="row inner-details mt-3">
              <div class="col-sm-3">
                <div class="box">
                  <span>First Name</span>
                  <p>{{ item.firstName }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Last Name</span>
                  <p>{{ item.lastName }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Gender</span>
                  <p>{{ item.gender }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Passport</span>
                  <p>{{ item.passport }}</p>
                </div>
              </div>
            </div>
            <div class="row inner-details mt-3">
              <div class="col-sm-3">
                <div class="box">
                  <span>Date of Birth</span>
                  <p>{{ item.dob }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>National Id</span>
                  <p>{{ item.nationalId }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>resides Out of Country</span>
                  <p>{{ item.residesOutofCountry }}</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <div class="d-flex gap-2" style="width: 100px">
                    <button
                      [ngClass]="formMode !== 0 ? 'd-none' : ''"
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="editUserDependentById(item.id)"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-pencil-line"></i></span>
                    </button>
                    <button
                      [ngClass]="formMode !== 0 ? 'd-none' : ''"
                      _ngcontent-mjv-c22=""
                      type="button"
                      (click)="deleteUserDependentById(item.id)"
                      class="btn btn-icon"
                    >
                      <span><i class="ri-delete-bin-6-line"></i></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </span>
      </div>
      <div *ngIf="isUserDependentsForm">
        <form [formGroup]="userDependentsForm">
          <div class="row inner-details">
            <div class="row inner-details mt-3">
              <div class="col-sm-3">
                <div class="box">
                  <span>First Name*</span>
                  <p>
                    <input
                      type="text"
                      formControlName="firstName"
                      required
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Last Name*</span>
                  <p>
                    <input
                      type="text"
                      formControlName="lastName"
                      required
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Gender</span>
                  <p>
                    <select
                      formControlName="gender"
                      style="
                        border: 0;
                        background: #f1f3f6;
                        border-radius: 4px;
                        width: 90%;
                      "
                    >
                      <option value="" disabled selected>Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Passport</span>
                  <p>
                    <input
                      type="text"
                      formControlName="passport"
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div class="row inner-details mt-3">
              <div class="col-sm-3">
                <div class="box">
                  <span>Date of Birth</span>
                  <p>
                    <input
                      type="date"
                      formControlName="dob"
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>National ID</span>
                  <p>
                    <input
                      type="text"
                      formControlName="nationalID"
                      style="border: 0; background: #f1f3f6; border-radius: 4px"
                    />
                  </p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="box">
                  <span>Resides Out of Country</span>
                  <p>
                    <select
                      formControlName="residesOutofCountry"
                      style="
                        border: 0;
                        background: #f1f3f6;
                        border-radius: 4px;
                        width: 90%;
                      "
                    >
                      <option value="" disabled selected>Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </p>
                </div>
              </div>
              <div class="d-flex gap-2" style="width: 100px">
                <button
                  *ngIf="formMode === 1"
                  [ngClass]="
                    requiredSaveUserDependent() ? 'btn-invalid-action' : ''
                  "
                  _ngcontent-mjv-c22=""
                  type="button"
                  (click)="saveUserDependent()"
                  class="btn btn-icon"
                >
                  <span><i class="ri-save-line"></i></span>
                </button>
                <button
                  *ngIf="formMode === 2"
                  [ngClass]="
                    requiredSaveUserDependent() ? 'btn-invalid-action' : ''
                  "
                  _ngcontent-mjv-c22=""
                  type="button"
                  (click)="updateUserDependent()"
                  class="btn btn-icon"
                >
                  <span><i class="ri-file-edit-line"></i></span>
                </button>
                <button
                  type="button"
                  (click)="cancelSave()"
                  class="btn btn-icon"
                >
                  <span><i class="ri-close-line"></i></span>
                </button>
              </div>
            </div>
            <hr />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade bd-example-modal-lg" id="profileUpdate">
  <div class="modal-dialog modal-lg">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="profileForm">
        <div class="modal-header">
          <h5 class="modal-title">User Profile</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">
              <h5 class="modal-title mb-3 h5">Personal Info</h5>
              <div class="input">
                <div class="input-item">
                  <input formControlName="firstName" type="text" required disabled/>
                  <label for="firstName" class="floating-label-special">First Name*</label>
                  <i class="ri-user-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="lastName" type="text" required disabled/>
                  <label for="lastName" class="floating-label-special">Last Name*</label>
                  <i class="ri-user-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="employeeCategory" type="text" />
                  <label for="employeeCategory" class="floating-label-special">Job Role*</label>
                  <i class="ri-user-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="birthDate" type="date" disabled/>
                  <label for="birthDate" class="floating-label-special">Birth Date*</label>
                  <i class="ri-file-user-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="emailId" type="text" disabled/>
                  <label for="emailId" class="floating-label-special">Email Id*</label>
                  <i class="ri-mail-unread-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <select formControlName="gender" required>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  <label class="floating-label floating-label-special">Gender*</label>
                  <i class="ri-map-pin-line"></i>
                </div>
              </div>

              <h5 class="modal-title mb-3 h5">Organisation Information</h5>
              <div class="input">
                <div class="input-item">
                  <input formControlName="adhaarNo" type="text" />
                  <label for="adhaarNo" class="floating-label">Adhaar No.*</label>
                  <i class="ri-bank-card-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="bankName" type="text" />
                  <label for="bankName" class="floating-label">Bank Name*</label>
                  <i class="ri-bank-card-2-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="bankAccountNo" type="text" />
                  <label for="bankAccountNo" class="floating-label">Bank Account No.*</label>
                  <i class="ri-bank-card-2-line"></i>
                </div>
              </div>

            </div>
            <div class="col-sm-6">

              <div class="input">
                <div class="input-item">
                  <input formControlName="bankIfscCode" type="text" />
                  <label for="bankIfscCode" class="floating-label">IFSC Code*</label>
                  <i class="ri-bank-card-line"></i>
                </div>
              </div>
              <div class="input">
                <div class="input-item">
                  <input formControlName="panNo" type="text" />
                  <label for="panNo" class="floating-label">PAN No.*</label>
                  <i class="ri-bank-card-line"></i>
                </div>
              </div>
              <div class="input">
                <div class="input-item">
                  <input formControlName="uinNo" type="text" />
                  <label for="uinNo" class="floating-label">UAN No.*</label>
                  <i class="ri-bank-card-line"></i>
                </div>
              </div>

              <h5 class="modal-title mb-3 h5">Employment Information</h5>

              <div class="input">
                <div class="input-item">
                  <input formControlName="phoneNo" type="text" disabled/>
                  <label for="phoneNo" class="floating-label-special">Phone No*</label>
                  <i class="ri-phone-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <select formControlName="bloodGroup">
                    <option value="" disabled selected>Select Blood Group</option>
                    <option value="A positive (A+)">A positive (A+)</option>
                    <option value="A negative (A-)">A negative (A-)</option>
                    <option value="B positive (B+)">B positive (B+)</option>
                    <option value="B negative (B-)">B negative (B-)</option>
                    <option value="AB positive (AB+)">AB positive (AB+)</option>
                    <option value="AB negative (AB-)">AB negative (AB-)</option>
                    <option value="O positive (O+)">O positive (O+)</option>
                    <option value="O negative (O-)">O negative (O-)</option>
                  </select>
                  <label for="bloodGroup" class="floating-label">Blood Group*</label>
                  <i class="ri-hand-coin-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="location" type="text" disabled/>
                  <label for="location" class="floating-label-special">Location*</label>
                  <i class="ri-map-pin-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="country" type="text" />
                  <label for="country" class="floating-label-special">Country*</label>
                  <i class="ri-earth-fill"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="previousDesignation" type="text" />
                  <label for="previousDesignation" class="floating-label-special" >Previous Designation*</label>
                  <i class="ri-user-line"></i>
                </div>
              </div>

              <div class="input">
                <div class="input-item">
                  <input formControlName="deputedAt" type="text" />
                  <label for="deputedAt" class="floating-label-special">Deputed At</label>
                  <i class="ri-user-line"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-sm-12 text-end">
            <a (click)="updateProfileData()" class="btn" [class.disabled]="!profileForm.valid ? true : null">Update</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
