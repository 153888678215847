import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { HttpResponse } from '@angular/common/http';
import { ProjectTaskService } from 'src/app/_services/project_task/project-task.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaskService } from 'src/app/_services/task/task.service';

@Component({
  selector: 'app-product-structure',
  templateUrl: './product-structure.component.html',
  styleUrls: ['./product-structure.component.scss']
})
export class ProductStructureComponent implements OnInit {
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;
  selectedCheckboxData = [];
  selectedFile: File;
  defaultColDef:any;
  rowSelection:any
  tenantId: string;
  sampleGridFrameworkComponents: any;
  form: FormGroup;
  fileName: any;

  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private projecttaskService: ProjectTaskService,
    private snackBar: MatSnackBar,
    private taskService: TaskService,
  ) { 
     this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
    actionRowRendererComponent: ActionItemsGridRowRendererComponent
  };
  this.form = this.formBuilder.group({
    file: ['', Validators.required]
  });

  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getSystemInformation();
  }

  getSystemInformation(){
      this.taskService.getSystemInformation().subscribe((response: any) => {
        if(response) {
          let array = [];
          let systems = Object.keys(response);
          for(let system in systems) {
            let actualSystem : any = systems[system];
            let subSystems = response[actualSystem];
            for(let subSystem in subSystems) {
              let subSystems = response[actualSystem];
              let actualSubSystem = subSystems[subSystem];
              for(let subSubSystem in actualSubSystem){
                let actualSubSubSystem = actualSubSystem[subSubSystem];
                let json = {
                  'system' : actualSystem,
                  'subSystem' : subSystem,
                  'subSubSystem' : actualSubSubSystem
                }
                array.push(json);
              }
            }  
          }
          this.rowData = array;
        }
      });
  }

  exportProductInformation(){
    // this.projecttaskService.exportProductInformation().subscribe(response =>{
    //   this.openSnackBar('File downloaded Successfully!!');
    // })
    this.projecttaskService.exportProductInformation().subscribe(
      (response: HttpResponse<Blob>) => {
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', 'download.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
      },
      (error) => {
      }
    );
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params): void {
    this.api = params.api;
    // console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  // create column definitions  
  private createColumnDefs() {
    return [
      {
        headerName: 'System',
        field: 'system',
        filter: true,
        editable: false,
        sortable: true,
      },

      {
        headerName: 'Sub System',
        field: 'subSystem',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Sub Sub System',
        field: 'subSubSystem',
        filter: true,
        editable: false,
        sortable: true
    },
    ]
  }

  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log('in onFileChanged, selectedFile: ', this.selectedFile);
  }

  importProductStructure(){
    const productStructure = new FormData();
    productStructure.append('file', this.selectedFile);
    this.projecttaskService.importProductStructure(productStructure).subscribe((response: any) => {
      if(response) {
        this.openSnackBar('File Imported Successfully!!');
      } else {
        this.openSnackBar('File Import Failed');
      }
      this.getSystemInformation();
      $('#closeImportModal').hide();
     });
  }

    openSnackBar(message: string) {
      this.snackBar.open(message, 'Close', { duration: 3000 });
    }

}
