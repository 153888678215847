<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Salary Sheet </h3>
                        </div>  
                    </div>
                    <form [formGroup]="payslipForm">
                        <div class="row my-4 align-items-center">
                            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <div class="input mb-0 no-min-width">
                                    <div class="input-item">
                                        <select formControlName="payslipMonth">
                                            <!-- <option value="" disabled selected>Select Month</option> -->
                                            <option value="January">January</option>
                                            <option value="February">February</option>
                                            <option value="March">March</option>
                                            <option value="April">April</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July</option>
                                            <option value="August">August</option>
                                            <option value="September">September</option>
                                            <option value="October">October</option>
                                            <option value="November">November</option>
                                            <option value="December">December</option>
                                          </select>
                                          <label class="loating-label floating-label-special">Select Month</label> 
                                          <i class="ri-calendar-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <div class="input mb-0 no-min-width">
                                    <div class="input-item">
                                        <select formControlName="payslipYear">
                                            <!-- <option value="" disabled selected>Select Year</option> -->
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                          </select>
                                          <label class="loating-label floating-label-special">Select Year</label> 
                                          <i class="ri-calendar-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <div class="input mb-0 no-min-width">
                                    <div class="input-item">
                                        <select formControlName="payslipFinancialYear">
                                            <!-- <option value="" disabled selected>Select Financial Year</option> -->
                                            <option value="2021-2022">2021-2022</option>
                                            <option value="2022-2023">2022-2023</option>
                                            <option value="2023-2024">2023-2024</option>
                                            <option value="2024-2025">2024-2025</option>
                                            <option value="2025-2026">2025-2026</option>
                                          </select>
                                          <label class="loating-label floating-label-special">Select Financial Year</label> 
                                          <i class="ri-calendar-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 text-end text-right">
                                <button class="btn" (click)="getAllUserPaySlip()">View</button>
                            </div>
                        </div>
                    </form>
                    
                    <ag-grid-angular
                        style="height: 620px;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        paginationPageSize=10>
                     </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>
