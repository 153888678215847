import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, retry } from "rxjs/operators";
import { Observable, throwError } from 'rxjs';
import { environment } from "../../../environments/environment";
import { LocalStorageService } from "../localStorage/local-storage.service";
import { NgxPermissionsService } from "ngx-permissions";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(private http: HttpClient, public router: Router, private storageService: LocalStorageService,
    private permissionsService: NgxPermissionsService) { }

  authenticate(value: any, tenantId: any) {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tenantId': tenantId,
      }),
    };
    return this.http.post<any>(`${environment.apiUrl}/authenticate`, value, header);
  }

  isUserLoggedIn() {
    let token = this.storageService.get('token');
    return !(token === null);
  }

  logOut() {
    this.storageService.clear();
    this.permissionsService.flushPermissions();
  }

  //Check if the Account present or not if present send an OTP for reset password
  checkAccountSendOTP(data): Observable<Object> {
    var dataObj = JSON.parse(data);

    const header = {
      headers: new HttpHeaders({
        'emailId': dataObj.email,
        'tenantId': dataObj.tenantId
      }),
      responseType: 'text' as 'json'
    };

    console.log("In PasswordRest method, data: ", data);
    //console.log(this.http.post(`${environment.apiUrl}/forgotPassword`, data, header).pipe(catchError(this.handleError)));


    return this.http.post(`${environment.apiUrl}/forgotPassword`, data, header).pipe(catchError(this.handleError));
  }

  // Resetpassword 
  resetForgotPassword(token, pass): Observable<Object> {
    var passObj = JSON.parse(pass);
    var tokenObj = JSON.parse(token);
    console.log(passObj, tokenObj);
    let currentUser = JSON.parse(this.storageService.get('emailTemp'));
    console.log(currentUser);
    const header = {
      headers: new HttpHeaders({
        'emailId': passObj.email,
        'tenantId': passObj.tenantId,
      }),
      responseType: 'text' as 'json'
    };
    // var data = {   }
    return this.http.post(`${environment.apiUrl}/resetForgotPassword?token=${tokenObj.otp}&password=${passObj.confirmPassword}`, null, header).pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Authentication error!';
    if (error.error instanceof ErrorEvent) {
      // Client side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(error);
    return throwError(error);
  }
}
