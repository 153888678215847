import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceService } from '../../../../_services/attendance/attendance.service'
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { convertYYYYMMDDtoDDMMYYYY } from '../../../../_helpers/utils';
import { HolidaycalenderService } from 'src/app/_services/holidaycalender/holidaycalender.service';
import 'rxjs/add/operator/map';
@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  userDropdownList = [];
  jsonData: any;
  attendanceform: FormGroup;
  reportForm: FormGroup;
  attendanceDetailsForm: FormGroup;
  tenantId: string;
  currentUser: any;
  defaultColDef:any;
   rowSelection:any
   dataSource: any;
   public columnDefs: ColDef[];
   modeAction: number = 1; //1 - create // 2 - update
   sampleGridFrameworkComponents: any;
   selectedCheckboxData = [];
   private api: GridApi;  
  private columnApi: ColumnApi;
  selectedRow: any;
  rowData: any;
  form: FormGroup;
  getUserName: any;
  public userData: any;
  isAdmin : boolean;
  isTenantCustomised : boolean;
  isStatus : any;
  isManager: any;


  constructor(
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService,
    private attendanceService :AttendanceService,
    private allocationService: AllocationService,
    private router: Router,
    private snackBar: MatSnackBar,
    private holidaycalenderService: HolidaycalenderService
  ) {
    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };
  }
  this.attendanceform = this.formBuilder.group({
    id : new FormControl(''),
    name : new FormControl(''),
    address : new FormControl(''),
    longitude : new FormControl(''),
    latitude : new FormControl(''),
    inTime : new FormControl(''),
    outTime : new FormControl(''),
    punchRadius : new FormControl(''),
    employeeId : new FormControl(''),
    officeId :new FormControl(''),
    attendanceDate : new FormControl(''),
    status : new FormControl(''),
    markingTime : new FormControl(''),
    employeeName: new FormControl(''),
    workTimeInHours: new FormControl(''),
    shift: new FormControl(''),
    comments: new FormControl(''),
  })

  this.attendanceDetailsForm = this.formBuilder.group({
    id: new FormControl(''),
    employeeId: new FormControl(''),
    startDate: new FormControl(''),
    endDate: new FormControl('')
  });

  this.reportForm = this.formBuilder.group({
    month: new FormControl(''),
    year: new FormControl('')
  });

  }
   get f() {
    return this.attendanceDetailsForm.controls;
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getUserList();
    this.getAllHolidays();
    this.getAttendance();
    this.isAdmin = this.currentUser.admin ||this.currentUser.roles.includes('RoleHrmAdministrator');
    const customisedTenants = ['imtechnologies']
    this.isTenantCustomised = customisedTenants.includes(this.tenantId);
    this.isManager = this.currentUser.admin || this.currentUser.roles.includes('RoleReportsExtractor') || this.currentUser.roles.includes('RoleHrmAdministrator');
  }

  getAllHolidays() {
    this.holidaycalenderService.getAllHolidays().subscribe((response:any) => {
      this.jsonData = response.result;
    });
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
      this.userDropdownList.push(
        `0 . All Users`
      );
    });
  }

  createOpenModal() {
    this.modeAction = 1;
      this.reportForm.reset();
      this.reportForm.patchValue({
        month: '',
        year: '',
      });
  }

 async getAttendance(){
    let employeeId = this.currentUser.id;
    this.attendanceService.getAttendanceById(employeeId).subscribe((response: any) => {
      if(response.result && response.result.length > 0) {
        this.rowData = response.result.map(x => ({...x , day:this.getDays(x.attendanceDate)}));
      } else {
        this.rowData = [];
      }
    });
  }

  getAttendanceSummary(){
    let employeeId = this.attendanceDetailsForm.get('employeeId').value.split(' . ')[0];
    let startDate = this.attendanceDetailsForm.get('startDate').value;
    startDate = convertYYYYMMDDtoDDMMYYYY(startDate);
    let endDate = this.attendanceDetailsForm.get('endDate').value;
    endDate = convertYYYYMMDDtoDDMMYYYY(endDate);
    this.attendanceService.getAttendanceSummary(employeeId, startDate, endDate).subscribe((response: any) => {
      if(response.result && response.result.length > 0) {
        this.rowData = response.result.map(x => ({...x , day:this.getDays(x.attendanceDate)}));
      } else {
        this.rowData = [];
      }
    });
  }

  getAttendanceReport(){
    let month = this.reportForm.get('month').value;
    let year = this.reportForm.get('year').value;
    if((month === '') || (year === '')) {
      this.snackBar.open('Month and Year are mandatory.', 'Close', { duration: 3000 });
      return;
    }
    this.attendanceService.getAttendanceReport(month, year).subscribe((response: any) => {
      var downloadURL = window.URL.createObjectURL(response);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "attendance.xlsx";
      link.click();
    });
  }

    /**
   * get day from date
   * @param date 
   */
    getDays(date) {
      const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
     return weekday[new Date(date).getDay()];
    }
  
  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Date",
        field: "attendanceDate",
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Days',
        field: 'day',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Employee',
        field: 'employeeName',
        filter: true,
        editable: false,
        sortable: true,
        cellRenderer: this.createHyperLink.bind(this),
      },
      {
        headerName: 'In',
        field: 'inTime',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Out',
        field: 'outTime',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Working Hours',
        field: 'workTimeInHours',
        cellRenderer: (params) => {
          if(params.data.workTimeInHours && params.data.workTimeInHours < '09:00:00') {
            return  `<span style="color: red">${params.data.workTimeInHours}</span>`;
          } 
          else if(params.data.workTimeInHours && params.data.workTimeInHours >= '09:00:00') {
            return  `<span style="color: green">${params.data.workTimeInHours}</span>`;
          } else {
            return "";
          }
        },
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Shift',
        field: 'shift',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: (params) => {
          if(params.data.status && params.data.status === 'Absent') {
            return  `<span style="color: red">${params.data.status}</span>`;
          } 
          else if(params.data.status && params.data.status !== 'Absent') {
            return  `<span style="color: green">${params.data.status}</span>`;
          } else {
            return "";
          }
        },
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Comp Off Status',
        field: 'manualLeaveStatus',
        cellRenderer: (params) => {
          if(params.data.manualLeaveStatus && params.data.manualLeaveStatus === 'Absent') {
            return  `<span style="color: red">${params.data.manualLeaveStatus}</span>`;
          }
          else if(params.data.manualLeaveStatus && params.data.manualLeaveStatus !== 'Absent' ) {
            return  `<span style="color: green">${params.data.manualLeaveStatus}</span>`;
          }
          else {
            return "";
          }
        },
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Action',
        field: 'day',
        cellRenderer : (params) => {
          if(params.data.day == 'Sunday' || params.data.day == 'Saturday'|| this.jsonData.map(o=>o.holidayDate).includes(params.data.attendanceDate)) {
            if(params.data.inTime != "" && params.data.outTime != "") {
              const spanElement = document.createElement('div');
              spanElement.innerHTML = `<button type="button" id="compoffBtn" class="btn btn-sm btn-primary">Compoff Apply</button> `;
              spanElement.addEventListener('click', ($event) => {
                $event.preventDefault();
                let element: HTMLElement = document.getElementById('confimationBtn');
                element.click();
                this.userData = params.data;
              });
              return spanElement;
            }
          }
        }
      },
    ]
  }

  manualLeaves(employeeData) {
    let payload = {
      employeeId : employeeData.employeeId,
      employeeName: employeeData.employeeName,
      attendanceDate: employeeData.attendanceDate,
      inTime: employeeData.inTime,
      outTime: employeeData.outTime,
      workTimeInHours: employeeData.workTimeInHours,
      shift: employeeData.shift,
      status: employeeData.status,
      comments: this.attendanceform.get('comments').value,
    }
    if(employeeData.inTime === null && employeeData.outTime === null){
      window.alert('Please regularize attandance, inTime and outTime cannot be null')
    }
    else{
      this.attendanceService.createManualLeaves(payload).subscribe({
        next: (value) => {
          
        },
        error: (error) => {
          console.error(error);
        },
        complete: () => {
          this.getAttendance();
        },
      })
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  createHyperLink(params): any {
    if (!params.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a href="${params.value}" > ${params.value} </a> `;
    spanElement.addEventListener('click', ($event) => {
      $event.preventDefault();
      this.router.navigate([this.tenantId + '/dashboard/hr/attendance-detail'], { queryParams: { contactId: params.data.id } });
    });
    return spanElement;
  }

  editOpenModal() {
    this.modeAction = 2;
    this.attendanceform.patchValue(this.selectedCheckboxData[0]);
  }

  openEdit(item) {
    this.selectedRow = item;
    this.attendanceform.patchValue(item);
  }

  addAttendance() {
    this.router.navigate([ this.tenantId + '/dashboard/hr/add-attendance' ]);
  }

  updateAttendance() {
    let selectedNodes = this.api.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    let editId = selectedNodes.map((node) => node.data.id);
    localStorage.setItem('userAttendanceInfo', JSON.stringify(this.selectedCheckboxData[0]));

    this.router.navigate([ this.tenantId + '/dashboard/hr/add-attendance', { employeeId: this.selectedCheckboxData[0].employeeId, employeeName: this.selectedCheckboxData[0].employeeName, attendanceDate: this.selectedCheckboxData[0].attendanceDate } ]);
  }

  openShiftSchedule(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/shift-schedule' ]);
  }
}
