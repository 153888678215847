import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GoalsService extends Subject<DataStateChangeEventArgs>  {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend,
     private http: HttpClient, 
     private storageService: LocalStorageService,
     ) { 
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
  return throwError(errorMessage);
  }

   // Create performancegoalsDetails
   createPerformancegoalsDetails(goal: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/goals`, goal).pipe(catchError(this.handleError));
  }


  // Get Overtime by Id Get
  getPerformancegoalsDetails(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/goals/${id}`).pipe(catchError(this.handleError));
  }

  //Delete Overtime by Id Delete
  deletePerformancegoalsDetails(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/goals/${id}`).pipe(catchError(this.handleError));
    }

  //Update Overtime by Id Put
  updatePerformancegoalsDetails(goal: Object,id: number): Observable<Object> {
    console.log("In update goal method, Goal: ", goal);
    return this.http.put(`${environment.apiUrl}/goals/${id}`, goal).pipe(catchError(this.handleError));
    }

    //Get All overtime 
    getAllPerformancegoalsDetails(): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/goals`).pipe(catchError(this.handleError));
      }

       // Create performancegoalsDetails
   createAssignDefaultTemplatesToUser(userId: number,designation: string,year: string): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/assignDefaultTemplatesToUser?userId=${userId}&designation=${designation}&year=${year}`,{}).pipe(catchError(this.handleError));
  }

  //Get Goals
  getGoalFilters(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/goalFilters`).pipe(catchError(this.handleError));
  }

  getPerformanceReportByUserId(userId: number, year: number): Observable<Object>{
    return this.http.get(`${environment.apiUrl}/performanceReport?userId=${userId}&year=${year}`).pipe(catchError(this.handleError));
  }

  getGoalsByFilter(userId: Number, year: Number, status: String): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/goals?userId=${userId}&year=${year}&status=${status}`)
  }

  updateGoalStatus(goalIdsList, status): Observable<Object>{
    let payload = {
      "ids" : goalIdsList,
      "status": status
    }
    return this.http.post(`${environment.apiUrl}/goalStatus`, payload).pipe(catchError(this.handleError));
  }

  //Get All overtime 
  getAllperformanceReportForAllUsers(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/performanceReportForAllUsers?year=2024`).pipe(catchError(this.handleError));
    }
}
