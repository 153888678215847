import { SpinnerService } from './spinner.service';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { LocalStorageService } from '../_services/localStorage/local-storage.service';
import { catchError, map, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private storageService: LocalStorageService,
    private spinnerService : SpinnerService,
    private snackBar: MatSnackBar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if(request.url.toString().includes('bulkStatusUpdate')) {

    }
    else{
      this.spinnerService.show();
    }
    
    if (
      this.storageService.get('token') &&
      this.storageService.get('tenantId')
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: this.storageService.get('token'),
          tenantId: this.storageService.get('tenantId'),
        },
      });
      //console.log("request with token: ", request);
    }

    return next.handle(request)
      .pipe(catchError((err) => {
        this.spinnerService.hide();
        if(err instanceof HttpErrorResponse){
          this.snackBar.open(err.error.message ? err.error.message : err.error ? err.error : 'Error Connecting to the Server' , 'close', { duration: 2000, panelClass: 'errorSnack' });
        }
        return err;
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.spinnerService.hide();
        }
        return evt;
      }));
  }
}
