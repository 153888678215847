import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { OpportunityService } from 'src/app/_services/opportunity/opportunity.service';
import { QuoteService } from 'src/app/_services/quote/quote.service';

@Component({
  selector: 'app-odc-distribution',
  templateUrl: './odc-distribution.component.html',
  styleUrls: ['./odc-distribution.component.scss']
})
export class OdcDistributionComponent implements OnInit {
  quoteDetail: any;
  opportunityDetail: any;
  dataSource_odcd: any;
  totalCost_odcdist: any;
  totalPercent_odcdist: any;
  odcDistDropDown: any;
  tenantId: string;

  constructor(private quoteService: QuoteService ,private opportunityService: OpportunityService, private formBuilder: FormBuilder, private dataService: DataService, private router: Router, private storageService: LocalStorageService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getOdcDistributionDetails();
  }

  getOdcDistributionDetails() {
    this.dataService.quoteUpdate.subscribe((details: any) => {
      console.log("odcDistributionSummary selected quote: ", details);  
      this.quoteDetail = details;
    });
    this.dataService.opportunityUpdate.subscribe((details: any) => {
      console.log("odcDistributionSummary opportunity in quote: ", details);  
      this.opportunityDetail = details;
    });
    this.quoteService.getOdcDistributionSummary(this.opportunityDetail.id,this.quoteDetail.id).subscribe((response:any) => {
      console.log("odcDistribution Summary Response", response);
      this.dataSource_odcd = response.otherDirectCostDistributions;
      this.totalCost_odcdist = response.totalCost;
      this.totalPercent_odcdist = response.totalPercent;

      this.odcDistDropDown = response.otherDirectCostDistributions;
      console.log("odcDistDropDown list check", this.odcDistDropDown);
    });
    
  }

}
