import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ResoureProjectService} from 'src/app/_services/resource-project/resoure-project.service'

@Component({
  selector: 'app-resource-allocation-project',
  templateUrl: './resource-allocation-project.component.html',
  styleUrls: ['./resource-allocation-project.component.scss']
})
export class ResourceAllocationProjectComponent implements OnInit {
  resourceAddForm: FormGroup;
  jsonData: any;
  selectedRow: any;
  currentUser: any;
  tenantId: string;

  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private router: Router,
    private resourceprojectservice: ResoureProjectService,
  ) {
    this.resourceAddForm = this.formBuilder.group({
      project: new FormControl(''),
      details: new FormControl(''),
      status: new FormControl(''),
      probability: new FormControl(''), 
    })
   }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.resourceprojectservice.getAllResourceAllocationProject().subscribe((response:any) => {
      this.jsonData = response;
    });
  }

  getResourceAllocationProject(){
    this.resourceprojectservice.getAllResourceAllocationProject().subscribe((response: any) => {
      this.jsonData = response;
      console.log("response: ", response);
    });
  }

  createResourceAllocationProject(){
    let details = JSON.stringify(this.resourceAddForm.value);
    // let id = this.contactForm.value.id;
    this.resourceprojectservice.createResourceAllocationProject(details).subscribe((response: any) => {
       console.log("response: ", response);
      if (response) {
        this.getResourceAllocationProject()
        $('#closeModalResourceAllocationProject').trigger("click");
      }
    });
  }

  updateResourceAllocationProject(id) {
    this.resourceprojectservice.updateResourceAllocationProject(this.resourceAddForm.value, id).subscribe(response => {
      this.getResourceAllocationProject()
        $('#closeModalEditResourceAllocationProject').trigger("click");
    })
  }


  deleteResourceAllocationProject(id) {
    this.resourceprojectservice.deleteResourceAllocationProject(id).subscribe(response => {
    this.getResourceAllocationProject()
        $('#closeModalDeleteResourceAllocationProject').trigger("click");
  })
}

 // to open delete modal
 open(item) {
  this.selectedRow = item;
  
}

 // to open edit modal
openEdit(item) {
this.selectedRow = item;
this.resourceAddForm .patchValue(item);

}


// getter for easy access to form fields
get f() {
return this.resourceAddForm.controls;
}


}
