import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-tags',
  templateUrl: './custom-tags.component.html',
  styleUrls: ['./custom-tags.component.scss']
})
export class CustomTagsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
