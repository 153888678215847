import { Component, OnInit} from "@angular/core";
import { Form, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from "src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component";
import { LocalStorageService } from "src/app/_services/localStorage/local-storage.service";
import { AllocationService } from "src/app/_services/resource-allocation/allocation.service";
import { HrService } from "src/app/_services/hr-management/hr.service";
import 'rxjs/add/operator/filter';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'app-leaveBook',
    templateUrl: './leaveBook.component.html',
    styleUrls: ['./leaveBook.component.scss']
})
export class LeaveBookComponent implements OnInit{
    leaveBookForm;
    employeeForm;
    form: FormGroup;
    rowData: any;
    public columnDefs: ColDef[];
    modeAction: number = 1 //1 - create // 2 - update
    // gridApi and columnApi
    private api: GridApi;
    private columnApi: ColumnApi;  
    defaultColDef:any;
    rowSelection:any;
    tenantId: string;
    currentUser: any;
    selectedFile: File;
    sampleGridFrameworkComponents: any;
    gridLength = 0;
    selectedCheckboxData = [];
    userDropdownList = [];
    earnleave: number;
    compensatoryleave: number;
    selectedRow: any;
    getUserName: any;

    constructor(
        private storageService: LocalStorageService,
        private formBuilder: FormBuilder,
        private HrService: HrService,
        private allocationService: AllocationService,
        private router: Router,
    ){
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            resizable: true,
        };
        this.rowSelection = 'multiple';
        this.columnDefs = this.createColumnDefs();

        this.sampleGridFrameworkComponents = {
            actionRowRendererComponent: ActionItemsGridRowRendererComponent,
        };

        this.form = this.formBuilder.group({
            file: ['', Validators.required]
        });

        this.leaveBookForm = new FormGroup({
            id: new FormControl(''),
            userId: new FormControl(''),
            leaveType: new FormControl(''),
            balance: new FormControl(''),
        });

        this.employeeForm = new FormGroup({
            userId: new FormControl(''),
        })
    }
    
    // getter for easy access to form fields
    get f() {
        return this.leaveBookForm.controls;
    }

    ngOnInit(): void {
        this.tenantId = this.storageService.get('tenantId');
        this.currentUser = JSON.parse(this.storageService.get('currentUser'));
        this.getLeaveBookData(this.currentUser.id)
        this.getUserList()
    }

    onGridReady(params): void {
        this.api = params.api;
        this.columnApi = params.columnApi;
        this.api.sizeColumnsToFit();
    }

    private createColumnDefs() {
        return[
            {
                headerName: 'Leave Type',
                valueGetter: function(params){
                    if(params.data.leaveType === 'Earned Leave') {
                      let leavetype = 'Casual Leave'
                      return leavetype;
                    } else {
                        return params.data.leaveType;
                    }
                },
                filter: true,
                editable: false,
                sortable: true,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
            },
            {
                headerName: 'Balance Leave',
                field: 'balance',
                filter: true,
                editable: false,
                sortable: true
            },
        ]        
    }

    onSelectionChanged(event) {
        this.selectedCheckboxData = []
        let select = event.api.getSelectedNodes();
        for( let item of select) {
            this.selectedCheckboxData.push(item.data)
        }
    }

    isFirstColumn(params) {
        const displayedColumns = params.columnApi.getAllDisplayedColumns();
        const thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
      }

    getUserList() {
        this.userDropdownList = []
        this.allocationService.getUserDetails().subscribe(response => {
            this.getUserName = response;
          for (let item of response) {
            this.userDropdownList.push(`${item.id} . ${item.firstName} ${item.lastName}`)
          }
        })
      }

    getLeaveBookData(userId){
        this.HrService.getUserLeveBalance(userId).subscribe((response)=> {
            this.rowData = response.result;
            for (let item of response.result) {
                this.earnleave = response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0] ?
                response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0].balance : 0;
                this.compensatoryleave = response.result.filter((leave) => leave.leaveType === ' Compensatory Leave')[0] ?
                response.result.filter((leave) => leave.leaveType === ' Compensatory Leave')[0].balance : 0;
              }
        })
    }

    // updateLeaveBook(){
    //     let data = {
    //         id: this.leaveBookForm.get('id').value,
    //         userId: this.leaveBookForm.get('userId').value,
    //         leaveType: this.leaveBookForm.get('leaveType').value,
    //         balance: this.leaveBookForm.get('balance').value,
    //     }
    //     this.HrService.updateUserLeveBalance(data['userId'], data['id'], data).subscribe(response => {
    //         this.getLeaveBookData(data['userId']);
    //         this.selectedCheckboxData = []
    //         $('#closeUpdateModal').click()
    //     });
    // }

    getEmployee(){
        let userId = this.employeeForm.get('userId').value.split(' . ')[0];
        this.getLeaveBookData(userId);
    }

    // to open edit modal
    openEdit(item) {
        this.selectedRow = item;
        this.leaveBookForm.patchValue(item);
    }

    editOpenModal() {
        this.modeAction = 2;
        this.leaveBookForm.patchValue(this.selectedCheckboxData[0])
    }
    
    backToLeaveProfile(){
        this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-profile']);
    }
}