import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import 'rxjs/add/operator/filter';
import { PerformanceTempService } from "src/app/_services/performance_temp/performance-temp.service";
import { GoalsService } from "src/app/_services/goals/goals.service"
import { LocalStorageService } from "src/app/_services/localStorage/local-storage.service";
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import * as _ from 'lodash';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-performance-templates',
  templateUrl: './performance-templates.component.html',
  styleUrls: ['./performance-templates.component.scss']
})
export class PerformanceTemplatesComponent implements OnInit {
  form: FormGroup;
  filterForm: FormGroup;
  performancetemplateForm
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
  defaultColDef:any;
  rowSelection:any
  tenantId: string;
  dataSource_performancegoals: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';  
  currentUser: any;
  selectedFile: File;
  userDropdownList = [];
  getUserName: any;

  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private allocationService: AllocationService,
    private goalsService: GoalsService,
    private snackBar: MatSnackBar,
    private performanceTempService: PerformanceTempService
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs(); 

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });

    this.filterForm = this.formBuilder.group({
      department: new FormControl(''),
      year: new FormControl('')
    });
  
    this.performancetemplateForm = this.formBuilder.group({
      designamtion: new FormControl('', Validators.required),
      department: new FormControl('', Validators.required),
      year: new FormControl('', Validators.required),
      kra: new FormControl('', Validators.required),
      kpi: new FormControl(''),
      weightage: new FormControl(''),
      base: new FormControl(''),
      target: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    // this.getperformanceTemplate();
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }  

  getperformanceTemplate() {  
    this.performanceTempService.getAllDefaultPerformanceTemplates().subscribe((response:any) => {
      console.log("getperformancegoalsDetails Response", response);
      // this.performancegoalsForm.patchValue(response.result);
      this.rowData = response;
    });
    
  }

   // create column definitions  
   private createColumnDefs() {
    return [
    {
      headerName: 'Designation',
      field: 'designamtion',
      filter: true,
      editable: false,
      sortable: true,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
    },
    {
      headerName: 'Department',
      field: 'department',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Year',
      field: 'year',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'KRA',
      field: 'kra',
      minWidth: 250,
      filter: true,
      editable: false,
      sortable: true, 
      wrapText: true,  
      autoHeight: true,
    },
    {
      headerName: 'KPI',
      field: 'kpi',
      minWidth: 400,
      filter: true,
      editable: false,
      sortable: true,
      wrapText: true,  
      autoHeight: true,
    },
    {
      headerName: 'Weightage',
      field: 'weightage',
      filter: true,
      editable: false,
      sortable: true
    },
    
    {
      headerName: 'Base',
      field: 'base',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Target',
      field: 'target',
      filter: true,
      editable: false,
      sortable: true
    },
    
  ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  createPerformanceTemp(){
    let details = {
      designamtion: this.performancetemplateForm.get('designamtion').value,
      department: this.performancetemplateForm.get('department').value,
      year: this.performancetemplateForm.get('year').value,
      kra: this.performancetemplateForm.get('kra').value,
      kpi: this.performancetemplateForm.get('kpi').value,
      weightage: parseInt(this.performancetemplateForm.get('weightage').value.toString()),
      base: parseInt(this.performancetemplateForm.get('base').value.toString()),
      target: parseInt(this.performancetemplateForm.get('target').value.toString()),
    }
    this.performanceTempService.createPerformanceTemplates(details).subscribe(response => {
      console.log("response: ", response);
      
      // update grid after creating new lead & close the modal
      this.api.applyTransaction({ add: [response] });
      $('#closeCreateModal').trigger("click");
    });
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }     
  }

  private deleteGridRow(params: any) {
    this.performanceTempService.deletePerformanceTemplates(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  editOpenModal() {
    this.modeAction = 2;
    this.performancetemplateForm.patchValue(this.selectedCheckboxData[0])
  }

  updatePerformanceTemp() {
    let details = {
      designamtion: this.performancetemplateForm.get('designamtion').value,
      department: this.performancetemplateForm.get('department').value,
      year: this.performancetemplateForm.get('year').value,
      kra: this.performancetemplateForm.get('kra').value,
      kpi: this.performancetemplateForm.get('kpi').value,
      weightage: parseInt(this.performancetemplateForm.get('weightage').value.toString()),
      base: parseInt(this.performancetemplateForm.get('base').value.toString()),
      target: parseInt(this.performancetemplateForm.get('target').value.toString()),
    }
    this.performanceTempService.updatePerformanceTemplates(details, this.selectedCheckboxData[0].id).subscribe(res => {
      this.getperformanceTemplate();
      this.selectedCheckboxData = []
      $('#closeCreateModal').click()
    })
  }


  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].designamtion}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].designamtion}`;
      else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].designamtion}`;
    }
  }

  deleteperformanceTemp() {
    this.performanceTempService.deletePerformanceTemplates(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getperformanceTemplate();
      this.selectedCheckboxData = []
      $('#closeModalDeleteVisitor').click()
    });
  }

  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log('in onFileChanged, selectedFile: ', this.selectedFile);
  }

  importPerformanceTemplates(){
    const performanceTemplate = new FormData();
    performanceTemplate.append('file', this.selectedFile);
    this.performanceTempService.importPerformanceTemplates(performanceTemplate).subscribe((response: any) => {
      if(response) {
        this.openSnackBar('File Imported Successfully!!');
      } else {
        this.openSnackBar('File Import Failed');
      }
      this.getperformanceTemplate();
      $('#closeImportModal').hide();
     }, error => {
      console.log(error);
    });
  }

    openSnackBar(message: string) {
      this.snackBar.open(message, 'Close', { duration: 3000 });
    }

    getUserList() {
      this.userDropdownList = [];
      this.allocationService.getUserDetails().subscribe((response) => {
        this.getUserName = response;
        for (let item of response) {
          this.userDropdownList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      });
    }

    getPerformanceReport(){
      let department = this.filterForm.get('department').value;
      let year = this.filterForm.get('year').value;
      this.performanceTempService.getPerformanceTemplatesByDept(department, year).subscribe((response: any) => {
        this.rowData = response;
      })
    }

}
