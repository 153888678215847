import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class OnsiteEmpService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) { 
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }

  // Create userDeputations
  createUserDeputations(addcclrdata: Object): Observable<Object> {
    console.log("In createitAssets method, holidays: ", addcclrdata);
    return this.http.post(`${environment.apiUrl}/userDeputations`, addcclrdata).pipe(catchError(this.handleError));
  }

  // // Get userDeputations by Id Get
  // getUserDeputations(id: number): Observable<Object> {
  //   return this.http.get(`${environment.apiUrl}/userDeputations/${id}`).pipe(catchError(this.handleError));
  //  }

    //Delete userDeputations by Id Delete
    deleteUserDeputations(id: number): Observable<Object> {
      return this.http.delete(`${environment.apiUrl}/userDeputations/${id}`).pipe(catchError(this.handleError));
     }

     //Update userDeputations by Id Put
    updateUserDeputations(user: Object,id: number): Observable<Object> {
      console.log("In createUser method, user: ", user);
      return this.http.put(`${environment.apiUrl}/userDeputations/${id}`, user).pipe(catchError(this.handleError));
    }

     //Get All userDeputations by Id Get
     getAllUserDeputations(): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/userDeputations`).pipe(catchError(this.handleError));
    }
  
      //Get  userDeputations Summary by Id Get
  getuserDeputations(userId, startDate, endDate): Observable<Object> {
    if (userId) {
      return this.http.get(`${environment.apiUrl}/userDeputations?userId=${userId}&startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
    }
    else {
      return this.http.get(`${environment.apiUrl}/userDeputations?startDate=${startDate}&endDate=${endDate}`).pipe(catchError(this.handleError));
    }
  }

  

    // //Get All userDeputations by Id Get
    // getAllHolidaysByYear(year: number): Observable<Object> {
    //   return this.http.get(`${environment.apiUrl}/userDeputations?year=${year}`).pipe(catchError(this.handleError));
    // }

}
