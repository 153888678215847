import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  privateKey = "pragamana2020";
  encrypted: any;
  decrypted: any;

  set(key: string, value: string) {
    this.encrypted = CryptoJS.AES.encrypt(value, this.privateKey).toString();
    localStorage.setItem(key, this.encrypted);
  }

  get(key: string) {
    if (localStorage.getItem(key)){
      this.decrypted = CryptoJS.AES.decrypt(localStorage.getItem(key), this.privateKey);
      return this.decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return null;
    }
  }

  clear() {
    localStorage.clear();
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

}
