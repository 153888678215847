<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> User CTC </h3>
                        </div>
                        <div class="col-sm-6 gap-2" style="display: flex; justify-content: flex-end; align-items: center;"> 
                            <div class="col-sm-4">
                                <div class="input mb-0 no-min-width">
                                    <select (change)="handleChange($event)">
                                        <option value="2021-2022">2021-2022</option>
                                        <option value="2022-2023">2022-2023</option>
                                        <option value="2023-2024">2023-2024</option>
                                        <option value="2024-2025">2024-2025</option>
                                        <option value="2025-2026">2025-2026</option>
                                    </select>
                                </div>
                            </div>
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" (click)="updateTaxctcApp()">
                                  <span><i class="ri-pencil-line"></i></span>
                                </button>
                              </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" data-bs-toggle="modal" data-bs-target="#deleteTaxMetaDataModal" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div class=" add-btn">
                                <button type="button"  (click)="openTaxForm()" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular 
                        style="height: 620px;width: 100;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)"
                        [rowData]="rowData"
                        [columnDefs]="columnDefs"
                        [animateRows]="true"
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        [rowDragManaged]="true"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10
                        [suppressDragLeaveHidesColumns]="true">
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Delete an item -->
<div class="modal fade" id="deleteTaxMetaDataModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteTaxMetaData" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteTaxMetadata()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>