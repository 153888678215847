
<section class="auth-sidebar">
    <div class="row m-0 align-items-center">
      <div class="col-md-12 col-lg-5 p-0 col-sm-12 col-xl-4">
        <div class="login-box">
          <img src="assets/logo.png">
          <h3>Sign up</h3>
          <div class="login-form mt-2">
            <form [formGroup]="form"  (ngSubmit)="onSubmit()">
            <div class="input">            
              <div class="input-item">              
                <input type="text" formControlName="userId" id="userId">
                <label class="floating-label">User ID</label>
                <i class="ri-user-line"></i>
                <mat-error *ngIf="f.userId.touched && f.userId.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.userId.errors.required">User Id is required</mat-error>
                </mat-error>
              </div>            
            </div>
            <!-- <div class="input">            
              <div class="input-item">              
                <input type="text" formControlName="displayName" id="displayName" required>
                <label class="floating-label">Display Name</label>
                <i class="ri-shield-user-line"></i>
                <mat-error *ngIf="f.displayName.touched && f.displayName.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.displayName.errors.required">Display Name is required</mat-error>
                </mat-error>
              </div>            
            </div> -->
            <div class="input">            
              <div class="input-item">              
                <input type="text" formControlName="firstName" id="firstName">
                <label class="floating-label">First Name</label>
                <i class="ri-user-3-line"></i>
              </div>            
            </div>
            <div class="input">            
                <div class="input-item">              
                  <input type="text" formControlName="lastName" id="lastName">
                  <label class="floating-label">Last Name</label>
                  <i class="ri-user-3-line"></i>
                </div>            
            </div>
            <div class="input">            
                <div class="input-item">              
                  <input type="text" formControlName="tenantId" id="tenantId">
                  <label class="floating-label">Tenant ID</label>
                  <i class="ri-shield-user-line"></i>
                  <mat-error *ngIf="f.tenantId.touched && f.tenantId.invalid" class="alert alert-danger">
                    <mat-error *ngIf="f.tenantId.errors.required">Tenant Id is required</mat-error>
                    <mat-error *ngIf="f.tenantId.errors.pattern">Enter a valid Tenant Id</mat-error>
                </mat-error>
                </div>            
            </div>
            <div class="input">            
                <div class="input-item">              
                  <input type="text" formControlName="emailId" id="emailId">
                  <label class="floating-label">Email Address</label>
                  <i class="ri-mail-unread-line"></i>
                  <mat-error *ngIf="f.emailId.touched && f.emailId.invalid" class="alert alert-danger">
                    <mat-error *ngIf="f.emailId.errors.required">Email Address is required</mat-error>
                    <mat-error *ngIf="f.emailId.errors.pattern">Enter valid Email Address</mat-error>
                    <mat-error *ngIf="f.emailId.errors.email">Email must be a valid email</mat-error>
                  </mat-error>
                </div>            
            </div>
            <div class="input">            
                <div class="input-item">              
                  <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" id="password">
                  <label class="floating-label">Password</label>
                  <i class="ri-lock-line"></i>
                  <mat-error *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                    <mat-error *ngIf="f.password.errors.required">Password is required</mat-error>
                    <mat-error *ngIf="f.password.errors.minlength">Password must be atleast 6 characters long
                    </mat-error>
                  </mat-error>
                </div>            
            </div>
            <div class="input">            
              <div class="input-item">              
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" id="password">
                <label class="floating-label"> Confirm Password</label>
                <i class="ri-lock-line"></i>
                <mat-error *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.password.errors.required">Password is required</mat-error>
                  <mat-error *ngIf="f.password.errors.minlength">Password must be atleast 6 characters long
                  </mat-error>
                </mat-error>
              </div>            
          </div>
            <div class="input">            
                <div class="input-item">              
                  <input type="text" formControlName="department" id="department">
                  <label class="floating-label">Department</label>
                  <i class="ri-lock-line"></i>
                  <mat-error *ngIf="f.department.touched && f.department.invalid" class="alert alert-danger">
                    <mat-error *ngIf="f.department.errors.required">Department is required</mat-error>
                  </mat-error>
                </div>            
            </div>
            <div class="input">            
                <div class="input-item">              
                  <input type="text" formControlName="phoneNo" id="phoneNo">
                  <label class="floating-label">Mobile No.</label>
                  <i class="ri-phone-line"></i>
                  <mat-error *ngIf="f.phoneNo.touched && f.phoneNo.invalid" class="alert alert-danger">
                    <mat-error *ngIf="f.phoneNo.errors.required">Phone Number is required</mat-error>
                    <mat-error *ngIf="f.phoneNo.errors.minlength">Phone Number must have at least 10 digits</mat-error>
                    <mat-error *ngIf="f.phoneNo.errors.minlength">Phone Number must have maximum 10 digits</mat-error>
                    <mat-error *ngIf="f.phoneNo.errors.pattern">Phone must contain numbers only</mat-error>
                  </mat-error>
                </div>            
            </div>
            <!-- <div class="input">            
                <div class="input-item">              
                  <input type="text" formControlName="location" id="location" required>
                  <label class="floating-label">Location</label>
                  <i class="ri-map-pin-line"></i>
                  <mat-error *ngIf="f.location.touched && f.location.invalid" class="alert alert-danger">
                    <mat-error *ngIf="f.location.errors.required">Location is required</mat-error>
                  </mat-error>
                </div>            
            </div> -->
            <div class="input">
              <button class="btn btn-big" [disabled]="!form.valid">Sign Up</button>
            </div>

            <button (click)="rzpbutton1()" [disabled]="!form.valid">Pay</button>
            <!-- <div class="input text-center or-option">
              <span>OR</span>
            </div>
            <div class="input">
              <button class="btn btn-big btn-outline">Sign In</button>
            </div> -->
          </form>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-7 col-xl-8 d-none d-lg-block">
        <div class="illustraton-box text-center">
          <img src="assets/svg/signup.svg">
          <h4>Business process improvement</h4>
        </div>
      </div>
    </div>
  </section>