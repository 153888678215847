<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                  <div class="canvas-header row justify-content-between align-items-center">
                    <div class="title col-sm-6">
                      <div class="d-flex align-items-center">
                        <div class="edit">
                          <button title="back page" (click)="backToJobApplication()">
                            <span><i class="ri-arrow-left-line"></i></span>
                          </button>
                        </div>
                        <h3> Job Applications Approved </h3>
                      </div>
                    </div>
                    <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;"> 
                      <div class="button" *ngIf="jobDescriptionId">
                          <button type="button" (click)="openJobPost()">Job Post Link</button>
                      </div>
                    </div>
                    <section class="job-application-timeline">
                      <div class="row align-items-center">
                        <ul>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openApplicantList()">
                                <span>1</span>
                              </div>
                              <p class="step-count">Applicant</p>
                            </div>
                          </li>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openShortlistedApplicants()">
                                <span>2</span>
                              </div>
                              <p class="step-count">Shortlisted</p>
                            </div>
                          </li>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openInterviewApplicants()">
                                <span>3</span>
                              </div>
                              <p class="step-count">Interview</p>
                            </div>
                          </li>
                          <li>
                            <div class="step">
                              <div class="stepn-numb" (click)="openOfferedApplicants()">
                                <span>4</span>
                              </div>
                              <p class="step-count">Offered</p>
                            </div>
                          </li>
                          <li>
                            <div class="step active">
                              <div class="stepn-numb" (click)="openApprovedApplicants()">
                                <i class="ri-medal-line"></i>
                              </div>
                              <p class="step-count">Approved</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                  
                  <hr><br>
                  <ag-grid-angular 
                      style="height: 620px;" 
                      class="ag-theme-material"
                      (gridReady)="onGridReady($event)"
                      [rowData]="rowData"
                      [columnDefs]="columnDefs"
                      [animateRows]="true"
                      [defaultColDef]="defaultColDef"
                      [suppressRowClickSelection]="true"
                      [rowSelection]="rowSelection"
                      [rowDragManaged]="true"
                      paginationPageSize=10
                      [suppressDragLeaveHidesColumns]="true">
                  </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<div class="modal fade" id="onBoardCandidate" tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content pl-5 pr-5">
          <form [formGroup]="">
              <div class="modal-header">
                  <h5 class="modal-title">Are you sure?</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div class="modal-body">
                <div class="text-center">
                  <p>Please enter the employee code to proceed with on-boarding?</p>
                  <div class="input">
                      <div class="input-item">
                          <input type="text" placeholder="Employee Code*" formControlName="userId"/>
                          <i class="ri-code-box-line"></i>
                      </div>
                      <mat-error *ngIf="f.userId.touched && f.userId.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.userId.errors.required">Employee Code is required</mat-error>
                      </mat-error>
                  </div>
                  <div class="input">
                    <div class="input-item">
                        <input type="text" placeholder="Department*" formControlName="department"/>
                        <i class="ri-code-box-line"></i>
                    </div>
                    <mat-error *ngIf="f.department.touched && f.department.invalid" class="alert alert-danger">
                        <mat-error *ngIf="f.department.errors.required">Department is required</mat-error>
                    </mat-error>
                  </div>
                  <div class="input">
                    <div class="input-item">
                        <input type="text" placeholder="Location*" formControlName="location"/>
                        <i class="ri-code-box-line"></i>
                    </div>
                    <mat-error *ngIf="f.location.touched && f.location.invalid" class="alert alert-danger">
                        <mat-error *ngIf="f.location.errors.required">Location is required</mat-error>
                    </mat-error>
                  </div>
                </div>
                <div class="d-flex gap-4 w-100 justify-content-around">
                  <button type="button"  class="btn btn-primary" data-bs-dismiss="modal" (click)="onBoardCandidate()">Yes</button>
                  <button type="button" data-bs-dismiss="modal" class="btn btn-secondary">No</button>
                </div>
         
              </div>
          </form>
      </div>
  </div>
</div>

<div class="modal fade" id="cancelConfirmation" tabindex="-1">
  <div class="modal-dialog">
      <div class="modal-content pl-5 pr-5">
          <form [formGroup]="">
              <div class="modal-header">
                  <h5 class="modal-title">Are you sure?</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div class="modal-body">
                <div class="text-center">
                  <p>Are you sure? do you want to delete this data?</p>
                </div> 
                <div class="d-flex gap-4 w-100 justify-content-around">
                  <button type="button"  class="btn btn-primary" data-bs-dismiss="modal" (click)="cancelOnBoarding()">Yes</button>
                  <button type="button" class="btn btn-secondary">No</button>
                </div>
         
              </div>
          </form>
      </div>
  </div>
</div>

<button type="button"  data-bs-toggle="modal" data-bs-target="#onBoardCandidate" id="confimationBtn" style="display: none;">
                                 
</button>

<button type="button"  data-bs-toggle="modal" data-bs-target="#cancelConfirmation" id="cancelConfirmationBtn" style="display: none;">
                               
</button>