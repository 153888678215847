import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthenticationService } from "../../../_services/auth/authentication.service";
import { UserService } from "../../../_services/user/user.service";
import { LocalStorageService } from "../../../_services/localStorage/local-storage.service";
import { JwtTokenService } from "../../../_services/jwtToken/jwt-token.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})

export class ForgotPasswordComponent implements OnInit {
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  isFirst = true;
  isSecond = false;
  isThird = false;
  displayError = '';
  emailStep3 = '';

  constructor(private _formBuilder: FormBuilder, private router: Router, private authenticationService: AuthenticationService, private snackBar: MatSnackBar, private storageService: LocalStorageService,) { }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      email: [null, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        Validators.email
      ]],
      tenantId: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      otp: ['', Validators.required],
    });
    this.thirdFormGroup = this._formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.email]],
      newPassword: ['', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]],
      confirmPassword: ['', Validators.required],
      tenantId: ['', Validators.required]
    });
  }

  // All is this method
  onPasswordChange() {
    if (this.confirmPassword.value == this.newPassword.value) {
      this.confirmPassword.setErrors(null);
    } else {
      this.confirmPassword.setErrors({ mismatch: true });
    }
  }

  // getting the form control elements
  get newPassword(): AbstractControl {
    return this.thirdFormGroup.controls['newPassword'];
  }

  get confirmPassword(): AbstractControl {
    return this.thirdFormGroup.controls['confirmPassword'];
  }
  //text-success
  //text-danger
  // getter for easy access to form fields
  get f() {
    return this.firstFormGroup.controls;
  }

  get fa() {
    return this.secondFormGroup.controls;
  }

  get fb() {
    return this.thirdFormGroup.controls;
  }

  onSubmitEmailTenant(args) {
    console.log("Validate the Email and tenant ID");
    let details = JSON.stringify(this.firstFormGroup.value);
    console.log("Check Account Email", details);

    if ((this.firstFormGroup.value.email == '' || this.firstFormGroup.value.email == null) && this.firstFormGroup.value.tenantId == '') {
      console.log("Both fileds are empty");
      this.displayError = "Please enter all mandatory fields"

      document.getElementById("forgot-auth-1").classList.remove("d-none");
      document.getElementById("forgot-auth-2").classList.add("d-none");
      document.getElementById("display-error1").classList.remove("d-none");
      document.getElementById("display-error1").classList.add("text-danger");
    }
    else {
      if (this.firstFormGroup.value.email == '' || this.firstFormGroup.value.email == null) {
        console.log("Email ID Empty null");
        this.displayError = "Please enter a correct email username"
        document.getElementById("display-error1").classList.remove("d-none");
        document.getElementById("display-error1").classList.add("text-danger");
        document.getElementById("forgot-auth-1").classList.remove("d-none");
        document.getElementById("forgot-auth-2").classList.add("d-none");
      }
      if (this.firstFormGroup.value.tenantId == '' || this.firstFormGroup.value.tenantId == null) {
        console.log("TenantId ID tenantId null");
        this.displayError = "Please enter a correct Tenant Id"

        document.getElementById("forgot-auth-1").classList.remove("d-none");
        document.getElementById("forgot-auth-2").classList.add("d-none");
        document.getElementById("display-error1").classList.remove("d-none");
        document.getElementById("display-error1").classList.add("text-danger");
      }

    }
    this.authenticationService.checkAccountSendOTP(details).subscribe((response: any) => {


      console.log("response 123333333: ", response);

      if (response != null) {
        console.log("Status Code 200")
        this.displayError = response;
        this.storageService.set('EmailFP', this.firstFormGroup.value.email);
        document.getElementById("forgot-auth-1").classList.add("d-none");
        document.getElementById("forgot-auth-2").classList.remove("d-none");
        document.getElementById("display-error2").classList.remove("d-none");
        document.getElementById("display-error2").classList.add("text-success");
        this.emailStep3 = this.storageService.get('EmailFP');
        this.thirdFormGroup.controls['email'].setValue(this.emailStep3);
        this.thirdFormGroup.controls['tenantId'].setValue(this.firstFormGroup.value.tenantId);
      }
    },
      (error) => {                              //Error callback

        console.log(error);
        this.displayError = "Something went wrong. Please enter a correct details to reset a password. "
        // document.getElementById("forgot-auth-2").classList.add("d-none");

        document.getElementById("forgot-auth-1").classList.remove("d-none");
        document.getElementById("forgot-auth-2").classList.add("d-none");

        document.getElementById("display-error1").classList.remove("d-none");
        document.getElementById("display-error1").classList.add("text-danger");
        //    //throw error;   //You can also throw the error to a global error handler
      });


  }

  //Add Token
  addTokenValidation() {
    let details = JSON.stringify(this.secondFormGroup.value);
    console.log("Check OTP Token ", details);
    if (details) {
      document.getElementById("forgot-auth-2").classList.add("d-none");
      document.getElementById("forgot-auth-3").classList.remove("d-none");

    }
  }

  //Go Back from second screen
  goBackFromSecondScreen() {
    this.secondFormGroup.controls['otp'].setValue(undefined);
    document.getElementById("forgot-auth-1").classList.add("d-none");
    document.getElementById("forgot-auth-2").classList.remove("d-none");
  }

  //Go Back from third screen
  goBackFromThirdScreen() {
    document.getElementById("forgot-auth-2").classList.add("d-none");
    document.getElementById("forgot-auth-3").classList.remove("d-none");
  }

  onCancel() {
    this.router.navigate(['/login']);
  }

  onSubmitCode(args) {
    let details = JSON.stringify(args.value);
    console.log("Check Code Email", details);
    if ((args.value.otp == '' || args.value.otp == null)) {
      this.displayError = "";
      this.displayError = "Please enter the correct verification code that send on your register email."
      // document.getElementById("forgot-auth-2").classList.add("d-none");
      document.getElementById("forgot-auth-2").classList.remove("d-none");
      //document.getElementById("forgot-auth-1").classList.remove("d-none");
      document.getElementById("display-error2").classList.remove("d-none");
      document.getElementById("display-error2").classList.add("text-danger");


    }
    else{
      this.displayError = undefined;
    }
    // this.displayError = "";
  }
  //Check account is available or not
  checkAccount() {

    let details = JSON.stringify(this.firstFormGroup.value);
    console.log("Check Account Email", details);
    this.storageService.set('emailTemp', details);

    this.authenticationService.checkAccountSendOTP(details).subscribe((response: any) => {


      console.log("response 123333333: ", response);

      if (response.status == 200) {
        //document.getElementById("forgot-auth-2").style.display = "block";
        //document.getElementById("forgot-auth-1").style.display = "none";
        //document.getElementById("forgot-auth-1").classList.add("d-none");
        //  document.getElementById("forgot-auth-2").classList.remove("d-none");


        //this.isSecond = true;
        // this.isFirst = false;
        //this.isFirst = !this.isFirst;


        // this.openSnackBar("New Opportunity created");
      } else {
        console.log("Status 500");
        document.getElementById("forgot-auth-1").classList.remove("d-none");
        document.getElementById("display-error").classList.remove("d-none");
      }
      //}
      //  (error) => {                              //Error callback
      //    console.error('error caught in component')
      //    console.log(error);
      //    this.displayError = "Something went Wrong. Please enter a correct details to reset a password. "
      //    // document.getElementById("forgot-auth-2").classList.add("d-none");
      //    document.getElementById("forgot-auth-1").classList.remove("d-none");
      //    document.getElementById("display-error").classList.remove("d-none");
      //    //throw error;   //You can also throw the error to a global error handler
    });
  }



  submitForgotUpdatedPassword() {
    let token = JSON.stringify(this.secondFormGroup.value);
    let updatedPass = JSON.stringify(this.thirdFormGroup.value);
    let bodyAsJson = this.thirdFormGroup.value;
    if(bodyAsJson.newPassword !== bodyAsJson.confirmPassword) {
      this.snackBar.open("New Password and Confirm Password does not match", 'Close', { duration: 3000 });
      return;
    }
    console.log("Check Updated Token", token);
    console.log("Check Updated password", updatedPass);
    this.authenticationService.resetForgotPassword(token, updatedPass).subscribe((response: any) => {
      console.log("response: ", response);
      if (response == "Invalid Token") {
        this.displayError = "Please enter a correct token."

        document.getElementById("display-error2").classList.remove("d-none");
        document.getElementById("display-error2").classList.add("text-danger");
        document.getElementById("forgot-auth-2").classList.remove("d-none");
        document.getElementById("forgot-auth-3").classList.add("d-none");
      } else {
        if (response != null) {
          this.snackBar.open(response, 'Close', { duration: 3000 });
          this.router.navigate(['/profile']);
        }
      }
    });
  }
  passwordMatchValidator(thirdFormGroup: FormGroup) {
    return this.thirdFormGroup.controls['newPassword'].value === this.thirdFormGroup.controls['confirmPassword'].value ? null : { 'mismatch': true };
  };

}
