import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../../_services/localStorage/local-storage.service';
import { AttendanceService } from '../../../../_services/attendance/attendance.service';
import { AllocationService } from '../../../../_services/resource-allocation/allocation.service';
import { addDays } from '@syncfusion/ej2-angular-schedule';

@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ['./add-attendance.component.scss']
})
export class AddAttendanceComponent implements OnInit {
  addattendanceForm: FormGroup;
  tenantId: string;
  userDropdownList = [];
  getUserName: any;
  userId: string;
  userDetails: any;
  public attendanceId: any;
  employeeId: any;
  employeeName: any;
  attendanceDate: any;
  inTime: any;
  outTime: any;
  attendanceDateNew = new Date();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storageService: LocalStorageService,
    private attendanceService :AttendanceService,
    private allocationService: AllocationService,
    private formBuilder: FormBuilder,
  ) {
    this.addattendanceForm = this.formBuilder.group({
      attendanceDate: new FormControl('', Validators.required),  
      employeeId: new FormControl('', Validators.required),
      employeeName: new FormControl(''),
      inTime: new FormControl(''),
      outTime: new FormControl(''),
      markingTime: new FormControl(''),
      status: new FormControl('')
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));
    this.getUserList();
    let userAttendanceInfo = JSON.parse(localStorage.getItem('userAttendanceInfo'));
    this.employeeId = userAttendanceInfo.employeeId;
    this.employeeName = userAttendanceInfo.employeeName;
    this.attendanceDate = userAttendanceInfo.attendanceDate;
    this.inTime = userAttendanceInfo.inTime;
    this.outTime = userAttendanceInfo.outTime;
    this.addattendanceForm.patchValue({
      employeeId: this.employeeId + ' . ' + this.employeeName,
      attendanceDate: this.attendanceDate,
   
      inTime: this.inTime,
      outTime: this.outTime
    })
    localStorage.removeItem('employeeInfo');
  }

  // getter for easy access to form fields
  get f() {
    return this.addattendanceForm.controls;
  }

  addAttendance() {
    var punchinTime = this.addattendanceForm.value.inTime;
    var punchoutTime = this.addattendanceForm.value.outTime;
    if(punchinTime != ''){
      var attendanceData = {
        attendanceDate: this.addattendanceForm.get('attendanceDate').value,
        employeeId: parseInt(this.addattendanceForm.get('employeeId').value.toString().includes(".") ? this.addattendanceForm.get('employeeId').value.split('.')[0].trim() : this.addattendanceForm.get('employeeId').value),
        employeeName: this.addattendanceForm.get('employeeId').value.toString().includes(".") ? this.addattendanceForm.get('employeeId').value.split('.')[1].trim() : "",
        markingTime: this.addattendanceForm.get('inTime').value.toString().split(':').length == 2 ? this.addattendanceForm.get('inTime').value+':00' : this.addattendanceForm.get('inTime').value,
        status: 'Regularization: Applied'
      };

      this.attendanceService.createAttendance(attendanceData).subscribe((response: any) => {
      });
    }
    if(punchoutTime!= ''){
      let newDate: any;
      if(punchoutTime > '00:00' && punchoutTime < '04:00'){
        console.log('punchoutTime', punchoutTime)
        this.attendanceDateNew = this.addattendanceForm.get('attendanceDate').value;
        this.attendanceDateNew = addDays(this.attendanceDateNew, 1);
        console.log('attendanceDate', this.attendanceDateNew);
        let month = this.attendanceDateNew.getMonth()+1;
        let dateNew = this.attendanceDateNew.getDate().toString().length === 1 ? "0"+this.attendanceDateNew.getDate().toString() : this.attendanceDateNew.getDate().toString();
        let monthNew = month.toString().length === 1 ? (("0"+month).toString()) : (month.toString());
        newDate = this.attendanceDateNew.getFullYear() + '-' + monthNew + '-' + dateNew;
        console.log('attendanceDate', newDate);
      }
      else{
        newDate = this.addattendanceForm.get('attendanceDate').value;
      }
      var attendanceData = {
        attendanceDate: newDate,
        employeeId: parseInt(this.addattendanceForm.get('employeeId').value.toString().includes(".") ? this.addattendanceForm.get('employeeId').value.split('.')[0].trim() : this.addattendanceForm.get('employeeId').value),
        employeeName: this.addattendanceForm.get('employeeId').value.toString().includes(".") ? this.addattendanceForm.get('employeeId').value.split('.')[1].trim() : "",
        markingTime: this.addattendanceForm.get('outTime').value.toString().split(':').length == 2 ? this.addattendanceForm.get('outTime').value+':00' : this.addattendanceForm.get('outTime').value,
        status: 'Regularization: Applied'
      };
      console.log("attendanceData", attendanceData);

      this.attendanceService.createAttendanceNotification(attendanceData).subscribe((response: any) => {
        });
    }
    this.backToAttendanceList();
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  backToAttendanceList() {
    this.router.navigate([this.tenantId + '/dashboard/hr/attendance']);
  }

}
