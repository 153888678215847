
  <section class="auth-sidebar">
    <div class="row m-0 align-items-center">
      <div class="col-md-12 col-lg-5 p-0 col-sm-12 col-xl-3">
        <div class="login-box">
          <img src="./assets/logo.png">
          <h3>Sign In</h3>
          <div class="login-form mt-2">
            <form [formGroup]="loginForm" >
            <div class="input">            
              <div class="input-item">              
                <!-- <input type="text" required> -->
                <input matInput formControlName="userId" id="userId" name = "userId" required />
                <label class="floating-label-special">User ID</label>
                <i class="ri-user-line"></i>
                <mat-error *ngIf="f.userId.touched && f.userId.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.userId.errors.required">User Id is required</mat-error>
                </mat-error>
              </div>            
            </div>
            <div class="input">            
              <div class="input-item">
                <input matInput  formControlName="tenantId" id="tenantId" required>
                <label class="floating-label-special"  >Tenant ID</label>
                <i class="ri-shield-user-line"></i>
                <mat-error *ngIf="f.tenantId.touched && f.tenantId.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.tenantId.errors.required">Tenant Id is required</mat-error>
                </mat-error>
              </div>            
            </div>
            <div class="input">            
              <div class="input-item">
                <input matInput type="password" formControlName="password" id="password" required>
                <label class="floating-label-special">Password</label>
                <i class="ri-lock-line"></i>
              </div>
              <mat-error *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                <mat-error *ngIf="f.password.errors.required">Password is required</mat-error>
                <mat-error *ngIf="f.password.errors.pattern">Password must have at least 8 chracters
                    which contain at least one lowercase character, at least one uppercase character, at
                    least one number and special character </mat-error>
              </mat-error>
            </div>           
            <div class="input">
              <button class="btn btn-big" (click)=checkLogin() [disabled]="!loginForm.valid">Sign In</button>
            </div>
            <div class="input text-center">
              <a href="#" class="link" routerLink="forgotPassword" (click)=forgotPassword() >Forgot Password?</a>
            </div>
            <div class="input text-center or-option">
              <span>OR</span>
            </div>
            <div class="input">
              <!-- <button class="btn btn-big btn-outline" routerLink="register" (click)=register() >Sign Up</button> -->
              <button class="btn btn-big btn-outline" routerLink="pricing-table" (click)=pricingTable() >Sign Up</button>
            </div>
          </form>  
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-7 col-xl-9 d-none d-lg-block">
        <div class="illustraton-box text-center">
          <img src="./assets/svg/sign-in.svg">
          <h4>Business Process Improvement</h4>
        </div>
      </div>
    </div>
  </section>
  <div style="text-align:right;color:white;">
    AppVersion: V-16032025-1
  </div>