<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <form [formGroup]="attendanceDetailsForm">
          <div class="canvas-item">
            <div class="canvas-header row justify-content-between align-items-center">
              <div class="title col-sm-3 p-0">
                <h3>My Attendance </h3>
              </div>
              <div class="col-sm-6 d-flex justify-content-end gap-2">
                <button *ngIf="this.isPMOManager || this.isAdmin" type="button" title="Create Shift Schedule" class="btn btn-sm"
                  (click)="openShiftSchedule()">
                  <div>Shift Schedule</div>
                </button>
                <div *ngIf="selectedCheckboxData.length === 1">
                  <button type="button" title="Create Regularize" (click)="updateAttendance()"
                    class="btn btn-primary btn-sm">
                    <i class="ri-pencil-line"></i>
                    <span> Regularize</span>
                  </button>
                </div>
                <div>
                  <button type="button" title="Apply Short Leave" title="Create" (click)="shortLeaveModal()"
                    data-bs-toggle="modal" data-bs-target="#shortLeave" class="btn btn-primary btn-sm">
                    <i class="ri-logout-box-r-line"></i>
                    <span> Short Leave</span>
                  </button>
                </div>
                <div class="add-btn" *ngIf="!this.isTenantCustomised">
                  <button type="button" title="Create Attendance" (click)="addAttendance()" class="btn btn-icon">
                    <i class="ri-add-line"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="canvas-header row justify-content-between align-items-center">
              <div class="row p-0">
                <div class="col-sm-12 p-0">
                  <div class="canvas-data px-0">
                    <div class="row m-0">
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2" *ngIf="this.isAdmin || this.isPMOManager">
                        <div class="input">
                          <div class="input-item">
                            <input list="taskOwnerDatalist" formControlName="employeeId"
                              placeholder="Select Employee Name" value="" onfocus="this.value=''"
                              onchange="this.blur();" />
                            <datalist id="taskOwnerDatalist">
                              <option [value]="item" *ngFor="let item of userDropdownList"></option>
                            </datalist>
                            <label class="floating-label">Employee Name</label>
                            <i class="ri-user-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="startDate" />
                            <label class="floating-label-special">From</label>
                            <i class="ri-calendar-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                        <div class="input">
                          <div class="input-item">
                            <input type="date" formControlName="endDate" />
                            <label class="floating-label-special">To</label>
                            <i class="ri-calendar-line"></i>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 text-end text-right">
                        <div class="row justify-content-center">
                          <div class="col-sm-6">
                            <button type="button" class="btn" (click)=getAttendanceSummary()>
                              <div>Submit</div>
                            </button>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#createVisitor"
                              id="confimationBtn" style="display: none;"></button>
                          </div>
                          <div class="col-sm-6" *ngIf="this.isManager">
                            <button type="button" class="btn" title="Create" (click)="createOpenModal()"
                              data-bs-toggle="modal" data-bs-target="#report_form">
                              <div>Report</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ag-grid-angular class="ag-theme-material" (gridReady)="onGridReady($event)" [rowData]="rowData"
              [columnDefs]="columnDefs" pagination="true" [defaultColDef]="defaultColDef"
              [suppressRowClickSelection]="true" [rowSelection]="rowSelection"
              (selectionChanged)="onSelectionChanged($event)" paginationPageSize="10" autoSizeAllColumns="true">
            </ag-grid-angular>
          </div>
        </form>
      </section>
    </section>
  </article>
</main>

<!-- Create new Lead -->
<div class="modal fade" id="createVisitor" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="attendanceform">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>Are you sure you want to apply compensatory off?</p>
          </div>
          <div class="row">
            <div class="col-sm-12 px-3 py-1">
              <div class="input">
                <div class="input-item">
                  <textarea name="" id="" cols="30" rows="4" formControlName="comments"></textarea>
                  <label class="floating-label">Comment</label>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex gap-4 w-100 justify-content-around">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="manualLeaves(this.userData)">Yes</button>
            <button type="button" class="btn btn-secondary">No</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Get Report Form -->
<div class="modal fade" id="report_form">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="reportForm">
        <div class="modal-header">
          <h5 class="modal-title">Attendance Report</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="input">
            <div class="input-item">
              <select formControlName="month">
                <option value="" disabled selected>Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
              <i class="ri-calendar-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <select formControlName="year">
                <option value="" disabled selected>Select Year</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
              <i class="ri-calendar-line"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="closereport_form" class="btn btn-secondary" data-bs-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-primary" (click)="getAttendanceReport()">Download</button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Create Short Leave -->
<div class="modal fade" id="shortLeave" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="shortLeaveForm">
        <div class="modal-header">
          <h5 class="modal-title">Shift Schedule</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="input">
            <div class="input-item">
              <input formControlName="userId" type="text" disabled>
              <label for="userId" class="floating-label-special">Employee</label>
              <i class="ri-user-line"></i>
            </div>
            <div class="input-item">
              <input formControlName="leaveDate" type="date">
              <label for="leaveDate" class="floating-label-special">Date</label>
              <i class="ri-calendar-line"></i>
            </div>
            <div class="input-item">
              <select formControlName="firstHalf">
                <option value="" disabled selected>Select</option>
                <option [ngValue]="true">First Half</option>
                <option [ngValue]="false">Second Half</option>
              </select>
              <label for="firstHalf" class="floating-label-special">Short Leave For</label>
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" id="closeCreateModal" class="btn btn-secondary"
              data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" *ngIf="modeAction === 1"
              (click)="createShortLeave()">Create</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>