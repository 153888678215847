import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { Router } from '@angular/router';
import { TaxmetadataService } from '../../../../_services/taxmetadata/taxmetadata.service';
import { data } from 'jquery';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent implements OnInit {
  jsonData: any;
  // row data and column definitions 
  taxmetadataform 
  rowData: any;
  form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  tenantId: string;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete';
  selectedFinancialYear: any;
  taxMetaDataArray : any[] = [];


  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private router: Router,
    private taxmetadataService :TaxmetadataService
  ) { 
    {
        this.defaultColDef = {
          flex: 1,
          minWidth: 100,
          resizable: true,
        };
        this.rowSelection = 'multiple';
      this.columnDefs = this.createColumnDefs();  

      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };
    }
    this.taxmetadataform = this.formBuilder.group({
      id: new FormControl(''),
      financialYear: new FormControl(''),
      country: new FormControl(''),
      regimeNo: new FormControl(''),
      componentName: new FormControl(''),
      componentUpperLimitValue: new FormControl(''),
      componentValue: new FormControl(''),
      percent:  new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getTaxmetaData()
  }

  getTaxmetaData(){
    if(this.selectedFinancialYear){
      this.taxmetadataService.getAlltaxMetadata().subscribe((response: any) => {
        this.taxMetaDataArray = [];
        for(let item of response.result){
          if(item.financialYear === this.selectedFinancialYear){
            this.taxMetaDataArray.push(item);
          }
        }
        this.rowData = this.taxMetaDataArray;
      });
    }
    else{
      this.taxmetadataService.getAlltaxMetadata().subscribe((response: any) => {
        this.rowData = response.result; 
        console.log("this.rowData:", this.rowData);
      });
    }
  }

  handleChange(fYear){
    this.selectedFinancialYear = fYear.target.value;
    this.getTaxmetaData();
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params: any) {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Tax Regims",
        field: "regimeNo",
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Name',
        field: 'componentName',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Financial Year',
        field: 'financialYear',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Country',
        field: 'country',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Tax %',
        field: 'percent',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Annual Tax Slab',
        field: 'componentUpperLimitValue',
        filter: true,
        editable: false,
        sortable: true
      },
    ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  openTaxForm() {
    // location.href = "{this.tenantId}/dashboard/hr/leaves/leave-application";
    this.router.navigate([this.tenantId + '/dashboard/finance/taxmeta-form']);
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }
  }

  private deleteGridRow(params: any) {
    this.taxmetadataService.deletetaxMetadata(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  editOpenModal() {
    this.modeAction = 2;
    this.taxmetadataform.patchValue(this.selectedCheckboxData[0])
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
      else this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
    }
  }

  deleteTaxMetadata() {
    this.taxmetadataService.deletetaxMetadata(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getTaxmetaData();
      this.selectedCheckboxData = []
      $('#closeModalDeleteTaxMetaData').click()
    });
  }

  updateTaxmedtaApp() {
    let selectedNodes = this.api.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    let editId = selectedNodes.map((node) => node.data.id);
    //console.log("id",editId);
    this.router.navigate([
      this.tenantId + '/dashboard/finance/taxmeta-form', { id: this.selectedCheckboxData[0].id }]);
  }

// to open edit modal
  openEdit(item) {
  this.selectedRow = item;
  this.taxmetadataform.patchValue(item);
}

}
