import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ActionItemsGridRowRendererComponent } from '../../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { convertYYYYMMDDtoDDMMYYYY } from 'src/app/_helpers/utils';

@Component({
  selector: 'app-leave-balance-logs',
  templateUrl: './leave-balance-logs.component.html',
  styleUrls: ['./leave-balance-logs.component.scss']
})
export class LeaveBalanceLogsComponent implements OnInit {
  
  tenantId: string;
  currentUser: any;
  gridLength = 0;
  leaveBalanceLogsForm: FormGroup;
  sampleGridFrameworkComponents: any;
  userDropdownList = [];
  getUserName: any;
  // row data and column definitions
  rowData: any;
  // public columnDefs: ColDef[];
  public columnDefs: ColDef[] = []
  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
  };
  
  constructor(
    private storageService: LocalStorageService,
    private HrService: HrService,
    private formBuilder: FormBuilder,
    private router: Router,
    private allocationService: AllocationService
  ) { 
    { 
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.columnDefs = this.createColumnDefs();
  
      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent,
      };
    };

    this.leaveBalanceLogsForm = this.formBuilder.group({
      userId: ['', Validators.required],
      userName: [''],
      startDate: [''],
      endDate: [''],
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.getUserList();
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.rowData = [];
  }

  // create column definitions
  private createColumnDefs() {
    return [  
        {
        headerName: 'Employee Name',
        field: 'userName',
        minWidth: 150,
        filter: true,
        editable: false,
        sortable: true,
        },
        { 
        headerName: 'Leave Type',
        field: 'leaveType',
        filter: true,
        editable: false,
        sortable: true,
        },
        {
        headerName: 'Action',
        field: 'action',
        minWidth: 250,
        filter: true,
        editable: false,
        sortable: true,
        },
        { 
        headerName: 'Old Balance',
        field: 'oldBalance',
        filter: true,
        editable: false,
        sortable: true,
        },
        {
        headerName: 'New Balance',
        field: 'newBalance',
        filter: true,
        editable: false,
        sortable: true,
        },
        { 
        headerName: 'Requester Name',
        field: 'requesterName',
        filter: true,
        editable: false,
        sortable: true,
        },
        {
        headerName: 'Action Date',
        field: 'actionDateTime',
        minWidth: 150,
        filter: true,
        editable: false,
        sortable: true,
        }
    ];
  }

  getLeaveBalanceLogsByUserId(){
    let startDate = this.leaveBalanceLogsForm.get('startDate').value;
    startDate = convertYYYYMMDDtoDDMMYYYY(startDate);
    let endDate = this.leaveBalanceLogsForm.get('endDate').value;
    endDate = convertYYYYMMDDtoDDMMYYYY(endDate);
    let userId = this.leaveBalanceLogsForm.get('userId').value.split(' . ')[0];
    this.HrService.getLeaveBalanceLogs(userId, startDate, endDate).subscribe(((response: any) => {
      if(response) {
        this.rowData = response;
      }
    }));
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      this.userDropdownList.push(
        `-1 . All Users`
      );
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  backToLeaveProfile(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-profile']);
  }

}