import { DatePipe } from '@angular/common';
import { ReturnStatement } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ColDef,
  ColumnApi,
  GridApi,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { Observable, Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { OvertimeService } from 'src/app/_services/overtime/overtime.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { UserService } from 'src/app/_services/user/user.service';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-overtime',
  templateUrl: './overtime.component.html',
  styleUrls: ['./overtime.component.scss'],
})
export class OvertimeComponent implements OnInit {
  overtimeForm;
  public columnDefs: ColDef[];
  modeAction: number = 1; //1 - create // 2 - update
  // gridApi and columnApi
  private api: GridApi;
  private columnApi: ColumnApi;
  defaultColDef: any;
  rowSelection: any;
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  modalService: any;
  currentUser: any;
  jsonData: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  projectDetailList = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';
  rowData: any;
  date: Date;
  TodayDate: string;
  lafduration: number;
  latduration: number;
  laduration: number;
  totalHours: number;
  userDropdownList = [];
  getUserName: any;
  getProjectDetailsList: any;

  constructor(
    private storageService: LocalStorageService,
    private overtimeService: OvertimeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private allocationService: AllocationService,
    private userService: UserService
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent,
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required],
    });

    this.overtimeForm = new FormGroup({
      id: new FormControl(''),
      title: new FormControl(''),
      appliedForId: new FormControl('', Validators.required),
      projectId: new FormControl('', Validators.required),
      explaination: new FormControl(''),
      startTime: new FormControl('', Validators.required),
      endTime: new FormControl('', Validators.required),
      createdByUserId: new FormControl(''),
      createdDateTime: new FormControl(''),
      modifiedByUserId: new FormControl(''),
      modifiedDateTime: new FormControl(''),
      totalHours: new FormControl(''),
      status: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    //console.log('tenantId: ', this.tenantId);
    //console.log('currentUser: ', this.currentUser);
    this.getUserList();
    this.getOvertime();
    this.getProjectDetails();
    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;
    //console.log('Current date:', this.TodayDate);
  }

  getovertimefromduration(e) {
   // console.log('Current date from:', e.target.value);
    this.lafduration = e.target.value;
  }

  getovertimetoduration(e) {
   // console.log('Current date To:', e.target.value);
    this.latduration = e.target.value;

    let toDate = new Date(this.latduration);
    let fromDate = new Date(this.lafduration);

    // this.totalHours = (differenceInDays + 1) * 8;
    this.totalHours = parseInt(
      timeConvert(
        Math.floor((toDate.getTime() - fromDate.getTime()) / (60 * 60 * 1000))
      )
    );
  }

  getOvertime() {
    this.overtimeService.getAllOvertime().subscribe((response: any) => {
      this.rowData = response.result;
      //console.log('response: ', response);
    });
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
  onGridReady(params): void {
    this.api = params.api;
    //console.log('qqqq', this.api);
    this.columnApi = params.columnApi;

    this.api.sizeColumnsToFit();
  }

  // create column definitions
  private createColumnDefs() {
    return [
      {
        headerName: 'Title',
        field: 'title',
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Applied For Id',
        //field: this.getuserbyID('appliedForId'),`
        //valueGetter: hashValueGetter,
        valueGetter: (params) => {
          //var x = this.getuserbyID(params.data.appliedForId)
          //console.log("x", x)

          return this.getUserID(params.data.appliedForId);
        },
        // valueSetter: (params) => {
        //   params.data.name = params.newValue;
        //   return true;
        // },
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Project Id',
        field: 'projectId',
        valueGetter: (params) => {
          //var x = this.getuserbyID(params.data.appliedForId)
          //console.log("x", x)

          return this.getProjectID(params.data.projectId);
        },
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Explaination',
        field: 'explaination',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Start Date',
        field: 'startTime',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'End Date',
        field: 'endTime',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Total Hours',
        field: 'totalHours',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Status',
        field: 'status',
        maxWidth: 150,
        cellStyle: (params) => {
          if (params.value === 'Save' || params.value === 'save') {
            return { color: 'blue', 'font-weight': 'bold' };
          } else if (params.value === 'Submit' || params.value === 'submit') {
            return { color: 'orange', 'font-weight': 'bold' };
          } else if (params.value === 'Approved' || params.value === 'approved') {
            return { color: 'green', 'font-weight': 'bold' };
          } else if (params.value === 'Reject' || params.value === 'reject') {
            return { color: 'red', 'font-weight': 'bold' };
          } else if (params.value === 'Withdraw' || params.value === 'withdraw') {
            return { color: 'grey', 'font-weight': 'bold' };
          }
          return null;
        },
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Created By UserId',
        field: 'createdByUserId',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Created DateTime',
        field: 'createdDateTime',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Modified By UserId',
        field: 'modifiedByUserId',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Modified DateTime',
        field: 'modifiedDateTime',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
    
    ];
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
    /*get the username if selected 0
    if (this.selectedCheckboxData.length == 1) {
      this.selectedCheckboxData[0].appliedForId = this.getUserID(
        this.selectedCheckboxData[0].appliedForId
      );
    }
    //get the projectid if selected 0
    if (this.selectedCheckboxData.length == 1) {
      this.selectedCheckboxData[0].projectId = this.getProjectID(
        this.selectedCheckboxData[0].projectId
      );
    }
    */
  //  console.log('Selected', this.selectedCheckboxData);
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  createOvertime() {
    // let details = JSON.stringify(this.overtimeForm.value);
    let details = {
     // id: '',
      title: this.overtimeForm.get('title').value,
      appliedForId: parseInt(
        this.overtimeForm
          .get('appliedForId')
          .value.toString()
          .split(" . ")[0]
      ),
      projectId: parseInt(
        this.overtimeForm
          .get('projectId')
          .value.toString()
          .split(" . ")[0]
      ),
      explaination: this.overtimeForm.get('explaination').value,
      startTime: this.overtimeForm.get('startTime').value.substring(0,10)+" "+this.overtimeForm.get('startTime').value.substring(11,16)+":00",
      endTime: this.overtimeForm.get('endTime').value.substring(0,10)+" "+this.overtimeForm.get('endTime').value.substring(11,16)+":00",
      createdByUserId: this.currentUser.id,
     // createdDateTime: this.overtimeForm.get('createdDateTime').value,
     // modifiedByUserId: this.overtimeForm.get('modifiedByUserId').value,
     // modifiedDateTime: this.overtimeForm.get('modifiedDateTime').value,
      status: this.overtimeForm.get('status').value,
      totalHours: this.overtimeForm.get('totalHours').value,
    };
   // console.log('createOvertime details before: ', details);

    // let id = this.contactForm.value.id;
    this.overtimeService.createOvertime(details).subscribe((response: any) => {
      // console.log('response: ', response);
      // update grid after creating new lead & close the modal
      this.api.applyTransaction({ add: [response] });
      $('#closeModalOvertime').trigger('click');
    //  console.log('createOvertime details after: ', response);
    });
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }
  }

  private deleteGridRow(params: any) {
    this.overtimeService.deleteOvertime(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  editOpenModal() {
    this.modeAction = 2;
    this.selectedCheckboxData[0].startTime = this.selectedCheckboxData[0].startTime.substring(0,10)+"T"+this.selectedCheckboxData[0].startTime.substring(11,16);
    this.selectedCheckboxData[0].endTime = this.selectedCheckboxData[0].endTime.substring(0,10)+"T"+this.selectedCheckboxData[0].endTime.substring(11,16);
   // console.log("editOpenModal startTime",this.selectedCheckboxData[0].startTime);
   // console.log("editOpenModal endTime",this.selectedCheckboxData[0].endTime);this.getUserID(response.userId)
   this.selectedCheckboxData[0].appliedForId = this.getUserID(this.selectedCheckboxData[0].appliedForId);
   this.selectedCheckboxData[0].projectId = this.getProjectID(this.selectedCheckboxData[0].projectId);
    this.overtimeForm.patchValue(this.selectedCheckboxData[0]);
   // console.log("edit initiated",this.selectedCheckboxData[0].startTime);
  }

  createOpenModal() {
    this.modeAction = 1;
    this.overtimeForm.reset();
    this.overtimeForm.patchValue({
      id: '',
      title: '',
      appliedForId: '',
      projectId: '',
      explaination: '',
      startTime: '',
      endTime: '',
      createdByUserId: '',
      createdDateTime: '',
      modifiedByUserId: '',
      modifiedDateTime: '',
      totalHours: '',
      status: '',
    });
  }

  updateOvertime() {
   // this.overtimeForm.startTime = this.overtimeForm.startTime.substring(0,10)+" "+this.overtimeForm.startTime.substring(11,16)+":00",
   // this.overtimeForm.endTime = this.overtimeForm.endTime.substring(0,10)+" "+this.overtimeForm.endTime.substring(11,16)+":00",
   // console.log("updateOvertime startTime:", this.overtimeForm.get('startTime'));
   // console.log("updateOvertime endTime:", this.overtimeForm.get('endTime'));

    //console.log("update initiated:", this.overtimeForm.value);

    let updatedetails = {
      id: this.selectedCheckboxData[0].id,
      title: this.overtimeForm.get('title').value,
      appliedForId: parseInt(
        this.overtimeForm
          .get('appliedForId')
          .value.toString()
          .split(" . ")[0]
      ),
      projectId: parseInt(
        this.overtimeForm
          .get('projectId')
          .value.toString()
          .split(" . ")[0]
      ),
      explaination: this.overtimeForm.get('explaination').value,
      startTime: this.overtimeForm.get('startTime').value.substring(0,10)+" "+this.overtimeForm.get('startTime').value.substring(11,16)+":00",
      endTime: this.overtimeForm.get('endTime').value.substring(0,10)+" "+this.overtimeForm.get('endTime').value.substring(11,16)+":00",
     // createdByUserId: this.overtimeForm.get('createdByUserId').value,
     // createdDateTime: this.overtimeForm.get('createdDateTime').value,
      modifiedByUserId: this.currentUser.id,
     // modifiedDateTime: this.overtimeForm.get('modifiedDateTime').value,
      status: this.overtimeForm.get('status').value,
      totalHours: this.overtimeForm.get('totalHours').value,
    };

   // console.log("updateOvertime updatedetails before:", updatedetails);

    this.overtimeService
      .updateOvertime(updatedetails, this.selectedCheckboxData[0].id)
      .subscribe((res) => {
        this.getOvertime();
        this.selectedCheckboxData = [];
        $('#closeModalOvertime').trigger('click');
      //  console.log("updateOvertime updatedetails after:", res);

      });
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete';
    for (let i in this.selectedCheckboxData) {
      if (+i === 0)
        this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].title} ${this.selectedCheckboxData[i].appliedForId}`;
      else if (this.selectedCheckboxData.length === +i + 1)
        this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].title} ${this.selectedCheckboxData[i].appliedForId}`;
      else
        this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].title} ${this.selectedCheckboxData[i].appliedForId}`;
    }
  }

  deleteOvertime() {
    this.overtimeService
      .deleteOvertime(this.selectedCheckboxData[0].id)
      .subscribe(() => {
        this.getOvertime();
        this.selectedCheckboxData = [];
        $('#closeModalDeleteOvertime').trigger('click');
      });
  }

  // to open delete modal
  open(item) {
    this.selectedRow = item;
  }

  // to open edit modal
  openEdit(item) {
    this.selectedRow = item;
    this.overtimeForm.patchValue(item);
  }

  // getter for easy access to form fields
  get f() {
    return this.overtimeForm.controls;
  }
  getUserID(par) {
    //console.log('List of User', this.getUserName);
    if (this.getUserName != null) {
      var result = this.getUserName.filter(function (chain) {
        return chain.id === par;
      });
      if (result[0] && (result[0].firstName !== null || result[0].lastName !== null)) {
        var userName =
          result[0].id + ' . ' + result[0].firstName + ' ' + result[0].lastName;
      //  console.log('getUserID Rsult', userName);
      }
    }
    return userName;
  }

  getProjectID(par) {
    if (this.getProjectDetailsList != null) {
      var result = this.getProjectDetailsList.filter(function (chain) {
        return chain.id === par;
      });
      var value = result[0].id + ' . ' + result[0].project;
      // console.log("getProjectID Rsult", result)
    }
    return value;
  }

  //getuserbyID(params) {
  //  let userName;
  //  // var userN = new Subject<any>();
  //  return this.userService.getUser(params).subscribe((response: any) => {
  //    // `${response.id} . ${response.firstName} ${response.lastName}`;
  //    console.log(
  //      "tes", `${response.id} . ${response.firstName} ${response.lastName}`
  //    );
  //    this.getUserName = response;
  //    //  this.getUserName = `${response.id} . ${response.firstName} ${response.lastName}`;

  //    // return this.getUserName;
  //  });
  //  //console.log('FFFFFFSDSDA', userName);
  //  //console.log('FFFFFFSDSDA', userN.asObservable());
  //  //return userN.asObservable();
  //}

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getProjectDetails() {
    this.allocationService.getProjectDetails().subscribe((response) => {
      this.getProjectDetailsList = response;
      for (let item of response) {
        this.projectDetailList.push(`${item.id} . ${item.project} `);
      }
      //console.log('projectDetailList: ', response);
    });
  }
}

// var hashValueGetter = function (params: ValueGetterParams) {
//   console.log(params.data.appliedForId);
//   this.userService.getUser(params).subscribe((response: any) => {
//     console.log(`${response.id} . ${response.firstName} ${response.lastName}`);
//   });

//   return 'Ritesh';
// };

function timeConvert(n) {
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return (
    num + ' minutes = ' + rhours + ' hour(s) and ' + rminutes + ' minute(s).'
  );
}
