import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { printDiv } from './print-div';
import { event } from 'jquery';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import jspdf, {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.scss']
})
export class PayslipComponent implements OnInit {
  @ViewChild('content', {static: false}) content: ElementRef;

  selectedDate: any;
  payslipForm: any;
  payslipMonth: any;
  payslipYear: any;
  payslipFinancialYear: any;
  currentUserId: any;
  payslipemployeeName: any;
  payslipresponse: any;
  payslipnoOfDays: any;
  payslipmonth: any;
  payslipyear: any;
  payslipnetPayable: any;
  paysliptotalDeduction: any;
  paysliptotalPayable: any;
  paysliptotalTax: any;
  payslippayables: any;
  payslipdeductions: any;
  selectedYear: any;
  employeeId: number;
  employeeName: any;
  month: any;
  year: any;
  noOfTotalDays: any;
  deductions: any [];
  updatedDeductions: any [];
  deductionss: any [];
  updatedDeductionss: any [];
  payables: [];
  payabless: [];
  totalPayable: any;
  totalPayables: any;
  totalDeduction: any;
  totalDeductions: any;
  totalTax: any;
  totalTaxs: any;
  netPayable: any;
  netPayables: any;
  other: any;
  adhaarNo: any;
  bankAccontNo: any;
  bankName: any;
  joiningDate: any;
  uanNo: any;
  panNo: any;
  userDropdownList = [];
  isAdmin : boolean;
  isTenantCustomised : boolean;
  isStatus : any;
  tenantId: any;
  currentUser: any;
  getUserName: any;
  id: any;
  payslipNo: any;
  netPayableInWords: any;
  noOfPayableDays: any;
  ifscCode: any;
  localId: any;
  resourceType: any;
  esicNo: any;
  currentDesignation: any;
  permanentOrContractor: any;
  educationcess: any;
  hidePrintView: boolean = false;

  constructor(
    private HrService: HrService,
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService,
    private allocationService: AllocationService,
    ) { 
    this.payslipForm = new FormGroup({
      employeeId: new FormControl(''),
      employeeName: new FormControl(''),
      payslipMonth: new FormControl(''),
      payslipYear: new FormControl(''),
      payslipNo: new FormControl(''),
      payslipFinancialYear: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.currentUserId = JSON.parse(this.storageService.get('currentUser'));
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.getUserList();
    const customisedTenants = ['imtechnologies']
    this.isTenantCustomised = customisedTenants.includes(this.tenantId);
    this.isAdmin = this.currentUser.admin ||this.currentUser.roles.includes('RoleFinanceAdministrator') ;
    this.hidePrintView = true;
    // TODO: Should be un-commented while enabling Print Button
    // const printBtn: HTMLElement = document.getElementById('print');
    // printBtn.onclick = function () {
    //     printDiv('registration');
    // };
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  //Gets called when the user selects date
  /*public onKey(event) {
    //Select File
    this.selectedDate = event.target.value;
    console.log('in onDateChanged, selectedFile: ', this.selectedDate);
    //this.onUpload();
  }
  */

  getPayslip() {
    this.payslipMonth = this.payslipForm.get('payslipMonth').value;
    this.payslipYear = this.payslipForm.get('payslipYear').value;
    this.payslipFinancialYear = this.payslipForm.get('payslipFinancialYear').value;
    let employeeId = this.payslipForm.get('employeeId').value.split(' . ')[0];

    if(employeeId){
      this.id = employeeId;
    }else{
      this.id = this.currentUserId.id;
    }

    this.HrService.getUserPaySlip(this.id,this.payslipMonth,this.payslipYear,this.payslipFinancialYear).subscribe(
      (response) => {
        console.log("response",response);
        if (response){
          this.hidePrintView = false;

          // TODO: Should be un-commented while enabling Print Button
          // const printBtn: HTMLElement = document.getElementById('print');
          // printBtn.onclick = function () {
          //     printDiv('registration');
          // };

          this.employeeId = response.employeeId,
          this.employeeName = response.employeeName,
          this.month= response.month;
          this.year= response.year;
          this.noOfTotalDays= response.noOfTotalDays;
          this.deductions= response.deductions;
          this.payables= response.payables;
          this.totalPayable= response.totalPayable;
          this.totalDeduction= response.totalDeduction;
          this.totalTax= response.totalTax;
          this.netPayable= response.netPayable;
          this.other= response.other;
          this.adhaarNo= response.adhaarNo;
          this.bankAccontNo= response.bankAccontNo;
          this.bankName= response.bankName;
          this.joiningDate= response.joiningDate;
          this.uanNo= response.uanNo;
          this.panNo= response.panNo;
          this.payslipNo= response.payslipNo;
          this.netPayableInWords= response.netPayableInWords;
          this.noOfPayableDays= response.noOfPayableDays;
          this.ifscCode= response.ifscCode;
          this.localId= response.localId;
          this.resourceType= response.resourceType;
          this.esicNo= response.esicNo;
          this.currentDesignation= response.currentDesignation;
          this.permanentOrContractor= response.permanentOrContractor;
          console.log("payables", this.payables);
          console.log("deductions", this.deductions);

          if(this.deductions){
            this.educationcess = 0;
            this.updatedDeductions = [];

            this.deductions.forEach(
              (d : any) => {
                if(d.componentName === 'Education Cess'){
                  this.educationcess = d.componentValue;
                }
              }
            );

            if(this.educationcess === 0) {
              this.updatedDeductions = this.deductions;
            }
            else{
              for(let i=0; i<this.deductions.length; i++){
                if(this.deductions[i].componentName === 'TDS'){
                  this.deductions[i].componentValue= this.deductions[i].componentValue + this.educationcess;
                  this.updatedDeductions.push(this.deductions[i]);
                }
                else if(this.deductions[i].componentName === 'Education Cess'){
                  // ignore this
                }
                else{
                  this.updatedDeductions.push(this.deductions[i]);
                }
              }
            }
          }
        }
      },
      (error) => {
        console.log('ERROR: cannot get project by id: ' + error);
      }
    );
    
  }
  

  payslipTillMonth() {
    this.payslipMonth = this.payslipForm.get('payslipMonth').value;
    this.payslipYear = this.payslipForm.get('payslipYear').value;
    this.payslipFinancialYear = this.payslipForm.get('payslipFinancialYear').value;
    let employeeId = this.payslipForm.get('employeeId').value.split(' . ')[0];

    if(employeeId){
      this.id = employeeId;
    }else{
      this.id = this.currentUserId.id;
    }

    this.HrService.getpayslipTillMonth(this.id,this.payslipMonth,this.payslipYear,this.payslipFinancialYear).subscribe(
      (response) => {
        console.log("response",response);
        if (response){
          
          this.deductionss= response.deductions;
          this.payabless= response.payables;
          this.totalPayables= response.totalPayable;
          this.totalDeductions= response.totalDeduction;
          this.totalTaxs= response.totalTax;
          this.netPayables= response.netPayable;
          
          if(this.deductionss){
            this.educationcess = 0;
            this.updatedDeductionss = [];

            this.deductionss.forEach(
              (d : any) => {
                if(d.componentName === 'Education Cess'){
                  this.educationcess = d.componentValue;
                }
              }
            );

            if(this.educationcess === 0) {
              this.updatedDeductionss = this.deductionss;
            }
            else{
              for(let i=0; i<this.deductionss.length; i++){
                if(this.deductionss[i].componentName === 'TDS'){
                  this.deductionss[i].componentValue= this.deductionss[i].componentValue + this.educationcess;
                  this.updatedDeductionss.push(this.deductionss[i]);
                }
                else if(this.deductions[i].componentName === 'Education Cess'){
                  // ignore this
                }
                else{
                  this.updatedDeductionss.push(this.deductionss[i]);
                }
              }
            }
          }
        }
      },
      (error) => {
        console.log('ERROR: cannot get project by id: ' + error);
      }
    ); 
  }

  downloadPDF() {
    let data = document.getElementById('registration');
    html2canvas(data).then(canvas => {
        const contentDataURL = canvas.toDataURL('image/jpeg', 1)  // 'image/jpeg' for lower quality output.
        let pdf = new jsPDF('l', 'cm', 'a4'); //Generates PDF in landscape mode
        pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
        //pdf.save('Payslip.pdf'); // code to download

        var data = new Blob([pdf.output('blob')], {type: 'application/pdf'})
        const payslip = new FormData();
        payslip.append("file", data, "payslip.pdf");
        payslip.append('userId', this.employeeId.toString());
        payslip.append('month', this.payslipForm.get('payslipMonth').value);
        payslip.append('financialYear', this.payslipForm.get('payslipFinancialYear').value);
        this.HrService.sendPayslipByEmail(payslip).subscribe((response: any) => {})
      }
    );
  }

/*
  getUserPaySlip(id,month,year) {
    this.HrService.getUserPaySlip(id,month,year).subscribe(
      (response) => {
        if (response) {
         // this.btappupdateId = response.id;
        }
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }*/

}
