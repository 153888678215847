import { ProjectTask } from './../../_models/project-task.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends Subject<DataStateChangeEventArgs>{

  constructor(private http: HttpClient) {
    super();
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

 //Get All Project tasks
 getAllProjectTasks(projectId): Observable<Object> {
  let params = new HttpParams();
  //params = params.append('filterBy', 'projectId');
  params = params.append('projectId', projectId);
  return this.http.get(`${environment.apiUrl}/projectTasks`, { params: params }).pipe(catchError(this.handleError));
}

// Get ProjectTaskss by Id Get
getProjectTasksById(id: number): Observable<Object> {
  return this.http.get(`${environment.apiUrl}/projectTasks/${id}`).pipe(catchError(this.handleError));
}

 // Create ProjectTasks
 createProjectTasks(user: Object): Observable<Object> {
  console.log("In createContact method, ProjectTasks: ", user);
  return this.http.post(`${environment.apiUrl}/projectTasks`, user).pipe(catchError(this.handleError));
}

//Delete Project by Id Delete
  deleteProjectTasks(id: number): Observable<Object> {
  return this.http.delete(`${environment.apiUrl}/projectTasks/${id}`).pipe(catchError(this.handleError));
}

 //Update Project by Id Put
  updateProjectTasks(user: Object,id: number): Observable<Object> {
  console.log("In createProjectTasks method, user: ", user);
  return this.http.put(`${environment.apiUrl}/projectTasks/${id}`, user).pipe(catchError(this.handleError));
}

   //Synfusion  CRUD Operation
   public execute(state: any): void {
    console.log("Execute ProjectTasks funtion", state);
    console.log("Execute Return Statement", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
    this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs));
  }

    /** GET all data from the server */
    getAllData(state?: any): Observable<any[]> {
      console.log("GetAllData ProjectTasks",this.http.get<ProjectTask[]>(`${environment.apiUrl}/projectTasks`).pipe(retry(2), catchError(this.handleError)));
      return this.http.get<ProjectTask[]>(`${environment.apiUrl}/projectTasks`).pipe(map((response: any) => (<any>{
        result: state.take > 0 ? response.result.slice(0, state.take) : response,
        count: response.length
      })));
    }

    getSystemInformation(): Observable<Object>{
      return this.http.get(`${environment.apiUrl}/pmoSystemInformation`).pipe(catchError(this.handleError));
    }

    getpmoSystemInformationDetails(): Observable<Object>{
      return this.http.get(`${environment.apiUrl}/pmoSystemInformationDetails`).pipe(catchError(this.handleError));
    }

    getProjectTaskForCurrentUser(userId: number): Observable<Object>{
      return this.http.get(`${environment.apiUrl}/projectTasks?userId=${userId}`).pipe(catchError(this.handleError));
    }

    getProjectTaskForCurrentUserByProject(userId: number, projectId: number): Observable<Object>{
      return this.http.get(`${environment.apiUrl}/projectTasks?projectId=${projectId}&userId=${userId}`).pipe(catchError(this.handleError));
    }

    getProjectTaskByStatus(userId: number, projectId: number): Observable<Object>{
      return this.http.get(`${environment.apiUrl}/projectTasks?status=open&projectId=${projectId}&userId=${userId}`).pipe(catchError(this.handleError));
    }

}
