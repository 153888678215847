import { Component } from '@angular/core';
import { SpinnerService } from './_helpers/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(public spinnerService: SpinnerService){
  }

  title = 'Pragamana';
}
