<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> HelpDesk </h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" title="Edit" (click)="updateHelpdeskApp()">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                            </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length === 1">
                                <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteHelpdesk" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div>
                                <button type="button" title="Create" (click)="openHelpdeskApp()"  class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>   
                    <ag-grid-angular
                        style="height: calc(100vh - 120px) !important;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                        
                    </ag-grid-angular>
                    </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Overtime -->
<div class="modal fade bd-example-modal-lg" id="helpDesk" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="helpdeskForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create HelpDesk </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="ticketNo" type="text" required>
                            <label  class="floating-label">Ticket No</label>
                            <i class="ri-file-list-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="title" type="text" required>
                            <label  class="floating-label">Title</label>
                            <i class="ri-file-list-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label"></label>
                            <input
                                list="userName"
                                formControlName="userName"
                                placeholder=" Select User"
                            />
                            <datalist id="userName">
                                <option
                                [value]="item"
                                *ngFor="let item of userDropdownList"
                                ></option>
                            </datalist>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="domainUserName" type="text" required>
                            <label class="floating-label">DomainUserName</label>
                            <i class="ri-file-list-3-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div  class="input-item">
                            <input formControlName="assetType" type="text" required>
                            <label class="floating-label">Asset Type</label>
                            <i class="ri-time-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label"></label>
                            <input
                                list="reportedByName"
                                formControlName="reportedByName"
                                placeholder=" Select reported Name"
                            />
                            <datalist id="reportedByName">
                                <option
                                [value]="item"
                                *ngFor="let item of userDropdownList"
                                ></option>
                            </datalist>
                            <i class="ri-time-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item"> 
                            <label class="floating-label"></label>
                            <input
                                list="diagnosedByName"
                                formControlName="diagnosedByName"
                                placeholder=" Select Diagnosed Name"
                            />
                            <datalist id="diagnosedByName">
                                <option
                                [value]="item"
                                *ngFor="let item of userDropdownList"
                                ></option>
                            </datalist>
                            <i class="ri-shield-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="actionTakenDetails" type="text" required>
                            <label for="actionTakenDetails" class="floating-label">Action Taken Details</label>
                            <i class="ri-time-line"></i>
                        </div>
                    </div>
                    <!-- <div class="input">
                        <div class="input-item">
                            <input formControlName="costCalculatedByName" type="text" required>
                            <label for="costCalculatedByName" class="floating-label">Cost Calculated By Name</label>
                            <i class="ri-time-line"></i>
                        </div>
                    </div> -->
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">
                                 </label
                              >
                              <select formControlName="finalStatus">
                                <option value="" disabled selected>
                                  Select Status
                                </option>
                                <option value="Open">Open</option>
                                <option value="Pending">Pending</option>
                                <option value="On hold">On hold</option>
                                <option value="Solved">Solved</option>
                                <option value="Closed">Closed</option>
                                <option value="Spam">Spam</option>
                              </select>
                            <i class="ri-timer-flash-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input  type="file" (change)="onFileChanged($event)" placeholder="Attachment" required>
                            <label for="attachment" class="floating-label"></label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div *ngFor="let item of hdattachment">
                        <table style="border: 1px dotted blue; border-radius: 10px">
                          <tbody>
                            <tr>
                              <td>
                                <span>{{ item }}</span>
                              </td>
                              <td>
                                <div class="delete">
                                  <a href="url"></a>
                                  <button
                                    type="button"
                                    (click)="
                                    downloadhdApplicationsattachments(item)
                                    "
                                  >
                                    <span
                                      ><i class="ri-download-cloud-fill"></i
                                    ></span>
                                  </button>
                                </div>
                              </td>
                              <td>
                                <div class="delete">
                                  <button
                                    type="button"
                                    (click)="
                                      deletehdApplicationsattachments(item)
                                    "
                                  >
                                    <span
                                      ><i class="ri-delete-bin-5-line"></i
                                    ></span>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="closeModalOvertime" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createHelpdesk()" >Create</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)=" updateHelpdesk()" >Update</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteHelpdesk" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteOvertime" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteHelpdesk()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>
