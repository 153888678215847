<main class="main-dashboard">
  <aside class="sidebar" [ngClass]="sidebarsmaller ? 'sidebarsmaller' : ''">
    <div class="sidebar-header">
      <div class="sidebar-logo">
        <a >
          <img src="assets/logo/logo-white.png" alt="" />
          <img src="assets/logo/logo-small.png" class="d-none" />
        </a>
       
      </div>
    </div>
    <div class="sidebar-item">
      <ul *ngIf="menuFlag == 'ITM'">
        <li class="active-menu">
          <p>
            <a routerLink="itm/it-dashboard" [routerLinkActive]="['active']"
              ><span><i class="ri-user-2-line"></i></span><span>ITM</span></a
            >
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="itm/it-asset" [routerLinkActive]="['active']">
              <span><i class="ri-filter-line"></i></span
              ><span class="local">Asset Management</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isAdmin">
          <p>
            <a routerLink="itm/it-asset" [routerLinkActive]="['active']">
              <span><i class="ri-filter-line"></i></span
              ><span class="local">Asset Management</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="itm/helpdesk" [routerLinkActive]="['active']">
              <span><i class="ri-list-check-2"></i></span
              ><span class="local">Helpdesk Ticket</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && (this.isAdmin||this.isItUser||this.isHelpdeskUser)">
          <p>
            <a routerLink="itm/helpdesk" [routerLinkActive]="['active']">
              <span><i class="ri-list-check-2"></i></span
              ><span class="local">Helpdesk Ticket</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="itm/softwarelist" [routerLinkActive]="['active']">
              <span><i class="ri-filter-line"></i></span
              ><span class="local">Software List</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isAdmin">
          <p>
            <a routerLink="itm/softwarelist" [routerLinkActive]="['active']">
              <span><i class="ri-filter-line"></i></span
              ><span class="local">Software List</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="itm/vendor" [routerLinkActive]="['active']">
              <span><i class="ri-list-check-2"></i></span
              ><span class="local">Vendor List</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isAdmin">
          <p>
            <a routerLink="itm/vendor" [routerLinkActive]="['active']">
              <span><i class="ri-list-check-2"></i></span
              ><span class="local">Vendor List</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="itm/onboarding" [routerLinkActive]="['active']">
              <span><i class="ri-book-mark-fill"></i></span
              ><span class="local">Onboarding Process</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isAdmin">
          <p>
            <a routerLink="itm/onboarding" [routerLinkActive]="['active']">
              <span><i class="ri-book-mark-fill"></i></span
              ><span class="local">Onboarding Process</span>
            </a>
          </p>
        </li>
       
      </ul>
      
      <ul *ngIf="menuFlag == 'CRM'">
        <li class="active-menu">
          <p>
            <a href="">
              <span><i class="ri-line-chart-line"></i></span><span>CRM</span>
            </a>
          </p>
        </li>
        <li >
          <p>
            <a routerLink="crm/lead" [routerLinkActive]="['active']">
              <span data-toggle="tooltip" data-placement="right" title="Leads"
                ><i class="ri-line-chart-line"></i></span
              ><span class="local">Leads</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="crm/contact" [routerLinkActive]="['active']">
              <span><i class="ri-group-line"></i></span
              ><span class="local">Contacts</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="crm/account" [routerLinkActive]="['active']">
              <span><i class="ri-user-line"></i></span
              ><span class="local">Account</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="crm/cases" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-3-fill"></i></span
              ><span class="local">Cases</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="opportunities" [routerLinkActive]="['active']">
              <span><i class="ri-hand-heart-line"></i></span
              ><span class="local">Opportunity</span>
            </a>
          </p>
        </li>
        <!-- <li>
          <p>
            <a routerLink="googleDrive" [routerLinkActive]="['active']">
              <span><i class="ri-drive-fill"></i></span
              ><span class="local">Google Drive</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="crm/product-master" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-line"></i></span
              ><span class="local">Product Master</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="crm/price-book" [routerLinkActive]="['active']">
              <span><i class="ri-price-tag-3-fill"></i></span
              ><span class="local">Price Book</span>
            </a>
          </p>
        </li> -->
        <li>
          <p>
            <a routerLink="crm/sales-task-list" [routerLinkActive]="['active']">
              <span><i class="ri-shopping-bag-line"></i></span
              ><span class="local">Sales Task</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="crm/campaign" [routerLinkActive]="['active']">
              <span><i class="ri-mail-volume-fill"></i></span
              ><span class="local">Campaign</span>
            </a>
          </p>
        </li>
      </ul>

      <ul *ngIf="menuFlag == 'Adminstration'">
        <li class="active-menu">
          <p>
            <a href="">
              <span><i class="ri-user-settings-line"></i></span
              ><span>Administration</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="adminstration/users" [routerLinkActive]="['active']">
              <span><i class="ri-user-line"></i></span
              ><span class="local">User</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isHrAdmin">
          <p>
            <a routerLink="adminstration/users" [routerLinkActive]="['active']">
              <span><i class="ri-user-line"></i></span
              ><span class="local">User</span>
            </a>
          </p>
        </li>
        <!-- <li>
          <p>
            <a routerLink="adminstration/costrate" [routerLinkActive]="['active']">
              <span><i class="ri-medal-line"></i></span
              ><span class="local">Standard Values</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="adminstration/costcenter" [routerLinkActive]="['active']">
              <span><i class="ri-money-dollar-circle-line"></i></span
              ><span class="local">Cost Center</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="adminstration/softwarerates" [routerLinkActive]="['active']">
              <span><i class="ri-money-dollar-circle-line"></i></span
              ><span class="local">Software Rate</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="adminstration/custom-tags" [routerLinkActive]="['active']">
              <span><i class="ri-bookmark-3-line"></i></span
              ><span class="local">Custom Tags</span>
            </a>
          </p>
        </li> -->
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="adminstration/product-structure" [routerLinkActive]="['active']">
              <span><i class="ri-product-hunt-line"></i></span
              ><span class="local">Product Structure</span>
            </a>
          </p>
        </li>
          <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
            <p>
              <a routerLink="adminstration/product-structure" [routerLinkActive]="['active']">
                <span><i class="ri-product-hunt-line"></i></span
                ><span class="local">Product Structure</span>
              </a>
            </p>
          </li>
          <li *ngIf="!this.isTenantCustomised">
            <p>
              <a routerLink="adminstration/notification" [routerLinkActive]="['active']">
                <span><i class="ri-notification-fill"></i></span
                ><span class="local">Notification</span>
              </a>
            </p>
          </li>
          <li *ngIf="this.isTenantCustomised && this.isHrAdmin">
            <p>
              <a routerLink="adminstration/notification" [routerLinkActive]="['active']">
                <span><i class="ri-notification-fill"></i></span
                ><span class="local">Notification</span>
              </a>
            </p>
          </li>
          <li  *ngIf="!this.isTenantCustomised">
            <p>
              <a routerLink="adminstration/performance-template" [routerLinkActive]="['active']">
                <span><i class="ri-bookmark-3-fill"></i></span
                ><span class="local">Performance Temp</span>
              </a>
            </p>
          </li>
          <li *ngIf="this.isTenantCustomised && this.isHrAdmin">
            <p>
              <a routerLink="adminstration/performance-template" [routerLinkActive]="['active']">
                <span><i class="ri-bookmark-3-fill"></i></span
                ><span class="local">Performance Temp</span>
              </a>
            </p>
          </li>
          <li *ngIf="!this.isTenantCustomised">
            <p>
              <a routerLink="adminstration/global-setting" [routerLinkActive]="['active']">
                <span><i class="ri-global-line"></i></span
                ><span class="local">Global Setting</span>
              </a>
            </p>
          </li>
          <li *ngIf="this.isTenantCustomised && this.isHrAdmin">
            <p>
              <a routerLink="adminstration/global-setting" [routerLinkActive]="['active']">
                <span><i class="ri-global-line"></i></span
                ><span class="local">Global Setting</span>
              </a>
            </p>
          </li>
          <li *ngIf="!this.isTenantCustomised">
            <p>
              <a routerLink="adminstration/onsite-employee" [routerLinkActive]="['active']">
                <span><i class="ri-building-line"></i></span
                ><span class="local">Onsite Employee</span>
              </a>
            </p>
          </li>
          <li *ngIf="this.isTenantCustomised && this.isHrAdmin">
          <p>
            <a routerLink="adminstration/onsite-employee" [routerLinkActive]="['active']">
              <span><i class="ri-building-line"></i></span
              ><span class="local">Onsite Employee</span>
            </a>
          </p>
        </li>
      </ul>

      <ul *ngIf="menuFlag == 'Project Management'" [routerLinkActive]="['active']">
        <li class="active-menu">
          <p>
            <a href="">
              <span><i class="ri-file-list-3-line"></i></span
              ><span>Project Management</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="project/myproject" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-2-line"></i></span
              ><span class="local">My Project</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="project/changerequest" [routerLinkActive]="['active']">
              <span><i class="ri-user-settings-line"></i></span
              ><span class="local">Change Request</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="project/changerequest" [routerLinkActive]="['active']">
              <span><i class="ri-user-settings-line"></i></span
              ><span class="local">Change Request</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="project/capacity-planning" [routerLinkActive]="['active']">
              <span><i class="ri-file-settings-line"></i></span
              ><span class="local">Capacity Planning</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="project/capacity-planning" [routerLinkActive]="['active']">
              <span><i class="ri-file-settings-line"></i></span
              ><span class="local">Capacity Planning</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="resource-utilization/resource-allocation" [routerLinkActive]="['active']">
              <span><i class="ri-list-settings-line"></i></span
              ><span class="local">Resource Allocation</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="resource-utilization/resource-allocation" [routerLinkActive]="['active']">
              <span><i class="ri-list-settings-line"></i></span
              ><span class="local">Resource Allocation</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="project/risk-management" [routerLinkActive]="['active']">
              <span><i class="ri-file-settings-line"></i></span
              ><span class="local">Risk Management</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="project/risk-management" [routerLinkActive]="['active']">
              <span><i class="ri-file-settings-line"></i></span
              ><span class="local">Risk Management</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="project/annual-maintenance-contracts" [routerLinkActive]="['active']">
              <span><i class="ri-list-check-2"></i></span
              ><span class="local">AMC</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="project/annual-maintenance-contracts" [routerLinkActive]="['active']">
              <span><i class="ri-list-check-2"></i></span
              ><span class="local">AMC</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="project/usit" [routerLinkActive]="['active']">
              <span><i class="ri-user-fill"></i></span
              ><span class="local">USI</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="project/usit" [routerLinkActive]="['active']">
              <span><i class="ri-user-fill"></i></span
              ><span class="local">USI</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="project/function-list" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-3-fill"></i></span
              ><span class="local">Feature List</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isPmoAdmin">
          <p>
            <a routerLink="project/function-list" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-3-fill"></i></span
              ><span class="local">Feature List</span>
            </a>
          </p>
        </li>
      </ul>

      <ul *ngIf="menuFlag == 'HRM'">
        <li class="active-menu">
          <p>
            <a routerLink="hr/hr-dashboard" [routerLinkActive]="['active']"
              ><span><i class="ri-user-2-line"></i></span><span>HRM</span></a
            >
          </p>
        </li>
        <li>
          <p>
            <a routerLink="hr/attendance" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Attendence</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="hr/leaves/leave-profile" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-3-line"></i></span
              ><span class="local">Leave Profile</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/overtime" [routerLinkActive]="['active']">
              <span><i class="ri-file-user-line"></i></span
              ><span class="local">my Overtime</span>
            </a>
          </p>
        </li>
        <li >
          <p>
            <a routerLink="hr/timesheet/time-sheet" [routerLinkActive]="['active']">
              <span><i class="ri-time-line"></i></span
              ><span class="local">My Timesheet</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/approval-hr/approval-status-hr" [routerLinkActive]="['active']">
              <span><i class="ri-user-line"></i></span
              ><span class="local">Approval Page</span>
            </a>
          </p>
        </li>
          <li *ngIf="this.isTenantCustomised && (this.isHrAdmin)">
            <p>
              <a routerLink="hr/approval-hr/approval-status-hr" [routerLinkActive]="['active']">
                <span><i class="ri-user-line"></i></span
                ><span class="local">Approval Page</span>
              </a>
            </p>
          </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/btlanding" [routerLinkActive]="['active']">
              <span><i class="ri-briefcase-2-line"></i></span
              ><span class="local">My Travel</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="hr/goal-profile-hr/goal-profile" [routerLinkActive]="['active']">
              <span><i class="ri-star-line"></i></span
              ><span class="local">Goals Profile</span>
            </a>
          </p>
        </li>
        <!-- <li>
          <p>
            <a routerLink="hr/goal-profile-hr/skill-matrix" [routerLinkActive]="['active']">
              <span><i class="ri-star-line"></i></span
              ><span class="local">Skill Matrix</span>
            </a>
          </p>
        </li> -->
        
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/employee" [routerLinkActive]="['active']">
              <span><i class="ri-user-line"></i></span
              ><span class="local">Our Employee</span>
            </a>
          </p>
        </li>
        <li>
          <li *ngIf="this.isTenantCustomised && (this.isHrAdmin)">
            <p>
              <a routerLink="hr/employee" [routerLinkActive]="['active']">
                <span><i class="ri-user-line"></i></span
                ><span class="local">Our Employee</span>
              </a>
            </p>
          </li>
          <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/visitor-register" [routerLinkActive]="['active']">
              <span><i class="ri-folder-user-line"></i></span
              ><span class="local">Our Guestbook</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isHrAdmin">
          <p>
            <a routerLink="hr/visitor-register" [routerLinkActive]="['active']">
              <span><i class="ri-folder-user-line"></i></span
              ><span class="local">Our Guestbook</span>
            </a>
          </p>
        </li>
       
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/training-process/training" [routerLinkActive]="['active']">
              <span><i class="ri-book-open-fill"></i></span
              ><span class="local">Training</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/training-process/training-catalog" [routerLinkActive]="['active']">
              <span><i class="ri-list-ordered"></i></span
              ><span class="local">Training Catalog</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/training-process/training-session" [routerLinkActive]="['active']">
              <span><i class="ri-book-mark-fill"></i></span
              ><span class="local">Training Session</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/recruite" [routerLinkActive]="['active']">
              <span><i class="ri-book-mark-fill"></i></span
              ><span class="local">Recruite</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/onboarding-component/onboarding" [routerLinkActive]="['active']">
              <span><i class="ri-book-mark-fill"></i></span
              ><span class="local">Onboarding Process</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && (this.isHrAdmin || this.isNDAAdmin)">
          <p>
            <a routerLink="hr/onboarding-component/onboarding" [routerLinkActive]="['active']">
              <span><i class="ri-book-mark-fill"></i></span
              ><span class="local">Onboarding Process</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="hr/exit-formalities/exit-process" [routerLinkActive]="['active']">
              <span><i class="ri-briefcase-4-line"></i></span
              ><span class="local">Exit Process</span>
            </a>
          </p>
        </li>
        <li>
          <p>
            <a routerLink="hr/exit-formalities/exit-process" [routerLinkActive]="['active']">
              <span><i class="ri-briefcase-4-line"></i></span
              ><span class="local">Exit Process</span>
            </a>
          </p>
        </li>
        <li >
          <p>
            <a routerLink="hr/hiring-process-profile/hiring-process" [routerLinkActive]="['active']">
              <span><i class="ri-article-line"></i></span
              ><span class="local">Hiring Process</span>
            </a>
          </p>
        </li>
        <!-- <li><p><a><span><i class="ri-book-line"></i></span><span class="local">HelpDesk</span> </a></p></li> -->
        <!-- <li><p><a><span><i class="ri-filter-line"></i></span><span class="local">Resume Sorting</span> </a></p></li> -->
      </ul>

      <ul *ngIf="menuFlag == 'Finance'">
        
        <li class="active-menu">
          <p>
            <a href="">
              <span><i class="ri-line-chart-line"></i></span><span>Finance</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/payslip" [routerLinkActive]="['active']">
              <span><i class="ri-money-dollar-circle-fill"></i></span>
              <span class="local">Payslip</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && (this.isFinanceAdmin||this.isFinanceUser)">
          <p>
            <a routerLink="finance/payslip" [routerLinkActive]="['active']">
              <span><i class="ri-money-dollar-circle-fill"></i></span>
              <span class="local">Payslip</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/tax" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-circle-line"></i></span 
              ><span class="local">Tax Slab</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isFinanceAdmin">
          <p>
            <a routerLink="finance/tax" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-circle-line"></i></span
              ><span class="local">Tax Slab</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/salary-sheet" [routerLinkActive]="['active']">
              <span><i class="ri-money-dollar-circle-fill"></i></span
              ><span class="local">Salary Sheet</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isFinanceAdmin">
          <p>
            <a routerLink="finance/salary-sheet" [routerLinkActive]="['active']">
              <span><i class="ri-money-dollar-circle-fill"></i></span
              ><span class="local">Salary Sheet</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/tax-ctc" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-box-line"></i></span
              ><span class="local">User-CTC</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isFinanceAdmin">
          <p>
            <a routerLink="finance/tax-ctc" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-box-line"></i></span
              ><span class="local">User-CTC</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/salary-distribustion" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-box-line"></i></span
              ><span class="local">Salary-Distribustion</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isFinanceAdmin">
          <p>
            <a routerLink="finance/salary-distribustion" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-box-line"></i></span
              ><span class="local">Salary-Distribustion</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/tax-deductions-two" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-circle-line"></i></span
              ><span class="local">Tax Exemption</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isFinanceAdmin">
          <p>
            <a routerLink="finance/tax-deductions-two" [routerLinkActive]="['active']">
              <span><i class="ri-money-cny-circle-line"></i></span
              ><span class="local">Tax Exemption</span>
            </a>
          </p>
        </li>
        <li  *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="finance/tax-regim-report" [routerLinkActive]="['active']">
              <span><i class="ri-folder-chart-fill"></i></span
              ><span class="local">Tax Report</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isFinanceAdmin">
          <p>
            <a routerLink="finance/tax-regim-report" [routerLinkActive]="['active']">
              <span><i class="ri-folder-chart-fill"></i></span
              ><span class="local">Tax Report</span>
            </a>
          </p>
        </li>
        
      </ul>

      <ul *ngIf="menuFlag == 'Home'">
        <li class="active-menu">
          <p>
            <a 
              ><span><i class="ri-home-7-line"></i></span><span>Home</span></a
            >
          </p>
        </li>
      </ul>

      <ul *ngIf="menuFlag == 'My Approval'">
        <li class="active-menu">
          <p>
            <a href="">
              <span><i class="ri-user-2-line"></i></span
              ><span>My Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/leave" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-3-line"></i></span
              ><span class="local">Leave Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/leave" [routerLinkActive]="['active']">
              <span><i class="ri-file-list-3-line"></i></span
              ><span class="local">Leave Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/overtime" [routerLinkActive]="['active']">
              <span><i class="ri-timer-line"></i></span
              ><span class="local">Overtime Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/overtime" [routerLinkActive]="['active']">
              <span><i class="ri-timer-line"></i></span
              ><span class="local">Overtime Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/timesheet" [routerLinkActive]="['active']">
              <span><i class="ri-time-line"></i></span
              ><span class="local">Timesheet Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/timesheet" [routerLinkActive]="['active']">
              <span><i class="ri-time-line"></i></span
              ><span class="local">Timesheet Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/attendance-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Compoff Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/attendance-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Compoff Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/regularization-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Regularization Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/regularization-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Regularization Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/short-leave-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Short Leave Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/short-leave-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Short Leave Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/performance-objectiveview" [routerLinkActive]="['active']">
              <span><i class="ri-bar-chart-2-fill"></i></span
              ><span class="local">Performance Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/performance-objectiveview" [routerLinkActive]="['active']">
              <span><i class="ri-bar-chart-2-fill"></i></span
              ><span class="local">Performance Approval</span>
            </a>
          </p>
        </li>
        <!-- <li>
          <p>
            <a routerLink="approval/dicision-approval" [routerLinkActive]="['active']">
              <span><i class="ri-time-line"></i></span
              ><span class="local">Decision Approval</span>
            </a>
          </p>
        </li> -->
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/onboarding-approval" [routerLinkActive]="['active']">
              <span><i class="ri-time-line"></i></span
              ><span class="local">Onboarding Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/onboarding-approval" [routerLinkActive]="['active']">
              <span><i class="ri-time-line"></i></span
              ><span class="local">Onboarding Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="!this.isTenantCustomised">
          <p>
            <a routerLink="approval/resignation-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Resignation Approval</span>
            </a>
          </p>
        </li>
        <li *ngIf="this.isTenantCustomised && this.isApprover">
          <p>
            <a routerLink="approval/resignation-approval" [routerLinkActive]="['active']">
              <span><i class="ri-calendar-2-line"></i></span
              ><span class="local">Resignation Approval</span>
            </a>
          </p>
        </li>
      </ul>
    </div>
  </aside>
  <article class="main-canvas" [ngClass]="sidebarbigger ? 'sidebarbigger' : ''">
    <section class="canvas-container">
      <header class="dashboard-header">
        <div class="header-items">
          <div class="header-title">
            <div class="d-flex align-items-center gap-3">
              <button (click)="openCloseInnerSidebarsmaller()" class="sidebar-closer border-0">
                <img src="assets/svg/arrow.svg" alt="" />
              </button>
              <h4>Dashboard</h4>
            </div>
          </div>
          <div class="header-profile">
            <!-- <div class="header-menu" >
              <select name="" id="" (change)="changeMenu($event)">
                <option value="6" selected >Home</option>
                <option value="1">Project Management</option>
                <option value="2">CRM</option>
                <option value="3">HRM</option>
                <option value="4">ITM</option>
                <option value="5">Adminstration</option>
              </select>
            </div> -->

            <div class="header-menu">
              <div class="mega-menu-selector">
                <p>
                  <span *ngIf="menuFlag == 'Home'" >Home</span>
                  <span *ngIf="menuFlag == 'Project Management'" >PMO</span>
                  <span *ngIf="menuFlag == 'CRM'" >CRM</span>
                  <span *ngIf="menuFlag == 'HRM'" >HRM</span>
                  <span *ngIf="menuFlag == 'ITM'" >ITSM</span>
                  <span *ngIf="menuFlag == 'Finance'" >Finance</span>
                  <span *ngIf="menuFlag == 'Adminstration'" >Admin</span>
                  <span *ngIf="menuFlag == 'My Approval'" >My Approval</span>
                  <i class="ri-arrow-down-s-line"></i>
                </p>
              </div>
              <div class="mega-menu">
                <div class="row">
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'Home'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(6)">
                            <i class="ri-home-3-line"></i>
                            <span>Home</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'Home'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(6)">
                            <i class="ri-home-3-line"></i>
                            <span>Home</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'Project Management'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(1)">
                            <i class="ri-file-list-line"></i>
                            <span>PMO</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'Project Management'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(1)">
                            <i class="ri-file-list-line"></i>
                            <span>PMO</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'CRM'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(2)">
                            <i class="ri-computer-line"></i>
                            <span>CRM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'CRM'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(2)">
                            <i class="ri-computer-line"></i>
                            <span>CRM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'HRM'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(3)">
                            <i class="ri-group-line"></i>
                            <span>HRM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'HRM'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(3)">
                            <i class="ri-group-line"></i>
                            <span>HRM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'ITM'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(4)">
                            <i class="ri-code-s-slash-fill"></i>
                            <span>ITSM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'ITM'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(4)">
                            <i class="ri-code-s-slash-fill"></i>
                            <span>ITSM</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'Adminstration'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(5)">
                            <i class="ri-tools-line"></i>
                            <span>Admin</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'Adminstration'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(5)">
                            <i class="ri-tools-line"></i>
                            <span>Admin</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div *ngIf="menuFlag == 'Finance'">
                      <div class="mega-menu-item active">
                        <div class="box">
                          <a (click)="changeMenu(8)">
                            <i class="ri-bank-fill"></i>
                            <span>Finance</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="menuFlag != 'Finance'">
                      <div class="mega-menu-item">
                        <div class="box">
                          <a (click)="changeMenu(8)">
                            <i class="ri-bank-fill"></i>
                            <span>Finance</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="header-notification">
              <button>
                <span class="notification-counter"></span>
                <i class="ri-notification-line"></i>
              </button>
            </div>
            <div class="header-avtar">
              <div class="avtar-item">
                <p>
                  Welcome, <strong>{{ currentUser.displayName }}!</strong>
                </p>
                <img [src]="retrievedImage" alt="profile_pic" />
                <i class="ri-arrow-down-s-fill"></i>
              </div>
              <div class="avtar-option">
                <ul>
                  <li>
                    <a routerLink="profile/profile-page"
                      ><i class="ri-user-line"></i> My Profile</a
                    >
                  </li>
                  <li>
                    <a (click)="changeMenu(7)"
                      ><i class="ri-thumb-up-fill"></i> My Approval</a
                    >
                  </li>
                  <li>
                    <a routerLink="logout"
                      ><i class="ri-login-box-line"></i> Logout</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section class="dashboard-items">
        <router-outlet></router-outlet>
      </section>
    </section>
  </article>
</main>
