import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class TaxdeductionService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) { 
    super();
    this.httpClient = new HttpClient(handler);

  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }

   // Create TaxDeduction
   createTaxDeduction(user: Object): Observable<Object> {
    console.log("In createSoftwares method, TaxDeduction: ", user);
    return this.http.post(`${environment.apiUrl}/taxExemptions`, user).pipe(catchError(this.handleError));
  }

  // Get TaxDeduction by Id Get
  getTaxDeduction(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/taxExemptions/${id}`).pipe(catchError(this.handleError));
  }

  //Delete TaxDeduction by Id Delete
  deletetTaxDeduction(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/taxExemptions/${id}`).pipe(catchError(this.handleError));
  }

  //Update TaxDeduction by Id Put
  updateTaxDeduction(user: Object,id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/taxExemptions/${id}`, user).pipe(catchError(this.handleError));
  }

  public getTaxDeductionById(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}/taxExemptions/${Id}`).pipe(catchError(this.handleError));
  }

   //Get All TaxDeduction by Id Get
   getAllTaxDeduction(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/taxExemptions`).pipe(catchError(this.handleError));
  }


}
