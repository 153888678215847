import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { environment } from "../../../environments/environment";
import { catchError, map, retry } from 'rxjs/operators';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectTaskService extends Subject<DataStateChangeEventArgs> {
  constructor(
    private handler: HttpBackend,
    private http: HttpClient,
    private storageService: LocalStorageService,
  ) {
    super();
   }

    handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // Create ProjectTask
   createProjectTask(user): Observable<Object> {
    console.log("In createprojectTasks method, projectTasks: ", user);
    return this.http.post(`${environment.apiUrl}/pmoSystemInformationImport`, user).pipe(catchError(this.handleError));
  }

     //Delete projectTasks by Id Delete
   deleteProjectTask(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/projectTasks/${id}`).pipe(catchError(this.handleError));
  }

   //Update projectTasks by Id Put
   updateProjectTask(user: Object,id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/projectTasks/${id}`, user).pipe(catchError(this.handleError));
  }

    //Get All projectTasks
   getAllProjectTask(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projectTasks`).pipe(catchError(this.handleError));
  }

  //Get All projectTasks by Id
  getAllProjectTaskByProjectId(projectId): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projectTasks?projectId=${projectId}`).pipe(retry(2), catchError(this.handleError));
  }


  //Get All campaigns 
  exportProductInformation(): Observable<Object> {
    return this.http.get<Blob>(`${environment.apiUrl}/pmoSystemInformationExport`,{observe: 'response' ,responseType: 'blob' as 'json'})
    // return this.http.get(`${environment.apiUrl}/pmoSystemInformationExport`).pipe(catchError(this.handleError));
  }
  
  importProductStructure(payload: FormData): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/pmoSystemInformationImport`, payload).pipe(catchError(this.handleError));
  }

  uploadProjectTaskAttachments(taskId: number, data: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.http.post(`${environment.apiUrl}/projectTasks/` + taskId + `/attachments`, data, header).pipe(catchError(this.handleError));
  }

  public getProjectTaskAttachments(taskId: number) {
    return this.http.get<any>(`${environment.apiUrl}/projectTasks/${taskId}/attachments`).pipe(map((result) => {return result;}),catchError(this.handleError));
  }

  public deleteProjectTaskAttachments(taskId: any, attachmentId: number) {
    return this.http.delete<any>(`${environment.apiUrl}/projectTasks/${taskId}/attachments/${attachmentId}`).pipe(map((result) => {return result;}),catchError(this.handleError));
  }

  public downloadProjectTaskAttachments(taskId: any, attachmentId: number): any {
    return this.http.get<Blob>(`${environment.apiUrl}/projectTasks/${taskId}/attachments/${attachmentId}`,{observe: 'response' ,responseType: 'blob' as 'json'})
  }


}

