import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import {
  GridModule,
  EditService,
  ToolbarService,
  PagerModule,
  GroupService,
  ColumnChooserService,
  CommandColumnService,
  PageService,
  FilterService,
  ExcelExportService,
  SortService,
} from '@syncfusion/ej2-angular-grids';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { AngularMaterialModule } from './angular-material.module';
import { AppRoutingModule } from './app-routing.module';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import {
  LineSeriesService,
  ChartAllModule,
  AccumulationChartAllModule,
  RangeNavigatorAllModule,
  CategoryService,
  ColumnSeriesService,
  ExportService,
  LegendService,
  DataLabelService,
} from '@syncfusion/ej2-angular-charts';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/_shared/login/login.component';
import { RegisterComponent } from './components/_shared/register/register.component';
import { ForgotPasswordComponent } from './components/_shared/forgot-password/forgot-password.component';
import { LogoutComponent } from './components/logout/logout.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CommonModule, DatePipe } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { InPlaceEditorAllModule } from '@syncfusion/ej2-angular-inplace-editor';
import { DatagridtestComponent } from './components/datagridtest/datagridtest.component';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { AuthInterceptorService } from './_helpers/auth-interceptor.service';
import {
  ButtonModule,
  CheckBoxModule,
  RadioButtonModule,
  SwitchModule,
} from '@syncfusion/ej2-angular-buttons';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSortModule } from '@angular/material/sort';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
  GanttAllModule,
  GanttModule,
  SelectionService,
} from '@syncfusion/ej2-angular-gantt';
import { MatStepperModule } from '@angular/material/stepper';
import { GoogledriveComponent } from './components/dashboard/googledrive/googledrive.component';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'ag-grid-enterprise';
import { NgxPaginationModule } from 'ngx-pagination';
import {
  FileManagerModule,
  NavigationPaneService,
  DetailsViewService,
} from '@syncfusion/ej2-angular-filemanager';
import { HttpModule, JsonpModule } from '@angular/http';
import { ActionItemsGridRowRendererComponent } from './components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { PricingTableComponent } from './components/_shared/pricing-table/pricing-table.component';
import { ScheduleModule, AgendaService, DayService, WeekService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { HelpdeskapplicationComponent } from './components/dashboard/itm/helpdeskapplication/helpdeskapplication.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    RegisterComponent,
    LogoutComponent,
    DashboardComponent,
    FooterComponent,
    DatagridtestComponent,
    ForgotPasswordComponent,
    GoogledriveComponent,
    ActionItemsGridRowRendererComponent,
    PricingTableComponent,
    HelpdeskapplicationComponent,
  ],
  imports: [
    FileManagerModule,
    HttpModule,
    JsonpModule,
    BrowserModule,
    CommonModule,
    SwitchModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FlexLayoutModule,
    LayoutModule,
    // AgGridModule.withComponents([]),
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    GridModule,
    PagerModule,
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    TabModule,
    TreeGridModule,
    InPlaceEditorAllModule,
    RangeNavigatorAllModule,
    TabModule,
    DropDownListAllModule,
    DialogModule,
    DashboardModule,
    DashboardLayoutModule,
    MatTableModule,
    MatPaginatorModule,
    DropDownListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSortModule,
    MatStepperModule,
    NgxPermissionsModule.forRoot(),
    GanttAllModule,
    GanttModule,
    ChartModule,
    NgbModule,
    DataTablesModule,
    // AgGridModule.withComponents([])
    ScheduleModule,
    NgxPaginationModule,
    // DataTablesModule
  ],
  entryComponents: [DashboardComponent],
  providers: [
    NavigationPaneService,
    ToolbarService,
    DetailsViewService,
    EditService,
    ToolbarService,
    GroupService,
    ColumnChooserService,
    PageService,
    FilterService,
    CommandColumnService,
    ExcelExportService,
    SortService,
    SelectionService,
    DatePipe,
    CategoryService,
    LineSeriesService,
    CategoryService,
    ColumnSeriesService,
    ExportService,
    LegendService,
    DataLabelService,
    AgendaService, 
    DayService, 
    WeekService, 
    WorkWeekService, 
    MonthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
  ],
  exports: [MatSortModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
