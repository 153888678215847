import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { JobPostingService } from 'src/app/_services/job-posting/job-posting.service';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-job-description',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.scss']
})
export class JobDescriptionComponent implements OnInit {
  form: FormGroup;
  jobDescriptionForm;
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[];
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  selectedRow: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  gridApi: any;
  gridColumnApi: any; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  tenantId: string;
  currentUser: any;
  deleteConfirmationMsg = 'Are you sure you want to delete';
  postedJobCount: any;
  openJobCount: any;
  closedJobCount: any;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private localStorageService: LocalStorageService,
    private router: Router,
    private jobPostingService: JobPostingService,
    ) { 
      {
        this.defaultColDef = {
          flex: 1,
          minWidth: 200,
          resizable: true,
        };
        this.rowSelection = 'multiple';
      this.columnDefs = this.createColumnDefs();
  
      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };
      
      }
      this.jobDescriptionForm = this.formBuilder.group({
        id: new FormControl(''),
        title: new FormControl(''),
        workMode: new FormControl(''),
        location: new FormControl(''),
        jobType: new FormControl(''),
        expectedExperience: new FormControl(''),
        expectedQualifications: new FormControl(''),
        expectedSalary: new FormControl(''),
        skills: new FormControl(''),
        active: new FormControl(''),
        description: new FormControl(''),
        emailForApplicants: new FormControl(''),
        jobLink: new FormControl(''),
        creationDate: new FormControl('')
      })
    }

  ngOnInit(): void {
    this.tenantId = this.localStorageService.get('tenantId');
    this.currentUser = JSON.parse(this.localStorageService.get('currentUser'));
    this.getJobDescription()
  }

  getJobDescription(){
    this.jobPostingService.getAllJobDescriptions().subscribe((response: any) => {
      this.rowData = response;
      let data = response;
      this.postedJobCount = response.length;
      this.openJobCount = 0;
      this.closedJobCount = 0;
      for(let i=0; i<data.length; i++){
        if(data[i].active === true){
          this.openJobCount = this.openJobCount + 1;
        } else if(data[i].active === false){
          this.closedJobCount = this.closedJobCount + 1;
        }
      }
    });
  }

  get f() {
    return this.jobDescriptionForm.controls;
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Job Posting",
        field: "title" ,'width': 150, 'suppressSizeToFit': true,
        mimWidth: 300 ,
        filter: true,
        editable: false,
        sortable: true,
        cellRenderer: this.createHyperLink.bind(this),
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Description',
        field: 'description',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Status',
        field: 'active',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Location',
        field: 'location',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Job Type',
        field: 'jobType',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Email for Applicant',
        field: 'emailForApplicants',
        filter: true,
        editable: false,
        sortable: true
      },
    ]
  }

  createHyperLink(params): any {
    if (!params.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = `<a href="${params.value}" > ${params.value} </a> `;
    spanElement.addEventListener('click', ($event) => {
      $event.preventDefault();
      this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/job-application'], { queryParams: { jobPostId: params.data.id } });
    });
    return spanElement;
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  createOpenModal(){
    this.modeAction = 1;
    this.jobDescriptionForm.reset();
    this.jobDescriptionForm.patchValue({
      title: '',
      workMode: '',
      location: '',
      jobType: '',
      expectedExperience: '',
      expectedQualifications: '',
      expectedSalary: '',
      skills: '',
      active: '',
      description: '',
      emailForApplicants: '',
      jobLink: '',
      creationDate: ''
    });
  }

  createJobDescription(){
    let data = {
      title: this.jobDescriptionForm.get('title').value,
      workMode: this.jobDescriptionForm.get('workMode').value,
      location: this.jobDescriptionForm.get('location').value,
      jobType: this.jobDescriptionForm.get('jobType').value,
      expectedExperience: this.jobDescriptionForm.get('expectedExperience').value,
      expectedQualifications: this.jobDescriptionForm.get('expectedQualifications').value,
      expectedSalary: this.jobDescriptionForm.get('expectedSalary').value,
      skills: this.jobDescriptionForm.get('skills').value,
      active: this.jobDescriptionForm.get('active').value,
      description: this.jobDescriptionForm.get('description').value,
      emailForApplicants: this.jobDescriptionForm.get('emailForApplicants').value,
      jobLink: this.jobDescriptionForm.get('jobLink').value,
      creationDate: this.jobDescriptionForm.get('creationDate').value,
    }
    this.jobPostingService.createJobDescription(data).subscribe( (response: any) => {
      this.getJobDescription();
      $('#closeModal').trigger('click');
      }
    );
  }

  editOpenModal(){
    this.modeAction = 2;
    this.jobDescriptionForm.patchValue(this.selectedCheckboxData[0]);
  }

  editJobDescription(){
    let data = {
      id: this.selectedCheckboxData[0].id,
      title: this.jobDescriptionForm.get('title').value,
      workMode: this.jobDescriptionForm.get('workMode').value,
      location: this.jobDescriptionForm.get('location').value,
      jobType: this.jobDescriptionForm.get('jobType').value,
      expectedExperience: this.jobDescriptionForm.get('expectedExperience').value,
      expectedQualifications: this.jobDescriptionForm.get('expectedQualifications').value,
      expectedSalary: this.jobDescriptionForm.get('expectedSalary').value,
      skills: this.jobDescriptionForm.get('skills').value,
      active: this.jobDescriptionForm.get('active').value,
      description: this.jobDescriptionForm.get('description').value,
      emailForApplicants: this.jobDescriptionForm.get('emailForApplicants').value,
      jobLink: this.jobDescriptionForm.get('jobLink').value,
      creationDate: this.jobDescriptionForm.get('creationDate').value,
    }
    this.jobPostingService.updateJobDescription(data, this.selectedCheckboxData[0].id).subscribe( (response: any) => {
      this.getJobDescription();
      $('#closeModal').trigger('click');
      }
    );
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete';
    for (let i in this.selectedCheckboxData) {
      if (+i === 0)
        this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].jobTitle}`;
      else if (this.selectedCheckboxData.length === +i + 1)
        this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].jobTitle}`;
      else
        this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].jobTitle}`;
    }
  }

  deleteJobDescription(){
    this.jobPostingService.deleteJobDescription(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getJobDescription();
      this.selectedCheckboxData = [];
      $('#closeModalDelete').trigger('click');
    });
  }

  backToHiringProcess(){
    this.router.navigate([this.tenantId + '/dashboard/hr/hiring-process-profile/hiring-process']);
  }
}
