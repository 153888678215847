import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpBackend } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from "../../_services/localStorage/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AvatarService {

  private httpClient: HttpClient;

  constructor(private handler: HttpBackend, private storageService: LocalStorageService) {
    this.httpClient = new HttpClient(handler);
   }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  uploadImage(id: number, data: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.httpClient.post(`${environment.apiUrl}/users/` + id + `/image`, data, header).pipe(catchError(this.handleError));
  }

}
