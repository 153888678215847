<div class="admin-profile">
    <div class="row">
        <div class="col-sm-12">
            <div class="d-flex justify-content-between align-items-center storage">
                <div class="storage-platform">
                    <img src="assets/svg/gdrive.svg" alt="">
                    <p>Google Drive</p>
                </div>
                <div class="storage-action">
                    <a href="" class="btn btn-grey">Disconnect</a>
                </div>
                <div class="storage-key">
                    <div class="d-flex">
                        <div class="row w-100">
                            <div class="col-sm-4">
                                <div class="input">
                                    <div class="input-item">
                                        <input type="text" required>
                                        <label class="floating-label">Drive Key</label>
                                        <i class="ri-key-line"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="input">
                                    <div class="input-item">
                                        <input type="text" required>
                                        <label class="floating-label">Drive Key</label>
                                        <i class="ri-key-line"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        
                        
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center storage">
                <div class="storage-platform">
                    <img src="assets/svg/onedrive.svg" alt="">
                    <p>One Drive</p>
                </div>
                <div class="storage-action">
                    <a href="" class="btn">Connect</a>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center storage">
                <div class="storage-platform">
                    <img src="assets/svg/dropbox.svg" alt="">
                    <p>Dropbox</p>
                </div>
                <div class="storage-action">
                    <a href="" class="btn">Connect</a>
                </div>
            </div>
        </div>
    </div>
</div>