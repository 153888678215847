import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/_services/user/user.service';
import { ExitProcessService } from 'src/app/_services/exit-process/exit-prosess.service';

@Component({
  selector: 'app-exit-employee-form',
  templateUrl: './exit-employee-form.component.html',
  styleUrls: ['./exit-employee-form.component.scss']
})
export class ExitEmployeeFormComponent implements OnInit {
  tenantId: string;
  userDropdownList = [];
  employeeExitForm
  form: FormGroup;
  currentUser: any;
  date: Date;
  TodayDate: string;
  resignationId: any;
  resignedUserId; any;
  isUpdateInfo: boolean = false;
  exitFormID: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private exitProcessService: ExitProcessService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
  ) {
    this.route.queryParams.subscribe(params => {
      this.resignedUserId = params['userId'];
    });

    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });

    this.employeeExitForm = this.formBuilder.group({
      userId: new FormControl({value: '', disabled: true}),
      mainReasonForLeavingCurrentJob: new FormControl('', Validators.required),
      mainReasonForAcceptingOtherJob: new FormControl('', Validators.required),
      mostLiked: new FormControl('', Validators.required),
      leastLiked: new FormControl('', Validators.required),
      localId: new FormControl({value: '', disabled: true}),
      resignationDate: new FormControl({value: '', disabled: true}),
      lastWorkingDate: new FormControl({value: '', disabled: true}),
      currentDesignation: new FormControl({value: '', disabled: true}),
      department: new FormControl({value: '', disabled: true}),
      location: new FormControl({value: '', disabled: true}),
      reportingManager: new FormControl({value: '', disabled: true}),
      dateOfJoining: new FormControl({value: '', disabled: true}),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;

    this.getUserSeparationsByUserId(this.resignedUserId);
    this.getEmployeeExitForm(this.resignedUserId);
  }
  
  getUserSeparationsByUserId(resignedUserId){
    this.exitProcessService.getUserSeparationsByUserId(resignedUserId).subscribe({
      next : (value: any[]) => {
        if(value.length > 0){
          this.employeeExitForm.patchValue({
            userId: value[0]['userId'] + ' . ' + value[0]['userName'],
            lastWorkingDate: value[0]['lastWorkingDate'],
            localId: value[0]['localId'],
            resignationDate: value[0]['resignationDate'],
            currentDesignation: value[0]['currentDesignation'],
            department: value[0]['department'],
            location: value[0]['location'],
            reportingManager: value[0]['reportingManager'],
            dateOfJoining: value[0]['dateOfJoining'],
          });
        }
      },
      error(err) {
        this.openSnackBar('ERROR: Getting resignation' + err)
      },
      complete() {
      }
    });
  }

  getEmployeeExitForm(resignedUserId){
    this.exitProcessService.getEmployeeExitForm(resignedUserId).subscribe({
      next : (value: any[]) => {
        if(value.length > 0){
          this.isUpdateInfo = true;
          this.exitFormID = value[0]['id'];
          this.employeeExitForm.patchValue({
            mainReasonForLeavingCurrentJob: value[0]['mainReasonForLeavingCurrentJob'],
            mainReasonForAcceptingOtherJob: value[0]['mainReasonForAcceptingOtherJob'],
            mostLiked: value[0]['mostLiked'],
            leastLiked: value[0]['leastLiked'],
          });
        }
      },
      error(err) {
        this.openSnackBar('ERROR: Getting resignation' + err)
      },
      complete() {
      }
    }); 
  }

  saveEmployeeExitForm(){
    let details = {
      userId: this.resignedUserId,
      mainReasonForLeavingCurrentJob: this.employeeExitForm.get('mainReasonForLeavingCurrentJob').value,
      mainReasonForAcceptingOtherJob: this.employeeExitForm.get('mainReasonForAcceptingOtherJob').value,
      mostLiked: this.employeeExitForm.get('mostLiked').value,
      leastLiked: this.employeeExitForm.get('leastLiked').value,
    }
    if(this.isUpdateInfo === true){
      this.exitProcessService.editEmployeeExitForm(details, this.exitFormID).subscribe({
        next : (value) => {
          this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-interview-form'], {queryParams: {userId: this.currentUser.id}});
        },
        error(err) {
          this.openSnackBar('ERROR: cannot create resignation: ' + err);
        },
        complete() {
          this.openSnackBar('Employee Form Update Successfully Completed!!!');
        }
      });
    }
    else{
      this.exitProcessService.createEmployeeExitForm(this.resignedUserId, details).subscribe({
        next : (value) => {
          this.exitFormID = value[0]['id'];
          this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-interview-form'], {queryParams: {userId: this.currentUser.id}});
        },
        error(err) {
          this.openSnackBar('ERROR: cannot create resignation: ' + err);
        },
        complete() {
          this.openSnackBar('Employee Form Successfully Completed!!!');
        }
      });
    }
  }

  backToExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-process' ]);
  }

  openExitInterviewForm(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-interview-form'], {queryParams: {userId: this.currentUser.id}});
  }

}