import { PricingTableComponent } from './components/_shared/pricing-table/pricing-table.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from "./_helpers/auth-guard.service";
import { LoginComponent } from "./components/_shared/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { RegisterComponent } from "./components/_shared/register/register.component";
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DatagridtestComponent } from './components/datagridtest/datagridtest.component';
import { ForgotPasswordComponent } from "./components/_shared/forgot-password/forgot-password.component";
import { JobPostComponent } from './components/dashboard/hr/hiring-process-profile/job-post/job-post.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: 'login'
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'forgot-password', component: ForgotPasswordComponent
  },
  {
    path: 'pricing-table', component: PricingTableComponent
  },
  {
    path: ':tenant/dashboard/hr/hiring-process-profile/job-post', component: JobPostComponent
  },
  {
    path: ':tenant/dashboard',
    loadChildren: () =>
      import('../app/components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardService],
  },
  {
    path: ':tenant/dashboard', component: DashboardComponent, canActivate: [AuthGuardService], children: [
      {
        path: 'logout', component: LogoutComponent, canActivate: [AuthGuardService]
      },
      {
        path: 'datagridtest', component: DatagridtestComponent, canActivate: [AuthGuardService]
      },
    ]
  },
  {
    path: '**', pathMatch: 'full', redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
