import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostService } from 'src/app/_services/cost/cost.service';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-softwarerates',
  templateUrl: './softwarerates.component.html',
  styleUrls: ['./softwarerates.component.scss']
})
export class SoftwareratesComponent implements OnInit {
  jsonData: any;
  softwareRatesForm: any;
  selectedRow: any;
  modeAction: number;

  constructor(private costService: CostService,private formBuilder: FormBuilder,private dataService: DataService,
    private router: Router, private storageService: LocalStorageService, private route: ActivatedRoute, 
    private modalService: NgbModal, private snackBar: MatSnackBar) { 
    
    this.softwareRatesForm = this.formBuilder.group({
      software: new FormControl('', Validators.required),
      hourlyCostRate: new FormControl('', Validators.required),
    });
    
  }

  // getter for easy access to form fields
  get f() {
    return this.softwareRatesForm.controls;
  }

  ngOnInit(): void {
    this.getSoftwareRates();
    console.log("this.modeAction = ", this.modeAction);
  }

  getSoftwareRates() {
    this.costService.getDefaultSoftwareRates().subscribe((response:any) => {
      this.jsonData = response;
    });
  }

  addSoftwareRates() {
    var addsrdata = {
      software: this.softwareRatesForm.get('software').value,
      hourlyCostRate: parseInt(this.softwareRatesForm.get('hourlyCostRate').value)
    }
    this.costService.addSoftwareRates(addsrdata).subscribe((response: any) => {
      console.log("addSoftwareRates ADD Response", response);
      this.snackBar.open("Software rate added!", 'Close', { duration: 2000 });
      this.getSoftwareRates();
      $('#closeSrAddModal').trigger("click");
    });
  }

  editOpenModal(item) {
    this.selectedRow = item;
    this.modeAction = 2;
    this.softwareRatesForm.patchValue(item);
  }

  editSoftwareRates() {
    var editsrdata = {
      software: this.softwareRatesForm.get('software').value,
      hourlyCostRate: parseInt(this.softwareRatesForm.get('hourlyCostRate').value)
    }
    console.log("selected row: ", this.selectedRow);
    this.costService.editSoftwareRates(this.selectedRow.id, editsrdata).subscribe((response: any) => {
      console.log("editSoftwareRates ADD Response", response);
      this.snackBar.open("Software rate edited!", 'Close', { duration: 2000 });
      this.getSoftwareRates();
      $('#closeSrAddModal').trigger("click");
      this.modeAction = 1;
      console.log("this.modeAction = ", this.modeAction);

    });
    
  }

  // deletedefsr(): void {
  deletedefsr(id) {
    this.costService.deleteSoftwareRates(id).subscribe((response: any) => {
      this.snackBar.open("Software rate deleted!", 'Close', { duration: 2000 });
      this.getSoftwareRates();
      $('#closeSrDeleteModal').trigger("click");
    });
  }

  // to open delete modal
  openDelete(item) {
    this.selectedRow = item;
  }

  addOpenModal() {
    this.modeAction = 1;
    this.resetForm()
  }
  
  resetForm() {
    this.softwareRatesForm.reset();
  }
}
