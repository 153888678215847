import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/_services/user/user.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ExitProcessService } from 'src/app/_services/exit-process/exit-prosess.service';

@Component({
  selector: 'app-exit-form',
  templateUrl: './exit-form.component.html',
  styleUrls: ['./exit-form.component.scss']
})
export class ExitFormComponent implements OnInit {
  exitProcessForm;
  userClearanceForm;
  form: FormGroup;
  tenantId: string;
  currentUser: any;
  userDropdownList = [];
  userClearanceData: any[] = [];
  userClearance= [];
  userCreationForm
  defaultColDef:any;
  rowSelection:any
  dataSource: any;
  public columnDefs: ColDef[];
  sampleGridFrameworkComponents: any;
  private api: GridApi;
  private columnApi: ColumnApi;
  selectedRow: any;
  rowData: any;
  isHRAdmin: boolean;
  isFinanceAdmin: boolean;
  isITAdmin: boolean;
  resignationId: any;
  clearanceUserId: any;
  isManager: boolean;
  isDisabled: boolean = true;
  isVisible: boolean;
  isEmployeeFormVisible: boolean;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
    private route : ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private exitProcessService: ExitProcessService,
    private userService: UserService,
  ) {
      this.route.queryParams.subscribe(params => {
        this.resignationId = params['resignationId'];
      });
      {
        this.defaultColDef = {
          flex: 1,
          minWidth: 100,
          resizable: true,
        };
      }
      
      this.exitProcessForm = this.formBuilder.group({
        userId: new FormControl({value: '', disabled: true}),
        userName: new FormControl({value: '', disabled: true}),
        localId: new FormControl({value: '', disabled: true}),
        resignationDate: new FormControl({value: '', disabled: true}),
        lastWorkingDate: new FormControl(''),
        alternateEmailId: new FormControl({value: '', disabled: true}),
        alternateContactNo: new FormControl({value: '', disabled: true}),
        reason: new FormControl({value: '', disabled: true}),
        comment: new FormControl({value: '', disabled: true}),
        currentDesignation: new FormControl({value: '', disabled: true}),
        joiningDate: new FormControl({value: '', disabled: true}),
        status: new FormControl(''),
        department: new FormControl({value: '', disabled: true}),
      });

      this.userClearanceForm = this.formBuilder.group({
        itemName: new FormControl({value: '', disabled: true}),
        status: new FormControl({value: '', disabled: true}),
        type: new FormControl({value: '', disabled: true}),
      });
    }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isHRAdmin = this.currentUser.roles.includes('RoleHrmAdministrator');
    this.isFinanceAdmin = this.currentUser.roles.includes('RoleFinanceAdministrator') ;
    this.isITAdmin = this.currentUser.roles.includes('RoleITAdministrator') ;
    if(this.isHRAdmin || this.isITAdmin || this.isFinanceAdmin){
      this.isVisible = true;
    }
    else{
      this.isVisible = false;
    }
    
    this.getUserSeparation(this.resignationId);
  }

  getUserSeparation(resignationId){
    this.exitProcessService.getUserSeparationsById(resignationId).subscribe({
      next : (value) => {
        this.clearanceUserId = value['userId'];
        this.getUserById(value['userId']);
        if(value['status'] === 'Accepted'){
          this.isEmployeeFormVisible = true;
        }

        this.exitProcessForm.patchValue({
          userId: value['userId'] + ' . ' + value['userName'],
          localId: value['localId'],
          currentDesignation: value['currentDesignation'],
          resignationDate: value['resignationDate'],
          lastWorkingDate: value['lastWorkingDate'],
          alternateEmailId: value['alternateEmailId'],
          alternateContactNo: value['alternateContactNo'],
          reason: value['reason'],
          comment: value['comment'],
          status: value['status'],
          joiningDate: value['dateOfJoining'],
          department: value['department'],
        });

        this.getUserClearance();
      },
      error(err) {
        this.openSnackBar('ERROR: Getting Resignation Details' + err);
      },
      complete() {
        // this.getUserClearance();
      }
    });
  }

  getUserById(selectedUserId) {
    this.userService.getUser(selectedUserId).subscribe({
      next: (response) => {
        if(selectedUserId === this.currentUser.id) {
          this.isDisabled = true;
        }
        else if(this.currentUser.id === response['supervisorId']){
          this.isManager = true;
          this.isDisabled = false;
        }
        else if(this.isHRAdmin) {
          this.isManager = false;
          this.isDisabled = false;
        }
        else{
          this.isManager = false;
          this.isDisabled = true;
        }
      },
      error: () => {

      },
      complete: () => {

      }
    })
  }

  getUserClearance(){
    this.exitProcessService.getAllUserClearanceByUserId(this.clearanceUserId).subscribe({
      next: (value: any) =>  {
        this.userClearanceData = value,
        this.userClearance = value
      },
      error(error)  {
        this.openSnackBar('ERROR: Getting Resignation Details' + error);
      },
      complete(){

      }
    })
  }

  updateUserSeperation(){
    let details = {
      userId: this.exitProcessForm.get('userId').value.split(' . ')[0],
      userName: this.exitProcessForm.get('userId').value.split(' . ')[1],
      resignationDate: this.exitProcessForm.get('resignationDate').value,
      lastWorkingDate: this.exitProcessForm.get('lastWorkingDate').value,
      alternateEmailId: this.exitProcessForm.get('alternateEmailId').value,
      alternateContactNo: this.exitProcessForm.get('alternateContactNo').value,
      reason: this.exitProcessForm.get('reason').value,
      comment: this.exitProcessForm.get('comment').value,
      status: this.exitProcessForm.get('status').value
  }
  this.exitProcessService.editUserSeparations(details, this.resignationId).subscribe({
      next : (value) => {
        this.backToExit();
      },
      error(err) {
        this.openSnackBar('ERROR: Getting Resignation Details' + err);
      },
      complete() {
        // this.getUserClearance();
      }
    });
  }

  backToExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-process' ]);
  }

  openFinanceExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-finance' ], { queryParams: { resignationId: this.resignationId } });
  }

  openItHrmExit(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-hrit' ], { queryParams: { resignationId: this.resignationId } });
  }

  ExitEmployeeForm(){
    this.router.navigate([ this.tenantId + '/dashboard/hr/exit-formalities/exit-employee-form'], {queryParams: {userId: this.currentUser.id}});
  }

}
