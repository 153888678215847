<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button routerLink="/{{tenantId}}/dashboard/opportunities" [routerLinkActive]="['active']">
                                        <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">All Quotes</h3>
                            </div>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <button type="button"  data-bs-toggle="modal" data-bs-target="#quote" (click)="createOpenModal()" class="btn btn-icon"><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="canvas-table">
                            <table class="table" id="datatable">
                                <thead>
                                    <tr>
                                        <th>Quote Name</th>
                                        <th>Account</th>
                                        <th>Project</th>
                                        <th>Project Number</th>
                                        <th>Project Manager</th>
                                        <th>Responsible Unit</th>
                                        <th>Support Unit</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Key Account Manager</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of jsonData">
                                        <td><a (click)="rowData(item)">{{item.quoteName}}</a></td>
                                        <td>{{item.account}}</td>
                                        <td>{{item.project}}</td>
                                        <td>{{item.projectNumber}}</td>
                                        <td>{{item.projectManager}}</td>
                                        <td>{{item.responsibleUnit}}</td>
                                        <td>{{item.supportUnit}}</td>
                                        <td>{{item.startDate}}</td>
                                        <td>{{item.endDate}}</td>
                                        <td>{{item.keyAccountManager}}</td>
                                        <td>
                                            <div class="table-action">
                                                <button class="action-btn"><i class="ri-more-2-fill"></i></button>
                                                <div class="action-items">
                                                    <div class="delete">
                                                        <button data-bs-toggle="modal" data-bs-target="#deleteQuoteModal"  type="button" (click)="open(item)">
                                                            <span><i class="ri-delete-bin-5-line"></i></span>
                                                            <span>Delete</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
  
<!-- Create new Quote -->
<div class="modal fade" id="quote" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="quotesForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create new Quote</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="quoteName" type="text" required>
                            <label class="floating-label">Quote Name</label>
                            <i class="ri-hand-coin-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">Account</label>
                            <input list="accountNameDatalist" formControlName="account" />
                                <datalist id="accountNameDatalist">
                                    <option [value]="item" *ngFor="let item of accountDropdownList">
                                </datalist>
                                <i class="ri-account-box-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">Project</label>
                            <input list="projectDropdownList" formControlName="project"  >
                            <datalist id="projectDropdownList">
                                <option [value]="item" *ngFor="let item of projectDropdownList">
                            </datalist>
                            <i class="ri-stack-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="projectNumber" type="text" required>
                            <label class="floating-label">Project Number</label>
                            <i class="ri-file-list-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">Project Manager</label>
                            <input list="projectDatalist" formControlName="projectManager" >
                                <datalist id="projectDatalist">
                                    <option [value]="item" *ngFor="let item of userDropdownList">
                                </datalist>
                                <i class="ri-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">Responsible Unit</label>
                            <input list="responsibleUnit" formControlName="responsibleUnit" >
                                <datalist id="responsibleUnit">
                                    <option [value]="item" *ngFor="let item of costDropdownList">
                                </datalist>
                                <i class="ri-recycle-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">Support Unit</label>
                            <input list="supportUnitlist" formControlName="supportUnit" >
                                <datalist id="supportUnitlist">
                                    <option [value]="item" *ngFor="let item of costDropdownList">
                                </datalist>
                                <i class="ri-file-user-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="startDate" type="date" required>
                            <label class="floating-label">Start Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="endDate" type="date" required>
                            <label class="floating-label">End Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label class="floating-label floating-label-special">Key Account Manager</label>
                            <input list="projectDatalist" formControlName="keyAccountManager" >
                                <datalist id="projectDatalist">
                                    <option [value]="item" *ngFor="let item of userDropdownList">
                                </datalist> 
                                <i class="ri-user-follow-line"></i>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalQuote" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="createQuote()">Create Quote</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteQuoteModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Quote</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>Are you sure you want to delete {{selectedRow?.quoteName}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteQuote" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="deleteQuote(selectedRow?.id)">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>