<button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
</button>
<h2 mat-dialog-title>Edit Image</h2>
<div mat-dialog-content class="mat-typography">
    <form [formGroup]="form" >
        <div id='element' class="center" >
            <span class="e-avatar e-avatar-xlarge e-avatar-circle" >
              <img [src]="retrievedImage" alt="profile_pic">
            </span>
           </div>
       
        <div class="container row">
            <div class="col-md-6">
                <input type="file" (change)="onFileChanged($event)">
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onUpload()" >Update</button>
    <button mat-button (click)="closeDialog()"   color="warn">Cancel</button>
</div>
