<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Resource Allocation</h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <button type="button" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#resAllAddModal" class="btn btn-icon"><i class="ri-add-line"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="canvas-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Allocation </th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Allocation Type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of resourceAllocationData">
                                        <td>
                                            <a (click)="rowData(item)">{{item.name}}</a>
                                        </td>
                                        <td>{{item.startDate}} </td>
                                        <td>{{item.endDate}}</td>
                                        <td>{{item.resourceType}}</td>
                                        <td>
                                            <div class="table-action">
                                                <button class="action-btn"><i class="ri-more-2-fill"></i></button>
                                                <div class="action-items d-flex">
                                                    <div class="edit">
                                                        <button data-bs-toggle="modal" data-bs-target="#resAllEditModal" type="button" (click)="openEdit(item)">
                                                            <span><i class="ri-pencil-line"></i></span>
                                                           
                                                        </button>
                                                    </div>
                                                    <div class="delete">
                                                        <button data-bs-toggle="modal" data-bs-target="#resAllDeleteModal" type="button" (click)="openDelete(item)">
                                                            <span><i class="ri-delete-bin-5-line"></i></span>
                                                           
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Resource Allocation -->
<div class="modal fade" id="resAllAddModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="resourceAllocationFormGroup">
                <div class="modal-header">
                    <h5 class="modal-title">Create new Allocation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="name" type="text" required >
                            <label for="name" class="floating-label">Resource Allocation Name</label>
                            <i class="ri-shield-user-line"></i>
                        </div>
                        <mat-error *ngIf="f.name.invalid && (f.name.touched || f.name.dirty)" class="alert alert-danger">
                            <mat-error *ngIf="f.name.errors.required">Resource Allocation Name is required</mat-error>
                        </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="startDate" type="date" required >
                            <label for="startDate" class="floating-label floating-label-special">Start Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                        <mat-error *ngIf="f.startDate.touched && f.startDate.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                          </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="endDate" type="date" required >
                            <label for="endDate" class="floating-label floating-label-special">End Date</label>
                            <i class="ri-calendar-check-line"></i>
                        </div>
                        <mat-error *ngIf="f.endDate.invalid && (f.endDate.touched || f.endDate.dirty)" class="alert alert-danger">
                            <mat-error *ngIf="f.endDate.errors.required">End Date is required</mat-error>
                        </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <label for="resourceType" class="floating-label floating-label-special">Resource Type</label>
                            <select formControlName="resourceType">
                                <option value="daily">daily</option>
                                <option value="weekly">weekly</option>
                                <option value="monthly">monthly</option>
                            </select>
                            <i class="ri-shield-user-line"></i>
                            <mat-error *ngIf="resourceAllocationFormGroup.controls.resourceType.touched && resourceAllocationFormGroup.controls.resourceType.invalid" class="alert alert-danger">
                                <mat-error *ngIf="resourceAllocationFormGroup.controls.resourceType.errors.required">Resource Type is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalResourceAllocation" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="createResourceAllocation()" [disabled]="!resourceAllocationFormGroup.valid?true:null" >Create Allocation</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="resAllDeleteModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Resource Allocation</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>Are you sure you want to delete {{selectedRow?.name}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteResourceAllocation" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="deleteResourceAllocation(selectedRow?.id)">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Edit an item -->
<div class="modal fade" id="resAllEditModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="resourceAllocationFormGroup">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Opportunity</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="name" type="text" required >
                            <label for="name" class="floating-label">Resource Allocation Name</label>
                            <i class="ri-shield-user-line"></i>
                            <mat-error *ngIf="f.name.invalid && (f.name.touched || f.name.dirty)" class="alert alert-danger">
                                <mat-error *ngIf="f.name.errors.required">Resource Allocation Name is required</mat-error>
                            </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="startDate" type="date" required >
                            <label for="startDate" class="floating-label floating-label-special">Start Date</label>
                            <i class="ri-shield-user-line"></i>
                            <mat-error *ngIf="f.startDate.invalid && (f.startDate.touched || f.startDate.dirty)" class="alert alert-danger">
                                <mat-error *ngIf="f.startDate.errors.required">Start Date is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="endDate" type="date" required >
                            <label for="endDate" class="floating-label floating-label-special">End Date</label>
                            <i class="ri-shield-user-line"></i>
                            <mat-error *ngIf="f.endDate.invalid && (f.endDate.touched || f.endDate.dirty)" class="alert alert-danger">
                                <mat-error *ngIf="f.endDate.errors.required">End Date is required</mat-error>
                              </mat-error>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalEditResourceAllocation" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"  (click)="updateResourceAllocation(selectedRow?.id)">Update</button>
                </div>
            </form>
        </div>
    </div>
</div>
