<main class="main-dashboard">
  <article class="main-canvas">
      <section class="canvas-container">
          <section class="dashboard-items">
              <div class="canvas-item">
                  <div class="canvas-header row justify-content-between align-items-center">
                      <div class="title col-sm-6">
                          <h3> Change Request </h3>
                      </div>
                      <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                          <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                              <button type="button" (click)="openEditChangeReq()" data-bs-toggle="modal">
                                  <span><i class="ri-pencil-line"></i></span>
                              </button>
                          </div>
                          <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                              <button type="button" data-bs-toggle="modal" data-bs-target="#deleteCR" (click)="deleteOpenModal()">
                                  <span><i class="ri-delete-bin-5-line"></i></span>
                              </button>
                          </div>
                          <div class=" add-btn">
                            <button type="button" (click)="openAddChangeReq()" class="btn btn-icon">
                                <i class="ri-add-line"></i>
                            </button>
                        </div>
                      </div>
                  </div>
                  <ag-grid-angular
                      style="height: calc(100vh - 120px) !important;" 
                       class="ag-theme-material"
                      (gridReady)="onGridReady($event)" 
                      [rowData]="rowData" 
                      [columnDefs]="columnDefs"
                      pagination="true" 
                      [defaultColDef]="defaultColDef"
                      [suppressRowClickSelection]="true"
                      [rowSelection]="rowSelection"
                      (selectionChanged)="onSelectionChanged($event)"
                      paginationPageSize=10>
                  </ag-grid-angular>
              </div>
          </section>
      </section>
  </article>
</main>

<!-- Delete an item -->
<div class="modal fade" id="deleteCR" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalCR" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteCR()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div> 
