<div class="row">
    <div class="col-sm-4">
        <section class="lead-user-timeline">
            <div class="card">
                <div class="card-header p-3">
                    <div class="title">
                        <h3>Add new events</h3>
                    </div>
                </div>
                <div class="card-body">
                    <div class="event-items">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="input">
                                    <div class="input-item">
                                        <input type="text" class="ng-pristine ng-valid ng-touched">
                                        <label class="floating-label">Enter Event Name</label>
                                    </div>
                                </div>
                                <div class="selection my-2">
                                    <span>Notification before Days/Months</span>
                                    <select name="" id="">
                                        <option value="" selected>1 Month</option>
                                    </select>
                                </div>
                                <a href="" class="template-btn">Set template</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer p-2" style="background: #fff;">
                    <div class="d-flex w-100 justify-content-end">
                        <a href="Cancel" class="btn btn-secondary">Cancel</a>
                        <a href="" class="btn">Save</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>