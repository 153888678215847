<div class="heading d-flex justify-content-between align-items-center">
    <h2>Other Direct Cost</h2>
    <button _ngcontent-mjv-c22="" type="button" (click)="visibleForm()"
        class="btn btn-icon"><i _ngcontent-mjv-c22="" class="ri-add-line"></i>
    </button>
</div>
<form [formGroup]="odcFormGroup">
    <div class="table-responsive">
        <table class="table">
            <thead class="h-90">
                <tr>
                    <th></th>
                    <th>Task Name</th>
                    <th>Activity</th>
                    <th>Cost Center</th>
                    <th>Other Cost</th>
                    <th *ngFor="let day of daysODC">
                        <div class="trans-270">{{day | date:'MMM-y'}}</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let odc of odcDataTop">
                    <td>
                        <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="editOdcById(odc.id)" class="btn btn-icon my-1">
                            <span><i class="ri-pencil-line"></i></span>
                        </button>
                        <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="deleteOtherDirectCost(odc.id)" class="btn btn-icon">
                            <span><i class="ri-delete-bin-5-line"></i></span>
                        </button>
                    </td>
                    <td>
                        {{odc.taskName}}
                    </td>
                    <td>
                        {{odc.activity}}
                    </td>
                    <td>
                        {{odc.costcenter}}
                    </td>
                    <td>
                        {{odc.otherCost}}
                    </td>
                    <td *ngFor="let dayodc of odc.values">
                        {{dayodc.value}}
                    </td>
                </tr>
                <tr *ngIf="isOdcFormVisible">
                    <td>
                        <button *ngIf="formMode === 1" [ngClass]="requiredSave() ? 'btn-invalid-action' : ''" _ngcontent-mjv-c22="" type="button" (click)="saveOtherDirectCost()" class="btn btn-icon">
                            <span><i class="ri-save-line"></i></span>
                        </button>
                        <button *ngIf="formMode === 2" [ngClass]="requiredSave() ? 'btn-invalid-action' : ''" _ngcontent-mjv-c22="" type="button" (click)="updateOtherDirectCost()" class="btn btn-icon">
                            <span><i class="ri-file-edit-line"></i></span>
                        </button>
                    </td>
                    <td>
                        <div class="input">
                            <input type="text" formControlName="taskName">
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <input type="text" formControlName="activity">
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select name="" id="" formControlName="costcenter"
                                (selectionChange)="getCostCenterddLevelRates()">
                                <option *ngFor="let costCenter of costCenterDropDown; let ccddpos = index"
                                    [value]="costCenter">{{ costCenter }}</option>
                                <option value="--">--</option>
                            </select>
                        </div>
                    </td>
                    <td>
                        <div class="input">
                            <select formControlName="otherCost">
                                <option *ngFor="let oc of otherCostDropdownList" [value]="oc">{{oc}}</option>
                                <option value="--">--</option>
                            </select>
                        </div>
                    </td>
                    <td  *ngFor="let day of daysODC">
                        <input class="input-date-manpower" type="text" [formControlName]="day">
                    </td>
                </tr>
                <tr *ngFor="let odc of odcDataBottom">
                    <td>
                        <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="editOdcById(odc.id)" class="btn btn-icon">
                            edit
                        </button>
                        <button [ngClass]="formMode !== 0 ? 'd-none' : '' " _ngcontent-mjv-c22="" type="button" (click)="deleteOtherDirectCost(odc.id)" class="btn btn-icon">
                            delete
                        </button>
                    </td>
                    <td>
                        {{odc.taskName}}
                    </td>
                    <td>
                        {{odc.activity}}
                    </td>
                    <td>
                        {{odc.costcenter}}
                    </td>
                    <td>
                        {{odc.otherCost}}
                    </td>
                    <td *ngFor="let dayodc of odc.values">
                        {{dayodc.value}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</form>