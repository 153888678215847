import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class JwtTokenService {
  jwtToken: string;
  decodedToken: { [key: string]: string };

  constructor(private storageService: LocalStorageService) {
    this.jwtToken = storageService.get('token');
  }

  setToken(token: string) {
    if (token) {
      this.jwtToken = token;
    }
  }

  decodeToken() {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodedToken() {
    return jwt_decode(this.jwtToken);
  }

  getUser() {
    //console.log("in getUser of jwtService, decoded token: ", jwt_decode(this.jwtToken), " jwtToken: ", this.jwtToken);
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.sub : null;
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  isTokenExpired(): boolean {
    const expiryTime: any = this.getExpiryTime();
    // console.log((Math.floor((new Date).getTime() / 1000)) >= expiryTime);
    if (Math.floor(new Date().getTime() / 1000) >= expiryTime) {
      // token expired
      return true;
    } else {
      // token valid
      return false;
    }
  }
}
