import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { Router } from '@angular/router';
import { TaxmetadataService } from '../../../../_services/taxmetadata/taxmetadata.service';
import { HrService } from 'src/app/_services/hr-management/hr.service';

@Component({
  selector: 'app-salary-distribustion',
  templateUrl: './salary-distribustion.component.html',
  styleUrls: ['./salary-distribustion.component.scss']
})
export class SalaryDistribustionComponent implements OnInit {
  jsonData: any;
  // row data and column definitions
  rowData: any;
  form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  tenantId: string;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  selectedObject;
  newList;
  list:any;
  private gridOptions: GridOptions;

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private router: Router,
    private hrService:HrService
  ) { 
    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };
  }
  this.gridOptions = <GridOptions>{
    enableSorting: true,
    // enable filtering 
    enableFilter: true
  };
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.fetchList();
   
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Component Name",
        field: "componentName",
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn
       
      },
      {
        headerName: 'Employee Name',
        field: 'employeeName',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Component Value',
        field: 'componentValue',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Percent',
        field: 'percent',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Deduction',
        field: 'deduction',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Regime',
        cellRenderer: (params) => {
          if(params.data.regime === null) {
            return  `<span>`+'Both'+`</span>`;
          }
          else if(params.data.regime && params.data.regime !== null ) {
            return  `<span>${params.data.regime}</span>`;
          }
          else {
            return "";
          }
        },
        filter: true,
        editable: false,
        sortable: true,
      },
    
    ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  fetchList()
  {
    this.hrService.fetchList().subscribe(data=>{
      this.list = data.result;
      var len =  this.list.length;
      this.selectedObject=this.dateList[0];
      this.newmethod();
    })
  }

  dateList =
  [
    { key : 'key3' , value : '2023-2024' },
    { key : 'key4' , value : '2024-2025' },
    { key : 'key5' , value : '2025-2026' },
    { key : 'key6' , value : '2026-2027' },
    { key : 'key7' , value : '2027-2028' },
    { key : 'key8' , value : '2028-2029' },   
  ]

  handleChange(index) {
    this.selectedObject = this.dateList[index];
    this.newmethod();
  }

  newmethod() {
    setTimeout(() => {
      this.newList = this.list.filter( val => val.financialYear == this.selectedObject.value);
      this.rowData = this.newList;
    },1000);
  }

    salaryDistributionPage() {
    this.router.navigate([this.tenantId + '/dashboard/finance/salary-distribution-form']);

    }
    updateSalaryApp(event){
        this.router.navigate([
          this.tenantId + '/dashboard/finance/salary-distribution-form',
          { id: this.selectedCheckboxData[0].id },
        ]);
    }

    deleteleaveAppRates() {
      this.hrService.deleteSalry(
        this.selectedCheckboxData[0].id
      ).subscribe(() => {
        this.fetchList();
        setTimeout(() => {
          this.newList = this.list.filter( val => val.financialYear == this.selectedObject.value)
        }, 1);
        this.rowData = this.newList;
        this.selectedCheckboxData = [];

        $('#closeModalDeleteLeave').trigger('click');
      });
    }

    backToMySalaryDistribution() {
      this.router.navigate([this.tenantId + '/dashboard/finance/salary-distribustion']);
    }

    deleteOpenModal()
    {
      this.deleteConfirmationMsg = 'Are you sure you want to delete';
      for (let i in this.selectedCheckboxData) {
        if (+i === 0)
          this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].id} ${this.selectedCheckboxData[i].userIdUserName}`;
        else if (this.selectedCheckboxData.length === +i + 1)
          this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].id} ${this.selectedCheckboxData[i].userIdUserName}`;
        else
          this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].id} ${this.selectedCheckboxData[i].userIdUserName}`;
      }
    }


}
