<div class="row">
    <div class="col-sm-8 mb-2">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">HR Trends</h3>
            </div>
            <div class="card-body">
                <div class="graph-container">
                    <!--<div>
                      <button ejs-button cssClass="e-info" (click)='export()' style="text-transform:none !important" id="togglebtn print">EXPORT</button>
                    </div>-->

                    <ejs-chart style='display:block;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [tooltip]="tooltip">
                        <e-series-collection>
                            <e-series  [dataSource]='data' type='StackingColumn' xName='x' yName='y' name='Addition Trends' width='2'> </e-series>
                            <e-series  [dataSource]='data1' type='StackingColumn' xName='x' yName='y' name='Attrition Trends' width='2'> </e-series>
                            <e-series  [dataSource]='data2' type='StackingColumn' xName='x' yName='y' name='Head Count' width='2'> </e-series>
                        </e-series-collection>
                    </ejs-chart>
                  </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 mb-2">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">Department Graph</h3>
            </div>
            <div class="card-body">
                <div class="p-3">
                    <ejs-accumulationchart>
                        <e-accumulation-series-collection>
                            <e-accumulation-series type = 'Pie' [dataSource]='departmentData' xName='name' yName='value' [dataLabel]='datalabel'></e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 mb-2">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">Designation Graph</h3>
            </div>
            <div class="card-body ">
                <div class="p-3">
                    <ejs-accumulationchart>
                        <e-accumulation-series-collection>
                            <e-accumulation-series type = 'Pie' [dataSource]='designationData' xName='name' yName='value' [dataLabel]='datalabel'></e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4 mb-2">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">Location Graph</h3>
            </div>
            <div class="card-body">
                <div class="p-3">
                    <ejs-accumulationchart>
                        <e-accumulation-series-collection>
                            <e-accumulation-series type = 'Pie' [dataSource]='locationData' xName='name' yName='value' [dataLabel]='datalabel'></e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="card h-100">
            <div class="card-header">
                <h3 class="h5">Age Graph</h3>
            </div>
            <div class="card-body">
                <div class="p-3">
                    <ejs-accumulationchart>
                        <e-accumulation-series-collection>
                            <e-accumulation-series type = 'Pie' [dataSource]='ageData' xName='name' yName='value' [dataLabel]='datalabel'></e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
            </div>
        </div>
    </div>
</div>