<main class="main-dashboard">
    <article class="main-canvas">
      <section class="canvas-container">
            <section class="dashboard-items">
                <form [formGroup]="addattendanceForm">
                    <div class="canvas-item">
                        <div class="canvas-header row justify-content-between align-items-center">
                          <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                              <div class="edit">
                                  <button title="back page" (click)="backToAttendanceList()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                  </button>
                              </div>
                              <h3 class="px-3">Add Attendance</h3>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-8">
                              <div class="canvas-data">
                                <div class="row">
                                  <div class="col-sm-6 px-3 py-1">
                                     <div class="input">
                                            <div class="input-item">
                                                <input type="date" formControlName="attendanceDate" required/>
                                                <label class="floating-label-special">Date In</label>
                                                <i class="ri-calendar-todo-line"></i>
                                            </div>
                                            <mat-error *ngIf="f.attendanceDate.touched && f.attendanceDate.invalid" class="alert alert-danger">
                                              <mat-error *ngIf="f.attendanceDate.errors.required">Date is required</mat-error>
                                            </mat-error>
                                        </div>
                                  </div>
                                  <!-- <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                           <div class="input-item">
                                               <input type="date" formControlName="attendanceDateOut" required/>
                                               <label class="floating-label-special">Date Out</label>
                                               <i class="ri-calendar-todo-line"></i>
                                           </div>
                                           <mat-error *ngIf="f.attendanceDate.touched && f.attendanceDate.invalid" class="alert alert-danger">
                                             <mat-error *ngIf="f.attendanceDate.errors.required">Date is required</mat-error>
                                           </mat-error> 
                                       </div>
                                 </div> -->
                                  <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                      <div class="input-item">
                                        <input list="taskOwnerDatalist" formControlName="employeeId" placeholder="Applied For User"/>
                                        <datalist id="taskOwnerDatalist">
                                          <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                        </datalist>
                                        <label class="floating-label">Applied For</label>
                                        <i class="ri-user-line"></i>
                                      </div>
                                      <mat-error *ngIf="f.employeeId.touched && f.employeeId.invalid" class="alert alert-danger">
                                        <mat-error *ngIf="f.employeeId.errors.required">Applied For User is required</mat-error>
                                      </mat-error>
                                    </div>
                                  </div>
                                  <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                          <input type="time" formControlName="inTime" required/>
                                          <label class="floating-label-special" >Punch In Time</label>
                                          <i class="ri-time-line"></i>
                                        </div>
                                      </div>
                                  </div>
                                 <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                          <input type="time" formControlName="outTime" required/>
                                          <label class="floating-label-special" >Punch Out Time</label>
                                          <i class="ri-time-line"></i>
                                        </div>
                                  </div>
                                </div>
                            
                                  <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                      <div class="input-item">
                                        <textarea name="" id="" cols="30" rows="4" formControlName=""></textarea>
                                        <label class="floating-label">Comment</label>
                                      </div>
                                    </div>
                                  </div>
                             </div>
                              <div class="row justify-content-end align-items-center">
                                <div class="col-sm-3 text-end px-3 py-1">
                                  <div>
                                    <button type="button" id="closeCreateModal" class="btn" (click)="addAttendance()" >
                                      <div>Submit</div>
                                    </button>
                                  </div> 
                                </div>
                              </div>
                           </div>
                         </div>
                        </div>
                     </div>
                 </form>
         </section>
      </section>
    </article>
  </main>
