<form [formGroup]="layoutFormGroup">
    <div class="row layout-box">
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-1.svg" alt="">
                <label for="">Layout 1</label>
                <input value="0" type="radio" formControlName="radioForm"> 
            </div>
        </div>
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-2.svg" alt="">
                <label for="">Layout 2</label>
                <input value="1" type="radio" formControlName="radioForm">
            </div>
        </div>
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-3.svg" alt="">
                <label for="">Layout 3</label>
                <input value="2" type="radio" formControlName="radioForm"> 
            </div>
        </div>
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-4.svg" alt="">
                <label for="">Layout 4</label>
                <input value="3" type="radio" formControlName="radioForm"> 
            </div>
        </div>
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-5.svg" alt="">
                <label for="">Layout 5</label>
                <input value="4" type="radio" formControlName="radioForm"> 
            </div>
        </div>
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-6.svg" alt="">
                <label for="">Layout 6</label>
                <input value="5" type="radio" formControlName="radioForm"> 
            </div>
        </div>
        <div class="col-sm-3">
            <div class="box">
                <img src="assets/svg/layout-7.svg" alt="">
                <label for="">Custom Layout</label>
                <input value="6" type="radio" formControlName="radioForm"> 
            </div>
        </div>
    </div>
</form>
<div class="text-end" *ngIf="details">
    <a  class="btn" (click)="updateRadio()">Update Profile</a>
</div>