import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { LocalStorageService } from "../../../_services/localStorage/local-storage.service";
// import { fs } from 'file-system';

@Component({
  selector: 'app-googledrive',
  templateUrl: './googledrive.component.html',
  styleUrls: ['./googledrive.component.scss']
})
export class GoogledriveComponent implements OnInit {
  clientId = "322881771017-jpsj7mt6l8cqeorqpkd700f32p31bcgg.apps.googleusercontent.com";
  client_secret = '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'
  redirect_uri = "http://localhost:4200/12345/dashboard/googleDrive";
  scope = "https://www.googleapis.com/auth/drive";
  url = "";
  isUploadAllowed = false;
  fileName = '';
  refreshToken;
  myParam;
  codeValues;
  isCodeValue = true;
  fileUpload;
  UploadResponse;
  imageSrc: string;
  listGoogleDriveData;
  isFileUploaded = false;
  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  config: any;
  collection = { count: 100, data: [] };

  public ajaxSettings: object;
  public enablePersistence: boolean;
  //public hostUrl: string = 'https://ej2-aspcore-service.azurewebsites.net/';
  public hostUrl: string = 'http://localhost:62869/'
  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private storageService: LocalStorageService) {

    this.config = {
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: this.collection.count
    };
  }


  pageChanged(event) {
    this.config.currentPage = event;
  }


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.myParam = params['code'];

      this.activatedRoute.params.subscribe((params: Params) => {
        if (this.myParam) {
          // Do something
          this.storageService.set("GoogleDriveCode", this.myParam);
          this.isCodeValue = false;
          console.log(this.myParam); // Print the parameter to the console.
        }
      });


    });
    //Call funtion get all the files from Google drive.
    this.getFilesFromGoogleDrive();
    this.ajaxSettings = {
      url: this.hostUrl + "api/GoogleDriveProvider/GoogleDriveFileOperations",
      downloadUrl: this.hostUrl + "api/GoogleDriveProvider/GoogleDriveDownload",
      uploadUrl: this.hostUrl + "api/GoogleDriveProvider/GoogleDriveUpload",
      getImageUrl: this.hostUrl + "api/GoogleDriveProvider/GoogleDriveGetImage"
    };
    this.enablePersistence = true;
  }

  getFilesFromGoogleDrive() {
    this.codeValues = this.storageService.get('GoogleDriveCode');
    const tokenurl = `https://www.googleapis.com/oauth2/v4/token`;
    //Generate Token
    console.log("Code Values", this.codeValues);
    /*if (this.refreshToken == '') {*/
    var bodyTest = "code=" + encodeURIComponent(this.codeValues) + "&redirect_uri=" + encodeURIComponent(this.redirect_uri) + "&client_id=" + encodeURIComponent(this.clientId) + "&client_secret=" + encodeURIComponent(this.client_secret) + "&scope=&grant_type=authorization_code";

    // "code=4/0AX4XfWgzzUawOwMZJE_Qae6_EcFShEkOpaTt8Wai4sFC2brB5eIkBA1NuPT9y7kXLItvxQ&redirect_uri=https%3A%2F%2Fdevelopers.google.com%2Foauthplayground&client_id=407408718192.apps.googleusercontent.com&client_secret=************&scope=&grant_type=authorization_code";

    let headers1 = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let options1 = {
      headers: headers1
    }

    this.http.post(`${tokenurl}`, bodyTest, options1) //just set the name
      .toPromise()
      .then(response => {
        let res = response //parse id of uploaded file
        console.log("Token ", res);
        //refresh_token
        this.refreshToken = res["access_token"];
        this.storageService.set("GoogleDriveAccessToken", this.refreshToken);
        // this.refreshToken = res["access_token"];
        //  return res // return object back to function that called this service
      })
      .finally(() => {
        const url = "https://www.googleapis.com/drive/v3/files";
        this.gDriveLoadFiles(url, this.refreshToken)
      })
      .catch(e => console.log(e));
  }

  gDriveLoadFiles(url, accesstoken) {
    // accesstoken = '1//04LlYWPOOlBbdCgYIARAAGAQSNwF-L9IrSGxCvBoO962uSLvnRNbA9AxHSAFLFJHdeG-iJVopkusO1ShDazk6d6yNXyE2hvy6qpY';
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + accesstoken,
      'Content-Type': 'application/json',
    });


    let options = {
      headers: headers,
    }





    // this.myForm.addControl('file', file.file);
    //this.myForm.addControl('fileSource', file.fileSource);

    return this.http.get(url, options) //just set the name
      .toPromise()
      .then(response => {
        this.listGoogleDriveData = response["files"];

        this.collection.data = response["files"];
        console.log("File response ", response);



        //refresh_token

      })
      .catch(e => {

        this.UploadResponse = e //parse id of uploaded file
        console.log("Response Status", this.UploadResponse);
        //if (this.UploadResponse.status == '401') {
        //  this.codeValues = "";
        //  this.refreshToken = "";
        //  this.isCodeValue = true;
        //  this.storageService.remove('GoogleDriveCode');
        //  this.storageService.remove('GoogleDriveAccessToken');
        //}
      });
  }
  //Upload file to Google Drive
  uploadFileToGoogleDrive() {
    //var file = $("#files")[0].files[0];
    //var upload = new Upload(file);

    // //maby check size or type here with upload.getSize() and upload.getType()

    // //execute upload
    //upload.doUpload();
  }
  gDriveUploader(file): Promise<any> {
    console.log("Uploaded File Final", file);
    console.log("Uploaded File Final name", file.name);
    console.log(encodeURIComponent(this.redirect_uri))
    const tokenurl = `https://www.googleapis.com/oauth2/v4/token`;
    this.codeValues = this.storageService.get('GoogleDriveCode');
    this.refreshToken = this.storageService.get("GoogleDriveAccessToken");
    console.log("Code Values", this.codeValues);
    /*if (this.refreshToken == '') {*/
    var bodyTest = "code=" + encodeURIComponent(this.codeValues) + "&redirect_uri=" + encodeURIComponent(this.redirect_uri) + "&client_id=" + encodeURIComponent(this.clientId) + "&client_secret=" + encodeURIComponent(this.client_secret) + "&scope=&grant_type=authorization_code";

    // "code=4/0AX4XfWgzzUawOwMZJE_Qae6_EcFShEkOpaTt8Wai4sFC2brB5eIkBA1NuPT9y7kXLItvxQ&redirect_uri=https%3A%2F%2Fdevelopers.google.com%2Foauthplayground&client_id=407408718192.apps.googleusercontent.com&client_secret=************&scope=&grant_type=authorization_code";

    let headers1 = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    let options1 = {
      headers: headers1
    }
    return this.http.post(`${tokenurl}`, bodyTest, options1) //just set the name
      .toPromise()
      .then(response => {
        let res = response //parse id of uploaded file
        console.log("Token ", res);
        //refresh_token
        this.refreshToken = res["access_token"];
        this.storageService.set("GoogleDriveAccessToken", this.refreshToken);
        // this.refreshToken = res["access_token"];
        //  return res // return object back to function that called this service
      })
      .finally(() => {
        const url = "https://www.googleapis.com/upload/drive/v3/files?uploadType=media";
        this.gDriveUploadFile(file, url, this.refreshToken)
        this.isFileUploaded = false;
      })
      .catch(e => console.log(e));
    // }



    // this.gDriveUploadFile(file, url);
    //return this.http.post(`${url}`, options) //just set the name
    //  .toPromise()
    //  .then(response => this.gDriveUploadFile(file, url)) //call second function to upload `file` to proper URI from response
    //  .then(response => {
    //    let id = response.json().id //parse id of uploaded file
    //    let resp = { fileName: file.fullName, fileType: file.fileType, fileSize: file.size, fileId: id } //create an object with file file properties, if you need that
    //    return resp // return object back to function that called this service
    //  })
    //  .catch(e => console.log(e));
  }

  gDriveUploadFile(file, url, token): Promise<any> { //file and url we got from first func



    // var token = this.storageService.get('GoogleDriveAccessToken');
    console.log("Access token", token)
    var contentType = file.type;
    var metadata = {
      "name": "test.pdf",
      "mimeType": contentType,
    };
    var fileMetadata = {
      'name': file.name
    };

    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': contentType,
      'title': file.name,
    });


    let options = {
      headers: headers,
      body: metadata,
    }

    console.log("options", options);
    const uploadImageData = new FormData();
    uploadImageData.append('file', file, file.name);

    console.log("uploadImageData: ", uploadImageData);

    // this.myForm.addControl('file', file.file);
    //this.myForm.addControl('fileSource', file.fileSource);

    return this.http.post(url, uploadImageData, options) //just set the name
      .toPromise()
      .then(response => {
        this.UploadResponse = response //parse id of uploaded file
        console.log("File response ", this.UploadResponse);
        this.isFileUploaded = true;
        this.getFilesFromGoogleDrive()

        //refresh_token

      })
      .catch(e => {

        this.UploadResponse = e //parse id of uploaded file
        console.log("Response Status", this.UploadResponse.status);
        if (this.UploadResponse.status == '401') {
          this.codeValues = "";
          this.refreshToken = "";
          this.isCodeValue = true;
          this.storageService.remove('GoogleDriveCode');
          this.storageService.remove('GoogleDriveAccessToken');
        }

      });


  }

  get f() {
    return this.myForm.controls;
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      console.log(event.target.files);
      this.fileUpload = event.target.files[0];
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        this.myForm.patchValue({
          fileSource: reader.result
        });

      };

    }
  }

  submit() {
    console.log(this.myForm.value);
    console.log(this.fileUpload);
    var file = this.fileUpload;
    //this.http.post('http://localhost:8001/upload.php', this.myForm.value)
    //  .subscribe(res => {
    //    console.log(res);
    //    alert('Uploaded Successfully.');
    //  })

    this.gDriveUploader(file)
  }
  //Delete the file from google drive
  deleteFromGoogleDrive(id) {
    const url = "https://www.googleapis.com/drive/v3/files/" + id;
    console.log("Delete URL", url);
    // this.codeValues = this.storageService.get('GoogleDriveCode');
    var token = this.storageService.get("GoogleDriveAccessToken");
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': "application/json",
    });


    let options = {
      headers: headers
    }
    this.http.delete(url, options) //just set the name
      .toPromise()
      .then(response => {

        console.log("Delete File response ", response);
        this.getFilesFromGoogleDrive()


        //refresh_token

      })
      .catch(e => {

        this.UploadResponse = e //parse id of uploaded file
        console.log("Response Status", this.UploadResponse.status);
        //if (this.UploadResponse.status == '401') {
        //  this.codeValues = "";
        //  this.refreshToken = "";
        //  this.isCodeValue = true;
        //  this.storageService.remove('GoogleDriveCode');
        //  this.storageService.remove('GoogleDriveAccessToken');
        //}
      });

  }

  //Authorize rto Google Drive API
  initGoogleDriveAuthentication() {
    this.signIn(this.clientId, this.redirect_uri, this.scope, this.url);
  }

  signIn(clientId, redirect_uri, scope, url) {

    // the actual url to which the user is redirected to 
    //url = "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" + redirect_uri
    //  + "&prompt=consent&response_type=code&client_id=" + clientId + "&scope=" + scope
    //  + "&access_type=offline";
    url = "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=" + redirect_uri
      + "&response_type=code&client_id=" + clientId + "&scope=" + scope
      + "&access_type=offline";

    // this line makes the user redirected to the url

    window.location = url;




  }
  // File Manager's file onSuccess function
  onAjaxSuccess(args: any) {
    console.log("Ajax request successful");
  }
  // File Manager's file onError function
  onAjaxFailure(args: any) {
    console.log("Ajax request has failed");
  }
}
