import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostService } from 'src/app/_services/cost/cost.service';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';

@Component({
  selector: 'app-costrate',
  templateUrl: './costrate.component.html',
  styleUrls: ['./costrate.component.scss']
})
export class CostrateComponent implements OnInit {
  jsonData: any;
  quoteParameterForm: FormGroup;


  constructor(private contactService: CostService,private formBuilder: FormBuilder,private dataService: DataService,private router: Router, private storageService: LocalStorageService, private route: ActivatedRoute)
   {
      this.quoteParameterForm = this.formBuilder.group({
        id: new FormControl(''),
        annualInflationRate: new FormControl(''),
        standardEbit: new FormControl(''),
        standardRiskMargin: new FormControl(''),
        standardHandlingSurcharge: new FormControl(''),
        standardMonthlyWorkingHours: new FormControl(''),
        standardSubContractorWorkHourPerMonth: new FormControl(''),
        standardvat: new FormControl(''),
        standardThirdPartyWorkHourPerMonth: new FormControl(''),
        standardSgst: new FormControl(''),
        standardCustomerTarget: new FormControl(''),
        standardThirdPartyDiscount: new FormControl(''),
        standardCgst: new FormControl(''),
        standardThirdPartySurcharge: new FormControl(''),
        standardSubcontractDiscount: new FormControl(''),
  
      });
   }
     
   getQuoteParameter() {
   
    this.contactService.getQuoteParameter().subscribe(data => {
      console.log("GetQuoteParameterData", data);
      
      // if (data.id) {
      //   console.log("Data Found");
         this.quoteParameterForm.patchValue(data);
      //   //this.quoteParameterForm = data;
      // }
    });
  }
    

  ngOnInit(): void {
    this.contactService.getDefaultCostCenterLevelRates().subscribe((response:any) => {
      this.jsonData = response;
    });

    this.getQuoteParameter();
  }

  saveQuoteParameter() {
    let details = JSON.stringify(this.quoteParameterForm.value);
    console.log("Quotes Parameter", details);
    this.contactService.createQuoteParameter(details).subscribe((response: any) => {
      console.log("response: ", response);
      // this.dataSource.data.push({ ...response })
      // this.dataSource.data = this.dataSource.data.map(o => {
      //   return o;
      // })
      // if (response != null) {
      //   //this.grid.refresh();
      //   this.openSnackBar("New Quote Standard Value Saved");
      // }
    });
  }


}
