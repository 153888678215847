<div class="row">
  <div class="col-sm-8 mb-2">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">Calendar</h3>
      </div>
      <div class="card-body" style="padding: 2%;">
        <ejs-schedule #schedule height="550px" [(selectedDate)]="selectedDate"
            [eventSettings]="eventSettings" currentView="Month" (popupOpen)="onPopupOpen($event)">
            <ng-template #eventSettingsTemplate let-data>
                <div>
                    <div class="time" *ngIf="data.name == ''">
                      {{data.inTime}} - {{data.outTime}}
                    </div>
                    <div class="time" *ngIf="data.name != ''">
                          {{data.name}}
                    </div>
                </div>
            </ng-template>
        </ejs-schedule>
      </div>
    </div>
  </div>
  <div class="col-sm-4 mb-2">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">Employee Availablity</h3>
      </div>
      <div class="card-body" style="overflow-y: scroll;">
        <!-- <div class="p-3">
          <div *ngIf="this.resourceAvailabilityArray.length > 0 ">
            <span *ngFor="let item of resourceAvailabilityArray" style="margin-left: 10px;margin-top: 10px;">
              <i class="ri-time-line"></i> <b>"{{item.userName}}"</b> is available on <b>{{item.date}}</b>.
              <br>
            </span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="col-sm-4 mb-2">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">Project-Wise Booked Hours</h3>
      </div>
      <div class="card-body ">
        <div class="p-3">
          <div *ngIf="this.bookedHoursProjectArray.length > 0 ">
            <span *ngFor="let item of bookedHoursProjectArray" style="margin-left: 10px;margin-top: 10px;">
              <i class="ri-time-line"></i> Booked Hrs for <b>"{{item.projectName}}"</b> are <b>{{item.totalHours - item.remainingHours}}</b>.
              <br>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-4 mb-2">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">PMO Dashboard</h3>
      </div>
      <div class="card-body">
        <div class="p-3">
          <p>Emp availablity3</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">PMO Dashboard</h3>
      </div>
      <div class="card-body">
        <div class="p-3">
          <p>Emp availablity4</p>
        </div>
      </div>
    </div>
  </div>
</div>