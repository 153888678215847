<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center"> 
                                <div class="edit">
                                    <button title="back page" (click)="backToExit()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Exit Process</h3>
                            </div>
                        </div>
                    </div>
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6 d-flex">
                            <h3><a class="active cursor-pointer">Employee Exit Form</a></h3>
                            <h3><a (click)="openExitInterviewForm()" class="cursor-pointer">Employee Exit Interview Form</a></h3>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div>
                            <p>
                                You worked with us & have contributed your share towards this organization. 
                                We appreciate your services & are interested in having your views towards the company 
                                & your colleagues, during your tenure with us.
                            </p>
                            <p>
                                We value your honest, objective & unbiased views on the following issues. 
                                We assure you that sharing this information with others would be kept anonymous 
                                & used to increase our commitment towards being a fair organization.
                            </p>
                        </div>
                        <form [formGroup]="employeeExitForm">
                            <div class="row">
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="userId">
                                            <label class="floating-label-special">Name of Employee</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="localId">
                                            <label class="floating-label-special">Employee ID/ID No.</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="lastWorkingDate">
                                            <label class="floating-label-special">Last Working Day</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="dateOfJoining">
                                            <label class="floating-label-special">Date of Joining</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="currentDesignation">
                                            <label class="floating-label-special">Designation</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="department">
                                            <label class="floating-label-special">Department</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="location">
                                            <label class="floating-label-special">Location</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="department">
                                            <label class="floating-label-special">Dept./Project Name</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 px-3 py-1">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" formControlName="reportingManager">
                                            <label class="floating-label-special">Reporting Manager</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p style="padding-top: 10px; margin-bottom: -5px;">
                                        What was your principal reason for leaving the Job ?*
                                    </p>
                                    <div class="col-sm-8 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="mainReasonForLeavingCurrentJob" required>
                                                    <option value="" disabled selected>Select Reason</option>
                                                    <option value="Health Concerns">Health Concerns</option>
                                                    <option value="Continue Education">Continue Education</option>
                                                    <option value="Personal reasons">Personal reasons</option>
                                                    <option value="Relocating residence">Relocating residence</option>
                                                    <option value="Marriage">Marriage</option>
                                                    <option value="Performance related">Performance related</option>
                                                    <option value="To Accept other employment">To Accept other employment</option>
                                                </select>
                                                <label class="floating-label-special">Reason</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p style="padding-top: 10px; margin-bottom: -5px;">
                                        Please Indicate the main reason for accepting other employment*
                                    </p>
                                    <div class="col-sm-8 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="mainReasonForAcceptingOtherJob">
                                                    <option value="" disabled selected>Select Reason</option>
                                                    <option value="Promotion/career advancement">Promotion/career advancement</option>
                                                    <option value="Distance to/from work">Distance to/from work</option>
                                                    <option value="Work Schedule">Work Schedule</option>
                                                    <option value="Better benefits">Better benefits</option>
                                                    <option value="Better Pay">Better Pay</option>
                                                    <option value="Career change">Career change</option>
                                                    <option value="Termination">Termination</option>
                                                </select>
                                                <label class="floating-label-special">Reason</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p style="padding-top: 10px; margin-bottom: -5px;">
                                        What did you like most about your employment experience here?
                                    </p>
                                    <div class="col-sm-8 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <textarea name="" id="" cols="20" rows="2" formControlName="mostLiked"></textarea>
                                                <label class="floating-label-special">Comment</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p style="padding-top: 10px; margin-bottom: -5px;">
                                        What did you like least about your employment experience here?
                                    </p>
                                    <div class="col-sm-8 px-3 py-1">
                                        <div class="input">
                                            <div class="input-item">
                                                <textarea name="" id="" cols="20" rows="2" formControlName="leastLiked"></textarea>
                                                <label class="floating-label-special">Comment</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer" style="padding-top: 10px;">
                                    <button class="btn-secondary btn" (click)="backToExit()">Cancel</button>
                                    <button type="button" class="btn" (click)="saveEmployeeExitForm()">Save</button>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>


<!-- You worked with us & have contributed your share towards this organization. We appreciate your  services & are interested in having your views towards the company & your colleagues, during  your tenure with us.  
We value your honest, objective & unbiased views on the following issues. We assure you that  sharing this information with others would be kept anonymous & used to increase our  commitment towards being a fair organization. -->