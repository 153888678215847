import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup, FormControl, Validators } from "@angular/forms";
import { UserService } from "../../../_services/user/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Location } from "@angular/common";


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required)
  });
    
  passwordMatchValidator( resetForm: FormGroup) {
    return  this.resetForm.controls['newPassword'].value === this.resetForm.controls['confirmPassword'].value ? null : {'mismatch': true};
  };

  constructor(private router: Router, private userService: UserService, private snackBar: MatSnackBar, private _location: Location) { }

  ngOnInit(): void {
  }

  onSubmit() {
    console.log("In onSubmit method, resetForm value: ", this.resetForm.value);
    if (this.resetForm.invalid) {
      return;
    }
    delete this.resetForm.value.confirmPassword;
    let resource = JSON.stringify(this.resetForm.value);
    console.log("resource: ", resource);
    this.userService.passwordReset(resource).subscribe((response: any) => {
      console.log("response: ", response);
      if (response != null) {
        this.snackBar.open('Password reset successful!', 'Close', { duration: 3000 });
         this._location.back();
      }
    });
  }

  cancel() {
    this._location.back();
  }

}
