import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ChartComponent, ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TimesheetService } from "../../../../../_services/timesheet/timesheet.service";
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { ProjectService } from 'src/app/_services/project/project.service';
import { convertYYYYMMDDtoDDMMYYYY } from 'src/app/_helpers/utils';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';

@Component({
  selector: 'app-timesheet-graph',
  templateUrl: './timesheet-graph.component.html',
  styleUrls: ['./timesheet-graph.component.scss']
})
export class TimesheetGraphComponent implements OnInit {
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  public quoteDropdown;
  public employeeList: Array<any> = [];
  public projectList: Array<any> = [];
  public selectedType: any = null;
  formGroup: FormGroup;
  mainCPGroup: FormGroup;
  TotalRow: number;
  public GraphData: Object[];
  public GraphDataName: Object[];
  id: number;
  tenantId: string;
  public lineGraph: Object[];
  public activeIndex: boolean = false;
  public chartData: Object[];
  public GraphCount: number;
  public uniqueChars: Object[];
  currentUser: any;
  userDropdownList = [];
  isPMOManager: boolean = false;
  projectDropdownList = [];
  projectCode: number;
  projname: any;
  getProjectDetailsList: any;
  getUserName: any;
  proj = [];
  public typeList: Array<any> = [
    {
      lable: 'Project',
      value: 'project'
    },
    {
      lable: 'User',
      value: 'user'
    }
  ];
  snackMessage: string;
  // public chartArea: Object;
  @ViewChild('drawer') drawer: SidebarComponent;
  @ViewChild('chart')
  public chart: ChartComponent;
  public yMaxValue = 0;
  //Initializing Primary X and Y Axis
  public primaryXAxis: any = {};
  public primaryYAxis: any = {};
  public title: string = 'Timesheet Report';
  tooltip: any = {
    enable: true,
  };
  graphForm: any;

  // custom code end
  // public title: string = 'Mobile Game Market by Country';
  public chartArea: Object = {
    border: {
      width: 0
    }
  };

  public legendSettings: Object = { visible: true };
  public columnMarker: Object = { dataLabel: { visible: true, position: 'Top' } };
  public lineMarker: Object = { visible: true, dataLabel: { visible: true } };
  public data: Object[];
  public width: Object = Browser.isDevice ? '100%' : '90%';
  timesheetGraphFormDetails: FormGroup;
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private timesheetService: TimesheetService,
    private allocationService: AllocationService,
    private projectService: ProjectService,
    private storageService: LocalStorageService
  ) {
    this.data = [];
    this.route.queryParams.subscribe(params => {
      this.id = params.id;
    });
    this.timesheetGraphFormDetails = this.fb.group({
      type: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });
  }

  drawerClose() {
    // this.capacityPlanningFormDetails.reset();
    this.activeIndex = false;
    this.drawer.close();
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isPMOManager = this.currentUser.roles.includes('RoleHrmAdministrator') || this.currentUser.roles.includes('RolePmoManager')
    this.getUserList();
    this.getProjectList();
    this.graphForm = this.fb.group({
      type: [null],
      ids: [null],
      startDate: [""],
      endDate: [""]
    });
    this.primaryXAxis = {
      majorGridLines: { width: 3 },
      minorGridLines: { width: 3 },
      majorTickLines: { width: 3 },
      minorTickLines: { width: 3 },
      interval: 1,
      lineStyle: { width: 0 },
      valueType: "Category"
    };
    this.primaryYAxis = {
      title: "Hours",
    };
  }

  backToTimesheetReport() {
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/timesheet-report']);
  }

  onchangeType() {
    this.graphForm.patchValue({
      ids: []
    });
  }

  getUserList() {
    this.userDropdownList = [];

    if (this.isPMOManager === true) {
      this.allocationService.getShiftAllocationUsers().subscribe((response) => {
        for (let item of response) {
          this.employeeList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      });
    }
    else {
      this.allocationService.getUserDetails().subscribe((response) => {
        for (let item of response) {
          this.employeeList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      })
    }
  }

  getProjectList() {
    if(this.isPMOManager === true){
      this.projectService.getProjectByUserId(this.currentUser.id, false).subscribe((response: any) => {
        this.projectList = response;
      });
    }
    else{
      this.projectService.getProjectDetails().subscribe(response => {
        this.projectList = response;
      })
    }
  }

  public onSelectAll(type) {
    this.graphForm.get('ids').patchValue([]);
    switch (type) {
      case 'project':
        const selectedProject = this.projectList.map(item => item.id);
        this.graphForm.get('ids').patchValue(selectedProject);
        break;
      case 'user':
        const selected = this.employeeList.map(item => item.id);
        this.graphForm.get('ids').patchValue(selected);
        break;
      default:
        break;
    }
  }

  public onClearAll() {
    this.graphForm.get('ids').patchValue([]);
  }

  onTooltipRender(event: any) {
    const data = this.data
    const data2 = event.data;
    let data3;
    data.forEach(p => {
      if (p["xName"] === data2["pointX"] && p["yName"] === data2["pointY"]) {
        data3 = p;
      }
    });
    if (data3) {
      event.text = data3["seriesName"] + " : " + data3["yName"];
    }
  }

  generateReport() {
    let type = this.graphForm.value.type ? this.graphForm.value.type : '';
    let startDate = this.graphForm.value.startDate ? convertYYYYMMDDtoDDMMYYYY(this.graphForm.value.startDate) : '';
    let endDate = this.graphForm.value.endDate ? convertYYYYMMDDtoDDMMYYYY(this.graphForm.value.endDate) : '';
    let ids = this.graphForm.value.ids && this.graphForm.value.ids.length > 0 ? this.graphForm.value.ids.join(',') : "";
    let queryParams = `type=${type}&startDate=${startDate}&endDate=${endDate}&ids=${encodeURIComponent(ids)}`;

    this.timesheetService.getTimesheetReport(queryParams).subscribe((res) => {
      if (res) {
        let userArray: Array<any> = [];  // This array will store the final formatted data for the chart
        let projectArray: Array<any> = [];  // This array will store the final formatted data for the chart

        if (type == 'user') {
          this.primaryXAxis = { valueType: 'Category', title: 'Employee' };
          this.primaryYAxis = { title: 'Project Total Hours' };

          if (res['employeesInfo'] && res['employeesInfo'].length > 0) {
            let employees = res['employeesInfo'];
            employees.forEach(emp => {
              if (emp['projects'] && emp['projects'].length > 0) {
                let projects = emp['projects'];
                projects.forEach(proj => {
                  userArray.push({
                    xName: emp.employee.name,
                    yName: proj.projectTotalHours,
                    seriesName: proj.projectName,
                  });
                });
              }
            });
          }
          this.data = userArray;  // Update chart data for user view
        }

        if (type == 'project') {
          this.primaryXAxis = { valueType: 'Category', title: 'Project' };
          this.primaryYAxis = { title: 'User Approved Hours' };

          if (res['projectsInfo'] && res['projectsInfo'].length > 0) {
            let projects = res['projectsInfo'];
            projects.forEach(proj => {
              if (proj['employees'] && proj['employees'].length > 0) {
                let employees = proj['employees'];
                employees.forEach(emp => {
                  projectArray.push({
                    xName: proj.project.projectName,
                    yName: emp.userTotalApprovedHours,
                    seriesName: emp.name,
                  });
                });
              }
            });
            this.data = projectArray;  // Update chart data for project view
          }
        }
      }
    });
  }

  public mergeKeysWithIndex(obj, index) {
    const result = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        result[`${key}_${index + 1}`] = obj[key];
        // index++;
      }
    }

    return result;
  }
}