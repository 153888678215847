import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuotesComponent } from "./quotes/quotes.component";
import { QuoteDetailsComponent } from "./quote-details/quote-details.component";
import { OpportunitiesComponent } from "./opportunities.component";
import { OpportunityComponent } from "./opportunity/opportunity.component";
import { AuthGuardService } from 'src/app/_helpers/auth-guard.service';

const routes: Routes = [{
  path: '',
  component: OpportunitiesComponent,
  children: [
    {
      path: '',
      component: OpportunityComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'quotes',
      component: QuotesComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'quote-details',
      component: QuoteDetailsComponent, canActivate: [AuthGuardService]
    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OpportunitiesRoutingModule { }
