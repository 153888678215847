import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import { values } from 'lodash';
import {MatPaginator} from '@angular/material/paginator';
// Imported syncfusion InPlaceEditorAllModule from ej2-angular-inplace-editor package
import { InPlaceEditorAllModule } from '@syncfusion/ej2-angular-inplace-editor';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';



/* 1*/
export interface PeriodicElement {
  
  task: string;
  hours: number;
  avgfte: number;
  peakfte: number;
  avgpricerate: number;
  cost_mp: number;
  cost_subcon: number;
  cost_totmp: number;
  cost_travel:number;
  cost_service: number;
  cost_tstandfaclts: number;
  cost_othrs: number;
  cost_totodc: number;
  cost_soft: number;
  cost_grndtotcst: number;
  price_mp: number;
  price_subcon: number;
  price_totmp: number;
  price_travel:number;
  price_service: number;
  price_tstandfaclts: number;
  price_othrs: number;
  price_totodc: number;
  price_soft: number;
  price_grndtotcst: number;
}
/* 1 */
/* 2 */
const ELEMENT_DATA: PeriodicElement[] = [
  {task: 'taskname1', hours: 1000, avgfte: 1000, peakfte: 1000, avgpricerate: 1000,cost_mp: 1000,cost_subcon: 1000,cost_totmp: 1000,cost_travel: 1000,cost_service: 1000,cost_tstandfaclts: 1000,cost_othrs: 1000,cost_totodc: 1000,cost_soft:1000, cost_grndtotcst:1000,price_mp: 1000,price_subcon: 1000,price_totmp: 1000,price_travel: 1000,price_service: 1000,price_tstandfaclts: 1000,price_othrs: 1000,price_totodc: 1000,price_soft:1000,price_grndtotcst:1000},
  {task: 'taskname2', hours: 1000, avgfte: 1000, peakfte: 1000, avgpricerate: 1000,cost_mp: 1000,cost_subcon: 1000,cost_totmp: 1000,cost_travel: 1000,cost_service: 1000,cost_tstandfaclts: 1000,cost_othrs: 1000,cost_totodc: 1000,cost_soft:1000, cost_grndtotcst:1000,price_mp: 1000,price_subcon: 1000,price_totmp: 1000,price_travel: 1000,price_service: 1000,price_tstandfaclts: 1000,price_othrs: 1000,price_totodc: 1000,price_soft:1000,price_grndtotcst:1000},
  {task: 'taskname3', hours: 1000, avgfte: 1000, peakfte: 1000, avgpricerate: 1000,cost_mp: 1000,cost_subcon: 1000,cost_totmp: 1000,cost_travel: 1000,cost_service: 1000,cost_tstandfaclts: 1000,cost_othrs: 1000,cost_totodc: 1000,cost_soft:1000, cost_grndtotcst:1000,price_mp: 1000,price_subcon: 1000,price_totmp: 1000,price_travel: 1000,price_service: 1000,price_tstandfaclts: 1000,price_othrs: 1000,price_totodc: 1000,price_soft:1000,price_grndtotcst:1000},
  
];
/* 2 */

export class Heroes {
  
  heroes = HEROES;

}

const HEROES = [
  {id: 1, name:'Superman'},
  {id: 2, name:'Batman'},
  {id: 5, name:'BatGirl'},
  {id: 3, name:'Robin'},
  {id: 4, name:'Flash'}
];

function AppCtrl($scope) {

  $scope.filterCondition={
      operator: 'eq'
  }
  
  $scope.operators = [
      {value: 'eq', displayName: 'equals'},
      {value: 'neq', displayName: 'not equal'}
   ]
}



@Component({
  selector: 'app-datagridtest',
  templateUrl: './datagridtest.component.html',
  styleUrls: ['./datagridtest.component.scss']
})
export class DatagridtestComponent implements OnInit {

 

  public genderData: string[] = ['Male', 'Female'];
  public dateModel: Object = { showTodayButton: true, placeholder: 'Select date of birth' };
  public dateValue: Date = new Date('04/12/2018');
  public elementModel: Object = { placeholder: 'Enter your name' };
  public genderModel: Object = {  dataSource: this.genderData, placeholder: 'Select gender' };

  peopleByCountry: any[] = [
    {
      'country': 'UK',
      'people': [
        {
          "name": "Douglas  Pace"
        },
        {
          "name": "Mcleod  Mueller"
        },
      ]
    },
    {
      'country': 'US',
      'people': [
        {
          "name": "Day  Meyers"
        },
        {
          "name": "Aguirre  Ellis"
        },
        {
          "name": "Cook  Tyson"
        }
      ]
    }
  ];

  people: any[] = [
    {
      "name": "Douglas  Pace"
    },
    {
      "name": "Mcleod  Mueller"
    },
    {
      "name": "Day  Meyers"
    },
    {
      "name": "Aguirre  Ellis"
    },
    {
      "name": "Cook  Tyson"
    }
  ];
  
  /* 3 */
  displayedColumns: string[] = ['task', 'hours', 'avg fte', 'peak fte', 'avg price rate', 'manpower', 'sub contracting', 'total manpower', 'travel', 'service', 'test and facilities', 'others', 'total odc', 'software', 'grand total cost', 'manpower_price', 'sub contracting_price', 'total manpower_price', 'travel_price', 'service_price', 'test and facilities_price', 'others_price', 'total odc_price', 'software_price', 'net price_price'];
  /*dataSource = ELEMENT_DATA;*/
  /* 3 */
  /* 4 */

  
  dataSource = new MatTableDataSource(ELEMENT_DATA);




  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /* 4 */

  constructor() { }

  public data: object[];
  public pageSettings: PageSettingsModel;

  ngOnInit(): void {

    this.data = this.data;
    this.pageSettings = { pageSize: 6 };
  }

  //inside export class

arrayBuffer:any;
file:File;
incomingfile(event) 
  {
  this.file= event.target.files[0]; 
  console.log("Xlsx file selected to upload");
  }

 Upload() {
  console.log("Xlsx upload initiated");

      let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type:"binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            console.log(" check", XLSX.utils.sheet_to_json(worksheet,{raw:true}));
        }
        fileReader.readAsArrayBuffer(this.file);
}
  
  willDownload = false;

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      console.log("xlsx to json text check", dataString);
      document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      this.setDownload(dataString);
    }
    reader.readAsBinaryString(file);
  }


  setDownload(data) {
    this.willDownload = true;
    setTimeout(() => {
      const el = document.querySelector("#download");
      el.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(data)}`);
      el.setAttribute("download", 'xlsxtojson.json');
    }, 1000)

  }

  clearTable() {
    this.dataSource.data = [];
  }

  addData() {
    this.dataSource.data = ELEMENT_DATA;
  }

  /* Toggle Display 1 start*/
  totalmanpowerisShow:boolean = true;
  tab_colspan_cost = 4;

  toggleDisplay() {
    this.totalmanpowerisShow = !this.totalmanpowerisShow;
    if (this.totalmanpowerisShow == false) {
      this.tab_colspan_cost = this.tab_colspan_cost + 2;
    } else{
      this.tab_colspan_cost = this.tab_colspan_cost - 2;
    }
  }
    /* Toggle Display 1 ends */

    /* Toggle Display 1 start*/
  totalodcisShow:boolean = true;


  toggleDisplay_totalodc() {
    this.totalodcisShow = !this.totalodcisShow;
    if (this.totalodcisShow == false) {
      this.tab_colspan_cost = this.tab_colspan_cost + 4;
    } else{
      this.tab_colspan_cost = this.tab_colspan_cost - 4;
    }
  }
    /* Toggle Display 1 ends */

    /* Toggle Display 1 start*/
  totalmanpowerpriceisShow:boolean = true;
  tab_colspan_price = 4;

  toggleDisplay_totalmanpowerprice() {
    this.totalmanpowerpriceisShow = !this.totalmanpowerpriceisShow;
    if (this.totalmanpowerpriceisShow == false) {
      this.tab_colspan_price = this.tab_colspan_price + 2;
    } else{
      this.tab_colspan_price = this.tab_colspan_price - 2;
    }
  }
    /* Toggle Display 1 ends */

    /* Toggle Display 1 start*/
  totalodcpriceisShow:boolean = true;

  toggleDisplay_totalodcprice() {
    this.totalodcpriceisShow = !this.totalodcpriceisShow;
    if (this.totalodcpriceisShow == false) {
      this.tab_colspan_price = this.tab_colspan_price + 4;
    } else{
      this.tab_colspan_price = this.tab_colspan_price - 4;
    }
  }
    /* Toggle Display 1 ends */

}
