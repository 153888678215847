import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends Subject<DataStateChangeEventArgs>  {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);
   }

   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }

   // Create notifications
   createNotifications(user: any): Observable<Object> {
    console.log("In createitAssets method, notifications: ", user);
    return this.http.post(`${environment.apiUrl}/notifications`, user).pipe(catchError(this.handleError));
  }

   // Get notifications by Id Get
   getNotifications(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/notifications/${id}`).pipe(catchError(this.handleError));
   }

   
   //Delete notifications by Id Delete
   deleteNotifications(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/notifications/${id}`).pipe(catchError(this.handleError));
   }

    //Update notifications by Id Put
    updateNotifications(user: any,id: number): Observable<Object> {
      console.log("In createUser method, user: ", user);
      return this.http.put(`${environment.apiUrl}/notifications/${id}`, user).pipe(catchError(this.handleError));
    }
    
      //Get All notifications by Id Get
      getAllNotifications(): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/notifications`).pipe(catchError(this.handleError));
    }

      /**
       * get dashboard notifications
       * @returns 
       */
      getDashboardNotifications(): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/dashboardNotifications`).pipe(catchError(this.handleError));
      }

}
