import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class HrService {

  private httpClient: HttpClient;
  url: string;

  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    this.httpClient = new HttpClient(handler);

  }

  public deleteleaveAppRates(id: number) {
    return this.http.delete(`${environment.apiUrl}/leaveApplications/${id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getUserLeaveApplicationDetailsById(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/leaveApplications/${Id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getUserbtApplicationDetailsById(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/businessTravels/${Id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public addleaveAppRates(addladata: Object) {
    console.log('addleaveAppRates: ', addladata);
    return this.http
      .post<any>(`${environment.apiUrl}/leaveApplications`, addladata)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public addbusinesstravelRates(addbtdata: Object, userId: string) {
    console.log('addbusinessrtravelRates: ', addbtdata);
    return this.http
      .post<any>(`${environment.apiUrl}/businessTravels`, addbtdata)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  updateGeneralDetails(leaveappdetailsdata: Object, id: number) {
    console.log("leaveappdetailsdata Details, Service response: ", leaveappdetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/leaveApplications/${id}`, leaveappdetailsdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updateleaveappDetailsbyId(leaveappupdatedetailsdata: Object, id: number) {
    console.log("leaveappdetailsdata Details, Service response: ", leaveappupdatedetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/leaveApplications/${id}`, leaveappupdatedetailsdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  updatebtappDetailsbyId(btappupdatedetailsdata: Object, id: number) {
    console.log("btappupdatedetailsdata Details, Service response: ", btappupdatedetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/businessTravels/${id}`, btappupdatedetailsdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getUserLeaveApplicationDetails(userId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/leaveApplications?userId=${userId}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getUserLeaveApplicationDetailsByYear(userId: number, year: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/leaveApplications?userId=${userId}&year=${year}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }


  //Leave Approval
  public getUserLeaveApplicationApproval(userId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/approvals?userId=${userId}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getUserBusinessTravelDetails(userId: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/businessTravels?userId=${userId}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public deletebusinessAppRates(id: number) {
    console.log('in service, BusinessApp Delete data: ', id);
    return this.http
      .delete<any>(`${environment.apiUrl}/businessTravels/${id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getUserLeveBalance(id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/users/${id}/leavesBook`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public updateUserLeveBalance(userId: number, leaveBookId: number, data: object) {
    return this.http
      .put<any>(`${environment.apiUrl}/users/${userId}/leavesBook/${leaveBookId}`, data)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  private handleError<T>() {
    return (error: any): Observable<boolean> => {
      return of(false);
    };
  }

  uploadImage(id: number, data: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.httpClient.post(`${environment.apiUrl}/leaveApplications/` + id + `/attachments`, data, header).pipe(catchError(this.handleError<any>()));
  }

  
  uploadbtImage(id: number, data: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.httpClient.post(`${environment.apiUrl}/businessTravels/` + id + `/attachments`, data, header).pipe(catchError(this.handleError<any>()));
  }


  public getleaveApplicationsattachments(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/leaveApplications/${Id}/attachments`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getbtApplicationsattachments(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/businessTravels/${Id}/attachments`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public deleteleaveApplicationsattachments(laId: any, imageid: number) {
    //console.log('in service, deleteleaveApplicationsattachments data: ', imageid);
    return this.http
      .delete<any>(`${environment.apiUrl}/leaveApplications/${laId}/attachments/${imageid}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public deletebtApplicationsattachments(laId: any, imageid: number) {
    //console.log('in service, deletebtApplicationsattachments data: ', imageid);
    return this.http
      .delete<any>(`${environment.apiUrl}/businessTravels/${laId}/attachments/${imageid}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getLeaveApplicationByUserId(Id: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/leaveApplications?userId=${Id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getLeaveApprovalReport(startDate, endDate) {
    let params = new HttpParams();
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.http
      .get<any>(`${environment.apiUrl}/leaveApplicationsReport`, { params: params })
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public downloadbtApplicationsattachments(btId: any, imageid: number): any {
    //console.log('in service, downloadbtApplicationsattachments imageid: ', imageid);
    
    return this.http
      .get<Blob>(`${environment.apiUrl}/businessTravels/${btId}/attachments/${imageid}`,{observe: 'response' ,responseType: 'blob' as 'json'})
      
  }

  public downloadleaveApplicationsattachments(laId: any, imageid: number) {
    //console.log('in service, downloadleaveApplicationsattachments imageid: ', imageid);
    
    return this.http
      .get<Blob>(`${environment.apiUrl}/leaveApplications/${laId}/attachments/${imageid}`,{observe: 'response' ,responseType: 'blob' as 'json'})
            
  }
   ///added for sal distribution
public fetchList()
{
  const header = {
    headers: new HttpHeaders({
      'Authorization': this.storageService.get('token'),
      'tenantId': this.storageService.get('tenantId'),
      'Accept' : 'application/json'
    }),
  };
  return this.http.get<any>(`${environment.apiUrl}/salaryDistributions`,header).pipe(catchError(this.handleError<any>()));

}
  public createSalary(data)
  {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
        'Accept' : 'application/json'
      }),
    };
    return this.http.post<any>(`${environment.apiUrl}/salaryDistributions`, data,header).pipe(catchError(this.handleError<any>()));
  }

  public deleteSalry(id)
  {
    console.log("ioio",id)
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
        'Accept' : 'application/json'
      }),
    };
    return this.http.delete<any>(`${environment.apiUrl}/salaryDistributions/${id}`).pipe(catchError(this.handleError<any>()));
  }

  public getSalaryApplicationDetailsById(Id: number) {
    console.log("service",Id)
    return this.http
      .get<any>(`${environment.apiUrl}/salaryDistributions/${Id}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }
  updateSalaryDetailsbyId(salaryupdatedetailsdata: Object, id: number) {
    console.log("salaryupdatedetailsdata Details, Service response: ", salaryupdatedetailsdata);
    return this.http
      .put<any>(`${environment.apiUrl}/salaryDistributions/${id}`, salaryupdatedetailsdata,
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getUserPaySlip(currentUserId: string,payslipMonth: string,payslipYear: number,payslipFinancialYear: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/users/${currentUserId}/payslip?month=${payslipMonth}&year=${payslipYear}&financialYear=${payslipFinancialYear}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getAllUserPaySlip(payslipMonth: string,payslipYear: number,payslipFinancialYear: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/userspayslip?month=${payslipMonth}&year=${payslipYear}&financialYear=${payslipFinancialYear}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public getpayslipTillMonth(currentUserId: string,payslipMonth: string,payslipYear: number,payslipFinancialYear: string) {
    return this.http
      .get<any>(`${environment.apiUrl}/users/${currentUserId}/payslipTillMonth?month=${payslipMonth}&year=${payslipYear}&financialYear=${payslipFinancialYear}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  public sendPayslipByEmail(payload: object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/users/payslipByEmail`, payload).pipe(catchError(this.handleError<any>()));
  }

  public getHrLookup(userId: Number,startDate: String, endDate: String) {
    // Date foirmat should be dd-MM-yyyy
    return this.http
      .get<any>(`${environment.apiUrl}/hrlookup?userId=${userId}&startTime=${startDate}&endTime=${endDate}`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  //Old Tax Regim
  public getOldTaxRegim(userId: string,year: number,financialYear: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/users/${userId}/payslipforRegime?year=${year}&financialYear=${financialYear}&regime=Old`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }
   //New Tax Regim
   public getNewTaxRegim(userId: string,year: number,financialYear: number) {
    return this.http
      .get<any>(`${environment.apiUrl}/users/${userId}/payslipforRegime?year=${year}&financialYear=${financialYear}&regime=New`)
      .pipe(
        map((result) => {
          return result;
        }),
        catchError(this.handleError<any>())
      );
  }

  //Tax Expection New
   // Get TaxDeduction by Id Get
   getTaxDeductionNew(employeeId: number,financialYear: number) {
    return this.http.get(`${environment.apiUrl}/taxExemptions?employeeId=${employeeId}&financialYear=${financialYear}&regime=New`)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //Tax Expection New
   // Get TaxDeduction by Id Get
   getTaxDeductionOld(employeeId: number,financialYear: number) {
    return this.http.get(`${environment.apiUrl}/taxExemptions?employeeId=${employeeId}&financialYear=${financialYear}&regime=Old`)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //Leave Book Detail
  getLeaveBookDetail(month: string,year: number){
    return this.http.get(`${environment.apiUrl}/users/leavesBooks?month=${month}&year=${year}`)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //OLD VERSION Leave Balance Record Detail
  getLeaveBalanceReport(year: number){
    return this.http.get(`${environment.apiUrl}/leaveApplicationBalanceReport?year=${year}`)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //NEW VERSION Leave Balance Record Detail
  getLeaveBalanceReportV2(year: number){
    return this.http.get(`${environment.apiUrl}/leaveApplicationBalanceReport/v2?year=${year}`)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //Get Leave Adjustments 
  getLeaveAdjustmentsReport(){
    return this.http.get(`${environment.apiUrl}/leaveAdjustments`)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //Create Leave Adjustments 
  createLeaveAdjustmentsReport(userId: number, createdByUserId: number, leaveData: object){
    return this.http.post(`${environment.apiUrl}/leaveAdjustments?userId=${userId}&createdByUserId=${createdByUserId}`, leaveData)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  //get Leave Balance Logs
  getLeaveBalanceLogs(userId: number, startDate: String, endDate: String){

    let startDateUrl : String = startDate === 'undefined-undefined-' ? '' : `&startDate=${startDate}`;
    let endDateUrl : String = endDate === 'undefined-undefined-' ? '' : `&endDate=${endDate}`;
    let url = `${environment.apiUrl}/leaveBalanceLogs?userId=${userId}${startDateUrl}${endDateUrl}`;

    return this.http.get(url)
    .pipe(
      map((result) => {
        return result;
      }),
      catchError(this.handleError<any>())
    );
  }

  createLOPRevoke(data: Object): Observable<Object>{
    return this.http.post(`${environment.apiUrl}/lopRevoke`, data).pipe(catchError(this.handleError()));
  }

  updateLOPRevoke(data: Object, id: number): Observable<Object>{
    return this.http.put(`${environment.apiUrl}/lopRevoke/${id}`, data).pipe(catchError(this.handleError()));
  }

  deleteLOPRevoke(id: number): Observable<Object>{
    return this.http.delete(`${environment.apiUrl}/lopRevoke/${id}`).pipe(catchError(this.handleError()));
  }

  getLOPRevokeById(id: number): Observable<Object>{
    return this.http.get(`${environment.apiUrl}/lopRevoke/${id}`).pipe(catchError(this.handleError()));
  }

  getAllLOPRevoke(): Observable<Object>{
    return this.http.get(`${environment.apiUrl}/lopRevoke`).pipe(catchError(this.handleError()));
  }
}
