<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div
            class="canvas-header row justify-content-between align-items-center"
          >
            <div class="title col-sm-6">
              <h3>Overtime</h3>
            </div>
            <div class="col-sm-6 d-flex justify-content-end">
              <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                <button
                  type="button"
                  title="Edit"
                  (click)="editOpenModal()"
                  data-bs-toggle="modal"
                  data-bs-target="#overtime"
                >
                  <span><i class="ri-pencil-line"></i></span>
                </button>
              </div>
              <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                <button
                  type="button"
                  title="Delete"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteOvertimeModal"
                  (click)="deleteOpenModal()"
                >
                  <span><i class="ri-delete-bin-5-line"></i></span>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  title="Create"
                  (click)="createOpenModal()"
                  data-bs-toggle="modal"
                  data-bs-target="#overtime"
                  class="btn btn-icon"
                >
                  <i class="ri-add-line"></i>
                </button>
              </div>
            </div>
          </div>
          <ag-grid-angular
            style="height: 620px;"
            class="ag-theme-material"
            (gridReady)="onGridReady($event)"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            pagination="true"
            [defaultColDef]="defaultColDef"
            [suppressRowClickSelection]="true"
            [rowSelection]="rowSelection"
            (selectionChanged)="onSelectionChanged($event)"
            paginationPageSize="10"
          >
          </ag-grid-angular>
        </div>
      </section>
    </section>
  </article>
</main>

<!-- Create new Overtime -->
<div class="modal fade bd-example-modal-lg" id="overtime" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form [formGroup]="overtimeForm">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="modeAction === 1">Create Overtime</h5>
          <h5 class="modal-title" *ngIf="modeAction === 2">Update Overtime</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="input">
            <div class="input-item">
              <input formControlName="title" type="text" />
              <label class="floating-label">Title</label>
              <i class="ri-file-list-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <label class="floating-label"></label>
              <input
                list="taskOwnerDatalist"
                formControlName="appliedForId"
                placeholder="Select Applied For"
              />
              <datalist id="taskOwnerDatalist">
                <option
                  [value]="item"
                  *ngFor="let item of userDropdownList"
                ></option>
              </datalist>
              <i class="ri-shield-user-line"></i>
            </div>
            <mat-error *ngIf="f.appliedForId.touched && f.appliedForId.invalid" class="alert alert-danger">
              <mat-error *ngIf="f.appliedForId.errors.required">Applied for User is required</mat-error>
            </mat-error>
          </div>
          <div class="input">
            <div class="input-item">
              <label class="floating-label"></label>
              <input
                list="projectDetailList"
                formControlName="projectId"
                placeholder="Select Project"
              />
              <datalist id="projectDetailList">
                <option
                  [value]="item"
                  *ngFor="let item of projectDetailList"
                ></option>
              </datalist>
              <i class="ri-shield-user-line"></i>
            </div>
              <mat-error *ngIf="f.projectId.touched && f.projectId.invalid" class="alert alert-danger">
                <mat-error *ngIf="f.projectId.errors.required">Project is required</mat-error>
              </mat-error>
          </div>
          <div class="input">
            <div class="input-item">
              <input formControlName="explaination" type="text" />
              <label class="floating-label">Explaination</label>
              <i class="ri-file-list-3-line"></i>
            </div>
          </div>
          <div class="input">
            <div class="input-item">
              <input
                formControlName="startTime"
                type="datetime-local"
                (change)="getovertimefromduration($event)"
                required
              />
              <label for="startTime" class="floating-label">Start Time</label>
              <i class="ri-time-line"></i>
            </div>
                <mat-error *ngIf="f.startTime.touched && f.startTime.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.startTime.errors.required">Start Time is required</mat-error>
                </mat-error>
          </div>
          <div class="input">
            <div class="input-item">
              <input
                formControlName="endTime"
                type="datetime-local"
                (change)="getovertimetoduration($event)"
                required
              />
              <label for="endTime" class="floating-label">End Time</label>
              <i class="ri-time-line"></i>
            </div>
              <mat-error *ngIf="f.endTime.touched && f.endTime.invalid" class="alert alert-danger">
                <mat-error *ngIf="f.endTime.errors.required">End Time is required</mat-error>
              </mat-error>
          </div>
          <div class="input">
            <div class="input-item">
              <label class="floating-label floating-label-special"> </label>
              <select formControlName="status">
                <option value="" disabled selected>Select Status</option>
                <option value="Save">Save</option>
                <option value="Submit">Submit</option>
                <option value="Withdraw">Withdraw</option>
              </select>
              <i class="ri-logout-box-r-line"></i>
            </div>
          </div>

          <div class="input">
            <div class="input-item">
              <input
                formControlName="totalHours"
                type="text"
                value="{{ this.totalHours }}"
              />
              <label for="totalHours" class="floating-label"
                >Total Hours</label
              >
              <i class="ri-timer-flash-line"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="closeModalOvertime"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            *ngIf="modeAction === 1"
            (click)="createOvertime()"
            [disabled]="!overtimeForm.valid"
          >
            Create
          </button>
          <button
            type="button"
            class="btn btn-primary"
            *ngIf="modeAction === 2"
            (click)="updateOvertime()"
            [disabled]="!overtimeForm.valid"
          >
            Update
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteOvertimeModal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content pl-5 pr-5">
      <form action="">
        <div class="modal-header">
          <h5 class="modal-title">Are you sure want to delete?</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="text-center">
            <p>{{ deleteConfirmationMsg }}?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="closeModalDeleteOvertime"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            *ngIf="selectedCheckboxData.length > 0"
            (click)="deleteOvertime()"
          >
            Yes Delete
          </button>
        </div>
      </form>
    </div>
  </div>
</div>