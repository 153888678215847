import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from "../../_services/auth/authentication.service";
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AvatarComponent } from './avatar/avatar.component';
import { UserService } from "../../_services/user/user.service";
import { LocalStorageService } from "../../_services/localStorage/local-storage.service";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
selector: 'app-dashboard',
templateUrl: './dashboard.component.html',
styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
isExpanded = false;
showSubMenu1 = false;
showSubMenu2 = false;
showSubMenu3 = false;
showSubMenu4 = false;
showSubMenu5 = false;
showSubMenu6 = false;
showSubMenu7 = false;
retrievedImage: any;
image: any;
tenantId: string;
currentUser: any;
adminFlag: boolean;
allowed: boolean;
menuFlag: string;
userDetails: any;

isAdmin : boolean;
isTenantCustomised : boolean;
isItUser : boolean;
isHelpdeskUser : boolean;
isHrAdmin: boolean;
isFinanceAdmin: boolean;
isFinanceUser: boolean;
isCrmAdmin: boolean;
isCrmUser: boolean;
isPmoUser: boolean;
isPmoAdmin: boolean;
isAdministrator: boolean;
isNDAAdmin: boolean;
isApprover: boolean;
isAdministration: boolean;
isHRMUser: boolean;


constructor(public loginService:AuthenticationService, private userService: UserService, public dialog: MatDialog, 
  private storageService: LocalStorageService, private router: Router,  private snackbar: MatSnackBar) { 
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    // check if roles list has 'Administrator' in it
    if(this.currentUser.roles.includes('Administrator')) { 
      this.allowed = true;
    } else {
      this.allowed = false;
    }
    // check tenant admin flag
    this.adminFlag = this.currentUser.admin;
  } 

ngOnInit(): void {
  this.getImage();
  this.tenantId = this.storageService.get('tenantId');
  this.menuFlag = "Home";
  this.userDetails = JSON.parse(this.storageService.get('currentUser'));

  // Below code is for IM Technologies' columns' customization for admin and normal user
  const customisedTenants = ['imtechnologies', 'pragamana']
  this.isTenantCustomised = customisedTenants.includes(this.tenantId)  ;
  this.isAdmin = this.userDetails.roles.includes('RoleITAdministrator');
  this.isItUser = this.userDetails.roles.includes('RoleITUser') ;
  this.isHelpdeskUser = this.userDetails.roles.includes('RoleHelpdeskUser');
  this.isHrAdmin = this.userDetails.roles.includes('RoleHrmAdministrator');
  this.isFinanceAdmin = this.userDetails.roles.includes('RoleFinanceAdministrator');
  this.isFinanceUser = this.userDetails.roles.includes('RoleFinanceUser');
  this.isCrmAdmin  = this.userDetails.roles.includes('RoleCrmAdministrator');
  this.isCrmUser = this.userDetails.roles.includes('RoleCrmUser');
  this.isPmoUser = this.userDetails.roles.includes('RolePmoUser');
  this.isPmoAdmin  = this.userDetails.roles.includes('RolePmoAdministrator');
  this.isAdministrator = this.userDetails.roles.includes('RoleAdministrator');
  this.isApprover = this.userDetails.roles.includes('RoleApprover');
  this.isNDAAdmin = this.userDetails.admin || this.userDetails.roles.includes('RoleNDATeam') ;
  this.isAdministration  = this.userDetails.roles.includes('RoleAdministrationUser');
  this.isHRMUser = this.userDetails.roles.includes('RoleHrmUser');
}

openDialog() {
  const dialogRef = this.dialog.open(AvatarComponent);
  
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
    this.getImage();
  });

}

getImage() {
  this.image = JSON.parse(this.storageService.get('userImage'));
  if (this.image !== null) {
    this.retrievedImage = 'data:image/jpeg;base64,' + this.image;
  } else {
    this.retrievedImage = './assets/user-icon-jpg-29.jpg';
  }
}

changeMenu(e) {
  let roles = this.currentUser.roles;
  switch(+e) {
    case 1:
      if(! this.isPermitted(1)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "Project Management";
      if(this.currentUser.admin || roles.includes("RolePmoAdministrator")){
        this.router.navigate([this.tenantId + '/dashboard/project/pmoDashboard']);
      } else {
        this.router.navigate([this.tenantId + '/dashboard']);
      }
      break;
    case 2:
      if(! this.isPermitted(2)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "CRM";
      this.router.navigate([this.tenantId + '/dashboard']);
      break;
    case 3:
      if(! this.isPermitted(3)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "HRM";
      if(this.currentUser.admin || roles.includes("RoleHrmAdministrator")){
        this.router.navigate([this.tenantId + '/dashboard/hr/hr-dashboard']);
      } else {
        this.router.navigate([this.tenantId + '/dashboard']);
      }
      break;
    case 4:
      if(! this.isPermitted(4)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "ITM";
      if(this.currentUser.admin || roles.includes("RoleITAdministrator")){
          this.router.navigate([this.tenantId + '/dashboard/itm/it-dashboard']);
      } else {
        this.router.navigate([this.tenantId + '/dashboard']);
      }
      break;
    case 5:
      if(! this.isPermitted(5)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "Adminstration";
      this.router.navigate([this.tenantId + '/dashboard']);
      break;
    case 6:
      if(! this.isPermitted(6)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "Home";
      this.router.navigate([this.tenantId + '/dashboard']);
      break;
    case 7:
      if(! this.isPermitted(7)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "My Approval";
      break;
    case 8:
      if(! this.isPermitted(8)){
        this.openNotAuthorizedSnackbar();
        break;
      }
      this.menuFlag = "Finance";
      this.router.navigate([this.tenantId + '/dashboard']);
      break;
  }
  
}

sidebarsmaller = false;
sidebarbigger = false; 
openCloseInnerSidebarsmaller() {
  this.sidebarsmaller = !this.sidebarsmaller;
  this.sidebarbigger  = !this.sidebarbigger
}

isPermitted(switchcase) {
  let roles = this.currentUser.roles;
  switch(switchcase) {
    case 1:  
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RolePmoAdministrator") || 
      roles.includes("RolePmoUser")){
          return true;
      }
      return false;
    case 2:
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RoleCrmAdministrator") || 
      roles.includes("RoleCrmUser")){
          return true;
      }
      return false;
    case 3:
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RoleHrmAdministrator") || roles.includes("RoleNDATeam") ||
      roles.includes("RoleHrmUser")){
          return true;
      }
      return false;
    case 4:
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RoleITAdministrator") || 
        roles.includes("RoleITUser") || roles.includes("RoleHelpdeskUser")){
          return true;
      }
      return false;
    case 5:
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RoleAdministrator") || roles.includes("RoleAdministrationUser")){
          return true;
      }
      return false;
    case 8:
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RoleFinanceAdministrator") || 
      roles.includes("RoleFinanceUser")){
          return true;
      }
      return false;
    case 6:
      // case for Home
      return true;
    case 7:
      if(this.currentUser.admin || roles.includes("Administrator") || roles.includes("RoleAdministrator") || roles.includes("RoleApprover")){
          return true;
      }
      return false;
    default:
      return true;
  }
}

openNotAuthorizedSnackbar() {
  this.snackbar.open('You are not authorized to perform this action', 'Close', {
    duration: 5000,
    verticalPosition: 'top',
  });
}

}
