import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { OpportunityService } from 'src/app/_services/opportunity/opportunity.service';
import { QuoteService } from 'src/app/_services/quote/quote.service';

@Component({
  selector: 'app-manpower-distribution',
  templateUrl: './manpower-distribution.component.html',
  styleUrls: ['./manpower-distribution.component.scss']
})
export class ManpowerDistributionComponent implements OnInit {
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  jsonData: any;
  dataSource_elmd: any;
  tenantId: string;
  totalHours_manpowerdistel: any;
  totalHourPercent_manpowerdistel: any;
  totalAvgFte_manpowerdistel: any;
  totalPeakFte_manpowerdistel: any;

  constructor(private quoteService: QuoteService ,private opportunityService: OpportunityService, private formBuilder: FormBuilder, private dataService: DataService, private router: Router, private storageService: LocalStorageService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getManpowerDistELSummaryDetails();
  }

  getManpowerDistELSummaryDetails() {
    this.quoteService.getManpowerDistELSummary(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      console.log("ManpowerDist EL Summary Response", response);
      console.log("ManpowerDist EL Summary Response", response.manpowerDistributions);
      this.dataSource_elmd = response.manpowerDistributions;

      this.totalHours_manpowerdistel = response.totalHours;
      this.totalHourPercent_manpowerdistel = response.totalHourPercent;
      this.totalAvgFte_manpowerdistel = response.totalAvgFte;
      this.totalPeakFte_manpowerdistel = response.totalPeakFte;
    });
    
  }
}
