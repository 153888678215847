import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { environment } from "../../../environments/environment";
import { Subject } from 'rxjs/Subject';
import { Project  } from 'src/app/_models/project.model';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends Subject<DataStateChangeEventArgs> {
  
  

  constructor(private http: HttpClient) {
 
 
    super();
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // Get all projects
  getProjects() {
    return this.http.get(`${environment.apiUrl}/projects`).pipe(retry(2), catchError(this.handleError));
  }

  // Get all sub-projects
  getSubProjects(parentId) {
    return this.http.get(`${environment.apiUrl}/projects?parentId=${parentId}`).pipe(retry(2), catchError(this.handleError));
  }

  public getProjectDetails() {
    return this.http
      .get<any>(`${environment.apiUrl}/projects`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError),
      );
  }
  
  // Get project details for mentioned id
  getProject(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projects/${id}`).pipe(retry(2), catchError(this.handleError));
  }

  getProjectForTask(id: number) {
    return this.http.get(`${environment.apiUrl}/projects/${id}`).pipe(map(result => {
      return result;
    }), catchError(this.handleError));
  }

  // Add project
  createProject(project: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/projects`, project).pipe(catchError(this.handleError));
  }

  // Update project details for mentioned id
  updateProject(id: number, value: any): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/projects/${id}`, value).pipe(catchError(this.handleError));
  }

  //Delete Contact by Id Delete
  deleteProject(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/projects/${id}`).pipe(catchError(this.handleError));
  }

   //Synfusion  CRUD Operation
   public execute(state: any): void {
    //console.log("Execute project funtion", state);
    console.log("Execute Return Statement", this.getAllProjects(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
    this.getAllProjects(state).subscribe(x => super.next(x as DataStateChangeEventArgs));
  }

  /** GET all data from the server */
  getAllProjects(state?: any): Observable<any[]> {
    console.log("getAll Project",this.http.get<Project[]>(`${environment.apiUrl}/projects`).pipe(retry(2), catchError(this.handleError)));
    return this.http.get<Project[]>(`${environment.apiUrl}/projects`).map((response: any) => ({
      result: state.take > 0 ? response.slice(0, state.take) : response,
      count: response.length
    } as any))
      .map((data: any) => data);
  }
  
  public getProjectById(id: number): Observable<any> {
    console.log("getProjectById in service:", id);
    return this.http.get<Project[]>(`${environment.apiUrl}/projects/${id}`).pipe(retry(2), catchError(this.handleError));
  }

  public updateProjectById(id: number, data: Object): Observable<any> {
    console.log("updateProjectById in service:", id);
    return this.http.put<any>(`${environment.apiUrl}/projects/${id}`,data).pipe(retry(2), catchError(this.handleError));
  }
  
  // Get project details for current user
  getProjectByUserId(userId: number, ignoreArchived: boolean): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projectsForResources?userId=${userId}&ignoreArchived=${ignoreArchived}`).pipe(retry(2), catchError(this.handleError));
  }

  // Get sub-project details for current user
  getSubProjectByUserId(userId: number, parentId: number, ignoreArchived: boolean): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/projectsForResources?userId=${userId}&parentId=${parentId}&ignoreArchived=${ignoreArchived}`).pipe(retry(2), catchError(this.handleError));
  }

  getPMOResourceAvailability(){
    return this.http.get(`${environment.apiUrl}/pmoResourceAvailability`).pipe(retry(2), catchError(this.handleError));
  }
}
