<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Product Structure</h3>
                        </div>
                         <div class="col-sm-6 d-flex justify-content-end gap-2">
                            <button type="button" class="btn btn-sm" (click)="exportProductInformation()">
                                <div><i class="ri-download-2-line"></i>Export</div>
                            </button>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#importList" class="btn btn-primary btn-sm">
                                <span><i class="ri-upload-2-line"></i>Import</span>
                            </button>
                        </div>
                        
                    </div>
                    <ag-grid-angular
                    style="height: calc(100vh - 120px) !important;"
                         class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        paginationPageSize=500>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>


<div class="modal fade" id="importList" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="form">
                <div class="modal-header">
                    <h5 class="modal-title">Import Product System</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="col-md-4" style="padding-left: 25%;">
                        <input type="file" (change)="onFileChanged($event)">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" id="closeImportModal" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" (click)="importProductStructure()" data-bs-dismiss="modal">Import</button>
                </div>
            </form>
        </div>
    </div>
</div>