import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, retry, map } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
//import { httpOptions } from "../auth/authentication.service";
import { QuoteParameterCostValue } from '../../_models/quote-parameter-cost-value';

@Injectable({
  providedIn: 'root'
})
export class CostService extends Subject<DataStateChangeEventArgs>  {

  constructor(private http: HttpClient) { super(); }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  //Synfusion  CRUD Operation
  public execute(state: any): void {
    //  console.log("Execute Opportunity funtion", state);
    //  console.log("Execute Return Staement", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
    this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs));
    //  console.log("Exeute Funtion", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
  }

  /** GET all data from the server */
  getAllData(state?: any): Observable<any[]> {
    //  console.log("GetAllData Opoprtunity",this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`).pipe(retry(2), catchError(this.handleError)));
    //return this.http.get<Opportunity[]>(`${environment.apiUrl}/opportunities`,httpOptions).pipe(retry(2), catchError(this.handleError));

    return this.http.get<CostService[]>(`${environment.apiUrl}/costcenterLevelRates`).pipe(map((response: any) => (<any>{
      result: state.take > 0 ? response.slice(0, state.take) : response,
      count: response.length
    })));

  }

  /** POST: add a new record  to the server */
  addRecord(state: DataSourceChangedEventArgs): Observable<CostService> {
    // you can apply empty string instead of state.data to get failure(error)
    //  console.log("Add record opportunity Call");
    return this.http.post<CostService>(`${environment.apiUrl}/opportunities`, state.data);
  }

  /** DELETE: delete the record from the server */
  deleteRecord(state: any): Observable<CostService> {
    const id = state.data[0].id;
    const url = `${environment.apiUrl}/opportunities/${id}`;
    return this.http.delete<CostService>(url);
  }

  /** PUT: update the record on the server */
  updateRecord(state: any): Observable<CostService> {

    //const id = state.data.id;
    console.log(state.data);
    return state.data;
    //  console.log("UpdateRecord", this.http.put<Opportunity>(`${environment.apiUrl}/opportunities/${id }`, state.data));
   // return this.http.put<CostService>(`${environment.apiUrl}/opportunities/${id}`, state.data);
  }


  // Add oppotunity
  createQuoteParameter(quoteParameter: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/quoteParameters`, quoteParameter).pipe(catchError(this.handleError));
  }

  public getQuoteParameter(): Observable<any> {
    return this.http.get<QuoteParameterCostValue[]>(`${environment.apiUrl}/quoteParameters`).pipe(retry(2), catchError(this.handleError));
  }

  public getDefaultCostCenterLevelRates(): Observable<any> {
    return this.http.get<QuoteParameterCostValue[]>(`${environment.apiUrl}/costcenterLevelRates/-1`).pipe(retry(2), catchError(this.handleError));
  }

  public getDefaultSoftwareRates(): Observable<any> {
    return this.http.get<QuoteParameterCostValue[]>(`${environment.apiUrl}/softwareRates/-1`).pipe(retry(2), catchError(this.handleError));
  }

  addCostCenterLevelRates(addcclrdata: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/opportunities/-1/quotes/-1/appendCostcenterLevelRates`, addcclrdata).pipe(catchError(this.handleError));
  }

  /** EDIT: edit the record from the server */
  editCostCenterLevelRates(srid: any, editsrdata: Object): Observable<CostService> {
    
    const url = `${environment.apiUrl}/opportunities/-1/quotes/-1/costcenterLevelRates/${srid}`;
    return this.http.put<CostService>(url, editsrdata);
  }

  addSoftwareRates(addsrdata: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/opportunities/-1/quotes/-1/appendSoftwareRates`, addsrdata).pipe(catchError(this.handleError));
  }

   /** EDIT: edit the record from the server */
   editSoftwareRates(srid: any, editsrdata: Object): Observable<CostService> {
    
    const url = `${environment.apiUrl}/opportunities/-1/quotes/-1/softwareRates/${srid}`;
    return this.http.put<CostService>(url, editsrdata);
  }



  /** DELETE: delete the record from the server */
  deleteSoftwareRates(srid: any): Observable<CostService> {
    
    const url = `${environment.apiUrl}/opportunities/-1/quotes/-1/softwareRates/${srid}`;
    return this.http.delete<CostService>(url);
  }

  /** DELETE: delete the record from the server */
  deleteCclr(cclrid: any): Observable<CostService> {
    
    const url = `${environment.apiUrl}/opportunities/-1/quotes/-1/costcenterLevelRates/${cclrid}`;
    return this.http.delete<CostService>(url);
  }

  // opportunity - cost center level rates
  getCostCenterLevelRatesOpportunity(quoteId:number): Observable<any> {
    return this.http.get<QuoteParameterCostValue[]>(`${environment.apiUrl}/costcenterLevelRates/${quoteId}`).pipe(retry(2), catchError(this.handleError));
  }
  addCostCenterLevelRatesOpportunity(addcclrdata: Object, appId:number, quoteId:number): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/opportunities/${appId}/quotes/${quoteId}/appendCostcenterLevelRates`, addcclrdata).pipe(catchError(this.handleError));
  }
  deleteCostCenterLevelRatesOpportunityu(cclrid: any, appId:number, quoteId:number): Observable<CostService> {
    const url = `${environment.apiUrl}/opportunities/${appId}/quotes/${quoteId}/costcenterLevelRates/${cclrid}`;
    return this.http.delete<CostService>(url);
  }
  // opportunity - software rates
  getSoftwareRatesOpportuniry(quoteId:number): Observable<any> {
    return this.http.get<QuoteParameterCostValue[]>(`${environment.apiUrl}/softwareRates/${quoteId}`).pipe(retry(2), catchError(this.handleError));
  }
  addSoftwareRatesOpportunity(addsrdata: Object, appId: number, quoteId:number): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/opportunities/${appId}/quotes/${quoteId}/appendSoftwareRates`, addsrdata).pipe(catchError(this.handleError));
  }
  deleteSoftwareRatesOpportunity(srid: any, appId:number, quoteId:number): Observable<CostService> {
    const url = `${environment.apiUrl}/opportunities/${appId}/quotes/${quoteId}/softwareRates/${srid}`;
    return this.http.delete<CostService>(url);
  }

}
