<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
             <section class="dashboard-items">
                <div class="canvas-item">
                   <div class="canvas-header row justify-content-between align-items-center">
                       <div class="title col-sm-6">
                           <h3>Leave Profile </h3>
                       </div>
                   </div>
                   <div class="container">
                       <div class="row">
                           <div class="col-sm-3">
                               <div class="box">
                                   <button type="button" title="my leave" class="btn btn-sm" (click)="myLeave()">
                                       <i class="ri-suitcase-3-fill"></i>
                                       <div>My Leave</div>
                                   </button>
                               </div>
                           </div>
                           <div class="col-sm-3">
                               <div class="box">
                                   <button type="button" title="Holiday list" class="btn btn-sm" (click)="holidayList()">
                                       <i class="ri-notification-fill"></i>
                                       <div>Holiday List</div>
                                   </button>
                               </div>
                           </div>
                       </div>
                       <div *ngIf="this.isHRAdmin">
                            <div class="canvas-header row justify-content-between align-items-center">
                                <div class="title col-sm-6">
                                    <h3>Leave for Hr</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="box">
                                        <button type="button" title="Leave Book Detail" class="btn btn-sm" (click)="leaveDetail()" >
                                            <i class="ri-user-line"></i>
                                            <div>LeaveBook Detail</div>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="box">
                                        <button type="button" title="Leave Balance Report" class="btn btn-sm" (click)="leaveBalanceReport()">
                                            <i class="ri-chat-download-fill"></i>
                                            <div>Leave Balance Report</div>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="box">
                                        <button type="button" title="Approval Page" class="btn btn-sm" (click)="leaveBook()">
                                            <i class="ri-user-line"></i>
                                            <div>Leave Book</div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="box">
                                        <button type="button" title="Leave Adjustment" class="btn btn-sm" (click)="leaveAdjustment()">
                                            <i class="ri-user-line"></i>
                                            <div>Leave Adjustment</div>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                 <div class="box">
                                     <button type="button" title="Leave Balance Logs" class="btn btn-sm" (click)="leaveBalanceLogs()">
                                         <i class="ri-user-line"></i>
                                         <div>Leave Balance Logs</div>
                                     </button>
                                 </div>
                            </div>
                       </div>
                       
                       
                       
                       </div>
                   </div>
                </div>
            </section>
        </section>
    </article>
</main>