<div class="row">
  <!-- <div class="col-sm-4 mb-3 col-md-6 col-lg-4 col-xl-3"> -->
    <div class="col-sm-4">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">Birthday</h3>
        <!-- <h2>Birthday</h2> -->
      </div>
      <div class="card-body image-content">
        <div class="container">
          <div *ngIf="notifications && notifications['birthday'] && notifications['birthday']?.length > 0 ">
            <span *ngFor="let item of notifications['birthday']"
              style="margin-left: 10px;font-weight: bold;margin-top: 10px;">

              <i class="ri-cake-2-fill"></i> {{item.employeeName}} Birthday on {{moment(item.date).format('DD')}}
              <br>
            </span>
          </div>
          <div
            *ngIf="notifications && notifications['marriageAnniversary'] && notifications['marriageAnniversary']?.length > 0 ">
            <span *ngFor="let item of notifications['marriageAnniversary']"
              style="margin-left: 10px;font-weight: bold;margin-top: 10px;">
              <i class="ri-cake-2-fill"></i> {{item.employeeName}} Marriage Anniversary on
              {{moment(item.date).format('DD')}}
              <br>
            </span>
          </div>

          <p class="text-center p-2" *ngIf="isNoData">No birthday
            today</p>
          
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-4 ">
    <div class="card h-100">
      <div class="card-header">
        <h3 class="h5">Work Anniversary</h3>
      </div>
      <div class="card-body image-content">
        <div class="container">
         <div class="">
          <div 
            *ngIf="notifications && notifications['workAnniversary'] && notifications['workAnniversary']?.length > 0 ">
            <span *ngFor="let item of notifications['workAnniversary']"
              style="margin-left: 10px;font-weight: bold;margin-top: 10px;">
              <i class="ri-cake-2-fill"></i> {{item.employeeName}} Work Anniversary on
              {{moment(item.date).format('DD')}}
              <br>
            </span>
          </div>
          <div class="box">
            <p class="text-center p-2" *ngIf="isNoData">No Work Anniversary</p>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-4 ">
    <div class="card h-100 dashboard-leave-counter">
      <div class="card-header">
        <h2>My Leave Status</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="row grey-boxes">
            <div class="box">
              <p class="table-box-heading">Leave</p>
              <i class="ri-calendar-check-line" style="color:purple"></i>
              <p class="text-left">Available: {{earnleave}}</p>
            </div>
            <div class="box">
              <p class="table-box-heading">Compoff</p>
                <i class="ri-calendar-check-line" style="color:green"></i>
                <p class="text-left">Available: {{compensatoryleave}}</p>
            </div>
          </div>
          <hr style="margin-left: 5px;margin-bottom: 5px; width: 98%;">
          <div>
            <span style="margin-left: 5px;font-weight: bold;margin-top: 10px;">Leave Notifications</span>
            <div *ngIf="leaveData.length > 0 ">
              <span *ngFor="let item of leaveData"
                style="margin-left: 10px;margin-top: 10px;">
                <i class="ri-file-list-3-line"></i> "{{item.applicationTitle}}" applied leave for {{moment(item.startDate).format('DD-MM-YYYY')}} is {{item.status}}.
                <br>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body d-none">
        <div class="box">
          <p>No upcoming today</p>
          <img src="assets/svg/upcomingEvent.svg" alt="">
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-8 mt-3">
    <div class="card h-100">
      <div class="card-header">
        <h2>Calendar</h2>
      </div>
      <div class="card-body" style="padding: 2%;">
        <ejs-schedule #scheduleObj height="550px" [eventSettings]='eventSettings' [currentView]='currentView'>
          <e-views>
            <e-view option="Month" [showWeekend]="showWeekend"></e-view>
            <e-view option="Week"></e-view>
            <e-view option="Day"></e-view>
            </e-views>
        </ejs-schedule>
      </div>
    </div>
  </div>
  <!-- <div class="col-sm-4 mt-3">
    <div class="card h-100">
      <div class="card-header">
        <h2>Upcoming events</h2>
      </div>
      <div class="card-body">
        <div class="box">  
        </div>
      </div>
    </div>
  </div> -->
  <div class="col-sm-4 mt-3">
    <div class="card h-100">
      <div class="card-header">
        <h2>My Tasks</h2>
      </div>
      <div class="card-body">
        <div class="box">
       
          
        </div>
      </div>
    </div>
  </div>
</div>

<main class="main-dashboard d-none">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">
          <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
              <h3>Dashboard</h3>
            </div>
            <div class="col-sm-6" style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              ">
              <div>
                <button type="button" class="btn btn-icon" (click)="onAdd()" *ngIf="dashboardIndexNum === 6">
                  <i class="ri-add-line"></i>
                </button>
              </div>
            </div>
          </div>
          <ejs-dashboardlayout id="predefine_dashboard" #predefine_dashboard columns="6" [panels]="panelsData"
            [cellSpacing]="cellSpacing" [allowResizing]="true">
          </ejs-dashboardlayout>
        </div>
      </section>
    </section>
  </article>
</main>