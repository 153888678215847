import { ResourceAllocationComponent } from './resource-utilization/resource-allocation/resource-allocation.component';
import { CapacityPlanningComponent } from './project/capacity-planning/capacity-planning.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RoleGuardService } from "../../_helpers/role-guard.service";
import { HrComponent } from './hr/hr.component';
import { GoogledriveComponent } from "./googledrive/googledrive.component";
import { IndexComponent } from './index/index.component';
import { ApprovalComponent } from './approval/approval.component';
import { AuthGuardService } from 'src/app/_helpers/auth-guard.service';
import { TimeSheetComponent } from './hr/timesheet/time-sheet/time-sheet.component';
import { HelpdeskComponent } from './itm/helpdesk/helpdesk.component';
import { AttendanceComponent } from './hr/attendance/attendance.component';
import { OvertimeComponent } from './hr/overtime/overtime.component';
import { EmployeeComponent } from './hr/employee/employee.component';
import { HelpdeskapplicationComponent } from './itm/helpdeskapplication/helpdeskapplication.component';
import { LeaveFirstPageComponent } from './hr/leaves/leave-first-page/leave-first-page.component';
import { LeaveApplicationComponent } from './hr/leaves/leave-application/leave-application.component';
import { LeaveBookComponent } from './hr/leaves/leaveBook/leaveBook.component';
import { HolidayListComponent } from './hr/leaves/holiday-list/holiday-list.component';
import { LeaveAdjustmentsComponent } from './hr/leaves/leave-adjustments/leave-adjustments.component';
import { LeaveBalanceLogsComponent } from './hr/leaves/leave-balance-logs/leave-balance-logs.component';
import { LeaveBalanceReportComponent } from './hr/leaves/leave-balance-report/leave-balance-report.component';
import { LeaveProfileComponent } from './hr/leaves/leave-profile/leave-profile.component';
import { LeaveBookDetailComponent } from './hr/leaves/leave-book-detail/leave-book-detail.component';
import { LeaveRevokeComponent } from './hr/leaves/leave-revoke/leave-revoke.component';
import { AddAttendanceComponent } from './hr/add-attendance/add-attendance.component';
import { ShiftScheduleComponent } from './hr/shift-schedule/shift-schedule.component';
import { PayslipComponent } from './finance/payslip/payslip.component';
import { TaxComponent } from './finance/tax/tax.component';
import { TaxCtcComponent } from './finance/tax-ctc/tax-ctc.component';
import { TaxDeductionsTwoComponent } from './finance/tax-deductions-two/tax-deductions-two.component';
import { SalaryDistribustionComponent } from './finance/salary-distribustion/salary-distribustion.component';
import { AttendanceDetailComponent } from './hr/attendance-detail/attendance-detail.component';
import { TaxCtcFormComponent } from './finance/tax-ctc-form/tax-ctc-form.component';
import { TaxmetaFormComponent } from './finance/taxmeta-form/taxmeta-form.component';
import { SalaryDistributionFormComponent } from './finance/salary-distribution-form/salary-distribution-form.component';
import { SalarySheetComponent } from './finance/salary-sheet/salary-sheet.component';
import { HrDashboardComponent } from './hr/hr-dashboard/hr-dashboard.component';
import { ItDashboardComponent } from './itm/it-dashboard/it-dashboard.component';
import { FinanceComponent } from './finance/finance.component';
import { TaxDeductionComponent } from './finance/tax-deduction/tax-deduction.component';
import { JobApplicationComponent } from './hr/hiring-process-profile/job-application/job-application.component';
import { JobDescriptionComponent } from './hr/hiring-process-profile/job-description/job-description.component';
import { JobPostComponent } from './hr/hiring-process-profile/job-post/job-post.component';
import { JobApplicantDetailsComponent } from './hr/hiring-process-profile/job-applicant-details/job-applicant-details.component';
import { JobApplicationShortlistedComponent } from './hr/hiring-process-profile/job-application-shortlisted/job-application-shortlisted.component';
import { JobApplicationInterviewComponent } from './hr/hiring-process-profile/job-application-interview/job-application-interview.component';
import { JobApplicationOfferedComponent } from './hr/hiring-process-profile/job-application-offered/job-application-offered.component';
import { JobApplicationApprovedComponent } from './hr/hiring-process-profile/job-application-approved/job-application-approved.component';
import { OnboardingComponent } from './hr/onboarding-component/onboarding/onboarding.component';
import { OnboardingNdaComponent } from './hr/onboarding-component/onboarding-nda/onboarding-nda.component';
import { PMODashboard } from './project/pmoDashboard/pmoDashboard.component';
import { ProjectTaskComponent } from './hr/timesheet/project-task/project-task.component';
import { ChangeRequestComponent } from './project/change-request/change-request.component';
import { UsersComponent } from './adminstration/users/users.component';
import { ProductStructureComponent } from './adminstration/product-structure/product-structure.component';
import { NotificationComponent } from './adminstration/notification/notification.component';
import { PerformanceTemplatesComponent } from './adminstration/performance-templates/performance-templates.component';
import { GlobalSettingComponent } from './adminstration/global-setting/global-setting.component';
import { OnsiteEmployeeComponent } from './adminstration/onsite-employee/onsite-employee.component';
import { ExitProcessComponent } from './hr/exit-formalities/exit-process/exit-process.component';
import { ExitResignationFormComponent } from './hr/exit-formalities/exit-resignation-form/exit-resignation-form.component';
import { ExitFinanceComponent } from './hr/exit-formalities/exit-finance/exit-finance.component';
import { ExitHritComponent } from './hr/exit-formalities/exit-hrit/exit-hrit.component';
import { ExitEmployeeFormComponent } from './hr/exit-formalities/exit-employee-form/exit-employee-form.component';
import { ExitInterviewFormComponent } from './hr/exit-formalities/exit-interview-form/exit-interview-form.component';
import { ExitFormComponent } from './hr/exit-formalities/exit-form/exit-form.component';
import { TimesheetReportComponent } from './hr/timesheet/timesheet-report/timesheet-report.component';
import { TimesheetNoPunchComponent } from './hr/timesheet/timesheet-no-punch/timesheet-no-punch.component';
import { TimesheetGraphComponent } from './hr/timesheet/timesheet-graph/timesheet-graph.component';

const routes: Routes = [{
  path: '',
  component: DashboardComponent,
  children: [
    {
      path: 'home',
      component: HomeComponent, canActivate: [AuthGuardService]
    },
    {
      path: 'index',
      component: IndexComponent, canActivate: [AuthGuardService]
    },
    // {
    //     path: 'accounts',
    //     component: AccountsComponent, 
    //     canActivate: [RoleGuardService], data: { roles: ['RoleSalesUser', 'RoleAllReader'] }
    // },
    // {
    //     path: 'contacts',
    //     component: ContactsComponent, 
    //     canActivate: [RoleGuardService], data: { roles: ['RoleSalesUser', 'RoleAllReader'] }
    // },
    {
      path: 'opportunities',
      loadChildren: () =>
        import('../dashboard/opportunities/opportunities.module').then(m => m.OpportunitiesModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleSalesUser', 'RoleAllReader', 'RoleOpportunityUser'] }
    },
    {
      path: 'adminstration',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/users',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: UsersComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/product-structure',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: ProductStructureComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/notification',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: NotificationComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/performance-template',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: PerformanceTemplatesComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/global-setting',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: GlobalSettingComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/global-setting',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: GlobalSettingComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'adminstration/onsite-employee',
      loadChildren: () =>
        import('../dashboard/adminstration/adminstration.module').then(m => m.AdminstrationModule),
        component: OnsiteEmployeeComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleAdministrationUser'] }
    },
    {
      path: 'hr/timesheet/time-sheet',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: TimeSheetComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser', 'RoleTimesheetUser'] }
    },
    {
      path: 'hr/timesheet/timesheet-report',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: TimesheetReportComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator','RoleReportsExtractor','RolePmoManager' ] }
    },
    {
    path: 'hr/timesheet/timesheet-no-punch',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: TimesheetNoPunchComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator','RoleReportsExtractor','RolePmoManager' ] }
    },
    {
      path: 'hr/timesheet/timesheet-graph',
        loadChildren: () =>
          import('../dashboard/hr/hr.module').then(m => m.HrModule),
        component: TimesheetGraphComponent,
        canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator','RoleReportsExtractor' ] }
      },
    {
      path: 'hr/timesheet/project-task',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ProjectTaskComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser', 'RoleTimesheetUser'] }
    },
    {
      path: 'hr/hr-dashboard',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: HrDashboardComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/attendance-detail',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: AttendanceDetailComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/add-attendance',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: AddAttendanceComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    // {
    //   path: 'hr/shift-schedule',
    //   loadChildren: () =>
    //     import('../dashboard/hr/hr.module').then(m => m.HrModule),
    //   component: ShiftScheduleComponent,
    //   canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RolePmoManager'] }
    // },
    {
      path: 'hr/attendance',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: AttendanceComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/leaves/holiday_list',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: HolidayListComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/leaves/leave-application',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveApplicationComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/leaves/leave-first-page',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveFirstPageComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/leaves/leaveBook',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveBookComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/leaves/leave-adjustments',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveAdjustmentsComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/leaves/leave-book-detail',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveBookDetailComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/leaves/leave-revoke',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveRevokeComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/leaves/leave-balance-report',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveBalanceReportComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/leaves/leave-balance-logs',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveBalanceLogsComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/leaves/leave-profile',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveProfileComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator','RoleHrmUser'] }
    },
    {
      path: 'hr/onboarding-component/onboarding',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: OnboardingComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleNDATeam'] }
    },
    {
      path: 'hr/onboarding-component/onboarding-nda',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: OnboardingNdaComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleNDATeam'] }
    },
    {
      path: 'hr/leaveBook',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: LeaveBookComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-description',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobDescriptionComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-application',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobApplicationComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-application-shortlisted',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobApplicationShortlistedComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-application-interview',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobApplicationInterviewComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-application-offered',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobApplicationOfferedComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-application-approved',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobApplicationApprovedComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-post',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobPostComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/hiring-process-profile/job-applicant-details',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: JobApplicantDetailsComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator'] }
    },
    {
      path: 'hr/overtime',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: OvertimeComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser', 'RoleOvertimeUser'] }
    },
    {
      path: 'hr/employee',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: EmployeeComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleEmployeeUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-process',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitProcessComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-resignation-form',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitResignationFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-form',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-finance',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitFinanceComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-hrit',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitHritComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-employee-form',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitEmployeeFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'hr/exit-formalities/exit-interview-form',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: ExitInterviewFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'finance/payslip',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: PayslipComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator','RoleFinanceUser'] }
    },
    {
      path: 'finance/tax',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: TaxComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/taxmeta-form',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: TaxmetaFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/tax-ctc',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: TaxCtcComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/tax-ctc-form',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: TaxCtcFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/salary-sheet',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: SalarySheetComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/tax-deduction',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: TaxDeductionComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/tax-deductions-two',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: TaxDeductionsTwoComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/salary-distribustion',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: SalaryDistribustionComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'finance/salary-distribution-form',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: SalaryDistributionFormComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleFinanceAdministrator'] }
    },
    {
      path: 'hr',
      loadChildren: () =>
        import('../dashboard/hr/hr.module').then(m => m.HrModule),
      component: HrComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleHrmAdministrator'] }
    },
    {
      path: 'finance',
      loadChildren: () =>
        import('../dashboard/finance/finance.module').then(m => m.FinanceModule),
      component: FinanceComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleFinanceAdministrator'] }
    },
    {
      path: 'crm',
      loadChildren: () =>
        import('../dashboard/crm/crm.module').then(m => m.CrmModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader'] }
    },
    {
      path: 'itm/helpdesk',
      loadChildren: () =>
        import('../dashboard/itm/itm.module').then(m => m.ItmModule),
      component: HelpdeskComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleITAdministrator', 'RoleITUser','RoleHelpdeskUser'] }
    },
    {
      path: 'itm/helpdeskapplication',
      loadChildren: () =>
        import('../dashboard/itm/itm.module').then(m => m.ItmModule),
      component: HelpdeskapplicationComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleITAdministrator', 'RoleITUser','RoleHelpdeskUser'] }
    },
    {
      path: 'itm/it-dashboard',
      loadChildren: () =>
        import('../dashboard/itm/itm.module').then(m => m.ItmModule),
      component: ItDashboardComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleHrmAdministrator', 'RoleHrmUser'] }
    },
    {
      path: 'itm',
      loadChildren: () =>
        import('../dashboard/itm/itm.module').then(m => m.ItmModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleITAdministrator', 'RoleITUser'] }
    },
    {
      path: 'profile',
      loadChildren: () =>
        import('../dashboard/profile/profile.module').then(m => m.ProfileModule),
      // canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader'] }
      // component: ProfileComponent,
    },
    {
      path: 'approval',
      loadChildren: () =>
        import('../dashboard/approval/approval.module').then(m => m.ApprovalModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader', 'RoleApprover'] }
    },
    {
      path: 'project/pmoDashboard',
      loadChildren: () =>
        import('../dashboard/project/project.module').then(m => m.ProjectModule),
        component: PMODashboard,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RolePmoAdministrator'] }
    },
    {
      path: 'project',
      loadChildren: () =>
        import('../dashboard/project/project.module').then(m => m.ProjectModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RolePmoAdministrator','RolePmoUser'] }
    },
    {
      path: 'project/capacity-planning',
      loadChildren: () =>
        import('../dashboard/project/project.module').then(m => m.ProjectModule),
        component: CapacityPlanningComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RolePmoAdministrator'] }
    },
    {
      path: 'project/changerequest',
      loadChildren: () =>
        import('../dashboard/project/project.module').then(m => m.ProjectModule),
        component: ChangeRequestComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RolePmoAdministrator'] }
    },
    {
      path: 'resource-utilization/resource-allocation',
      loadChildren: () =>
        import('../dashboard/project/project.module').then(m => m.ProjectModule),
        component: ResourceAllocationComponent,
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader','RoleResourceAllocationUser'] }
    },
    
    {
      path: 'resource-utilization',
      loadChildren: () =>
        import('../dashboard/resource-utilization/resource-utilization.module').then(m => m.ResourceUtilizationModule),
      canActivate: [RoleGuardService], data: { roles: ['RoleAdministrator', 'RoleAllReader'] }
    },
    {
      path: 'reset-password',
      component: ResetPasswordComponent,
    },
    {
      path: 'guidelines',
      component: HomeComponent,
      canActivate: [AuthGuardService, RoleGuardService], data: { roles: ['RoleKnowledgeAdministrator', 'RoleAllReader'] }
    },


    {
      path: '',
      redirectTo: 'index',
      pathMatch: 'full',
    },
    {
      path: 'googleDrive',
      component: GoogledriveComponent,
      canActivate: [AuthGuardService, RoleGuardService], data: { roles: ['RoleSalesUser', 'RoleAllReader'] }
    },

  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
