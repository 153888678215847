import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpBackend } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, retry } from "rxjs/operators";
import { Subject } from 'rxjs/Subject';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { map } from 'rxjs/operators';

import { environment } from "../../../environments/environment";
import { UserDetails } from '../../_models/user.model';
import { LocalStorageService } from "../../_services/localStorage/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UserService extends Subject<DataStateChangeEventArgs> {
  // getData() {
  //   throw new Error('Method not implemented.');
  // }
  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private storageService: LocalStorageService) {
    super();
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Error while fetching details!';
    if (error.error instanceof ErrorEvent) {
      // Client side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  // Get list of all user details
  getUserDetailsList() {
    return this.http.get(`${environment.apiUrl}/users`).pipe(retry(2), catchError(this.handleError));
  }

  // Get list of all user details
  getArchivedUserDetailsList() {
    return this.http.get(`${environment.apiUrl}/users?archived=true`).pipe(retry(2), catchError(this.handleError));
  }
  

  // // Get User details for mentioned id
  // getUser(id: number): Observable<Object> {
  //   return this.http.get(`${environment.apiUrl}/users/${id}`).pipe(retry(2), catchError(this.handleError));
  // }

  // Get User details for mentioned id
  getUser(id: number) {
    return this.http.get<any>(`${environment.apiUrl}/users/${id}`).pipe(retry(2), catchError(this.handleError));
  }

  // Add User
  createUser(user: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/users`, user).pipe(catchError(this.handleError));
  }

  // Register User
  registerUser(user: Object, tenantId: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'tenantId': tenantId,
      }),
    };
    console.log("In registerUser method, user: ", user);
    return this.http.post(`${environment.apiUrl}/register`, user, header);
  }



  // Update user for mentioned id 
  updateUser(id: number, value: any): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/users/${id}`, value).pipe(catchError(this.handleError));
  }

  // Delete user for mentioned id
  deleteUser(id: number) {
    return this.http.delete(`${environment.apiUrl}/users/${id}`).pipe(catchError(this.handleError));
  }

  //Synfusion  CRUD Operation
  public execute(state: any): void {
    // console.log("Execute Opportunity funtion", state);
    // console.log("Execute Return Staement", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
    this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs));
  }

  /** GET all data from the server */
  getAllData(state?: any): Observable<any[]> {
    // console.log("GetAllData Users",this.http.get<UserDetails[]>(`${environment.apiUrl}/users`).pipe(retry(2), catchError(this.handleError)));
    return this.http.get<UserDetails[]>(`${environment.apiUrl}/users`).pipe(map((response: any) => (<any>{
      result: state.take > 0 ? response.slice(state.skip, state.skip + state.take) : response,
      count: response.length
    })));
  }

  /** POST: add a new record  to the server */
  addRecord(state: DataSourceChangedEventArgs): Observable<UserDetails> {
    // you can apply empty string instead of state.data to get failure(error)
    // console.log("Add record users Call");
    return this.http.post<UserDetails>(`${environment.apiUrl}/users`, state.data);
  }

  /** DELETE: delete the record from the server */
  deleteRecord(state: any): Observable<UserDetails> {
    const id = state.data[0].id;
    const url = `${environment.apiUrl}/users/${id}`;
    return this.http.delete<UserDetails>(url);
  }

  /** PUT: update the record on the server */
  updateRecord(state: any): Observable<any> {
    const id = state.data.id;
    console.log("UpdateRecord", this.http.put<UserDetails>(`${environment.apiUrl}/users/${id}`, state.data));
    return this.http.put<UserDetails>(`${environment.apiUrl}/users/${id}`, state.data);
  }

  // Resetpassword 
  passwordReset(data: Object): Observable<Object> {
    let currentUser = JSON.parse(this.storageService.get('currentUser'));
    const header = {
      headers: new HttpHeaders({
        'emailId': currentUser.emailId,
      }),
    };
    console.log("In PasswordRest method, data: ", data);
    return this.http.post(`${environment.apiUrl}/resetPassword`, data, header).pipe(catchError(this.handleError));
  }

  passwordResetByAdmin(data: Object): Observable<Object> {
    let currentUser = JSON.parse(this.storageService.get('currentUser'));
    const header = {
      headers: new HttpHeaders({
        'emailId': currentUser.emailId,
      }),
    };
    // data - userId, oldPassword, newPassword
    return this.http.post(`${environment.apiUrl}/resetPasswordByAdmin`, data, header).pipe(catchError(this.handleError));
  }

//User Onboarding API
  userCreation(user: Object) : Observable<Object> {
    return this.http.post(`${environment.apiUrl}/userCreations`, user).pipe(catchError(this.handleError));
  }

  getUserCreation(requesterId: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userCreations/?requesterId=${requesterId}`).pipe(map((result) => { return result; }), catchError(this.handleError));
  }

  getAllUserCreation(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userCreations`).pipe(map((result) => { return result; }), catchError(this.handleError));
  }

  getUserCreationById (id: number) {
    return this.http.get<any>(`${environment.apiUrl}/userCreations/${id}`).pipe(map((result) => { return result; }), catchError(this.handleError));
  }

  updateUserCreation(id: number, user: any): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/userCreations/${id}`, user).pipe(catchError(this.handleError));
  }

  deleteUserCreation(id: number) {
    return this.http.delete(`${environment.apiUrl}/userCreations/${id}`).pipe(catchError(this.handleError));
  }

  //work experience
  addWorkExperience(workData: Object) : Observable<Object> {
    return this.http.post(`${environment.apiUrl}/userExperience`, workData).pipe(catchError(this.handleError));
  }

  updateWorkExperience(workData: Object, workExperienceId: string) : Observable<Object> {
    return this.http.put(`${environment.apiUrl}/userExperience/${workExperienceId}`, workData).pipe(catchError(this.handleError));
  }

  deleteWorkExperience(workExperienceId: string) : Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/userExperience/${workExperienceId}`).pipe(catchError(this.handleError));
  }

  getWorkExperience(userId: Object) : Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userExperience/?userId=${userId}`).pipe(catchError(this.handleError));
  }

  //User SKills
  addUserSkill(skillData: Object) : Observable<Object> {
    return this.http.post(`${environment.apiUrl}/userSkills`, skillData).pipe(catchError(this.handleError));
  }

  updateUserSkill(skillData: Object, skillId: string) : Observable<Object> {
    return this.http.put(`${environment.apiUrl}/userSkills/${skillId}`, skillData).pipe(catchError(this.handleError));
  }

  deleteUserSkill(skillId: string) : Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/userSkills/${skillId}`).pipe(catchError(this.handleError));
  }

  getUserSkill(userId: Object) : Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userSkills/?userId=${userId}`).pipe(catchError(this.handleError));
  }

  //User Emergency Contacts
  getEmergencyContacts(userId: number) : Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userEmergencyContacts/?userId=${userId}`).pipe(catchError(this.handleError));
  }

  addEmergencyContacts(userEmergencyContactsData: Object) : Observable<Object> {
    return this.http.post(`${environment.apiUrl}/userEmergencyContacts`, userEmergencyContactsData).pipe(catchError(this.handleError));
  }

  updateEmergencyContacts(userEmergencyContactsId: String, userEmergencyContactsData: Object) : Observable<Object> {
    return this.http.put(`${environment.apiUrl}/userEmergencyContacts/${userEmergencyContactsId}`, userEmergencyContactsData).pipe(catchError(this.handleError));
  }

  deleteEmergencyContacts(userEmergencyContactsId: string) : Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/userEmergencyContacts/${userEmergencyContactsId}`).pipe(catchError(this.handleError));
  }

  //user Dependents
  addUserDependents(userDependentsData: Object) : Observable<Object> {
    return this.http.post(`${environment.apiUrl}/userDependents`, userDependentsData).pipe(catchError(this.handleError));
  }

  updateUserDependents(userDependentsId: String, userDependentsData: Object) : Observable<Object> {
    return this.http.put(`${environment.apiUrl}/userDependents/${userDependentsId}`, userDependentsData).pipe(catchError(this.handleError));
  }

  deleteUserDependents(userDependentsId: string) : Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/userDependents/${userDependentsId}`).pipe(catchError(this.handleError));
  }

  getUserDependents(userId: number) : Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userDependents/?userId=${userId}`).pipe(catchError(this.handleError));
  }

  notifyLicense(license: string, userName: string): Observable<Object>{
    return this.http.get(`${environment.apiUrl}/notifyLicense?license=${license}&user=${userName}`).pipe(catchError(this.handleError));
  }

  emailLink(emailId: string): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/standardAwarenessTrainingExamEmail?emailId=${emailId}`,null).pipe(catchError(this.handleError));
  }


}
