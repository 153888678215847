import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { TaxdeductionService } from '../../../../_services/taxdeduction/taxdeduction.service';
import { AllocationService } from '../../../../_services/resource-allocation/allocation.service';

@Component({
  selector: 'app-tax-deduction',
  templateUrl: './tax-deduction.component.html',
  styleUrls: ['./tax-deduction.component.scss']
})
export class TaxDeductionComponent implements OnInit {
  tenantId: string;
  sonData: any;
  // row data and column definitions 
  taxdeductionform : FormGroup;
  rowData: any;
  // form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  userDropdownList = [];
  getUserName: any;
  currenttaxdeductionId: any;

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private router: Router,
    private allocationService: AllocationService,
    private taxdeductionService :TaxdeductionService,
    private route: ActivatedRoute,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.currenttaxdeductionId = params['id'];
  });
    this.taxdeductionform = this.formBuilder.group({
      id: new FormControl(''),
      financialYear: new FormControl(''),
      employeeId: new FormControl(''),
      employeeName: new FormControl(''),
      metro: new FormControl(''),
      taxExemptionName: new FormControl(''),
      taxExemptionValue: new FormControl(''),
      percent:  new FormControl(''),
      basicSalaryDA: new FormControl(''),
      hra: new FormControl(''),
      lta: new FormControl(''),
      gratuityPayment: new FormControl(''),
      leaveEncashmentPayment: new FormControl(''),
      childrenEducationAllowance: new FormControl(''),
      otherTaxFreeAllowanceOrReimbursements: new FormControl(''),
      sharedDeduction: new FormControl(''),
      professionalTax: new FormControl(''),
      section80C: new FormControl(''),
      section80DMediclaim: new FormControl(''),
      section80E: new FormControl(''),
      section80TTA: new FormControl(''),
      incomeFromHousePropertySection3: new FormControl(''),
      section80CCD1: new FormControl(''),
	    section80CCD2: new FormControl(''),
	    section80DD: new FormControl(''),
	    section80DDB: new FormControl(''),
	    section80EEA: new FormControl(''),
	    section80EEB: new FormControl(''),
	    section80GGGAGGC: new FormControl(''),
	    section80GG: new FormControl(''),
	    section80U: new FormControl(''),
      incomeLossOnHouseProperty: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    // this.currenttaxdeductionId = this.route.snapshot.paramMap.get('id');
  
    if (this.currenttaxdeductionId ){
      this.getTaxDeductionByid();
    }
    this.getUserList();
   
  }


  getTaxDeductionByid(){
    this.taxdeductionService.getTaxDeductionById(this.currenttaxdeductionId).subscribe((responce: any) =>{
      this.taxdeductionform.patchValue(responce);
      this.taxdeductionform.value.employeeName = responce.employeeId +' . '+ responce.employeeName;
      this.taxdeductionform.value.financialYear = responce.financialYear;
      this.taxdeductionform.patchValue(this.taxdeductionform.value);
    })
  }

  // getter for easy access to form fields
  get f() {
    return this.taxdeductionform.controls;
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getTaxDeduction(){
    this.taxdeductionService.getAllTaxDeduction().subscribe((response: any) => {
      this.rowData = response.result; 
      console.log("this.rowData:", this.rowData);
    });
    
  }

  createTaxDeduction() {
    // let details = JSON.stringify(this.taxdeductionform.value);
    let data = {
      id: this.taxdeductionform.get('id').value,
      financialYear: this.taxdeductionform.get('financialYear').value,
      employeeId: this.taxdeductionform.get('employeeName').value.split(' . ')[0],
      employeeName: this.taxdeductionform.get('employeeName').value.split(' . ')[1],
      metro: this.taxdeductionform.get('metro').value,
      taxExemptionName: this.taxdeductionform.get('taxExemptionName').value,
      taxExemptionValue: this.taxdeductionform.get('taxExemptionValue').value,
      percent:  this.taxdeductionform.get('percent').value,
      basicSalaryDA: this.taxdeductionform.get('basicSalaryDA').value,
      hra: this.taxdeductionform.get('hra').value,
      lta: this.taxdeductionform.get('lta').value,
      gratuityPayment: this.taxdeductionform.get('gratuityPayment').value,
      leaveEncashmentPayment: this.taxdeductionform.get('leaveEncashmentPayment').value,
      childrenEducationAllowance: this.taxdeductionform.get('childrenEducationAllowance').value,
      otherTaxFreeAllowanceOrReimbursements: this.taxdeductionform.get('otherTaxFreeAllowanceOrReimbursements').value,
      sharedDeduction: this.taxdeductionform.get('sharedDeduction').value,
      professionalTax: this.taxdeductionform.get('professionalTax').value,
      section80C: this.taxdeductionform.get('section80C').value,
      section80DMediclaim: this.taxdeductionform.get('section80DMediclaim').value,
      section80E: this.taxdeductionform.get('section80E').value,
      section80TTA: this.taxdeductionform.get('section80TTA').value,
      incomeFromHousePropertySection3: this.taxdeductionform.get('incomeFromHousePropertySection3').value,
      section80CCD1: this.taxdeductionform.get('section80CCD1').value,
	    section80CCD2: this.taxdeductionform.get('section80CCD2').value,
	    section80DD: this.taxdeductionform.get('section80DD').value,
	    section80DDB: this.taxdeductionform.get('section80DDB').value,
	    section80EEA: this.taxdeductionform.get('section80EEA').value,
	    section80EEB: this.taxdeductionform.get('section80EEB').value,
	    section80GGGAGGC: this.taxdeductionform.get('section80GGGAGGC').value,
	    section80GG: this.taxdeductionform.get('section80GG').value,
	    section80U: this.taxdeductionform.get('section80U').value,
      incomeLossOnHouseProperty: this.taxdeductionform.get('incomeLossOnHouseProperty').value,
    }
    this.taxdeductionService.createTaxDeduction(data).subscribe((response: any) => {
      console.log("response: ", response);
      // update grid after creating new contact & close the modal
      this.deduction();
    });
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
      else this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].regimeNo} ${this.selectedCheckboxData[i].financialYear}`;
    }
  }

  deleteTaxMetadata() {
    this.taxdeductionService.deletetTaxDeduction(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getTaxDeduction();
      this.selectedCheckboxData = []
      $('#closeModalDeleteTaxMetaData').click()
    });
  } 

  backToTaxDeduction() {
    this.router.navigate([this.tenantId + '/dashboard/finance/tax-deductions-two']);
  }

  // grossSalary() {
  //   this.router.navigate([this.tenantId + '/dashboard/hr/tax-deduction']);
  // }

  deduction() {
    this.router.navigate([this.tenantId + '/dashboard/finance/tax-deductions-two']);
  }

  updateDeduction(){
    var updatedata = {
      id: this.taxdeductionform.get('id').value,
      financialYear: this.taxdeductionform.get('financialYear').value,
      employeeId: this.taxdeductionform.get('employeeName').value.split(' . ')[0],
      employeeName: this.taxdeductionform.get('employeeName').value.split(' . ')[1],
      metro: this.taxdeductionform.get('metro').value,
      taxExemptionName: this.taxdeductionform.get('taxExemptionName').value,
      taxExemptionValue: this.taxdeductionform.get('taxExemptionValue').value,
      percent:  this.taxdeductionform.get('percent').value,
      basicSalaryDA: this.taxdeductionform.get('basicSalaryDA').value,
      hra: this.taxdeductionform.get('hra').value,
      lta: this.taxdeductionform.get('lta').value,
      gratuityPayment: this.taxdeductionform.get('gratuityPayment').value,
      leaveEncashmentPayment: this.taxdeductionform.get('leaveEncashmentPayment').value,
      childrenEducationAllowance: this.taxdeductionform.get('childrenEducationAllowance').value,
      otherTaxFreeAllowanceOrReimbursements: this.taxdeductionform.get('otherTaxFreeAllowanceOrReimbursements').value,
      sharedDeduction: this.taxdeductionform.get('sharedDeduction').value,
      professionalTax: this.taxdeductionform.get('professionalTax').value,
      section80C: this.taxdeductionform.get('section80C').value,
      section80DMediclaim: this.taxdeductionform.get('section80DMediclaim').value,
      section80E: this.taxdeductionform.get('section80E').value,
      section80TTA: this.taxdeductionform.get('section80TTA').value,
      incomeFromHousePropertySection3: this.taxdeductionform.get('incomeFromHousePropertySection3').value,
      section80CCD1: this.taxdeductionform.get('section80CCD1').value,
	    section80CCD2: this.taxdeductionform.get('section80CCD2').value,
	    section80DD: this.taxdeductionform.get('section80DD').value,
	    section80DDB: this.taxdeductionform.get('section80DDB').value,
	    section80EEA: this.taxdeductionform.get('section80EEA').value,
	    section80EEB: this.taxdeductionform.get('section80EEB').value,
	    section80GGGAGGC: this.taxdeductionform.get('section80GGGAGGC').value,
	    section80GG: this.taxdeductionform.get('section80GG').value,
	    section80U: this.taxdeductionform.get('section80U').value,
      incomeLossOnHouseProperty: this.taxdeductionform.get('incomeLossOnHouseProperty').value,
    }
    this.taxdeductionService.updateTaxDeduction(updatedata,this.currenttaxdeductionId).subscribe((response: any) => {});
  }

}
