import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.scss']
})
export class ProfileSettingComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  settingData() {
    console.log("item: ",);
    this.router.navigate(['/dashboard/profile/profile-setting']);
    // this.router.navigate(['quote-details'], { relativeTo: this.route });
  }

}
