import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/_services/data-share/data.service';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { LocalStorageService } from "../../../../_services/localStorage/local-storage.service";
import { QuoteService } from "../../../../_services/quote/quote.service";
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-manpower-planning',
  templateUrl: './manpower-planning.component.html',
  styleUrls: ['./manpower-planning.component.scss']
})
export class ManpowerPlanningComponent implements OnInit {
  @Output() manpowerPlanningEvent = new EventEmitter<any>();
  tenantId: string;
  quoteForm: FormGroup;
  quoteObject = JSON.parse(localStorage.getItem("quoteObject"));
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));

  // manpower planning start 
  manpowerTable: FormGroup;
  cclredit: FormGroup;
  startDate = new Date();
  endDate = new Date();
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });
  touchedRows: any;
  days = [];
  allocationData: object[];
  control: FormArray;
  deleteArray = [];
  // manpower planning end
  isManpowerFormVisible: boolean = false;
  costCenterDropDownReslvl: any[] = [];
  costCenterDropDownloc: any[] = [];
  softwareDropDown: any[] = [];
  softwareDropDownhourlyrate: any[] = [];
  costCenterDropDown: any[] = [];
  softwareRatesapi: any;
  // defined the array of Software Dropdown
  public dataSource_srdropdown: {[key: string]: Object}[];
  // defined the array of COSTcenter Dropdown
  public dataSource_cclrdropdown: {[key: string]: Object}[];
  softwareUtildropdown: number[] = [0, 25, 50, 75, 100]

  manpowerFormGroup: FormGroup = new FormGroup({
    taskName: new FormControl(''),
    activity: new FormControl(''),
    costcenter: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    resourceLevel: new FormControl('', Validators.required),
    software: new FormControl(''),
    softwareUtilization: new FormControl(0)
  })

  allAllocationData: any[] = [];
  allocationDataTop: any[] = [];
  allocationDataBottom: any[] = []; 

  formMode: number = 0; // 0 - none // 1 - new form // 2 - edit form

  constructor(
    private dataService: DataService, 
    private formBuilder: FormBuilder, 
    private storageService: LocalStorageService, 
    private quoteService: QuoteService, 
    public datepipe: DatePipe) {
   }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    // this.getManpowerDist();
    this.getPlanning();
    this.getCostCenterLevelRates();
    this.getSoftwareRates();
    this.startDate = new Date(this.quoteObject.startDate);
    this.endDate = new Date(this.quoteObject.endDate);
    this.getAllDates(this.startDate, this.endDate);

    for (let item of this.days) {
      this.manpowerFormGroup.addControl(item, new FormControl(0))
    }
  }

  requiredSave() {
    if (this.manpowerFormGroup.status === 'INVALID') return true;
    else return false;
  }

  getPlanning() {
    this.quoteService.getManpowerDistributions(this.opportunityObject.id, this.quoteObject.id).subscribe(response => {
      this.allAllocationData = [];
      this.allocationDataTop = [];
      this.allocationDataBottom = [];
      this.allAllocationData = response;
      this.allocationDataTop = response;
      this.manpowerPlanningEvent.emit(response)

    }, error => {
      console.log('ERROR - cannot get manpower planning: ' + error)
    })
  }

  savePlanning() {
    let valuesDate = [];
    for (let item of this.days) {
      valuesDate.push({
        date: item,
        value: this.manpowerFormGroup.get(item).value
      })
    }
    let data = { 
      taskName: this.manpowerFormGroup.get('taskName').value,
      activity: this.manpowerFormGroup.get('activity').value,
      costcenter: this.manpowerFormGroup.get('costcenter').value,
      location: this.manpowerFormGroup.get('location').value,
      resourceLevel: this.manpowerFormGroup.get('resourceLevel').value,
      software: this.manpowerFormGroup.get('software').value,
      softwareUtilization: this.manpowerFormGroup.get('softwareUtilization').value,
      values: valuesDate
    }
    if (!this.requiredSave()) {
      this.quoteService.createManpowerDistributions(data, this.opportunityObject.id, this.quoteObject.id).subscribe(response => {
        this.getPlanning();
        this.formMode = 0;
        this.isManpowerFormVisible = false;
      },
      error => {
        console.log('ERROR - cannot post manpower planning: ' + error)
      });
    }
  }

  updatePlanning() {
    let valuesDate = [];
    for (let item of this.days) {
      valuesDate.push({
        date: item,
        value: this.manpowerFormGroup.get(item).value
      })
    }
    let data = { 
      taskName: this.manpowerFormGroup.get('taskName').value,
      activity: this.manpowerFormGroup.get('activity').value,
      costcenter: this.manpowerFormGroup.get('costcenter').value,
      location: this.manpowerFormGroup.get('location').value,
      resourceLevel: this.manpowerFormGroup.get('resourceLevel').value,
      software: this.manpowerFormGroup.get('software').value,
      softwareUtilization: this.manpowerFormGroup.get('softwareUtilization').value,
      values: valuesDate
    }
    if (!this.requiredSave()) {
      this.quoteService.updateManpowerDistributions(data, this.opportunityObject.id, this.quoteObject.id, this.editPlanningId).subscribe(response => {
        this.getPlanning();
        this.formMode = 0;
        this.isManpowerFormVisible = false;
      },
      error => {
        console.log('ERROR - cannot post manpower planning: ' + error)
      });
    }
  }

  editPlanningId: number;
  editPlanningById(id: number) {
    this.formMode = 2;
    this.isManpowerFormVisible = true;
    const idx = this.allAllocationData.findIndex(i => i.id === id);
    this.editPlanningId = id;
    this.allocationDataTop = this.allAllocationData.slice(0, idx);
    this.allocationDataBottom = this.allAllocationData.slice(idx + 1, this.allAllocationData.length)
    this.manpowerFormGroup.patchValue({
      taskName: this.allAllocationData[idx].taskName,
      activity: this.allAllocationData[idx].activity,
      costcenter: this.allAllocationData[idx].costcenter,
      location: this.allAllocationData[idx].location,
      resourceLevel: this.allAllocationData[idx].resourceLevel,
      software: this.allAllocationData[idx].software,
      softwareUtilization: this.allAllocationData[idx].softwareUtilization,
    })
    for (let item of this.allAllocationData[idx].values) {
      this.manpowerFormGroup.get(item.date).setValue(item.value)
    }
  }

  deletePlanningById(id: number) {
    this.quoteService.deleteManpowerDistributions(this.opportunityObject.id, this.quoteObject.id, id).subscribe(response => {
      this.getPlanning();
    }, error => {
      console.log('ERROR - cannot delete manpower planning: ' + error)
    })
  }

  visibleForm() {
    if (this.formMode === 0) {
      this.isManpowerFormVisible = true;
      this.formMode = 1;
      this.manpowerFormGroup.patchValue({
        taskName: '',
        activity: '',
        costcenter: '',
        location: '',
        resourceLevel: '',
        software: '',
        softwareUtilization: 0,
      })
      for (let item of this.days) {
        this.manpowerFormGroup.get(item).setValue(null)
      }
    } else if (this.formMode === 1) {
      this.isManpowerFormVisible = false;
      this.formMode = 0;
    }
  }

  getAllDates(startDate, endDate) {
    let dates = [];
    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setMonth(theDate.getMonth() + 1);
    }
    dates = [...dates];
    this.days = [];
    for (let item of dates) {
      this.days.push(this.datepipe.transform(item, 'MMM-y'));
    }
  }

  getCostCenterLevelRates() {
    this.quoteService.getCostCenterLevelRates(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      if (response.length > 0) {
        for (let item of response) {
          if (!this.costCenterDropDown.includes(item.costcenter)) this.costCenterDropDown.push(item.costcenter)
          if (!this.costCenterDropDownloc.includes(item.location)) this.costCenterDropDownloc.push(item.location)
          if (!this.costCenterDropDownReslvl.includes(item.level)) this.costCenterDropDownReslvl.push(item.level)
        }
      }
    });
  }

  getSoftwareRates() {
    this.quoteService.getSoftwareRates(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      this.dataSource_srdropdown = response;
      this.softwareRatesapi = response.length;
      this.softwareDropDown = response;
      this.softwareDropDownhourlyrate = response[0]?.hourlyCostRate;
    });
  }

  getSoftwareddRates() {
    this.quoteService.getSoftwareRates(this.opportunityObject.id,this.quoteObject.id).subscribe((response:any) => {
      this.dataSource_srdropdown = response;
      this.softwareRatesapi = response.length;
    });
  }

}
