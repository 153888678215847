import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TableRows } from '../../_models/tableRows';
import { FormArray } from '@angular/forms';
import { environment } from "../../../environments/environment";
import { result } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AllocationService {

  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }

  public getResourceAllocationProjects() {
    return this.http
      .get<any>(`${environment.apiUrl}/resourceAllocationProjects`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public getResourceAllocationLocationAndRole() {
    return this.http
      .get<any>(`${environment.apiUrl}/costcenterLevelRates/-1`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public getResourceAllocationProjectDetails() {
    return this.http
      .get<any>(`${environment.apiUrl}/resourceAllocationWrappers`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public getProjectDetails() {
    return this.http
      .get<any>(`${environment.apiUrl}/resourceAllocationProjects`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getReportees(){
    return this.http.get<any>(`${environment.apiUrl}/reportees`).pipe(map(result => { return result; }), catchError(this.handleError<any>()),);
  }

  public getUserDetails() {
    
    return this.http
      .get<any>(`${environment.apiUrl}/userSummary`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getResourceAllocationProjectDetailsByIds(allocationIdsArray) {
    return this.http
      .get<any>(`${environment.apiUrl}/resourceAllocationWrappers/${allocationIdsArray}/resourceAllocationProjectDetails`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public creatResourceAllocationProjectDetails(postparameters = {}) {
    return this.http
      .post<any>(`${environment.apiUrl}/resourceAllocationProjectDetails`, postparameters)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  getAllAsFormArray(): Observable<FormArray> {
    return this.getResourceAllocationProjectDetails().pipe(map((tableRows: TableRows[]) => {
      const allocationData = tableRows.map(TableRows.asFormGroup);
      return new FormArray(allocationData);
    }));
  }
  addAllocation(data): Observable<FormArray> {
    return this.createResourceAllocationProjectDetails(data);
  }
  updateAllocation(id: any, data): Observable<FormArray> {
    return this.updateResourceAllocationProjectDetails(data, id);
  }
  public getResourceAllocationGraphResults(allocationIdsArray, isoType) {
    return this.http
      .post<any>(`${environment.apiUrl}/resourceAllocationGraphResults?sumType=${isoType}`, allocationIdsArray)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public getResourceAllocationTeamSummary(allocationIdsArray, isoType) {
    return this.http
      .post<any>(`${environment.apiUrl}/resourceAllocationTeamSummary?sumType=${isoType}`, allocationIdsArray)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public getResourceAllocationProjectSummary(allocationIdsArray, isoType) {
    return this.http
      .post<any>(`${environment.apiUrl}/resourceAllocationProjectSummary?sumType=${isoType}`, allocationIdsArray)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public getResourceAllocationEmployees() {
    return this.http
      .get<any>(`${environment.apiUrl}/users`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getResourceAllocationByDate(id:number, startDate:string, endDate:string) {
    return this.http.get<any>(`${environment.apiUrl}/resourceAllocationProjectDetails?startDate=${startDate}&endDate=${endDate}`)
    .pipe(
      map(result => {
        return result;
      }),
      catchError(this.handleError<any>()),
    );
  }

  public getResourceAllocationById(id:number) {
    return this.http.get<any>(`${environment.apiUrl}/resourceAllocationWrappers/${id}/resourceAllocationProjectDetails`)
    .pipe(
      map(result => {
        return result;
      }),
      catchError(this.handleError<any>()),
    );
  }

  public createResourceAllocationProjectDetails(postparameters = {}) {
    return this.http
      .post<any>(`${environment.apiUrl}/resourceAllocationWrappers`, postparameters)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public createResourceAllocationProjectDetailsInside(allocationIdsArray, postparameters = {}) {
    return this.http
      .post<any>(`${environment.apiUrl}/resourceAllocationWrappers/${allocationIdsArray}/resourceAllocationProjectDetails`, postparameters)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public updateResourceAllocationProjectDetails(postparameters, id) {
    return this.http
      .put<any>(`${environment.apiUrl}/resourceAllocationWrappers/${id}`, postparameters)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public updateResourceAllocationProjectDetailsInside(allocationIdsArray, id, postparameters) {
    return this.http
      .put<any>(`${environment.apiUrl}/resourceAllocationWrappers/${allocationIdsArray}/resourceAllocationProjectDetails/${id}`, postparameters)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public deleteResourceAllocationProjectDetails(id) {
    return this.http
      .delete<any>(`${environment.apiUrl}/resourceAllocationWrappers/${id}`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }
  public deleteResourceAllocationProjectDetailsInside(allocationIdsArray, id) {
    return this.http
      .delete<any>(`${environment.apiUrl}/resourceAllocationWrappers/${allocationIdsArray}/resourceAllocationProjectDetails/${id}`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  public getAllocationData(allocationIdsArray) {
    return this.http
      .get<any>(`${environment.apiUrl}/resourceAllocationWrappers/${allocationIdsArray}`)
      .pipe(
        map(result => {
          return result;
        }),
        catchError(this.handleError<any>()),
      );
  }

  private handleError<T>() {
    return (error: any): Observable<boolean> => {
      return of(false);
    };
  }


}
