<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <form [formGroup]="helpdeskapplicationForm">
          <div class="canvas-item">
            <div class="canvas-header row justify-content-between align-items-center">
              <div class="title col-sm-6">
                <div class="d-flex align-items-center">
                  <div class="edit">
                    <button title="back page" (click)="backToMyHelpdesk()">
                      <span><i class="ri-arrow-left-line"></i></span>
                    </button>
                  </div>
                  <h3 class="px-3">Helpdesk</h3>
                </div>
              </div>

              <!-- <div class="col-sm-6 d-flex justify-content-end">
                              <button type="button"  data-bs-toggle="modal" data-bs-target="#opportunity" class="btn btn-icon"><i class="ri-add-line"></i></button>
                          </div> -->
            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="canvas-data">
                  <div class="row">
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item" *ngIf="this.helpdeskapprowId">
                          <input type="text" formControlName="ticketNo" required disabled />
                          <label class="floating-label floating-label-special"></label>
                          <i class="ri-calendar-todo-line"></i>
                        </div>
                        <div class="input-item" *ngIf="!this.helpdeskapprowId">
                          <input type="text" required placeholder="IN-" disabled />
                          <label class="floating-label"></label>
                          <i class="ri-calendar-todo-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && this.isAdmin">
                      <div class="input">
                        <div class="input-item">          
                          <input list="taskOwnerDatalist" formControlName="reportedToName" />
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label floating-label-special">Incident Reported For*</label>
                          <i class="ri-shield-user-line"></i>
                        </div>
                        <mat-error *ngIf="f.reportedToName.touched && f.reportedToName.invalid"
                          class="alert alert-danger">
                          <mat-error *ngIf="f.reportedToName.errors.required">Reported to Name is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item">    
                          <select formControlName="assetType">
                            <option value="Desktop">Desktop</option>
                            <option value="Laptop">Laptop</option>
                          </select>
                          <label class="floating-label floating-label-special">
                            Asset Type*</label>
                          <i class="ri-file-copy-2-line"></i>
                        </div>
                        <mat-error *ngIf="f.assetType.touched && f.assetType.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.assetType.errors.required">Asset Type is required</mat-error>
                        </mat-error>
                      </div>
                    </div>

                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <input type="text" formControlName="assetNo" required />
                          <label class="floating-label">Asset Id*</label>
                          <i class="ri-user-follow-line"></i>
                        </div>
                        <mat-error *ngIf="f.assetNo.touched && f.assetNo.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.assetNo.errors.required">Asset Id is required</mat-error>
                          <mat-error *ngIf="f.assetNo.errors.pattern">Asset Id can contain alphaNumeric Characters
                            only</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="!this.isTenantCustomised">
                      <div class="input">
                        <div class="input-item">
                          <input type="datetime-local" formControlName="receivedDatetime" />
                          <label class="floating-label">Incident Receive Date&Time</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <input type="datetime-local" formControlName="receivedDatetime" />
                          <label class="floating-label floating-label-special">Incident Receive Date&Time</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && !this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <input type="datetime-local" formControlName="receivedDatetime" disabled />
                          <label class="floating-label floating-label-special">Incident Receive Date&Time</label>
                          <i class="ri-calendar-check-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="! this.isTenantCustomised">
                      <div class="input">
                        <div class="input-item">
                          <input list="taskOwnerDatalist" formControlName="reportedByName" />
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label floating-label-special">Incident Reported By</label>
                          <i class="ri-file-copy-2-line"></i>
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <input list="taskOwnerDatalist" formControlName="reportedByName" />
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label floating-label-special">Incident Reported By</label>
                          <i class="ri-file-copy-2-line"></i>
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && !this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <input list="taskOwnerDatalist" formControlName="reportedByName"
                             disabled/>
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label floating-label-special">Incident Reported By</label>
                          <i class="ri-file-copy-2-line"></i>
                        </div>

                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="! this.isTenantCustomised">
                      <div class="input">
                        <div class="input-item">
                          <input list="taskOwnerDatalist" formControlName="userName"
 />
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label floating-label-special">Incident Assign To*</label>
                          <i class="ri-file-copy-2-line"></i>
                        </div>
                        <mat-error *ngIf="f.userName.touched && f.userNameId.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.userName.errors.required">User Name is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <input list="taskOwnerDatalist" formControlName="userName"/>
                          <datalist id="taskOwnerDatalist">
                            <option [value]="item" *ngFor="let item of userDropdownList"></option>
                          </datalist>
                          <label class="floating-label floating-label-special">Incident Assign To*</label>
                          <i class="ri-file-copy-2-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && !this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <label class="floating-label floating-label-special"></label>
                          <input type='text' formControlName="userName" placeholder="Incident Assign To"
                            disabled />
                          <i class="ri-file-copy-2-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="!this.isTenantCustomised">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="contactType">
                            <option value="Email">Email</option>
                            <option value="Phone">Phone</option>
                            <option value="walkin">walkin</option>
                            <option value="portal ">Portal</option>
                          </select>
                          <label class="floating-label floating-label-special">
                            Contact Means*</label>
                          <i class="ri-contacts-book-fill"></i>
                        </div>
                        <mat-error *ngIf="f.contactType.touched && f.contactType.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.contactType.errors.required">Contact Type is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="contactType">
                            <option value="Email">Email</option>
                            <option value="Phone">Phone</option>
                            <option value="walkin">walkin</option>
                            <option value="portal">Portal</option>
                          </select>
                          <label class="floating-label floating-label-special">
                            Contact Means*</label>
                          <i class="ri-contacts-book-fill"></i>
                        </div>
                        <mat-error *ngIf="f.contactType.touched && f.contactType.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.contactType.errors.required">Contact Type is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="category" (change)="onSelect($event)">
                             <option *ngFor="let categories of category" value="{{categories.id}}">{{categories.name}}</option>
                          </select>
                          <label class="floating-label floating-label-special">Category*</label>
                          <i class="ri-profile-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="subCategory">
                            <option *ngFor="let subCategories of subCategory" value="{{subCategories.id}}">{{subCategories.name}}</option>
                         </select>
                         <label class="floating-label floating-label-special">Sub-Category</label>
                          <i class="ri-profile-line"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="!this.isTenantCustomised">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="finalStatus">
                            <option value="Open">Open</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <!-- <option value="Resolved">Resolved</option> -->
                            <option value="Closed">Closed</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                          <label class="floating-label floating-label-special"> Status</label>
                          <i class="ri-line-chart-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1" *ngIf="this.isTenantCustomised && this.isAdmin">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="finalStatus">
                            <option value="New">New</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Resolved">Resolved</option>
                            <option value="Closed">Closed</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                          <label class="floating-label floating-label-special"> Status</label>
                          <i class="ri-line-chart-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1"
                      *ngIf="this.isTenantCustomised && !this.isAdmin && this.isStatus==='Closed'">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="finalStatus">
                            <option value="New">New</option>
                            <option value="Closed">Closed</option>
                          </select>
                          <label class="floating-label floating-label-special">  Status</label>
                          <i class="ri-line-chart-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1"
                      *ngIf="this.isTenantCustomised && !this.isAdmin && this.isStatus!='Closed'">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="finalStatus" disabled>
                            <option value=" Open">Open</option>
                            <option value="In Progress">In Progress</option>
                            <option value="On Hold">On Hold</option>
                            <!-- <option value="Resolved">Resolved</option> -->
                            <option value="Closed">Closed</option>
                            <option value="Cancelled">Cancelled</option>
                          </select>
                          <label class="floating-label floating-label-special"> Status</label>
                          <i class="ri-line-chart-fill"></i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <select formControlName="priority">
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </select>
                          <label class="floating-label floating-label-special"> Priority*</label>
                          <i class="ri-bar-chart-grouped-fill"></i>
                        </div>
                        <mat-error *ngIf="f.priority.touched && f.priority.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.priority.errors.required">Priority is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <textarea name="" id="" cols="10" rows="1" formControlName="title"></textarea>
                          <label class="floating-label">Short Description (Title)*</label>
                        </div>
                        <mat-error *ngIf="f.title.touched && f.title.invalid" class="alert alert-danger">
                          <mat-error *ngIf="f.title.errors.required">Title is required</mat-error>
                        </mat-error>
                      </div>
                    </div>
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <textarea name="" id="" cols="30" rows="4" formControlName="remarks"></textarea>
                          <label class="floating-label">Description</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item" *ngIf="this.helpdeskapprowId">
                          <input type="file" (change)="onFileChanged($event)" placeholder="Attachment" />
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6 px-3 py-1">
                      <div class="input">
                        <div class="input-item" *ngIf="!this.helpdeskapprowId">
                          <input type="file" (change)="onFileChangedBeforeTicketCreation($event)" placeholder="Attachment" />
                          <i class="ri-calendar-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="this.helpdeskapprowId">
                    <div *ngFor="let item of hdattachment">
                      <table style="border: 1px dotted blue; border-radius: 10px">
                        <tbody>
                          <tr>
                            <td>
                              <span>{{ item }}</span>
                            </td>
                            <td>
                              <div class="delete">
                                <a href="url"></a>
                                <button type="button" (click)="
                                    downloadhelpdeskApplicationsattachments(item)
                                    ">
                                  <span><i class="ri-download-cloud-fill"></i></span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div class="delete">
                                <button type="button" (click)="
                                      deletehelpdeskApplicationsattachments(item)
                                    ">
                                  <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div *ngIf="! this.helpdeskapprowId">
                    <div *ngFor="let item of hdattachment">
                      <table style="border: 1px dotted blue; border-radius: 10px">
                        <tbody>
                          <tr>
                            <td>
                              <span>{{ item }}</span>
                            </td>
                            <td>
                              <div class="delete">
                                <button type="button" (click)="
                                      deletehelpdeskApplicationsattachmentsBeforeTicketCreation(item)
                                    ">
                                  <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-6 px-3 py-1 text-end">
                      <div class="input">
                        <div *ngIf="this.helpdeskapprowId && this.escalatestatus == true">
                          <button type="button" id="closeCreateModal" class="btn" data-bs-dismiss="modal"
                            (click)="escalatehelpdeskappDetailsbyId()" [disabled]="true">
                            <div *ngIf="this.helpdeskapprowId && this.escalatestatus == true">Escalated</div>
                          </button>
                        </div>
                        <div *ngIf="this.helpdeskapprowId && this.escalatestatus == false">
                          <button type="button" id="closeCreateModal" class="btn" data-bs-dismiss="modal"
                            (click)="escalatehelpdeskappDetailsbyId()" [disabled]="!helpdeskapplicationForm.valid">
                            <div *ngIf="this.helpdeskapprowId && this.escalatestatus == false">Escalate</div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 px-3 py-1 text-end">
                      <div class="input">
                        <div *ngIf="this.helpdeskapprowId && this.escalatestatus == true">
                          <button type="button" id="closeCreateModal" class="btn"
                            (click)="updatehelpdeskappDetailsbyId()" [disabled]="!helpdeskapplicationForm.valid">
                            <div *ngIf="this.helpdeskapprowId && this.escalatestatus == true">Save</div>
                          </button>
                        </div>
                        <div *ngIf="this.helpdeskapprowId && this.escalatestatus == false">
                          <button type="button" id="closeCreateModal" class="btn"
                            (click)="updatehelpdeskappDetailsbyId()" [disabled]="!helpdeskapplicationForm.valid">
                            <div *ngIf="this.helpdeskapprowId && this.escalatestatus == false">Save</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>

                  <hr>

                  <div class="row">
                    <div class="col-sm-12 px-3 py-1">
                      <div class="input">
                        <div class="input-item">
                          <textarea name="" id="" cols="30" rows="4" formControlName="comments"></textarea>
                          <label class="floating-label">Comment</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-end align-items-center">
                    <div class="col-sm-3 text-end px-3 py-1">

                      <div *ngIf="this.helpdeskapprowId">
                        <button type="button" id="closeCreateModal" class="btn" (click)="addhelpdeskAppComments()">
                          <div *ngIf="this.helpdeskapprowId">Add Comment</div>
                        </button>
                      </div>
                      <div *ngIf="!this.helpdeskapprowId">
                        <button type="button" id="closeCreateModal" class="btn" (click)="addhelpdeskAppRates()"
                          [disabled]="!helpdeskapplicationForm.valid">
                          <div *ngIf="!this.helpdeskapprowId">Submit</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 px-3 py-1">
                    <div *ngFor="let item of helpdeskcomments">
                      <table style="border: 1px dotted blue; border-radius: 10px">
                        <tbody>
                          <tr>
                            <td>
                              <span> {{ item.commentDate }}</span>
                            </td>
                            <td>
                              <span> {{ item.commentedBy }} : </span>
                            </td>
                            <td>
                              <span> {{ item.comment }}</span>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </section>
    </section>
  </article>
</main>