<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center"> 
                                <div class="edit">
                                    <button title="back page" (click)="backToOnboarding()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Onboarding NDA</h3>
                            </div>
                        </div>
                    </div>
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6 d-flex" *ngIf="this.isHRAdmin">
                            <h3><a (click)="openHrmonboarding()" class="cursor-pointer">HR</a></h3>
                            <h3><a (click)="openndaonboarding()"  class="active cursor-pointer">NDA</a></h3>
                            <h3><a (click)="openItonboarding()"  class="cursor-pointer">IT</a></h3>
                        </div>

                        <div class="title col-sm-6 d-flex" *ngIf="this.isNDAAdmin">
                            <h3><a class="cursor-pointer">HR</a></h3>
                            <h3><a (click)="openndaonboarding()" class="active cursor-pointer">NDA</a></h3>
                            <h3><a class="cursor-pointer">IT</a></h3>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <form [formGroup]="userCreationForm">
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5>Personal Information</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="text" value="{{this.userFullName}}" disabled/>
                                            <label class="floating-label-special">Full Name</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="joinDate" disabled/>
                                            <label class="floating-label-special">Joining Date</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <h5>NDA Information</h5>
                                </div>
                            </div>
                            <div class="row" *ngIf="this.isNDAAdmin">
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <select formControlName="ndaSignedBoolean" required>
                                                <option value="" disabled selected>NDA Sign</option>
                                                <option [ngValue]="true">Yes</option>
                                                <option [ngValue]="false">No</option>
                                              </select>
                                              <label class="floating-label-special">NDA Sign*</label>
                                              <i class="ri-file-list-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="ndaSignedDate" required/>
                                            <label class="floating-label-special">NDA Sign Date*</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                        <!-- <mat-error *ngIf="f.ndaSignedDate.touched && f.ndaSignedDate.invalid" class="alert alert-danger">
                                            <mat-error *ngIf="f.ndaSignedDate.errors.required">Date is required</mat-error>
                                        </mat-error> -->
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <select formControlName="status">
                                                <option value="" disabled selected>Select Status</option>
                                                <option value="withHrTeam">With Hr Team</option>
                                                <option value="withNDATeam">With NDA Team</option>
                                                <option value="withItTeam">With IT Team</option>
                                                <option value="waitingForApproval">Waiting For Approval</option>
                                                <option value="approved">Approved</option>
                                            </select>
                                            <label class="floating-label-special">Status</label>
                                            <i class="ri-file-list-line"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!this.isNDAAdmin">
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <select formControlName="ndaSignedBoolean" disabled>
                                                <option value="" disabled selected>NDA Sign</option>
                                                <option [ngValue]="true">Yes</option>
                                                <option [ngValue]="false">No</option>
                                              </select>
                                              <label class="floating-label-special">NDA Sign*</label>
                                              <i class="ri-file-list-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="input">
                                        <div class="input-item">
                                            <input type="date" formControlName="ndaSignedDate" disabled/>
                                            <label class="floating-label-special">NDA Sign Date</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex gap-2 mt-3 justify-content-end" *ngIf="this.isNDAAdmin">
                                <button class="btn btn-outline-primary" (click)="updateUserCreation()">Save</button>
                                <button class="btn btn-primary" (click)="backToOnboarding()">Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
