<div class="sidebar-details tab-content">
    <div class="tab-pane fade show active" id="costcenter-level-rates">
        <div class="heading">
            <h2>CostCenter Level Rates</h2>
        </div>
        <div class="data">
            <div class="canvas-table">
                <form [formGroup]="costCenterLevelRatesForm">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Location</th>
                                <th>Cost Center</th>
                                <th>Level</th>
                                <th>Hourly Cost Rate</th>
                                <th>
                                    <button (click)="showInputCclr()" class="btn btn-icon">
                                        <span>
                                            <i class="ri-add-line"></i>
                                        </span>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of jsonData">
                                <td>{{item.location}}</td>
                                <td>{{item.costcenter}}</td>
                                <td>{{item.level}}</td>
                                <td>{{item.hourlyCostRate}}</td>
                                <td>
                                    <div class="delete">
                                        <button (click)="deleteCostCenterLevelRates(item.id)"><span><i class="ri-delete-bin-line"></i></span></button>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="isShowInputCclr">
                                <td>
                                    <input type="text" formControlName="location">
                                </td>
                                <td>
                                    <input type="text" formControlName="costcenter">
                                </td>
                                <td>
                                    <input type="text" formControlName="level">
                                </td>
                                <td>
                                    <input type="number" formControlName="hourlyCostRate">
                                </td>
                                <td>
                                    <button class="btn" (click)="addCostCenterLevelRates()">Save</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </div>
    </div>
</div>