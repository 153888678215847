import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  Validators,
} from '@angular/forms';
import 'rxjs/add/operator/filter';
import { HelpdeskService } from 'src/app/_services/helpdesk/helpdesk.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { DatePipe } from '@angular/common';
import { ColumnApi, GridApi } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { ItassetService } from 'src/app/_services/itasset/itasset.service';

@Component({
  selector: 'app-helpdeskapplication',
  templateUrl: './helpdeskapplication.component.html',
  styleUrls: ['./helpdeskapplication.component.scss']
})
export class HelpdeskapplicationComponent implements OnInit {

  helpdeskapplicationForm: FormGroup;
  userDropdownList = [];
  assetDropdownList = [];
  tenantId: string;
  userDetails: any;
  date: Date;
  TodayDate: string;
  lafduration: number;
  latduration: number;
  laduration: number;
  helpdeskduration: number;
  sickleave: number;
  private api: GridApi;
  private columnApi: ColumnApi;
  earnleave: number;
  casualleave: number;
  helpdeskapprowId: string;
  helpdeskappupdateId: any;
  userId: string;
  DelegationId: any;
  Delegationuser: any;
  getUserName: any;
  hdeditstartDate: any;
  selectedFile: File;
  orphanFiles: File [];
  currentUser: any;
  hdattachment: any;
  imageid: any;
  retrievedImage: string;
  url: any;
  helpdeskticketno: any;
  helpdeskticketnoid: any;
  helpdeskcomments: any;
  escalatestatus: any;
  imagename: any;
  userFullName: string;
  received_Datetime: Date;

  isAdmin : boolean;
  isTenantCustomised : boolean;
  isStatus : any;

  ignoreSnackbar : boolean;

  constructor(
    private storageService: LocalStorageService,
    private HelpdeskService: HelpdeskService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private allocationService: AllocationService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private itassetService: ItassetService,
  ) {
    this.helpdeskapplicationForm = this.formBuilder.group({
      actionTakenDetails: new FormControl(''),
      assetNo: new FormControl('', [ Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$') ]),
      assetType: new FormControl('', Validators.required),
      callLoggedByVendor: new FormControl(''), 
      category: new FormControl(''),
      closureDateTime: new FormControl(''),
      comments: new FormControl(''),
      contactType: new FormControl(''),
      costCalculatedById: new FormControl(''),
      costCalculatedByName: new FormControl(''),
      createdBy: new FormControl(''),
      createdByDatetime: new FormControl(''),
      diagnosedById: new FormControl(''),
      diagnosedByName: new FormControl(''),
      domainUserId: new FormControl(''),
      domainUserName: new FormControl(''),
      escalated: new FormControl(''),
      finalStatus: new FormControl(''),
      id: new FormControl(''),
      impact: new FormControl(''),
      impactCost: new FormControl(''),
      learnings: new FormControl(''),
      modifiedBy: new FormControl(''),
      modifiedByDatetime: new FormControl(''),
      priority: new FormControl('', Validators.required),
      receivedDatetime: new FormControl(''),
      remarks: new FormControl(''),
      reportedById: new FormControl(''),
      reportedByName: new FormControl(''),
      reportedToId: new FormControl(''),
      reportedToName: new FormControl(''),
      subCategory: new FormControl(''),
      ticketNo: new FormControl(''),
      title: new FormControl('', Validators.required),
      trainingRequirements: new FormControl(''),
      userId: new FormControl(''),
      userName: new FormControl(''),
      vendorCaseId: new FormControl(''),
    });
  }

  // getter for easy access to form fields
  get f() {
    return this.helpdeskapplicationForm.controls;
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));
    this.getUserList();
    this.getAssetType();
   // this.getUserhelpdeskBalance();

    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;
    //   console.log("leaveapprowidObject", this.leaveapprowidObject);
    //  console.log("setleaveappeditcounter", this.setleaveappeditcounter);

    this.helpdeskapprowId = this.route.snapshot.paramMap.get('id');
    //console.log('leaveapprowidObject', this.helpdeskapprowId);
    if (this.helpdeskapprowId) {
      this.getUserHelpdeskApplicationDetailsById(this.helpdeskapprowId);
      this.gethelpdeskApplicationsattachments(this.helpdeskapprowId);
    }
    else{
      this.hdattachment = []; // empty array for the case when incident is not created
    }

    this.category=this.HelpdeskService.category();
    this.subCategory=this.HelpdeskService.subCategory();
    console.log(this.category);

    // Below code is for IM Technologies' columns' customization for admin and normal user
    const customisedTenants = ['imtechnologies', 'pragamana']
    this.isTenantCustomised = customisedTenants.includes(this.tenantId)  ;
    this.isAdmin = this.userDetails.roles.includes('RoleITAdministrator');
    this.orphanFiles= [];
    

  }

  gethelpdeskfromduration(e) {
    this.lafduration = e.target.value;
    if (this.helpdeskapprowId) {
      this.calculateduration(this.lafduration, this.latduration);
      this.hdeditstartDate = this.lafduration;
    }
  }

  // Pop-up message
  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }

  gethelpdesktoduration(e) {
    this.latduration = e.target.value;
    if (this.helpdeskapprowId) {
      this.lafduration = this.hdeditstartDate;
      console.log('under leaveapprowid lafduration:', this.lafduration);
    }
    this.calculateduration(this.lafduration, this.latduration);
    //console.log('Current date duration:', this.helpdeskduration);
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    console.log('in onFileChanged, selectedFile: ', this.selectedFile);
    this.onUpload();
  }
  
  // Gets called when user selects an attachment before ticket creation
  public onFileChangedBeforeTicketCreation(event) {
    if(event.target.files[0]) {
      this.orphanFiles.push(event.target.files[0] as never);
      this.hdattachment.push(event.target.files[0].name as never);
    }
  }
    

  //Gets called when the user clicks on submit to upload the image
  onUpload() {
    // get logged in user's details
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    console.log("current user's id: ", this.currentUser.id);

    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();
    uploadImageData.append('file', this.selectedFile, this.selectedFile.name);
    console.log('uploadImageData: ', uploadImageData);

    //Make a call to the Spring Boot Application to save the image
    this.HelpdeskService.uploadImage(
      Number(this.helpdeskapprowId),
      uploadImageData
    ).subscribe((response: any) => {
      console.log('upload image response: ', response);
      if (response) {
        if(! this.ignoreSnackbar) {
          this.openSnackBar('Image uploaded successfully');
          window.location.reload();
        }
      } else {
       window.location.reload();
      }
    });

    setTimeout(() => {
      this.gethelpdeskApplicationsattachments(this.helpdeskapprowId);
    }, 400);
  }

  gethelpdeskApplicationsattachments(id) {
    //console.log('I AM IN GET getUserHelpdeskApplicationDetailsById ');

    this.HelpdeskService.gethelpdeskApplicationsattachments(id).subscribe(
      (response) => {
       // console.log('gethelpdeskApplicationsattachments: ', response);
        this.hdattachment = response;
       // console.log('gethelpdeskApplicationsattachments: ', this.hdattachment);
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }

  deletehelpdeskApplicationsattachmentsBeforeTicketCreation(value) {
    this.hdattachment = this.hdattachment.filter((attachment) => {
      return attachment != value;
    });

    this.orphanFiles = this.orphanFiles.filter ((file) => {
      return file.name != value;
    })
  }

  deletehelpdeskApplicationsattachments(value) {
   // console.log('I AM IN deletehelpdeskApplicationsattachments ', value);

    this.imageid = value.split(': ')[0];

    this.HelpdeskService.deletehelpdeskApplicationsattachments(
      this.helpdeskapprowId,
      this.imageid
    ).subscribe(
      (response) => {
        console.log('deletehelpdeskApplicationsattachments: ', response);
        this.openSnackBar('Delete Successful');
        //window.location.reload();
        this.gethelpdeskApplicationsattachments(this.helpdeskapprowId)
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }

  downloadhelpdeskApplicationsattachments(value) {
    
    this.imageid = value.split(': ')[0];
    this.imagename = value.split(': ')[1];

    console.log('I AM IN downloadhelpdeskApplicationsattachments ', this.imagename);

    this.HelpdeskService.downloadhelpdeskApplicationsattachments(
      this.helpdeskapprowId,
      this.imageid
    ).subscribe(
      (response: HttpResponse<Blob>) => {
        let filename: string = this.imagename;
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        
        console.log('downloadhelpdeskApplicationsattachments: ', response);
        
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
    
  }

  openLeaveApp() {
    location.href = '${this.tenantId}/dashboard/leaves/leave-applicationS ';
  }

  getUserHelpdeskApplicationDetailsById(id) {
    

    this.HelpdeskService.getUserHelpdeskApplicationDetailsById(id).subscribe(
      (response) => {
        
        if (response) {
          this.helpdeskapplicationForm.patchValue({
                userId: response.userId,
                title: response.title,
                category: response.category,
                contactType: response.contactType,
                finalStatus: response.finalStatus,
                priority: response.priority,
                appliedByUserId: response.appliedByUserId,
                reportedToId: response.reportedToId,
                reportedToName:response.reportedToName,
                ticketNo: response.ticketNo,
                assetNo: response.assetNo,
                remarks: response.remarks,
                impactCost: response.impactCost,
                impact: response.impact,
                vendorCaseId: response.vendorCaseId,
                actionTakenDetails:response.actionTakenDetails,
                assetType:response.assetType,
                callLoggedByVendor:response.callLoggedByVendor,
                closureDateTime:response.closureDateTime,
                //comments:response.comments,
                costCalculatedById:response.costCalculatedById,
                costCalculatedByName:response.costCalculatedByName,
                createdBy:response.createdBy,
                createdByDatetime:response.createdByDatetime,
                diagnosedById:response.diagnosedById,
                diagnosedByName:response.diagnosedByName,
                domainUserId:response.domainUserId,
                domainUserName:response.domainUserName,
                escalated:response.escalated,
                id:response.id,
                learnings:response.learnings,
                modifiedBy:response.modifiedBy,
                modifiedByDatetime:response.modifiedByDatetime,
                receivedDatetime:response.receivedDatetime,
                reportedById:response.reportedById,
                reportedByName:response.reportedByName,
                subCategory:response.subCategory,
                trainingRequirements:response.trainingRequirements,
                userName:response.userName,
                });

          this.helpdeskticketno = response.ticketNo;
          this.escalatestatus = response.escalated;
          this.helpdeskapplicationForm.value.reportedByName = this.helpdeskapplicationForm.value.reportedById + " . " + this.helpdeskapplicationForm.value.reportedByName;
          this.helpdeskapplicationForm.value.reportedToName = this.helpdeskapplicationForm.value.reportedToId + " . " + this.helpdeskapplicationForm.value.reportedToName;
          this.helpdeskapplicationForm.value.userName = this.helpdeskapplicationForm.value.userId + " . " + this.helpdeskapplicationForm.value.userName;
          this.helpdeskapplicationForm.patchValue(this.helpdeskapplicationForm.value);

          this.isStatus = response.finalStatus;
          this.helpdeskappupdateId = response.id;
          this.calculateduration(response.startDate, response.endDate);
          this.hdeditstartDate = response.startDate;
          this.gethelpedeskcomments(response.id);
        }
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );

  }

  gethelpedeskcomments(id){

    this.HelpdeskService.getUserHelpdeskApplicationDetailsById(id).subscribe(
      (response) => {
        
        if (response) {
         
          this.helpdeskcomments = response.comments;
          console.log("helpdesk comments", this.helpdeskcomments);

        }
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }

  calculateduration(startDate, endDate) {
    let toDate = new Date(endDate);
    let fromDate = new Date(startDate);
    let differenceInTime = toDate.getTime() - fromDate.getTime();
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    if (differenceInDays < 0) {
      this.helpdeskduration = 0;
    } else {
      this.helpdeskduration = differenceInDays + 1;
    }
    console.log('helpdeskduration:', this.helpdeskduration);
  }

  updatehelpdeskappDetailsbyId() {
    this.userFullName = this.userDetails.firstName + " " + this.userDetails.lastName;

    var helpdeskappupdatedetailsdata = {
      id: this.helpdeskappupdateId,
      ticketNo: this.helpdeskticketno,
      userId: this.helpdeskapplicationForm.get('userName') ? this.helpdeskapplicationForm.get('userName').value.split(' . ')[0] : null,
      userName: this.helpdeskapplicationForm.get('userName') ? this.helpdeskapplicationForm.get('userName').value.split(' . ')[1] : null,
      title: this.helpdeskapplicationForm.get('title').value,
      category: this.helpdeskapplicationForm.get('category').value,
      subCategory: this.helpdeskapplicationForm.get('subCategory').value,
      contactType: this.helpdeskapplicationForm.get('contactType').value,
      finalStatus: this.helpdeskapplicationForm.get('finalStatus').value,
      priority: this.helpdeskapplicationForm.get('priority').value,
      reportedById: this.helpdeskapplicationForm.get('reportedByName') ?
      this.helpdeskapplicationForm.get('reportedByName').value.split(' . ')[0] : null,
      reportedByName: this.helpdeskapplicationForm.get('reportedByName') ? 
      this.helpdeskapplicationForm.get('reportedByName').value.split(' . ')[1] : null,
      reportedToId: this.helpdeskapplicationForm.get('reportedToName') ?
        this.helpdeskapplicationForm.get('reportedToName').value.split(' . ')[0] : null,
      reportedToName: this.helpdeskapplicationForm.get('reportedToName') ? 
        this.helpdeskapplicationForm.get('reportedToName').value.split(' . ')[1] : null,
      escalated: false,
      assetNo: this.helpdeskapplicationForm.get('assetNo').value,
      remarks: this.helpdeskapplicationForm.get('remarks').value,
      impact: this.helpdeskapplicationForm.get('impact').value,
      assetType: this.helpdeskapplicationForm.get('assetType').value,
      receivedDatetime: this.helpdeskapplicationForm.get('receivedDatetime').value.substring(0,10)+" "+this.helpdeskapplicationForm.get('receivedDatetime').value.substring(11,16)+":00",
    };

    this.HelpdeskService.updatehelpdeskappDetailsbyId(
      helpdeskappupdatedetailsdata,
      this.helpdeskappupdateId
    ).subscribe((response: any) => {
      this.openSnackBar('Update Helpdesk Application Successful');
      this.backToMyHelpdesk();
    });
  }

  escalatehelpdeskappDetailsbyId() {
    this.userFullName = this.userDetails.firstName + " " + this.userDetails.lastName;
    var helpdeskappupdatedetailsdata = {
      id: this.helpdeskappupdateId,
      ticketNo: this.helpdeskticketno,
      userId: this.userDetails.id,
      userName: this.userFullName,
      title: this.helpdeskapplicationForm.get('title').value,
      category: this.helpdeskapplicationForm.get('category').value,
      subCategory: this.helpdeskapplicationForm.get('subCategory').value,
      contactType: this.helpdeskapplicationForm.get('contactType').value,
      finalStatus: this.helpdeskapplicationForm.get('finalStatus').value,
      priority: this.helpdeskapplicationForm.get('priority').value,
      reportedById: this.userDetails.id,
      reportedByName: this.userDetails.firstName + " " + this.userDetails.lastName,
      reportedToId: this.helpdeskapplicationForm
        .get('reportedToName')
        .value.split(' . ')[0],
      reportedToName: this.helpdeskapplicationForm
        .get('reportedToName')
        .value.split(' . ')[1],
      escalated: true,
      assetNo: this.helpdeskapplicationForm.get('assetNo').value,
      remarks: this.helpdeskapplicationForm.get('remarks').value,
      impact: this.helpdeskapplicationForm.get('impact').value,
      assetType: this.helpdeskapplicationForm.get('assetType').value,
      receivedDatetime: this.helpdeskapplicationForm.get('receivedDatetime').value.substring(0,10)+" "+this.helpdeskapplicationForm.get('receivedDatetime').value.substring(11,16)+":00",
    };

    this.HelpdeskService.escalatehelpdeskappDetailsbyId(
      helpdeskappupdatedetailsdata,
      this.helpdeskappupdateId
    ).subscribe((response: any) => {
      this.openSnackBar('Escalate Helpdesk Application Successful');
      this.getUserHelpdeskApplicationDetailsById(this.helpdeskappupdateId);
      this.backToMyHelpdesk();
    });
  }

  addhelpdeskAppRates() {
    this.userFullName = this.userDetails.firstName + " " + this.userDetails.lastName;
    var addhddata = {
      userId: parseInt(
        this.helpdeskapplicationForm
          .get('userId')
          .value.toString()
          .substring(
            0,
            this.helpdeskapplicationForm
              .get('userId')
              .value.toString()
              .indexOf('.')
          )
      ),
      userName: this.helpdeskapplicationForm
        .get('userId')
        .value.split(' . ')[1],
      id: this.helpdeskappupdateId,
      assetType: this.helpdeskapplicationForm.get('assetType').value,
      receivedDatetime: this.helpdeskapplicationForm.get('receivedDatetime').value.substring(0,10)+" "+this.helpdeskapplicationForm.get('receivedDatetime').value.substring(11,16)+":00",
      title: this.helpdeskapplicationForm.get('title').value,
      category: this.helpdeskapplicationForm.get('category').value,
      subCategory: this.helpdeskapplicationForm.get('subCategory').value,
      contactType: this.helpdeskapplicationForm.get('contactType').value,
      finalStatus: this.helpdeskapplicationForm.get('finalStatus').value,
      priority: this.helpdeskapplicationForm.get('priority').value,
     reportedById: this.userDetails.id,
      reportedByName: this.userDetails.firstName + " " + this.userDetails.lastName,
      reportedToId: this.helpdeskapplicationForm
        .get('reportedToName')
        .value.split(' . ')[0],
      reportedToName: this.helpdeskapplicationForm
        .get('reportedToName')
        .value.split(' . ')[1],
      assetNo: this.helpdeskapplicationForm.get('assetNo').value,
      remarks: this.helpdeskapplicationForm.get('remarks').value,
      impact: this.helpdeskapplicationForm.get('impact').value,
    };
    this.HelpdeskService.addhelpdeskAppRates(addhddata, this.userDetails.id).subscribe(
      (response: any) => {
        if(this.helpdeskapplicationForm.get('comments').value) {
          this.helpdeskappupdateId = response.id;
          this.ignoreSnackbar = true;
          this.addhelpdeskAppComments();
        }
        if(this.orphanFiles && this.orphanFiles.length > 0) {
          this.helpdeskapprowId = response.id;
          this.ignoreSnackbar = true;
          this.orphanFiles.forEach( (file) => {
            this.selectedFile = file;
            this.onUpload();
          })
        }
        
        this.openSnackBar('Helpdesk Ticket created Successfully');
        this.backToMyHelpdesk();
      }
    );
    
  }



  addhelpdeskAppComments() {
    
    var addhdcommentsdata = {
      comment: this.helpdeskapplicationForm.get('comments').value,

    };
    this.HelpdeskService.addhelpdeskAppComments(addhdcommentsdata, this.helpdeskappupdateId).subscribe(
      (response: any) => {
      if(! this.ignoreSnackbar) this.openSnackBar('Helpdesk Comment added Successfully');
       this.gethelpdeskApplicationsattachments(this.helpdeskapprowId);
       this.gethelpedeskcomments(this.helpdeskapprowId);
      }
    );
   // window.location.reload();
    
  }

  backToMyHelpdesk() {
    this.router.navigate([this.tenantId + '/dashboard/itm/helpdesk']);
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getAssetType(){
    this.assetDropdownList = [];
    this.itassetService.getAllItasset().subscribe((response: any) => {
      //  this.getAssetType  = response;
       for (let item of response.result) {
        this.assetDropdownList.push(
          `${item.assetType}`
        );
      }
    });
  }

  getUserID(par) {
    //console.log('List of User', this.getUserName);
    if (this.getUserName != null) {
      var result = this.getUserName.filter(function (chain) {
        return chain.id === par;
      });
      if (result[0].firstName !== null || result[0].lastName !== null) {
        var userName =
          result[0].id + ' . ' + result[0].firstName + ' ' + result[0].lastName;
        //console.log('Rsult', userName);
      }
    }
    return userName;
  }
  category:any=[];
  subCategory:any=[];

  onSelect(category){
    this.subCategory = this.HelpdeskService.subCategory().filter(e => e.parent_id == category.target.value)
  }

  /*
  getUserhelpdeskBalance() {
    this.HelpdeskService.getUserhelpdeskBalance(this.userDetails.id).subscribe(
      (response) => {
        for (let item of response.result) {
          //console.log(`${item.leaveType} ${item.balance}`);
          this.sickleave = response.result[0].balance;
          this.earnleave = response.result[1].balance;
          this.casualleave = response.result[2].balance;
          //console.log('sl', response.result);
        }
      }
    );
  }
*/


}
