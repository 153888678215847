import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { Router } from '@angular/router';
import { TimesheetService } from 'src/app/_services/timesheet/timesheet.service';
import { convertYYYYMMDDtoDDMMYYYY } from 'src/app/_helpers/utils';

@Component({
  selector: 'app-timesheet-no-punch',
  templateUrl: './timesheet-no-punch.component.html',
  styleUrls: ['./timesheet-no-punch.component.scss']
})
export class TimesheetNoPunchComponent implements OnInit {
  jsonData: any;
  // row data and column definitions 
  timesheetPunchForm
  rowData: any;
  public columnDefs: ColDef[];
  modeAction: number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;
  private columnApi: ColumnApi;
  defaultColDef: any;
  rowSelection: any
  dataSource: any;
  selectedRowId: any;
  tenantId: string;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  isPMOManager: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService,
    private router: Router,
    private timesheetService: TimesheetService,
  ) {
    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
      this.columnDefs = this.createColumnDefs();

      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };
    }
    this.timesheetPunchForm = this.formBuilder.group({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      employee: new FormControl(''),
      noPunchDates: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isPMOManager = this.currentUser.roles.includes('RoleHrmAdministrator') || this.currentUser.roles.includes('RolePmoManager')
  }

  getNoTimesheetPunchReport() {
    let startDate = this.timesheetPunchForm.get('startDate').value;
    startDate = convertYYYYMMDDtoDDMMYYYY(startDate);
    let endDate = this.timesheetPunchForm.get('endDate').value;
    endDate = convertYYYYMMDDtoDDMMYYYY(endDate);

    this.timesheetService.getNoTimesheetPunchReport(startDate, endDate).subscribe((response: any) => {
      if (response && response.length > 0) {
        let arr = [];
        let result = response;
        for (let index = 0; index < result.length; index++) {
          arr.push(
            {
              employee: result[index].employee,
              noPunchDates: result[index].noPunchDates.length > 0 ? result[index].noPunchDates.join(', ') : ""
            }
          )
        }
        this.rowData = arr;
      } else {
        this.rowData = [];
      }
    });
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Employee",
        field: "employee",
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'No Punch Dates',
        field: "noPunchDates",
        resizable: true,
        filter: true,
        editable: false,
        sortable: true
      },
    ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  backToTimesheetReport() {
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/timesheet-report']);
  }
}