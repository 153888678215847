import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CostService } from 'src/app/_services/cost/cost.service';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-costcenter',
  templateUrl: './costcenter.component.html',
  styleUrls: ['./costcenter.component.scss']
})
export class CostcenterComponent implements OnInit {
  jsonData: any;
  CostCenter: FormGroup;
  costCenterLevelRatesForm: FormGroup;
  selectedRow: any;
  modeAction: number;

  constructor(private costService: CostService,private formBuilder: FormBuilder,private dataService: DataService,
    private router: Router, private storageService: LocalStorageService, private route: ActivatedRoute, 
    private modalService: NgbModal, private snackBar: MatSnackBar) { 
      
      this.costCenterLevelRatesForm = this.formBuilder.group({
      location: new FormControl('', [ Validators.required, Validators.pattern('^[a-zA-Z ]*$') ] ),
      costcenter: new FormControl('', Validators.required),
      level: new FormControl('', Validators.required),
      hourlyCostRate: new FormControl('', Validators.required)
    });
  }

  // getter for easy access to form fields
  get f() {
    return this.costCenterLevelRatesForm.controls;
  }

  ngOnInit(): void {
    this.getCostCenterLevelRates();
  }

  getCostCenterLevelRates() {
    this.costService.getDefaultCostCenterLevelRates().subscribe((response:any) => {
      this.jsonData = response;
    });
  }

  addCostCenterLevelRates() {
    var addcclrdata = {
      location: this.costCenterLevelRatesForm.get('location').value,
      costcenter: this.costCenterLevelRatesForm.get('costcenter').value,
      level: this.costCenterLevelRatesForm.get('level').value,
      hourlyCostRate: this.costCenterLevelRatesForm.get('hourlyCostRate').value
    }
    this.costService.addCostCenterLevelRates(addcclrdata).subscribe((response: any) => {
      console.log("addCostCenterLevelRates ADD Response", response);
      this.snackBar.open("Costcenter level rate added!", 'Close', { duration: 2000 });
      this.getCostCenterLevelRates();
      $('#closecclrAddModal').trigger("click");
    });
  }

  editOpenModal(item) {
    this.selectedRow = item;
    this.modeAction = 2;
    this.costCenterLevelRatesForm.patchValue(item);
  }

  editCostCenterLevelRates() {
    var editsrdata = {
      location: this.costCenterLevelRatesForm.get('location').value,
      costcenter: this.costCenterLevelRatesForm.get('costcenter').value,
      level: this.costCenterLevelRatesForm.get('level').value,
      hourlyCostRate: this.costCenterLevelRatesForm.get('hourlyCostRate').value
    }
    console.log("selected row: ", this.selectedRow);
    this.costService.editCostCenterLevelRates(this.selectedRow.id, editsrdata).subscribe((response: any) => {
      console.log("editSoftwareRates ADD Response", response);
      this.snackBar.open("costcenter rate edited!", 'Close', { duration: 2000 });
      this.getCostCenterLevelRates();
      $('#closecclrAddModal').trigger("click");
      this.modeAction = 1;
      console.log("this.modeAction = ", this.modeAction);
    });
  }

  deletedefcclr(id) {
    this.costService.deleteCclr(id).subscribe((response: any) => {
      this.snackBar.open("Costcenter level rate deleted!", 'Close', { duration: 2000 });
      this.getCostCenterLevelRates();
      $('#closeCclrDeleteModal').trigger("click");
    });
  }

   // to open delete modal
   openDelete(item) {
    this.selectedRow = item;
  }

  addOpenModal() {
    this.modeAction = 1;
    this.resetForm();
  }

  resetForm() {
    this.costCenterLevelRatesForm.reset();
  }

}
