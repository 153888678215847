import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/_services/notification/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public notificationDuration: Array<any> = [
    {
      lable: '1 Day',
      value: '1',
    },
    {
      lable: '7 Day',
      value: '7',
    },
    {
      lable: '15 Day',
      value: '15',
    },
    {
      lable: '1 Month',
      value: '30',
    },
  ];
  public form: FormGroup;
  public addForm: FormGroup;
  public title: string[] = [""];
  public isTitle: boolean[] = [false];
  selectedCheckboxData = []
  public notifications: Array<any> = [];
  deleteConfirmationMsg = 'Are you sure you want to delete'
  constructor(private _fb: FormBuilder, private _notification: NotificationService) {}

  ngOnInit(): void {
    this.createForm();
    this.createNotificationForm();
  }

  createForm() {
    this.getNotification();
    this.form = this._fb.group({
      notificationArray: new FormArray([])
    })
  }

  createNotificationForm() {
   
    this.addForm = this._fb.group({
      notificationName: ["", [Validators.required]],
      notificationValue: [null, [Validators.required]],
      receiver: ["", [Validators.required]],
      isActive: ["", [Validators.required]],
      viewFlag: ["", [Validators.required]]
    })
  }

  get f() {
    return this.addForm.controls;
  }

  createSettings() {
    // console.log(th)
    // let id = this.form.value.notificationArray[i].id;
    let payload = {
      notificationName: this.addForm.value.notificationName,
      notificationValue : this.addForm.value.notificationValue.length > 0 ? this.addForm.value.notificationValue.join([',']) : "",
      receiver:this.addForm.value.receiver,
      active: this.addForm.value.active
    } 
    // console.log(id);
    console.log(payload);
    this._notification.createNotifications(payload).subscribe({
      next: (response) =>  {
        console.log(response)
        let ele: HTMLElement = document.getElementById('closeModalNotification');
        ele.click();
        this.getNotification();
      },
      error: (error) =>  {
        
      },
      complete: () =>  {
       
        
      },
    });
  }

  updateSettings(i) {
    let id = this.form.value.notificationArray[i].id;
    let payload = {
      notificationName: this.form.value.notificationArray[i].notificationName,
      notificationValue : this.form.value.notificationArray[i].notificationValue.length > 0 ? this.form.value.notificationArray[i].notificationValue.join([',']) : "",
      receiver:this.form.value.notificationArray[i].receiver,
      active: this.form.value.notificationArray[i].active
    } 
    console.log(id);
    console.log(payload);
    this._notification.updateNotifications(payload, id).subscribe({
      next: (response) =>  {
        console.log(response)
      },
      error: (error) =>  {
        
      },
      complete: () =>  {
       
        
      },
    });
  }

  addEvent() {
    if( this.addForm) {
      this.addForm.reset();
    }
  }

  getNotification() {
    this._notification.getAllNotifications().subscribe({
      next: (response) =>  {
        const add = this.form.get('notificationArray') as FormArray;
        add.clear();
        if(response['result'] && response['result'].length > 0) {
          for (let index = 0; index < response['result'].length; index++) {
            this.title[index] = response['result'][index].notificationName;
            add.push(this._fb.group({
              notificationName: [response['result'][index].notificationName],
              notificationValue: [response['result'][index].notificationValue != "" ? response['result'][index].notificationValue.split(',') : null],
              receiver: [response['result'][index].receiver],
              active: [response['result'][index].active],
              id: [response['result'][index].id],
            }));
          } 
         
        }
         this.notifications = response['result'];
      },
      error: (error) =>  {
        
      },
      complete: () =>  {
        console.log(this.form.value)
        
      },
    })
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let id = this.form.value.notificationArray[event].id;
    // let select = event.api.getSelectedNodes();
    this.selectedCheckboxData.push({
      id:id
    })
  }

  // deleteOpenModal(){
  //   this.deleteConfirmationMsg = 'Are you sure you want to delete';
  //   for (let i in this.selectedCheckboxData) {
  //     if (+i === 0)
  //       this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].projectName}`;
  //     else if (this.selectedCheckboxData.length === +i + 1)
  //       this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].projectName}`;
  //     else
  //       this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].projectName}`;
  //   }
  // }

  deleteCampaign(){
    this._notification.deleteNotifications(this.selectedCheckboxData[0].id).subscribe((response: any) => {
      this.getNotification();
      this.selectedCheckboxData = []
      $('#closeModalDelete').trigger("click");
    });
   }

  setTitleEdit(i) {
    console.log(i);
    this.isTitle[i] = !this.isTitle[i];
  }
}


