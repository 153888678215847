import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';

@Component({
  selector: 'app-leave-profile',
  templateUrl: './leave-profile.component.html',
  styleUrls: ['./leave-profile.component.scss']
})
export class LeaveProfileComponent implements OnInit {
  tenantId: string;
  currentUser: any;
  isHRAdmin: boolean;

  constructor(
    private storageService: LocalStorageService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    this.isHRAdmin = this.currentUser.roles.includes('RoleHrmAdministrator');
  }

  leaveBook(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leaveBook']);
  }

  myLeave(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-first-page']);
  }

  leaveDetail(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-book-detail']);
  }

  leaveAdjustment(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-adjustments']);
  }

  leaveBalanceReport(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-balance-report']);
  }

  leaveBalanceLogs(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-balance-logs']);
  }

  holidayList(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/holiday_list']);
  }

  leaveRevoke(){
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-revoke']);
  }
}
