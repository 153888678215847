import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ItassetService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);
   }

   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
  //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }

    // Create Itasset
    createItasset(user: Object): Observable<Object> {
      console.log("In createitAssets method, itAssets: ", user);
      return this.http.post(`${environment.apiUrl}/itAssets`, user).pipe(catchError(this.handleError));
    }

    // Get Itasset by Id Get
   getItasset(id: number) {
   return this.http.get<any>(`${environment.apiUrl}/itAssets/${id}`).pipe(catchError(this.handleError));
  }

   //Delete Itasset by Id Delete
   deleteItasset(id: number): Observable<Object> {
   return this.http.delete(`${environment.apiUrl}/itAssets/${id}`).pipe(catchError(this.handleError));
  }

  //Update Itasset by Id Put
    updateItasset(user: Object,id: number): Observable<Object> {
    console.log("In createUser method, user: ", user);
    return this.http.put(`${environment.apiUrl}/itAssets/${id}`, user).pipe(catchError(this.handleError));
  }

    //Get All Itasset by Id Get
    getAllItasset(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/itAssets`).pipe(catchError(this.handleError));
  }

}
