<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="row">
          <div class="col-sm-12">
            <div class="canvas-item top-sticky">
              <div class="canvas-data">
                <div class="canvas-header row justify-content-between align-items-center">
                  <div class="title col-sm-6">
                    <div class="d-flex align-items-center">
                      <div class="edit">
                        <button title="back page" (click)="backToTimesheetReport()">
                          <span><i class="ri-arrow-left-line"></i></span>
                        </button>
                      </div>
                      <h3 class="px-3">Timesheet Graph</h3>
                    </div>
                  </div>
                </div>

                <div class="row p-0 mt-3">
                  <div class="col-sm-12 p-0">
                    <div class="canvas-data px-0">
                      <form [formGroup]="graphForm">
                        <div class="row">
                          <div class="col-sm-3 p-0">
                            <div class="input">
                              <div class="input-item no-overflow">
                                <ng-select
                                  [items]="typeList"
                                  [(ngModel)]="selectedType"
                                  bindLabel="lable"
                                  bindValue="value"
                                  class="w-100"
                                  placeholder="Select Type"
                                  formControlName="type"
                                  (change)="onchangeType()">
                                </ng-select>
                                <i class="ri-calendar-line"></i>
                              </div>
                            </div>
                            <div class="input my-2" *ngIf="selectedType && selectedType != ''">
                              <div class="input-item no-overflow">
                                <ng-select 
                                  [items]="projectList"
                                  class="w-100"
                                  [multiple]="true"
                                  bindLabel="projectName"
                                  bindValue="id"
                                  placeholder="Select Projects"
                                  *ngIf="selectedType == 'project'"
                                  formControlName="ids">
                                    <ng-template ng-header-tmp>
                                      <div class="d-flex gap-2">
                                        <button class="btn btn-sm" (click)="onSelectAll('project')">Select All</button>
                                        <button class="btn btn-sm" (click)="onClearAll()">Clear All</button>
                                      </div>
                                    </ng-template>
                                </ng-select>

                                <ng-select
                                  [items]="employeeList"
                                  class="w-100"
                                  [multiple]="true"
                                  bindLabel="userId"
                                  bindValue="id"
                                  placeholder="Select Employee"
                                  *ngIf="selectedType == 'user'"
                                  formControlName="ids">
                                    <ng-template ng-header-tmp>
                                      <div class="d-flex gap-2">
                                        <button class="btn btn-sm" (click)="onSelectAll('user')">Select All</button>
                                        <button class="btn btn-sm" (click)="onClearAll()">Clear All</button>
                                      </div>
                                    </ng-template>
                                </ng-select>
                                <i class="ri-calendar-line"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="input">
                              <div class="input-item">
                                <input type="date" formControlName="startDate" />
                                <label class="floating-label-special">From</label>
                                <i class="ri-calendar-line"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-3">
                            <div class="input">
                              <div class="input-item">
                                <input type="date" formControlName="endDate" />
                                <label class="floating-label-special">To</label>
                                <i class="ri-calendar-line"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-2 text-end text-right">
                            <button type="button" class="btn" (click)="generateReport()" [disabled]="this.selectedType && this.selectedType == ''">
                              <div>Submit</div>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <hr class="mt-3">

                <div class="graph-container">
                  <ejs-chart style='display:block;' [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [title]='title' [tooltip]="tooltip" (tooltipRender)="onTooltipRender($event)">
                    <e-series-collection>
                      <e-series [dataSource]='data' type='StackingColumn' xName='xName' yName='yName'>
                      </e-series>
                    </e-series-collection>
                  </ejs-chart>
                </div>
                <br />
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </section>
    </section>
  </article>
</main>