import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import 'rxjs/add/operator/filter';
import { PerformanceTempService } from "src/app/_services/performance_temp/performance-temp.service";
import { OnsiteEmpService } from "src/app/_services/onsite_emp/onsite-emp.service"
import { LocalStorageService } from "src/app/_services/localStorage/local-storage.service";
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import * as _ from 'lodash';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { convertYYYYMMDDtoDDMMYYYY } from '../../../../_helpers/utils';

@Component({
  selector: 'app-onsite-employee',
  templateUrl: './onsite-employee.component.html',
  styleUrls: ['./onsite-employee.component.scss']
})
export class OnsiteEmployeeComponent implements OnInit {
  selectedRow: any;
  form: FormGroup;
  globalSettingForm: FormGroup;
  userDeputationsForm
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
  defaultColDef:any;
  rowSelection:any
  tenantId: string;
  dataSource_performancegoals: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';  
  currentUser: any;
  selectedFile: File;
  userDropdownList = [];
  getUserName: any;

  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private allocationService: AllocationService,
    private onsiteEmpService: OnsiteEmpService,
    private snackBar: MatSnackBar,
  ) { 
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs(); 


    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    this.userDeputationsForm = this.formBuilder.group({
      userId: new FormControl(''),
      username: new FormControl(''),
      deputatedAtCompanyName: new FormControl(''),
      jobRole: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      duration: new FormControl(''),
    });

    
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getAllOnsiteInfo();
    this.getUserList();
  }

  getAllOnsiteInfo() {
    this.onsiteEmpService.getAllUserDeputations().subscribe((response:any) => {
      this.rowData = response;
      console.log("Getall OnsiteInfo Response", response);
    });
  }

  addUserDeputations() {
    var addcclrdata = {
      userId: parseInt(this.userDeputationsForm.get('userId').value.toString().includes(".") ? this.userDeputationsForm.get('userId').value.split('.')[0].trim() : this.userDeputationsForm.get('userId').value),
      username: parseInt(this.userDeputationsForm.get('username').value.toString().includes(".") ? this.userDeputationsForm.get('username').value.split('.')[0].trim() : this.userDeputationsForm.get('username').value),
      deputatedAtCompanyName: this.userDeputationsForm.get('deputatedAtCompanyName').value,
      jobRole: this.userDeputationsForm.get('jobRole').value,
      startDate: this.userDeputationsForm.get('startDate').value,
      endDate: this.userDeputationsForm.get('endDate').value,
      duration: this.userDeputationsForm.get('duration').value,
     
    }
    this.onsiteEmpService.createUserDeputations(addcclrdata).subscribe((response: any) => {
      console.log("create OnsiteInfo Response", response);
      this.snackBar.open("OnsiteInfo Added!", 'Close', { duration: 2000 });
      this.getAllOnsiteInfo();
      $('#closeCreateModal').trigger("click");
    });
  }

  editOpenModal() {
    this.selectedRow = this.selectedCheckboxData[0];
    this.modeAction = 2;
    this.userDeputationsForm.patchValue(this.selectedCheckboxData[0]);
  }

  editserDeputations() {
   let data = {
    userId: parseInt(this.userDeputationsForm.get('userId').value.toString().includes(".") ? this.userDeputationsForm.get('userId').value.split('.')[0].trim() : this.userDeputationsForm.get('userId').value),
    // username: parseInt(this.userDeputationsForm.get('username').value.toString().includes(".") ? this.userDeputationsForm.get('username').value.split('.')[0].trim() : this.userDeputationsForm.get('username').value),  
    deputatedAtCompanyName: this.userDeputationsForm.get('deputatedAtCompanyName').value,
      jobRole: this.userDeputationsForm.get('jobRole').value,
      startDate: this.userDeputationsForm.get('startDate').value,
      endDate: this.userDeputationsForm.get('endDate').value,
      duration: this.userDeputationsForm.get('duration').value,
      
    }
    console.log("create OnsiteInfo Response", data);
    this.onsiteEmpService.updateUserDeputations(data , this.selectedRow.id).subscribe((response: any) => {
      console.log("create OnsiteInfo Response", response);
      this.snackBar.open("OnsiteInfo Added!", 'Close', { duration: 2000 });
      this.getAllOnsiteInfo();
      $('#closeCreateModal').trigger("click");
    });
  }

  addOpenModal() {
    this.modeAction = 1;
    this.resetForm();
  }

  resetForm() {
    this.userDeputationsForm.reset();
  }

    // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
    onGridReady(params): void {
      this.api = params.api;
      console.log('qqqq', this.api)
      this.columnApi = params.columnApi;
      this.api.sizeColumnsToFit();
    } 

       // create column definitions  
   private createColumnDefs() {
    return [
    {
      headerName: 'User Name',
      field: 'username',
      filter: true,
      editable: false,
      sortable: true,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
    },
    {
      headerName: 'Company Name',
      field: 'deputatedAtCompanyName',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Job Role',
      field: 'jobRole',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      filter: true,
      editable: false,
      sortable: true
    },
    {
      headerName: 'Duration',
      field: 'duration',
      filter: true,
      editable: false,
      sortable: true
    },
  ]
  }


  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }


  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }     
  }

  private deleteGridRow(params: any) {
    this.onsiteEmpService.deleteUserDeputations(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].deputatedAtCompanyName}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].deputatedAtCompanyName}`;
      else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].deputatedAtCompanyName}`;
    }
  }

  deleteHoliday() {
    this.onsiteEmpService.deleteUserDeputations(this.selectedCheckboxData[0].id).subscribe((response: any) => {
      this.getAllOnsiteInfo();
      this.selectedCheckboxData = []
      $('#closeDeleteModal').trigger("click");
    });
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
      this.userDropdownList.push(
        `0 . All Users`
      );
    });
  }

  getuserDeputations(){
    let userId = this.userDeputationsForm.get('userId').value.split(' . ')[0];
    let startDate = this.userDeputationsForm.get('startDate').value;
    startDate = convertYYYYMMDDtoDDMMYYYY(startDate);
    let endDate = this.userDeputationsForm.get('endDate').value;
    endDate = convertYYYYMMDDtoDDMMYYYY(endDate);
    console.log('start date end date',startDate ,endDate)
    this.onsiteEmpService.getuserDeputations(userId, startDate, endDate).subscribe((response: any) => {
      if(response && response.length > 0) {
        this.rowData = response.map(x => ({...x }));
      } else {
        this.rowData = [];
      }
    });
  }

}
