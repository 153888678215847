<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> Global Setting </h3>
                        </div>
                        <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button title="Edit" type="button" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#createForm">
                                    <span><i class="ri-pencil-line"></i></span>
                                </button>
                                </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteModal" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div class="add-btn">
                                <button type="button" (click)="createOpenModal()" data-bs-toggle="modal" data-bs-target="#createForm" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Create new Goal -->
<div class="modal fade bd-example-modal-lg" id="createForm" tabindex="-1">
    <div class="modal-dialog modal-lg">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="globalSettingForm">
                <div class="modal-header">
                    <h5 class="modal-title">Create Global Setting</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="input">
                            <div class="input-item">
                                <input formControlName="globalSettingName" type="text" >
                                <label class="floating-label">Global Setting Name*</label>
                                <i class="ri-calendar-check-line"></i>
                            </div>
                        </div>
                        <div class="input">
                            <div class="input-item">
                                <input formControlName="globalSettingType" type="text">
                                <label class="floating-label">Global Setting Type</label>
                                <i class="ri-file-list-line"></i>
                            </div>
                        </div>
                        <div class="input">
                            <div class="input-item">
                                <input formControlName="globalSettingValue" type="text">
                                <label class="floating-label">Global Setting Value</label>
                                <i class="ri-message-2-line"></i>
                            </div>
                        </div>

                        <div class="input">
                                <p><b>Note:</b>&nbsp;&nbsp;{{note}}</p>
                        </div>
                    </div> 
                    
                </div>
                <div class="modal-footer">
                    <button type="button" id="closeCreateModal"  class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" (click)="createGlobalSetting()" >Create Global</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" (click)="updateGlobalVersion()" >Update</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="deleteModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteleaveAppRates()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>