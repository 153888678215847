<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3>Cost Center Level Rates </h3>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <button type="button"  data-bs-toggle="modal" data-bs-target="#cclrAddModal" class="btn btn-icon"><i class="ri-add-line" (click)="addOpenModal()"></i></button>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="canvas-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th>Cost Center</th>
                                        <th>Level</th>
                                        <th>Hourly Rate</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of jsonData">

                                        <td>{{item.location}}</td>
                                        <td>{{item.costcenter}}</td>
                                        <td>{{item.level}}</td>
                                        <td>{{item.hourlyCostRate}}</td>
                                        <td>
                                            <div class="table-action">
                                                <button class="action-btn"><i class="ri-more-2-fill"></i></button>
                                                <div class="action-items d-flex">
                                                    <div class="edit">
                                                        <button type="button" (click)="editOpenModal(item)" data-bs-toggle="modal" data-bs-target="#cclrAddModal">
                                                            <span><i class="ri-pencil-line"></i></span>
                                                            
                                                        </button>
                                                    </div>
                                                    <div class="delete">
                                                        <button type="button" data-bs-toggle="modal" data-bs-target="#cclrDeleteModal" (click)="openDelete(item)">
                                                            <span><i class="ri-delete-bin-5-line"></i></span>
                                                            
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
  
<!-- Add new Cost Center Level Rate -->
<div class="modal fade" id="cclrAddModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="costCenterLevelRatesForm">
                <div class="modal-header">
                    <h5 class="modal-title">Add Cost Center Level Rate</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="location" type="text" required>
                            <label class="floating-label">Location</label>
                            <i class="ri-map-pin-line"></i>
                        </div>
                        <mat-error *ngIf="f.location.touched && f.location.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.location.errors.required">Location is required</mat-error>
                            <mat-error *ngIf="f.location.errors.pattern">Location can contain alphabets only</mat-error>
                          </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="costcenter" type="text" required>
                            <label class="floating-label">Cost Center</label>
                            <i class="ri-gradienter-line"></i>
                        </div>
                        <mat-error *ngIf="f.costcenter.touched && f.costcenter.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.costcenter.errors.required">Cost Center is required</mat-error>
                          </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="level" type="text" required>
                            <label class="floating-label">Level</label>
                            <i class="ri-bar-chart-2-line"></i>
                        </div>
                        <mat-error *ngIf="f.level.touched && f.level.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.level.errors.required">Level is required</mat-error>
                          </mat-error>
                    </div>
                    <div class="input">
                        <div class="input-item">
                            <input formControlName="hourlyCostRate" type="text" required>
                            <label class="floating-label">Hourly Rate</label>
                            <i class="ri-money-dollar-circle-line"></i>
                        </div>
                        <mat-error *ngIf="f.hourlyCostRate.touched && f.hourlyCostRate.invalid" class="alert alert-danger">
                            <mat-error *ngIf="f.hourlyCostRate.errors.required">Hourly Rate is required</mat-error>
                          </mat-error>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeCclrAddModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary"  *ngIf="modeAction === 1" data-bs-dismiss="modal" (click)=addCostCenterLevelRates() [disabled]="!costCenterLevelRatesForm.valid" >Add</button>
                    <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" data-bs-dismiss="modal" (click)="editCostCenterLevelRates()" [disabled]="!costCenterLevelRatesForm.valid" >Update</button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Delete an item -->
<div class="modal fade" id="cclrDeleteModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup]="costCenterLevelRatesForm">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Cost Center Level Rate</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>Are you sure you want to delete <b>{{selectedRow?.costcenter}}: {{selectedRow?.level}}</b>?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeCclrDeleteModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <a class="btn btn-primary" (click)="deletedefcclr(selectedRow?.id)">Yes Delete</a>
                </div>
            </form>
        </div>
    </div>
</div>