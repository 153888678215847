import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';
import {
  GridModule,
  EditService,
  ToolbarService,
  PagerModule,
  GroupService,
  ColumnChooserService,
  CommandColumnService,
  PageService,
  FilterService,
  ExcelExportService,
} from '@syncfusion/ej2-angular-grids';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { ChartModule } from '@syncfusion/ej2-angular-charts';
import {
  ChartAllModule,
  AccumulationChartAllModule,
  RangeNavigatorAllModule,
  CategoryService,
  ColumnSeriesService,
  ExportService,
  DataLabelService,
} from '@syncfusion/ej2-angular-charts';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { InPlaceEditorAllModule } from '@syncfusion/ej2-angular-inplace-editor';
import { KanbanModule } from '@syncfusion/ej2-angular-kanban';
import { AngularMaterialModule } from '../../angular-material.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { HomeComponent } from './home/home.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  ButtonModule,
  CheckBoxModule,
  RadioButtonModule,
  SwitchModule,
} from '@syncfusion/ej2-angular-buttons';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { MatStepperModule } from '@angular/material/stepper';

// import { RiskManegementComponent } from './risk-manegement/risk-manegement.component';
import {
  GanttAllModule,
  GanttModule,
  SelectionService,
} from '@syncfusion/ej2-angular-gantt';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OpportunitiesComponent } from './opportunities/opportunities.component';
import { OpportunitiesModule } from './opportunities/opportunities.module';
import { AdminstrationComponent } from './adminstration/adminstration.component';
import { AdminstrationModule } from './adminstration/adminstration.module';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HrComponent } from './hr/hr.component';
import { ProfileModule } from './profile/profile.module';
import { CrmComponent } from './crm/crm.component';
import { ProjectComponent } from './project/project.component';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { ResourceUtilizationComponent } from './resource-utilization/resource-utilization.component';
import { CapacityPlanningDetailComponent } from './project/capacity-planning-detail/capacity-planning-detail.component';
import { ItmComponent } from './itm/itm.component';
import { PaymentsComponent } from './payments/payments.component';
import { IndexComponent } from './index/index.component';
import {
  CircularGaugeModule,
  AnnotationsService,
  LegendService,
} from '@syncfusion/ej2-angular-circulargauge';
import { ApprovalComponent } from './approval/approval.component';

import { ScheduleModule, AgendaService, DayService, WeekService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { FinanceComponent } from './finance/finance.component';


@NgModule({
  declarations: [
    HomeComponent,
    AvatarComponent,
    ProfileComponent,
    ResetPasswordComponent,
    // RiskManegementComponent,
    OpportunitiesComponent,
    AdminstrationComponent,
    HrComponent,
    CrmComponent,
    ProjectComponent,
    ResourceUtilizationComponent,
    CapacityPlanningDetailComponent,
    ItmComponent,
    PaymentsComponent,
    IndexComponent,
    ApprovalComponent,
    FinanceComponent,
    
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    GridModule,
    PagerModule,
    ChartAllModule,
    AccumulationChartAllModule,
    RangeNavigatorAllModule,
    TabModule,
    TreeGridModule,
    InPlaceEditorAllModule,
    RangeNavigatorAllModule,
    TabModule,
    DropDownListAllModule,
    DialogModule,
    AngularMaterialModule,
    SwitchModule,
    DashboardLayoutModule,
    DashboardLayoutModule,
    KanbanModule,
    // SalesTaskModule,
    MatTableModule,
    MatPaginatorModule,
    DropDownListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    NgxPermissionsModule.forChild(),
    GanttAllModule,
    ChartModule,
    OpportunitiesModule,
    AdminstrationModule,
    NgbModule,
    ProfileModule,
    DataTablesModule,
    AgGridModule.withComponents([]),
    GanttModule,
    KanbanModule,
    CircularGaugeModule,
    ScheduleModule,
    MatStepperModule
  ],
  providers: [
    SelectionService,
    EditService,
    ToolbarService,
    GroupService,
    ColumnChooserService,
    PageService,
    FilterService,
    CommandColumnService,
    ExcelExportService,
    CategoryService,
    ColumnSeriesService,
    ExportService,
    LegendService,
    DataLabelService,
    CircularGaugeModule,
    AnnotationsService,
    AgendaService, 
    DayService, 
    WeekService, 
    WorkWeekService, 
    MonthService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DashboardModule {}
