import { Component, OnInit, ViewChild } from '@angular/core';
import { LocalStorageService } from '../../../../_services/localStorage/local-storage.service';
import { UserService } from '../../../../_services/user/user.service';
import * as moment from 'moment';
import { HolidaycalenderService } from '../../../../_services/holidaycalender/holidaycalender.service';
import {
  View, EventSettingsModel, DayService, WeekService, WorkWeekService, MonthService, ResizeService,
  DragAndDropService, NavigatingEventArgs, ActionEventArgs, ScheduleComponent, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService, PopupOpenEventArgs
} from '@syncfusion/ej2-angular-schedule';
import { extend } from '@syncfusion/ej2-base';
import { AttendanceService } from '../../../../_services/attendance/attendance.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/_services/project/project.service';

@Component({
  selector: 'pmoDashboard',
  templateUrl: './pmoDashboard.component.html',
  styleUrls: ['./pmoDashboard.component.scss'],
  providers: [DatePipe, DayService, WeekService, WorkWeekService, MonthService, AgendaService, MonthAgendaService, TimelineViewsService, TimelineMonthService]
})
export class PMODashboard implements OnInit {

  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent;
  public selectedDate: Date = new Date();

  // public data: object[] = [];
  public eventSettings: EventSettingsModel = {
    dataSource: null,
    allowAdding: false,
    allowEditing: false,
    allowDeleting: false,
  };
  public currentView: View = 'Month';
  public isHubConnected = false;
  public data: Record<string, any>[];
  public isReadOnly: boolean = true;
  public showWeekend: boolean = true;
  public jsonData;
  public showWeekNumber: boolean = true;
  public moment = moment;
  public isNoData: boolean = false;
  public cellSpacing: number[] = [10, 10];
  public headerCount: number = 1;
  public notifications:any;
  dashboardIndexNum: number;
  currentUser: any;
  public salaryMetadata: any;
  earnleave: number;
  compensatoryleave: number;
  today= new Date();
  currentDate: any;
  leaveData= [];
  tenantId: any;
  isAdministrator: boolean = false;
  bookedHoursProjectArray: any[] = [];
  resourceAvailabilityArray: any[] = [];

  constructor(
    private userService: UserService,
    private storageService: LocalStorageService,
    private holidaycalenderService: HolidaycalenderService,
    private attendanceService: AttendanceService,
    private router: Router,
    private projectService: ProjectService,
  ) { }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    const currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.currentUser= currentUser;
    this.isAdministrator = this.currentUser.roles.includes('RoleAdministrator');
    this.userService.getUser(currentUser.id).subscribe((response: any) => {
      this.dashboardIndexNum = response.dashboardIndex
    });
    this.handleCalenderData();

    this.getProjectsData();
    this.getPMOResourceAvailability();
  }

  getProjectsData() {
    this.bookedHoursProjectArray = [];
    if(this.isAdministrator){
      this.projectService.getProjects().subscribe((response: any) => {
        for(let item of response) {
          this.bookedHoursProjectArray.push(item);
        }
        });
    } else{
      let ignoreArchived : boolean = false;
      this.projectService.getProjectByUserId(this.currentUser.id, ignoreArchived).subscribe((response: any) => {
        for(let item of response) {
          this.bookedHoursProjectArray.push(item);
        }
      });
    }
  }

  getPMOResourceAvailability(){
    this.resourceAvailabilityArray = [];
    this.projectService.getPMOResourceAvailability().subscribe((response: any) => {
      for(let item of response){
        if(item.availability === true){
          this.resourceAvailabilityArray.push(item);
        }
      }
    })
  }

  /**
   * get day from dat
   * @param index 
   */
   getDays(index) {
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
   return weekday[index];
  }

  public count: number = 8;

  async handleCalenderData() {
    let data: Array<any> = [];
    let responseResults: Array<any> = [];
    let employeeId = this.currentUser.id;
    await this.attendanceService.getAttendanceById(employeeId).subscribe({
      next: (response: any) => {
        if (response.result && response.result.length > 0) {
           responseResults = response.result;
           this.setCalendarData(response.result)
        }
      },
      error: (err) => {

      },
      complete: () => {
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        const d = new Date();

        for (let index = 0; index < responseResults.length; index++) {
          let date = responseResults[index].attendanceDate.split('-');
          let inTime = responseResults[index].inTime && responseResults[index].inTime != "" ? responseResults[index].inTime.split(':') : "";
          let outTime = responseResults[index].outTime && responseResults[index].outTime != "" ? responseResults[index].outTime.split(':') : "";
          data.push({
            Id: index + 1,
            Subject: responseResults[index].workTimeInHours,
            StartTime: new Date(date[0], date[1] - 1, date[2], inTime[0], inTime[1]),
            EndTime: new Date(date[0], date[1] - 1, date[2], outTime[0], outTime[1]),
            inTime: responseResults[index].inTime,
            outTime: responseResults[index].outTime,
            day: this.getDays(responseResults[index].attendanceDate),
            attendanceDate: responseResults[index].attendanceDate,
            name: ""
          })

        }
        for (let index = 0; index < this.jsonData.length; index++) {
          let element = this.jsonData[index];
          let date = this.jsonData[index].holidayDate.split('-');
          data.push({
            Id: index + 1,
            Subject: "",
            StartTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
            EndTime: new Date(date[0], date[1] - 1, date[2], 0, 0),
            inTime: "",
            outTime: "",
            day: this.getDays(element.holidayDate),
            attendanceDate: element.holidayDate,
            name: element.name
          })
        }
        let dataNew: Object[] = <Object[]>extend([], data, null, true)
        this.eventSettings = {
          dataSource: dataNew
        };
      }
    })
  }

  setCalendarData(responseResults) {
        let data: Array<any> = [];
        const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        const d = new Date();

        for (let index = 0; index < responseResults.length; index++) {
          let date = responseResults[index].attendanceDate.split('-');
          let inTime = responseResults[index].inTime && responseResults[index].inTime != "" ? responseResults[index].inTime.split(':') : "";
          let outTime = responseResults[index].outTime && responseResults[index].outTime != "" ? responseResults[index].outTime.split(':') : "";
          data.push({
            Id: index + 1,
            Subject: responseResults[index].workTimeInHours,
            StartTime: new Date(date[0], date[1] - 1, date[2], inTime[0], inTime[1]),
            EndTime: new Date(date[0], date[1] - 1, date[2], outTime[0], outTime[1]),
            inTime: responseResults[index].inTime,
            outTime: responseResults[index].outTime,
            day: this.getDays(responseResults[index].attendanceDate),
            attendanceDate: responseResults[index].attendanceDate,
            name: ""
          })

        }
        let dataNew: Object[] = <Object[]>extend([], data, null, true)
        this.eventSettings = {
          dataSource: dataNew
        };
      }

      public onPopupOpen(args:PopupOpenEventArgs): void{
        if (args.type !== 'QuickInfo' && args.type !== 'Editor')
          return;

        if (!args.target.classList.contains('e-appointment'))
          return;

        if (args.type === 'QuickInfo') {
          // To prevent the default quick info popup by args.cancel is set to true.
          args.cancel = true;
          this.router.navigate([this.tenantId + '/dashboard/hr/time-sheet']);
        } else if (args.type === 'Editor') {
          // To prevent the default editor window popup by args.cancel is set to true.
          args.cancel = true;
          this.router.navigate([this.tenantId + '/dashboard/hr/time-sheet']);
        }
      }

}
