import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { UploadDocumentService } from '../../../../_services/uploadDocument/uploadDocument.service';
import { GridApi, ColumnApi, ColDef } from 'ag-grid-community';
import { HttpResponse } from '@angular/common/http';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss']
})
export class UploadDocumentComponent implements OnInit {
  @Output() documentEvent = new EventEmitter<any>();
  form;
  documentUploadForm: FormGroup;
  employeeForm: FormGroup;
  modeAction:number = 1 //1 - create // 2 - update
  tenantId: string;
  selectedFile: File;
  currentUser: any;
  deleteConfirmationMsg = 'Are you sure you want to delete';
  private api: GridApi;
  public columnDefs: ColDef[];
  private columnApi: ColumnApi;
   defaultColDef:any;
   rowSelection:any;
   dataSource: any;
  gridApi: any;
  gridColumnApi: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  rowData: any;
  isOther: boolean;
  isForm: boolean = false;
  documentId: any;
  documentsData: any[] = [];
  formMode: number = 0;
  isHRAdmin: boolean;
  isManager: boolean;
  isSameUserDocs: boolean;
  userDropdownList = [];
  getUserName: any;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: LocalStorageService,
    private snackBar: MatSnackBar,
    private uploadDocumentService: UploadDocumentService,
    private allocationService: AllocationService,
  ) {

    {
      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
      this.columnDefs = this.createColumnDefs();

      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };
    }
    
    this.documentUploadForm = this.formBuilder.group({
      id: new FormControl(''),
      file: new FormControl(''),
      userId: new FormControl(''),
      documentName: new FormControl(''),
      documentType: new FormControl(''),
      documentName1: new FormControl(''),
    });

    this.employeeForm = this.formBuilder.group({
      id: new FormControl(''),
      employeeId: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isHRAdmin = this.currentUser.roles.includes('RoleHrmAdministrator');
    this.getUserList()
    this.getDocuments();
  }

  getUserList() {
    this.userDropdownList = [];
    if(this.isHRAdmin){
      this.allocationService.getUserDetails().subscribe((response) => {
        this.getUserName = response;
        for(let item of response) {
          this.userDropdownList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
      });
    }
    else {
      this.allocationService.getReportees().subscribe((response) => {
        this.getUserName = response;
        for(let item of response) {
          this.userDropdownList.push(
            `${item.id} . ${item.firstName} ${item.lastName}`
          );
        }
        if(this.userDropdownList.length > 0){
          this.isManager = true;
          console.log("Manager: ",this.isManager);
        }
      });
    }
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params: any) {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Document Name",
        field: "documentName",
        'width': 450,
        'suppressSizeToFit': true,
        filter: true,
        editable: false,
        sortable: true,        
      },
      {
        headerName: 'Docuement Type',
        field: 'documentType',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Docuement',
        field: 'file',
        filter: true,
        editable: false,
        sortable: true
      },
    ]
  }

  getDocumentsByUserId(){
    this.isSameUserDocs = false;
    let employeeId = this.employeeForm.get('employeeId').value.split(' . ')[0];
    if(this.currentUser.id === employeeId){
      this.isSameUserDocs = true;
    }
    this.uploadDocumentService.getUserDocumentsByUserId(employeeId).subscribe((response : any) => {
      this.documentsData = [];
      this.documentsData = response;
      this.documentEvent.emit(response);
    },
    error => {
      console.log('ERROR - cannot get user documents: ' + error)
    });
  }

  getDocuments() {
    this.isSameUserDocs = true;
    this.uploadDocumentService.getUserDocumentsByUserId(this.currentUser.id).subscribe((response : any) => {
      this.documentsData = [];
      this.documentsData = response;
      this.documentEvent.emit(response);
    },
    error => {
      console.log('ERROR - cannot get user documents: ' + error)
    });
  }

  createOpenModal() {
    this.modeAction = 1;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }

  checkStatus(systemStatusValue){
    let b : string = systemStatusValue.split(': ')[1];
    if(b === 'true'){
      this.isOther = true;
    }
  }

  visibleForm() {
    if (this.formMode === 0) {
      this.isForm = true;
      this.formMode = 1;
      this.documentUploadForm.patchValue({
          userId: '',
          file: '',
          documentName: '',
          documentName1: '',
          documentType: '',
      })
    } else if (this.formMode === 1) {
      this.isForm = false;
      this.formMode = 0;
      this.isOther = false;
    }
  }

  requiredSave() {
    if (this.documentUploadForm.status === 'INVALID') return true;
    else return false;
  }

  cancelSave(){
    this.formMode = 0;
    this.isForm = false;
    this.isOther = false;
  }

  //Gets called when the user selects an image/file
  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    console.log('in onFileChanged, selectedFile: ', this.selectedFile);
  }

  saveDocument() {
    const file = new FormData();
    // let userId = this.documentUploadForm.get('userId').value;

    if(this.documentUploadForm.get('documentName').value === true){
      file.append("file", this.selectedFile);
      file.append('userId', this.currentUser.id);
      file.append('documentName', this.documentUploadForm.get('documentName1').value);
      file.append('documentType', this.documentUploadForm.get('documentType').value);
    }
    else{
      file.append("file", this.selectedFile);
      file.append('userId', this.currentUser.id);
      file.append('documentName', this.documentUploadForm.get('documentName').value);
      file.append('documentType', this.documentUploadForm.get('documentType').value);
    }
    
    if (!this.requiredSave()) {
      this.uploadDocumentService.uploadUserDocument(file, this.currentUser.id).subscribe(response => {
        this.getDocuments();
        this.formMode = 0;
        this.isForm = false;
        this.isOther = false;
      },
      error => {
        console.log('ERROR - cannot upload user Document: ' + error)
      });
    }
  }

  downloadDocumentById(id: string, fileName: string){
    this.uploadDocumentService.downloadUserDocumentsId(id).subscribe((response: HttpResponse<Blob>) => {
      let filename: string = fileName;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    });
  }

  deleteDocumentById(id){
    this.uploadDocumentService.deleteDocumentById(id).subscribe(response => {
      this.getDocuments();
    },
    error => {
      console.log('ERROR - cannot delete User Document: ' + error)
    });
  }

}
