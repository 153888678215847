import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private projectUpdateSubject = new BehaviorSubject({});
  private opportunitySubject = new BehaviorSubject({});
  private quoteSubject = new BehaviorSubject({});

  projectUpdate = this.projectUpdateSubject.asObservable();
  opportunityUpdate = this.opportunitySubject.asObservable();
  quoteUpdate = this.quoteSubject.asObservable();

  constructor() {}

  updatedProject(data: any) {
    this.projectUpdateSubject.next(data);
  }

  updatedOpportunity(data: any) {
    this.opportunitySubject.next(data);
  }
  updatedQuote(data: any) {
    this.quoteSubject.next(data);
  }
}
