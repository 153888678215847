<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-12">
                            <div class="d-flex align-items-center"> 
                                <div class="edit">
                                    <button title="back page" (click)="backToExit()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <div class="title col-sm-6">
                                    <h3> Exit Process</h3>
                                </div>
                                <div *ngIf="this.isEmployeeFormVisible" class="col-sm-6 d-flex justify-content-end" style="display: flex; justify-content: flex-end; align-items: center;">
                                    <div>
                                        <button title="Create" type="button" (click)="ExitEmployeeForm()" class="btn">
                                            Employee Exit Forms
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6 d-flex" *ngIf="this.isVisible">
                            <h3><a class="active cursor-pointer">User</a></h3>
                            <h3><a (click)="openFinanceExit()" class="cursor-pointer">Finance</a></h3>
                            <h3><a (click)="openItHrmExit()" class="cursor-pointer">IT & HRM</a></h3>
                        </div>
                        <div class="title col-sm-6 d-flex" *ngIf="!this.isVisible">
                            <h3><a class="active cursor-pointer">User</a></h3>
                        </div>
                    </div>
                    <div class="canvas-data">
                        <div class="row">
                            <div class="col-sm-12">
                                <h5>Personal Information</h5>
                            </div>
                        </div>
                        <div class="row">
                            <form [formGroup]="exitProcessForm">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="text" formControlName="localId">
                                                <label class="floating-label-special">Employee Id</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="text" formControlName="userId">
                                                <label class="floating-label-special">Full Name</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="date" formControlName="joiningDate">
                                                <label class="floating-label-special">Joining Date</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="text" formControlName="currentDesignation">
                                                <label class="floating-label-special">Designation</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="date" formControlName="resignationDate">
                                                <label class="floating-label-special">Resignation Date</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <input type="text" formControlName="department">
                                                <label class="floating-label-special">Department</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item" *ngIf="this.isDisabled">
                                                <input type="date" formControlName="lastWorkingDate" disabled>
                                                <label class="floating-label-special">Last Working Date</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                            <div class="input-item" *ngIf="!this.isDisabled">
                                                <input type="date" formControlName="lastWorkingDate">
                                                <label class="floating-label-special">Last Working Date</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                            <!-- <div class="input-item">
                                                <input type="date" formControlName="lastWorkingDate">
                                                <label class="floating-label-special">Releaving Date</label>
                                                <i class="ri-user-line"></i>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="input">
                                            <div class="input-item">
                                                <select formControlName="status">
                                                    <option value="" disabled selected>Select Status</option>
                                                    <option value="Accepted" disabled>Accepted</option>
                                                    <option value="Submitted">Submitted</option>
                                                    <option value="Rejected">Rejected</option>
                                                </select>
                                                <label class="floating-label-special">Status</label>
                                                <i class="ri-user-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 d-flex justify-content-between align-items-center">
                                        <button class="btn-secondary btn" (click)="backToExit()">Cancel</button>
                                        <button type="button" class="btn" (click)="updateUserSeperation()">Save</button>
                                    </div>
                                </div>
                            </form>
                            
                            <div class="col-sm-12">
                                <p>CLEARANCE FROM HR/ACCOUNTS/FINANCE</p>
                            </div>
                            <div class="col-sm-10 inner-details">
                                <table class="table">
                                    <thead class="h-90" style="border: 0; border-radius: 8px">
                                      <tr>
                                        <th>List of Activities</th>
                                        <th>Status</th>
                                        <th>Action With Team</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of userClearanceData">
                                            <td>
                                              {{ item.itemName }}
                                            </td>
                                            <td>
                                              {{ item.status }}
                                            </td>
                                            <td>
                                              {{ item.clearanceType }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    </article>
</main>
