import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { Observable, throwError } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { environment } from "../../../environments/environment";
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalsettingService extends Subject<DataStateChangeEventArgs> {

  constructor(
    private http: HttpClient
  ) { 
    super();
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }


  // Create GlobalSetting 
  createGlobalSetting(globalSetting: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/globalSettings`, globalSetting).pipe(catchError(this.handleError));
  }

  //Delete GlobalSetting by Id Delete
  deleteGlobalSetting(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/globalSettings/${id}`).pipe(catchError(this.handleError));
  }

  //Update GlobalSetting by Id Put
  updateGlobalSetting(globalSetting: Object, id: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/globalSettings/${id}`, globalSetting).pipe(catchError(this.handleError));
  }

  //Get All GlobalSetting
  getAllGlobalSetting(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/globalSettings`).pipe(catchError(this.handleError));
  }

  //Get All GlobalSetting
  getGlobalSettingById(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/globalSettings/${id}`).pipe(catchError(this.handleError));
  }

  //Get All GlobalSetting by Name
  getGlobalSettingByName(globalSettingName: String): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/globalSettings?globalSettingName=${globalSettingName}`).pipe(catchError(this.handleError));
  }
}
