import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { HelpdeskService } from 'src/app/_services/helpdesk/helpdesk.service';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';

@Component({
  selector: 'app-helpdesk',
  templateUrl: './helpdesk.component.html',
  styleUrls: ['./helpdesk.component.scss']
})
export class HelpdeskComponent implements OnInit {
  helpdeskForm
  public columnDefs: ColDef[]; 
   modeAction:number = 1 //1 - create // 2 - update
   // gridApi and columnApi  
   private api: GridApi;  
   private columnApi: ColumnApi;  
  defaultColDef:any;
  rowSelection:any
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  modalService: any;
  currentUser: any;
  jsonData: any;
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'
  rowData: any;
  selectedFile: File;
  url: string;
  imageid: any;
  hdattachment: Object;
  userDropdownList = [];



  constructor(
    private storageService: LocalStorageService,
    private helpdeskService: HelpdeskService,
    private formBuilder: FormBuilder,
    private allocationService: AllocationService,
     private router: Router
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });

     this.helpdeskForm = this.formBuilder.group({
      id : new FormControl(''),
      ticketNo : new FormControl(''),
      title :  new FormControl(''),
      userId : new FormControl(''),
      userName :  new FormControl(''),
      domainUserId : new FormControl(''),
      domainUserName : new FormControl(''), 
      assetType :  new FormControl(''),
      assetNo :  new FormControl(''),
      receivedDatetime : new FormControl(''),
      reportedById : new FormControl(''),
      reportedByName : new FormControl(''), 
      reportedToId : new FormControl(''),
      reportedToName :  new FormControl(''),
      diagnosedById : new FormControl(''),
      diagnosedByName :  new FormControl(''),
      actionTakenDetails :  new FormControl(''),
      vendorCaseId :  new FormControl(''),
      remarks :  new FormControl(''),
      learnings :  new FormControl(''),
      trainingRequirements :  new FormControl(''),
      costCalculatedById : new FormControl(''),
      costCalculatedByName :  new FormControl(''),
      impactCost : new FormControl(''),
      finalStatus :  new FormControl(''),
      closureDateTime : new FormControl(''),
      callLoggedByVendor : new FormControl(''),
     })
   }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.tenantId = this.storageService.get('tenantId');
    console.log("tenantId: ",  this.tenantId);
    console.log("currentUser: ",  this.currentUser);
    this.getHelpdesk();
    this.getUserList();
    // this.overtimeSer
  }

  getHelpdesk(){
    this.helpdeskService.getAllIncidents().subscribe((response: any) => {
      this.rowData = response.result;
      console.log("response: ", response);
       
    });
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }  

   // create column definitions  
   private createColumnDefs() {
    return [
    {
      headerName: 'Ticket No',
      field: 'ticketNo',
      filter: true,
      editable: false,
      sortable: true,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
    },
    {
      headerName: 'Title',
      field: 'title',
      filter: true,
      editable: false,
      sortable: true,
    },
    {
      headerName: 'Assign To',
      field: 'userName',
      filter: true,
      editable: false,
      sortable: true,
      
    },
    {
      headerName: 'Reported By',
      field: 'reportedByName',
      filter: true,
      editable: false,
      sortable: true,
      
    },
    {
      headerName: 'User Id',
      field: 'userId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
      
    },
    {
      headerName: 'Asset Type',
      field: 'assetType',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Domain User Name',
      field: 'domainUserName',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Domain User Id',
      field: 'domainUserId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Asset No',
      field: 'assetNo',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Received Datetime',
      field: 'receivedDatetime',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Reported By Name',
      field: 'reportedByName',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Reporte To Id',
      field: 'reportedToId',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Diagnosed By Id',
      field: 'diagnosedById',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Diagnosed By Name',
      field: 'diagnosedByName',
      filter: true,
      editable: false,
      sortable: true,
      hide: true,
    },
    {
      headerName: 'Status',
      field: 'finalStatus',
       //autoSizeColumns: true,
       maxWidth: 150,
       cellStyle: (params) => {
         if (params.value === 'New' || params.value === 'new') {
           return { color: 'red', 'font-weight': 'bold' };
         } else if (params.value === 'In Progress' || params.value === 'in progress') {
           return { color: 'orange', 'font-weight': 'bold' };
         } else if (params.value === 'Closed' || params.value === 'closed') {
           return { color: 'green', 'font-weight': 'bold' };
         } else if (params.value === 'On Hold' || params.value === 'on hold') {
          return { color: 'grey', 'font-weight': 'bold' };
        } else if (params.value === 'Spam' || params.value === 'spam') {
          return { color: 'black', 'font-weight': 'bold' };
        } 
         else if (
           params.value === 'Withdraw' ||
           params.value === 'withdraw'
         ) {
           return { color: 'grey', 'font-weight': 'bold' };
         }
         return null;
       },
      filter: true,
      editable: false,
      sortable: true,
      hide: false,
    },
  ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  createHelpdesk(){
    let details = JSON.stringify(this.helpdeskForm.value);
    // let id = this.contactForm.value.id;
    this.helpdeskService.createIncidents(details).subscribe((response: any) => {
       console.log("response: ", response);
      // update grid after creating new lead & close the modal
      this.api.applyTransaction({ add: [response] });
      $('#closeCreateModal').trigger("click");
    });
  }

  updateHelpdeskApp() {
    let selectedNodes = this.api.getSelectedNodes();
    let selectedData = selectedNodes.map((node) => node.data);
    let editId = selectedNodes.map((node) => node.data.id);

    this.router.navigate([
      this.tenantId + '/dashboard/itm/helpdeskapplication',
      { id: this.selectedCheckboxData[0].id },
    ]);
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }     
  }

  private deleteGridRow(params: any) {
    this.helpdeskService.deleteIncidents(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }


  editOpenModal() {
    this.modeAction = 2;
    this.helpdeskForm.patchValue(this.selectedCheckboxData[0])
  }

  openHelpdeskApp() {
    // location.href = "{this.tenantId}/dashboard/hr/leaves/leave-application";
    this.router.navigate([this.tenantId + '/dashboard/itm/helpdeskapplication']);
  }

  createOpenModal() {
    this.modeAction = 1;
    this.helpdeskForm.patchValue({
      id : '',
      ticketNo : '',
      title :  '',
      userId : '',
      userName :  '',
      domainUserId : '',
      domainUserName : '', 
      assetType :  '',
      assetNo :  '',
      receivedDatetime : '',
      reportedById : '',
      reportedByName : '', 
      reportedToId : '',
      reportedToName :  '',
      diagnosedById : '',
      diagnosedByName :  '',
      actionTakenDetails :  '',
      vendorCaseId :  '',
      remarks :  '',
      learnings :  '',
      trainingRequirements :  '',
      costCalculatedById : '',
      costCalculatedByName :  '',
      impactCost : '',
      finalStatus :  '',
      closureDateTime : '',
      callLoggedByVendor : '',
    })
  }

  updateHelpdesk() {
    this.helpdeskService.updateIncidents(this.helpdeskForm.value, this.selectedCheckboxData[0].id).subscribe(res => {
      this.getHelpdesk()
      this.selectedCheckboxData = []
      $('#closeCreateModal').trigger("click")
       
    })
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete'
    for (let i in this.selectedCheckboxData) {
      if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].ticketNo}`;
      else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].ticketNo}`;
      else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].ticketNo}`;
    }
  }


  deleteHelpdesk() {
    this.helpdeskService.deleteIncidents(this.selectedCheckboxData[0].id).subscribe(() => {
    this.getHelpdesk()
    this.selectedCheckboxData = []
    $('#closeModalDeleteOvertime').trigger("click");
  });
}

  // to open delete modal
  open(item) {
    this.selectedRow = item;
    
    }


     // to open edit modal
  openEdit(item) {
    this.selectedRow = item;
    this.gethdApplicationsattachments(this.selectedCheckboxData[0].id);
    this.helpdeskForm.patchValue(item);
     
   }

    // getter for easy access to form fields
  get f() {
    return this.helpdeskForm.controls;
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      for (let item of response) {
        this.userDropdownList.push(` ${item.firstName} ${item.lastName}`);
      }
    });
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    console.log('in onFileChanged, selectedFile: ', this.selectedFile);
    this.onUpload();
  }

  //Gets called when the user clicks on submit to upload the image
  onUpload() {
    console.log('in onUpload, selectedFile: ', this.selectedFile);
    console.log('in onUpload, selectedFile: ', this.selectedFile.name);

    // get logged in user's details
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    console.log("current user's id: ", this.currentUser.id);

    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();

    uploadImageData.append('file', this.selectedFile, this.selectedFile.name);
    console.log('uploadImageData: ', uploadImageData);

    //Make a call to the Spring Boot Application to save the image
    this.helpdeskService.uploadImage(
      Number(this.selectedCheckboxData[0].id),
      uploadImageData
    ).subscribe((response: any) => {
      console.log('upload image response: ', response);
      if (response) {
        // this.message = 'Image uploaded successfully';
        window.alert('Image uploaded successfully');
        window.location.reload();

      } else {
        // this.message = 'Image not uploaded successfully';
       // window.alert('Image upload NOT successfull');
       window.location.reload();

      }
    });

    setTimeout(() => {
      this.gethdApplicationsattachments(this.selectedCheckboxData[0].id);
    }, 400);
  }

  gethdApplicationsattachments(id) {
    //console.log('I AM IN GET getUserSlApplicationDetailsById ');

    this.helpdeskService.gethdApplicationsattachments(id).subscribe(
      (response) => {
        console.log('gethdApplicationsattachments: ', response);
        this.hdattachment = response;
        console.log('gethdApplicationsattachments: ', this.hdattachment);
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }

  deletehdApplicationsattachments(value) {
    console.log('I AM IN deletehdApplicationsattachments ', value);

    this.imageid = value.split(': ')[0];

    this.helpdeskService.deletehdApplicationsattachments(
      this.selectedCheckboxData[0].id,
      this.imageid
    ).subscribe(
      (response) => {
        console.log('deletehdApplicationsattachments: ', response);
        window.alert('Delete Successful');
       // window.location.reload();
       this.gethdApplicationsattachments(this.selectedCheckboxData[0].id)
      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
  }

  downloadhdApplicationsattachments(value) {
    console.log('I AM IN downloadslApplicationsattachments ', value);

    this.imageid = value.split(': ')[0];

    this.url = environment.apiUrl + '/incidents/' + this.selectedCheckboxData[0].id + '/attachments/' + this.imageid;
    console.log('downloadhdApplicationsattachments url: ', this.url);

    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };

    window.open(this.url, "_blank");

    
    this.helpdeskService.downloadhdApplicationsattachments(
      this.selectedCheckboxData[0].id,
      this.imageid
    ).subscribe(
      (response) => {
       // console.log('downloadleaveApplicationsattachments: ', this.url);
       this.url = environment.apiUrl + '/incidents/' + this.selectedCheckboxData[0].id + '/attachments/' + this.imageid;
       //window.open(this.url, "_blank");

        window.alert('Download Successful');
        console.log('downloadhdApplicationsattachments in ts: ', response);


        //window.location.reload();
       // this.retrievedImage = 'data:image/jpeg;base64,' + ;
       // console.log('this.retrievedImage downloadslApplicationsattachments: ', this.retrievedImage);

      },
      (error) => {
        //console.log('ERROR: cannot get project by id: ' + error);
      }
    );
    
  }

}
