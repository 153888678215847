import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ExitProcessService extends Subject<DataStateChangeEventArgs>  {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend,
     private http: HttpClient, 
     private storageService: LocalStorageService,
     ) { 
    super();
    this.httpClient = new HttpClient(handler);
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
  return throwError(errorMessage);
  }

  //Create Resignation
  createUserSeparations(userDetails: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/userSeparations`, userDetails).pipe(catchError(this.handleError));
  }

  //Edit Resignation
  editUserSeparations(userDetails: Object, resignationId: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/userSeparations/${resignationId}`, userDetails).pipe(catchError(this.handleError));
  }

  //Delete Resignation
  deleteUserSeparations(resignationId: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/userSeparations/${resignationId}`).pipe(catchError(this.handleError));
  }

  //Get Resignation By Id
  getUserSeparationsById(resignationId: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userSeparations/${resignationId}`).pipe(catchError(this.handleError));
  }

  //Get Resignation By userId
  getUserSeparationsByUserId(userId: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userSeparations?userId=${userId}`).pipe(catchError(this.handleError));
  }

  //Get All Resignation
  getAllUserSeparations(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/userSeparations`).pipe(catchError(this.handleError));
  }

  //Create Employee Exit Form
  createEmployeeExitForm(userId: number, userDetails: object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/exitForms?userId=${userId}`, userDetails).pipe(catchError(this.handleError));
  }

  //Edit Employee Exit Form
  editEmployeeExitForm(userDetails: Object, exitFormID: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/exitForms/${exitFormID}`, userDetails).pipe(catchError(this.handleError));
  }

  //Get Employee Exit Form
  getEmployeeExitForm(userId: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/exitForms?userId=${userId}`).pipe(catchError(this.handleError));
  }

  //Create Employee Interview Form
  createEmployeeInterviewForm(userDetails: Object, userId: number): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/exitInterviewForms?userId=${userId}`, userDetails).pipe(catchError(this.handleError));
  }

  //Edit Employee Interview Form
  editEmployeeInterviewForm(userDetails: Object, exitInterviewFormID: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/exitInterviewForms/${exitInterviewFormID}`, userDetails).pipe(catchError(this.handleError));
  }

  //Get Employee Interview Form
  getEmployeeInterviewForm(userId: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/exitInterviewForms?userId=${userId}`).pipe(catchError(this.handleError));
  }

  //Create Exit Clearance Interview Form
  createClearanceInterviewForm(userDetails: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/exitProcessClearanceForms`, userDetails).pipe(catchError(this.handleError));
  }

  //Edit Exit Clearance Interview Form
  editClearanceInterviewForm(userDetails: Object, clearanceFormId: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/exitProcessClearanceForms/${clearanceFormId}`, userDetails).pipe(catchError(this.handleError));
  }

  //delete Exit Clearance Interview Form
  deleteClearanceInterviewForm(clearanceFormId: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/exitProcessClearanceForms/${clearanceFormId}`).pipe(catchError(this.handleError));
  }

  //Get Exit Clearance Interview Form
  getClearanceInterviewForm(clearanceType: String, userId: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/exitProcessClearanceForms?userId=${userId}&clearanceType=${clearanceType}`).pipe(catchError(this.handleError));
  }

  //Get Exit Clearance Details For User Form
  getAllUserClearanceByUserId(userId: number): Observable<Object>   {
    return this.http.get(`${environment.apiUrl}/exitProcessClearanceForms?userId=${userId}`).pipe(catchError(this.handleError));
  }

}
