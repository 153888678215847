import { Injectable } from '@angular/core';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class OvertimeService extends Subject<DataStateChangeEventArgs> {
  private httpClient: HttpClient;
  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) { 
    super();
    this.httpClient = new HttpClient(handler);
    
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //  window.alert(errorMessage);
  console.log("Error:",errorMessage);
  return throwError(errorMessage);
  }

  // Create Timesheet
  createOvertime(data: Object): Observable<Object> {
    console.log("In createOvertime method, Overtime: ", data);
    return this.http.post(`${environment.apiUrl}/overtimes`, data).pipe(catchError(this.handleError));
  }

    // Get Overtime by Id Get
    getOvertime(id: number): Observable<Object> {
      return this.http.get(`${environment.apiUrl}/overtimes/${id}`).pipe(catchError(this.handleError));
    }

    //Delete Overtime by Id Delete
    deleteOvertime(id: number): Observable<Object> {
    return this.http.delete(`${environment.apiUrl}/overtimes/${id}`).pipe(catchError(this.handleError));
    }

     //Update Overtime by Id Put
    updateOvertime(data: Object,id: number): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/overtimes/${id}`, data).pipe(catchError(this.handleError));
    }

    //Get All overtime 
    getAllOvertime(): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/overtimes`).pipe(catchError(this.handleError));
    }
}
