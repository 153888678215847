<!--
 // Client ID and API key from the Developer Console
    //var CLIENT_ID = '322881771017-jpsj7mt6l8cqeorqpkd700f32p31bcgg.apps.googleusercontent.com';
  //  var API_KEY = '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';
  (click)=uploadFileToGoogleDrive()
(click)=initGoogleDriveAuthentication()
  -->
<main class="main-dashboard">
  <article class="main-canvas">
    <section class="canvas-container">
      <section class="dashboard-items">
        <div class="canvas-item">


          <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.2.3/jquery.min.js"></script>
          <div class="canvas-header row justify-content-between align-items-center">
            <div class="title col-sm-6">
              <h2>Google Drive File Manager</h2>
            </div>
          </div>
          <br />
          <div id="file-manager">
            <p>Step 1. Click on the button to autorize with the Google Drive</p>
            <br />
            <p>Step 2. Upload the document (PDF) throgh the UI to the google drive account</p>
            <br />
            <p>Step 3.List all the files from the google drive</p>
            <!--<button onclick="initCall123()" id="login">
              Upload Files to Drive
            </button>-->
            <br />
            <br />
            <!--<ejs-filemanager id='file-manager' [ajaxSettings]='ajaxSettings' [enablePersistence]='enablePersistence' (success)='onAjaxSuccess($event)' (failure)='onAjaxFailure($event)'>
            </ejs-filemanager>-->

            <br />
            <br />
            <div *ngIf="this.isCodeValue">
              <button class="btn btn-big" (click)=initGoogleDriveAuthentication()>Authentication</button>

            </div>
            <div *ngIf="this.isCodeValue == false">
              <br />
              <br />
              <form [formGroup]="myForm" (ngSubmit)="submit()">
                <div class="form-group">
                  <br />
                  <label for="file">Upload File</label>
                  <br />
                  <input multiple formControlName="file"
                         id="file"
                         type="file"
                         class="form-control"
                         (change)="onFileChange($event)">
                  <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                    <div *ngIf="f.file.errors.required">File is required.</div>
                  </div>
                  <div *ngIf="this.isFileUploaded">
                    <p style="font-size:20px;color:green;">File Uploaded</p>
                  </div>
                  <!--<div id="progress-wrp">
                    <div class="progress-bar"></div>
                    <div class="status">0%</div>
                  </div>-->
                </div>

                <!--<img [src]="imageSrc" *ngIf="imageSrc" style="height: 300px; width:500px">-->
                <br />
                pr
                <button class="btn btn-primary" type="submit">Submit</button>
              </form>
            </div>



            <!--<div class="file-upload">
              {{fileName || "No file uploaded yet."}}
              <input id="files" type="file" name="files" multiple />
              <button id="upload" class="btn btn-big">Upload</button>
              <div id="progress-wrp">
                <div class="progress-bar"></div>
                <div class="status">0%</div>
              </div>
            </div>
            <div id="result">

            </div>-->
            <br />
            <br />
            <div>
              <h4>
                Basic Pagination
              </h4>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Owner</th>
                    <th scope="col">Last Modified</th>
                    <th scope="col">File Size</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of collection.data | paginate: config;  index as i">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.id}}</td>
                    <td *ngIf="item.mimeType == 'image/jpeg'">
                      <i class="fa fa-image" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'image/png'">
                      <i class="fa fa-image" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'image/heif'">
                      <i class="fa fa-image" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'application/pdf'">
                      <i class="fa fa-file" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'application/vnd.google-apps.spreadsheet'">
                      <i class="fa fa-file" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'application/vnd.google-apps.document'">
                      <i class="fa fa-file" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'video/mp4'">
                      <i class="fa fa-film" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td *ngIf="item.mimeType == 'application/rar'">
                      <i class="fa fa-file-archive" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>


                    <td *ngIf="item.mimeType == 'application/vnd.google-apps.folder'">
                      <i class="fa fa-folder" style="font-size:22px;padding-right:5px;color:red;"></i>  {{item.name}}
                    </td>
                    <td>{{item.mimeType}}</td>
                    <td>
                      <!--<button class="btn btn-primary" type="button" ng-click="edit(record)">edit</button>-->
                      <!--<button class="btn btn-danger" type="button" (click)="deleteFromGoogleDrive(file.id)">Delete</button>-->
                      <button type="button" data-bs-toggle="modal" data-bs-target="#delete-items" (click)="deleteFromGoogleDrive(file.id)">
                        <span><i class="ri-delete-bin-5-line"></i></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>

            </div>















            <!--<div>
            <h4>List of Google Drive Files</h4>

            <tbody>
              <tr *ngFor="let file of  this.listGoogleDriveData">

                <td>{{file.id}}</td>
                <td>{{file.name}}</td>
                <td>{{file.mimeType}}</td>
                <td>-->
            <!--<button class="btn btn-primary" type="button" ng-click="edit(record)">edit</button>-->
            <!--<button class="btn btn-danger" type="button" (click)="deleteFromGoogleDrive(file.id)">Delete</button>
                </td>
              </tr>
            </tbody>-->
            <!--<div *ngFor="let file of  this.listGoogleDriveData">
            <p>{{file.name}}</p>-->
            <!-- this works fine-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>

      </section>

    </section>


  </article>
</main>
