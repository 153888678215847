import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';

@Component({
  selector: 'app-salary-distribution-form',
  templateUrl: './salary-distribution-form.component.html',
  styleUrls: ['./salary-distribution-form.component.scss']
})
export class SalaryDistributionFormComponent implements OnInit {
 
  salaryDistributionForm: FormGroup;
  date:string;
  componentName:string;
  componentValue:number;
  percent:boolean;
  deduction:boolean;
  employeeId: number;
  employeeName: string;
  submitted=false;
  salaryId:string;
  salaryupdateId: any;
  userDropdownList = [];
  getUserName: any;
  tenantId;
  userDetails;
  month: any;
  monthlyUpperLimit: any;
  displayOrder: number;
  percentComparedToComponent: any;
  absoluteLimitWithinDistribution: any;
  gender: any;
  list: any;
  regime: any;
  componentsList= [];
  comparedToComponentRange: any;

  constructor(
    private formBuilder: FormBuilder,
    private hrService:HrService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: LocalStorageService,
    private allocationService: AllocationService,
    ) { }

  ngOnInit(): void {
      this.tenantId = this.storageService.get('tenantId');
      this.userDetails = JSON.parse(this.storageService.get('currentUser'));
      this.salaryId = this.route.snapshot.paramMap.get('id');

      this.salaryDistributionForm = this.formBuilder.group({
      date: new FormControl('',Validators.required),
      componentname: new FormControl('',Validators.required),
      componentvalue: new FormControl('',Validators.required),
      percent: new FormControl('',Validators.required),
      deduction: new FormControl('',Validators.required),
      employeeId: new FormControl(''),
      financialYear: new FormControl(''),
      employeeName: new FormControl(''),
      monthlyUpperLimit: new FormControl(''),
      displayOrder: new FormControl('', Validators.pattern('^[0-9 ]*$') ),
      month: new FormControl('',Validators.required),
      percentComparedToComponent: new FormControl(''),
      absoluteLimitWithinDistribution: new FormControl(''),
      gender: new FormControl(''),
      comparedToComponentRange: new FormControl(''),
      regime: new FormControl(''),
    
    });

    this.getUserList();
    this.fetchList();
    
    if (this.salaryId) {
      this.getSalaryApplicationDetailsById(this.salaryId);
    }
  }

  get f() {
    return this.salaryDistributionForm.controls;
  }

  fetchList()
  {
    this.componentsList = [];
    this.hrService.fetchList().subscribe(data=>{
      this.list = data.result;
      for(let item of this.list) {
        this.componentsList.push(
          `${item.componentName}`
        );
      }
    })
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getSalaryApplicationDetailsById(id) {
        this.hrService.getSalaryApplicationDetailsById(id).subscribe(
          (response) => {
            if (response) {
              this.salaryDistributionForm.patchValue({
                date: response.financialYear,
                componentname: response.componentName,
                componentvalue: response.componentValue,
                percent: response.percent,
                deduction: response.deduction,
                employeeId: response.employeeId?response.employeeId + ' . ' + response.employeeName : undefined,
                month: response.month,
                monthlyUpperLimit: response.monthlyUpperLimit,
                absoluteLimitWithinDistribution: response.absoluteLimitWithinDistribution,
                displayOrder: response.displayOrder,
                percentComparedToComponent: response.percentComparedToComponent,
                gender: response.gender,
                regime: response.regime,
                comparedToComponentRange: response.comparedToComponentRange,
              });
              if(response.month === null){
                this.salaryDistributionForm.value.month = 'All';
            
              }
              if(response.regime === null){
                this.salaryDistributionForm.value.regime = 'Both';
               
              }
              this.salaryDistributionForm.patchValue(this.salaryDistributionForm.value);
              this.salaryupdateId = response.id;
            }
          },
          (error) => {
            
          }
        );
  }

  salaryform(salaryDistributionForm)
  {
    this.submitted = true;
    const data = this.salaryDistributionForm.value;
    this.date = data.date;
    this.componentName = data.componentname;
    this.componentValue = data.componentvalue;
    this.percent = data.percent;
    this.deduction = data.deduction;
    this.employeeId = data.employeeId.split(' . ')[0];
    this.employeeName = data.employeeId.split(' . ')[1];
    this.monthlyUpperLimit = data.monthlyUpperLimit;
    this.displayOrder= data.displayOrder;
    this.percentComparedToComponent= data.percentComparedToComponent;
    this.absoluteLimitWithinDistribution= data.absoluteLimitWithinDistribution;
    this.gender= data.gender;
    this.regime = data.regime;
    this.comparedToComponentRange = data.comparedToComponentRange;

    if(data.month === "All"){
      this.month = null ;
    }
    else{
      this.month = data.month;
    }

    if(data.regime === "Both"){
      this.regime = null ;
    }
    else{
      this.regime = data.regime;
    }

    const reqbody={
      "financialYear": this.date,
      "componentName": this.componentName,
      "componentValue": this.componentValue,
      "percent": this.percent,
      "deduction": this.deduction,
      "employeeId": this.employeeId,
      "employeeName": this.employeeName,
      "month": this.month,
      "monthlyUpperLimit": this.monthlyUpperLimit,
      "displayOrder": this.displayOrder,
      "percentComparedToComponent": this.percentComparedToComponent,
      "absoluteLimitWithinDistribution": this.absoluteLimitWithinDistribution,
      "gender": this.gender,
      "regime": this.regime,
      "comparedToComponentRange": this.comparedToComponentRange,
    }

    this.hrService.createSalary(reqbody).subscribe(data =>{ 
      this.salaryDistributionForm.reset();
      this.backToMySalaryDistribution();
    }),(error) =>
    {
     
    }
  }

  updateSalaryDetailsbyId()
{
  let checkMonth : any;
  let checkRegime: any;
  if(this.salaryDistributionForm.get('month').value === "All"){
    checkMonth = null ;
  }
  else{
    checkMonth = this.salaryDistributionForm.get('month').value;
  }

  if(this.salaryDistributionForm.get('regime').value === "Both"){
    checkRegime = null ;
  }
  else{
    checkRegime = this.salaryDistributionForm.get('regime').value;
  }

  var salaryupdatedetailsdata = {
    financialYear: this.salaryDistributionForm.get('date').value,
    componentName: this.salaryDistributionForm.get('componentname').value,
    componentValue: this.salaryDistributionForm.get('componentvalue').value,
    percent: this.salaryDistributionForm.get('percent').value,
    deduction: this.salaryDistributionForm.get('deduction').value,
    employeeId: this.salaryDistributionForm.get('employeeId').value?.split(' . ')[0],
    employeeName: this.salaryDistributionForm.get('employeeId').value?.split(' . ')[1],
    month: checkMonth,
    monthlyUpperLimit: this.salaryDistributionForm.get('monthlyUpperLimit').value,
    displayOrder: this.salaryDistributionForm.get('displayOrder').value,
    percentComparedToComponent: this.salaryDistributionForm.get('percentComparedToComponent').value,
    absoluteLimitWithinDistribution: this.salaryDistributionForm.get('absoluteLimitWithinDistribution').value,
    gender: this.salaryDistributionForm.get('gender').value,
    regime: checkRegime,
    comparedToComponentRange:this.salaryDistributionForm.get('comparedToComponentRange').value,

  };

    this.hrService.updateSalaryDetailsbyId(
      salaryupdatedetailsdata,
      this.salaryupdateId
    ).subscribe((response: any) => {
      this.clearForm();
      this.backToMySalaryDistribution()
    }),(error) =>
    {
     
    };

}
  
  clearForm()
  {
    this.salaryDistributionForm.reset();
  }

  backToMySalaryDistribution() {
    this.router.navigate([this.tenantId + '/dashboard/finance/salary-distribustion']);
  }
}
