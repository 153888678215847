

<section class="auth-sidebar forgot-auth-1" id="forgot-auth-1">

  <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmitEmailTenant(firstFormGroup)" novalidate class="form-horizontal">
    <div class="row m-0 align-items-center">
      <div class="col-md-12 col-lg-5 p-0 col-sm-12 col-xl-3">
        <div class="login-box">
          <img src="assets/logo.png">
          <h3>Forgot Password</h3>
          <div class="login-form mt-5">
            <div class="password-process">
              <div class="process stage-1">
                <div class="circle">
                  <img src="assets/svg/stage-1.svg" alt="">
                  <span class="active">1/</span>
                  <span>3</span>
                </div>
                <div class="process-stage">
                  <p>Enter an email/tenant</p>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input matInput formControlName="email" type="email" name="email" id="email" class="form-control" required>
                <label class="floating-label">Email ID</label>
                <i class="ri-mail-unread-line"></i>
                <mat-error *ngIf="f.email.touched && f.email.invalid" class=" alert alert-danger">
                  <mat-error *ngIf="f.email.errors.required">Email Id is required</mat-error>
                  <mat-error *ngIf="f.email.errors.email">Email must be a valid email</mat-error>
                  <mat-error *ngIf="f.email.errors.pattern">Email must be a valid email</mat-error>
                </mat-error>
              </div>

            </div>
            <div class="input">
              <div class="input-item">
                <input matInput formControlName="tenantId" name="tenantId" id="tenantId" type="text" required>
                <label class="floating-label">Tenant ID</label>
                <i class="ri-shield-user-line"></i>
                <mat-error *ngIf="f.tenantId.touched && f.tenantId.invalid" class="alert alert-danger">
                  <mat-error *ngIf="f.tenantId.errors.required">Tenant Id is required</mat-error>
                </mat-error>
              </div>
            </div>
            <div class="input text-center">
              <span id="display-error1" class="d-none">{{displayError}}</span>
              <!--<span>Verification code is sent to your register <br> email address</span>-->
            </div>
            <div class="input">
              <button class="btn btn-big forgot-step-1" submit>Next</button>
            </div>
            <div class="input">
              <button class="btn btn-big forgot-step-1" (click)="onCancel()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-7 col-xl-9 d-none d-lg-block">
        <div class="illustraton-box text-center">
          <img src="assets/svg/forgotpassword.svg">
          <h4>Business process improvement</h4>
        </div>
      </div>
    </div>
  </form>
</section>

<section class="auth-sidebar d-none forgot-auth-2" id="forgot-auth-2">
  <form [formGroup]="secondFormGroup" (ngSubmit)="onSubmitCode(secondFormGroup)" novalidate class="form-horizontal">
    <div class="row m-0 align-items-center">
      <div class="col-md-12 col-lg-5 p-0 col-sm-12 col-xl-3">
        <div class="login-box">
          <img src="assets/logo.png">
          <h3>Forgot Password</h3>
          <div class="login-form mt-5">
            <div class="password-process">
              <div class="process stage-2">
                <div class="circle">
                  <img src="assets/svg/stage-2.svg" alt="">
                  <span class="active">2/</span>
                  <span>3</span>
                </div>
                <div class="process-stage">
                  <p>Enter verification code</p>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input matInput formControlName="otp" type="text" required>
                <label class="floating-label">Enter Token shared over email</label>
                <i class="ri-hashtag"></i>
              </div>
              <mat-error *ngIf="fa.otp.touched && fa.otp.invalid" class=" alert alert-danger">
                <mat-error *ngIf="fa.otp.errors.required">OTP Id is required</mat-error>
              </mat-error>
            </div>

            <div class="input text-center">
              <span id="display-error2" class="d-none">{{displayError}}</span>
              <!--<span>Verification code is sent to your register <br> email address</span>-->
            </div>
            <div class="input">
              <button class="btn btn-big forgot-step-2"  (click)=addTokenValidation() submit>Verify</button>
            </div>
            <div class="input">
              <button class="btn btn-big btn-outline forgot-back-1" (click)="onCancel()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-7 col-xl-9 d-none d-lg-block">
        <div class="illustraton-box text-center">
          <img src="assets/svg/forgotpassword.svg">
          <h4>Business process improvement</h4>
        </div>
      </div>
    </div>
  </form>
</section>

<section class="auth-sidebar d-none forgot-auth-3" id="forgot-auth-3">
  <form [formGroup]="thirdFormGroup">
    <div class="row m-0 align-items-center">
      <div class="col-md-12 col-lg-5 p-0 col-sm-12 col-xl-3">
        <div class="login-box">
          <img src="assets/logo.png">
          <h3>Forgot Password</h3>
          <div class="login-form mt-5">
            <div class="password-process">
              <div class="process stage-2">
                <div class="circle">
                  <img src="assets/svg/stage-3.svg" alt="">
                  <span class="active">3/</span>
                  <span>3</span>
                </div>
                <div class="process-stage">
                  <p>Enter new password</p>
                </div>
              </div>
            </div>
            <div class="input">
              <div class="input-item">
                <input matInput formControlName="email" class="form-control" type="email" value="{{emailStep3}}" required>
                <label class="floating-label">Email ID</label>
                <i class="ri-mail-unread-line"></i>
              </div>
              <mat-error *ngIf="fb.email.touched && fb.email.invalid" class=" alert alert-danger">
                <mat-error *ngIf="fb.email.errors.required">Email Id is required</mat-error>
                <mat-error *ngIf="fb.email.errors.email">Email must be a valid email</mat-error>
                <mat-error *ngIf="fb.email.errors.pattern">Email must be a valid email</mat-error>
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input matInput formControlName="newPassword" id="newPassword"
                       type="password" class="form-control" required (change)="onPasswordChange()">
                <label class="floating-label">New password</label>
                <i class="ri-lock-line"></i>
              </div>
              <mat-error *ngIf="fb.newPassword.touched && fb.newPassword.invalid" class="alert alert-danger">
                <mat-error *ngIf="fb.newPassword.errors.required">Password is required</mat-error>
                <mat-error *ngIf="fb.newPassword.errors.pattern">Password must have at least 8 chracters which contain at least one lowercase character, at least one uppercase character, at least one number and special character </mat-error>
              </mat-error>
            </div>
            <div class="input">
              <div class="input-item">
                <input matInput formControlName="confirmPassword" id="confirmPassword"
                       type="password" class="form-control" required (change)="onPasswordChange()">
                <label class="floating-label">Confirm password</label>
                <i class="ri-lock-line"></i>
              </div>
              <!-- <mat-error *ngIf="fb.confirmPassword.touched && fb.confirmPassword.invalid" class="alert alert-danger">
                <mat-error *ngIf="fb.confirmPassword.errors.required">Password is required</mat-error>
              </mat-error> -->
            </div>
            <div class="input text-center">
              <span *ngIf="confirmPassword.hasError('mismatch')" class="text-danger" style="color:red;">Password do not match.</span>
              <span id="display-error3" class="d-none ">{{displayError}}</span>
              <!--<span>Verification code is sent to your register <br> email address</span>-->
            </div>
            <div class="input">
              <button class="btn btn-big" (click)=submitForgotUpdatedPassword()>Change Password</button>
            </div>
            <div class="input">
              <button class="btn btn-big btn-outline forgot-back-2" (click)="onCancel()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8 col-lg-7 col-xl-9 d-none d-lg-block">
        <div class="illustraton-box text-center">
          <img src="assets/svg/forgotpassword.svg">
          <h4>Business process improvement</h4>
        </div>
      </div>
    </div>
  </form>
</section>
