<main class="main-dashboard">
    <article class="main-canvas">
         <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                     <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <h3> OnSite Employee List </h3>
                        </div>
                        <div class="col-sm-6" style="display: flex; justify-content: flex-end; align-items: center;">
                          <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                            <button type="button" title="Edit" (click)="editOpenModal()" data-bs-toggle="modal" data-bs-target="#createForm">
                                <span><i class="ri-pencil-line"></i></span>
                            </button>
                        </div>
                        <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                            <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteHoliday" (click)="deleteOpenModal()">
                                <span><i class="ri-delete-bin-5-line"></i></span>
                            </button>
                        </div> 
                          <div>
                                <button type="button"  data-bs-toggle="modal" data-bs-target="#createForm" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                     </div>
                     <div class="canvas-header row justify-content-between align-items-center">
                        <div class="row p-0">
                          <form [formGroup]="userDeputationsForm">
                          <div class="col-sm-12 p-0">
                            <div class="canvas-data px-0">
                              <div class="row m-0">
                                <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2" >
                                  <div class="input">
                                    <div class="input-item"> 
                                      <input
                                        list="taskOwnerDatalist"
                                        formControlName="employeeId"
                                        placeholder="Select Employee Name"
                                        value=""
                                        onfocus="this.value=''"
                                        onchange="this.blur();"
                                      />
                                      <datalist id="taskOwnerDatalist">
                                        <option
                                          [value]="item"
                                          *ngFor="let item of userDropdownList"
                                        ></option>
                                      </datalist>
                                      <label class="floating-label">Employee Name</label>
                                      <i class="ri-user-line"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                  <div class="input">
                                    <div class="input-item">
                                      <input
                                            type="date"
                                            formControlName="startDate"
                                          />
                                          <label class="floating-label-special">From</label>
                                          <i class="ri-calendar-line"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 mb-2">
                                  <div class="input">
                                    <div class="input-item">
                                      <input
                                            type="date"
                                            formControlName="endDate"
                                          />
                                          <label class="floating-label-special">To</label>
                                          <i class="ri-calendar-line"></i>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-3 col-md-6 col-lg-3 col-xl-3 text-end text-right">
                                  <div class="row justify-content-center">
                                    <div class="col-sm-6">
                                      <button
                                        type="button"
                                        class="btn"
                                        (click)=getuserDeputations()
                                      >
                                        <div>Submit</div>
                                      </button>
                                      <button type="button" data-bs-toggle="modal" data-bs-target="#createVisitor" id="confimationBtn" style="display: none;"></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </form>
                      </div>
                    </div> 
                     <ag-grid-angular
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
         </section>
    </article>
</main>


  <!-- Delete an item -->
  <div class="modal fade" id="deleteHoliday" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeDeleteModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteHoliday()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div> 

<!--Create an item -->
<div class="modal fade" id="createForm">
    <div class="modal-dialog">
      <div class="modal-content pl-5 pr-5">
        <form [formGroup]="userDeputationsForm">
          <div class="modal-header">
            <h5 class="modal-title">Create OnSite Info</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="input">
              <div class="input-item">
                <input list="taskOwnerDatalist" formControlName="userId" placeholder="User Namw"/>
                <datalist id="taskOwnerDatalist">
                  <option [value]="item" *ngFor="let item of userDropdownList"></option>
                </datalist>
                <label class="floating-label">User Name</label>
                <i class="ri-user-line"></i>
              </div>
            </div>
            <div class="input">
                <div class="input-item">
                    <input formControlName="deputatedAtCompanyName" type="text" required>
                    <label class="floating-label-special">Company Name</label>
                    <i class="ri-time-line"></i>
                </div>
            </div>
            <div class="input">
                <div class="input-item">
                    <input formControlName="jobRole" type="text" required>
                    <label class="floating-label-special">Job Role </label>
                    <i class="ri-time-line"></i>
                </div>
            </div>
            <div class="input">
                <div class="input-item">
                    <input formControlName="startDate" type="date" required>
                    <label class="floating-label-special">Start Date</label>
                    <i class="ri-time-line"></i>
                </div>
            </div>
            <div class="input">
                <div class="input-item">
                    <input formControlName="endDate" type="date" required>
                    <label class="floating-label-special">End Date</label>
                    <i class="ri-time-line"></i>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button id="closeCreateModal" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" *ngIf="modeAction === 1" data-bs-dismiss="modal" (click)="addUserDeputations()">Add</button>
            <button type="button" class="btn btn-primary" *ngIf="modeAction === 2" data-bs-dismiss="modal" (click)="editserDeputations()">Update</button>
        </div>
        </form>
      </div>
    </div>
  </div>
