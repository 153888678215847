import { Component, OnInit } from '@angular/core';
import { OpportunityService } from 'src/app/_services/opportunity/opportunity.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { DataService } from "../../../../_services/data-share/data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { LocalStorageService } from "../../../../_services/localStorage/local-storage.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AccountsService } from 'src/app/_services/accounts/accounts.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss']
})
export class OpportunityComponent implements OnInit {
  jsonData: any;
  opportunityForm: FormGroup;
  tenantId: string;
  selectedRow: any;
  accountDropdownList = [];
  selectedAccountId: any;
  createdopportunityid: any;

  constructor(
    private opportunityService: OpportunityService,
    private formBuilder: FormBuilder, 
    private dataService: DataService, 
    private router: Router, 
    private storageService: LocalStorageService, 
    private route: ActivatedRoute, 
    private modalService: NgbModal,
    private accountsService: AccountsService,
    ) {
    this.opportunityForm = this.formBuilder.group({
      id: new FormControl(''),
      opportunityName: new FormControl('',Validators.required),
      account: new FormControl('',Validators.required),
      startDate: new FormControl('',Validators.required),
      endDate: new FormControl('',Validators.required),
      submissionDeadline: new FormControl('',Validators.required),
      grossPrice: new FormControl(''),
      status: new FormControl(''),
      salesWeightedValue: new FormControl(''),
      probability: new FormControl('',Validators.required),
    })
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.getOpportunities();
    this.getAccountist()
  }
 
  getOpportunities() {
    this.opportunityService.getData().subscribe((response: any) => {
      this.jsonData = response;
    });
  }

  getAccountist() {
    /*
    this.accountDropdownList = []
    this.accountsService.getAllAccounts().subscribe((res:any) => {
      for (let item of res.result) {
        this.accountDropdownList.push(item.accountName)
      }
    })
    */


    this.accountDropdownList = [];
      this.accountsService.getAllAccounts().subscribe((res:any) => {
        console.log("result getAccountList:", res.result);

        this.accountDropdownList = res.result;
        /*
        for (let item of res.result) {
          this.accountDropdownList.push(`${item}`);
        }*/
        console.log("accountDropdownList:", this.accountDropdownList);

      });
  }

  createOpportunity() {
    let details = JSON.stringify(this.opportunityForm.value);
    this.opportunityService.createOpportunity(details).subscribe((response: any) => {
      // console.log("Opportunity created!, response: ", response);

      this.createdopportunityid = response.id;
    console.log("response this.createdopportunityid: ", this.createdopportunityid);

    let associationdetails = '{ "id1" : ' + this.selectedAccountId +' , "id2" : '+ this.createdopportunityid +', "type1" : "/accounts" , "type2" : "/opportunities" }';
    console.log("associationdetails variable:", associationdetails);
    this.opportunityService.createOpportunityAssociation(associationdetails).subscribe((response: any) => {
      console.log("response createOpportunityAssociation: ", response);
      
    });

    
      if (response) {
        this.getOpportunities()
        $('#closeModalOpportunity').trigger("click");
      }
      
    });
  }

  deleteOpportunity(id) {
    this.opportunityService.deleteOpportunitys(id).subscribe(response => {
      this.getOpportunities()
      $('#closeModalDeleteOpportunity').trigger("click");
    });
  }

  updateOpportunity(id) {
    let details = JSON.stringify(this.opportunityForm.value);
    this.opportunityService.updateOpportunity(details, id).subscribe(response => {
        this.getOpportunities()
        $('#closeModalEditOpportunity').trigger("click");
    });
  }

  rowData(item) {
    console.log("item: ", item);
    this.dataService.updatedOpportunity(item);
    localStorage.setItem("opportunityObject", JSON.stringify(item))
    this.router.navigate([this.tenantId + '/dashboard/opportunities/quotes']);
    // this.router.navigate(['quotes'], { relativeTo: this.route });
  }

  // to open delete modal
  open(item) {
    this.selectedRow = item;
  }

  // to open edit modal
  openEdit(item) {
    this.opportunityForm.patchValue(item);
    this.selectedRow = item;
  }

  createOpenModal() {
    //this.modeAction = 1;
    this.opportunityForm.reset();
  }

    // getter for easy access to form fields
    get f() {
      return this.opportunityForm.controls;
    }

    public getSelectedAccountId(e): void {
      let find = this.accountDropdownList.find(x => x?.accountName === e.target.value);
     // console.log(find?.id);
      this.selectedAccountId = find?.id;
      console.log(this.selectedAccountId);

    }
}

export class ZeroConfigComponent {}