import { QuoteService } from 'src/app/_services/quote/quote.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CapacityPlanningService } from "../../../../_services/capacity-planning/capacity-planning.service";
import { CapacityPlanningValues } from './../../../../_models/capacity-planning.model';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
import { ExportType, ChartComponent, ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Browser } from '@syncfusion/ej2-base';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { OpportunityService } from 'src/app/_services/opportunity/opportunity.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';

@Component({
  selector: 'app-capacity-planning-detail',
  templateUrl: './capacity-planning-detail.component.html',
  styleUrls: ['./capacity-planning-detail.component.scss']
})
export class CapacityPlanningDetailComponent implements OnInit {
  opportunityObject = JSON.parse(localStorage.getItem("opportunityObject"));
  public quoteDropdown;
  formGroup: FormGroup;
  capacityPlanningFormDetails: FormGroup;
  mainCPGroup: FormGroup;
  TotalRow: number;
  public CPData: Object[];
  public CPJsonData;
  public detailsCP: Object[];
  public GraphData: Object[];
  public GraphDataName: Object[];
  id: number;
  tenantId: string;
  public lineGraph: Object[];
  public activeIndex: boolean = false;
  public chartData: Object[];
  public title: string;
  public GraphCount: number;
  public uniqueChars: Object[];
  snackMessage: string;
  public chartArea: Object;
  @ViewChild('drawer') drawer: SidebarComponent;
  @ViewChild('chart')
  public chart: ChartComponent;
  public yMaxValue = 0;
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    labelIntersectAction: 'Rotate45',
    valueType: 'Category',
    labelFormat: 'MMM-y',
    //valueType: 'DateTime'
  

  };
  public primaryYAxis: Object = {
    title: 'FTE',
    rangePadding: 'None',
    //interval: 10,
    //minimum: 0,
    maximum: 50,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
  };

  public legendSettings: Object = { visible: true };
  public columnMarker: Object = { dataLabel: { visible: true, position: 'Top' } };
  public lineMarker: Object = { visible: true, dataLabel: { visible: true } };

  public tooltip: Object = {
    enable: true
  }

  public width: Object = Browser.isDevice ? '100%' : '90%';
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };

  constructor(
    private capacityPlanningService: CapacityPlanningService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private opportunityService: OpportunityService,
    private storageService: LocalStorageService,
    private quoteService: QuoteService) {
    this.capacityPlanningFormDetails = this.fb.group({
      id: new FormControl(''),
      capacityPlanningName: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      values: this.formGroup
    });
    this.mainCPGroup = this.fb.group({
      id: new FormControl(''),
      capacityPlanningName: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    })
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.id = params.id;
      console.log(this.id);
    });

    console.log("Component Details page ID", this.id);

  }

  drawerClose() {
    this.capacityPlanningFormDetails.reset();
    this.activeIndex = false;
    this.drawer.close();
  }

  ngOnInit(): void {
    //Capacity Planning Graph Data
    this.getCapacityPlanningGraph(this.id);
    this.tenantId = this.storageService.get('tenantId');
    this.formGroup = this.fb.group({
      values: this.fb.array([this.initItemRow()])
    })
    this.addSomeCPDataFromAPI();
    this.getQuoteProject();
    this.getQuoteDetails()
  }

  // Choose city using select dropdown
  changeCity(e) {
    console.log("e", e);
    this.quoteDropdown.setValue(e.target.value, {
      onlySelf: true
    })

  }

  getQuoteDetails() {
    this.quoteService.getQuoteDetails(this.opportunityObject.id).subscribe(res => {
      console.log('res', res)
      // this.quoteDropdown = res
    })
  }



  addSomeCPDataFromAPI() {
    // console.log("ID addSomeCPDataFromAPI", this.id);
    if (this.id) {
      this.capacityPlanningService.getCapacityPlanningDatabyID(this.id).subscribe((response: any) => {
        console.log("Capacity Planning by ID addSomeCPDataFromAPI", response);

        //this.mainCPGroup.patchValue(response, { onlySelf: true });
        this.CPJsonData = response;
        this.capacityPlanningFormDetails = response;
        console.log("this.CPJsonData addSomeCPDataFromAPI", this.mainCPGroup);
        console.log("this.CPJsonData capacityPlanningFormDetails", this.capacityPlanningFormDetails);
        this.CPData = response.values;
        const control = <FormArray>this.formGroup.controls["values"];
        for (let i = 1; i < this.CPData.length; i++) {
          control.push(this.initItemRow());
        }
        this.formGroup.patchValue({ values: this.CPData });

      });
      //this.getCapacityPlanningDetails(this.id);
      //console.log("addSomeCPDataFromAPI Data", this.CPData);

    }
    //const unitsArray = [
    //  { unitName: "test unit 1", qty: 2, unitPrice: 22.44 },
    //  { unitName: "test unit 2", qty: 1, unitPrice: 4 },
    //  { unitName: "test unit 3", qty: 44, unitPrice: 1.5 }
    //];

  }

  initItemRow() {
    //var retArr;
    //if (this.id) {
    //  this.getCapacityPlanningDetails(this.id);
    //  this.formGroup.controls.values = this.CPData["values"];
    //  //this.formGroup = this._fb.group({
    //  //  values: this._fb.array(this.CPData["values"])
    //  //})
    ////  retArr = this.formGroup.controls.values;
    //} else {
    return this.fb.group({
      quoteId: '',
      probability: ''
    });
    //}
    //console.log("Resulted Array", retArr);
    //return retArr;
  }
  //Get Capacity Planning
  getCapacityPlanningDetails(id) {
    this.capacityPlanningService.getCapacityPlanningDatabyID(id).subscribe((response: any) => {
      console.log("Capacity Planning by ID", response);
      this.CPData = response;

    });
    console.log("CPData", this.CPData);
    return this.CPData;
  }


  //Funtion to get the month
  getMonth(monthStr) {
    return new Date(monthStr + '-1-01').getMonth();
  }

  //get Capacity Planning Graph Data
  getCapacityPlanningGraph(id) {
    var monthArr = [];
    var taskArr = [];
    var lineGraphTemp = [];
    var TempGraphName = [];
    //{
    //"fte": 20,
    //  "monthYear": "April-2021",
    //    
    //      "taskToFte": {
    //    "Task3": 0.35,
    //      "Task1": 0.022,
    //  }
    this.capacityPlanningService.getCapacityPlanningGraphDatabyID(id).subscribe((response: any) => {
      console.log("Capacity Planning Graph by ID", response);
      this.CPData = response;
      //this.dummpyData = response;

      for (var i = 0; i < this.CPData.length; i++) {
        // var parentObjectKey = Object.keys(this.dummpyData[i]);
        //   console.log(parentObjectKey)

        var month = this.CPData[i]['monthYear'];


        //monthArr.push(month.split(/-(.*)/)[1]);
        monthArr.push(month);
        // console.log("Month ARray", monthArr);
        var taskToFte = Object.keys(this.CPData[i]['taskToFte']);
        // console.log(taskToFte);
        //for (var j = 0; j < childAttr.length; j++) {
        //  arrayChildAttr.push(childAttr[j]);
        //}
        taskArr.push(...taskToFte);
      }

      for (var i = 0; i < this.CPData.length; i++) {
        var month = this.CPData[i]['monthYear'];
        var monthArrSplit = month.split("-");
        console.log(monthArrSplit);
        var getMonthNumber = this.getMonth(monthArrSplit[0]);
        var getYear = parseInt(monthArrSplit[1]);
        //  console.log(getMonthNumber, getYear);
        var fte = this.CPData[i]['fte'];
        if (fte > this.yMaxValue) { this.yMaxValue = fte; this.primaryYAxis["maximum"] = this.yMaxValue + 10; }
        //Line Graph Calculate the Line plot on graph
        //lineGraphTemp.push({ "x": new Date(getYear, getMonthNumber, 11), "y": fte });
        lineGraphTemp.push({ "x": month, "y": fte });
        this.lineGraph = lineGraphTemp;
      }
      console.log("this line Graph", this.lineGraph);
      //  console.log("Month ARray", taskArr);
      // this.uniqueChars = [...new Set(taskArr)];
      taskArr.filter(function (value, index) { return taskArr.indexOf(value) == index });
      this.uniqueChars = [...new Set(taskArr)];
      for (var x = 0; x < this.uniqueChars.length; x++) {
        TempGraphName.push(this.uniqueChars[x].toString());

      }
      this.GraphDataName = TempGraphName;
      this.GraphCount = this.uniqueChars.length;
      // this.uniqueChars = [...new Set(taskArr)];
      console.log("this.GraphDataName ", this.GraphDataName);
      for (var i = 0; i < this.uniqueChars.length; i++) {
        var arr = [];
        for (var j = 0; j < this.CPData.length; j++) {

          var taskToFte = this.CPData[j]['taskToFte'];
          var month = this.CPData[j]['monthYear'];
          var monthArrSplit = month.split("-");
          // console.log(monthArrSplit);
          var getMonthNumber = this.getMonth(monthArrSplit[0]);
          var getYear = parseInt(monthArrSplit[1]);
          // console.log(getMonthNumber, getYear);
          var valueNo = this.uniqueChars[i].toString();
          if (taskToFte.hasOwnProperty(valueNo)) {

            var arrP = { "x": month, y: this.CPData[j]['taskToFte'][this.uniqueChars[i]] };
            arr.push(arrP);

          }


        }
        this.uniqueChars[i] = arr;

      }
      this.GraphData = this.uniqueChars;
      console.log(this.uniqueChars);
    });


    //   return this.CPData;
  }


  addNewRow() {

    const control = <FormArray>this.formGroup.controls['values'];
    console.log("ADD NEW ROW", control)
    control.push(this.initItemRow());
  }

  deleteRow(index: number) {
    const control = <FormArray>this.formGroup.controls['values'];
    if (control != null) {
      this.TotalRow = control.value.length;
    }
    if (this.TotalRow > 1) {
      control.removeAt(index);
    } else {
      alert("One record is mandatory");
      return false;
    }
  }


  //Funtion to generate the Head Count by Engineering Type
  getQuoteProject() {
    this.capacityPlanningService.getQuoteProjectData().subscribe((response: any) => {
      console.log("Capacity Planning Quotes", response);
      this.quoteDropdown = response;
    });

  }

  savedCapacityPlanningDetails(id: number, formValueList) {

    console.log("Saved the Capacity Planning Details Page", id);
    let details = JSON.stringify(this.formGroup.value);
    let detailsArr = this.formGroup.value;
    console.log("detailsArr", this.formGroup);
    //this.getCapacityPlanningDetails(id);
    this.capacityPlanningService.getCapacityPlanningDatabyID(id).subscribe((response: any) => {
      console.log("Capacity Planning by ID", response);
      this.CPData = response;
      console.log("Updated Capacity Planning add Values111111111", this.CPData);
      //  this.CPData["capacityPlanningName"] = this.CPData["capacityPlanningName"] + '1';
      this.detailsCP = this.CPData;
      console.log("details CP", details);
      this.detailsCP["values"] = detailsArr["values"];
      console.log("Updated Capacity Planning add Values", this.detailsCP);
    });
    var data = JSON.stringify(this.detailsCP);
    this.capacityPlanningService.updateCapacityPlanningData(this.detailsCP, id).subscribe((response: any) => {
      console.log("Updated CP", response);
      //this.dataSource.data.push({ ...response })
      //this.dataSource.data = this.dataSource.data.map(o => {
      //  return o;
      //})
      if (response != null && response) {
        //this.grid.refresh();
        this.openSnackBar("Capacity Planning Project List Updated");

      }
    });
  }

  //Graph Export
  export() {
    this.chart.exportModule.export('PNG', 'chart');
  }


  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }
  sidebarOpen = false;
  openCloseInnerSidebar() {
    this.sidebarOpen = !this.sidebarOpen
  }

  backToCapacityTable() {
    this.router.navigate([this.tenantId + '/dashboard/project/capacity-planning']);
  }



}
