import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AuthenticationService } from "../../../_services/auth/authentication.service";
import { LocalStorageService } from "../../../_services/localStorage/local-storage.service";
import { JwtTokenService } from "../../../_services/jwtToken/jwt-token.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  user: any;
  title = 'dashboard';

  loginForm = new FormGroup({
    userId: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]),
    tenantId: new FormControl('', Validators.required)
  });

  constructor(private formBuilder: FormBuilder, 
    private router: Router,
    private loginService: AuthenticationService, 
    private snackBar: MatSnackBar,
    private storageService: LocalStorageService,
    private jwtService: JwtTokenService) { }

  ngOnInit(): void {
  }

  // getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  checkLogin() {
    let credentials = JSON.stringify(this.loginForm.value);
    let tenantId = this.loginForm.value.tenantId;
    this.storageService.clear();
    this.loginService.authenticate(credentials, tenantId).subscribe((response: any) => {
      let tokenStr = "Bearer " + response.jwtResponse.token;
      this.storageService.set('currentUser', JSON.stringify(response.userMedia)); // separate service or global
      this.jwtService.setToken(tokenStr);
      this.storageService.set('token', tokenStr);
      this.storageService.set('tenantId', tenantId); // global
      this.storageService.set('userImage', JSON.stringify(response.userMedia.image)); // delete later
      this.router.navigate([tenantId + '/dashboard']);
    },
      (error: any) => {
        this.snackBar.open('Incorrect login credentials!', 'Close', { duration: 3000 });
      });
  }

  register() {
    this.router.navigate(['/register']);
  }
  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }
  pricingTable() {
    this.router.navigate(['/pricing-table']);
  }
}
