import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxPermissionsService } from 'ngx-permissions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { TaxdeductionService } from '../../../../_services/taxdeduction/taxdeduction.service';

@Component({
  selector: 'app-tax-deductions-two',
  templateUrl: './tax-deductions-two.component.html',
  styleUrls: ['./tax-deductions-two.component.scss']
})
export class TaxDeductionsTwoComponent implements OnInit {
  tenantId: string;
  sonData: any;
  // row data and column definitions 
  taxdeductionform 
  rowData: any;
  form: FormGroup;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any
   dataSource: any;
  selectedRowId: any;
  selectedRow: any;
  selectedFile: File;
  currentUser: any;
  gridApi: any;
  gridColumnApi: any;
  emailPattern = "^[a-z0-9._%+-]+\\.[a-z]{2,4}$";
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete';
  selectedFinancialYear: any;
  taxExemptionArray : any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: NgxPermissionsService, 
    private storageService: LocalStorageService, 
    private modalService: NgbModal, 
    private snackBar: MatSnackBar,
    private router: Router,
    private taxdeductionService :TaxdeductionService
  ) { 
    {
        this.defaultColDef = {
          flex: 1,
          minWidth: 100,
          resizable: true,
        };
        this.rowSelection = 'multiple';
      this.columnDefs = this.createColumnDefs();  

      this.sampleGridFrameworkComponents = {
        actionRowRendererComponent: ActionItemsGridRowRendererComponent
      };
    }
     this.taxdeductionform = this.formBuilder.group({
      iid: new FormControl(''),
      financialYear: new FormControl(''),
      employeeId: new FormControl(''),
      employeeName: new FormControl(''),
      metro: new FormControl(''),
      taxExemptionName: new FormControl(''),
      taxExemptionValue: new FormControl(''),
      percent:  new FormControl(''),
      basicSalaryDA: new FormControl(''),
      hra: new FormControl(''),
      lta: new FormControl(''),
      gratuityPayment: new FormControl(''),
      leaveEncashmentPayment: new FormControl(''),
      childrenEducationAllowance: new FormControl(''),
      otherTaxFreeAllowanceOrReimbursements: new FormControl(''),
      sharedDeduction: new FormControl(''),
      professionalTax: new FormControl(''),
      section80C: new FormControl(''),
      section80DMediclaim: new FormControl(''),
      section80E: new FormControl(''),
      section80TTA: new FormControl(''),
      incomeFromHousePropertySection3: new FormControl(''),
      section80CCD1: new FormControl(''),
	    section80CCD2: new FormControl(''),
	    section80DD: new FormControl(''),
	    section80DDB: new FormControl(''),
	    section80EEA: new FormControl(''),
	    section80EEB: new FormControl(''),
	    section80GGGAGGC: new FormControl(''),
	    section80GG: new FormControl(''),
	    section80U: new FormControl(''),
    })  
}

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getTaxDeduction();
  }

  handleChange(fYear){
    this.selectedFinancialYear = fYear.target.value;
    this.getTaxDeduction();
  }

  getTaxDeduction(){
    if(this.selectedFinancialYear){
      this.taxdeductionService.getAllTaxDeduction().subscribe((response: any) => {
        this.taxExemptionArray = [];
        for(let item of response.result){
          if(item.financialYear === this.selectedFinancialYear){
            this.taxExemptionArray.push(item);
          }
        }
        this.rowData = this.taxExemptionArray;
      });
    }
    else{
      this.taxdeductionService.getAllTaxDeduction().subscribe((response: any) => {
        this.rowData = response.result; 
      });
    }
    
    
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params: any) {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  private createColumnDefs() {
    return [
      {
        headerName: "Employee Name",
        field: "employeeName",
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Financial Year',
        field: 'financialYear',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Total Exemption',
        field: 'totalDeclaration',
        filter: true,
        editable: false,
        sortable: true
      },
      // {
      //   headerName: 'Tax Exemption Value',
      //   field: 'taxExemptionValue',
      //   filter: true,
      //   editable: false,
      //   sortable: true
      // },
      // {
      //   headerName: 'Tax %',
      //   field: 'percent',
      //   filter: true,
      //   editable: false,
      //   sortable: true
      // },
      // {
      //   headerName: 'Annual Tax Slab',
      //   field: 'componentUpperLimitValue',
      //   filter: true,
      //   editable: false,
      //   sortable: true
      // },
    ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }
  }

  private deleteGridRow(params: any) {
    this.taxdeductionService.deletetTaxDeduction(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  deleteOpenModal() {
      this.deleteConfirmationMsg = 'Are you sure you want to delete'
      for (let i in this.selectedCheckboxData) {
        if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].employeeName} `;
        else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].employeeName} `;
        else this.deleteConfirmationMsg += `, ${this.selectedCheckboxData[i].employeeName} `;
      }
    }

    deleteTaxDeclaration() {
      this.taxdeductionService.deletetTaxDeduction(this.selectedCheckboxData[0].id).subscribe(() => {
        this.getTaxDeduction();
        this.selectedCheckboxData = []
        $('#closeModalDeleteTaxDeduction').click()
      });
    } 

    // updateDeduction() {
    //   let selectedNodes = this.api.getSelectedNodes();
    //   let selectedData = selectedNodes.map((node) => node.data);
    //   let editId = selectedNodes.map((node) => node.data.id);
    //   //console.log("id",editId);
    //   this.router.navigate([
    //     this.tenantId + '/dashboard/finance/tax-deduction', { id: this.selectedCheckboxData[0].id }]);
    // }

    updateDeduction(){
      let currenttaxdeductionId = this.selectedCheckboxData[0].id; 
      this.router.navigate([this.tenantId + '/dashboard/finance/tax-deduction'], { queryParams: { id : currenttaxdeductionId } })
      
     
    }
    
  grossSalary() {
    this.router.navigate([this.tenantId + '/dashboard/finance/tax-deduction']);
  }

  deduction() {
    this.router.navigate([this.tenantId + '/dashboard/hr/tax-deductions-two']);
  }

}
