import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { DataStateChangeEventArgs, DataSourceChangedEventArgs } from '@syncfusion/ej2-angular-grids';
import { Accounts } from '../../_models/accounts.model';
import { map } from 'rxjs/operators';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService extends Subject<DataStateChangeEventArgs> {

  private httpClient: HttpClient;


  constructor(private handler: HttpBackend, private http: HttpClient, private storageService: LocalStorageService) {
    super();
    this.httpClient = new HttpClient(handler);

   }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
   // window.alert(errorMessage);
   console.log("Error:",errorMessage);
    return throwError(errorMessage);
  }

  imprtAcc(data: any): Observable<Object> {
    const header = {
      headers: new HttpHeaders({
        'Authorization': this.storageService.get('token'),
        'tenantId': this.storageService.get('tenantId'),
      }),
    };
    return this.httpClient.post(`${environment.apiUrl}/accountsUpload`, data, header).pipe(catchError(this.handleError));
  }

    //  upload image
    uploadImage(id: number, data: any): Observable<Object> {
      const header = {
        headers: new HttpHeaders({
          'Authorization': this.storageService.get('token'),
          'tenantId': this.storageService.get('tenantId'),
        }),
      };
      return this.httpClient.post(`${environment.apiUrl}/accounts/` + id + `/image`, data, header).pipe(catchError(this.handleError));
    }

       // Create Accounts
       createAccounts(user: Object): Observable<Object> {
        console.log("In createContact method, Accounts: ", user);
        return this.http.post(`${environment.apiUrl}/accounts`, user).pipe(catchError(this.handleError));
      }

        // Create Accounts email
     createAccountsemail(mailinput: Object): Observable<Object> {
      console.log("In createAccounts method, Accounts email: ", mailinput);
      return this.http.post(`${environment.apiUrl}/accountsEmail`, mailinput).pipe(catchError(this.handleError));
    }
  
    
       // Get leads by Id Get
       getAccounts(id: number): Observable<Object> {
        return this.http.get(`${environment.apiUrl}/accounts/${id}`).pipe(catchError(this.handleError));
      }
    
       //Delete Contact by Id Delete
       deleteAccounts(id: number): Observable<Object> {
        return this.http.delete(`${environment.apiUrl}/accounts/${id}`).pipe(catchError(this.handleError));
      }
    
         //Update Contact by Id Put
         updateAccounts(user: Object,id: number): Observable<Object> {
          console.log("In createLead method, user: ", user);
          return this.http.put(`${environment.apiUrl}/accounts/${id}`, user).pipe(catchError(this.handleError));
        }
    
        //Get All Contact by Id Get
         getAllAccounts(): Observable<Object> {
          return this.http.get(`${environment.apiUrl}/accounts`).pipe(catchError(this.handleError));
        }

    //Synfusion  CRUD Operation
  public execute(state: any): void {
    console.log("Execute Accounts funtion", state);
    console.log("Execute Return Staement", this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs)));
    this.getAllData(state).subscribe(x => super.next(x as DataStateChangeEventArgs));
  }

  /** GET all data from the server */
  getAllData(state?: any): Observable<any[]> {
    console.log("GetAllData Accounts",this.http.get<Accounts[]>(`${environment.apiUrl}/accounts`).pipe(retry(2), catchError(this.handleError)));
    return this.http.get<Accounts[]>(`${environment.apiUrl}/accounts`).pipe(map((response: any) => (<any>{
      result: state.take > 0 ? response.result.slice(0, state.take) : response,
      count: response.length
    })));
  }

  /** POST: add a new record  to the server */
  addRecord(state: DataSourceChangedEventArgs): Observable<Accounts> {
    // you can apply empty string instead of state.data to get failure(error)
    console.log("Add record Accounts Call");
    return this.http.post<Accounts>(`${environment.apiUrl}/accounts`, state.data);
  }

  /** DELETE: delete the record from the server */
  deleteRecord(state: any): Observable<Accounts> {
    const id = state.data[0].id;
    const url = `${ environment.apiUrl}/accounts/${ id }`;
    return this.http.delete<Accounts>(url);
  }

  /** PUT: update the record on the server */
  updateRecord(state: any): Observable<any> {
    const id = state.data.id;
    console.log("UpdateRecord", this.http.put<Accounts>(`${environment.apiUrl}/accounts/${ id }`, state.data));
    return this.http.put<Accounts>(`${environment.apiUrl}/accounts/${ id }`, state.data);
  }

  // Get crm Associations by Id Get
  getcrmAssociationbyId(id: number, associationtype: string): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/crmAssociations?id=${id}&type=${associationtype}`).pipe(catchError(this.handleError));
  }
  
  //Update crmAssociations by Id Put
  updatecrmAssociationbyId(user: Object,id: number): Observable<Object> {
    console.log("In accountUpdatecrmAssociationbyId method, user: ", user);
    return this.http.put(`${environment.apiUrl}/crmAssociations/${id}`, user).pipe(catchError(this.handleError));
  }

  // Create crm association of contact with others(eg:account)
  createAccountAssociation(user: Object): Observable<Object> {
    console.log("In createAccountAssociation method, Account Association: ", user);
    return this.http.post(`${environment.apiUrl}/crmAssociations`, user).pipe(catchError(this.handleError));
  }

}
